import React from 'react';
import { Divider, Typography } from '@material-ui/core';


const CommissioningOrder  = props => {

    return (
        <div style={{marginBottom: 20}}>
            <Typography variant="h6">#VE{props.order.id_order} - {props.order.order_name}</Typography>
            <Typography variant="body1" color="textSecondary">Organisation: {props.order.organisation_name}</Typography>
            <Typography variant="body1" color="textSecondary">Group Scheme: {props.order.group_scheme}</Typography>
            <Typography variant="body1" color="textSecondary">Subscription: {props.subscription !== null && `${props.subscription.product_description} ${props.subscription.subscription_description}`}</Typography>
            <Divider style={{margin: '20px 0'}} />
        </div>
    )
}

export default CommissioningOrder;