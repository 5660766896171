import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden'
    },
    wrapper: {
        display: 'flex',
        padding: theme.spacing(2),
        flexDirection: 'column',
        flex: 1,
        height: 'auto',
        overflow: 'auto'
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 'auto',
        overflow: 'auto'
    },
    flexRight: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 10
    },
    flexLeft: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10
    },
    flexSpaced: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10
    },
    input: {
        marginBottom: 15
    },
    space: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    label: {
        display: 'flex',
        flexDiraction: 'row',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    highlightedArea: {
        padding: 4, 
        backgroundColor: theme.palette.action.hover, 
        border: `3px solid ${theme.palette.background.default}`,
        display: 'flex',
        marginTop: 10,
        marginBottom: 10
    }
}));

