import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';
import { Grid, TextField, Button, FormControl, FormHelperText, CircularProgress, Typography, InputLabel, Select, Collapse, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';


const AddOrderContact = props => {
    const [loading, setLoading] = useState(false);

    const baseContact = {
        id_order: typeof props.id_order!='undefined'?props.id_order:null,
        id_organisation: null,
        id_organisation_type: null,
        organisation_name: null,
        contact_name: '',
        contact_position: '',
        contact_email: '',
        contact_phone: ''
    }

    // Create a local state
    const [contact, setContact] = useState({...baseContact});
    const [selectedOrganisation, setOrganisation] = useState(null);
    const [organisations, setOrganisations] = useState([]);
    const [organisation_types, setOrganisationTypes] = useState([]);

    const [show, setShow] = useState(false);
    const [shrinkLabels, setShrinkLabels] = useState(false);


    useEffect(() => {

        setLoading(true)

        context.fetchOrganisationTypes().then(
            res => {
                setOrganisationTypes(res.result);

                context.fetchOrganisations().then(
                    res => {
                        console.log(res);
                        setOrganisations(res.result);
                        setLoading(false)
                    }, 
                    err => {
                        setLoading(false)
                        context.showAlert('error', 'error fetching organisations: ' + JSON.stringify(err.msg));
                    }
                )

            }, 
            err => {
                setLoading(false)
                context.showAlert('error', 'error fetching organistion types: ' + JSON.stringify(err.msg));
            }
        )

        
    }, [])


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    const handleChange = e => {
        let newContact = {...contact};
        newContact[e.target.name] = e.target.value;
        setContact(newContact);
        setShrinkLabels(true);
    }

    const handleChangeOrganisation = (e, newValue) => {
        setOrganisation(newValue);
        setContact({
            ...contact,
            id_organisation: Helpers.isNull(newValue.id_organisation,null),
            organisation_name: Helpers.isNull(newValue.organisation_name),
            contact_name: Helpers.isNull(newValue.main_contact_name),
            contact_position: Helpers.isNull(newValue.main_contact_position),
            contact_email: Helpers.isNull(newValue.main_contact_email),
            contact_phone: Helpers.isNull(newValue.main_contact_phone),
            id_organisation_type: Helpers.isNull(newValue.organisation_type)
        });
        setShow(true);
        setShrinkLabels(true);
    }


    const invalid = () => {
        if(isInvalid('contact_name') || isInvalid('id_organisation_type') || (isInvalid('contact_email') && isInvalid('contact_phone')))
            return true;
        
        return false;
    }

    const isInvalid = prop => {
        switch(prop){
            case 'contact_name':
                return contact.contact_name == ''?true:false;
            case 'id_organisation_type':
                return parseInt(contact.id_organisation_type) > 0?false:true;
            case 'contact_email':
            case 'contact_phone':
                return contact.contact_email == '' && contact.contact_phone == ''?true:false;
            default:
                return true;
        }
    }

    const createContact = () => {
        if(!invalid())
            setLoading(true);
            context.addOrderContact(contact).then(
                res => {
                    setContact({...baseContact});
                    setLoading(false);
                    context.showAlert('success', 'Contact added');

                    if(typeof props.onAdd == 'function')
                        props.onAdd();
                },
                err => {
                    setLoading(false);
                    context.showAlert('error', 'Error adding contact ' + JSON.stringify(err));
                }
            )
    }

    
    const getOrganisationName = org => {
        if(org.organisation_name!=null)
            return org.organisation_name
        
        if(org.main_contact_name!=null)
            return org.main_contact_name
        
        if(org.main_contact_email!=null)
            return org.main_contact_email
        
        if(org.main_contact_phone!=null)
            return org.main_contact_phone
        
        return '';
    }

    
        
    return (

        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: 20}}>
            <Typography variant="h5">Add contact</Typography>


            {!show && <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                <Autocomplete
                    options={organisations}
                    getOptionLabel={option => option.organisation_name}
                    value={selectedOrganisation}
                    style={{width: '100%'}}
                    onChange={handleChangeOrganisation}
                    id="id_organisation"
                    autoHighlight
                    renderOption={option => (
                        <React.Fragment>
                            <span style={{marginRight: 10}}>({option.id_organisation})</span>
                            {getOrganisationName(option)}
                        </React.Fragment>
                    )}
                    renderInput={params => <TextField 
                        {...params}
                        label="Select Organisation" 
                    />}
                />

                <Button style={{marginTop: 15}} color="secondary" onClick={() => setShow(true)}>Enter Manually</Button>
            </div>}
                  
            <Collapse in={show}>

                {contact.id_organisation != null && 
                    <div style={{flex: 1, marginBottom: 10, padding: '10px 0'}}>
                        <Typography variant="body">Organisation ID #{contact.id_organisation} - {contact.organisation_name}</Typography>
                    </div>
                }

                {contact.id_organisation == null && 
                <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                    <FormControl fullWidth>
                        <TextField native id="organisation_name" name="organisation_name" value={contact.organisation_name} onChange={handleChange} label="Organisation Name" InputLabelProps={{shrink: shrinkLabels}}/>
                    </FormControl>
                </div>}


                <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                    <FormControl fullWidth>
                        <InputLabel id="contact-type-label" error={isInvalid('id_organisation_type')} shrink={shrinkLabels} >Contact Type</InputLabel>
                        <Select
                        labelId="contact-type-label"
                        name="id_organisation_type"
                        value={contact.id_organisation_type}
                        onChange={handleChange}
                        error={isInvalid('id_organisation_type')} 
                        >
                            <MenuItem value="">None</MenuItem>
                            {organisation_types.map(type => (
                                <MenuItem key={type.id_organisation_type} value={type.id_organisation_type}>{type.organisation_type_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                    <FormControl fullWidth>
                        <TextField name="contact_name" value={contact.contact_name} onChange={handleChange} label="Name" error={isInvalid('contact_name')} InputLabelProps={{shrink: shrinkLabels}} />
                    </FormControl>
                </div>

                <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                    <FormControl fullWidth>
                        <TextField name="contact_position" value={contact.contact_position} onChange={handleChange} label="Position" InputLabelProps={{shrink: shrinkLabels}} />
                    </FormControl>
                </div>

                <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                    <FormControl fullWidth>
                        <TextField name="contact_email" value={contact.contact_email} onChange={handleChange} label="Email" error={isInvalid('contact_email')} InputLabelProps={{shrink: shrinkLabels}} />
                    </FormControl>
                </div>

                <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                    <FormControl fullWidth>
                        <TextField name="contact_phone" value={contact.contact_phone} onChange={handleChange} label="Phone" error={isInvalid('contact_phone')} InputLabelProps={{shrink: shrinkLabels}} />
                    </FormControl>
                </div>

                <div style={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 30, justifyContent: 'center', alignItems: 'flex-end', padding: 10}}>
                    <div style={{flex: 1}}></div>
                    <div style={{padding: '0 10px'}}>
                        <Button color="secondary" variant="contained" disabled={invalid()} onClick={createContact}>Add {loading && <CircularProgress size={20} className="ml" color="inherit" />}</Button>
                    </div>
                </div>

            </Collapse>
        </div>

    );
}

export default AddOrderContact