import React, {useContext, useRef, useState, useEffect} from 'react';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/styles';
import Helpers from '../global/helpers';
import EventIcon from '@material-ui/icons/Event';
import CloseIcon from '@material-ui/icons/Close';

import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Grid,
    TextField,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Switch,
    FormControlLabel
} from '@material-ui/core';
import GlobalContext from '../../context/global-context';


const blank_validation = (name, required = true) => {
    return JSON.parse(JSON.stringify({
        name,
        required,
        valid: false,
        msg: '',
        dirty: false
    }))
}

const TicketVisitReport = props => {

    const theme = useTheme();
    const styles = useStyles(theme);
    const context = useContext(GlobalContext)

    const [ report, setReport ] = useState({
        id_ticket_visit: props.visit.id_ticket_visit,
        ticket_visit_report: '',
        date_attended_arrived: null,
        date_attended_departed: null,
        parts: []
    });
    const [ validation, setValidation ] = useState({
        ticket_visit_report: blank_validation('Report', true),
        date_attended_arrived: blank_validation('Report', true),
        date_attended_departed: blank_validation('Report', true),
    });
    const [ loading, setLoading ] = useState(false);
    const [ partInput, setPartInput] = useState('');
    const [ closeTicket, setCloseTicket ] = useState(false);


    useEffect(() => {
        validate();
    }, [report])


    const isError = (obj) => {
        return (obj.dirty && obj.valid === false);
    }

    const setDirty = name => {
        let newValidation = {...validation};
        if(typeof newValidation[name] != 'undefined'){
            newValidation[name].dirty = true;
            setValidation(newValidation);
        }
    }

    const setDirtyAll = () => {
        let newValidation = {...validation};
        const keys = Object.keys(newValidation);
        keys.map(k => newValidation[k].dirty = true);
        setValidation(newValidation);
    }

    const handleValueChange = e => {
        setDirty(e.target.name);
        const newReport = {...report};
        newReport[e.target.name] = e.target.value;
        setReport(newReport);
    }

    const handlePartChange = e => {
        setPartInput(e.target.value);
    }

    const handlePartChangeKeyPress = e => {
        if(e.key == 'Enter')
            handleAddPart();
    }

    const handleAddPart = () => {
        const newReport = {...report};
        newReport.parts.push(partInput);
        setPartInput('');
        setReport(newReport);
    }

    const handleRemovePart = idx => {
        const newReport = {...report};
        newReport.parts.splice(idx, 1);
        setPartInput('');
        setReport(newReport);
    }


    const validate = () => {
        let newValidation = {...validation};

        const keys = Object.keys(newValidation);
        keys.map(k => {
            newValidation[k].valid = true;
            newValidation[k].msg = '';

            if(newValidation[k].required === true && (report[k] === null || report[k].length === 0 || report[k] == '')){
                newValidation[k].valid = false;
                newValidation[k].msg = `${newValidation[k].name} is required to add a visit report`;
            }
        })

        setValidation(newValidation);
    }

    const isValid = () => {
        const keys = Object.keys(validation);
        let result = true;
        keys.map(k => {
            if(validation[k].valid === false)
            result = false;
        })
        return result;
    }

    const handleAddReport = () => {
        if(isValid()){
            setLoading(true);
            context.addVisitReport({
                id_ticket: props.ticket.id_ticket,
                visit: report,
                closeTicket: closeTicket
            }).then(
                res => {
                    setLoading(false);
                    props.setTicket(res.result);
                    props.handleUpdateTicket(res.result);
                    props.handleClose();
                }, err => {
                    setLoading(false);
                    context.showAlert('error', 'There was an error creating the visit report')
                }
            );
        }
    }


    
    
    const getSiteName = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return s.SiteName;

        return '';
    }
    
    const getSiteAddress = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return (
                <React.Fragment>
                    {s.AddressLine1.length > 0 && <Typography variant="body2">{s.AddressLine1}</Typography>}
                    {s.AddressLine2.length > 0 && <Typography variant="body2">{s.AddressLine2}</Typography>}
                    {s.AddressLine3.length > 0 && <Typography variant="body2">{s.AddressLine3}</Typography>}
                    {s.Town.length > 0 && <Typography variant="body2">{s.Town}</Typography>}
                    {s.County.length > 0 && <Typography variant="body2">{s.County}</Typography>}
                    {s.PostCode.length > 0 && <Typography variant="body2">{s.PostCode}</Typography>}
                </React.Fragment>);

        return '';
    }
    
    const getTechnicalContact = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        // const sd = props.ticketMeta.map_data.find(sd => sd.GroupSchemeID === s.GroupSchemeID && sd.ChargeSiteID === s.ChargeSiteID);
        // if(typeof sd != 'undefined')
        //     return (
        //         <React.Fragment>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Name: </Typography>
        //             {sd.TechnicalContacts.name != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.name}</Typography>}
        //             </div>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Email: </Typography>
        //             {sd.TechnicalContacts.email != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.email}</Typography>}
        //             </div>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Phone: </Typography>
        //             {sd.TechnicalContacts.telephone != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.telephone}</Typography>}
        //             </div>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Position: </Typography>
        //             {sd.TechnicalContacts.position != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.position}</Typography>}
        //             </div>
        //         </React.Fragment>);

        return '';
    }
    

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth>
            <DialogTitle id="form-dialog-title">
                <div className={styles.row}>
                    <div style={{flex: 1}}>Visit Report</div>
                    <div className={styles.row}><EventIcon style={{marginRight: 10}} />
                        <Typography variant="h6">{Helpers.SQLtoUTCDate(props.visit.date_booked, false)}</Typography>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>

                <div style={{padding: theme.spacing(2)}}>
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <Typography variant="body2" color="textSecondary">Description:</Typography>
                            <Typography variant="subtitle1" gutterBottom={false}>{props.visit.ticket_visit_description}</Typography>
                        </Grid>
                        <Grid item sm={6}>
                    
                            <div className={styles.pos}>
                                <Typography variant="body2" color="textSecondary">Where:</Typography>
                                <Typography variant="body2">{getSiteName(props.visit.id_site)}</Typography>
                            </div>
                        </Grid>
                    </Grid>


                    <div style={{paddingTop: 20}}>

                        <Grid container spacing={3}>

                                <Grid item sm={6}>
                                    <div className={styles.col}>
                                        <TextField
                                            name="date_attended_arrived"
                                            label="Date / Time Attended"
                                            type="datetime-local"
                                            variant="outlined" 
                                            value={report.date_attended_arrived} 
                                            onChange={handleValueChange} 
                                            className={styles.inputField} 
                                            error={isError(validation.date_attended_arrived)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {isError(validation.date_attended_arrived) && <Typography variant="caption" color="error">{validation.date_attended_arrived.msg}</Typography>}
                                        {!isError(validation.date_attended_arrived) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                                    </div>
                                </Grid>

                                <Grid item sm={6}>
                                    <div className={styles.col}>
                                    <TextField
                                        name="date_attended_departed"
                                        label="Date / Time Departed"
                                        type="datetime-local"
                                        variant="outlined" 
                                        value={report.date_attended_departed} 
                                        onChange={handleValueChange} 
                                        className={styles.inputField} 
                                        error={isError(validation.date_attended_departed)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {isError(validation.date_attended_departed) && <Typography variant="caption" color="error">{validation.date_attended_departed.msg}</Typography>}
                                    {!isError(validation.date_attended_departed) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                                    </div>
                                </Grid>

                                <Grid item xs={12}>

                                    <TextField 
                                        size="small" 
                                        name="ticket_visit_report" 
                                        label="Details" 
                                        variant="outlined" 
                                        value={report.ticket_visit_report} 
                                        onChange={handleValueChange} 
                                        className={styles.inputField} 
                                        fullWidth 
                                        multiline 
                                        rows={10} 
                                        error={isError(validation.ticket_visit_report)}
                                     />
                                    {isError(validation.ticket_visit_report) && <Typography variant="caption" color="error">{validation.ticket_visit_report.msg}</Typography>}
                                    {!isError(validation.ticket_visit_report) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}


                                </Grid>

                                <Grid item xs={12}>
                                    
                                    <div className={styles.row}>
                                        <div style={{flex: 1}}>
                                        <TextField 
                                                size="small" 
                                                name="parts_used" 
                                                label="Parts used" 
                                                variant="outlined" 
                                                className={styles.inputField}  
                                                value={partInput}
                                                onChange={handlePartChange}
                                                autoComplete="no" 
                                                fullWidth
                                                onKeyPress={handlePartChangeKeyPress}
                                            />
                                        </div>
                                        <Button variant="contained" color="primary" onClick={handleAddPart}>Add</Button>
                                    </div>
                                </Grid>


                                <Grid item xs={12}>

                                    {report.parts.length > 0 && 
                                        <React.Fragment>
                                            <Typography variant="subtitle2">Parts</Typography>
                                            <List dense>
                                                {report.parts.map((p, idx) => (
                                                    <ListItem key={idx}>
                                                        <ListItemText primary={p} />
                                                        <IconButton onClick={() => handleRemovePart(idx)} color="secondary"><CloseIcon /></IconButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </React.Fragment>
                                    }

                                </Grid>
                            
                        </Grid>
                        

                    </div>


                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Close</Button>
                <div style={{flex: 1}}></div>
                
                {/* <FormControlLabel
                    control={
                    <Switch
                        checked={closeTicket}
                        onChange={() => setCloseTicket(!closeTicket)}
                        name="closeTicket"
                        color="secondary"
                    />
                    }
                    label="Close ticket"
                /> */}

                <Button variant="contained" color="secondary" disabled={!isValid() || loading} onClick={handleAddReport}>Add Report {loading && <CircularProgress size={24} />}</Button>
            </DialogActions>
        </Dialog>
    )

}

export default TicketVisitReport