import React from 'react';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Menu, MenuItem} from '@material-ui/core'
import SendStatementConfirmDialog from "./awaiting_sendstatement";

const RowMenu = props => {
    const {row, setSchemeRevoke, groupSchemeName, showPaymentDialog, downloadBatchCSV, emailBatchCSV} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button onClick={handleClick}><MoreVertIcon /></Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {!row.Paid && <MenuItem onClick={() => setSchemeRevoke({name:groupSchemeName(row.groupSchemeID, row.Source), id: row.groupSchemeID, currency: row.Currency})}>Revoke</MenuItem>}
                {!row.Paid?<MenuItem onClick={() => {showPaymentDialog(row)}}>Pay</MenuItem>:<MenuItem variant="contained" disabled>PAID</MenuItem>}
                <MenuItem onClick={() => {downloadBatchCSV(row.groupSchemeID, row.Currency, row.Batches)}}>Download Statement</MenuItem> 
                <SendStatementConfirmDialog row={row} emailBatchCSV={emailBatchCSV} />
            </Menu>
        </>
    )
}

export default RowMenu