import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { Typography, List, ListItem, ListItemText, Divider, Fab, Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, TextField, DialogActions, Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Helpers from '../global/helpers';

import { useStyles } from './styles';

const useCustomStyles = makeStyles(theme => ({
    contact: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    contactItem: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        maxWidth: 240,
        alignItems: 'center'
    }
}));

const blankContact = {
    id_ticket_contact_type: null,
    ticket_contact_name: '',
    ticket_contact_email: '',
    ticket_contact_phone: '',
}


const TicketContacts = props => {

    const [newContact, setNewContact] = useState({...blankContact});
    const [showNewContact, setShowNewContact] = useState(false);

    const theme = useTheme();
    const styles = useStyles(theme);
    const customStyles = useCustomStyles(theme);
    const t = props.ticket;

    const handleValueChange = e => {
        const contact = {...newContact};
        contact[e.target.name] = e.target.value;
        setNewContact(contact);
    }

    const addContact = () => {
        if(newContact.ticket_contact_name !== '' && newContact.id_ticket_contact_type !== null)
            props.handleAddContact({
                id_ticket: t.id_ticket,
                id_ticket_contact_type: newContact.id_ticket_contact_type,
                ticket_contact_type_name: props.contactTypes.find(c => c.id_ticket_contact_type === newContact.id_ticket_contact_type).ticket_contact_type_name,
                ticket_contact_name: newContact.ticket_contact_name,
                ticket_contact_email: newContact.ticket_contact_email,
                ticket_contact_phone: newContact.ticket_contact_phone,
            });

        setNewContact({...blankContact});
        setShowNewContact(false);
    }

    const removeContact = (contact) => {
        props.handleRemoveContact(contact);
    }


    return (
        <div>
            <Typography variant="subtitle2">External Notifications</Typography>
            <List>
                {t.contacts.map((c, idx) => (
                    <div key={idx}>
                        <ListItem alignItems="flex-start" style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
                            <ListItemText 
                                primary={(
                                    <React.Fragment>
                                        <Typography component="span">{c.ticket_contact_name}</Typography>
                                        <Typography color="textSecondary" component="span" style={{marginLeft: 10}}>({c.ticket_contact_type_name})</Typography>
                                    </React.Fragment>
                                )}
                                secondary={(
                                <React.Fragment>
                                    {c.ticket_contact_email && <Typography variant="caption" component="div">{c.ticket_contact_email}</Typography>}
                                    {c.ticket_contact_phone && <Typography variant="caption" component="div">{c.ticket_contact_phone}</Typography>}
                                </React.Fragment>
                                )}
                             />

                            {props.editMode && <IconButton color="secondary" onClick={() => removeContact(c)}><CloseIcon fontSize="small" /></IconButton>}
                        </ListItem>
                        
                        {(idx+1) < t.logs.length && <Divider/>}
                    </div>
                ))}

            </List>
            {t.contacts.length === 0 && <Typography variant="body2" color="textSecondary">-- No external notifications {props.editMode && <Button onClick={() => setShowNewContact(true)} color="secondary" aria-label="new contact"><AddIcon /> Add</Button>}</Typography>}
            {(t.contacts.length > 0 && props.editMode) && <Button onClick={() => setShowNewContact(true)} color="secondary" aria-label="new contact"><AddIcon /> Add</Button>}
            


            <Dialog open={showNewContact} onClose={() => setShowNewContact(false)} aria-labelledby="form-dialog-title" maxWidth='xs'>
                <DialogTitle id="form-dialog-title">Add a contact</DialogTitle>
                <DialogContent>
                    <FormControl className={styles.inputField} variant="outlined" fullWidth size="small">
                        <InputLabel id="id_ticket_contact_type_label">Type</InputLabel>
                        <Select
                            labelId="id_ticket_contact_type_label"
                            id="id_ticket_contact_type"
                            name="id_ticket_contact_type"
                            value={newContact.id_ticket_contact_type}
                            onChange={handleValueChange}
                            label="Type"
                        >
                            {props.contactTypes.map((type, idx) => (
                                <MenuItem key={idx} value={type.id_ticket_contact_type}>{type.ticket_contact_type_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField size="small" name="ticket_contact_name" label="Name" variant="outlined" value={newContact.ticket_contact_name} onChange={handleValueChange} className={styles.inputField} fullWidth />

                    <TextField size="small" name="ticket_contact_email" label="Email" variant="outlined" value={newContact.ticket_contact_email} onChange={handleValueChange} className={styles.inputField} fullWidth />

                    <TextField size="small" name="ticket_contact_phone" label="Tel" variant="outlined" value={newContact.ticket_contact_phone} onChange={handleValueChange} className={styles.inputField} fullWidth />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowNewContact(false)} color="default">Cancel</Button>
                    <Button onClick={addContact} variant='contained' color="secondary">Add</Button>
                </DialogActions>
            </Dialog>


        </div>
    )
}

export default TicketContacts
