import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, IconButton, Popover, Paper, Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {
        padding: 20,
        maxHeight: 400,
        overflowY: 'auto'
    },
}));

const OrderControlDocumentUnitsPopover = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'units-popover' : undefined;

    const handleAddUnit = (unit) => {
        if(typeof props.onAdd === 'function')
            props.onAdd(unit);
    }

    const isUnitUsed = (unit) => {
        if(typeof props.units !== 'undefined'){
            if(props.type === 'mode3'){
                let count = 0;
                props.units.forEach(u => {
                    u.sockets.forEach(s => {
                        unit.sockets.forEach(us => {
                            if(s.socket_id === us.socket_id)
                                count++;
                        })
                    })
                })

                if(count >= unit.sockets.length)
                    return true;

            } else {
                const existingUnit = props.units.findIndex(u => u.subscription_socket.socket_id === unit.subscription_socket.socket_id);
                if(existingUnit > -1)
                    return true;
            }
        }

        return false;
    }

    return (
        <React.Fragment>
            <IconButton aria-describedby={id} variant="contained" color="secondary" onClick={handleClick}><ListIcon /></IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {typeof props.unsanitizedUnits !== 'undefined' &&

                    <div className={classes.inner}>

                        <Typography variant="subtitle2">Available Units</Typography>
                        
                        {props.unsanitizedUnits.map((unit, idx) => (

                            <div className="flex-row gap-10 mt" key={idx}>

                                <div>{isUnitUsed(unit) ? <CheckCircleIcon color="primary" /> : <IconButton size="small" onClick={() => handleAddUnit(unit)}><CheckCircleOutlinedIcon style={{color: '#d2d2d2'}} /></IconButton>}</div>

                                <Paper variant="outlined">
                                    
                                    <Table  size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Socket</TableCell>
                                                <TableCell>Output</TableCell>
                                                <TableCell>Socket ID</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {unit.sockets.map((s, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell>{s.socket_no}</TableCell>
                                                    <TableCell>{s.socket_ouput}</TableCell>
                                                    <TableCell>{s.socket_id}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </Paper>

                            </div>

                        ))}

                    </div>

                }
            </Popover>
        </React.Fragment>
    )
}
export default OrderControlDocumentUnitsPopover;