import { Typography, Dialog, DialogContent, DialogActions, Button, TextField, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import globalContext from '../../../context/global-context';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../../../components/progressButton';

const TestCertificate = props => {

  const context = useContext(globalContext);
  const {show, handleSuccess, handleCancel} = props;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState(null);
  const [orderContacts, setOrderContacts] = useState([]);
  const [fields, setFields] = useState({
    tested_by: '',
    tested_by_company: ''
  })

  const selectContactName = (e, newValue) => {
    let newFields = {...fields};
    if(newValue === null)
      newFields.tested_by = '';
    else {
      newFields.tested_by = newValue;
      const matchingContact = orderContacts.find(c => c.contact_name === newValue);
      newFields.tested_by_company = typeof matchingContact !== 'undefined' ? matchingContact.organisation_name : newFields.tested_by_company;
    }
    setFields(newFields);
  }

  const selectContactCompany = (e, newValue) => {
    let newFields = {...fields};
    newFields.tested_by_company = newValue === null ? '' : newValue;
    setFields(newFields);
  }

  const getContacts = () => {

      context.fetchOrderContacts(order.id_order).then(
          res => {
            setOrderContacts(res.result);
          }, 
          err => {
              context.showAlert('error', 'error fetching contacts: ' + JSON.stringify(err.msg));
          }
      )
      
  }

  const handleSubmit =  async () => {

    if(fields.tested_by === '' || fields.tested_by_company === '')
      setError('Please enter a name for tested by and the company name of the tester')
    else {

        setError(null);
        setLoading(true);

        try {
            const body = {
                id_order: props.order.id_order,
                points: props.points,
                ...fields
            }
            const filename = `VendElectric Test Certificate_VE${props.order.id_order}.pdf`
            await context.apiFileGenerate(`/commission/test_cert`, filename, 'POST', body).then(
                res => {
                    if(typeof props.handleSuccess === 'function')
                        props.handleSuccess();
                },
                err => {
                    console.log(err);
                    throw err;
                }
            )

        } catch (error) {
          console.log('error');
          setError(typeof error === 'string' ? error : 'ERR');
        } finally { 
          setLoading(false);
        }

    }
  }

  useEffect(() => {

    if(typeof props.order !== 'undefined' && props.order !== null)
      setOrder(props.order);

  }, [props.order])

  useEffect(() => {

    if(typeof order !== 'undefined' && order !== null && typeof order.id_order !== 'undefined')
      getContacts();

  }, [order])

  useEffect(() => {

    console.log(props.points)

  }, [props.points])
  
  return (
      <Dialog open={show} onClose={handleCancel} aria-labelledby="delete-socket-dialog-title">
          <DialogContent>
              <Typography variant="h6" style={{marginBottom: 10}}>Generate Test Certificate</Typography>
              <Typography variant="subheading">Once generated, the test certificate will also be attached to the IMS order for download.</Typography>

              {error && <Alert severity='error' style={{marginTop: 10}}>{error}</Alert>}

              {order && 
                <div className="pt pb">
                    <Typography variant="h6">#VE{order.id_order} - {order.order_name}</Typography>
                    <Typography variant="body1" color="textSecondary">Organisation: {order.organisation_name}</Typography>
                    
                </div>
              }

              <Grid container className="pt" spacing={2}>
                <Grid item md={6}>
                  <Autocomplete
                      id="tested_by"
                      freeSolo
                      onChange={selectContactName}
                      value={fields.tested_by}
                      options={orderContacts.map(option => option.contact_name)}
                      autoHighlight
                      renderInput={
                        params => <TextField 
                          {...params} 
                          label="Test carried out by" 
                          name="tested_by"
                          variant="filled"
                          fullWidth 
                          InputLabelProps={{shrink: true}}
                        />
                      }
                  />
                </Grid>

                <Grid item md={6}>
                  
                  <Autocomplete
                        id="tested_by_company"
                        freeSolo
                        onChange={selectContactCompany}
                        value={fields.tested_by_company}
                        options={orderContacts.map(option => option.organisation_name)}
                        autoHighlight
                        renderInput={
                          params => <TextField 
                            {...params} 
                            label="Company name" 
                            name="tested_by_company"
                            variant="filled"
                            fullWidth 
                            InputLabelProps={{shrink: true}}
                          />
                        }
                    />

                </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCancel} color="default">Cancel</Button>
              <ProgressButton waiting={loading} onClick={handleSubmit} color="secondary">Create</ProgressButton>
          </DialogActions>
      </Dialog>
  )

}

export default TestCertificate;