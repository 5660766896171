import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles } from '@material-ui/core';
import OrderControlDocumentUnit from './order_control_document_unit';
import globalContext from '../../context/global-context';
import Alert from '@material-ui/lab/Alert';
import OrderControlDocumentUnitsPopover from './order_control_document_units_popover';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const OrderControlDocumentUnits = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);

    const [unsanitizedUnits, setUnsanitizedUnits] = useState([]);

    useEffect(() => {

        if(props.id_order !== null && props.type !== null){
            let controller_type = 2;
            switch(props.type){
                case 'mode3':
                    controller_type = 1;
                    break;
                case 'delta':
                    controller_type = 3;
                    break;
            }
            context.apiRequest(`/subscription/socket/generateControlDocUnits/${props.id_order}/${controller_type}`, 'GET').then(
                res => {
                    setUnsanitizedUnits(res.result);
                    if(props.existing === false)
                        sanitizeUnits(res.result);
                },
                err => {
                    console.log(err);
                }
            )
        }

    }, [props.type])

    const sanitizeUnits = (arr) => {
        let sanitized = [];
        arr.map((u, idx) => {
            u.unit_no = (idx+1);
            for(let i=0;i<u.sockets.length;i++){
                u.sockets[i].socket_no = (i+1);
                if(typeof u.sockets[i].unit_rol_no === 'undefined' && typeof u.unit_rol_no !== 'undefined')
                    u.sockets[i].unit_rol_no = u.unit_rol_no;
            }
            sanitized.push({
                ...u,
                unit_serial_no: u.existing_serial ? u.unit_serial_no : `${props.mi_no}/${u.unit_no}`
            })
        })
        props.setDocUnits(sanitized);
    }

    useEffect(() => {
        if(props.mi_no !== 'undefined' && props.mi_no !== null)
            sanitizeUnits(props.units);
    }, [props.mi_no])


    const handleUpdateUnit = (idx, field, value) => {

        let newUnits = JSON.parse(JSON.stringify(props.units));
        newUnits[idx][field] = value;

        props.setDocUnits(newUnits);
    }

    const handleUpdateUnitSocket = (idx, field, value, socketIdx) => {

        let newUnits = JSON.parse(JSON.stringify(props.units));
        newUnits[idx].sockets[socketIdx][field] = value;

        props.setDocUnits(newUnits);

    }


    const handleUpdateAllUnits = (field, value) => {

        let newUnits = JSON.parse(JSON.stringify(props.units));
        for(let i=0;i<newUnits.length;i++){
            newUnits[i][field] = value;
        }

        props.setDocUnits(newUnits);

    }

    const handleUpdateAllUnitSockets = (field, value) => {

        let newUnits = JSON.parse(JSON.stringify(props.units));
        
        for(let i=0;i<newUnits.length;i++){

            for(let j=0;j<newUnits[i].sockets.length;j++){
                newUnits[i].sockets[j][field] = value;
            }
        }

        props.setDocUnits(newUnits);

    }

    const checkSerialNumberExists = (serial_no) => {
        return (props.units.filter(u => u.unit_serial_no === serial_no).length > 1)
    }


    const handleAddUnit = (unit) => {

        let newUnits = JSON.parse(JSON.stringify(props.units));
        newUnits.push(unit);

        // The unit numbers and serial numbers will be knocked out
        // So resanitize the units
        sanitizeUnits(newUnits);

    }


    const handleRemoveUnit = (idx) => {

        let newUnits = JSON.parse(JSON.stringify(props.units));
        newUnits.splice(idx, 1);

        // The unit numbers and serial numbers will be knocked out
        // So resanitize the units
        sanitizeUnits(newUnits);

    }


    const handleSplitUnit = (idx) => {

        // Get a copy of the units
        let newUnits = JSON.parse(JSON.stringify(props.units));
        // Grab the unit we are splitting
        let splitUnit = JSON.parse(JSON.stringify(newUnits[idx]));
        // Remove the unit being split from the array
        newUnits.splice(idx, 1);

        //  Loop through the sockets and push the elements
        //  into the array before what was the next unit in
        //  the list
        for(let i=0;i<splitUnit.sockets.length;i++){
            newUnits.splice((idx+i), 0, 
                {
                    ...splitUnit,
                    sockets: [splitUnit.sockets[i]]
                }
            )
        }

        // The unit numbers and serial numbers will be knocked out
        // So resanitize the units
        sanitizeUnits(newUnits);
        
    }

    const handleUnitsOrder = (src, dest, unit) => {
        const newUnits = JSON.parse(JSON.stringify(props.units));
        newUnits.splice(src, 1);
        newUnits.splice(dest, 0, unit);
        sanitizeUnits(newUnits);
    }

    const handleDragEnd = result => {
        const { destination, source, draggableId } = result;

        if(!destination)
            return;

        if(destination.droppableId === source.droppableId && destination.index === source.index)
            return;

        const unit = JSON.parse(JSON.stringify(props.units[source.index]));

        handleUnitsOrder(source.index, destination.index, unit)
    }

    return (
        <div className="pb pt">
            <div className="flex-row align-center space-between">
                <Typography variant="h6">Units</Typography>
                <OrderControlDocumentUnitsPopover unsanitizedUnits={unsanitizedUnits} units={props.units} onAdd={handleAddUnit} type={props.type} />
            </div>

            {props.units.length === 0 && <Alert severity="info">No units available, try selecting another unit type.</Alert>}

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={'unit-sort'}>
                    {provided => (
                        <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        >
                            {props.units.map((unit, idx) => (
                                <Draggable key={idx} draggableId={`unit-${idx}`} index={idx}>
                                    {provided=>(
                                        <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef} 
                                        >
                                        <OrderControlDocumentUnit 
                                            unit={unit} 
                                            index={idx} 
                                            onUpdate={handleUpdateUnit} 
                                            onUpdateSocket={handleUpdateUnitSocket} 
                                            onUpdateAll={handleUpdateAllUnits} 
                                            onUpdateAllSockets={handleUpdateAllUnitSockets} 
                                            onRemove={handleRemoveUnit}
                                            onSplit={handleSplitUnit}
                                            type={props.type}
                                            serialNumberExists={checkSerialNumberExists}
                                        />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </div>
    )
}
export default OrderControlDocumentUnits;