import React, {useContext, useState} from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import ProgressButton from '../../components/progressButton';
import { Alert } from '@material-ui/lab';
import globalContext from '../../context/global-context';


const AwaitingRevoke = props => {

    const context = useContext(globalContext);

    const {scheme} = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleRevoke = async () => {
        setLoading(true);
        setError(null);

        try {
            
            const res = await context.apiRequest(`/statements/revoke`, 'POST', {id_group_scheme: scheme.id, currency: scheme.currency});
            if(res.success){
                setSuccess(true);

                if(typeof props.onSuccess === 'function')
                    props.onSuccess();
            } else
                throw res.msg;
            
        } catch (err) {
            setError(typeof err === 'string' ? err : typeof err.msg !== 'undefined' ? err.msg : JSON.stringify(err));
        } finally {
            setLoading(false);
        }
        
    }

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    return (
        <React.Fragment>
            <Dialog open={true} onClose={handleClose}>
                <DialogTitle>Revoke Statment for {scheme.name}</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">Confirm you wish to revoke that statement for {scheme.name} and put these sessions back into pending.</Typography>
                    {error && <Alert severity='error'>{error}</Alert>}
                    {success && <Alert severity='success'>Statement revoked</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{success ? 'Close' : 'Cancel'}</Button>
                    {!success && <ProgressButton waiting={loading} onClick={handleRevoke} variant="contained" color="secondary">Confirm</ProgressButton>}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default AwaitingRevoke;