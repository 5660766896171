import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';

import { CircularProgress, Collapse, Button, Modal, Paper, Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Typography, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import AddOrderContact from './add_contact';
import DeleteOrderContact from './delete_contact';


const OrderContacts = props => {

    const reloadCount = typeof props.reloadCount != 'undefined'?props.reloadCount:[];

    // Create a local state
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [id_order, setOrder] = useState(typeof props.id_order!='undefined'?props.id_order:null);
    const [showAdd, setShowAdd] = useState(false);
    const [removeContact, setRemoveContact] = useState(null);


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    useEffect(() => {
        console.log('getting contacts');
        getContacts();
    }, reloadCount)

    const getContacts = () => {
        setLoading(true);

        context.fetchOrderContacts(id_order).then(
            res => {
                setContacts(res.result);
                setRemoveContact(null);
                setShowAdd(false);
                setLoading(false);
            }, 
            err => {
                setLoading(false);
                context.showAlert('error', 'error fetching contacts: ' + JSON.stringify(err.msg));
            }
        )
    }

        
    return (

        <div style={{display: 'flex', flexDirection: 'column', padding: '10px 0', flex:1}}>
            
            <Typography variant="h6">Contacts</Typography>

            {contacts.length > 0 &&
            <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Organisation</TableCell>
                        <TableCell align="right">Position</TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">Phone</TableCell>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {contacts.map(row => (
                        <TableRow key={row.id_order_contact}>
                            <TableCell component="th" scope="row">{row.contact_name}</TableCell>
                            <TableCell align="right">{row.organisation_name}</TableCell>
                            <TableCell align="right">{row.contact_position}</TableCell>
                            <TableCell align="right">{row.contact_email}</TableCell>
                            <TableCell align="right">{row.contact_phone}</TableCell>
                            <TableCell align="right">{row.organisation_type_name}</TableCell>
                            <TableCell align="right"><CloseIcon style={{cursor: 'pointer'}} color="secondary" onClick={()=>{setRemoveContact(row)}} aria-label="remove" /></TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>}

        
            {contacts.length == 0 &&
                <Typography variant="h6" style={{color: 'rgba(0,0,0,.3)'}}>None</Typography>
            }
            
            <div style={{display: 'flex', flexDirection: 'row', padding: '10px 0', justifyContent: 'flex-end'}}>
                <Fab onClick={()=>{setShowAdd(true)}} color="secondary" size="medium" aria-label="add">
                    <AddIcon />
                </Fab>
            </div>
            
            <Modal open={showAdd} onClose={() => {setShowAdd(false)}}>
                <Paper style={{position: 'absolute', width: '90%', maxWidth: 500, left: '50%', top: '50%', transform: `translate(-50%, -50%)`}}>
                    <AddOrderContact id_order={id_order} onAdd={getContacts} />
                </Paper>
            </Modal>
            
            {removeContact != null && <Modal open={removeContact != null} onClose={() => {setRemoveContact(null)}}>
                <Paper style={{position: 'absolute', width: '90%', maxWidth: 400, left: '50%', top: '50%', transform: `translate(-50%, -50%)`}}>
                    <DeleteOrderContact id_order_contact={removeContact.id_order_contact} name={removeContact.contact_name} onDelete={getContacts} onCancel={() => {setRemoveContact(null)}}/>
                </Paper>
            </Modal>}

        </div>
    );
}

export default OrderContacts