/*
*
*
*   This is a helper class for sending REST API requests from one place
*   It reads the Redux state from the store in order to attach a bearer 
*   token to the request.
*
*
*/

import store from './store';

class API {

    
    constructor(){}

    static _ENDPOINT = (process.env.NODE_ENV == 'development'?'http://localhost:8080':'')+'/api/v1.4/';

    static req = (uri = null, method = null, body = null) => {
      return new Promise((resolve, reject) => {
        if(this._isUserValid())
          fetch(this._ENDPOINT + uri, {
            method: method,
            headers: this._withBearer({
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }),
            body: body != null ? JSON.stringify(body) : null,
          })
          .then((res) => res.json())
          .then((resJson) => {
            if(resJson.success == true){
              resolve(resJson);
            } else {
              reject(resJson);
            }
          })
          .catch((error) => {
            reject(error);
          });
        else
          reject('Not logged in');
      });
    }

    static _withBearer = (headers = {}) => {
        const state = store.getState();
        if(state.user.token != null)
            headers['x-access-token'] = state.user.token
        return headers;
    }

    static _isUserValid = () => {
      const state = store.getState();
      if(state.user.expires !== null && new Date() > new Date(state.user.expires)){
        return false;
      } else 
        return true;
    }


}
export default API;