import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../../styles';

import { Button, Typography } from '@material-ui/core';

import Crud from '../../crud';
import Quickcrumbs from '../../../global/Quickcrumbs';
import AddUser from './add';


const Users = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    const [ showNew, setShowNew ] = useState(false);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Users',
                            route: '/settings/users'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">System Users</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>Update user information and permissions.</Typography>

                <Crud
                    uri='system/users'
                    idCol='id_user'
                    displayColumns={[
                        { 
                            name: 'name', 
                            type: 'string',
                            editable: false
                        },
                        { 
                            name: 'username', 
                            type: 'string',
                            editable: false
                        },
                        { 
                            name: 'role', 
                            display_name: 'System Admin',
                            type: 'boolean',
                            editable: false
                        },
                        { 
                            name: 'last_login', 
                            type: 'datetime',
                            editable: false
                        },
                        { 
                            name: 'active', 
                            display_name: 'Active',
                            type: 'boolean',
                            editable: true
                        }
                    ]}
                    readOnly={true}
                    clickRow={`/settings/users/!id_user`}
                />

                <div>
                    <Button variant="contained" color="primary" onClick={() => setShowNew(true)}>Add new</Button>
                </div>
                
            </div>

            {showNew && <AddUser open={true} handleClose={() => setShowNew(false)} />}

        </div>
    )

}

export default Users
