
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import EvStationIcon from '@material-ui/icons/EvStation';

const EvCircularProgess = props => {

    const {size, color} = props
    
    return (
        <div style={{position: 'relative', width: size, height: size}}>
            <CircularProgress color={color} size={size} style={{position: 'absolute', top: 0, left: 0}} />
            <EvStationIcon style={{ fontSize: size*.5, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
        </div>
    )
}

export default EvCircularProgess;