import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React, {useContext, useState} from "react"
import Alert from '@material-ui/lab/Alert'
import globalContext from "../../../context/global-context";

const BatchUnpaid = props => {

  const {id_statement} = props;
  const context = useContext(globalContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async () => {

    setLoading(true);
    setError(null);
    try {

      
      context.apiRequest(`/ocpi_statements/paid`, 'DELETE', {id_statement: id_statement}).then(
        res => {
          setLoading(false);
          setShow(false);

          if(typeof props.onSuccess === 'function')
            props.onSuccess();
        },
        err => {
          setLoading(false);
          setError(typeof err.msg !== 'undefined' ? err.msg : typeof err === 'string' ? err : JSON.stringify(err))
        }
      );
      
    } catch (error) {
      setLoading(false);
      setError(typeof error === 'string' ? error : JSON.stringify(error))
    }

  }


  return (
    <React.Fragment>
      <Button variant="contained" color="secondary" onClick={() => setShow(true)} disabled={loading}>{`Mark Unpaid`}</Button>

      <Dialog open={show} fullWidth maxWidth="xs" onClose={() => setShow(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Confirm you wish to un-mark this batch as paid.</Typography>
          {error && <Alert severity="error" style={{marginBottom: 10}}>{error}</Alert>}
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: '10px 0', alignItems: 'center'}}>
            {loading && <CircularProgress size={32} color="secondary" />}
            {!loading && <Button variant="contained" color="secondary" onClick={handleSubmit}>Confirm</Button>}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )

}

export default BatchUnpaid