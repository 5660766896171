import React from 'react';
import { Dialog, DialogContent, Box, Typography, IconButton, List, ListItem } from '@material-ui/core';
import Helpers from '../../../global/helpers';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';


const ProductionChargepointConfiguration = props => {

    const handleClose = () => {
        if(typeof props.onClose === 'function')
            props.onClose();
    }

    return (
        <React.Fragment>
            <Dialog open={true} fullWidth maxWidth="sm" onClose={ handleClose}>
                <DialogContent>
                    <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h5" style={{display: 'flex', alignItems: 'center', gap: 15}}><InfoIcon/> Production Unit Log</Typography>
                        <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                    </Box>
                    <Typography variant="body2" color="textSecondary">Configured: {Helpers.toLocaleDateTime(props.log.date_configured)}</Typography>

                    <Box style={{marginTop: 30, paddingBottom: 20}}>
                        <Typography variant="h6">Configuration</Typography>
                        <List dense style={{width: '100%'}}>
                            <pre>{JSON.stringify(JSON.parse(props.log.product_data), undefined, 2)}</pre>
                        </List>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}
export default ProductionChargepointConfiguration;