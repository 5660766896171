import React from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from './styles';
import { Grid, Typography, List, ListItem, Paper } from '@material-ui/core';


// Reporting class
const Subscriptions = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    const routeTo = route => {
        props.history.push(`/subscriptions/${route}`)
    }

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                

                <div className={styles.taskbar}>

                    <Typography variant="h4">Subscriptions</Typography>

                </div>
            
                <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={4} md={3}>
                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('renewals')}>Renewals</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    
                </Grid>
            </div>
        </div>
    )

}

export default Subscriptions
