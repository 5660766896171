import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useTheme } from '@material-ui/styles';
import { useStyles } from './styles';

import { createStore } from 'redux';
import { Grid, Typography, List, ListItem, Paper, Divider } from '@material-ui/core';


// Settings class
const Settings = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    useEffect(() => {
    }, [])

    const routeTo = route => {
        props.history.push(`/settings/${route}`)
    }

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                

                <div className={styles.taskbar}>

                    <Typography variant="h4">Settings</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>Be careful with settings, in here you can control meta data, accessibility and the system. Seek guidance from your system admin if unsure.</Typography>


                <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography variant="h6">Tickets</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('ticket_units')}>Units</ListItem>
                                <ListItem button onClick={() => routeTo('ticket_unit_users')}>Unit Users</ListItem>
                                <ListItem button onClick={() => routeTo('ticket_unit_groups')}>Unit Groups</ListItem>
                                <ListItem button onClick={() => routeTo('ticket_unit_group_users')}>Unit Group Supervisors</ListItem>
                            </List>
                        </Paper>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('ticket_types')}>Types</ListItem>
                                <ListItem button onClick={() => routeTo('ticket_statuses')}>Statuses</ListItem>
                                <ListItem button onClick={() => routeTo('ticket_priorities')}>Priorities</ListItem>
                                <ListItem button onClick={() => routeTo('ticket_levels')}>Levels</ListItem>
                                <ListItem button onClick={() => routeTo('ticket_contact_types')}>Contact Types</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography variant="h6">System</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('users')}>Users</ListItem>
                                <ListItem button onClick={() => routeTo('networks')}>Networks</ListItem>
                                <ListItem button onClick={() => routeTo('system_sectors')}>System Sectors</ListItem>
                                <ListItem button onClick={() => routeTo('products')}>Products</ListItem>
                                <ListItem button onClick={() => routeTo('currencies')}>Currencies</ListItem>
                            </List>
                        </Paper>

                        <Typography variant="h6">Socket Config</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('gprs_networks')}>GPRS Networks</ListItem>
                                <ListItem button onClick={() => routeTo('controller_types')}>Controller Types</ListItem>
                                <ListItem button onClick={() => routeTo('controller_settings')}>Controller Settings</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography variant="h6">Emnify</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('sim_profiles')}>SIM Profiles</ListItem>
                                <ListItem button onClick={() => routeTo('tariff_profiles')}>Tariff Profiles</ListItem>
                            </List>
                        </Paper>

                        <Typography variant="h6">Firmware</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('firmware')}>OCPP Firmware Updates</ListItem>
                            </List>
                        </Paper>

                        <Typography variant="h6">OCPI</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('ocpi_tariffs')}>Tariffs</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    
                </Grid>
            </div>
        </div>
    )

}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
