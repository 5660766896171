import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles } from '@material-ui/core';
import { useStyles } from '../styles';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';

const TariffProfiles = props => {
    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <React.Fragment>
            <div className={styles.root}>
                <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Tariff Profiles',
                            route: '/settings/tariff_profiles'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Tariff Profiles</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>A list of available tariff profiles for Emnify.</Typography>

                <Crud
                    uri='devices/tariff_profiles'
                    idCol='id'
                    readOnly={true}
                    displayColumns={[
                        { 
                            name: 'id', 
                            type: 'string',
                            display_name: 'ID'
                        }, 
                        { 
                            name: 'name', 
                            type: 'string'
                        }, 
                        {
                            name: 'used_count', 
                            type: 'number',
                            display_name: 'Devices'
                        }
                    ]}
                />

                </div>
            </div>
        </React.Fragment>
    )
}
export default TariffProfiles;