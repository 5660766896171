import API from '../api';

export function ticketsGet () {
    return dispatch => {
        // Loading
        dispatch({
            type: 'TICKETS_GET',
            payload: null,
            status: 'loading'
        })

        // Fetch the tickets
        API.req('tickets', 'GET').then(
            res => {
                dispatch({
                    type: 'TICKETS_GET',
                    payload: res.result,
                    status: 'ok'
                })
            },
            err => {
                // Error loading the tickets
                console.log(err);
                dispatch({
                    type: 'TICKETS_GET',
                    payload: [],
                    status: 'error'
                })
            }
        )
    }
}

export function ticketsGot (payload) {
    return dispatch => {
        // Loading
        dispatch({
            type: 'TICKETS_GET',
            payload: payload,
            status: 'ok'
        })
    }
}

export function ticketMeta () {
    return dispatch => {
        // Loading
        dispatch({
            type: 'TICKET_META',
            payload: null,
            status: 'loading'
        })


        // Fetch the tickets
        API.req('tickets_meta', 'GET').then(
            res => {
                dispatch({
                    type: 'TICKET_META',
                    payload: res.result,
                    status: 'ok'
                })
            },
            err => {
                // Error loading the tickets
                console.log(err);
                dispatch({
                    type: 'TICKET_META',
                    payload: [],
                    status: 'error'
                })
            }
        )

    }
}

export function ticketOpen (ticket) {
    return dispatch => {

        dispatch({
            type: 'TICKET_OPEN',
            payload: ticket
        })

    }
}

export function ticketAdd (ticket) {
    return dispatch => {

        dispatch({
            type: 'TICKET_ADD',
            payload: ticket
        })

    }
}

export function ticketDiscard (ticket) {
    return dispatch => {
        // Loading
        dispatch({
            type: 'TICKET_DISCARD',
            payload: ticket
        })
    }
}

export function ticketUpdate (ticket) {
    return dispatch => {

        dispatch({
            type: 'TICKET_UPDATE',
            payload: ticket
        })

    }
}

export function ticketAddComment (comment) {
    return dispatch => {

        // Loading
        dispatch({
            type: 'TICKET_ADDCOMMENT',
            payload: comment
        })

    }
}

export function ticketAddContact (contact) {
    return dispatch => {

        // Loading
        dispatch({
            type: 'TICKET_ADDCONTACT',
            payload: contact
        })


    }
}

export function ticketRemoveContact (contact) {
    return dispatch => {

        // Loading
        dispatch({
            type: 'TICKET_DELETECONTACT',
            payload: contact
        })


    }
}

export function ticketDirty (dirty) {
    return dispatch => {
        // Loading
        dispatch({
            type: 'TICKET_DIRTY',
            payload: dirty
        })
    }
}