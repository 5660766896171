import React, { useContext, useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import Helpers from '../../global/helpers';
import GlobalContext from '../../../context/global-context';
import ActivityIndicator from '../../global/ActivityIndicator';
import { Table, TablePagination, TableBody, TableCell, TableHead, TableRow, Paper, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import Quickcrumbs from '../../global/Quickcrumbs';
import BatchInvoice from './batch_invoice';
import BatchUninvoice from './batch_uninvoice';
import BatchPaid from './batch_paid';
import BatchUnpaid from './batch_unpaid';
import BatchDropLine from './batch_delete_line';


const OcpiBatch  = props => {

    const context = useContext(GlobalContext);

    const id_statement = props.match.params.id;
    const [status, setStatus] = useState('PENDING');
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState(null);
    const headers = [
        'id_batch', 
        'gm_point_id', 
        'group_scheme_id', 
        'group_scheme_name', 
        'id_cdr', 
        'start_date_time', 
        'stop_date_time', 
        'currency', 
        'total_cost', 
        'total_energy', 
        'total_time', 
        'invoiced', 
        'invoice_paid', 
        'paid_to_host'];
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [page, setPage] = useState(0);



    const loadData = () => {
        setLoading(false);
        setData([]);

        context.apiRequest(`/ocpi_statements/${id_statement}`, 'GET')
        .then(
            res => {
                setData(res.result.lines);
                setSummary(res.result.summary);
                setLoading(false);
            },
            err => {
                console.log(err);
                setLoading(false);
            }
        );
    }

    

    const downloadExcel = async () => {
        setDownloading(true);
  
        try {
  
            await context.apiFileDownload(`/ocpi_statements/${id_statement}/download`, `OCPI_statement_${id_statement}_${new Date().getTime()}.xlsx`);
  
        } catch (error) {
  
            console.log(error);

        } finally {
          setDownloading(false);
        }
  
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    useEffect(loadData, [])

    useEffect(() => {

        if(summary !== null){
            let newStatus  = 'PENDING';
            if(summary.invoice_no && !summary.paid)
                newStatus = 'INVOICED';
            else if(summary.invoice_no && summary.paid)
                newStatus = 'PAID';

            setStatus(newStatus)
        }


    }, [summary])

    return(
        <div style={styles.container}>
            <div style={styles.inner}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Statements',
                            route: '/statements'
                        },
                        {
                            name: 'VE',
                            route: '/statements/ve'
                        },
                        {
                            name: 'OCPI',
                            route: '/statements/ve/ocpi'
                        },
                        {
                            name: 'Batch',
                            route: `/statements/ve/ocpi/batch/${id_statement}`
                        }
                    ]}
                    history={props.history}
                />

                <div style={styles.taskbar}>

                    <h2>OCPI Batch</h2>

                    <div style={{display: 'flex', flexDirection: 'row', gap: '15px'}}>
                        <Button variant="contained" color="secondary" onClick={loadData}>{!loading && <RefreshIcon />}{loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
                        <Button variant="contained" color="secondary" onClick={downloadExcel}>Download to .xlsx{downloading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
                    </div>

                </div>

                {summary && 
                    <div style={styles.taskbar}>
                        <div style={{display: 'flex', flexDirection: 'row', gap: '15px'}}>
                            {status === 'PENDING' && <BatchInvoice id_statement={id_statement} onSuccess={loadData} />}
                            {status === 'INVOICED' && <BatchPaid id_statement={id_statement} onSuccess={loadData} /> }
                            {status === 'INVOICED' && <BatchUninvoice id_statement={id_statement} onSuccess={loadData} /> }
                            {status === 'PAID' && <BatchUnpaid id_statement={id_statement} onSuccess={loadData} /> }
                        </div>

                        <Typography variant='subtitle1'>Status: {status === 'PAID' ? `Paid` : status === 'INVOICED' ? `Invoiced` : `Pending Invoice`}{summary.invoice_no && ` - #${summary.invoice_no}`}</Typography>
                    </div>
                }

                {(data.length > 0 && headers.length > 0) &&
                    <Paper style={styles.root}>
                        <Table style={styles.table}>
                            <TableHead>
                            <TableRow>
                                {headers.map((h, idx) => <TableCell key={idx}>{Helpers.cleanHeader(h)}</TableCell>)}
                                <TableCell></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                                <TableRow key={idx}>
                                    {headers.map((h, hIdx) => <TableCell key={hIdx}>{row[h]}</TableCell>)}
                                    <TableCell>{row.invoiced === null && <BatchDropLine id_statement_line={row.id_statement_line} onSuccess={loadData}/>}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                }

                {data.length == 0 && <div style={styles.nodata}>No Data</div>}
                

            </div>
        </div>
    );

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default OcpiBatch