import React, {useContext, useState, useEffect, useRef} from 'react';
import { useTheme, Typography, makeStyles, FormControl, IconButton, Dialog, DialogContent, DialogTitle, Popover, TextField, DialogActions, Button, CircularProgress, InputLabel } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import globalContext from '../../context/global-context';

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
        minHeight: 58
    },
    actions: {
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '10px'
    },
}));


var sageOrgAbortController;

const OrganisationLinkedSageAccount = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);


    /**
     * DISPLAY ACCOUNT METHODS AND PROPERTIES
     */

    const [sageAccount, setSageAccount] = useState(null);
    const [sageAccountLoading, setSageAccountLoading] = useState(false);
    
    useEffect(() => {

        const fetchAccount = async () => {

            try {

                if(props.id_sage_customer !== null){
                    setSageAccountLoading(true);               
    
                    const res = await context.apiRequest(`/sage/account?id=${props.id_sage_customer}`, 'GET');
                    
                    if(res.success)
                        setSageAccount(res.result);
                    else 
                        setSageAccount([]);

                } else {

                }


            } catch (error) {
                console.log(error);
            } finally {
                setSageAccountLoading(false);
            }


        }
        fetchAccount();

    }, [props.id_sage_customer])

    useEffect(() => {
        if(sageAccount !== null && typeof props.onAccountLoad === 'function')
            props.onAccountLoad(sageAccount);
    }, [sageAccount])





    /**
     * EDIT ACCOUNT METHOD AND PROPERTIES
     */
    
    const [showEdit, setShowEdit] = useState(false);
    const [search, setSearch] = useState('');
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [sageAccounts, setSageAccounts] = useState([]);
    const [sageFilteredAccounts, setSageFilteredAccounts] = useState([]);
    const [sageAccountsLoading, setSageAccountsLoading] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const anchorEl = useRef(null);

    const handleSearchChange = e => {
        setSearch(e.target.value);

        if(e.target.value.length > 0){
            if(!showAutocomplete)
                setShowAutocomplete(true);
        } else
            setShowAutocomplete(false);
    }

    useEffect(() => {
        if(search.length > 0)
            filterAccounts();
    }, [search])


    const filterAccounts = () => {

        let filteredAccounts = [];
        if(search !== '' && search.length > 1)
            filteredAccounts = sageAccounts.filter(a => a.value.toUpperCase().indexOf(search.toUpperCase()) > -1)
        
        setSageFilteredAccounts(filteredAccounts);

    }
        
    
    const fetchAccounts = async () => {
        if(showEdit){
            try {

                setSageAccountsLoading(true);

                if(typeof sageOrgAbortController !== 'undefined')
                    sageOrgAbortController.abort();
                
                
                sageOrgAbortController = new AbortController();
                const res = await context.apiRequest(`/sage/accounts`, 'GET', null, sageOrgAbortController.signal);
                
                if(res.success && res.result.length > 0){
                    let sAcc = [];
                    res.result.forEach(a => {
                        const acc = {
                            key: a.SLCustomerAccountID,
                            no: a.CustomerAccountNumber,
                            value: `${a.CustomerAccountNumber} - ${a.CustomerAccountName}`
                        };
                        sAcc.push(acc)
                        if(props.id_sage_customer !== null && props.id_sage_customer.toString() === a.SLCustomerAccountID.toString())
                            setSelectedAccount(acc)
                    });
                    setSageAccounts(sAcc);
                } else 
                    setSageAccounts([]);


            } catch (error) {
                console.log(error);
            } finally {
                setSageAccountsLoading(false);
            }

        }
    }

    useEffect(() => {
        fetchAccounts();
    }, [showEdit])


    const handleChange = () => {
        if(typeof props.onChange === 'function')
            if(selectedAccount !== null)
                props.onChange(selectedAccount.key, selectedAccount.no);
            else
                props.onChange(null, null);

        setShowEdit(false);
    }

    const selectAccount = account => {
        setSelectedAccount(account);
        setSearch('');
    }

    return (
        <React.Fragment>
            <FormControl fullWidth>
                <InputLabel shrink={true}>Sage 200 Customer Account</InputLabel>
                <div className={classes.container}>
                    {!props.sage_customer_account_number && <Typography color="textSecondary">No linked account</Typography>}
                    {props.sage_customer_account_number && <Typography>{props.sage_customer_account_number} - {sageAccount && sageAccount.CustomerAccountName}</Typography>}
                    {sageAccountLoading && <CircularProgress size={24} />}
                    {props.edit && <IconButton color="secondary" onClick={() => setShowEdit(true)}><EditIcon /></IconButton>}
                </div>
            </FormControl>

            <Dialog open={showEdit} onClose={() => setShowEdit(false)}>
                <DialogTitle>Search Sage 200 Accounts</DialogTitle>
                <DialogContent>

                    {selectedAccount !== null && 
                        <div className={classes.container}>
                            <Typography>{selectedAccount.value}</Typography>
                            <IconButton color="secondary" onClick={() => setSelectedAccount(null)}><CloseIcon /></IconButton>
                        </div>
                    }
                    {selectedAccount === null && 
                        <React.Fragment>
                            <TextField
                                ref={anchorEl}
                                name="select_account"
                                label="Select Account"
                                helperText="Enter the account number or name as it is in Sage"
                                value={search}
                                onChange={handleSearchChange}
                                autoComplete='off'
                            />

                            <Popover
                                id='search_autocomplete'
                                open={showAutocomplete}
                                anchorEl={anchorEl.current}
                                onClose={() => setShowAutocomplete(false)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                disableAutoFocus
                                disableEnforceFocus
                            >
                                
                                <div style={{padding: 20, maxHeight: 250, overflowY: 'auto'}}>
                                    {sageAccountsLoading && <CircularProgress color="inherit" size={24} />}
                                    {(!sageAccountsLoading && sageFilteredAccounts.length === 0) && <Typography disabled>No account found</Typography>}
                                    {!sageAccountsLoading && sageFilteredAccounts.map(option => 
                                        <Typography style={{cursor: 'pointer'}} key={option.key} onClick={() => selectAccount(option)}>{option.value}</Typography>
                                    )}
                                </div>
                            </Popover>
                        </React.Fragment>
                    }
                </DialogContent>
                <DialogActions>
                    <div className={classes.actions}>
                        <Button onClick={() => setShowEdit(false)}>Cancel</Button>
                        <Button variant="contained" color="secondary" onClick={handleChange}>Set</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default OrganisationLinkedSageAccount;