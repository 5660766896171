import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip, IconButton, CircularProgress} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

const Refresh = props => {
    return (
        <Tooltip title="Refresh">
            <IconButton onClick={props.handleClick} color="secondary" size={props.size || 'medium'} aria-label="refresh" style={{marginLeft: 10, marginRight: 10}}>
                {((typeof props.loading != 'undefined' && props.loading ===false) || typeof props.loading == 'undefined')&&<RefreshIcon />}
                {(typeof props.loading != 'undefined' && props.loading===true)&&<CircularProgress color="secondary" size={24} />}
            </IconButton>
        </Tooltip>
    )
}

Refresh.propTypes = {
    handleClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    loading: PropTypes.bool.isRequired,
}

export default Refresh