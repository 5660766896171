import React, {useState, useEffect, useContext} from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, IconButton } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { object } from 'prop-types';

const useStyles = makeStyles((theme) => ({
    listValue: {
        padding: '0 8px',
        display: 'inline'
    }
}));

const DashItem = props => {

    const classes = useStyles();

    return (
        <ListItem button onClick={() => props.handleClick(props.handle)}>
            <ListItemIcon style={{minWidth: 36}}>
                    {props.panel == props.handle && <ExpandMoreIcon color="secondary" />}
                    {props.panel != props.handle && <ChevronRightIcon color="secondary" />}
            </ListItemIcon>
            <ListItemText primary={props.heading}/>
            
            {typeof props.value == 'object' && 
                <div>
                    {
                    Object.keys(props.value).map(k => (
                        // <div key={k} className={classes.listValue}>{k}: <b>{props.value[k]}</b></div>
                        <div key={k} className={classes.listValue}><b>{props.value[k]}</b></div>
                    ))
                    } 
                </div>
            }
            
            {typeof props.value != 'object' && 
                <ListItemSecondaryAction><div className={classes.listValue}>{props.value}</div></ListItemSecondaryAction>
            }
        </ListItem>
    )
}

export default DashItem;