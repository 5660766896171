const userReducer = (state = {
    profile: null,
    token: null,
    expires: null,
    status: 'out',
    msg: '',
    notifications: []
}, action) => {
    switch(action.type){
        case "USER_LOGIN":
            state = {
                ...state,
                status: action.status,
                msg: action.status === 'error'?action.payload:'',
                profile: action.status === 'in'?action.payload:null,
                token: action.status === 'in'?action.payload.token:null,
                expires: action.status === 'in'?action.payload.expires:null,
                notifications: action.status === 'in'?action.payload.user_notifications || []:[],
            }
            break;
        case "USER_LOGOUT":
            console.log('running logout');
            state = {
                ...state,
                status: 'out',
                msg: '',
                profile: null,
                token: null,
                notifications: []
            }
            break;
        case "USER_VALIDATE":
            state = {
                ...state,
                status: state.status != 'in' ? 'validating' : state.status,
                msg: '',
                token: action.payload,
                expires: action.expires,
                notifications: action.status === 'in'?action.payload.user_notifications || []:[]
            }
            break;
        case "USER_NOTIFY":
            state = {
                ...state,
                notifications: [...JSON.parse(JSON.stringify(state.notifications)), action.payload]
            }
            break;
        case "USER_NOTIFIED":
            const newNotifications = JSON.parse(JSON.stringify(state.notifications));
            const notifyIdx = state.notifications.findIndex(s => JSON.stringify(s) === JSON.stringify(action.payload))
            if(notifyIdx > -1)
                newNotifications[notifyIdx].was_read = new Date().toISOString();

            state = {
                ...state,
                notifications: newNotifications
            }
            break;
    }
    return state;
}

export default userReducer;