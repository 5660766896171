import React, { useContext, useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import Helpers from '../global/helpers';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { Table, TablePagination, TableBody, TableCell, TableHead, TableRow, Paper, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import Quickcrumbs from '../global/Quickcrumbs';


const SubscriptionRenewals  = props => {

    const context = useContext(GlobalContext);
    const history = useHistory();

    const [data, setData] = useState([]);
    const headers = ['id_subscription', 'id_order', 'mi_number', 'organisation_name', 'order_name', 'expiry_date'];
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [page, setPage] = useState(0);

    const loadData = () => {
        setLoading(true);
        setData([]);

        context.apiRequest(`/subscriptions/renewals/due`, 'GET')
        .then(
            res => {
                setData(res.result);
                setLoading(false);
            },
            err => {
                console.log(err);
                setLoading(false);
            }
        );
    }

    

    const downloadExcel = async () => {
        setDownloading(true);
  
        try {
  
            await context.apiFileDownload(`/subscriptions/renewals/due/download`, `SubscriptionRenewals_${new Date().getTime()}.xlsx`);
  
        } catch (error) {
  
            console.log(error);

        } finally {
          setDownloading(false);
        }
  
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleRowClick = row => {
        history.push(`/order/${row.id_order}`);
    }

    useEffect(loadData, [])

    return(
        <div style={styles.container}>
            <div style={styles.inner}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Subscriptions',
                            route: '/subscriptions'
                        },
                        {
                            name: 'Renewals',
                            route: '/subscriptions/renewals'
                        }
                    ]}
                    history={props.history}
                />

                <div style={styles.taskbar}>

                    <h2>Subscription Renewals</h2>

                    <div style={{display: 'flex', flexDirection: 'row', gap: '15px'}}>
                        <Button variant="contained" color="secondary" onClick={loadData}>Refresh{loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
                        <Button variant="contained" color="secondary" onClick={downloadExcel}>Download to .xlsx{downloading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
                    </div>

                </div>

                {(data.length > 0 && headers.length > 0) &&
                    <Paper style={styles.root}>
                        <Table style={styles.table}>
                            <TableHead>
                            <TableRow>
                                {headers.map((h, idx) => <TableCell key={idx}>{Helpers.cleanHeader(h)}</TableCell>)}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                                <TableRow hover key={idx} onClick={() => handleRowClick(row)}>
                                    {headers.map((h, hIdx) => <TableCell key={hIdx}>{row[h]}</TableCell>)}
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                }

                {data.length == 0 && <div style={styles.nodata}>No Data</div>}
                

            </div>
        </div>
    );

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default SubscriptionRenewals