import { Button, makeStyles, TextField, useTheme, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import globalContext from "../../../context/global-context";
import CommissioningAddPoints from "./pointAdd";
import CommissioningPoint from './point';
import io from 'socket.io-client';
import TestCertificate from "./testCertificate";

const useStyles = makeStyles(theme => ({
    pointsContainer: {
        paddingTop: 40,
        display: 'flex',
        flexDirection: 'row',
        gap: '15px',
        flexWrap: 'wrap'
    }
}))

const CommissioningPoints = props => {

    
    //
    //  The socket
    //
    var socket;
    var socket_connecting = false; 
    var nonStateSocketData = [];

    const context = useContext(globalContext);
    const theme = useTheme();
    const classes = useStyles(theme);

    const [socketData, setSocketData] = useState([]);
    const [showTestCert, setShowTestCert] = useState(false);

    const getCPIDs = () => {
        let cpids_santitized = [];
        props.points.map(p => {

            let cpid = `UKEV${p.EVPointID < 1000 ? '0' : ''}${p.EVPointID}`;
            if(cpid.length > 9){
                if(cpids_santitized.indexOf(cpid.slice(cpid, -1)) === -1)
                    cpids_santitized.push(cpid.slice(cpid, -1));
            } else 
                cpids_santitized.push(cpid);

        })
        // Set the CPIDS subscribed somewhere
        return cpids_santitized;
    }


    //
    //  Subscribe to the socket feed.
    //
    const subscribeToRealtimeRelay = () => {

        if((!socket || socket === null) && !socket_connecting){
            socket_connecting = true;
            socket = io('https://controller.vendelectric.com/realtime-relay', {query: {token: context.user.token}, forceNew: true});
        }

        if(socket && !socket.connected && !socket_connecting){
            socket.connect();
        }

        if(socket && socket.connected){
            let sub_cpids = getCPIDs();
            socket.send('subscribe', sub_cpids);
        }

        if(socket){


            // On connect subscribe to the charge points
            socket.on('connect', () => {
                socket_connecting = false;

                let sub_cpids = getCPIDs();
                socket.send('subscribe', sub_cpids);
            

                //
                //  Incoming message from the socket
                //
                if(typeof socket._callbacks['$message'] == 'undefined')
                    socket.on('message', (type, msg) => {

                        const newMsg = {
                            ...msg
                        }
                        
                        //
                        // Obtain a copy of the non state data
                        //
                        let newRealTimeRelay = JSON.parse(JSON.stringify(nonStateSocketData));
                        
                        //
                        //  Look for the idx of the cpid from the incomming message
                        //
                        const idx = newRealTimeRelay.findIndex(nCP => nCP.cpid === newMsg.cpid);

                        // I exist, update the data
                        if(idx > -1){
                        
                            // Update the existing message with the new message 
                            if(newMsg.isOnline === true || newRealTimeRelay[idx].isOnline === true)
                                newRealTimeRelay[idx] = newMsg;
                        }
                        else{

                            // Add the new charge point message in
                            newRealTimeRelay.push(newMsg);
                        }

                        // setting the state works from inside a socket
                        // message but retrieving the current state
                        // object doesn't instead we use a variable
                        // outside the state and emit and event to 
                        // trigger the set state event
                        nonStateSocketData = newRealTimeRelay;
                        setSocketData(nonStateSocketData);

                        
                    });

            });

        }

    }

    const handleIsOnline = (EVPointID) => {
        let cpid = `UKEV${EVPointID < 1000 ? '0' : ''}${EVPointID}`;
        if(cpid.length > 9)
            cpid = cpid.slice(cpid, -1);

        const point = socketData.find(p => p.cpid === cpid);
        if(typeof point !== 'undefined')
            return point.isOnline;
        else
            return false;
    }


    useEffect(() => {

        subscribeToRealtimeRelay();

        return () => {
            
            if(socket && socket.connected){
                socket.close();
                socket = null;
            }
            
        }

    }, [props.points])


    return (
        <div style={{marginTop: 20}}>

            <div className="flex-row align-center gap-20">
                <CommissioningAddPoints handleAdd={props.handleAdd} loading={props.loading} />

                {/* <div>
                    {(props.points.length > 0 && props.order) && <Button variant="contained" color="primary" onClick={() => setShowTestCert(true)}>Create Test Certificate</Button>}
                </div> */}
            </div>

            {props.points.length > 0 && 
                <div className={classes.pointsContainer}>
                    {props.points.map((p, idx) => (
                        <CommissioningPoint point={p} key={idx} handleRemove={props.handleRemove} handleIsOnline={handleIsOnline} />
                    ))}
                </div>
            }

            {showTestCert && <TestCertificate show={true} handleCancel={() => setShowTestCert(false)} handleSuccess={() => setShowTestCert(false)} order={props.order} points={props.points} />}

        </div>
    )
}

export default CommissioningPoints;