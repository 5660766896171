import React, { useEffect, useState, useContext } from 'react';
import { useStyles } from '../styles';
import { useTheme, makeStyles } from '@material-ui/styles';

import { EvStationConnection, LastContact, MainsReport, SessionEnding, Mode3Connector, OCPPConnector } from './supporting_components';

import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Avatar,
    Divider,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Grid
} from '@material-ui/core';

import EvCircularProgress from '../../global/EvCircularProgress';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import globalContext from '../../../context/global-context';


const useCustomStyles = makeStyles(theme => ({
    pos: {
        marginBottom: 5
    }
}))


const TicketChargePointDialog = props => {

    const context = useContext(globalContext);

    const theme = useTheme();
    const styles = useStyles(theme);
    const customStyles = useCustomStyles(theme);

    const { socketInfo, handleClose, cpid } = props;

        
    const evLEDClass = (feed) => {
        if(feed == null)
            return null;

        let style = null;
        if(typeof feed.mode3 != 'undefined')
            style = `LED${feed.mode3.ledColour}${feed.mode3.ledBlink ? 'b' : ''}`;

        if(style != null)
            return styles[style];

        return null;
    }

    const getProtocol = (feed) => {
        if(feed == null)
            return 'unknown';

        if(typeof feed.mode3 != 'undefined')
            return 'Rolec Mode 3';

        if(typeof feed.ocpp16 != 'undefined')
            return 'OCPP 1.6';
    }

    const isMode3 = feed => {
        return feed !== null && typeof feed.mode3 != 'undefined'
    }

    const isOCPP = feed => {
        return feed !== null && typeof feed.ocpp16 != 'undefined'
    }

    const isLoading = () => {
        if(typeof socketInfo == 'undefined' || (!socketInfo.isOnline && typeof socketInfo.lastContactMs == 'undefined'))
            return true;

        return false
    }

    return (
        <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth>
            
            {!isLoading() && 
            <DialogTitle id="form-dialog-title">
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <EvStationConnection isOnline={socketInfo.isOnline} size={60} />
                    <div>
                        <Typography variant="h6">{cpid}</Typography>
                        <Typography color={socketInfo.isOnline?'textSecondary':'error'}>Network Status: <b>{socketInfo.isOnline ? 'Connected' : 'Offline'}</b></Typography>
                    </div>
                </div>
            </DialogTitle>}

            {isLoading() && 
                <DialogContent>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <EvCircularProgress size={40} />
                        <Typography color="textSecondary" style={{marginLeft: 10}}>Connecting to charge point</Typography>
                    </div>
                </DialogContent>
            }
            
            {!isLoading() &&
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">Charge point details:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {isMode3(socketInfo) && <div style={{display: 'flex', flexDirection: 'row'}} className={customStyles.pos}><Typography>LED Status: </Typography><Avatar className={evLEDClass(socketInfo)} style={{width: 24, height: 24, marginLeft: 16}}>&nbsp;</Avatar></div>}
                        <Typography className={customStyles.pos}>Protocol: <b>{getProtocol(socketInfo)}</b></Typography>
                        <Typography className={customStyles.pos}>Firmware: <b>{socketInfo.firmwareVersion}</b></Typography>
                        {isMode3(socketInfo) && <MainsReport feed={socketInfo} className={customStyles.pos} />}
                        {(isOCPP(socketInfo) && typeof props.socketInfo.ocpp16.boot != 'undefined') && <Typography className={customStyles.pos}>Vendor: <b>{props.socketInfo.ocpp16.boot.chargePointVendor}</b></Typography>}
                        {(isOCPP(socketInfo) && typeof props.socketInfo.ocpp16.boot != 'undefined') && <Typography className={customStyles.pos}>Model: <b>{props.socketInfo.ocpp16.boot.chargePointModel}</b></Typography>}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LastContact lastContact={socketInfo.lastContactDateTime} lastAuthorised={socketInfo.lastAuthorised} className={customStyles.pos} />
                    </Grid>
                    {!socketInfo.isOnline && <Grid item xs={12}>
                        <Typography variant="subtitle1" color="error">Last known state:</Typography>
                    </Grid>}
                </Grid>
                
                
                {isMode3(socketInfo) && socketInfo.connectors.map((con, idx) => (
                    <div key={idx}>
                        <Mode3Connector connector={con} mode3={socketInfo.mode3} />
                    </div>
                ))}
                
                
                {isOCPP(socketInfo) && socketInfo.connectors.map((con, idx) => (
                    <div key={idx}>
                        <OCPPConnector connector={con} socketInfo={socketInfo} />
                    </div>
                ))}

                <ExpansionPanel style={{marginTop: 10}}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="advanaced-content" id="advanced-header"><Typography>Advanced</Typography></ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <pre>{JSON.stringify(socketInfo, undefined, 2)}</pre>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

            </DialogContent>}
            <DialogActions>
                <Button onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )

}

export default TicketChargePointDialog