import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';
import { Grid, TextField, Button, FormControl, FormHelperText, CircularProgress, Typography, InputLabel, Select } from '@material-ui/core';


const AddSubscription = props => {
    const [loading, setLoading] = useState(false);

    const baseSubscription = {
        id_organisation: typeof props.id_organisation!='undefined'?props.id_organisation:null,
        id_order: typeof props.id_order!='undefined'?props.id_order:null,
        id_product: 0,
        subscription_description: '',
        qty: 1,
        cost: 0
    }

    // Create a local state
    const [subscription, setSubscription] = useState({...baseSubscription});
    const [products, setProducts] = useState([]);


    useEffect(() => {

        setLoading(true)

        context.fetchProducts().then(
            res => {
                console.log(res.result);
                setProducts(res.result.filter(p => p.active_to === null || new Date(p.active_to) > new Date()));
                setLoading(false)
            }, 
            err => {
                setLoading(false)
                context.showAlert('error', 'error fetching products: ' + JSON.stringify(err.msg));
            }
        )
    }, [])


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    const handleChange = e => {
        let newSubscription = {...subscription};
        newSubscription[e.target.name] = e.target.value;
        setSubscription(newSubscription);
    }

    const handleProductChange = e => {
        let newSubscription = {...subscription};
        newSubscription[e.target.name] = e.target.value;

        const product = products.find(p => p.id_product == e.target.value);
        const cost = product.price;

        newSubscription.cost = cost;
        setSubscription(newSubscription);
    }

    const invalid = () => {
        if(isInvalid('subscription_description') || isInvalid('qty') || isInvalid('id_organisation') || isInvalid('id_order') || isInvalid('id_product'))
            return true;
        
        return false;
    }

    const isInvalid = prop => {
        switch(prop){
            case 'subscription_description':
                return false;
            case 'qty':
                return parseInt(subscription.qty) <= 0?true:false;
            case 'id_organisation':
                return subscription.id_organisation == null?true:false;
            case 'id_order':
                return subscription.id_order == null?true:false;
            case 'id_product':
                return subscription.id_product == null?true:false;
            default:
                return true;
        }
    }

    const createSubscription = () => {
        if(!invalid())
            setLoading(true);
            context.addSubscription(subscription).then(
                res => {
                    setSubscription({...baseSubscription});
                    setLoading(false);
                    context.showAlert('success', 'Purchase added');

                    if(typeof props.onAdd == 'function')
                        props.onAdd();
                },
                err => {
                    setLoading(false);
                    context.showAlert('error', 'Error adding purchase ' + JSON.stringify(err));
                }
            )
    }

    const total = () => {
        const t = parseFloat(parseFloat(subscription.cost) * parseInt(subscription.qty)).toLocaleString('en-gb');
        if(t == 'NaN')
            return 0;

        return t;
    }
        
    return (

        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: 20}}>
            <Typography variant="h5">Add purchase</Typography>


            <div style={{flex: 1, margin: '10px 0', padding: 10}}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="id_product">Product</InputLabel>
                    <Select
                    native
                    value={subscription.id_product}
                    onChange={handleProductChange}
                    inputProps={{
                        name: 'id_product',
                        id: 'id_product',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {products.map(p => (
                        <option key={p.id_product} value={p.id_product}>{p.product_description}</option>
                    ))}
                    </Select>
                </FormControl>
            </div>

            <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                <FormControl fullWidth>
                    <TextField name="subscription_description" value={subscription.subscription_description} onChange={handleChange} label="Purchase Description" error={isInvalid('subscription_description')} />
                </FormControl>
            </div>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 30, justifyContent: 'center', alignItems: 'flex-end', padding: 10}}>
                <div>
                    <FormControl>
                        <TextField name="qty" type="number" value={subscription.qty} onChange={handleChange} label="Qty" error={isInvalid('qty')} />
                    </FormControl>
                </div>
                <div style={{flex: 1}}></div>
                <div>
                    <FormControl>
                        <TextField name="cost" value={subscription.cost} onChange={handleChange} label="Cost" />
                    </FormControl>
                </div>
            </div>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 30, justifyContent: 'center', alignItems: 'flex-end', padding: 10}}>
                <div style={{flex: 1}}><Typography variant="h6">Total: £{total()}</Typography></div>
                <div style={{padding: '0 10px'}}>
                    <Button color="secondary" variant="contained" disabled={invalid()} onClick={createSubscription}>Add {loading && <CircularProgress size={20} className="ml" color="inherit" />}</Button>
                </div>
            </div>
        </div>

    );
}

export default AddSubscription