import React, {useContext, useEffect, useState} from 'react';
import GlobalContext from '../../../context/global-context';
import ActivityIndicator from '../../global/ActivityIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Helpers from '../../global/helpers';
import { withTheme } from '@material-ui/styles';
import API from '../../../api';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';


const BerthVendReport = props => {

    const context = useContext(GlobalContext);

    const [end, setEnd] = useState(new Date().toISOString().split('T')[0]);
    const [start, setStart] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0]);
    const [organisation, setOrganisation] = useState(0);

    const [dirty, setDirty] = useState(false);

    const [loading, setLoading] = useState(false);
    const [summaryLines, setSummaryLines] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [marinas, setMarinas] = useState([]);


    const loadStatements = async () => {

        try {

            setLoading(true);
            setDirty(false);
            let newSumLines = [];
            const data = await API.req(`/bv/statements/report?start=${start}&end=${end}${organisation !== 0 ? '&id_organisation='+organisation : ''}`, 'GET', null);
            if(data.success === true)
                newSumLines = data.result;
            setSummaryLines(newSumLines);
            setLoading(false);
            
        } catch (error) {

            setLoading(false);
            
        }

    }


    const loadMarinas = async () => {

        try {

            const data = await API.req(`/bv/marinas`, 'GET', null);
            if(data.success === true)
                setMarinas(data.result);
            
        } catch (error) {

            console.log(error);
            
        }

    }

    
    useEffect(() => {

        loadStatements();
        loadMarinas();

    }, [])


    const downloadExcel = async (row) => {
  
        try {
            
            setDownloading(true);
            await context.apiFileDownload(`/bv/statements/report/download?start=${start}&end=${end}${organisation !== 0 ? '&id_organisation='+organisation : ''}`, `BerthVend_Transactions_${new Date().getTime()}.xlsx`);
  
        } catch (error) {
  
            console.log(error);

        } finally {
            setDownloading(false);
        }
  
    }



    return (
        
        <div style={Object.assign({}, !props.show&&styles.hide)}>

        <h2 style={{color: props.theme.palette.primary.main, fontWeight: 300}}>Report</h2>

        <div style={styles.taskbar}>
            <div style={{gap: 10, display: 'flex'}}>
                <TextField name="start" label="Start" type="date" value={start} onChange={e => {setStart(e.target.value); setDirty(true)}} style={styles.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />

                <TextField name="end" label="End" type="date" value={end} onChange={e => {setEnd(e.target.value); setDirty(true)}} style={styles.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />

                <FormControl style={styles.textField}>
                    <InputLabel id="marina-label">Marina</InputLabel>
                    <Select
                        labelId="marina-label"
                        id="marina"
                        value={organisation}
                        onChange={e => setOrganisation(e.target.value)}
                    >
                    <MenuItem value={0}>-- All --</MenuItem>
                    {marinas.map(m => <MenuItem key={m.id_organisation} value={m.id_organisation}>{m.organisation_name}</MenuItem>)}
                    </Select>
                </FormControl>

                <Button variant="contained" color={dirty?'secondary':'default'} onClick={loadStatements}>Go{loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
                <Button variant="contained" color="secondary" onClick={downloadExcel}>Download {downloading && <CircularProgress color="inherit" size={24} />}</Button>
            </div>
        </div>

        <Paper style={styles.root}>
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Marina</TableCell>
                        <TableCell align="right">Identity</TableCell>
                        <TableCell align="right">From</TableCell>
                        <TableCell align="right">To</TableCell>
                        <TableCell align="right">Currency</TableCell>
                        <TableCell align="right">Rate</TableCell>
                        <TableCell align="right">Meter</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Subtotal</TableCell>
                        <TableCell align="right">Tax</TableCell>
                        <TableCell align="right">Amount Paid</TableCell>
                        <TableCell align="right">Paid On</TableCell>
                        <TableCell align="right">Payment Reference</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {summaryLines.map((row, idx) => (
                    <React.Fragment key={idx}>
                        <TableRow selected={row.selected}>
                            <TableCell>{row.organisation_name}</TableCell>
                            <TableCell align="right">{row.identity}</TableCell>
                            <TableCell align="right">{Helpers.SQLtoUTCDate(row.from_time, false)}</TableCell>
                            <TableCell align="right">{Helpers.SQLtoUTCDate(row.snapshot_time, false)}</TableCell>
                            <TableCell align="right">{row.currency}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.rate, row.currency)}/{row.id_unit === 1 ? 'kWh' : 'm3'}</TableCell>
                            <TableCell align="right">{row.meter/1000}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.cost, row.currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.cost_sub, row.currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.cost_tax, row.currency)}</TableCell>
                            <TableCell align="right">{(row.host_due && row.payment_made) && Helpers.localeCurrency(row.host_due, row.currency)}</TableCell>
                            <TableCell align="right">{row.payment_made && Helpers.SQLtoUTCDate(row.payment_made, false)}</TableCell>
                            <TableCell align="right">{row.payment_reference}</TableCell>
                        </TableRow>
                    </React.Fragment>
                ))}
                </TableBody>
            </Table>
        </Paper>

        {summaryLines.length == 0 && <div style={styles.nodata}>No Data</div>}

        </div>
    )
}


const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    hide: {
        display: 'none'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default withTheme(BerthVendReport)