import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../context/global-context';
import { CircularProgress, Typography, Button, makeStyles, useTheme, TextField, List, ListItem, ListItemText, Paper, FormControlLabel, Checkbox, Divider, FormControl, FormHelperText } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: 20,
        padding: 20,
        maxWidth: 600
    },
    form: {
        display: 'flex', 
        flexDirection: 'row', 
        gap: '15px', 
        alignItems: 'flex-start'
    },
    list:  {
        maxHeight: 300, 
        overflowY: 'auto'
    }
}))


const CommissioningSelectGroupScheme  = props => {

    const context = useContext(GlobalContext);
    const theme = useTheme();
    const classes = useStyles(theme);

    const [groupSchemeText, setGroupSchemeText] = useState(props.orderGroupScheme || '')
    const [essentialPlan, setEssentialPlan] = useState(false);
    const [contactEmail, setContactEmail] = useState('');

    const [groupSchemes, setGroupSchemes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [adding, setAdding] = useState(false);
    const [filtered, setFiltered] = useState([]);


    const handleInputChange = e => {
        switch(e.target.name){
            case 'groupSchemeText':
                setGroupSchemeText(e.target.value);
                break;
            case 'contactEmail':
                setContactEmail(e.target.value);
                break;
            default:
        }
    }

    const handleEssentialChange = e => {
        setEssentialPlan(e.target.checked);
    }

    const fetchGroupSchemes = async () => {

        setLoading(true);

        await context.commissionFetchGroupSchemes().then(
            res => {
                setGroupSchemes(res.result.recordset);
                setLoading(false);
            },
            err => {
                setLoading(false);
                context.showAlert('error', 'Error loading group schemes')
            }
        )

    }

    const handleAdd = () => {

        if(groupSchemeText.length > 0){

            setAdding(true);

            context.commissionAddGroupScheme({schemeName: groupSchemeText, essentialPlan: essentialPlan, contactEmail: contactEmail}).then(
                res => {
                    setAdding(false);
                    handleSelect(res.result);
                },
                err => {
                    setAdding(false);
                    context.showAlert('error', 'Error adding group scheme')
                }
            )

        } else {
            context.showAlert('error', 'Group scheme cannot be empty')
        }

    }

    const handleFilter = () => {
        if(groupSchemeText == ''){
            setFiltered(JSON.parse(JSON.stringify(groupSchemes)));
        } else {
            const filterd = JSON.parse(JSON.stringify(groupSchemes)).filter(s => s.groupSchemeName.toUpperCase().indexOf(groupSchemeText.toUpperCase()) > -1);
            setFiltered(filterd);
        }
    }

    const handleSelect = (scheme) => {

        if(typeof props.handleSelect === 'function')
            props.handleSelect(scheme);

    }


    useEffect(() => {
        fetchGroupSchemes()
    }, [])

    useEffect(() => {
        handleFilter()
    }, [groupSchemes, groupSchemeText])

    return (
        
        <Paper className={classes.paper}>
            <div className={classes.form}>
                <div style={{flex: 1}}>
                    <TextField fullWidth name="groupSchemeText" label="Create Group Scheme" variant="outlined" autoComplete="off" error={(groupSchemeText.length === 0)} value={groupSchemeText} onChange={handleInputChange} style={{flex: 1}} />
                    <FormControlLabel
                        style={{marginTop: 15}}
                        control={
                        <Checkbox
                            checked={essentialPlan}
                            onChange={handleEssentialChange}
                            name="essentialPlan"
                            color="primary"
                        />
                        }
                        label="Essential Plan"
                    />
                    {essentialPlan && 
                        <FormControl>
                            <TextField fullWidth name="contactEmail" label="Contact Email Address" variant="outlined" autoComplete="off" value={contactEmail} onChange={handleInputChange} style={{marginTop: 15}} />
                            <FormHelperText>This is the email that will be used to send reports to the host on the essential plan.</FormHelperText>
                        </FormControl>
                    }
                </div>
                <Button type="submit" onClick={handleAdd} variant="contained" color="secondary">{adding?<CircularProgress color="inherit" size={24}/>:'CREATE NEW'}</Button>
            </div>

            <Divider style={{marginTop: 20, marginBottom: 20}} />

            <Typography variant="h6" style={{marginTop: 20}}>or select existing Group Scheme</Typography>
            <Typography variant="caption">Type in the box above to filter</Typography>

            {loading && <div><CircularProgress size={24} /></div>}
            {(filtered.length == 0 && !loading) && <Typography variant="body1" color="textSecondary">Nothing found</Typography> }

            {filtered && 
            <List className={classes.list}>
                {filtered.map((s, idx) => 
                    <ListItem button key={idx} onClick={() => handleSelect(s)}>
                        <ListItemText primary={`#${s.groupSchemeID}: ${s.groupSchemeName}`} secondary={`Current Plan: ${s.essentialPlan ? 'Essential' : 'Premium'} | Currency: ${s.Currency}`} />
                    </ListItem>
                )}
            </List>
            }
        </Paper>
    )
}

export default CommissioningSelectGroupScheme;