import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const ControllerSettings = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Controller Settings',
                            route: '/settings/controller_settings'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Controller Settings</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>A stack a settings that are sent as the default configuration for a socket.</Typography>

                <Crud
                    heading='Controller Settings'
                    uri='controller_settings'
                    idCol='id_controller_setting'
                    orderCol='order'
                    displayColumns={[ 
                        { 
                            name: 'id_controller_type', 
                            type: 'select',
                            display_name: 'Controller Type',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'controller_setting_criteria', 
                            type: 'object',
                            display_name: 'Criteria',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'controller_setting', 
                            type: 'object',
                            display_name: 'Settings',
                            editable: true,
                            required: true
                        }
                    ]}
                    selectors={[
                        {
                            uri: 'controller_types',
                            name: 'controller_name',
                            col_name: 'id_controller_type',
                            selector_name: 'id_controller_type',
                            display_col: 'controller_type_name'
                        },
                        {
                            data: [
                                {
                                    id: 'connectivity_type',
                                    name: 'Connectivity'
                                }, 
                                {
                                    id: 'network',
                                    name: 'Network'
                                }, 
                                {
                                    id: 'phase',
                                    name: 'Phase'
                                }, 
                                {
                                    id: 'rfid',
                                    name: 'RFID'
                                }, 
                                {
                                    id: 'power',
                                    name: 'Power'
                                }, 
                                {
                                    id: 'connectors',
                                    name: 'Connectors'
                                }, 
                                {
                                    id: 'connector_type',
                                    name: 'Connector Type'
                                }
                            ],
                            name: 'controller_setting_criteria',
                            col_name: 'controller_setting_criteria',
                            selector_name: 'id',
                            display_col: 'name'
                        }
                    ]}
                    canRemove={true}
                />

            </div>

        </div>
    )

}

export default ControllerSettings
