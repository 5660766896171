const defaultFieldValidationObject = {
    dirty: false,
    error: false,
    required: false,
    msg: '',
    display_name: ''
};

export function makeValidation (fields) {
    let validation = {};
    fields.forEach(field => {
        let _validation = JSON.parse(JSON.stringify(defaultFieldValidationObject));
        _validation.required = field.required || false;
        _validation.display_name = field.display_name || field.name;
        validation[field.name] = _validation;
    })
    return validation;
}

export function validateForm (form, validationObj, dirtyAll = false) {
    
    // Whip up a copy so references don't get involved
    let nvo = JSON.parse(JSON.stringify(validationObj));

    Object.keys(validationObj).forEach(f => {
        nvo[f].error = false;
        nvo[f].msg = '';
        nvo[f].dirty = dirtyAll || (typeof form[f] !== 'undefined' &&  form[f] !== null && form[f].length > 0) ? true : validationObj[f].dirty;
    
        if(nvo[f].required && (form[f] === null || form[f].length <= 0)){
            nvo[f].error = true;
            nvo[f].msg = `${nvo[f].display_name} is required.`
        }
    });

    return nvo;
}

export function isValidForm (form, validationObj) {
    const validation = validateForm(form, validationObj, true);
    let result = {
        isValid: true,
        validation
    }
    Object.keys(validation).forEach(f => {
        if(validation[f].error === true)
            result.isValid = false;
    });
    return result;
}