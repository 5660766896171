import React, { useEffect, useState, useContext } from 'react'
import { Typography, InputBase, TextField, FormControl, FormHelperText, InputAdornment, Button, IconButton, List, ListItem, ListItemText, Collapse, CircularProgress, Paper, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Helpers from '../global/helpers';
import GlobalContext from '../../context/global-context';



const GlobalSearch = props => {

    
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [searchProps, setSearchProps] = useState({
        searching: false,
        open: false
    });

    
    // Use the global context
    const context = useContext(GlobalContext);

    const handleSearch = () => {
        setSearchProps({
            ...searchProps,
            searching: true,
            open: true
        })

        context.search(search).then(
            res => {
                setSearchResults(res.data);
                console.log(res.data);
                setSearchProps({...searchProps, searching: false, open: true})
            },
            err => {
                setSearchProps({...searchProps, searching: false, open: false})
                context.showAlert('error', 'error loading search results');
            }
        )
    }

    const handleSearchChange = e => {
        setSearch(e.target.value);
    }

    const handleSearchKeyPress = e => {
        if(e.key == 'Enter')
            handleSearch();
    }

    const handleSearchClear = () => {
        setSearchResults(null);
        setSearchProps({
            searching: false,
            open: false
        })
    }

    const handleSearchResultsOverflow = () => {
        let i = 0;
        if(searchResults != null)
            Object.keys(searchResults).map(k => {
                if(i < searchResults[k].length)
                    i = searchResults[k].length;
            })
        return i
    }

    const handleChangeRoute = route => {
        handleSearchClear();
        props.history.push(route);
    }

    const noSearchResults = () => {
        if(
            searchResults != null && 
            typeof searchResults.orders != 'undefined' && 
            (
                searchResults.orders.length > 0 || 
                searchResults.organisations.length > 0 || 
                searchResults.logs.length > 0 || 
                searchResults.subscriptions.length > 0 || 
                searchResults.tickets.length > 0)
            ){
            console.log('we have results');
            return false             
        }
        
        return true
    }

    useEffect(() => {

        if(search === '')
            handleSearchClear();

    }, [search])

    return (

        <div style={{position: 'relative', margin: '0 20px', width: 300}}>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div style={{display: 'flex', flex: 1}}>
                    <Paper style={{padding: '0px 16px', flex: 1, display: 'flex'}} elevation={0}>
                        <InputBase
                            name={'Search'} 
                            type="text" 
                            value={search} 
                            onChange={handleSearchChange} 
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'Search' }}
                            autoComplete="off"
                            onKeyPress={handleSearchKeyPress}
                            style={{flex: 1}}
                        />
                        {search.length != '' &&<IconButton onClick={() => setSearch('')}>
                            <CloseIcon color="default" />
                        </IconButton>}
                        <IconButton type="submit" aria-label="search" onClick={handleSearch}>
                            <SearchIcon color="secondary" />
                        </IconButton>

                        {/* <TextField 
                            InputLabelProps={{shrink: true,}} 
                            label="Global Search" 
                            variant="filled" 
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    {search.length != '' && }
                                </InputAdornment>
                                ),
                            }}
                             /> */}
                        {handleSearchResultsOverflow() > 20 && <FormHelperText error={true}>Your search has returned alot of results, try refining your search a bit more.</FormHelperText>}
                    </Paper>
                </div>
                {/* <div style={{width: 20}}></div>
                <div>
                    <Button variant="contained" color={'secondary'} onClick={handleSearch}>Search</Button>
                </div> */}
            </div>
                            
            <Collapse in={searchProps.open} style={{position: 'absolute', zIndex:888, width: '300%', minWidth: 300, maxWidth: 700}}>
                <Paper style={{padding: 20, marginTop: 20, maxHeight: 400, overflowY: 'auto'}}>
                    {searchProps.searching && <CircularProgress size={24} />}
                    {(!searchProps.searching && noSearchResults()) && <Typography variant="body1" disabled>No results found</Typography>}
                    {(!searchProps.searching && searchResults != null) && 
                        <div>

                            {searchResults.orders.length > 0 && 
                            <div>
                                <Typography variant="subtitle2">Orders</Typography>
                                <List dense={true}>
                                    {searchResults.orders.map(i => (
                                        <ListItem button key={i.id_order} onClick={()=>handleChangeRoute('/order/'+i.id_order)}>
                                            <ListItemText primary={`VE${i.id_order}${i.manufacturer_ref !== null ? ' MI:'+i.manufacturer_ref : ''} - ${i.order_name}`} secondary={i.order_description} />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                            }

                            {searchResults.organisations.length > 0 && 
                            <div>
                                <Typography variant="subtitle2">Organisations</Typography>
                                <List dense={true}>
                                    {searchResults.organisations.map(i => (
                                        <ListItem button key={i.id_organisation} onClick={()=>handleChangeRoute('/organisation/'+i.id_organisation)}>
                                            <ListItemText primary={`#${i.id_organisation} - ${i.organisation_name}`} secondary={`Main contact: ${Helpers.isNull(i.main_contact_name, '')} - ${i.main_contact_email} | ${Helpers.isNull(i.main_contact_phone, '')}`} />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                            }

                            {searchResults.logs.length > 0 && 
                            <div>
                                <Typography variant="subtitle2">Logs</Typography>
                                <List dense={true}>
                                    {searchResults.logs.map(i => (
                                        <ListItem button key={i.id_log} onClick={()=>{
                                            if(i.id_order != null)
                                                handleChangeRoute('/order/'+i.id_order);
                                            else
                                                handleChangeRoute('/organisation/'+i.id_organisation);
                                        }}>
                                            <ListItemText primary={i.log_entry} secondary={`${i.log_by} - ${Helpers.SQLtoUTCDate(i.created)} ${i.id_order != null&&`- Order: VE${i.id_order}`}`}/>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                            }

                            {searchResults.subscriptions.length > 0 && 
                            <div>
                                <Typography variant="subtitle2">Subscriptions</Typography>
                                <List dense={true}>
                                    {searchResults.subscriptions.map(i => (
                                        <ListItem button key={i.id_subscription} onClick={()=>handleChangeRoute('/order/'+i.id_order)}>
                                            <ListItemText primary={`${i.subscription_description} - ${i.sockets}`} secondary={`${i.organisation_name} Expires: ${Helpers.isNull(Helpers.SQLtoUTCDate(i.expiry_date, false), '')}`}/>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                            }

                            {searchResults.tickets.length > 0 && 
                            <div>
                                <Typography variant="subtitle2">Tickets</Typography>
                                <List dense={true}>
                                    {searchResults.tickets.map(i => (
                                        <ListItem button key={i.ticket_ref} onClick={()=>handleChangeRoute('/tickets/monitor/'+i.ticket_ref)}>
                                            <ListItemText primary={`#${i.ticket_ref} - ${i.ticket_subject}`} secondary={i.ticket_description} />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                            }

                        </div>
                    }
                </Paper>
            </Collapse>

        </div>


    )

}

export default GlobalSearch