import React, { useState, useContext, useEffect} from 'react';
import GlobalContext from '../../context/global-context';
import { CircularProgress, DialogContent, DialogActions, Dialog, Typography, Button, List } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import OrderAttachment from './order_attachment';

const OrderRenewalDialog = props => {

    const context = useContext(GlobalContext);
    const history = useHistory();

    const [loadingAttachments, setLoadingAttachments] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [selectedAttachments, setSelectedAttachments] = useState([]);
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleClose = () => {
        if(typeof props.handleClose == 'function')
            props.handleClose()
    }

    const handleSubmit = async () => {

        setLoading(true);
        setError(null);

        try {

            // Send in any attachments that need moving across with the renewal
            let copyAttachments = [...selectedAttachments];
            if(selectedAttachments.length > 0)
                selectedAttachments.map(sa => {
                    const findAttachment = attachments.find(a => a.id_order_attachment === sa);
                    if(typeof findAttachment !== 'undefined' && typeof findAttachment.revisions !== 'undefined')
                        findAttachment.revisions.map(ar => copyAttachments.push(ar.id_order_attachment));
                })

            const res = await context.apiRequest(`/order/renew`, 'POST', {id_order: props.order.id_order, attachments: copyAttachments});
            
            if(res.success){
                context.showAlert('success', `Renewal order created VE${res.result}`);

                if(typeof history != 'undefined')
                    history.push('/order/'+res.result);
            } else 
                setError(res.msg);


        } catch (error) {
            setError(typeof error === 'string' ? error : typeof error.msg !== 'undefined' ? error.msg : 'Something went wrong, please try again');
            console.log(error);
        } finally {
            setLoading(false);
        }

    }


    const fetchAttachments = () => {
        setLoadingAttachments(true);

        context.apiRequest(`order/attach/${props.order.id_order}`, 'GET').then(
            res => {
                setLoadingAttachments(false);
                groupAttachments(res.result);
            },
            err => {
                context.showAlert('error', `Error fetching attachments: ${err.msg}`)
                console.log(err);
            }
        )
    }

    const groupAttachments = result => {
        let revGrouped = [];
        result.map(r => {
            const aIdx = revGrouped.findIndex(rg => rg.order_attachment_name === r.order_attachment_name);
            if(aIdx > -1)
                revGrouped[aIdx].revisions.push(r);
            else
                revGrouped.push({
                    ...r,
                    revisions: []
                })
        })
        setAttachments(revGrouped);
    }

    const handleToggleAttachment = (a) => {
        const currentIndex = selectedAttachments.indexOf(a.id_order_attachment);
        const newSelectedAttachments = [...selectedAttachments];
    
        if (currentIndex === -1) {
            newSelectedAttachments.push(a.id_order_attachment);
        } else {
            newSelectedAttachments.splice(currentIndex, 1);
        }
    
        setSelectedAttachments(newSelectedAttachments);
    };

    useEffect(fetchAttachments, []);

    
    return (
        <Dialog open={true} onClose={handleClose} aria-labelledby="delete-socket-dialog-title">
            <DialogContent style={{maxWidth: 500}}>
                <Typography variant="h6" style={{marginBottom: 10}}>Create Renewal Order?</Typography>
                <Typography variant="subheading" style={{marginBottom: 20}}>This will create a new order from this order for the purposes of renewing the existing subscriptions.</Typography>
                {error && <Alert severity="error" style={{marginTop: 10}}>{error}</Alert>}

                <Typography variant="subtitle1" style={{marginTop: 10}}>Carry attachments</Typography>
                <Typography variant="caption">Select any attachments you wish to also be available in the renewal order.</Typography>
                {loadingAttachments && 
                    <div><CircularProgress size={24} /><Typography variant="body1" color="primary">&nbsp;Loading attachments</Typography></div>
                }
                {(!loadingAttachments && attachments.length === 0) && <div><Typography variant="body1" color="textSecondary">No attachments</Typography></div>}
                <List dense={true}>
                    {attachments.map((a, idx) => (
                        <OrderAttachment attachment={a} kay={idx} onDeleteSuccess={groupAttachments} selectable={true} checked={selectedAttachments.indexOf(a.id_order_attachment) > -1} onToggle={() => handleToggleAttachment(a)} />
                    ))}
                </List>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">Cancel</Button>
                <Button onClick={handleSubmit} color="secondary" disabled={loading}>Confirm {loading && <CircularProgress color="inherit" size={18} />}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default OrderRenewalDialog;