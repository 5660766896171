import React, { useEffect, useState } from "react"
import {TextField, InputAdornment, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const SearchBar = props => {

    const [search, setSearch] = useState('');
    const [shrink, setShrink] = useState(typeof props.shrink !== 'undefined' ? props.shrink : true);

    const handleChange = e => {
        setSearch(e.target.value);
    }

    const handleClear = e => {
        setSearch('');
    }

    const handleKeyPress = e => {
        if(e.key == 'Enter' && typeof props.onSearch === 'function')
            props.onSearch(search);
    }

    useEffect(() => {
        if(search === ''){
            if(typeof props.shrink !== 'undefined')
                setShrink(props.shrink);

            if(typeof props.onClear === 'function')
                props.onClear();
        } else
            if(shrink === false)
                setShrink(true);

    }, [search])

    return (
        <TextField 
        name={'Search'} 
        type="text" 
        value={search} 
        onChange={handleChange} 
        InputLabelProps={{shrink: shrink}} 
        label={props.label || 'Search'} 
        variant="outlined" 
        fullWidth
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                {search.length != '' && <IconButton onClick={handleClear}><CloseIcon color="secondary" /></IconButton>}
                </InputAdornment>
            ),
        }}
        onKeyPress={handleKeyPress}
        autoComplete="off" />
    )
}

export default SearchBar