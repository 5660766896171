import React from 'react';
import GlobalContext from '../../context/global-context';
import Helpers from '../global/helpers';
import querystring from 'query-string';
import {
    Table, 
    TableBody, 
    TableCell, 
    TablePagination, 
    TableRow, 
    TextField, 
    Typography, 
    Checkbox, 
    Button, 
    Paper, 
    TableContainer, 
    TableHead, 
    TableSortLabel, 
    Fab,
    InputAdornment,
    IconButton,
    Tabs,
    Tab,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Tooltip,
    CircularProgress,
    Divider,
    Chip
} from '@material-ui/core/';
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import TocIcon from '@material-ui/icons/Toc'
import ArrowDownIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpIcon from '@material-ui/icons/ArrowUpward'
import DownloadIcon from '@material-ui/icons/GetApp'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { withTheme } from '@material-ui/core/styles';

import Refresh from '../global/Refresh'

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

//
// The headers for the table with sorting
//

const headCells = ['id_order', 'organisation_name', 'order_name', 'agreement_issued', 'agreement_counter_signed', 'commissioned', 'scheme', 'paid', 'order_value']


const EnhancedTableHead = props => {
    const { order, orderBy, onRequestSort, headers, cleanHeader } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    const getOrderBy = property => {
        const idx = orderBy.findIndex(o => o.orderBy === property);
        if(idx > -1)
            return orderBy[idx].order;
        
        return false;
    }
  
    return (
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell
              key={header}
              align={'left'}
              sortDirection={getOrderBy(header)}
            >
              <TableSortLabel
                active={getOrderBy(header) !== false}
                direction={getOrderBy(header) || 'asc'}
                onClick={createSortHandler(header)}
              >
                {cleanHeader(header)}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  
  const OrderChips = props => {

    const { orderBy, handleDelete, handleDeleteAll, cleanHeader } = props;

    return (
      <div style={{paddingBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Typography variant="body2">Sort: </Typography>
        {orderBy.map(o => (
            <Chip
                key={o.orderBy}
                size="small"
                icon={o.order == 'desc' ? <ArrowDownIcon /> : <ArrowUpIcon />}
                label={cleanHeader(o.orderBy)}
                style={{marginLeft: 5, marginRight: 5}}
                onDelete={() => handleDelete(o.orderBy)}
            />
        ))}
        {orderBy.length > 1 && <Button color="secondary" onClick={handleDeleteAll}>Clear</Button>}
      </div>
    );
}




const ColumnDialog = props => {
    const { columns, display, handleColumnClick, open, handleClose, cleanHeader, handleDisplayOrder, handleReset } = props;

    const onDragEnd = result => {
        const { destination, source, draggableId } = result;

        if(!destination)
            return;

        if(destination.droppableId === source.droppableId && destination.index === source.index)
            return;

        handleDisplayOrder(source.index, destination.index, draggableId)
    }
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="column-dialog" open={open}>
      
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <DialogTitle id="column-dialog-title">Show / Hide columns</DialogTitle>
            <Button color={"secondary"} onClick={handleReset}>Reset</Button>
        </div>
        
        <DragDropContext onDragEnd={onDragEnd}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'top'}}>
            
                <List 
                    style={{flex: 1}}
                    subheader={
                        <ListSubheader component="div" id="column-list-subheader">Columns</ListSubheader>
                    }
                >
                {columns.sort().map(value => (
                    <ListItem key={value} role={undefined} dense button onClick={() => handleColumnClick(value)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={display.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText id={value} primary={cleanHeader(value)} />
                    </ListItem>
                ))}
                </List>
                
                <Droppable droppableId={'col-sort'}>
                    {provided => (
                        <List 
                        innerRef={provided.innerRef}
                        {...provided.droppableProps}
                        style={{flex: 1}}
                        subheader={
                            <ListSubheader component="div" id="column-list-subheader">Order</ListSubheader>
                        }>
                            {display.map((value, idx) => (
                                <Draggable key={value} draggableId={value} index={idx}>
                                    {provided=>(
                                        <ListItem 
                                            {...provided.draggableProps}
                                            innerRef={provided.innerRef} 
                                            role={undefined} 
                                            dense >
                                            <ListItemIcon {...provided.dragHandleProps}><Tooltip title="Drag"><DragIndicatorIcon /></Tooltip></ListItemIcon>
                                            <ListItemText id={value} primary={cleanHeader(value)} />
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            
            </div>
        </DragDropContext>
      </Dialog>
    );
  }
  


// Orders class


class Orders extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);
        
        const qs = querystring.parse(this.props.location.search);

        this.state = {
            columns: [],
            orders: [],
            display: this.getDisplayColumns(),
            filterdOrders: [],
            loading: false,
            preset: typeof this.props.match.params.preset != 'undefined'?this.props.match.params.preset:null,
            table: {
                page: typeof qs.page != 'undefined'?parseInt(qs.page):0,
                rowsPerPage: this.getRowsPerPage(),
                order: typeof qs.order != 'undefined'?qs.order:'desc',
                orderBy: typeof qs.order_by != 'undefined'?Helpers.basetoObj(qs.order_by, []):[
                    {
                        order: 'desc',
                        orderBy: 'id_order'
                    }
                ]
            },
            search: typeof qs.search != 'undefined'?qs.search:'',
            tab: typeof qs.tab != 'undefined'?parseInt(qs.tab):1,
            showColumnDialog: false,
            downloading: false
        };
    }

    componentDidMount(){
        this.getOrders();
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.preset != prevProps.match.params.preset)
            window.location.reload(false);
    } 

    getOrders = () => {
        this.setState({
            loading: true
        }, () => {
            this.context.fetchOrders().then(
                res => {
                    const orders = this.checkPreset(res.result);
                    this.setState({
                        columns: Object.keys(res.result[0]),
                        orders: orders,
                        filterdOrders: orders,
                        loading: false
                    }, () => {
                        if(this.state.search.length > 0 && this.state.search != '')
                            this.search();
                    })
                },
                err => {
                    this.setState({
                        loading: false
                    })
                    this.context.showAlert('error', `Error loading orders: ${err.msg}`);
                }
            )
        })
    }

    checkPreset = (orders) => {

        let filtered = JSON.parse(JSON.stringify(orders));
        if(this.state.preset != null)
            switch(this.state.preset.toUpperCase()){
                case 'NOTSHIPPED':
                    filtered = filtered.filter(o => o.id_order_status == 1 && o.proforma_outstanding == 'No' && o.invoiced == 'No' && o.paid == 'No');
                    break;
                case 'AWAITINGPROFORMA':
                    filtered = filtered.filter(o => o.id_order_status > 1 && o.id_order_status < 5 && o.proforma_outstanding == 'No' && o.invoiced == 'No' && o.paid == 'No');
                    break;
                case 'PROFORMAUNPAID':
                    filtered = filtered.filter(o => o.id_order_status > 1 && o.id_order_status < 5 && o.outstanding_balance > 0 && o.proforma_outstanding == 'Yes');
                    break;
                case 'INVOICEUNPAID':
                    filtered = filtered.filter(o => o.id_order_status > 1 && o.id_order_status < 5 && o.outstanding_balance > 0 && o.invoiced == 'Yes');
                    break;
                case 'OTHER':
                    filtered = filtered.filter(o => o.id_order_status == 1 && (o.proforma_outstanding == 'Yes' || o.invoiced == 'Yes' || o.paid == 'Yes'));
                    break;
                case 'CHARGEPOINTSINCOMPLETE':
                    filtered = filtered.filter(o =>  o.id_order_status > 1 && o.id_order_status < 5 && parseInt(o.incomplete_charge_points) > 0);
                    break;
                case 'OCPPCONFIG':
                    filtered = filtered.filter(o =>  o.id_order_status < 5 && parseInt(o.incomplete_charge_points_ocpp) > 0);
                    break;
            }

        return filtered;

    }

    getDisplayColumns = () => {    
        var display = null;

        if(typeof this.props.match.params.preset != 'undefined')
            display = localStorage.getItem('ord_col_'+this.props.match.params.preset);

        if(display != null){
            try {
                return JSON.parse(display);
            } catch (error) { 
                return [...headCells];
            }
        } else {
            display = localStorage.getItem('ord_col');

            if(display != null){
                try {
                    return JSON.parse(display);
                } catch (error) { 
                    return [...headCells];
                }
            } else {
                return [...headCells];
            }
        }
    }

    getRowsPerPage = () => {    
        const localRows = localStorage.getItem('rows');
        if(localRows !== null && !isNaN(JSON.parse(parseInt(localRows))))
            return JSON.parse(parseInt(localRows));
        else
            return 25
    }

    goToOrder = (e, id) => {
        this.props.history.push(`/order/${id}`);
    }

    handleTabChange = (e, val) => {
        this.setState({
            tab: val,
            table: {
                ...this.state.table,
                page: 0
            }
        }, this.handleClearSearch)
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            table: {
                ...this.state.table,
                page: newPage
            }
        }, this.handleQueryChange);
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            table: {
                ...this.state.table,
                page: 0,
                rowsPerPage: parseInt(event.target.value, 10)
            }
        }, () => {
            localStorage.setItem('rows', parseInt(event.target.value, 10));
            this.handleQueryChange()
        });
    };

    handleRequestSort = (event, property) => {
        // const isAsc = this.state.table.orderBy === property && this.state.table.order === 'asc';
        
        // this.setState({
        //     table: {
        //         ...this.state.table,
        //         order: isAsc ? 'desc' : 'asc',
        //         orderBy: property
        //     }
        // }, this.handleQueryChange);


        var newOrder = JSON.parse(JSON.stringify(this.state.table.orderBy));

        const idx = newOrder.findIndex(o => o.orderBy === property);
        if(idx === -1){
            newOrder.push({
                orderBy: property,
                order: 'desc'
            })
        } else {
            const isAsc = newOrder[idx].order === 'asc';
            if(isAsc){
                // Remove from order
                //newOrder.splice(idx, 1);
                newOrder[idx].order = 'desc'
            } else {
                newOrder[idx].order = 'asc'
            }
        }
        
        this.setState({
            table: {
                ...this.state.table,
                orderBy: newOrder
            }
        }, this.handleQueryChange);
    };

    handleDeleteSort = (property) => {

        var newOrder = JSON.parse(JSON.stringify(this.state.table.orderBy));

        const idx = newOrder.findIndex(o => o.orderBy === property);
        if(idx > -1){
            newOrder.splice(idx, 1);
        }
        
        this.setState({
            table: {
                ...this.state.table,
                orderBy: newOrder
            }
        });

    };

    handleDeleteAllSort = (property) => {

        this.setState({
            table: {
                ...this.state.table,
                orderBy: []
            }
        });

    };

    handleSearchChange = e => {
        this.setState({
            search: e.target.value
        })
    }

    handleSearchKeyPress = e => {
        if(e.key == 'Enter')
            this.search();
    }

    handleClearSearch = () => {
        this.setState({
            search: '',
            filterdOrders: this.state.orders
        }, this.handleQueryChange)
    }

    handleQueryChange = () => {

        let query = {};
        if(this.state.search != '')
            query.search = this.state.search;

        if(typeof query.search == 'undefined' && this.state.tab > 0)
            query.tab = this.state.tab;
        
        if(this.state.table.page != 0)
            query.page = this.state.table.page;
        
        if(this.state.table.order != 'desc')
            query.order = this.state.table.order;

        if(this.state.table.orderBy != 'id_order')
            query.order_by = btoa(JSON.stringify(this.state.table.orderBy));
        
        this.props.history.replace('?'+Helpers.makeQueryString(query));

    }


        
    search = () => {
        this.handleQueryChange();

        // Search can be called on load if there is a query string with the search string in

        let orders = this.state.orders;
        const searchStr = this.state.search.toUpperCase();

        if(this.state.search.length > 0 && this.state.search != ''){
            orders = this.state.orders.filter(p => (
                this.isNull(p.id_order).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull('VE'+p.id_order).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.order_name).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.order_description).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.organisation_name).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.manufacturer_ref).toUpperCase().indexOf(searchStr) > -1
            ));
        }

        // Reset the tab to 0 to ensure it is a global search and not per tab
        this.setState({
            filterdOrders: orders,
            tab: 0
        }, () => {

            // If a page number exists in the query string and that page doesn't exist in the results return to page 0
            if(this.state.filterdOrders.length <= (this.state.table.rowsPerPage * this.state.table.page))
                this.setState({
                    table: {
                        ...this.state.table,
                        page: 0
                    }
                }, this.handleQueryChange)
        });
    }

    isNull = (str) => {
        if(typeof str == 'undefined' || str == null)
            return ''
        
        return str.toString();
    }
    

    render(){

        const rows = this.state.filterdOrders.filter(r => {

            // Tab 0 is when a search exists
            switch(this.state.tab){
                case 0:
                    return true
                case 1:
                    return [1,2,3,7,8].indexOf(r.id_order_status) > -1;
                case 2:
                    return r.id_order_status == 4;
                case 3: 
                    return r.id_order_status == 5;
            }
            if(this.state.tab === 0)
                return true;

        });

        const page = this.state.table.page;
        const rowsPerPage = this.state.table.rowsPerPage;
        const orderBy = this.state.table.orderBy;
        const order = this.state.table.order;
        const tabs = ['Search', 'Open', 'Complete', 'Cancelled'];
        const tab = tabs[this.state.tab]
        const preset = () => {
            if(this.state.preset == null)
                return '';
            else
                switch(this.state.preset.toUpperCase()){
                    case 'NOTSHIPPED':
                        return 'Not Shipped'
                        break;
                    case 'AWAITINGPROFORMA':
                        return 'Awaiting Proforma / Invoice'
                        break;
                    case 'PROFORMAUNPAID':
                        return 'Unpaid Proforma'
                        break;
                    case 'INVOICEUNPAID':
                        return 'Unpaid Invoice'
                        break;
                    case 'OTHER':
                        return 'Other'
                        break;
                    case 'SOCKETSINCOMPLETE':
                        return 'Sockets Incomplete'
                        break;
                    case 'OCPPCONFIG':
                        return 'OCPP Configuration Required'
                        break;
                }
        }

        // const descendingComparator = (a, b, orderBy) => {
        //     let x = a[orderBy]!=null?a[orderBy]:'';
        //     let y = b[orderBy]!=null?b[orderBy]:'';

        //     if (y < x) return -1;
        //     if (y > x) return 1;

        //     return 0;
        // }

        // const getComparator = (order, orderBy) => {
        //     return order === 'desc'
        //       ? (a, b) => descendingComparator(a, b, orderBy)
        //       : (a, b) => -descendingComparator(a, b, orderBy);
        // }

        // const tableSort = (array, comparator) => {
        //     const stabilizedThis = array.map((el, index) => [el, index]);
        //     stabilizedThis.sort((a, b) => {
        //       const order = comparator(a[0], b[0]);
        //       if (order !== 0) return order;
        //       return a[1] - b[1];
        //     });
        //     return stabilizedThis.map(el => el[0]);
        //   }


        // const comparator = (a, b, orderBy, order = 'desc') => {
        //     var x, y;

        //     if(Array.isArray(orderBy)){

        //         orderBy.map(z => {
        //             x = a[z]!=null?a[z]:'';
        //             y = b[z]!=null?b[z]:'';

        //             if(isNaN(x) === false)
        //                 x = Number(x);

        //             if(isNaN(y) === false)
        //                 y = Number(x);

        //             if(x !== y){
        //                 if (y < x) return order == 'desc' ? -1 : 1;
        //                 if (y > x) return order == 'desc' ? 1 : -1;
        //             }
        //         })

        //     } else {

        //         x = a[orderBy]!=null?a[orderBy]:'';
        //         y = b[orderBy]!=null?b[orderBy]:'';
    
        //         if (y < x) return order == 'desc' ? -1 : 1;
        //         if (y > x) return order == 'desc' ? 1 : -1;

        //     }
        //     return 0;
        // }

        // const tableSort = array => {
        //     var revOrder = JSON.parse(JSON.stringify(orderBy)).reverse();
        //     var arr = [...array];
        //     revOrder.map(o => {
        //         arr = arr.sort((a, b) => {
        //             return comparator(a, b, o.orderBy, o.order)
        //         })
        //     })
        //     return arr;
        // }


        const handleColumnClick = val => {
            const idx = this.state.display.indexOf(val);
            let display = [...this.state.display];
            if(idx > -1)
                display.splice(idx, 1)
            else
                display.push(val)
            
            this.setState({display: display}, () => {
                saveDisplayColumns(display);
            })
        }

        const handleDisplayOrder = (src, dest, val) => {
            const display = Array.from(this.state.display);
            display.splice(src, 1);
            display.splice(dest, 0, val);
            this.setState({display: display}, () => {
                saveDisplayColumns(display);
            });
        }

        const resetDisplayColumns = () => {
            this.setState({display: [...headCells]}, () => {
                saveDisplayColumns(headCells);
            });
        }

        const saveDisplayColumns = (display) => {
            let name = 'ord_col';
            if(this.state.preset != null)
                name = name + '_' + this.state.preset;
            localStorage.setItem(name, JSON.stringify(display));
        }

        const handleShowColumnDialog = () => {
            this.setState({showColumnDialog: true});
        }

        const sanitizeValue = (val, col) => {

            switch(col){
                case 'id_order':
                    return 'VE'+val;
                    break;
                case 'order_value':
                case 'outstanding_balance':
                    return val != null?'£'+val.toLocaleString('en-GB'):'';
                    break;
                case 'teckpro_order':
                    return val === 1?'YES':'NO';
                    break;
            }

            if(val != null && val.toString().match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/))
                return Helpers.SQLtoUTCDate(val, false);

            return Helpers.isNull(val, '');
        }

        

        // Makes the columns name more presentable
        const cleanHeader = header => {
            let newHeader = header;
            if(header.indexOf('id_') > -1)
                newHeader = newHeader.replace('id_', '') + ' ID';

            newHeader = newHeader.replace(/_/gi, ' ');
            return newHeader.charAt(0).toUpperCase() + newHeader.slice(1)
        }


        const handleDownload = () => {
            this.setState({downloading: true}, () => {
                let csvStr = '';
        
                // Add the headers
                this.state.display.map(h => {
                    csvStr = csvStr + cleanHeader(h) + ',';
                })

                csvStr = csvStr.substring(0,csvStr.length - 1);
                csvStr = csvStr + "\n";
        
                // Add the data
                Helpers.multiSort(rows, orderBy).map(row => {

                    this.state.display.map(x => {
                        csvStr = csvStr + '"' + sanitizeValue(row[x], x) + '",';
                    })

                    csvStr = csvStr.substring(0,csvStr.length - 1);
                    csvStr = csvStr + "\n";
                })
        
                csvStr = csvStr.substring(0, csvStr.length - 1);

                const filename = `orders-${new Date().getTime()}`;

                var blob = new Blob([csvStr], { type: 'text/csv' });
        
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                console.log('using window navigator');
                window.navigator.msSaveOrOpenBlob(blob, filename+'.csv');
                this.setState({downloading: false});
                } else { // for Non-IE (chrome, firefox etc.)
                console.log('not using window navigator');
                let a = document.createElement('a');
                a.style.display = 'none';
                a.href = 'data:text/csv;charset=utf-8,'+encodeURIComponent("\uFEFF"+csvStr);
                a.download = filename+'.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                this.setState({downloading: false});
                }
            });

        }

        return(
            <div style={styles.container}>
                <div style={styles.inner}>

                    <div style={styles.taskbar}>

                        <Typography variant="h4">{tab} Orders <span style={styles.subheading} color="primary">{preset()}</span></Typography>

                        <div style={{display:'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end'}}>

                            {this.state.preset == null && <Tabs
                                value={this.state.tab}
                                onChange={this.handleTabChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label="status"
                            >
                                <Tab value={1} label="Open" />
                                <Tab value={2} label="Complete" />
                                <Tab value={3} label="Cancelled" />
                                <Tab value={0} label="All" />
                            </Tabs>}

                            <Refresh handleClick={this.getOrders} loading={this.state.loading} />

                            <Fab onClick={() => {this.props.history.push('/order/add')}} color="secondary" size="medium" aria-label="new order">
                                <AddIcon />
                            </Fab>
                        </div>
                    </div>
                    
                    <div style={styles.taskbar}>
                        <div style={{display: 'flex', flex: 1}}>
                            <TextField 
                                name={'Search'} 
                                type="text" 
                                value={this.state.search} 
                                onChange={this.handleSearchChange} 
                                InputLabelProps={{shrink: true,}} 
                                label="Search Orders" 
                                variant="outlined" 
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {this.state.search.length != '' && <IconButton onClick={this.handleClearSearch}><CloseIcon color="secondary" /></IconButton>}
                                      </InputAdornment>
                                    ),
                                }}
                                onKeyPress={this.handleSearchKeyPress} />
                        </div>
                        <div style={{width: 20}}></div>
                        <div>
                            <Button variant="contained" color={'secondary'} onClick={this.search} style={{marginRight: 10}}>Search</Button>
                            
                            <Tooltip title="Select Columns" aria-label="select-columns"><IconButton variant="contained" color="secondary" onClick={handleShowColumnDialog}><TocIcon /></IconButton></Tooltip>
                            <Tooltip title="Download to .csv" aria-label="download"><IconButton variant="contained" color="secondary" onClick={handleDownload}>
                                {!this.state.downloading && <DownloadIcon />}
                                {this.state.downloading && <CircularProgress color="inherit" size={18} />}
                            </IconButton></Tooltip>
                        </div>
                    </div>

                    {orderBy.length > 0 && <OrderChips orderBy={orderBy} handleDelete={this.handleDeleteSort} handleDeleteAll={this.handleDeleteAllSort} cleanHeader={cleanHeader} />}

                    <Paper style={styles.root}>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        <Divider />
                        <TableContainer>
                            <Table>
                                
                                <EnhancedTableHead
                                    headers={this.state.display}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={rows.length}
                                    cleanHeader={cleanHeader}
                                />
                                <TableBody>
                                {Helpers.multiSort(rows, orderBy).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                                    <TableRow hover onClick={event => this.goToOrder(event, row.id_order)} key={row.id_order} style={{cursor: 'pointer'}}>
                                        {this.state.display.map(x => (
                                            <TableCell>{sanitizeValue(row[x], x)}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>

                </div>

                <ColumnDialog 
                    columns={this.state.columns}
                    display={this.state.display}
                    open={this.state.showColumnDialog}
                    cleanHeader={cleanHeader}
                    handleColumnClick={handleColumnClick}
                    handleClose={() => this.setState({showColumnDialog: false})}
                    handleDisplayOrder={handleDisplayOrder}
                    handleReset={resetDisplayColumns}
                />
            </div>
        );
    }

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    },
    subheading: {
        display: 'block',
        fontSize: '.6em',
        fontWeight: 600,
        color: '#727272'
    }
}

export default withTheme(Orders)