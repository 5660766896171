import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';
import globalContext from '../../../context/global-context';


const TicketUnitGroups = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Ticket Unit Groups',
                            route: '/settings/ticket_unit_gruops'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Ticket Unit Groups</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>These are umbrella groups for units to live in, these could be clusters of an organisation or a department that may be broken into multiple teams (units).</Typography>

                <Crud
                    uri='ticket_unit_groups'
                    idCol='id_ticket_unit_group'
                    displayColumns={[
                        { 
                            name: 'ticket_unit_group_name', 
                            type: 'string',
                            editable: true,
                            required: true
                        }, 
                        { 
                            name: 'id_network',
                            display_name: 'Network', 
                            type: 'select',
                            editable: true
                        }
                    ]}
                    selectors={[
                        {
                            uri: 'networks',
                            name: 'network',
                            col_name: 'id_network',
                            selector_name: 'id_network',
                            display_col: 'network_name'
                        }
                    ]}
                />

            </div>
        </div>
    )

}

export default TicketUnitGroups
