import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withTheme } from '@material-ui/styles';
import Helpers from '../global/helpers';


const batch = props => {

    return (
        <div style={Object.assign({}, !props.show&&styles.hide)}>

            <Paper style={styles.root}>
                <Table style={styles.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Group Scheme</TableCell>
                            <TableCell align="right">Total Gross</TableCell>
                            <TableCell align="right">Total Net</TableCell>
                            <TableCell align="right">Total VAT</TableCell>
                            <TableCell align="right">Net Service Charge</TableCell>
                            <TableCell align="right">Net Management Fee</TableCell>
                            <TableCell align="right">Management VAT</TableCell>
                            <TableCell align="right">Adjustments</TableCell>
                            <TableCell align="right">Amount Due</TableCell>
                            <TableCell align="right">Paid</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.batch.map(row => (
                        <TableRow key={row.groupSchemeID} selected={row.Selected}>
                            <TableCell>{row.groupSchemeName}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.TotalGross, row.Currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.TotalNet, row.Currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.TotalVAT, row.Currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.NetManagementServiceCharge, row.Currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.NetManagementFee, row.Currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.ManagementVAT, row.Currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.Adjustments, row.Currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.SubscriberDue, row.Currency)}</TableCell>
                            <TableCell align="right">{row.PaymentMade != null?Helpers.SQLtoUTCDate(row.PaymentMade, false):''}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </Paper>

            {props.batch.length == 0 && <div style={styles.nodata}>No Data</div>}

            </div>
    )
}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    hide: {
        display: 'none'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    },
    heading: {
        fontSize: 15,
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: 15,
        color: '#a3a3a3',
    },
};

export default withTheme(batch)