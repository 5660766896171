import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, TextField, Button, Menu, MenuItem } from '@material-ui/core';
import Helpers from '../global/helpers';

import { amber, red, orange } from '@material-ui/core/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
    comment: {

    },
    commentFresh: {
        animation: `$flashFresh ease-in-out .5s 1`,
    },
    "@keyframes flashFresh": {
        "0%, 49%": {
            backgroundColor: amber[700]
        },
        "50%, 100%": {
            backgroundColor: 'auto'
        }
    }, 
    primaryText: {
        whiteSpace: 'pre-line'
    }
}));


const TicketComments = props => {
    
    
    const [anchorEl, setAnchorEl] = React.useState(null);


    const theme = useTheme();
    const styles = useStyles(theme);
    const t = props.ticket;

    const isFresh = log => {
        if(Helpers.SQLTimeSinceSecs(log.created) < 20)
            return true;

        return false;
    }


    return (
        <List>
            {t.logs.map((log, idx) => (
                <div key={idx} className={isFresh(log) ? styles.commentFresh : styles.comment}>
                    {log.id_action &&
                        <div style={{display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: 30, backgroundColor: '#455677', color: '#fff', marginBottom: 15}}>
                                <div style={{fontSize: 14, fontWeight: 400}}>{log.log_entry} - <span style={{fontSize: 12, fontWeight: 300}}>{Helpers.isNull(log.user, log.name)} | {Helpers.SQLTimeSince(log.created)}</span></div>
                        </div>
                    }
                    
                    {!log.id_action &&   
                    <ListItem alignItems="flex-start" style={{paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3)}}>
                        
                        <ListItemAvatar>
                            <Avatar style={{backgroundColor: Helpers.getHexColor(Helpers.getInitials(log.name))}}>{Helpers.getInitials(log.name)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                        primary={log.log_entry}
                        classes={{
                            primary: styles.primaryText
                        }}
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2">{log.name}</Typography>
                                {' - ' + Helpers.SQLTimeSince(log.created)}
                                {' | ' + Helpers.SQLtoUTCDate(log.created)}
                            </React.Fragment>
                        }
                        />
                    </ListItem>
                    }
                    
                    {(idx+1) < t.logs.length && <Divider/>}
                </div>

                
                        
                
            ))}
        </List>
    )
}

export default TicketComments
