import React from 'react';

const NotFound = props => {


    const styles  = {
        container: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            overflow: 'auto'
        },
        inner: {
            padding: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexFlow: 'row wrap',
            flexWrap: 'wrap'
        }
    }
        
    return (

        <div style={styles.container}>
            <div style={styles.inner}>
                <h1>Whoops, we can't find where your are going.</h1>
            </div>
        </div>
    );
}

export default NotFound