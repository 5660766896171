import { Typography, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import React from 'react';

const PopupAlert = props => {

  const {show, handleConfirm, handleCancel, title, message, showCancel = true, confirmLabel = 'Confirm'} = props;
  
  return (
      <Dialog open={show} onClose={handleCancel} aria-labelledby="delete-socket-dialog-title">
          <DialogContent>
              {title && <Typography variant="h6" style={{marginBottom: 10}}>{title}</Typography>}
              {message && <Typography variant="subheading">{message}</Typography>}
          </DialogContent>
          <DialogActions>
              {showCancel && <Button onClick={handleCancel} color="default">Cancel</Button>}
              <Button onClick={handleConfirm} color="secondary">{confirmLabel}</Button>
          </DialogActions>
      </Dialog>
  )

}

export default PopupAlert;