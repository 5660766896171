import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Paper, InputBase, makeStyles, IconButton, CircularProgress } from '@material-ui/core';
import globalContext from '../../context/global-context';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 260,
        height: 48
    },
    paper: {
        padding: '0px 16px', 
        flex: 1, 
        display: 'flex', 
        height: 48,
        alignItems: 'center'
    }
}));

const SageOrderSearch = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSearchKeyPress = e => {
        if(e.key == 'Enter')
            handleSearchOrders();
    }

    const handleSearchOrders = async () => {
        if(search.length > 0){
            setLoading(true);
            try {

                const res = await context.apiRequest(`/sage/orders/search?search=${search}`, 'GET');
                
                if(res.success && typeof props.handleSearchResults === 'function')
                    props.handleSearchResults(res.result);

            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    }

    const handleClearSearch = () => {
        setSearch('');
        if(typeof props.handleClearSearch === 'function')
            props.handleClearSearch();
    }

    return (
        <React.Fragment>
            <div className={classes.container}>
                <Paper className={classes.paper} elevation={0}>
                    <InputBase
                        name={'Search'} 
                        type="text" 
                        value={search} 
                        onChange={e => setSearch(e.target.value)} 
                        placeholder="Sage Order No"
                        inputProps={{ 'aria-label': 'Search' }}
                        autoComplete="off"
                        onKeyPress={handleSearchKeyPress}
                        style={{flex: 1}}
                    />
                    {search.length != '' &&
                        <IconButton onClick={handleClearSearch}>
                            <CloseIcon color="default" />
                        </IconButton>
                    }
                    {!loading && 
                        <IconButton type="submit" aria-label="search" onClick={handleSearchOrders}>
                            <SearchIcon color="secondary" />
                        </IconButton>
                    }
                    {loading && <CircularProgress size={24} color="secondary" />}
                </Paper>
            </div>
        </React.Fragment>
    )
}
export default SageOrderSearch;