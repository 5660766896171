import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const GprsNetworks = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'GPRS Networks',
                            route: '/settings/gprs_networks'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">GPRS Networks</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>A categorization of GPRS networks available on this system.</Typography>

                <Crud
                    heading='GPRS Networks'
                    uri='gprs_networks'
                    idCol='id_gprs_network'
                    displayColumns={[
                        { 
                            name: 'gprs_network', 
                            display_name: 'GPRS Network',
                            type: 'string',
                            editable: true,
                            required: true
                        }
                    ]}
                />

            </div>

        </div>
    )

}

export default GprsNetworks
