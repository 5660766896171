import React, {useContext, useState, useEffect} from 'react';
import { useTheme, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Helpers from '../../../global/helpers';

import AutoTable from '../../../../components/autoTable';
import globalContext from '../../../../context/global-context';
const useStyles = makeStyles(theme => ({
    container: {
        display: 'block',
        width: '100%',
        maxHeight: 300,
        overflowY: 'auto'
    },
    inner: {},
}));

const ChargepointRecentTests = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const {id_chargepoint} = props;

    const headCells = ["timestamp", "connector", "meter_start", "meter_stop", "total_usage", "csq", "error_code", "pass"]
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const loadData = async () => {
        setLoading(true);
        setError(null);
        setData([]);

        try {
            
            const res = await context.apiRequest(`/service_consumer/ev_connect/chargepoint/recent_tests?id=${id_chargepoint}`, 'GET');
            if(res.success)
                setData(res.result);
            else
                throw res.msg;
            
        } catch (err) {

            setError(typeof err === 'string' ? err : typeof err?.msg === 'string' ? err.msg : JSON.stringify(err));
            setData([]);
            
        } finally {
            setLoading(false);
        }
        
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>

                    {error && <Alert severity="error">{error}</Alert>}

                    <AutoTable 
                        rows={data} 
                        idCol="id"
                        headCells={headCells}
                        loading={loading}
                        cellReplacementProps={{
                            'timestamp': (val, id) => Helpers.toLocaleDateTime(val)
                        }}
                    />

                </div>
            </div>
        </React.Fragment>
    )
}
export default ChargepointRecentTests;