import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const TicketUnitUsers = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Ticket Unit Users',
                            route: '/settings/ticket_unit_users'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Ticket Unit Supervisors</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>If a user is to be part of a unit, assign them here and they will see the tickets for that unit.</Typography>

                <Crud
                    uri='ticket_unit_users'
                    idCol='id_ticket_unit_user'
                    displayColumns={[
                        { 
                            name: 'id_user', 
                            type: 'select',
                            display_name: 'User',
                            editable: true,
                            required: true
                        }, 
                        { 
                            name: 'id_ticket_unit', 
                            type: 'select',
                            display_name: 'Unit',
                            editable: true,
                            required: true
                        }
                    ]}
                    selectors={[
                        {
                            uri: 'users',
                            name: 'user',
                            col_name: 'id_user',
                            selector_name: 'id_user',
                            display_col: 'name'
                        },
                        {
                            uri: 'ticket_units',
                            name: 'unit',
                            col_name: 'id_ticket_unit',
                            selector_name: 'id_ticket_unit',
                            display_col: 'ticket_unit_name'
                        }
                    ]}
                    canRemove={true}
                />

            </div>
        </div>
    )

}

export default TicketUnitUsers
