import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';

import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Typography } from '@material-ui/core';


const Orders = props => {

    // Create a local state
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [id_organisation, setOrganisation] = useState(typeof props.id_organisation!='undefined'?props.id_organisation:null);


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    useEffect(() => {
        getOrders();
    }, [])

    const getOrders = () => {
        setLoading(true);

        context.fetchOrganisationOrders(id_organisation).then(
            res => {
                setOrders(res.result);
                setLoading(false);
            }, 
            err => {
                setLoading(false);
                context.showAlert('error', 'error fetching orders: ' + JSON.stringify(err.msg));
            }
        )
    }

    const handleRowClick = id => {
        if(typeof props.history != 'undefined')
            props.history.push('/order/'+id);
    }

        
    return (

        <div style={{display: 'flex', flexDirection: 'column', padding: '10px 0', flex:1}}>
            
            <Typography variant="h6">Orders</Typography>

            {orders.length > 0 &&
            <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Organisation</TableCell>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">Created</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {orders.map(row => (
                        <TableRow key={row.id_order} hover onClick={() => handleRowClick(row.id_order)}>
                            <TableCell component="th" scope="row"><Typography color="secondary" variant="body1">VE{row.id_order}</Typography></TableCell>
                            <TableCell align="right">{row.order_name}</TableCell>
                            <TableCell align="right">{row.organisation_name}</TableCell>
                            <TableCell align="right">{row.order_description}</TableCell>
                            <TableCell align="right">{Helpers.SQLtoUTCDate(row.created, false)}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>}

        
            {orders.length == 0 &&
                <Typography variant="h6" style={{color: 'rgba(0,0,0,.3)'}}>None</Typography>
            }

        </div>
    );
}

export default Orders