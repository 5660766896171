import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';
import { Grid, TextField, Button, FormControl, FormHelperText, CircularProgress, Typography, InputLabel, Select, MenuItem } from '@material-ui/core';


const EditSubscription = props => {
    const [loading, setLoading] = useState(false);

    // Create a local state
    const [subscription, setSubscription] = useState(typeof props.subscription !='undefined' ? props.subscription: null);
    const [modified, setModified] = useState(false);
    const [products, setProducts] = useState([]);


    useEffect(() => {

        setLoading(true)

        context.fetchProducts().then(
            res => {
                setProducts(res.result);
                setLoading(false)
            }, 
            err => {
                setLoading(false)
                context.showAlert('error', 'error fetching products: ' + JSON.stringify(err.msg));
            }
        )
    }, [])


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    const handleChange = e => {
        let newSubscription = {...subscription};
        newSubscription[e.target.name] = e.target.value;
        setSubscription(newSubscription);
        setModified(true);
    }

    const handleProductChange = e => {
        let newSubscription = {...subscription};
        newSubscription[e.target.name] = e.target.value;

        const product = products.find(p => p.id_product == e.target.value);
        const cost = product.price;

        newSubscription.cost = cost;
        setSubscription(newSubscription);
        setModified(true);
    }

    const invalid = () => {
        if(isInvalid('subscription_description') || isInvalid('qty') || isInvalid('id_organisation') || isInvalid('id_order') || isInvalid('id_product'))
            return true;
        
        return false;
    }

    const isInvalid = prop => {
        switch(prop){
            case 'subscription_description':
                return false;
            case 'qty':
                return parseInt(subscription.qty) <= 0?true:false;
            case 'id_organisation':
                return subscription.id_organisation == null?true:false;
            case 'id_order':
                return subscription.id_order == null?true:false;
            case 'id_product':
                return subscription.id_product == null?true:false;
            default:
                return true;
        }
    }

    const submitForm = () => {
        if(!invalid())
            setLoading(true);
            context.saveSubscription(subscription).then(
                res => {
                    setLoading(false);
                    context.showAlert('success', 'Purchase saved');

                    if(typeof props.onSave == 'function')
                        props.onSave();
                },
                err => {
                    setLoading(false);
                    context.showAlert('error', 'Error updating purchase ' + JSON.stringify(err));
                }
            )
    }

    const total = () => {
        const t = parseFloat(parseFloat(subscription.cost) * parseInt(subscription.qty)).toLocaleString('en-gb');
        if(t == 'NaN')
            return 0;

        return t;
    }

    
        
    return (

        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: 20}}>
            <Typography variant="h5">Change purchase</Typography>


            <div style={{flex: 1, margin: '10px 0', padding: 10}}>
                <FormControl fullWidth>
                    <InputLabel id="id_product-label">Product</InputLabel>
                    <Select
                    labelId="id_product-label"
                    name="id_product"
                    value={subscription.id_product}
                    onChange={handleProductChange}
                    label="Product"
                    >
                    {products.map(p => (
                        <MenuItem key={p.id_product} value={p.id_product}>{p.product_description}</MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </div>

            <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                <FormControl fullWidth>
                    <TextField name="subscription_description" value={subscription.subscription_description} onChange={handleChange} label="Purchase Description" error={isInvalid('subscription_description')} />
                </FormControl>
            </div>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 30, justifyContent: 'center', alignItems: 'flex-end', padding: 10}}>
                <div>
                    <FormControl fullWidth>
                        <TextField name="qty" type="number" value={subscription.qty} onChange={handleChange} label="Qty" error={isInvalid('qty')} />
                    </FormControl>
                </div>
                <div style={{flex: 1}}></div>
                <div>
                    <FormControl>
                        <TextField name="cost" value={subscription.cost} onChange={handleChange} label="Cost" />
                    </FormControl>
                </div>
            </div>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 30, justifyContent: 'center', alignItems: 'flex-end', padding: 10}}>
                <div style={{flex: 1}}><Typography variant="h6">Total: £{total()}</Typography></div>
                <div style={{padding: '0 10px'}}>
                    <Button color="secondary" variant="contained" disabled={invalid()} onClick={submitForm}>Save {loading && <CircularProgress size={20} className="ml" color="inherit" />}</Button>
                </div>
            </div>
        </div>

    );
}

export default EditSubscription