import React from 'react';
import Helpers from '../global/helpers';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Quickcrumbs from '../global/Quickcrumbs';


class WalletPayments extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            fields: {
                start: new Date().toISOString().split('T')[0],
                end: new Date().toISOString().split('T')[0]
            },
            loadedFields: {
                start: null,
                end: null
            },
            data: [],
            filteredData: [],
            loading: false,
            downloading: false,
            showDialog: false
        };
    }
    
    handleChange = (e, callback = () => {}) => {
        let fields = {...this.state.fields};
        fields[e.target.name] = e.target.value;
        this.setState({fields: fields}, () => {
            typeof callback === 'function' && callback();
        });
    }
    
    handleCheckboxChange = (e, callback = () => {}) => {
        let fields = {...this.state.fields};
        fields[e.target.name] = e.target.checked;
        this.setState({fields: fields}, () => {
            typeof callback === 'function' && callback();
        });
    }

    closeDialog = () => {
        this.setState({showDialog: false});
    }

    loadData = () => {
        this.setState({loading: true, loadedFields: {...this.state.fields}, data: []});
        this.context.apiRequest(`/service_consumer/wallet/payments?${this.context.queryString(this.state.fields)}`, 'GET')
        .then(
            res => {
                console.log(res);
                const data = res.result;
                this.setState({data: data, loading: false})
            },
            err => {
                console.log(err);
                this.setState({data: [], loading: false})
            }
        );
    }

    dirty = () => {        
        if(this.state.fields.start != this.state.loadedFields.start)
            return true;
        
        if(this.state.fields.end != this.state.loadedFields.end)
            return true;

        return false;
    }


    downloadCSV = () => {
        if(this.dirty()){
            this.setState({showDialog: true});
        } else {
            if(this.state.data.length > 0){
                this.setState({downloading: true});
                let csvStr = '';

        
                // Add the headers
                csvStr = csvStr + 'Name,';
                csvStr = csvStr + 'Email,';
                csvStr = csvStr + 'Transaction Date,';
                csvStr = csvStr + 'Transaction ID,';
                csvStr = csvStr + 'Amount,';
                csvStr = csvStr + 'Currency,';
                csvStr = csvStr + 'Type,';
    
    
                csvStr = csvStr.substring(0,csvStr.length - 1);
                csvStr = csvStr + "\n";
        
                // Add the data
                this.state.data.map(row => { 
    
                    csvStr = csvStr + `${row.FirstName} ${row.LastName}` + ',';
                    csvStr = csvStr + row.Email + ',';
                    csvStr = csvStr + this.SQLtoUTCDate(row.TransactionDateTime) + ',';
                    csvStr = csvStr + row.VendorTxCode + ',';
                    csvStr = csvStr + Helpers.localeCurrency(row.Amount, row.Currency) + ',';
                    csvStr = csvStr + row.Currency + ',';
                    csvStr = csvStr + row.Type + ',';
    
                    csvStr = csvStr.substring(0,csvStr.length - 1);
                    csvStr = csvStr + "\n";
                    
                })
        
                csvStr = csvStr.substring(0, csvStr.length - 1);
    
                const filename = 'wallet_payments_' + this.state.fields.start + '_' + this.state.fields.end;
    
                var blob = new Blob([csvStr], { type: 'text/csv' });
        
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                    console.log('using window navigator');
                    window.navigator.msSaveOrOpenBlob(blob, filename+'.csv');
                    this.setState({downloading: false});
                } else { // for Non-IE (chrome, firefox etc.)
                    console.log('not using window navigator');
                    let a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = 'data:text/csv;charset=utf-8,'+encodeURIComponent("\uFEFF"+csvStr);
                    a.download = filename+'.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    this.setState({downloading: false});
                }

            }
        }
    }

    SQLtoUTCDate = (str, datetime = true) => {
        const d = str.split(/-|T|:|\.|Z/);
        const jD = new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]));
        if(datetime)
            return jD.toLocaleDateString('en-GB') + ' ' + jD.toLocaleTimeString('en-GB');
        
        return jD.toLocaleDateString('en-GB');
    }

    render(){
        return(
            <div style={styles.container}>
                <div style={styles.inner}>

                    <Quickcrumbs 
                        crumbs={[
                            {
                                name: 'Service Users',
                                route: '/service_users'
                            },
                            {
                                name: 'Wallet Payments',
                                route: '/service_users/wallet/payments'
                            }
                        ]}
                        history={this.props.history}
                    />

                    <h2>Wallet Payments</h2>

                    <div style={styles.taskbar}>

                        <div>

                            <TextField name="start" label="Start" type="date" value={this.state.fields.start} onChange={this.handleChange} style={styles.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <TextField name="end" label="End" type="date" value={this.state.fields.end} onChange={this.handleChange} style={styles.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <Button variant="contained" color={this.dirty()?'secondary':'default'} onClick={this.loadData}>Go{this.state.loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>

                        </div>
                        <div>
                            <Button variant="contained" color="secondary" onClick={this.downloadCSV}>Download to .CSV{this.state.downloading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
                        </div>
                    </div>

                    <Paper style={styles.root}>
                        <Table style={styles.table}>
                            <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Transaction Date</TableCell>
                                <TableCell>Transaction ID</TableCell>
                                <TableCell align="right">Amount</TableCell>
                                <TableCell align="right">Currency</TableCell>
                                <TableCell align="right">Type</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.data.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{row.FirstName} {row.LastName}</TableCell>
                                    <TableCell>{row.Email}</TableCell>
                                    <TableCell>{this.SQLtoUTCDate(row.TransactionDateTime)}</TableCell>
                                    <TableCell>{row.VendorTxCode}</TableCell>
                                    <TableCell align="right">{Helpers.localeCurrency(row.Amount, row.Currency)}</TableCell>
                                    <TableCell align="right">{row.Currency}</TableCell>
                                    <TableCell align="right">{row.Type}</TableCell>                                    
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </Paper>

                    {this.state.data.length == 0 && <div style={styles.nodata}>No Data</div>}

                    <Dialog
                        open={this.state.showDialog}
                        onClose={this.closeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Reload the data"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You changed the values in the taskbar but didn't reload the data, reload before you download.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.closeDialog} color="secondary">
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                    

                </div>
            </div>
        );
    }

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default WalletPayments