import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import GlobalContext from '../../context/global-context';
import { useStyles } from './styles';
import Helpers from '../global/helpers';

import TicketVisitAdd from './ticket_visit_add';

import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    TextField,
    Avatar,
    Typography,
    ButtonGroup,
    CircularProgress,
    Grid,
    FormControlLabel,
    Switch
} from '@material-ui/core';
import RichEditor from '../global/RichEditor';

const useCustomStyles = makeStyles(theme => ({
    section: {
        marginBottom: 20
    },
    comment: {
        display: 'flex', 
        flexDirection: 'row',
        marginBottom: 20
    }
}));


const TicketAction = props => {

    const [idTicketStatus, setIdTicketStatus] = useState(props.ticket.id_ticket_status);
    const [idTicketUnit, setIdTicketUnit] = useState(props.ticket.id_ticket_unit);
    const [ newComment, setNewComment ] = useState('');

    const [changeStatus, setChangeStatus] = useState(null);
    const [changeRoute, setChangeRoute] = useState(null);
    const [bookVisit, setBookVisit] = useState(null);
    const [visit, setVisit] = useState(null);
    const [suppressExternal, setSuppressExternal] = useState(null);

    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    const styles = useStyles(theme);
    const customStyles = useCustomStyles(theme);

    const context = useContext(GlobalContext);

    const handleAccept = () => {
        if(valid()){
            if(newComment.length != 0){
                setLoading(true);
                context.routeTicket({
                    id_ticket_unit: changeRoute === true ? idTicketUnit : props.ticket.id_ticket_unit,
                    id_ticket_status: changeStatus === true ? idTicketStatus : props.ticket.id_ticket_status,
                    id_ticket: props.ticket.id_ticket,
                    log_entry: newComment,
                    visit: visit,
                    suppress_external: suppressExternal
                }).then(
                    res => {
                        setLoading(false);
                        props.setTicket(res.result);
                        props.handleUpdateTicket(res.result);
                        close();
                    }, err => {
                        setLoading(false);
                        context.showAlert('error', `There was an error actioning this ticket: ${JSON.stringify(err.msg)}`)
                    }
                );
            }
        }
    }

    const close = () => {
        setNewComment('');
        setChangeStatus(null);
        setChangeRoute(null);
        setBookVisit(null);
        setVisit(null);
        setSuppressExternal(null);
        props.handleClose();
    }

    const valid = () => {
        if(changeStatus != null && changeRoute != null && newComment.length != 0 && bookVisit != null && (bookVisit === false || (bookVisit === true && visit !== null)))
            return true;
        
        return false
    }


    const handleDiscardVisit = () => {
        setBookVisit(false);
        setVisit(null);
    }

    const handleAddVisit = (visit) => {
        setBookVisit(true);
        setVisit(visit);
    }

    
    
    const getSiteName = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return s.SiteName;

        return '';
    }
    
    const getSiteAddress = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return (
                <React.Fragment>
                    {s.AddressLine1.length > 0 && <Typography variant="body2">{s.AddressLine1}</Typography>}
                    {s.AddressLine2.length > 0 && <Typography variant="body2">{s.AddressLine2}</Typography>}
                    {s.AddressLine3.length > 0 && <Typography variant="body2">{s.AddressLine3}</Typography>}
                    {s.Town.length > 0 && <Typography variant="body2">{s.Town}</Typography>}
                    {s.County.length > 0 && <Typography variant="body2">{s.County}</Typography>}
                    {s.PostCode.length > 0 && <Typography variant="body2">{s.PostCode}</Typography>}
                </React.Fragment>);

        return '';
    }
    
    const getTechnicalContact = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        // const sd = props.ticketMeta.sd.find(sd => sd.GroupSchemeID === s.GroupSchemeID && sd.ChargeSiteID === s.ChargeSiteID);
        // if(typeof sd != 'undefined')
        //     return (
        //         <React.Fragment>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Name: </Typography>
        //             {sd.TechnicalContacts.name != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.name}</Typography>}
        //             </div>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Email: </Typography>
        //             {sd.TechnicalContacts.email != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.email}</Typography>}
        //             </div>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Phone: </Typography>
        //             {sd.TechnicalContacts.telephone != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.telephone}</Typography>}
        //             </div>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Position: </Typography>
        //             {sd.TechnicalContacts.position != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.position}</Typography>}
        //             </div>
        //         </React.Fragment>
        //     );

        return '';
    }

    const filteredStatuses = () => {
        const unit = props.ticketMeta.units.find(u => u.id_ticket_unit == props.ticket.id_ticket_unit);

        return props.ticketMeta.statuses.filter(s => {
            // If the user is a member of the unit group supervisors then always return true;
            if(props.user.ticket_unit_groups.findIndex(uug => uug.id_ticket_unit_group == unit.id_ticket_unit_group) > -1)
                return true;

            // Otherwise check that the status isn't locked
            return s.locked == false
        })
    }

    const sendingExternalEmails = () => {

        const route = props.ticketMeta.units.find(u => u.id_ticket_unit === idTicketUnit);

        if(changeStatus && idTicketStatus !== props.ticket.id_ticket_status) 
            return true 

        if(changeRoute && idTicketUnit !== props.ticket.id_ticket_unit && route.routed_external_email_in !== null)
            return true 

        if(bookVisit && visit !== null)
            return true 
        
        return false;
    }
    

    return (
        <Dialog open={props.open} onClose={close} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'}>
            <DialogTitle id="form-dialog-title">Action Ticket</DialogTitle>
            <DialogContent>

                

            
                <div className={customStyles.comment}>
                    <Avatar></Avatar>
                    <div style={{flex: 1, marginLeft: 20}}>
                        <TextField type="text" variant="outlined" label="Add a comment" name="log_entry" value={newComment} onChange={e => setNewComment(e.target.value)} fullWidth multiline rows={6} />
                    </div>
                </div>

                <div className={customStyles.section}>
                    <Typography variant="subtitle2" className={styles.pos}>Is the ticket still in <u>{props.ticket.ticket_status_name}</u>?</Typography>
                    <ButtonGroup color="primary" aria-label="outlined secondary button group">
                        <Button variant={changeStatus === false && 'contained'} onClick={() => setChangeStatus(false)}>Yes</Button>
                        <Button variant={changeStatus === true && 'contained'} onClick={() => setChangeStatus(true)}>No</Button>
                    </ButtonGroup>
                </div>

                {changeStatus === true && 
                <div className={customStyles.section}>
                    <FormControl className={styles.inputField} variant="outlined" fullWidth size="small">
                        <InputLabel id="id_status_label">Change Status</InputLabel>
                        <Select
                            labelId="id_status_label"
                            id="id_status"
                            name="id_status"
                            value={idTicketStatus}
                            onChange={e => setIdTicketStatus(e.target.value)}
                            label="Change Status"
                        >
                            {filteredStatuses().map((status, idx) => (
                                <MenuItem key={idx} value={status.id_ticket_status}>{status.ticket_status_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>}

                <div className={customStyles.section}>
                    <Typography variant="subtitle2" className={styles.pos}>Does the ticket need redirecting to another team?</Typography>
                    <ButtonGroup color="primary" aria-label="outlined secondary button group">
                        <Button variant={changeRoute === true && 'contained'} onClick={() => setChangeRoute(true)}>Yes</Button>
                        <Button variant={changeRoute === false && 'contained'} onClick={() => setChangeRoute(false)}>No</Button>
                    </ButtonGroup>
                </div>

                {changeRoute === true && 
                <div className={customStyles.section}>
                    <FormControl className={styles.inputField} variant="outlined" fullWidth size="small">
                        <InputLabel id="id_ticket_unit_label">Route to</InputLabel>
                        <Select
                            labelId="id_ticket_unit_label"
                            id="id_ticket_unit"
                            name="id_ticket_unit"
                            value={idTicketUnit}
                            onChange={e => setIdTicketUnit(e.target.value)}
                            label="Route to"
                        >
                            {props.ticketMeta.units.map((unit, idx) => (
                                <MenuItem key={idx} value={unit.id_ticket_unit}>{unit.ticket_unit_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>}

                <div className={customStyles.section}>
                    <Typography variant="subtitle2" className={styles.pos}>Does the ticket need a visit booking?</Typography>
                    <ButtonGroup color="primary" aria-label="outlined secondary button group">
                        <Button variant={bookVisit === true && 'contained'} onClick={() => setBookVisit(true)}>Yes</Button>
                        <Button variant={bookVisit === false && 'contained'} onClick={() => setBookVisit(false)}>No</Button>
                    </ButtonGroup>
                </div>
                
                {visit !== null && 
                <div className={customStyles.section}>
                    <Typography variant="subtitle2" className={styles.pos}>Visit details</Typography>

                    <Grid container spacing={3}>
                        <Grid item sm={4}>
                            <Typography variant="body2" color="textSecondary" className={styles.pos}>Where:</Typography>
                            <Typography variant="body2">{getSiteName(visit.id_site)}<br />{getSiteAddress(visit.id_site)}</Typography>
                        </Grid>
                        <Grid item sm={4}> 
                            <Typography variant="body2" color="textSecondary" className={styles.pos}>Technical contact:</Typography>
                            {getTechnicalContact(visit.id_site)}
                        </Grid>
                        <Grid item sm={4}>
                            <div className={styles.pos}>
                                <Typography variant="body2" color="textSecondary" component="span">When: </Typography>
                                <Typography variant="body2" component="span">{Helpers.SQLtoUTCDate(visit.date_booked, false)}</Typography>
                            </div>

                            <Typography variant="body2" color="textSecondary" className={styles.pos}>Reason:</Typography>
                            <Typography variant="body2">{visit.ticket_visit_description}</Typography>
                        </Grid>
                    </Grid>
                </div>}

            </DialogContent>
            <DialogActions>

                {sendingExternalEmails() &&
                    <div className={customStyles.section}>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={!suppressExternal}
                                    onChange={() => setSuppressExternal(!suppressExternal)}
                                    name="supress_external"
                                    color="secondary"
                                />
                            }
                            label={<Typography variant="subtitle2" color="textSecondary">{suppressExternal ? `Don't send external emails from this action` : `Send external emails from this action`}</Typography>}
                            style={{fontSize: '0.875rem', marginLeft: 10}}
                        />
                    </div>
                }
                
                <div style={{flex: 1}}></div>

                <Button onClick={close}>Cancel</Button>
                <Button color="secondary" variant="contained" onClick={handleAccept} disabled={!valid() || loading}>{props.acceptLabel || 'Confirm'}{loading && <CircularProgress color="inherit" size={24} style={{marginLeft: 10}} />}</Button>
            </DialogActions>

            {(visit === null && bookVisit === true) && <TicketVisitAdd show={true} onClose={handleDiscardVisit} ticket={props.ticket} ticketMeta={props.ticketMeta} add={handleAddVisit} />}
        </Dialog>
    )

}

export default TicketAction