import React, {useState, useContext, useRef, useEffect} from 'react';
import {Typography, List, Button, CircularProgress, useTheme, makeStyles, ButtonGroup, Divider} from '@material-ui/core';
import GlobalContext from '../../context/global-context';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import OrderAttachment from './order_attachment';



const useStyles = makeStyles(theme => ({
    dropArea: {
        padding: 4, 
        backgroundColor: theme.palette.background.paper, 
        border: `3px solid ${theme.palette.background.paper}`,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    dropAreaHighlight: {
        padding: 4, 
        backgroundColor: theme.palette.action.hover, 
        border: `3px solid ${theme.palette.background.default}`,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    message: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center'
    }
}));



const OrderAttachments = props => {

    const context = useContext(GlobalContext);
    const theme = useTheme();
    const styles = useStyles(theme);

    const [ attachments, setAttachments ] = useState([]);
    const [ loadingAttachments, setLoadingAttachments ] = useState(false);

    const [ dropHighlight, setDropHighlight ] = useState(false)
    const [ fileUpload, setFileUpload ] = useState(null);
    const [ uploading, setUploading ] = useState(false);

    const dropArea = useRef(null);

    useEffect(() => {
        fetchAttachments();

        const preventDefaults = (e) => {
            e.preventDefault()
            e.stopPropagation()
        }

        dropArea.current.addEventListener('dragenter', e => {
            preventDefaults(e);
            setDropHighlight(true);
        }, false)

        dropArea.current.addEventListener('dragover', e => {
            preventDefaults(e);
            setDropHighlight(true);
        }, false)

        dropArea.current.addEventListener('dragleave', e => {
            preventDefaults(e);
            setDropHighlight(false);
        }, false)

        dropArea.current.addEventListener('drop', e => {
            preventDefaults(e);
            setDropHighlight(false);
            setFileUpload(e.dataTransfer.files[0]);
        }, false)

    }, [])


    useEffect(() => {
        console.log(props.reloadCount);
        fetchAttachments();
    }, [props.reloadCount])

    

    const fetchAttachments = () => {
        setLoadingAttachments(true);

        context.apiRequest(`order/attach/${props.order.id_order}`, 'GET').then(
            res => {
                setLoadingAttachments(false);
                groupAttachments(res.result);
            },
            err => {
                setUploading(false);
                context.showAlert('error', `Error uploading attachment: ${err.msg}`)
                console.log(err);
            }
        )
    }

    const groupAttachments = result => {
        let revGrouped = [];
        result.map(r => {
            const aIdx = revGrouped.findIndex(rg => rg.order_attachment_name === r.order_attachment_name);
            if(aIdx > -1)
                revGrouped[aIdx].revisions.push(r);
            else
                revGrouped.push({
                    ...r,
                    revisions: []
                })
        })
        setAttachments(revGrouped);
    }


    const handleFileChange = e => {
        setFileUpload(e.target.files[0]);
    }

    const handleUpload = () => {
        setUploading(true);

        context.apiFileUpload(`order/attach/${props.order.id_order}`, {attachment: fileUpload}).then(
            res => {
                setUploading(false);
                setFileUpload(null);
                groupAttachments(res.result);
                console.log(res);
            },
            err => {
                setUploading(false);
                context.showAlert('error', `Error uploading attachment: ${err.msg}`)
                console.log(err);
            }
        )
    }

    const harmfulUpload = () => {
        if(fileUpload !== null && fileUpload.name.substring(fileUpload.name.indexOf('.'), fileUpload.name.length) === '.msg')
            return true;
        
        return false;
    }



    return (
        <div style={{padding: '0 30px', marginTop: 60}}>
            <Typography variant="h6" style={{flex: 1}}>Attachments</Typography>
            
            <Divider style={{marginTop: 20, marginBottom: 40}} />

            {loadingAttachments && 
                <div className={styles.message}><CircularProgress size={24} /><Typography variant="body1" color="primary">&nbsp;Loading attachments</Typography></div>
            }
            {(!loadingAttachments && attachments.length === 0) && <div className={styles.message}><Typography variant="body1" color="textSecondary">No attachments</Typography></div>}
            <List dense={true}>
                {attachments.map((a, idx) => (
                    <OrderAttachment attachment={a} kay={idx} onDeleteSuccess={groupAttachments} />
                ))}
            </List>
            
            <div ref={dropArea} className={dropHighlight ? styles.dropAreaHighlight : styles.dropArea}>
                {fileUpload === null && 
                    <React.Fragment>
                        {!dropHighlight && <Button
                            component="label"
                            disabled={uploading}
                            >
                            <VerticalAlignBottomIcon size="small" /> Choose a file or drag it here
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                            {uploading && <CircularProgress size={24} />}
                        </Button>}
                        {dropHighlight && 
                            <div style={{paddingTop: 6, paddingBottom: 6, paddingLeft: 8, paddingRight: 8}}>
                                <Typography variant="button" className={styles.row}><VerticalAlignBottomIcon size="small" /> DROP</Typography>
                            </div>
                        }
                    </React.Fragment>
                }
            
                {fileUpload !== null && 
                <div style={{width: '100%', paddingTop: 6, paddingBottom: 6, paddingLeft: 8, paddingRight: 8}}>
                    <Typography variant="subtitle2">Confirm Upload</Typography>
                    <div className={styles.row} style={{width: '100%'}}>
                        <Typography variant="caption" style={{flex: 1}}>{fileUpload.name}</Typography>
                        {!uploading && 
                        <ButtonGroup disableElevation size="small" style={{marginLeft: 10}}>
                            <Button onClick={() => setFileUpload(null)}><CloseIcon fontSize="small" /></Button>
                            <Button color="secondary" variant="contained" onClick={handleUpload}><CheckIcon fontSize="small" /></Button>
                        </ButtonGroup>}
                        {uploading && <CircularProgress size={24} />}
                    </div>
                    {harmfulUpload() && <Typography color="error" variant="caption">Be careful, emails can contain malicious attachments or links.</Typography>}
                </div>}
            </div>
        </div>

    )
}

export default OrderAttachments