import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/styles';
import { useStyles } from './styles';

import { 
    Typography, 
    Button, 
    TextField, 
    Grid, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    Divider, 
    Chip, 
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    ButtonGroup,
    CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

import TicketContacts from './ticket_contacts';
import TicketSelectGroupScheme from './ticket_select_group_scheme';
import TicketSelectSite from './ticket_select_site';
import TicketSelectChargePoint from './ticket_select_charge_point';


import { blankTicket } from './ticket_helpers.js'


const blank_validation = (name, required = true) => {
    return JSON.parse(JSON.stringify({
        name,
        required,
        valid: false,
        msg: '',
        dirty: false
    }))
}


const TicketAdd = props => {

    const theme = useTheme();
    const styles = useStyles(theme);

    const context = useContext(GlobalContext);

    const [ ticketFault, setTicketFault ] = useState(null);
    const [ ticket, setTicket ] = useState(blankTicket());
    const [ validation, setValidation ] = useState({
        id_ticket_type: blank_validation('Enquiry Type', true),
        id_ticket_effect: blank_validation('Level', false),
        charge_points: blank_validation('Charge Point', false),
        sites: blank_validation('Site', false),
        group_schemes: blank_validation('Group Scheme', false),
        summary: blank_validation('Summary', true),
        ticket_description: blank_validation('Description', true),
        id_ticket_priority: blank_validation('Priority', true),
        id_network: blank_validation('Network', true),
        id_ticket_contact_type: blank_validation('Reported By Type', true),
        raised_by_name: blank_validation('Report By Name', true),
        id_ticket_unit: blank_validation('A team to route', true),
        id_ticket_status: blank_validation('Ticket status', true),
    });
    const [ summary, setSummary ] = useState({
        value: '',
        auto: '',
        dirty: false
    });
    const [ skipSelection, setSkipSelection ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    /*
    *
    *   SAVING AND VALIDATING
    *
    */

    const saveTicket = () => {
        setDirtyAll();
        if(isValid()){
            setLoading(true);
            context.saveTicket({...ticket, ticket_subject: summary.value}).then(
                res => {
                    setTicket(res.result);
                    props.handleAddTicket(res.result);
                    props.handleClose();
                    setLoading(false);
                    props.history.push(`/tickets/monitor/${res.result.ticket_ref}`)
                    context.showAlert('success', `Ticket created: ref ${res.result.ticket_ref}`)
                }, err => {
                    setLoading(false);
                    context.showAlert('error', 'There was an error saving the ticket')
                }
            );
        }
    }


    const validate = () => {
        let newValidation = {...validation};

        const keys = Object.keys(newValidation);
        keys.map(k => {
            newValidation[k].valid = true;
            newValidation[k].msg = '';
            if(typeof ticket[k] != 'undefined' && newValidation[k].required === true && (ticket[k] === null || ticket[k].length === 0 || ticket[k] === '')){
                newValidation[k].valid = false;
                newValidation[k].msg = `${newValidation[k].name} is required to create a new ticket`;
            }

            //
            //  Custom validations
            //
            if(typeof ticket[k] == 'undefined'){
                switch(k){
                    case 'summary':
                        if(summary.value === null || summary.value.length === 0 || summary.value === '')
                            newValidation[k].valid = false;
                            newValidation[k].msg = `${newValidation[k].name} is required to create a new ticket`;
                        break;
                }
            }
        })

        setValidation(newValidation);
    }

    const isValid = () => {
        const keys = Object.keys(validation);
        let result = true;
        keys.map(k => {
            if(validation[k].valid === false)
            result = false;
        })
        return result;
    }






    /*
    *
    *   HANDLE CHANGES TO VALUES
    *
    */

    const setDirty = name => {
        let newValidation = {...validation};
        if(typeof newValidation[name] != 'undefined'){
            newValidation[name].dirty = true;
            setValidation(newValidation);
        }
    }

    const setDirtyAll = () => {
        let newValidation = {...validation};
        const keys = Object.keys(newValidation);
        keys.map(k => newValidation[k].dirty = true);
        setValidation(newValidation);
    }

    const handleValueChange = e => {
        setDirty(e.target.name);

        const newTicket = {...ticket};
        newTicket[e.target.name] = e.target.value;
        setTicket(newTicket);
    }

    const handleSummaryChange = e => {
        const newSummary = {
            ...summary,
            value: e.target.value,
            dirty: true
        }
        setSummary(newSummary);
        setDirty('summary');
    }

    // CONTACTS

    const handleAddContact = obj => {
        setDirty('contacts');

        var newTicket = {...ticket};
        newTicket.contacts.push(obj);
        setTicket(newTicket);
    }

    const handleRemoveContact = obj => {
        setDirty('contacts');

        var newTicket = {...ticket};
        let idx = newTicket.contacts.findIndex(c => JSON.stringify(c) === JSON.stringify(obj));
        newTicket.contacts.splice(idx, 1);
        setTicket(newTicket);
    }

    // GROUP SCHEMES

    const handleAddGroupScheme = obj => {
        setDirty('group_schemes');

        var newTicket = {...ticket};
        newTicket.group_schemes.push(obj);
        newTicket.sites = [];
        newTicket.charge_points = [];
        setTicket(newTicket);
    }

    const handleRemoveGroupScheme = obj => {
        setDirty('group_shemes');

        var newTicket = {...ticket};
        const idx = newTicket.group_schemes.findIndex(p => p.id_group_scheme === obj.id_group_scheme);
        newTicket.group_schemes.splice(idx, 1);
        setTicket(newTicket);
    }

    // SITES

    const handleAddSite = obj => {
        setDirty('sites');



        var newTicket = {...ticket};
        newTicket.sites.push(obj);

        const site = props.ticketMeta.sites.find(s => s.ChargeSiteID === obj.id_site);

        if(typeof site != 'undefined'){
            const gs = {
                id_ticket: obj.id_ticket,
                id_group_scheme: site.GroupSchemeID
            }
        
            const cps = [];
            const charge_points = props.ticketMeta.charge_points.filter(c => c.ChargeSiteID === obj.id_site);
            charge_points.map(cp => cps.push({
                id_ticket: obj.id_ticket,
                id_charge_point: cp.CPID
            }))
            
            newTicket.group_schemes = [gs];
            newTicket.charge_points = cps;
        }

        setTicket(newTicket);
    }

    const handleRemoveSite = obj => {
        setDirty('sites');

        var newTicket = {...ticket};
        const idx = newTicket.sites.findIndex(p => p.id_site === obj.id_site);
        newTicket.sites.splice(idx, 1);
        setTicket(newTicket);
    }

    // CHARGE POINTS

    const handleAddChargePoint = obj => {
        setDirty('charge_points');

        var newTicket = {...ticket};
        newTicket.charge_points.push(obj);

        const cp = props.ticketMeta.charge_points.find(c => c.CPID === obj.id_charge_point);

        if(typeof cp != 'undefined'){
            const s = {
                id_ticket: obj.id_ticket,
                id_site: cp.ChargeSiteID
            }

            const gs = {
                id_ticket: obj.id_ticket,
                id_group_scheme: cp.GroupSchemeID
            }
            
            newTicket.sites = [s];
            newTicket.group_schemes = [gs];
        }

        setTicket(newTicket);
    }

    const handleRemoveChargePoint = obj => {
        setDirty('charge_points');

        var newTicket = {...ticket};
        const idx = newTicket.charge_points.findIndex(p => p.id_charge_point === obj.id_charge_point);
        newTicket.charge_points.splice(idx, 1);
        setTicket(newTicket);
    }



    


    /*
    *
    *   SHOW AND HIDE SECTIONS
    *
    */


    const showSection = lvl => {
        switch(lvl){
            case 1:
                return ticketFault === true;
            case 2:
                return ticketFault === true && ticket.id_ticket_effect != null;
            case 3:
                return ticketFault === false || (ticketFault === true && skipSelection === true) || (ticketFault === true && skipSelection === false && ticket.id_ticket_effect != null && (ticket.charge_points.length > 0 || ticket.group_schemes.length > 0 || ticket.sites.length > 0));
            default:
                return false;
        }
    }




    


    /*
    *
    *   SUMMARY BUILDER & TICKET CHANGE HANDLER
    *
    */


    useEffect(() => {

        //
        //  When the ticket changes put together an automated summary
        //
        let autoSummary = ticketFault ? 'FAULT | ' : 'ENQUIRY | ';

        if(ticket.id_ticket_effect != null){
            autoSummary += props.ticketMeta.effects.find(t => t.id_ticket_effect === ticket.id_ticket_effect).ticket_effect_name;

            switch(ticket.id_ticket_effect){
                case 1:
                    autoSummary += (typeof ticket.charge_points != 'undefined' && ticket.charge_points.length > 0) ? ' - ' + ticket.charge_points[0].id_charge_point : '';
                    autoSummary += (typeof ticket.sites != 'undefined' && ticket.sites.length > 0) ? ' - ' + getSiteDetails(ticket.sites[0].id_site).SiteName : '';
                    break
                case 2:
                    autoSummary += (typeof ticket.group_schemes != 'undefined' && ticket.group_schemes.length > 0) ? ' - ' + getGroupSchemeDetails(ticket.group_schemes[0].id_group_scheme).GroupSchemeName : '';
                    autoSummary += (typeof ticket.sites != 'undefined' && ticket.sites.length > 0) ? ' - ' + getSiteDetails(ticket.sites[0].id_site).SiteName : '';
                    break
                case 3:
                    autoSummary += (typeof ticket.group_schemes != 'undefined' && ticket.group_schemes.length > 0) ? ' - ' + getGroupSchemeDetails(ticket.group_schemes[0].id_group_scheme).GroupSchemeName : '';
                    break
                default:
            }
        }

        if(ticket.id_ticket_type != null)
            autoSummary += ' - ' + props.ticketMeta.types.find(t => t.id_ticket_type === ticket.id_ticket_type).ticket_type_name.toUpperCase();


        //
        //  The summary is created
        //  Now apply the new summary if the current summary isn't being modified by the user
        //
        let newSummary = {
            ...summary,
            auto: autoSummary
        };
        if(!summary.dirty || autoSummary.indexOf(summary.value) > -1){
            newSummary.value = autoSummary;
            newSummary.dirty = false;
        }

        setSummary(newSummary);
        setDirty('summary');

        validate();

    }, [ticket])

    useEffect(validate, [summary]);
    


    /*
    *
    *   HELPER FUNCTIONS
    *
    */

    const getGroupSchemeDetails = id_group_scheme => {
        return props.ticketMeta.group_schemes.find(gs => gs.GroupSchemeID === id_group_scheme)
    }

    const getSiteDetails = id_site => {
        return props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site)
    }


    const filterTypes = () => {
        if(ticketFault)
            return props.ticketMeta.types.filter(t => parseInt(t.fault) === 1);
        else
            return props.ticketMeta.types;
    }

    const isError = (obj) => {
        return (obj.dirty && obj.valid === false);
    }



    return (
        <Dialog open={true} onClose={props.handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'}>
            <DialogContent>

            {/* MAIN CENTER BODY */}

            <div className={styles.ticketContent}>

                {/* TICKET NUMBER AND AVATAR */}

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: theme.spacing(3), width: '100%'}}>
                    <div>
                        <Typography variant="h6"> <span style={{fontWeight: 300}}>New Ticket</span></Typography>
                    </div>
                </div>
                        

                    <Grid container spacing={3}>

                        {/* ENQUIRY TYPE AND DESCRIPTION */}

                        <Grid item xs={12} className={styles.section}>

                            <Typography variant="subtitle2" className={styles.pos}>The ticket is for a&hellip;</Typography>
                            <ButtonGroup color="primary" aria-label="outlined secondary button group">
                                <Button variant={ticketFault === true && 'contained'} onClick={() => setTicketFault(true)}>Fault</Button>
                                <Button variant={ticketFault === false && 'contained'} onClick={() => setTicketFault(false)}>Enquiry</Button>
                            </ButtonGroup>
                        
                        </Grid>

                        {showSection(1) && <Grid item xs={12} className={styles.section}>

                            <FormControl className={styles.inputField} variant="outlined" size="small" error={isError(validation.id_ticket_effect)}>
                                <InputLabel id="id_ticket_effect_label">Level</InputLabel>
                                <Select
                                    labelId="id_ticket_effect_label"
                                    id="id_ticket_effect"
                                    name="id_ticket_effect"
                                    value={ticket.id_ticket_effect}
                                    onChange={handleValueChange}
                                    label="Level"
                                    
                                >
                                    {props.ticketMeta.effects.map((type, idx) => (
                                        <MenuItem key={idx} value={type.id_ticket_effect}>{type.ticket_effect_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                                
                        </Grid>}

                        
                        {showSection(2) && <Divider className={styles.divider} />}


                        {(showSection(2) && ticket.id_ticket_effect === 1) && 
                        <Grid item xs={12} className={styles.section}>
                            
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                <Typography variant="subtitle2">Charge Point</Typography>
                            </div>

                            {ticket.charge_points.length === 0 && <TicketSelectChargePoint charge_points={props.ticketMeta.charge_points} sites={props.ticketMeta.sites} ticket={ticket} handleAdd={handleAddChargePoint} />}
                            
                            {ticket.charge_points.length === 0 && <Typography style={{padding: 20}}>No charge point selected <Button color="primary" onClick={() => setSkipSelection(true)}>Not listed</Button></Typography>}

                            {ticket.charge_points.map((p,idx) => (
                                <Chip
                                    key={idx}
                                    label={p.id_charge_point}
                                    clickable
                                    onDelete={() => handleRemoveChargePoint(p)}
                                    style={{margin: theme.spacing(1)}}
                                />
                            ))}

                        </Grid>
                        }
        
                        {(showSection(2) && ticket.id_ticket_effect === 2) && 
                        <Grid item xs={12} className={styles.section}>

                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                <Typography variant="subtitle2">Sites</Typography>
                            </div>

                            {ticket.sites.length === 0 && <TicketSelectSite sites={props.ticketMeta.sites} group_schemes={props.ticketMeta.group_schemes} ticket={ticket} handleAdd={handleAddSite} />}

                            {ticket.sites.length === 0 && <Typography style={{padding: 20}}>No site selected <Button color="primary" onClick={() => setSkipSelection(true)}>Not listed</Button></Typography>}

                            <List>
                                {ticket.sites.map((s, idx) => (
                                        <ListItem key={idx}>
                                            <ListItemText primary={getSiteDetails(s.id_site).SiteName} secondary={getSiteDetails(s.id_site).GroupSchemeName} />
                                            <ListItemSecondaryAction><IconButton edge="end" aria-label="delete" color="secondary" onClick={() => handleRemoveSite(s)}><CloseIcon /></IconButton></ListItemSecondaryAction>
                                        </ListItem>
                                ))}
                            </List>


                        </Grid>
                        }

                        {(showSection(2) && ticket.id_ticket_effect === 3) && 
                        <Grid item xs={12} className={styles.section}>

                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                <Typography variant="subtitle2">Group Scheme</Typography>
                            </div>

                            {ticket.group_schemes.length === 0 && <TicketSelectGroupScheme group_schemes={props.ticketMeta.group_schemes} ticket={ticket} handleAdd={handleAddGroupScheme} />}
                            
                            {ticket.group_schemes.length === 0 && <Typography style={{padding: 20}}>No group scheme added <Button color="primary" onClick={() => setSkipSelection(true)}>Not listed</Button></Typography>}

                            <List>
                                {ticket.group_schemes.map((s, idx) => (
                                        <ListItem key={idx}>
                                            <ListItemText primary={`#${s.id_group_scheme}`} secondary={getGroupSchemeDetails(s.id_group_scheme).GroupSchemeName} />
                                            <ListItemSecondaryAction><IconButton edge="end" aria-label="delete" color="secondary" onClick={() => handleRemoveGroupScheme(s)}><CloseIcon /></IconButton></ListItemSecondaryAction>
                                        </ListItem>
                                ))}
                            </List>
                                
                        </Grid>
                        }


                        
                        {showSection(3) && <Divider className={styles.divider} />}

                        

                        {showSection(3) && 
                        <Grid item xs={12}>

                            <FormControl className={styles.inputField} fullWidth>
                                <TextField size="small" name="summary" label="Summary" variant="outlined" value={summary.value} onChange={handleSummaryChange} fullWidth autoComplete="no" error={isError(validation.summary)}/>
                                {isError(validation.summary) && <Typography variant="caption" color="error">{validation.summary.msg}</Typography>}
                                {!isError(validation.summary) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                            </FormControl>
                            
                            <FormControl className={styles.inputField} fullWidth>
                                <TextField size="small" name="ticket_description" label="Description" variant="outlined" value={ticket.ticket_description} onChange={handleValueChange} fullWidth multiline rows={10} autoComplete="no" error={isError(validation.ticket_description)}/>
                                {isError(validation.ticket_description) && <Typography variant="caption" color="error">{validation.ticket_description.msg}</Typography>}
                                {!isError(validation.ticket_description) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                            </FormControl>
                        </Grid>}


                        {showSection(3) && 
                        <Grid item xs={12}>

                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                            
                                <FormControl className={styles.inputField} variant="outlined" size="small" error={isError(validation.id_ticket_priority)}>
                                    <InputLabel id="id_ticket_priority_label">Priority</InputLabel>
                                    <Select
                                        labelId="id_ticket_priority_label"
                                        id="id_ticket_priority"
                                        name="id_ticket_priority"
                                        value={ticket.id_ticket_priority}
                                        onChange={handleValueChange}
                                        label="Priority"
                                        
                                    >
                                        {props.ticketMeta.priorities.map((type, idx) => (
                                            <MenuItem key={idx} value={type.id_ticket_priority}>{type.ticket_priority_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {isError(validation.id_ticket_priority) && <Typography variant="caption" color="error">{validation.id_ticket_priority.msg}</Typography>}
                                    {!isError(validation.id_ticket_priority) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                                </FormControl>

                                <FormControl className={styles.inputField} variant="outlined" size="small" error={isError(validation.id_ticket_type)}>
                                    <InputLabel id="ticket_type_label">Enquiry Type</InputLabel>
                                    <Select
                                        labelId="ticket_type_label"
                                        id="id_ticket_type"
                                        name="id_ticket_type"
                                        value={ticket.id_ticket_type}
                                        onChange={handleValueChange}
                                        label="Enquiry Type"
                                    >
                                        {filterTypes().map((type, idx) => (
                                            <MenuItem key={idx} value={type.id_ticket_type}>{type.ticket_type_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {isError(validation.id_ticket_type) && <Typography variant="caption" color="error">{validation.id_ticket_type.msg}</Typography>}
                                    {!isError(validation.id_ticket_type) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                                </FormControl>

                                <FormControl className={styles.inputField} variant="outlined" size="small" error={isError(validation.id_network)}>
                                    <InputLabel id="id_network_label">Network</InputLabel>
                                    <Select
                                        labelId="id_network_label"
                                        id="id_network"
                                        name="id_network"
                                        value={ticket.id_network}
                                        onChange={handleValueChange}
                                        label="Network"
                                        
                                    >
                                        {props.ticketMeta.networks.map((type, idx) => (
                                            <MenuItem key={idx} value={type.id_network}>{type.network_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {isError(validation.id_network) && <Typography variant="caption" color="error">{validation.id_network.msg}</Typography>}
                                    {!isError(validation.id_network) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                                </FormControl>

                            </div>

                        </Grid>}

                        
                        {showSection(3) && <Divider className={styles.divider} />}
                            

                        {/* REPORTED BY */}

                        {showSection(3) && 
                        <Grid item container xs={12} spacing={3}>
                            
                            <Grid item xs={12} md={6}>

                                <div style={{display: 'flex', flexDirection: 'column'}}>

                                    <Typography variant="subtitle2" style={{marginBottom: theme.spacing(2)}}>Reported By</Typography>

                                    <FormControl className={styles.inputField} variant="outlined" size="small" error={isError(validation.id_ticket_contact_type)}>
                                        <InputLabel id="id_ticket_contact_type_label">Type</InputLabel>
                                        <Select
                                            labelId="id_ticket_contact_type_label"
                                            id="id_ticket_contact_type"
                                            name="id_ticket_contact_type"
                                            value={ticket.id_ticket_contact_type}
                                            onChange={handleValueChange}
                                            label="Type"
                                            
                                        >
                                            {props.ticketMeta.contact_types.map((type, idx) => (
                                                <MenuItem key={idx} value={type.id_ticket_contact_type}>{type.ticket_contact_type_name}</MenuItem>
                                            ))}
                                        </Select>
                                        {isError(validation.id_ticket_contact_type) && <Typography variant="caption" color="error">{validation.id_ticket_contact_type.msg}</Typography>}
                                        {!isError(validation.id_ticket_contact_type) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                                    </FormControl>

                                    <FormControl className={styles.inputField}>
                                        <TextField size="small" name="raised_by_name" label="Name" variant="outlined" value={ticket.raised_by_name} onChange={handleValueChange} error={isError(validation.raised_by_name)} autoComplete="no"/>
                                        {isError(validation.raised_by_name) && <Typography variant="caption" color="error">{validation.raised_by_name.msg}</Typography>}
                                        {!isError(validation.raised_by_name) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                                    </FormControl>

                                    <TextField size="small" name="raised_by_email" label="Email" variant="outlined" value={ticket.raised_by_email} onChange={handleValueChange} className={styles.inputField} autoComplete="no" />

                                    <TextField size="small" name="raised_by_phone" label="Tel" variant="outlined" value={ticket.raised_by_phone} onChange={handleValueChange} className={styles.inputField} autoComplete="no" />

                                </div>

                            </Grid>
                            

                            <Grid item xs={12} md={6}>

                                <div style={{display: 'flex', flexDirection: 'column'}}>

                                    <Typography variant="subtitle2" style={{marginBottom: theme.spacing(2)}}>Route To</Typography>

                                    <FormControl className={styles.inputField} variant="outlined" size="small" error={isError(validation.id_ticket_unit)}>
                                        <InputLabel id="id_ticket_unit_label">Route to</InputLabel>
                                        <Select
                                            labelId="id_ticket_unit_label"
                                            id="id_ticket_unit"
                                            name="id_ticket_unit"
                                            value={ticket.id_ticket_unit}
                                            onChange={handleValueChange}
                                            label="Route to"
                                        >
                                            {props.ticketMeta.units.map((unit, idx) => (
                                                <MenuItem key={idx} value={unit.id_ticket_unit}>{unit.ticket_unit_name}</MenuItem>
                                            ))}
                                        </Select>
                                        {isError(validation.id_ticket_unit) && <Typography variant="caption" color="error">{validation.id_ticket_unit.msg}</Typography>}
                                        {!isError(validation.id_ticket_unit) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                                    </FormControl>

                                    <FormControl className={styles.inputField} variant="outlined" size="small" error={isError(validation.id_ticket_status)}>
                                        <InputLabel id="id_ticket_status_label">Status</InputLabel>
                                        <Select
                                            labelId="id_ticket_status_label"
                                            id="id_ticket_status"
                                            name="id_ticket_status"
                                            value={ticket.id_ticket_status}
                                            onChange={handleValueChange}
                                            label="Status"
                                        >
                                            {props.ticketMeta.statuses.map((status, idx) => (
                                                <MenuItem key={idx} value={status.id_ticket_status}>{status.ticket_status_name}</MenuItem>
                                            ))}
                                        </Select>
                                        {isError(validation.id_ticket_status) && <Typography variant="caption" color="error">{validation.id_ticket_status.msg}</Typography>}
                                        {!isError(validation.id_ticket_status) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                                    </FormControl>
                                </div>

                            </Grid>

                        </Grid>}

                        {showSection(3) && <Divider className={styles.divider} />}

                        {showSection(3) && 
                        <Grid item sm={12}>

                            <TicketContacts  
                                ticket={ticket} 
                                handleAddContact={handleAddContact} 
                                handleRemoveContact={handleRemoveContact} 
                                contactTypes={props.ticketMeta.contact_types}
                                editMode={true} />

                        </Grid>}

                    </Grid>

            </div>

            </DialogContent>

            <DialogActions>

                <Button variant="contained" onClick={props.handleClose}>Close</Button>
                        <Button variant="contained" color={isValid() ? 'secondary' : 'default'} onClick={saveTicket} disabled={!isValid() || loading}>Save {loading && <CircularProgress color="inherit" size={24} style={{marginLeft: 10}} />}</Button>

            </DialogActions>
        </Dialog>
    )

}

export default withRouter(TicketAdd)