import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';

import { 
    Button, 
    Modal, 
    Paper, 
    Table, 
    TableContainer, 
    TableHead, 
    TableCell, 
    TableBody, 
    TableRow, 
    Typography, 
    Fab, 
    Menu, 
    MenuItem, 
    IconButton, 
    Checkbox, 
    Toolbar,
    ButtonGroup,
    Chip
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddSubscription from './add_subscription';
import EditSubscription from './edit_subscription';
import DeleteSubscription from './delete_subscription';
import AddInvoiceNo from './add_invoice_no';
import StartSubscription from './start_subscription';
import SuspendSubscription from './suspend_subscription';
import AddInvoiceDetails from './add_invoice_details';
import SubscriptionSockets from './subscription_sockets';


const Subscriptions = props => {

    // Create a local state
    const [subscriptions, setSubscriptions] = useState([]);
    const [readOnly, setReadOnly] = useState(false);
    const [loading, setLoading] = useState(true);
    const [id_organisation, setOrganisation] = useState(typeof props.id_organisation!='undefined'?props.id_organisation:null);
    const [id_order, setOrder] = useState(typeof props.id_order!='undefined'?props.id_order:null);
    const [subscriptionTotal, setSubscriptionTotal] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [removeSubscription, setRemoveSubscription] = useState(null);
    const [editSubscription, setEditSubscription] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [showInvoiceNo, setShowInvoiceNo] = useState(false);
    const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);
    const [showStartSubscription, setShowStartSubscription] = useState(false);
    const [showSuspendSubscription, setShowSuspendSubscription] = useState(false);

    const [reloads, setReloads] = useState(0);

    const handleActionMenuOpen = event => {
        setAnchorEl(event.currentTarget);
      };

    const handleActionMenuClose = () => {
        setAnchorEl(null);
      };


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    useEffect(() => {
        getSubscriptions();
    }, [])

    const getSubscriptions = () => {
        setLoading(true);
        setShowAdd(false);
        setShowInvoiceDetails(false);
        setShowInvoiceNo(false);
        setShowStartSubscription(false);
        setShowSuspendSubscription(false);
        setRemoveSubscription(null);
        setEditSubscription(null);

        if(typeof id_order != 'undefined' && id_order != null){
            context.fetchOrderSubscriptions(id_order).then(
                res => {
                    setSubscriptions(res.result);
                    setReadOnly(false);
                    
                    let total = 0;
                    res.result.map(row => total += row.total);
                    setSubscriptionTotal(total);
                    setReloads(reloads+1);

                    setLoading(false);
                }, 
                err => {
                    setLoading(false);
                    context.showAlert('error', 'error fetching subscriptions: ' + JSON.stringify(err.msg));
                }
            )
        } else if(typeof id_organisation != 'undefined' && id_organisation != null) {
            context.fetchOrganisationSubscriptions(id_organisation).then(
                res => {
                    setSubscriptions(res.result);
                    setReadOnly(true);
                    
                    let total = 0;
                    res.result.map(row => total += row.total);
                    setSubscriptionTotal(total);
                    setReloads(reloads+1);

                    setLoading(false);
                }, 
                err => {
                    setLoading(false);
                    context.showAlert('error', 'error fetching subscriptions: ' + JSON.stringify(err.msg));
                }
            )
        }
    }

    const handleUpdateInvoicePaid = () => {
        handleActionMenuClose();
        context.updateSubscriptionsAs({selected: selected}, 'invoice_paid').then(
            res => {
                context.showAlert('success', 'Subscriptions updated');
                getSubscriptions();
            },
            err => {
                context.showAlert('error', 'Error updating subscriptions ' + JSON.stringify(err))
            }
        )
    }

    const handleUpdateInvoiceUnPaid = () => {
        handleActionMenuClose();
        context.updateSubscriptionsAs({selected: selected}, 'invoice_unpaid').then(
            res => {
                context.showAlert('success', 'Subscriptions updated');
                getSubscriptions();
            },
            err => {
                context.showAlert('error', 'Error updating subscriptions ' + JSON.stringify(err))
            }
        )
    }

    const handleActionInvoiceDetails = () => {
        handleActionMenuClose();
        setShowInvoiceDetails(true);
    }

    const handleActionInvoiceNo = () => {
        handleActionMenuClose();
        setShowInvoiceNo(true);
    }

    const handleActionStartSubscription = () => {
        handleActionMenuClose();
        setShowStartSubscription(true);
    }

    const handleActionSuspendSubscription = () => {
        handleActionMenuClose();
        setShowSuspendSubscription(true);
    }

    const canRemove = row => {
        return row.invoice_paid == null && row.start_date == null;
    }



    const handleSelectAllRows = event => {
        if (event.target.checked) {
            const newSelecteds = subscriptions.map(n => n.id_subscription);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    
    const handleSelectRow = (event, id) => {
        if(!readOnly){
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];
        
            if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
            }
        
            setSelected(newSelected);
        }
    };


    const handleOrderClick = id => {
        if(typeof props.history != 'undefined')
            props.history.push('/order/'+id);
    }

    const isRowSelected = id => selected.indexOf(id) !== -1;

    const getFirstEmail = (subscriptions.length > 0 && typeof subscriptions[0].main_contact_email != 'undefined')?subscriptions[0].main_contact_email:'';

        
    return (

        <div style={{display: 'flex', flexDirection: 'column', padding: '10px 0'}}>

            <Toolbar style={{padding: '10px 0'}}>
                <Typography variant="h6">{selected.length > 0 ? `${selected.length} purchase${selected.length>1?'s':''} selected`: 'Purchases'}</Typography>
                <div style={{flex:1}}></div>
                <div>
                    {selected.length > 0 && <IconButton
                        edge="end"
                        aria-label="open action menu"
                        aria-controls={'action-menu'}
                        aria-haspopup="true"
                        onClick={handleActionMenuOpen}
                        color="inherit"
                    >
                    <MoreHorizIcon color="secondary"/>
                    </IconButton>}
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        id={'action-menu'}
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={Boolean(anchorEl)}
                        onClose={handleActionMenuClose}
                        >
                        <MenuItem onClick={handleActionInvoiceDetails}>Add Proforma</MenuItem>
                        <MenuItem onClick={handleActionInvoiceNo}>Invoice No</MenuItem>
                        <MenuItem onClick={handleUpdateInvoicePaid}>Mark as Paid</MenuItem>
                        <MenuItem onClick={handleUpdateInvoiceUnPaid}>Mark as Unpaid</MenuItem>
                        <MenuItem onClick={handleActionStartSubscription}>Start Subscription</MenuItem>
                        <MenuItem onClick={handleActionSuspendSubscription}>Suspend / Unsuspend Subscription</MenuItem>
                    </Menu>
                </div>
            </Toolbar>

            {subscriptions.length > 0 &&
            <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        {!readOnly && <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={selected.length > 0 && selected.length < subscriptions.length}
                                checked={subscriptions.length > 0 && selected.length === subscriptions.length}
                                onChange={handleSelectAllRows}
                                inputProps={{ 'aria-label': 'select all subscriptions' }}
                            />
                        </TableCell>}
                        <TableCell>Description</TableCell>
                        <TableCell align="right">Qty</TableCell>
                        <TableCell align="right">Cost</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Proforma Issued</TableCell>
                        <TableCell align="right">Invoice To</TableCell>
                        <TableCell align="right">Invoice No</TableCell>
                        <TableCell align="right">Invoice Paid</TableCell>
                        <TableCell align="right">Expires</TableCell>
                        {!readOnly && <TableCell align="right"></TableCell>}
                        {readOnly && <TableCell align="right">Order</TableCell>}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {subscriptions.map((row, index) => {
                        const isItemSelected = isRowSelected(row.id_subscription);
                        const labelId = `subscription-table-checkbox-${index}`;

                        return (
                            <React.Fragment
                                key={row.id_subscription}
                                >

                                <TableRow 
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                    style={{verticalAlign: 'top'}}
                                >
                                    {!readOnly && <TableCell padding="checkbox" onClick={event => handleSelectRow(event, row.id_subscription)}>
                                        <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        style={{marginTop: 6}}
                                        />
                                    </TableCell>}
                                    <TableCell component="th" scope="row">{row.product_description} - {row.subscription_description}</TableCell>
                                    <TableCell align="right">{row.qty}</TableCell>
                                    <TableCell align="right">{row.cost.toFixed(2)}</TableCell>
                                    <TableCell align="right">{row.total.toFixed(2)}</TableCell>
                                    <TableCell align="right">{Helpers.SQLtoUTCDate(row.proforma_issued, false)}</TableCell>
                                    <TableCell align="right">{row.invoice_to}</TableCell>
                                    <TableCell align="right">{row.invoice_no}</TableCell>
                                    <TableCell align="right">{row.invoice_paid != null && 'YES'}</TableCell>
                                    <TableCell align="right">{Helpers.SQLtoUTCDate(row.expiry_date, false)}{(row.term === 0 && row.expiry_date === null) && 'N/A'}</TableCell>
                                    {!readOnly && <TableCell align="right">
                                    <ButtonGroup variant="text" aria-label="text primary button group">
                                        {canRemove(row) && <IconButton size="small" color="secondary" onClick={()=>{setEditSubscription(row)}} aria-label="remove"><EditIcon /></IconButton>}
                                        {canRemove(row) && <IconButton color="secondary" onClick={()=>{setRemoveSubscription(row)}} aria-label="remove"><CloseIcon /></IconButton>}
                                    </ButtonGroup>
                                    </TableCell>}
                                    {readOnly && <TableCell align="right"><Button color="secondary" onClick={() => handleOrderClick(row.id_order)}>VE{row.id_order}</Button></TableCell>}
                                </TableRow>

                                {row.term > 0 && 
                                    <TableRow>
                                        <TableCell colSpan={!readOnly ? 12 : 9}>
                                            <SubscriptionSockets commissioned={row.start_date != null} max={row.qty} id_subscription={row.id_subscription} id_order={row.id_order} getSubscriptions={getSubscriptions} reloads={reloads} groupSchemeName={props.groupSchemeName} />
                                        </TableCell>
                                    </TableRow>
                                }
                            </React.Fragment>
                        )
                    })}
                    </TableBody>
                </Table>
            </TableContainer>}

            {subscriptions.length == 0 &&
                <Typography variant="h6" style={{color: 'rgba(0,0,0,.3)'}}>None</Typography>
            }
            
            {!readOnly && <div style={{display: 'flex', flexDirection: 'row', padding: '10px 0', justifyContent: 'space-between'}}>
                <Typography variant="h6">Total: {subscriptionTotal.toFixed(2)}</Typography>
                
                <Fab onClick={()=>{setShowAdd(true)}} color="secondary" size="medium" aria-label="add">
                    <AddIcon />
                </Fab>
            </div>}
            
            <Modal open={showAdd} onClose={() => {setShowAdd(false)}}>
                <Paper style={{position: 'absolute', width: '90%', maxWidth: 500, left: '50%', top: '50%', transform: `translate(-50%, -50%)`}}>
                    <AddSubscription id_order={id_order} id_organisation={id_organisation} onAdd={getSubscriptions} />
                </Paper>
            </Modal>
            
            {removeSubscription != null && <Modal open={removeSubscription != null} onClose={() => {setRemoveSubscription(null)}}>
                <Paper style={{position: 'absolute', width: '90%', maxWidth: 400, left: '50%', top: '50%', transform: `translate(-50%, -50%)`}}>
                    <DeleteSubscription id_subscription={removeSubscription.id_subscription} name={removeSubscription.subscription_description} onDelete={getSubscriptions} onCancel={() => {setRemoveSubscription(null)}}/>
                </Paper>
            </Modal>}
            
            {editSubscription != null && <Modal open={editSubscription != null} onClose={() => {setEditSubscription(null)}}>
                <Paper style={{position: 'absolute', width: '90%', maxWidth: 500, left: '50%', top: '50%', transform: `translate(-50%, -50%)`}}>
                    <EditSubscription subscription={editSubscription} onSave={getSubscriptions} onCancel={() => {setEditSubscription(null)}}/>
                </Paper>
            </Modal>}

            <AddInvoiceNo show={showInvoiceNo} close={()=>{setShowInvoiceNo(false)}} selected={selected} success={getSubscriptions} />
            <AddInvoiceDetails show={showInvoiceDetails} close={()=>{setShowInvoiceDetails(false)}} selected={selected} success={getSubscriptions} />
            <StartSubscription show={showStartSubscription} close={()=>{setShowStartSubscription(false)}} selected={selected} success={getSubscriptions} />
            {showSuspendSubscription && <SuspendSubscription show={showSuspendSubscription} close={()=>{setShowSuspendSubscription(false)}} selected={selected} success={getSubscriptions} email={getFirstEmail} />}

        </div>
    );
}

export default Subscriptions