import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: theme.backgroundColor,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
    }
}));

const SplashScreen = props => {

    const theme = useTheme();
    const styles = useStyles(theme);

    return(
        <div className={styles.container}>
            <CircularProgress color="primary" />
        </div>
    );

}

export default SplashScreen