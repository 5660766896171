import React from 'react';
import { useTheme, makeStyles, Typography, Dialog, DialogTitle, DialogContent, Divider } from '@material-ui/core';
import Helpers from '../../global/helpers';
import AppUserRecentChargepoints from './app_user/app_user_recent_chargepoints';
import ChargepointRecentConfigurations from './chargepoint/chargepoint_recent_configurations';
import ChargepointRecentTests from './chargepoint/chargepoint_recent_tests';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const EVConnectChargepoint = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const {chargepoint} = props;

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    return (
        <React.Fragment>
            <Dialog open={true} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>EV Connect Chargepoint</DialogTitle>
                <DialogContent>
                    <div className='flex-col gap-10'>
                     
                        <Divider style={{marginTop: 10, marginBottom: 10}} />

                        <div className='flex-row gap-10'>
                            <div className='flex'>
                                <div className='flex-col'>
                                    <Typography variant="subtitle1" style={{fontWeight: 600, marginBottom: 20}}>Basic Info</Typography>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">CPID: </Typography>
                                        <Typography variant="subtitle1"><b>{chargepoint.cpid}</b></Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Serial: </Typography>
                                        <Typography variant="subtitle1"><b>{chargepoint.serial_number}</b></Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Status: </Typography>
                                        <Typography variant="subtitle1"><b>{chargepoint.status}</b></Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Created: </Typography>
                                        <Typography variant="subtitle1"><b>{Helpers.toLocaleDateTime(chargepoint.created)}</b></Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Connectors: </Typography>
                                        <Typography variant="subtitle1"><b>{chargepoint.connectors}</b></Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Ble Device Name: </Typography>
                                        <Typography variant="subtitle1"><b>{chargepoint.ble_device_name}</b></Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Unit Type: </Typography>
                                        <Typography variant="subtitle1"><b>{chargepoint.unit_type}</b></Typography>
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='flex-col'>
                                    <Typography variant="subtitle1" style={{fontWeight: 600}}>Recent Configurations</Typography>
                                    <ChargepointRecentConfigurations id_chargepoint={chargepoint.id_chargepoint} />
                                </div>
                            </div>
                        </div>

                        <Divider style={{marginTop: 10, marginBottom: 10}} />

                        <div className='flex-col'>
                            <Typography variant="subtitle1" style={{fontWeight: 600}}>Recent Tests</Typography>
                            <ChargepointRecentTests id_chargepoint={chargepoint.id_chargepoint} />
                        </div>

                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}
export default EVConnectChargepoint;