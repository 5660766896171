import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, CircularProgress, List, ListItem, Collapse, Paper } from '@material-ui/core';
import GlobalContext from '../../context/global-context';

const useStyles = makeStyles(theme => ({
    container: {
        maxHeight: 280,
        overflowY: 'auto',
        marginBottom: 10,
        padding: 10
    },
    inner: {},
}));

const SubscriptionSocketSimAutocomplete = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    var abortFetch;


    //
    //  Fetch the profile information from the Emnify portal
    //
    useEffect(() => {

        const fetchData = () => {

            setData([]);

            if(typeof abortController !== 'undefined')
                abortFetch.abort();

            if(props.sim_no !== null && props.sim_no.length > 0){
                setLoading(true);
    
                abortFetch = new AbortController();
                context.apiRequest(`/devices/sims/unused/${props.sim_no}`, 'GET', null, abortFetch.signal).then(
                    res => {
                        setLoading(false);
                        if(res.result.length > 1 || (res.result.length === 1 && res.result[0].iccid !== props.sim_no))
                            setData(res.result);
                    }, 
                    err => {
                        setLoading(false);
                        setError(`Error getting unused emnify sims`);
                    }
                )
            } else
                setLoading(false);

        }
        fetchData();

        return () => {
            if(typeof abortController !== 'undefined')
                abortFetch.abort();
        }

    }, [props.sim_no])


    const handleSimSelect = (sim_no) => {
        if(typeof props.onSelect === 'function')
            props.onSelect(sim_no);
    }


    return (
        <React.Fragment>
            <Collapse in={(loading || (data !== null && data.length > 0))}>
                <Paper className={classes.container}>
                    <Typography variant="caption">Available SIMs</Typography>
                    {loading && <CircularProgress size={24} />}
                    {!loading &&
                        <List dense>
                            {data.map((sim, idx) => (
                                <ListItem key={idx} button onClick={() => handleSimSelect(sim.iccid)}>{sim.iccid}</ListItem>
                            ))}
                        </List>
                    }
                </Paper>
            </Collapse>
        </React.Fragment>
    )
}
export default SubscriptionSocketSimAutocomplete;