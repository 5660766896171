import React, {useContext, useState} from 'react';
import globalContext from '../../context/global-context';

import {Typography, List, ListItem, ListItemText, ListItemIcon, CircularProgress, useTheme, makeStyles, Tooltip, IconButton, Link, Collapse, Checkbox} from '@material-ui/core';
import Helpers from '../global/helpers';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import MovieIcon from '@material-ui/icons/Movie';
import CloseIcon from '@material-ui/icons/Close';
import OrderConfirmDialog from './order_confirm_dialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';



const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    initials: {
        marginRight: theme.spacing(1)
    },
    delete: {
        width: 16, 
        height: 16, 
        marginLeft: 15,
        color: theme.palette.text.disabled,
        "&:hover" : {
            color: theme.palette.text.default
        }
    },
}));

const OrderAttachment = props => {
    const theme = useTheme();
    const styles = useStyles(theme);
    const context = useContext(globalContext);

    const {attachment} = props;
    const selectable = props.selectable || false;

    const [downloading, setDownloading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [deleting, setDeleting] = useState(false);

    const [showRevisions, setShowRevisions] = useState(false);

    

    const handleDownload = (e) => {
        
        e.preventDefault()
        e.stopPropagation()

        setDownloading(attachment.id_order_attachment);

        context.apiFileDownload(`order/attach/${attachment.id_order}/${attachment.id_order_attachment}`, attachment.order_attachment_name).then(
            res => {
                setDownloading(null);
                console.log(res);
            },
            err => {
                setDownloading(null);
                context.showAlert('error', `Error downloading attachment: ${err}`)
                console.log(err);
            }
        )
    }


    const fileTypeIcon = mimeType => {
        switch(mimeType.toUpperCase()){
            case 'IMAGE/JPEG':
            case 'IMAGE/PNG':
            case 'IMAGE/GIF':
                return <InsertPhotoIcon />
            case 'AUDIO/MPEG':
                return <AudiotrackIcon />
            case 'VIDEO/MPEG':
                return <MovieIcon />
            default:
                return <InsertDriveFileIcon />
        }
    }

    

    const handleDelete = () => {
        setDeleting(true);

        context.apiRequest(`order/attach/${confirmDelete.id_order}/${confirmDelete.id_order_attachment}`, 'DELETE').then(
            res => {
                setDeleting(false);
                setConfirmDelete(null);
                if(typeof props.onDeleteSuccess === 'function')
                    props.onDeleteSuccess(res.result);
                console.log(res);
            },
            err => {
                setDeleting(false);
                setConfirmDelete(null);
                context.showAlert('error', `Error deleting attachment: ${err}`)
                console.log(err);
            }
        )

    }

    const handleToggle = () => {
        if(selectable && props.onToggle !== 'undefined')
            props.onToggle();
    }

    return (
        <React.Fragment>
            <ListItem button={selectable} onClick={handleToggle}>
                <ListItemIcon>
                    {selectable && 
                    <Checkbox
                        edge="start"
                        checked={props.checked}
                        tabIndex={-1}
                    />
                    }
                    {downloading !== attachment.id_order_attachment && <IconButton onClick={(e) => handleDownload(e, attachment)}>{fileTypeIcon(attachment.order_attachment_filetype)}</IconButton>}
                    {downloading === attachment.id_order_attachment && <CircularProgress size={24} />}
                </ListItemIcon>
                <ListItemText
                    primary={<Link href="#" color="secondary" onClick={(e) => handleDownload(e, attachment)}>{attachment.order_attachment_name} (REV{attachment.revision_no} {attachment.order_attachment_size})</Link>}
                    secondary={
                    <React.Fragment>
                        <div className={styles.row}>
                            <Tooltip title={attachment.name} aria-label={attachment.name}>
                                <Typography color="textSecondary" variant="caption" className={styles.initials}>{Helpers.getInitials(attachment.name)}</Typography>
                            </Tooltip>
                            <Typography color="textSecondary" variant="caption"> - {Helpers.SQLtoUTCDate(attachment.created, true)}</Typography>
                            <IconButton className={styles.delete} onClick={() => setConfirmDelete(attachment)}><CloseIcon fontSize="small" className={styles.deleteIcon} /></IconButton>
                        </div>
                    </React.Fragment>
                    }
                />
                {attachment.revisions.length > 0 && 
                    <ListItemIcon>
                        <IconButton onClick={() => setShowRevisions(!showRevisions)}>{showRevisions ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                    </ListItemIcon>
                }
            </ListItem>
            <Collapse in={showRevisions}>
                <List dense={true}>

                    <Typography variant='subtitle2'>Revisions</Typography>
                
                    {attachment.revisions.map((r, idx) => (

                        <ListItem key={idx}>
                            <ListItemText
                                primary={<Link href="#" color="secondary" onClick={(e) => handleDownload(e, r)}>{r.order_attachment_name} (REV{r.revision_no} {r.order_attachment_size})</Link>}
                                secondary={
                                <React.Fragment>
                                    <div className={styles.row}>
                                        <Tooltip title={r.name} aria-label={r.name}>
                                            <Typography color="textSecondary" variant="caption" className={styles.initials}>{Helpers.getInitials(r.name)}</Typography>
                                        </Tooltip>
                                        <Typography color="textSecondary" variant="caption"> - {Helpers.SQLtoUTCDate(r.created, true)}</Typography>
                                        <IconButton className={styles.delete} onClick={() => setConfirmDelete(r)}><CloseIcon fontSize="small" className={styles.deleteIcon} /></IconButton>
                                    </div>
                                </React.Fragment>
                                }
                            />
                        </ListItem>

                    ))}

                </List>
            </Collapse>


            {confirmDelete !== null && <OrderConfirmDialog open={true} title="Delete attachment" body={`Are you sure you wish to delete ${confirmDelete.order_attachment_name} from this order?`} handleClose={() => setConfirmDelete(null)} handleAccept={handleDelete} loading={deleting} />}
        </React.Fragment>
    )
}
export default OrderAttachment;