import Helpers from '../content/global/helpers';

export function userLogin (payload) {
    return dispatch => {
        // Login successful
        dispatch({
            type: 'USER_LOGIN',
            status: 'in',
            payload: payload
        })
    }
}

export function userValidate (payload) {
    return dispatch => {
        // Login successful
        dispatch({
            type: 'USER_VALIDATE',
            payload: payload
        })
    }
}

export function userLogout () {
    
    console.log('logout action');
    return dispatch => {
        dispatch({
            type: 'USER_LOGOUT',
            payload: null
        })
    }
}

export function userNotify (payload) {
    return dispatch => {
        dispatch({
            type: 'USER_NOTIFY',
            payload: payload
        })

    }
}

export function userNotified (payload) {
    return dispatch => {
        dispatch({
            type: 'USER_NOTIFIED',
            payload: payload
        })

    }
}