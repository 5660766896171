import React from 'react';
import './ActivityIndicator.css'

const ActivityIndicator = props => {

    return (  
        <div className="activity-indicator"><div></div><div></div><div></div><div></div></div>
    );
}

export default ActivityIndicator