import React from 'react';
import GlobalContext from '../../context/global-context';
import Helpers from '../global/helpers';
import querystring from 'query-string';
import {
    Table, 
    TableBody, 
    TableCell, 
    TablePagination, 
    TableRow, 
    TextField, 
    Typography, 
    Checkbox, 
    Button, 
    Paper, 
    TableContainer, 
    TableHead, 
    TableSortLabel, 
    Fab, 
    Modal,
    InputAdornment,
    IconButton,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Tabs,
    Tab
} from '@material-ui/core/';
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import AddOrganisation from './add_organisation';
import Refresh from '../global/Refresh';

//
// The headers for the table with sorting
//

const headCells = [
    { id: 'id_organisation', alignRight: false, label: 'ID' },
    { id: 'organisation_name', alignRight: false, label: 'Organisation' },
    { id: 'main_contact_name', alignRight: false, label: 'Main Contact' },
    { id: 'main_contact_email', alignRight: false, label: 'Email' },
    { id: 'main_contact_phone', alignRight: false, label: 'Phone' },
    { id: 'organisation_type_name', alignRight: true, label: 'Type' }
]


const EnhancedTableHead = props => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  


// Organisations class


class Organisations extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        const qs = querystring.parse(this.props.location.search);

        this.state = {
            organisations: [],
            filterdOrganisations: [],
            loading: false,
            table: {
                page:  typeof qs.page != 'undefined'?parseInt(qs.page):0,
                rowsPerPage: 25,
                order: typeof qs.order != 'undefined'?qs.order:'desc',
                orderBy: typeof qs.order_by != 'undefined'?qs.order_by:'id_organisation'
            },
            search: typeof qs.search != 'undefined'?qs.search:'',
            showAdd: false,
            organisation_type: typeof qs.organisation_type != 'undefined'?qs.organisation_type:1,
            organisation_types: []
        };
    }

    componentDidMount(){
        this.getOrganisations();
        this.getOrganisationTypes();
    }

    getOrganisations = () => {
        this.setState({
            loading: true
        }, () => {
            this.context.fetchOrganisations().then(
                res => {
                    this.setState({
                        organisations: res.result,
                        filterdOrganisations: res.result.filter(o => o.organisation_type == this.state.organisation_type),
                        loading: false
                    }, () => {
                        if(this.state.search != '')
                            this.search();
                    })
                },
                err => {
                    this.setState({
                        loading: false
                    })
                    this.context.showAlert('error', 'Error loading organisations');
                }
            )
        })
    }

    getOrganisationTypes = () => {
        this.context.fetchOrganisationTypes().then(
            res => {
                this.setState({
                    organisation_types: res.result
                });
            }, 
            err => {
                this.context.showAlert('error', 'error fetching organistion types: ' + JSON.stringify(err.msg));
            }
        )
    }

    goToOrganisation = (e, id) => {
        this.props.history.push(`/organisation/${id}`);
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            table: {
                ...this.state.table,
                page: newPage
            }
        }, this.handleQueryChange);
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            table: {
                ...this.state.table,
                page: 0,
                rowsPerPage: parseInt(event.target.value, 10)
            }
        }, this.handleQueryChange);
    };

    handleRequestSort = (event, property) => {
        const isAsc = this.state.table.orderBy === property && this.state.table.order === 'asc';
        
        this.setState({
            table: {
                ...this.state.table,
                order: isAsc ? 'desc' : 'asc',
                orderBy: property
            }
        }, this.handleQueryChange);
    };

    handleQueryChange = () => {

        let query = {};
        if(this.state.search != '')
            query.search = this.state.search;
        
        if(this.state.organisation_type != '' && this.state.organisation_type != 1)
            query.organisation_type = this.state.organisation_type;
        
        if(this.state.table.page != 0)
            query.page = this.state.table.page;
    
        if(this.state.table.order != 'desc')
            query.order = this.state.table.order;

        if(this.state.table.orderBy != 'id_order')
            query.order_by = this.state.table.orderBy;
        
        this.props.history.replace('?'+Helpers.makeQueryString(query));

    }

    handleSearchChange = e => {
        this.setState({
            search: e.target.value
        }, this.handleQueryChange)
    }

    handleSearchKeyPress = e => {
        if(e.key == 'Enter')
            this.search();
    }

    handleClearSearch = () => {
        this.setState({
            search: '',
            filterdOrganisations: this.state.organisations.filter(o => o.organisation_type == this.state.organisation_type)
        }, this.handleQueryChange)
    }

    handleNewOrganisation = (id) => {
        this.getOrganisations();
        this.setState({showAdd: false});
    }

    handleOrganisationTypeChange = (e, val) => {
        console.log(val);
        this.setState({
            organisation_type: val,
            filterdOrganisations: this.state.organisations.filter(o => o.organisation_type == val)
        }, () => {
            this.search();
            this.handleQueryChange();
        })
    }

    setShowAdd = (show = true) => {
        this.setState({showAdd: show});
    }


        
    search = () => {
        this.handleQueryChange();

        let organisations = this.state.organisations.filter(o => o.organisation_type == this.state.organisation_type);
        const searchStr = this.state.search.toUpperCase();

        if(this.state.search.length > 0 && this.state.search != ''){
            organisations = organisations.filter(o => (
                this.isNull(o.id_organisation).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(o.organisation_name).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(o.main_contact_name).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(o.main_contact_position).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(o.main_contact_email).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(o.main_contact_phone).toUpperCase().indexOf(searchStr) > -1
            ));
        }

        this.setState({
            filterdOrganisations: organisations
        });
    }

    isNull = (str) => {
        if(typeof str == 'undefined' || str == null)
            return ''
        
        return str.toString();
    }
    

    render(){

        const rows = this.state.filterdOrganisations;
        const page = this.state.table.page;
        const rowsPerPage = this.state.table.rowsPerPage;
        const orderBy = this.state.table.orderBy;
        const order = this.state.table.order;
        const organisation_type = this.state.organisation_types.find(o => o.id_organisation_type == this.state.organisation_type);

        const descendingComparator = (a, b, orderBy) => {
            let x = a[orderBy]!=null?a[orderBy]:'';
            let y = b[orderBy]!=null?b[orderBy]:'';

            if (y < x) return -1;
            if (y > x) return 1;

            return 0;
        }

        const getComparator = (order, orderBy) => {
            return order === 'desc'
              ? (a, b) => descendingComparator(a, b, orderBy)
              : (a, b) => -descendingComparator(a, b, orderBy);
        }

        const tableSort = (array, comparator) => {
            const stabilizedThis = array.map((el, index) => [el, index]);
            stabilizedThis.sort((a, b) => {
              const order = comparator(a[0], b[0]);
              if (order !== 0) return order;
              return a[1] - b[1];
            });
            return stabilizedThis.map(el => el[0]);
          }

        return(
            <div style={styles.container}>
                <div style={styles.inner}>

                    <div style={styles.taskbar}>

                        <Typography variant="h4">{organisation_type && organisation_type.organisation_type_name}s</Typography>

                        <div style={{display:'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end'}}>

                            <Tabs
                                value={this.state.organisation_type}
                                onChange={this.handleOrganisationTypeChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label="types"
                            >
                                {this.state.organisation_types.map(t => (
                                    <Tab key={t.id_organisation_type} value={t.id_organisation_type} label={t.organisation_type_name+'S'} />
                                ))}
                            </Tabs>

                            <Refresh handleClick={this.getOrganisations} loading={this.state.loading} />

                            <Fab onClick={this.setShowAdd} color="secondary" size="medium" aria-label="new organisation">
                                <AddIcon />
                            </Fab>
                        </div>
                    </div>
                    
                    <div style={styles.taskbar}>
                        <div style={{display: 'flex', flex: 1}}>
                            <TextField 
                                name={'Search'} 
                                type="text" 
                                value={this.state.search} 
                                onChange={this.handleSearchChange} 
                                InputLabelProps={{shrink: true,}} 
                                label={`Search ${organisation_type && organisation_type.organisation_type_name}s`} 
                                variant="outlined" 
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {this.state.search.length != '' && <IconButton onClick={this.handleClearSearch}><CloseIcon color="secondary" /></IconButton>}
                                      </InputAdornment>
                                    ),
                                }}
                                onKeyPress={this.handleSearchKeyPress} />
                        </div>
                        <div style={{width: 20}}></div>
                        <div>
                            <Button variant="contained" color={'secondary'} onClick={this.search}>Search</Button>
                        </div>
                    </div>

                    <Paper style={styles.root}>
                        <TableContainer>
                            <Table>
                                
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                {tableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                                    <TableRow
                                    hover
                                    onClick={event => this.goToOrganisation(event, row.id_organisation)} 
                                    key={row.id_organisation}
                                    style={{cursor: 'pointer'}}>
                                        <TableCell component="th" scope="row">{row.id_organisation}</TableCell>
                                        <TableCell>{row.organisation_name}</TableCell>
                                        <TableCell>{row.main_contact_name}</TableCell>
                                        <TableCell>{row.main_contact_email}</TableCell>
                                        <TableCell>{row.main_contact_phone}</TableCell>
                                        <TableCell align="right">{row.organisation_type_name}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>

                </div>
            
                <Modal open={this.state.showAdd} onClose={() => {this.setShowAdd(false)}}>
                    <Paper style={{position: 'absolute', width: '90%', maxWidth: 500, left: '50%', top: '50%', transform: `translate(-50%, -50%)`}}>
                        <AddOrganisation onAdd={this.handleNewOrganisation} />
                    </Paper>
                </Modal>
            </div>
        );
    }

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default Organisations