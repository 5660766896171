import React, {useContext, useEffect, useState} from 'react';
import GlobalContext from '../../../context/global-context';
import ActivityIndicator from '../../global/ActivityIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Helpers from '../../global/helpers';
import { withTheme } from '@material-ui/styles';
import API from '../../../api';
import AwaitingRevoke from './awaiting_revoke';
import AwaitingPay from './awaiting_pay';
import SubjectIcon from '@material-ui/icons/Subject';
import { CircularProgress, IconButton } from '@material-ui/core';


const DownloadButton = props => {


    const context = useContext(GlobalContext);
    const {row} = props;
    const [downloading, setDownloading] = useState(false);

    const downloadExcel = async (row) => {
  
        try {
            
            setDownloading(true);
            await context.apiFileDownload(`/bv/statements/download/${row.id_organisation}/${row.currency}`, `BerthVend_${row.organisation_name}_${new Date().getTime()}.xlsx`);
  
        } catch (error) {
  
            console.log(error);

        } finally {
            setDownloading(false);
        }
  
    }


    return (
        <IconButton onClick={() => {downloadExcel(row)}} color="secondary" disabled={downloading}>{downloading ? <CircularProgress color="inherit" size={24} /> : <SubjectIcon />}</IconButton>
    )
}


const BerthVendAwaiting = props => {

    const [loading, setLoading] = useState(false);
    const [summaryLines, setSummaryLines] = useState([]);

    const [statementRevoke, setStatementRevoke] = useState(null);
    const [statementPay, setStatementPay] = useState(null);


    const loadStatements = async () => {

        try {

            setLoading(true);
            const data = await API.req(`/bv/statements/awaiting`, 'GET', null);
            if(data.success === true)
                setSummaryLines(data.result);

            setLoading(false);
            
        } catch (error) {

            setLoading(false);
            
        }

    }

    useEffect(() => {

        loadStatements();

    }, [])


    return (
        
        <div style={Object.assign({}, !props.show&&styles.hide)}>

        <div style={styles.taskbar}>
            <h2 style={{color: props.theme.palette.primary.main, fontWeight: 300}}>Awaiting Payment</h2>
            <Button variant="contained" color="secondary" onClick={loadStatements}>Reload{loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
        </div>


        <Paper style={styles.root}>
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Marina</TableCell>
                        <TableCell align="right">Country Code</TableCell>
                        <TableCell align="right">Tax Number</TableCell>
                        <TableCell align="right">Currency</TableCell>
                        <TableCell align="right">Mgmnt Fee</TableCell>
                        <TableCell align="right">Trnactn Fee</TableCell>
                        <TableCell align="right">Mgmnt VAT</TableCell>
                        <TableCell align="right">Trnactn VAT</TableCell>
                        <TableCell align="right">Amount Due</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {summaryLines.map(row => (
                    <TableRow key={row.id_organisation}>
                        <TableCell><DownloadButton row={row}/></TableCell>
                        <TableCell>{row.organisation_name}</TableCell>
                        <TableCell align="right">{row.country_code}</TableCell>
                        <TableCell align="right">{row.tax_number}</TableCell>
                        <TableCell align="right">{row.currency}</TableCell>
                        <TableCell align="right">{Helpers.localeCurrency(row.management_fee, row.currency)}</TableCell>
                        <TableCell align="right">{Helpers.localeCurrency(row.transaction_fee, row.currency)}</TableCell>
                        <TableCell align="right">{Helpers.localeCurrency(row.management_vat, row.currency)}</TableCell>
                        <TableCell align="right">{Helpers.localeCurrency(row.transaction_vat, row.currency)}</TableCell>
                        <TableCell align="right">{Helpers.localeCurrency(row.host_due, row.currency)}</TableCell>
                        <TableCell align="right">
                            <Button variant="contained" style={{marginRight: 10}} color={'default'} onClick={() => setStatementRevoke(row)}>Revoke</Button>
                            <Button variant="contained" color={'secondary'} onClick={() => {setStatementPay(row)}}>Pay</Button>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Paper>

        {summaryLines.length == 0 && <div style={styles.nodata}>No Data</div>}
        {statementRevoke !== null && <AwaitingRevoke row={statementRevoke} handleClose={() => setStatementRevoke(null)} onSuccess={() => {setStatementRevoke(null); loadStatements();}} />}
        {statementPay !== null && <AwaitingPay row={statementPay} handleClose={() => setStatementPay(null)} onSuccess={() => {setStatementPay(null); loadStatements();}} />}


        </div>
    )
}


const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    hide: {
        display: 'none'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default withTheme(BerthVendAwaiting)