import React, { useState, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../../styles';

import { 
    Typography, 
    CircularProgress, 
    Button, 
    Dialog, 
    DialogContent, 
    DialogActions, 
    DialogTitle
} from '@material-ui/core';
import GlobalContext from '../../../../context/global-context';






const UserResetPassword = props => {

    const theme = useTheme();
    const styles = useStyles(theme);
    const context = useContext(GlobalContext);

    const id_user = props.id_user;
    const [ password, setPassword ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const [ showConfirm, setShowConfirm ] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        context.apiRequest(`system/user/reset_password`, 'PUT', {id_user: id_user}).then(
            res => {
                setPassword(res.password);
                setLoading(false);
            },
            err => {
                setLoading(false);
                context.showAlert('error', err.msg);
            }
        )
    }

    return (
        <>
            
            {!loading && <Button variant="outlined" color="secondary" onClick={() => setShowConfirm(true)}>Reset Password</Button>}
            {showConfirm && 
                <Dialog onClose={() => setShowConfirm(false)} aria-labelledby="dirty-warning-dialog-title" open={true} maxWidth={'xs'} fullWidth>
                    <DialogTitle>CONFIRM PASSWORD RESET?</DialogTitle>
                    {!password && 
                        <>                                
                            <DialogContent>Pressing reset will stop this user logging in with their current password and will display a new password on screen.</DialogContent>
                            <DialogActions>
                                <Button onClick={() => setShowConfirm(false)}>No</Button>
                                <Button onClick={handleSubmit} color="secondary" disabled={loading}>{loading && <CircularProgress color="secondary" size={18} style={{marginRight: '10px'}} />}Yes</Button>
                            </DialogActions>
                        </>
                    }
                    {password &&
                        <>
                            <DialogContent>
                                <Typography variant="subtitle2">New Password</Typography>
                                <Typography variant="caption">Note this password and forward to the new user who will be asked to change their password on next login</Typography>
                                <Typography variant="h6" style={{marginTop: 10}}>{password}</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setShowConfirm(false)} color="secondary">Got it</Button>
                            </DialogActions>
                        </>
                    }
                </Dialog>
            }
        </>
    )

}

export default UserResetPassword


