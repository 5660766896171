import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import { TextField, Button, FormControl, CircularProgress, Typography, InputLabel, Select } from '@material-ui/core';
import OrganisationLinkedSageAccount from './sage_account';


const AddOrganisation = props => {
    const [loading, setLoading] = useState(false);

    // An empty version of the form
    const base = {
        organisation_name: '',
        main_contact_name: '',
        main_contact_email: '',
        main_contact_phone: '',
        main_contact_position: '',
        organisation_type: null,
        id_sage_customer: null,
        sage_customer_account_number: null
    }

    // Create a local state
    const [form, setForm] = useState({...base});
    const [organisation_types, setOrganisationTypes] = useState([]);


    useEffect(() => {

        setLoading(true)

        context.fetchOrganisationTypes().then(
            res => {
                setOrganisationTypes(res.result);
                setLoading(false)
            }, 
            err => {
                setLoading(false)
                context.showAlert('error', 'error fetching organistion types: ' + JSON.stringify(err.msg));
            }
        )

    }, [])

    useEffect(() => {

        if(typeof props.id_sage_customer !== 'undefined' && props.id_sage_customer !== null){
            let newForm = {...form};
            newForm.id_sage_customer = props.id_sage_customer;
            newForm.sage_customer_account_number = typeof props.sage_customer_account_number !== 'undefined' ? props.sage_customer_account_number : null;
            setForm(newForm);
        }

    }, [props.id_sage_customer, props.sage_customer_account_number])


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    const handleChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleChangeSageAccount = (id_sage_customer, sage_customer_account_number) => {
        let newForm = {...form};
        newForm.id_sage_customer = id_sage_customer;
        newForm.sage_customer_account_number = sage_customer_account_number;
        setForm(newForm);
    }

    const handleLoadSageAccount = (account) => {
        if(account !== null){
            let newForm = {...form};
            if(newForm.organisation_name === '') newForm.organisation_name = account.CustomerAccountName
            if(newForm.main_contact_phone === '') newForm.main_contact_phone = `${account.MainTelephoneCountryCode !== null && account.MainTelephoneCountryCode.length > 0 ? '+':account.MainTelephoneCountryCode} ${account.MainTelephoneAreaCode} ${account.MainTelephoneSubscriberNumber}`
            if(newForm.main_contact_name === '') newForm.main_contact_name = account.ContactName
            setForm(newForm);
        }
    }

    const invalid = () => {
        if(isInvalid('organisation_name') || isInvalid('organisation_type'))
            return true;
        
        return false;
    }

    const isInvalid = prop => {
        switch(prop){
            case 'organisation_name':
                return form.organisation_name == ''?true:false;
            case 'organisation_type':
                return parseInt(form.organisation_type) > 0?false:true;
            default:
                return true;
        }
    }

    const submitForm = () => {
        if(!invalid())
            setLoading(true);
            context.saveOrganisation(form, 'A').then(
                res => {
                    setForm({...base});
                    setLoading(false);
                    context.showAlert('success', 'Organisation added');

                    if(typeof props.onAdd == 'function')
                        props.onAdd(res.result);
                },
                err => {
                    setLoading(false);
                    context.showAlert('error', 'Error adding contact ' + JSON.stringify(err));
                }
            )
    }

    
        
    return (

        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: 20}}>
            <Typography variant="h5">Add Organisation</Typography>


            <div style={{flex: 1, marginTop: 10, padding: 10}}>
                <OrganisationLinkedSageAccount 
                    id_sage_customer={form.id_sage_customer} 
                    sage_customer_account_number={form.sage_customer_account_number} 
                    edit={true} 
                    onChange={handleChangeSageAccount}
                    onAccountLoad={handleLoadSageAccount}
                    />
            </div>


            <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="organisation_type" shrink={true}>Organisation Type</InputLabel>
                    <Select
                    native
                    value={form.organisation_type}
                    onChange={handleChange}
                    inputProps={{
                        name: 'organisation_type',
                        id: 'organisation_type',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {organisation_types.map(t => (
                        <option key={t.id_organisation_type} value={t.id_organisation_type}>{t.organisation_type_name}</option>
                    ))}
                    </Select>
                </FormControl>
            </div>

            <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                <FormControl fullWidth>
                    <TextField name="organisation_name" value={form.organisation_name} onChange={handleChange} label="Organisation Name" InputLabelProps={{shrink: true}} />
                </FormControl>
            </div>

            <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                <FormControl fullWidth>
                    <TextField name="main_contact_name" value={form.main_contact_name} onChange={handleChange} label="Contact Name" InputLabelProps={{shrink: true}} />
                </FormControl>
            </div>

            <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                <FormControl fullWidth>
                    <TextField name="main_contact_position" value={form.main_contact_position} onChange={handleChange} label="Contact Position" InputLabelProps={{shrink: true}} />
                </FormControl>
            </div>

            <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                <FormControl fullWidth>
                    <TextField name="main_contact_email" value={form.main_contact_email} onChange={handleChange} label="Contact Email" InputLabelProps={{shrink: true}} />
                </FormControl>
            </div>

            <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                <FormControl fullWidth>
                    <TextField name="main_contact_phone" value={form.main_contact_phone} onChange={handleChange} label="Contact Phone" InputLabelProps={{shrink: true}} />
                </FormControl>
            </div>

            <div style={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 30, justifyContent: 'center', alignItems: 'flex-end', padding: 10}}>
                <div style={{flex: 1}}></div>
                <div style={{padding: '0 10px'}}>
                    <Button color="secondary" variant="contained" disabled={invalid()} onClick={submitForm}>Add {loading && <CircularProgress size={20} className="ml" color="inherit" />}</Button>
                </div>
            </div>
        </div>

    );
}

export default AddOrganisation