import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {Box, MenuItem, Typography} from '@material-ui/core'

const SendStatementConfirmDialog = props => {
    const {row, emailBatchCSV} = props;
    const [show, setShow] = useState(false);
    const [result, setResult] = useState(null);

    const handleSendBatchCSV = async () => {
        const sendResult = await emailBatchCSV(row.groupSchemeID, row.Currency, row.Batches);
        setResult(sendResult);
    }

    const handleClose = () => {
        setShow(false);
        setResult(null);
    }

    return (
        <>
            <MenuItem onClick={() => setShow(true)}>Email Statement</MenuItem> 
            <Dialog open={show} fullWidth maxWidth="xs">
                
                {result === null && 
                    <Box style={{padding: 20, display: 'flex', gap: 10, flexDirection: 'column'}}>
                        <Typography variant="h6">Confirm send statment</Typography>
                        <Typography variant="body2">This will email the statement to the accounts contact in the back office and also BCC the designated internal mailbox.</Typography>

                        <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleSendBatchCSV} variant="contained" color="secondary">Confirm</Button>
                        </Box>
                    </Box>
                }

                {result !== null && 
                    <Box style={{padding: 20, display: 'flex', gap: 10, flexDirection: 'column'}}>
                        
                        {result === 'SENT' &&
                            <>
                                <Typography variant="h6">Statements Sent</Typography>
                                <Typography variant="body2">The statement(s) should be received shortly.</Typography>
                            </>
                        }
                        
                        {result === 'PARTIAL' &&
                            <>
                                <Typography variant="h6">Statements Partially Sent</Typography>
                                <Typography variant="body2">One or more statements failed to send, those that were able to send should be received shortly.</Typography>
                            </>
                        }
                        
                        {result === 'ERROR' &&
                            <>
                                <Typography variant="h6">Error sending statement</Typography>
                                <Typography variant="body2">Unfortunally the system was unable to send the statement(s). Please try again.</Typography>
                            </>
                        }

                        <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <Button onClick={handleClose}>OK</Button>
                        </Box>
                    </Box>
                }


            </Dialog>
        </>
    )
}

export default SendStatementConfirmDialog