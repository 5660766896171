const pins = {
    user: require('./pin_user_location.png'),
    multi: require('./pin_point_multi.png'),
    blue: require('./blue.png'),
    green: require('./green.png'),
    red: require('./red.png'),
    black: require('./black.png'),
    white: require('./white.png'),
    yellow: require('./yellow.png'),
    orange: require('./orange.png'),
    purple: require('./purple.png')
};

export default pins;