import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'


const Prompt = props => {

    const handleClose = () => {
        if(typeof props.onClose === 'function')
            props.onClose();
    }

    const handleCancel = () => {

        if(typeof props.onCancel === 'function')
            props.onCancel();

        handleClose();

    }

    const handleConfirm = () => {
        if(typeof props.onConfirm === 'function')
            props.onConfirm();
    }

    return (
        <Dialog open={props.show} onClose={handleClose}>
            <DialogTitle>{props.title || 'Just a second'}</DialogTitle>
            <DialogContent>{props.message}</DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{props.cancelLabel || 'Cancel'}</Button>
                <Button onClick={handleConfirm} variant="contained" color="secondary">{props.cancelLabel || 'Confirm'}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Prompt