import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles } from '@material-ui/core';
import { useStyles } from '../styles';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';

const SimProfiles = props => {
    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <React.Fragment>
            <div className={styles.root}>
                <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'SIM Profiles',
                            route: '/settings/sim_profiles'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">SIM Profiles</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>A list of service profiles created in Emnify.</Typography>

                <Crud
                    uri='devices/service_profiles'
                    idCol='id'
                    readOnly={true}
                    displayColumns={[
                        { 
                            name: 'id', 
                            type: 'string',
                            display_name: 'ID'
                        }, 
                        { 
                            name: 'name', 
                            type: 'string'
                        }, 
                        {
                            name: 'allowed_3g', 
                            type: 'boolean',
                            display_name: '3G UMTS'
                        }, 
                        {
                            name: 'allowed_4g', 
                            type: 'boolean',
                            display_name: '4G LTE'
                        }, 
                        {
                            name: 'used_count', 
                            type: 'number',
                            display_name: 'Devices'
                        }
                    ]}
                />

                </div>
            </div>
        </React.Fragment>
    )
}
export default SimProfiles;