import React, {useContext, useState} from 'react';
import ProgressButton from '../../components/progressButton';
import globalContext from '../../context/global-context';

const SageLinkOrganisation = props => {
    const context = useContext(globalContext);

    const [loading, setLoading] = useState(false);

    const handleLinkOrganisation = async () => {
        setLoading(true);
        try {

            const res = await context.apiRequest(`/organisation/link`, 'POST', {
                id_organisation: props.id_organisation,
                id_sage_customer: props.id_sage_customer,
                sage_customer_account_number: props.sage_customer_account_number
            });
            
            if(res.success)
                context.showAlert('success', 'Account linked')
                if(typeof props.onSuccess === 'function')
                    props.onSuccess();
            else
                context.showAlert('error', res.msg)

        } catch (error) {
            context.showAlert('error', typeof error === 'string' ? error : 'Something went wrong, please try again')
            console.log(error);
        } finally {
            setLoading(false);
        }
    }


    return (
        <React.Fragment>
            <ProgressButton waiting={loading} onClick={handleLinkOrganisation} variant="contained" size="small" color="secondary">Link this organisation</ProgressButton>
        </React.Fragment>
    )
}
export default SageLinkOrganisation;