import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import Helpers from '../global/helpers';
import GlobalContext from '../../context/global-context';
import { useStyles } from './styles';
import querystring from 'query-string';

import { 
    ticketOpen, 
    ticketAdd, 
    ticketUpdate, 
    ticketAddComment, 
    ticketDirty, 
    ticketDiscard, 
    ticketMeta, 
    ticketsGet, 
    ticketAddContact, 
    ticketRemoveContact
 } from '../../actions/ticketsActions';
import { useTheme } from '@material-ui/styles';
import { 
    Typography, 
    Grow, 
    List, 
    Divider, 
    Button, 
    FormControl,
    Select,
    MenuItem
     } from '@material-ui/core';

import EvCircularProgess from '../global/EvCircularProgress';

import WarningIcon from '@material-ui/icons/Warning';

import TicketSummary from './ticket_summary';
import Ticket from './ticket';
import TicketAdd from './ticket_add';
import TicketSearch from './ticket_search';




// Tickets class
const Tickets = props => {

    const context = useContext(GlobalContext)
    const qs = querystring.parse(props.location.search);

    const [ ticketUnit, setTicketUnit ] = useState(qs.unit || null);

    const [ showNewTicket, setShowNewTicket ] = useState(false);

    const theme = useTheme();
    const styles = useStyles(theme);

    const tickets = props.tickets.tickets;
    const openTicket = props.tickets.openTicket;
    const dirtyTicket = props.tickets.dirtyTicket;
    const ticketMeta = props.tickets.meta;
    const status = props.tickets.status;

    // on load
    useEffect(() => {
        props.ticketsGet();
        props.ticketMeta();

        if(props.user.ticket_units.length > 0 && ticketUnit == null)
            setTicketUnit(props.user.ticket_units[0].id_ticket_unit)

    }, [])


    //
    //  If the route is for a specific ticket, 
    //  open it once the tickets have loaded
    //
    
    useEffect(() => {

        if(props.match.params.id && tickets.length > 0){
            const ticket = tickets.find(t => t.ticket_ref == props.match.params.id);

            if(openTicket === null && typeof ticket != 'undefined')
                showTicket(ticket);

            if(openTicket != null && openTicket.ticket_ref != ticket.ticket_ref)
                showTicket(ticket);

            if(typeof ticket == 'undefined')
                context.showAlert('error', `Could not find ticket ${props.match.params.id}`)
        } else {
            if(openTicket != null)
                closeTicket();
        }


    }, [tickets, props.match.params.id])

    const handleSetTicketUnit = e => {
        if(e.target.value != null){
            setTicketUnit(e.target.value); 
            const query = {
                unit: e.target.value
            }
            props.history.replace('?'+Helpers.makeQueryString(query));
        }
    }


    const newTicket = () => {
        setShowNewTicket(true);
    }

    const showTicket = ticket => {
        props.ticketOpen(ticket);
    }

    const handleOpenTicket = ticket => {
        if(openTicket != null)
            props.history.replace(`/tickets/monitor/${ticket.ticket_ref}`)
        else
            props.history.push(`/tickets/monitor/${ticket.ticket_ref}`)
    }

    const closeTicket = () => {
        //setOpenTickets([]);
        props.ticketOpen(null);
        props.history.replace(`/tickets/monitor?unit=${ticketUnit}`)
    }

    const comparator = (a, b, orderBy, order = 'desc') => {
        var x, y;

        if(Array.isArray(orderBy)){

            orderBy.map(z => {
                x = a[z]!=null?a[z]:'';
                y = b[z]!=null?b[z]:'';

                if(isNaN(x) === false)
                    x = Number(x);

                if(isNaN(y) === false)
                    y = Number(x);

                if(x !== y){
                    if (y < x) return order == 'desc' ? -1 : 1;
                    if (y > x) return order == 'desc' ? 1 : -1;
                }
            })

        } else {

            x = a[orderBy]!=null?a[orderBy]:'';
            y = b[orderBy]!=null?b[orderBy]:'';

            if (y < x) return order == 'desc' ? -1 : 1;
            if (y > x) return order == 'desc' ? 1 : -1;

        }
        return 0;
    }

    const sortTickets = (array, orderBy) => {
        var revOrder = JSON.parse(JSON.stringify(orderBy)).reverse();
        var arr = [...array];
        revOrder.map(o => {
            arr = arr.sort((a, b) => {
                return comparator(a, b, o.orderBy, o.order)
            })
        })
        return arr;
    }

    const filteredTickets = status => {
        if(parseInt(ticketUnit) === 0)
            return tickets.filter(t => t.id_ticket_status === status && t.id_user_assigned == props.user.id_user);
        else
            return tickets.filter(t => t.id_ticket_status === status && t.id_ticket_unit == ticketUnit);
    }

    const filteredUnits = () => {
        if(props.user == null)
            return []

        const filtered = props.tickets.meta.units.filter(u => props.user.ticket_units.findIndex(uu => uu.id_ticket_unit == u.id_ticket_unit) > -1);
        return filtered;
    }

    const filteredStatuses = () => {
        const unit = props.tickets.meta.units.find(u => u.id_ticket_unit == ticketUnit)

        if(props.user == null)
            return []

        const filtered = props.tickets.meta.statuses.filter(s => {

            if(typeof unit != 'undefined' && props.user.ticket_unit_groups.findIndex(uug => uug.id_ticket_unit_group == unit.id_ticket_unit_group) > -1)
                return true;

            return s.management_status != 1
        });
        return filtered;
    }

    const isActive = ticket => {
        if(openTicket === null)
            return false;

        return openTicket != null && openTicket.id_ticket === ticket.id_ticket;
    }

    const isFresh = ticket => {
        if(Helpers.SQLTimeSinceSecs(ticket.last_updated) < 20)
            return true;

        return false;
    }

    return (
        <div className={styles.root}>

            {/* LOADING STATUS */}
            {(status.tickets == 'loading' || status.meta == 'loading') && 
                <div className={styles.wrapper}>
                    <div style={{flex: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <EvCircularProgess size={60} color='primary' />
                        <Typography variant="h6" color="textSecondary" style={{marginLeft: 10}}>{status.tickets == 'loading' ? 'Loading Tickets' : status.meta == 'loading' ? 'Loading EV Network & System' : 'Nearly There'}</Typography>
                    </div>
                </div>
            }

            {/* LOADING STATUS */}
            {(status.tickets == 'error' || status.meta == 'error') && 
                <div className={styles.wrapper}>
                    <div style={{flex: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <WarningIcon color="error" />
                        <Typography variant="h6" color="error" style={{marginLeft: 10}}>Something went wrong loading either the tickets or system data. Try reloading.</Typography>
                    </div>
                </div>
            }


            {/* MAIN TICKETS VIEW */}
            {(status.tickets == 'ok' && status.meta == 'ok') &&
            <div className={styles.wrapper}>
            
                <div className={styles.grid}>

                    {/* TICKETS SUMMARY */}

                    {(context.breakpoint('lg') || openTicket == null) && 
                    <div className={styles.tickets}>
                        {tickets.length > 0 &&
                            <div className={styles.ticketsInner}>


                                {/* TICKETS SUMMARY TASKBAR */}

                                <div className={styles.taskbar}>

                                    <div style={{display:'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                        <Typography variant="h4" style={{marginBottom: theme.spacing(1), marginRight: theme.spacing(2)}}>Tickets</Typography>
                                        
                                        <FormControl className={styles.inputField} size="small">
                                            <Select
                                                labelId="ticket_unit_label"
                                                id="id_ticket_unit"
                                                name="id_ticket_unit"
                                                value={ticketUnit}
                                                onChange={handleSetTicketUnit}
                                            >
                                                <MenuItem value={null}>-- Select a unit</MenuItem>
                                                <MenuItem value={0}>Owned Tickets</MenuItem>
                                                {filteredUnits().map((unit, idx) => (
                                                    <MenuItem key={idx} value={unit.id_ticket_unit}>{unit.ticket_unit_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div style={{display:'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end'}}>
                                        <Button color="secondary" variant="contained" onClick={newTicket}>New</Button>
                                    </div>

                                </div>


                                <TicketSearch tickets={tickets} openMode={openTicket != null} />


                                {/* SUMMARY OF TICKETS */}


                                {filteredStatuses().map((status, idx) => (
                                    
                                    <div key={idx}>
                                        <Typography variant="subtitle2">{status.ticket_status_name}</Typography>
                                        <Divider />

                                        <List>
                                            {sortTickets(
                                                filteredTickets(status.id_ticket_status),
                                                [{
                                                    orderBy: 'priority_score',
                                                    order: 'desc'
                                                }]).map((t, idx) => (
                                                <Grow key={idx} in={true}>
                                                    <TicketSummary ticket={t} handleOpenTicket={handleOpenTicket} openMode={openTicket != null} isActive={isActive(t)} isFresh={isFresh(t)} />
                                                </Grow>
                                            ))}
                                        </List>
                                    </div>

                                ))}

                            </div>
                        }



                        {(tickets.length === 0 && status.tickets == null) && 
                            <Typography variant="subtitle1">No tickets to show</Typography>
                        }



                    </div>
                    }


                    {openTicket != null &&        
                        <div className={styles.ticketPane}>
                            <div className={styles.ticketPaneInner}>
                                <Ticket 
                                    user={props.user}
                                    handleCloseTicket={closeTicket} 
                                    ticket={props.tickets.openTicket} 
                                    handleUpdateTicket={props.ticketUpdate} 
                                    handleAddComment={props.ticketAddComment} 
                                    handleDirty={props.ticketDirty} 
                                    dirty={dirtyTicket}
                                    handleDiscardTicket={props.ticketDiscard}
                                    ticketMeta={ticketMeta}
                                    handleAddContact={props.ticketAddContact}
                                    handleRemoveContact={props.ticketRemoveContact}
                                    handleAddGroupScheme={props.ticketAddGroupScheme}
                                    handleRemoveGroupScheme={props.ticketRemoveGroupScheme}
                                    handleAddSite={props.ticketAddSite}
                                    handleRemoveSite={props.ticketRemoveSite}
                                    handleAddChargePoint={props.ticketAddChargePoint}
                                    handleRemoveChargePoint={props.ticketRemoveChargePoint}
                                    editMode={props.tickets.openTicket.mode == 'new' ? true : false} />
                            </div>
                        </div>        
                    }
                </div>
            </div>}

            {showNewTicket && <TicketAdd ticketMeta={ticketMeta} handleClose={() => setShowNewTicket(false)} handleAddTicket={props.ticketAdd} />}
        </div>
    )

}

const mapStateToProps = state => {
    return {
        tickets: state.tickets,
        user: state.user.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ticketsGet: () => {
            dispatch(ticketsGet());
        },
        ticketOpen: ticket => {
            dispatch(ticketOpen(ticket));
        },
        ticketAdd: ticket => {
            dispatch(ticketAdd(ticket));
        },
        ticketUpdate: ticket => {
            dispatch(ticketUpdate(ticket));
        },
        ticketDiscard: ticket => {
            dispatch(ticketDiscard(ticket));
        },
        ticketAddComment: comment => {
            dispatch(ticketAddComment(comment))
        },
        ticketAddContact: contact => {
            dispatch(ticketAddContact(contact))
        },
        ticketRemoveContact: contact => {
            dispatch(ticketRemoveContact(contact))
        },
        ticketDirty: dirty => {
            dispatch(ticketDirty(dirty))
        },
        ticketMeta: metadata => {
            dispatch(ticketMeta(metadata))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tickets)
