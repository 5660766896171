import React from 'react';
import GlobalContext from '../../context/global-context';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Logs from '../logs/logs';
import Orders from './orders';
import Refresh from '../global/Refresh';
import OrganisationLinkedSageAccount from './sage_account';
import OrganisationMergeDialog from './organisation_name';


class Organisation extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            id: null,
            mode: 'V',
            original_organisation: null,
            organisation: null,
            loading: {
                organisation: false,
                organisation_types: false,
                saving: false
            },
            organisation_types: [],
            showMerge: false
        };
    }

    componentDidMount(){

        this.fetchTypes();
        this.init();
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.id != prevProps.match.params.id)
            window.location.reload(false);
    } 

    init = () => {

        /*

            params.id is either an integer or 'add'

            MODES
            V = View
            A = Add
            E = Edit

        */

        const id = this.props.match.params.id;
        let mode = 'V';
        if(id.toString().toUpperCase() == 'ADD')
            mode = 'A';

        this.setState({
            id: id,
            mode: mode,
            loading: {
                ...this.state.loading,
                organisation: mode=='A'?false:true
            }
        }, () => {

            if(mode == 'V')
                this.context.fetchOrganisation(id).then(
                    res => {
                        this.setState({
                            organisation: res.result,
                            original_organisation: JSON.parse(JSON.stringify(res.result)),
                            loading: {
                                ...this.state.loading,
                                organisation: false
                            }
                        });
                    },
                    err => {
                        this.setState({
                            organisation: null,
                            loading: {
                                ...this.state.loading,
                                organisation: false
                            }
                        });
                        this.context.showAlert('error', 'Error loading Organisation ' + JSON.stringify(err));
                    }
                )
        })

    }

    fetchTypes = () => {

        this.setState({
            loading: {
                ...this.state.loading,
                organisation_types: true
            }
        }, () => {
            this.context.fetchOrganisationTypes().then(
                res => {
                    this.setState({
                        organisation_types: res.result,
                        loading: {
                            ...this.state.loading,
                            organisation_types: false
                        }
                    });
                }, 
                err => {
                    
                    this.setState({
                        loading: {
                            ...this.state.loading,
                            organisation_types: false
                        }
                    });
                    this.context.showAlert('error', 'error fetching organistion types: ' + JSON.stringify(err.msg));
                }
            )
        })
    }

    handleChange = (e, callback = () => {}) => {
        let organisation = {...this.state.organisation};
        organisation[e.target.name] = e.target.value;
        this.setState({organisation: organisation}, () => {
            typeof callback === 'function' && callback();
        });
    }

    handleChangeSageAccount = (id_sage_customer, sage_customer_account_number, callback = () => {}) => {
        let organisation = {...this.state.organisation};
        organisation.id_sage_customer = id_sage_customer;
        organisation.sage_customer_account_number = sage_customer_account_number;
        this.setState({organisation: organisation}, () => {
            typeof callback === 'function' && callback();
        });
    }

    modifyMode = () => {
        this.setState({mode: 'E'});
    }

    saveOrganisation= () => {
        this.setState({
            loading: {
                ...this.state.loading,
                saving: true
            }
        }, () => {
            this.context.saveOrganisation(this.state.organisation, 'E').then(
                res => {

                    this.context.showAlert('success', `Organisation saved`);

                    this.setState({
                        loading:{
                            ...this.state.loading,
                            saving: false
                        }
                    }, () => {
                        // reloading the order from the database
                        // this is useful not only to read back the data but also
                        // because a quirk exists in the form control labels
                        // which causes them to overlay on the values
                        // if the init is re-called
                        this.init();
                    });

                },
                err => {
                    this.setState({
                        loading:{
                            ...this.state.loading,
                            saving: false
                        }
                    });
                    this.context.showAlert('error', 'Error saving organisation ' + JSON.stringify(err));
                }
            );
        })
        this.setState({mode: 'V'});
    }

    cancelModify = () => {
        this.setState({
            mode: 'V',
            organisation: JSON.parse(JSON.stringify(this.state.original_organisation))
        });
    }

    render(){

        const organisation = this.state.organisation;
        const readOnly = this.state.mode=='V'?true:false;
        const variant = readOnly?'outlined':'filled';

        const setShowMerge = (val) => {
            this.setState({showMerge: val})
        }

        return(
            <div style={styles.container}>

                <Backdrop open={this.state.loading.organisation} style={{zIndex: 999}}>
                    <CircularProgress color="inherit" />
                </Backdrop>


                {/* Providing we have an id we can show something */}
                {this.state.id != null &&
                    <div style={styles.inner}>

                        {this.state.mode != 'A' &&
                        <div style={{flex: 1}}>

                            {organisation != null &&

                                <div style={styles.taskbar}>

                                    <Typography variant="h4">#{this.state.id} {organisation.organisation_name}</Typography>

                                    <Refresh handleClick={this.init} loading={this.state.loading.organisation} />

                                </div>
                            
                            }

                            {organisation != null &&

                            <div style={styles.cols}>

                                <div style={styles.organisation}>

                                    <Paper style={{padding: 30, display: 'flex', flexDirection: 'column'}}>


                                        <Grid container spacing={3}>

                                            <Grid container item spacing={3} direction="row" justify="space-between">
                                                <div>
                                                </div>

                                                <div>
                                                    {this.state.mode == 'V' && <Button variant="contained" color="secondary" onClick={this.modifyMode}>Modify</Button>}
                                                    {this.state.mode == 'E' && <Button variant="contained" onClick={this.cancelModify}>Cancel</Button>}
                                                    {this.state.mode == 'E' && <Button variant="contained" color="secondary" onClick={this.saveOrganisation}>Save</Button>}
                                                </div>
                                            </Grid>



                                            <Grid container item spacing={3} direction="row" alignItems="flex-start">

                                                {/* PRODUCT DETAILS */}
                                                
                                                <Grid 
                                                    container 
                                                    item xs={12} 
                                                    sm={6}
                                                >

                                                    <Grid item style={styles.item}>
                                                        <Typography variant="h6">Organisation</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.item}>
                                                        {/* ORGANISATION NAME */}
                                                        <TextField
                                                            style={styles.input}
                                                            name="organisation_name"
                                                            label="Organisation Name"
                                                            helperText="e.g. 'vendelectric'"
                                                            variant={variant}
                                                            value={organisation.organisation_name}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.item}>
                                                        {/* ORGANISATION TYPE */}
                                                        <FormControl
                                                            variant={variant}
                                                            style={styles.inputSmall}
                                                            >
                                                            <InputLabel id="organisation-type-label">Organisation Type</InputLabel>
                                                            <Select
                                                            labelId="organisation-type-label"
                                                            name="organisation_type"
                                                            value={organisation.organisation_type}
                                                            onChange={this.handleChange}
                                                            label="Organisation Type"
                                                            inputProps={{ readOnly: readOnly }}
                                                            >
                                                                {this.state.organisation_types.map(type => (
                                                                    <MenuItem key={type.id_organisation_type} value={type.id_organisation_type}>{type.organisation_type_name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.item}>
                                                        <OrganisationLinkedSageAccount id_sage_customer={organisation.id_sage_customer} sage_customer_account_number={organisation.sage_customer_account_number} edit={!readOnly} onChange={this.handleChangeSageAccount} />
                                                    </Grid>

                                                </Grid>




                                                {/* PROJECT STATUS */}

                                                <Grid container item xs={12} sm={6} spacing={3} alignItems="flex-start">

                                                    <Grid item xs={12} style={styles.item}>
                                                        <Typography variant="h6">Main Contact</Typography>
                                                    </Grid>


                                                    <Grid item xs={12} style={styles.item}>
                                                        {/* NAME */}
                                                        <TextField
                                                            style={styles.input}
                                                            name="main_contact_name"
                                                            label="Name"
                                                            helperText="e.g. 'Rob Turner'"
                                                            variant={variant}
                                                            value={organisation.main_contact_name}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                        />
                                                    </Grid>


                                                    <Grid item xs={12} style={styles.item}>
                                                        {/* POSITION  */}
                                                        <TextField
                                                            style={styles.input}
                                                            name="main_contact_position"
                                                            label="Position"
                                                            helperText="e.g. 'Managing Director'"
                                                            variant={variant}
                                                            value={organisation.main_contact_position}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                                readOnly: readOnly,
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.item}>
                                                        {/* EMAIL */}
                                                        <TextField
                                                            style={styles.input}
                                                            name="main_contact_email"
                                                            label="Email"
                                                            helperText="e.g. 'robt@vendelectric.com'"
                                                            variant={variant}
                                                            value={organisation.main_contact_email}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.item}>
                                                        {/* PHONE */}
                                                        <TextField
                                                            style={styles.input}
                                                            name="main_contact_phone"
                                                            label="Phone"
                                                            helperText="e.g. '01636 640504'"
                                                            variant={variant}
                                                            value={organisation.main_contact_phone}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                        />
                                                    </Grid>


                                                </Grid>

                                            </Grid>

                                        </Grid>


                                    </Paper>
                                    

                                    <Paper style={{marginTop: 15, padding: 30, display: 'flex', flexDirection: 'column'}}>


                                        <Grid container spacing={3}>

                                            <Grid container item spacing={3} direction="row" justify="space-between">

                                                <Orders id_organisation={this.state.organisation.id_organisation} history={this.props.history} />

                                            </Grid>
                                        
                                        </Grid>

                                    </Paper>

                                    <div style={{marginTop: 20}}><Button variant="contained" color="secondary" onClick={() => setShowMerge(true)}>Merge</Button></div>
                                    

                                </div>

                                <div style={styles.logs}>

                                    <Logs id_organisation={this.state.organisation.id_organisation} />

                                </div>

                                <OrganisationMergeDialog show={this.state.showMerge} organisation={this.state.organisation} handleCancel={() => setShowMerge(false)} handleConfirm={() => setShowMerge(false)} />

                            </div>
                            
                            }

                        </div>

                        }


                    </div>
                }
            </div>
        );
    }

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    cols: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    organisation: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minWidth: '60%'
    },
    logs: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '40%',
        width: '100%',
        maxWidth: 400
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 35,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    },
    input: {
        maxWidth: 400,
        width: '100%'
    },
    inputSmall: {
        maxWidth: 220,
        minWidth: 120,
        width: '100%'
    },
    dateWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    space: {
        width: 20
    },
    item: {
        paddingBottom: 15,
        flexGrow: 'unset'
    }
}

export default Organisation