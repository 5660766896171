import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const TicketUnitGroupUsers = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Ticket Unit Group Users',
                            route: '/settings/ticket_unit_group_users'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Ticket Unit Group Users</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>If a user is to manage a whole unit group, assign them here and they will see the tickets for that unit group.</Typography>

                <Crud
                    uri='ticket_unit_group_users'
                    idCol='id_ticket_unit_group_user'
                    displayColumns={[
                        { 
                            name: 'id_user', 
                            type: 'select',
                            display_name: 'User',
                            editable: true,
                            required: true
                        }, 
                        { 
                            name: 'id_ticket_unit_group', 
                            type: 'select',
                            display_name: 'Unit Group',
                            editable: true,
                            required: true
                        }
                    ]}
                    selectors={[
                        {
                            uri: 'users',
                            name: 'user',
                            col_name: 'id_user',
                            selector_name: 'id_user',
                            display_col: 'name'
                        },
                        {
                            uri: 'ticket_unit_groups',
                            name: 'unit_group',
                            col_name: 'id_ticket_unit_group',
                            selector_name: 'id_ticket_unit_group',
                            display_col: 'ticket_unit_group_name'
                        }
                    ]}
                    canRemove={true}
                />

            </div>
        </div>
    )

}

export default TicketUnitGroupUsers
