import React from 'react';
import { Typography } from '@material-ui/core';

const NotAuthorised = props => {


    const styles  = {
        container: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            overflow: 'auto',
            justifyContent: 'center',
            alignItems: 'center'
        },
        inner: {
            padding: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexFlow: 'row wrap',
            flexWrap: 'wrap'
        }
    }
        
    return (

        <div style={styles.container}>
            <div style={styles.inner}>
                <Typography variant="h5" color="textSecondary">Whoops, you don't have permission to access this sector.</Typography>
            </div>
        </div>
    );
}

export default NotAuthorised