import React from 'react';
import GlobalContext from '../../context/global-context';
import AddOrder from './add_order';
import Helpers from '../global/helpers';

import {Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem, Menu, CircularProgress, Backdrop, Paper, Tooltip, FormControlLabel, Switch, Grid, Collapse, IconButton, Popover, } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import RepeatIcon from '@material-ui/icons/Repeat';
import WarningIcon from '@material-ui/icons/Warning';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';
import ScheduleIcon from '@material-ui/icons/Schedule';

import Logs from '../logs/logs';
import Subscriptions from '../subscriptions';
import OrderAttachments from './order_attachments';

import Modal from '@material-ui/core/Modal';
import OrderContacts from '../order_contacts';
import SwitchOrderOrganisation from './switch_order';
import IssueDeactivationNoticeDialog from './deactivation_notice';
import ClearDeactivationNoticeDialog from './deactivation_clear';
import OrderControlDocument from './order_control_document';
import OrderRenewalDialog from './order_renewal_dialog';



const connectivity_types = [
    {id_connectivity: 1, connectivity_description: 'GPRS'},
    {id_connectivity: 2, connectivity_description: 'Ethernet'},
    {id_connectivity: 3, connectivity_description: 'Wifi'}
];

class Order extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            id: null,
            mode: 'V',
            original_order: null,
            order: null,
            loading: {
                order: false,
                saving: false
            },
            organisationOpen: false,
            showSwitch: false,
            showDeactivation: false,
            showClearDeactivation: false,
            showControlDocument: false,
            showRenewalDialog: false,
            cancellation_reasons: [],
            order_statuses: [],
            reloadCount: 0,
            groupSchemes: [],
            groupSchemesOpen: false,
            selectedGroupScheme: null,
            anchorElMenu: null
        };

        this.groupScheme = React.createRef();
    }

    componentDidMount(){
        this.init();
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.id != prevProps.match.params.id)
            window.location.reload(false);
    } 

    init = () => {

        /*

            params.id is either an integer or 'add'

            MODES
            V = View
            A = Add
            E = Edit

        */

        const id = this.props.match.params.id;
        let mode = 'V';
        if(id.toString().toUpperCase() == 'ADD')
            mode = 'A';

        this.setState({
            id: id,
            mode: mode,
            loading: {
                ...this.state.loading,
                order: mode=='A'?false:true
            },
            showSwitch: false,
            showDeactivation: false,
            showClearDeactivation: false,
            reloadCount: this.state.reloadCount++
        }, () => {
            if(mode == 'V')
                this.context.fetchOrder(id).then(
                    res => {
                        this.setState({
                            order: res.result,
                            selectedGroupScheme: {
                                groupSchemeId: null,
                                groupSchemeName: res.result.group_scheme
                            },
                            original_order: JSON.parse(JSON.stringify(res.result)),
                            loading: {
                                ...this.state.loading,
                                order: false
                            }
                        }, () => {
                            this.fetchGroupSchemes();
                        });
                    },
                    err => {
                        this.setState({
                            order: null,
                            loading: {
                                ...this.state.loading,
                                order: false
                            }
                        });
                        this.context.showAlert('error', 'Error loading order ' + JSON.stringify(err));
                    }
                )

                this.context.fetchOrderCancellationReasons().then(
                    res => {
                        this.setState({cancellation_reasons: res.result})
                    },
                    err => {
                        this.context.showAlert('error', 'Error loading cancellation reasons ' + JSON.stringify(err));
                    }
                )

                this.context.fetchOrderStatuses().then(
                    res => {
                        this.setState({order_statuses: res.result})
                    },
                    err => {
                        this.context.showAlert('error', 'Error loading order statuses ' + JSON.stringify(err));
                    }
                )
        })

    }


    fetchGroupSchemes = () => {
        
        var source = 'veos';
        if(this.state.order.id_scheme != null && parseInt(this.state.order.id_scheme) === 2)
            source = 'wavemasta';

        this.context.fetchGroupSchemes(source).then(
            res => {
                this.setState({
                    groupSchemes: res.result
                })
            },
            err => {
                this.context.showAlert('error', 'Error loading group schemes ' + JSON.stringify(err));
            }
        )
    }

    handleChange = (e, callback = () => {}) => {
        let order = {...this.state.order};
        order[e.target.name] = e.target.value;
        this.setState({order: order}, () => {
            typeof callback === 'function' && callback();
        });
    }

    handleSwitchChange = (e, callback = () => {}) => {
        let order = {...this.state.order};
        order[e.target.name] = e.target.checked?1:0;
        this.setState({order: order}, () => {
            typeof callback === 'function' && callback();
        });
    }

    modifyMode = () => {
        this.setState({mode: 'E'});
    }

    saveOrder = () => {
        this.setState({
            loading: {
                ...this.state.loading,
                saving: true
            }
        }, () => {
            if(this.saveOrderValid()){
                this.context.saveOrder(this.state.order, 'E').then(
                    res => {

                        this.context.showAlert('success', `Order saved`);

                        this.setState({
                            ...this.state.loading,
                            saving: false
                        }, () => {
                            // reloading the order from the database
                            // this is useful not only to read back the data but also
                            // because a quirk exists in the form control labels
                            // which causes them to overlay on the values
                            // if the init is re-called
                            this.init();
                        });

                    },
                    err => {
                        this.setState({
                            ...this.state.loading,
                            saving: false
                        });
                        this.context.showAlert('error', 'Error saving order ' + JSON.stringify(err));
                    }
                );
                this.setState({mode: 'V'});
            }
        })
    }

    saveOrderValid = () => {
        const o = this.state.order;
        
        if(o.id_order_status == 5 && o.id_cancellation_reason == null){
            this.context.showAlert('error', 'You must provide a cancellation reason to save a cancelled order.');
            return false;
        }

        return true;
    }

    cancelModify = () => {
        this.setState({
            mode: 'V',
            order: JSON.parse(JSON.stringify(this.state.original_order))
        });
    }

    handleToggleOrganisationDetails = () => {
        this.setState({organisationOpen: this.state.organisationOpen?false:true})
    };

    render(){

        const order = this.state.order;
        const selectedGroupScheme = this.state.selectedGroupScheme;
        const readOnly = this.state.mode=='V'?true:false;
        const variant = readOnly?'outlined':'filled';

        const filteredGroupSchemes = () => {
            if(this.state.order.group_scheme == null || this.state.order.group_scheme.length === 0)
                return this.state.groupSchemes;

            return this.state.groupSchemes.filter(gs => gs.groupSchemeName.toUpperCase().indexOf(this.state.order.group_scheme.toUpperCase()) === 0);
        }

        const selectGroupScheme = val => {
            const order = {
                ...this.state.order,
                group_scheme: val
            };
            this.setState({order: order, groupSchemesOpen: false});
        }


        const menuOpen = Boolean(this.state.anchorElMenu);

        const handleOpenMenu = e => {
            this.setState({anchorElMenu: e.currentTarget});
        }

        const handleCloseMenu = () => {
            this.setState({anchorElMenu: null});
        }

        const handleShowRenewal = () => {
            this.setState({showRenewalDialog: true});
        }

        return(
            <div style={styles.container}>

                <Backdrop open={this.state.loading.order} style={{zIndex: 999}}>
                    <CircularProgress color="inherit" />
                </Backdrop>


                {/* Providing we have an id we can show something */}
                {this.state.id != null &&
                    <div style={styles.inner}>

                        {this.state.mode == 'A' && <AddOrder history={this.props.history} onCreate={this.init} />}

                        {this.state.mode != 'A' &&
                        <div style={{flex: 1}}>

                            <div style={styles.taskbar}>

                                <Typography variant="h4">Order VE{this.state.id}</Typography>


                                {order != null &&
                                    <React.Fragment>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={handleOpenMenu}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="order-menu"
                                            anchorEl={this.state.anchorElMenu}
                                            keepMounted
                                            open={menuOpen}
                                            onClose={handleCloseMenu}
                                            >
                                            <MenuItem onClick={() => window.location.reload(false)} style={styles.menuItem}><RefreshIcon /> Refresh</MenuItem>
                                            <MenuItem onClick={handleShowRenewal} style={styles.menuItem}><ScheduleIcon /> Create Renewal Order</MenuItem>
                                            {this.state.mode == 'V' && <MenuItem onClick={() => this.setState({showDeactivation: true})} style={styles.menuItem}><WarningIcon /> Issue Deactivation Notice</MenuItem>}
                                            {(this.state.mode == 'V' && (this.state.order != 'undefined' && this.state.order.deactivation_scheduled != null)) && 
                                                <MenuItem onClick={() => this.setState({showClearDeactivation: true})} style={styles.menuItem}><EventBusyIcon /> Clear Deactivation Notice</MenuItem>
                                            }
                                        </Menu>
                                    </React.Fragment>
                                }


                                

                            </div>

                            {order != null &&
                            <div style={styles.cols}>

                                <div style={styles.order}>

                                    <Paper style={{padding: 30, display: 'flex', flexDirection: 'column'}}>


                                        <Grid container spacing={3}>

                                            <Grid container item spacing={3}>

                                                <Grid container item xs={12} direction="row" justify="space-between">
                                                    <div>
                                                        
                                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}} onClick={this.handleToggleOrganisationDetails}>
                                                            <Typography variant="h5">{order.organisation_name}</Typography>
                                                            {this.state.organisationOpen?<ExpandLessIcon />:<ExpandMoreIcon />}
                                                        </div>

                                                        <Collapse in={this.state.organisationOpen}>
                                                            <Typography variant="subtitle1">{order.main_contact_name}</Typography>
                                                            <Typography variant="subtitle2">{order.main_contact_position}</Typography>
                                                            <Typography variant="subtitle2">{order.main_contact_email}</Typography>
                                                            <Typography variant="subtitle2">{order.main_contact_phone}</Typography>
                                                            <Tooltip title="Switch the organisation">
                                                                <IconButton color="secondary" onClick={() => this.setState({showSwitch: true})}><RepeatIcon /></IconButton>
                                                            </Tooltip>
                                                        </Collapse>

                                                    </div>

                                                    <div>
                                                        {this.state.mode == 'V' && <Button variant="contained" color="secondary" onClick={this.modifyMode}>Modify</Button>}
                                                        {this.state.mode == 'E' && <Button variant="contained" onClick={this.cancelModify}>Cancel</Button>}
                                                        {this.state.mode == 'E' && <Button variant="contained" color="secondary" onClick={this.saveOrder}>Save</Button>}
                                                    </div>
                                                </Grid>

                                            </Grid>



                                            <Grid container item spacing={3} alignItems="flex-start">

                                                {/* PRODUCT DETAILS */}
                                                
                                                <Grid container item xs={12} sm={6}>

                                                    <Grid item xs={12} style={styles.gridItem}>
                                                        <Typography variant="h6">Order details</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.gridItem}>
                                                        {/* PROJECT DESCRIPTION */}
                                                        <TextField
                                                            style={styles.input}
                                                            name="order_name"
                                                            label="Order Name"
                                                            helperText="e.g. 'vendelectric Newark Back Car Park'"
                                                            variant={variant}
                                                            value={order.order_name}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.gridItem}>
                                                        <TextField
                                                            style={styles.input}
                                                            name="order_description"
                                                            label="Order Description"
                                                            helperText="e.g. '8x Rolec Quantum 7.2kWh UKEV**** - UKEV**** and 1x Delta 25kWh UKEV*****s'"
                                                            variant={variant}
                                                            value={order.order_description}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.gridItem}>
                                                        <FormControl
                                                            variant={variant}
                                                            style={styles.inputSmall}
                                                            >
                                                            <InputLabel id="connectivity-type-label">Connectivity</InputLabel>
                                                            <Select
                                                            labelId="connectivity-type-label"
                                                            name="connectivity_type"
                                                            value={order.connectivity_type}
                                                            onChange={this.handleChange}
                                                            label="Connectivity"
                                                            inputProps={{ readOnly: readOnly }}
                                                            >
                                                                {connectivity_types.map(type => (
                                                                    <MenuItem key={type.id_connectivity} value={type.id_connectivity}>{type.connectivity_description}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.gridItem}>
                                                        {/* MANUFACTURER REF */}
                                                        <TextField
                                                            style={styles.input}
                                                            name="manufacturer_ref"
                                                            label="MI Number"
                                                            helperText="e.g. MI12345"
                                                            variant={variant}
                                                            value={order.manufacturer_ref}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.gridItem}>
                                                        <TextField
                                                            style={styles.input}
                                                            name="invoice_to"
                                                            label="Invoice To"
                                                            helperText="Subscriber, Installer, Manufacturer etc"
                                                            variant={variant}
                                                            value={order.invoice_to}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}  style={styles.gridItem}>
                                                        <TextField
                                                            ref={this.groupScheme}
                                                            style={styles.input}
                                                            name="group_scheme"
                                                            label="Group Scheme"
                                                            helperText="Enter the group scheme as it should be setup"
                                                            variant={variant}
                                                            value={order.group_scheme}
                                                            onChange={(e) => this.handleChange(e, () => {
                                                                if(this.state.groupSchemesOpen == false)
                                                                    this.setState({
                                                                        groupSchemesOpen: true
                                                                    })
                                                            })}
                                                            InputProps={{
                                                                readOnly: readOnly,
                                                            }}
                                                            autoComplete='off'
                                                        />

                                                        <Popover
                                                            id='group_schemes_popover'
                                                            open={this.state.groupSchemesOpen}
                                                            anchorEl={this.groupScheme.current}
                                                            onClose={() => {
                                                                this.setState({
                                                                    groupSchemesOpen: false
                                                                })
                                                            }}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            disableAutoFocus
                                                            disableEnforceFocus
                                                        >
                                                            <div style={{padding: 20, maxHeight: 250, overflowY: 'auto'}}>
                                                                {filteredGroupSchemes().length == 0 && <Typography disabled>No scheme found</Typography>}
                                                                {filteredGroupSchemes().map(option => 
                                                                    <Typography style={{cursor: 'pointer'}} key={option.groupSchemeID} onClick={() => selectGroupScheme(option.groupSchemeName)}>{option.groupSchemeName} - #{option.groupSchemeID}</Typography>
                                                                )}
                                                            </div>
                                                        </Popover>



                                                        {/* // <Autocomplete
                                                        //     options={this.state.groupSchemes}
                                                        //     getOptionLabel={option => option.groupSchemeName}
                                                        //     style={{ width: 400 }}
                                                        //     value={selectedGroupScheme}
                                                        //     onChange={this.handleGroupSchemeChange}
                                                        //     id="group_scheme"
                                                        //     name="group_scheme"
                                                        //     disabled={readOnly}
                                                        //     freeSolo
                                                        //     renderOption={option => (
                                                        //     <React.Fragment>
                                                        //         <span>{option.groupSchemeName}</span>
                                                        //     </React.Fragment>
                                                        //     )}
                                                        //     renderInput={params => <TextField 
                                                        //         {...params} 
                                                        //         label="Group Scheme" 
                                                        //         variant={variant}
                                                        //     />}
                                                        // /> */}
                                                    </Grid>

                                                </Grid>




                                                {/* PROJECT STATUS */}

                                                <Grid container item xs={12} sm={6} spacing={3}>

                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle2">Order status</Typography>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant={variant}
                                                            style={styles.inputSmall}
                                                            >
                                                            <InputLabel id="order_status-label">Status</InputLabel>
                                                            <Select
                                                            labelId="order_status-label"
                                                            name="id_order_status"
                                                            value={order.id_order_status}
                                                            onChange={this.handleChange}
                                                            label="Status"
                                                            inputProps={{ readOnly: readOnly }}
                                                            >
                                                                {this.state.order_statuses.map(status => (
                                                                    <MenuItem key={status.id_order_status} value={status.id_order_status}>{status.order_status_name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    
                                                    {order.id_order_status === 5 && <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant={variant}
                                                            style={styles.inputSmall}
                                                            >
                                                            <InputLabel id="cancellation_reason-label" error={order.id_order_status === 5 && !order.id_cancellation_reason}>Reason</InputLabel>
                                                            <Select
                                                            labelId="cancellation_reason-label"
                                                            name="id_cancellation_reason"
                                                            value={order.id_cancellation_reason}
                                                            onChange={this.handleChange}
                                                            label="Reason"
                                                            inputProps={{ readOnly: readOnly }}
                                                            error={order.id_order_status === 5 && !order.id_cancellation_reason}
                                                            >
                                                            {this.state.cancellation_reasons.map(r => (
                                                                    <MenuItem key={r.id_cancellation_reason} value={r.id_cancellation_reason}>{r.cancellation_reason}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>}

                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl
                                                            variant={variant}
                                                            style={styles.inputSmall}
                                                            >
                                                            <InputLabel id="id_scheme-label">Scheme</InputLabel>
                                                            <Select
                                                            labelId="id_scheme-label"
                                                            name="id_scheme"
                                                            value={order.id_scheme}
                                                            onChange={(e) => this.handleChange(e, this.fetchGroupSchemes)}
                                                            label="Scheme"
                                                            inputProps={{ readOnly: readOnly }}
                                                            >
                                                            <MenuItem value="1">VEOS</MenuItem>
                                                            <MenuItem value="2">EVCO</MenuItem>
                                                            <MenuItem value="3">GM</MenuItem>
                                                            <MenuItem value="4">Other</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            name="created"
                                                            type="date"
                                                            value={Helpers.SQLtoUTCISODate(order.created)}
                                                            onChange={this.handleChange}
                                                            InputLabelProps={{shrink: true,}}
                                                            style={styles.inputSmall}
                                                            label="Created"
                                                            variant={variant}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                            className="mb"
                                                        />
                                                    </Grid>

                                                    {order.id_business_type === 1 &&
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                name="manufacturer_promise_date"
                                                                type="date"
                                                                value={Helpers.SQLtoUTCISODate(order.manufacturer_promise_date)}
                                                                onChange={this.handleChange}
                                                                InputLabelProps={{shrink: true,}}
                                                                style={styles.inputSmall}
                                                                label="Manufacturer Promised"
                                                                variant={variant}
                                                                InputProps={{
                                                                readOnly: readOnly,
                                                                }}
                                                                className="mb"
                                                            />
                                                        </Grid>
                                                    }

                                                    
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle2">Agreement</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            name="agreement_issued"
                                                            type="date"
                                                            value={Helpers.SQLtoUTCISODate(order.agreement_issued)}
                                                            onChange={this.handleChange}
                                                            InputLabelProps={{shrink: true,}}
                                                            style={styles.inputSmall}
                                                            label="Issued"
                                                            variant={variant}
                                                            InputProps={{
                                                            readOnly: readOnly
                                                            }}
                                                            className="mb"
                                                            error={!order.agreement_issued}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            name="agreement_received"
                                                            type="date"
                                                            value={Helpers.SQLtoUTCISODate(order.agreement_received)}
                                                            onChange={this.handleChange}
                                                            InputLabelProps={{shrink: true,}}
                                                            style={styles.inputSmall}
                                                            label="Received"
                                                            variant={variant}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                            className="mb"
                                                            error={!order.agreement_received}
                                                        />
                                                    </Grid>

                                                    {order.id_business_type === 1 &&
                                                    <React.Fragment>

                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2">Commissioning</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                name="commission_scheduled"
                                                                type="date"
                                                                value={Helpers.SQLtoUTCISODate(order.commission_scheduled)}
                                                                onChange={this.handleChange}
                                                                InputLabelProps={{shrink: true,}}
                                                                style={styles.inputSmall}
                                                                label="Scheduled"
                                                                variant={variant}
                                                                InputProps={{
                                                                readOnly: readOnly,
                                                                }}
                                                                className="mb"
                                                                error={!order.commission_scheduled}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                name="commissioned"
                                                                type="date"
                                                                value={Helpers.SQLtoUTCISODate(order.commissioned)}
                                                                onChange={this.handleChange}
                                                                InputLabelProps={{shrink: true,}}
                                                                style={styles.inputSmall}
                                                                label="Commissioned"
                                                                variant={variant}
                                                                InputProps={{
                                                                readOnly: readOnly,
                                                                }}
                                                                className="mb"
                                                                error={!order.commissioned}
                                                            />
                                                        </Grid>


                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2">Onboarding</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                name="backoffice_invited"
                                                                type="date"
                                                                value={Helpers.SQLtoUTCISODate(order.backoffice_invited)}
                                                                onChange={this.handleChange}
                                                                InputLabelProps={{shrink: true,}}
                                                                style={styles.inputSmall}
                                                                label="Invited"
                                                                variant={variant}
                                                                InputProps={{
                                                                readOnly: readOnly,
                                                                }}
                                                                className="mb"
                                                                error={!order.backoffice_invited}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                name="backoffice_onboarded"
                                                                type="date"
                                                                value={Helpers.SQLtoUTCISODate(order.backoffice_onboarded)}
                                                                onChange={this.handleChange}
                                                                InputLabelProps={{shrink: true,}}
                                                                style={styles.inputSmall}
                                                                label="Walkthrough"
                                                                variant={variant}
                                                                InputProps={{
                                                                readOnly: readOnly,
                                                                }}
                                                                className="mb"
                                                                error={!order.backoffice_onboarded}
                                                            />
                                                        </Grid>

                                                    </React.Fragment>
                                                    }

                                                    

                                                    

                                                    <Grid item xs={12}>

                                                        <FormControlLabel
                                                            control={<Switch checked={Boolean(order.teckpro_order)} onChange={this.handleSwitchChange} name="teckpro_order" disabled={readOnly} />}
                                                            label="Teckpro Order"
                                                        />

                                                        {Boolean(order.teckpro_order) && <TextField
                                                            style={styles.input}
                                                            name="additional_comments"
                                                            label="Additional Comments"
                                                            helperText="e.g. Please refer to VE190XXX"
                                                            variant={variant}
                                                            value={order.additional_comments}
                                                            onChange={this.handleChange}
                                                            InputProps={{
                                                            readOnly: readOnly,
                                                            }}
                                                        />}
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        

                                            <Grid item container spacing={3}>

                                                <Grid item xs={12}>

                                                    <OrderContacts id_order={this.state.order.id_order} reloadCount={this.reloadCount} />
                                                </Grid>

                                            </Grid>

                                            

                                            <Grid item container spacing={3}>

                                                <Grid item xs={12}>

                                                    <Subscriptions id_order={this.state.order.id_order} id_organisation={this.state.order.id_organisation} reloadCount={this.reloadCount} groupSchemeName={this.state.order.group_scheme}/>
                                                </Grid>

                                            </Grid>

                                        </Grid>


                                    </Paper>

                                    

                                    <div style={{marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <div></div>
                                        <Button variant="contained" color="default" onClick={() => this.setState({showControlDocument: true})}>Create project document</Button>
                                    </div>

                                </div>

                                <div style={styles.logs}>

                                    <Logs id_order={this.state.order.id_order} reloadCount={this.reloadCount} />

                                    <OrderAttachments order={this.state.order} reloadCount={this.state.reloadCount} />

                                </div>


                                <IssueDeactivationNoticeDialog show={this.state.showDeactivation} success={() => window.location.reload(false)} close={() => this.setState({showDeactivation: false})} id_order={this.state.id} email={order.main_contact_email} />
                                <ClearDeactivationNoticeDialog show={this.state.showClearDeactivation} success={() => window.location.reload(false)} close={() => this.setState({showClearDeactivation: false})} id_order={this.state.id} />
                                {this.state.showRenewalDialog && <OrderRenewalDialog handleClose={() => this.setState({showRenewalDialog: false})} order={order} />}
                                {this.state.showControlDocument && 
                                    <OrderControlDocument 
                                    show={true} 
                                    onClose={() => this.setState({showControlDocument: false, reloadCount: (this.state.reloadCount+1)})} 
                                    order={this.state.order} />
                                }

                            </div>}

                        </div>

                        }


                    </div>
                }

                {this.state.showSwitch && 
                <Modal open={this.state.showSwitch} onClose={() => this.setState({showSwitch: false})}>
                    <Paper style={{position: 'absolute', width: '90%', maxWidth: 400, left: '50%', top: '50%', transform: `translate(-50%, -50%)`}}>
                        <SwitchOrderOrganisation id_order={this.state.order.id_order} id_organisation={this.state.order.id_organisation} onSwitch={this.init}/>
                    </Paper>
                </Modal>}


            </div>
        );
    }

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    cols: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    order: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minWidth: '60%'
    },
    logs: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '40%',
        width: '100%',
        maxWidth: 400
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 35,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    },
    input: {
        maxWidth: 400,
        width: '100%'
    },
    inputSmall: {
        maxWidth: 220,
        minWidth: 120,
        width: '100%'
    },
    dateWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    space: {
        width: 20
    },
    gridItem: {
        padding: '10px 0'
    },
    menuItem: {
        display: 'flex', 
        gap: '10px'
    }
}

export default Order