import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Button, CircularProgress } from '@material-ui/core';
import globalContext from '../../context/global-context';
import SubscriptionSocketSimCreate from './subscription_socket_sim_create';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const SubscriptionSocketSim = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [simData, setSimData] = useState(null);
    const [showCreateDevice, setShowCreateDevice] = useState(false);
    const [showRenameDevice, setShowRenameDevice] = useState(false);

    var simLoadTimeout;
    var abortFetch;


    //
    //  Fetch the sim information from the Emnify portal
    //
    useEffect(() => {

        
        fetchSimData();

        return () => {
            if(typeof abortFetch !== 'undefined')
                abortFetch.abort();
        }

    }, [props.sim_no])

    const fetchSimData = async => {

        setLoading(true);
        setError(null);
        setShowRenameDevice(false);

        if(typeof simLoadTimeout !== 'undefined')
            clearTimeout(simLoadTimeout);

        if(typeof abortFetch !== 'undefined')
            abortFetch.abort();

        abortFetch = new AbortController();
        simLoadTimeout = setTimeout(() => { 
            context.apiRequest(`/devices/sim/${props.sim_no}`, 'GET', null, abortFetch.signal).then(
                res => {
                    setLoading(false);
                    setSimData(res.result);
                }, 
                err => {
                    setLoading(false);
                    setSimData(null);
                    setError(`Error getting emnify sim`);
                }
            )
        }, 1000)   

    }


    //
    //  Validate the sim data against the socket information
    //
    useEffect(() => {

        if(simData !== null){

            console.log(simData);
            let error = null;
            if(typeof simData.endpoint !== 'undefined' && simData.endpoint !== null && simData.endpoint.name !== props.endpoint){
                error = `The endpoint for this SIM number (${simData.endpoint.name}) doesn't match the Unit ID`;
                setShowRenameDevice(true);
            }

            if(typeof simData.endpoint === 'undefined'){
                error = `No endpoint created, click below to create now.`;

            }

            
            setError(error);

        } else {
            setError(`No SIM found`);
        }

    }, [simData])


    const hasEndpoint = () => {
        return (simData !== null && typeof simData.endpoint !== 'undefined' && simData.endpoint !== null);
    }


    //
    // Acivate a device
    //
    const activateDevice = async => {
        setLoading(true);
        setError(null);

        context.apiRequest(`/devices/activate/${simData.endpoint.id}/${simData.id}`, 'POST', props.socket).then(
            res => {
                fetchSimData();
            }, 
            err => {
                setLoading(false);
                setError(typeof err.msg !== 'undefined' ? err.msg : 'An error occured activating the SIM');
            }
        ) 
    }


    //
    // Deacivate a device
    //
    const deactivateDevice = async => {
        setLoading(true);
        setError(null);

        context.apiRequest(`/devices/deactivate/${simData.endpoint.id}/${simData.id}`, 'POST', props.socket).then(
            res => {
                fetchSimData();
            }, 
            err => {
                setLoading(false);
                setError(typeof err.msg !== 'undefined' ? err.msg : 'An error occured deactivating the SIM');
            }
        ) 

    }

    
    //
    // Update device name
    //
    const nameDevice = async => {
        setLoading(true);
        setError(null);

        context.apiRequest(`/devices/name/${simData.endpoint.id}/${props.endpoint}`, 'PUT', props.socket).then(
            res => {
                fetchSimData();
            }, 
            err => {
                setLoading(false);
                setError(typeof err.msg !== 'undefined' ? err.msg : 'An error occured re-naming the SIM');
            }
        ) 
    }

    const handleCreateSuccess = () => {
        console.log('I am handle success');
        setShowCreateDevice(false); 
        fetchSimData()
    }

    return (
        <React.Fragment>

            {loading && <CircularProgress size={24} />}
            {!loading && 
                <React.Fragment>
                    {error !== null && <Typography variant="caption" color="error" component="div" style={{marginBottom: 10}}>{error}</Typography>}
                    {(!hasEndpoint() && simData !== null) && <Button variant="contained" onClick={() => setShowCreateDevice(true)} color="secondary">Allocate SIM</Button>}
                    
                    
                    {hasEndpoint() && 
                        <React.Fragment>
                            <div className="flex-row" style={{gap: '10px'}}>
                                {(simData.status.id === 1) && 
                                    <React.Fragment>
                                        <Alert severity="success">SIM Active</Alert>
                                        <Button variant="contained" onClick={deactivateDevice} color="default">{'Deactivate'}</Button>
                                    </React.Fragment>
                                }
                                {(simData.status.id === 2 || simData.status.id === 0) && 
                                    <React.Fragment>
                                        <Alert severity="warning">SIM Disabled</Alert>
                                        <Button variant="contained" onClick={activateDevice} color="secondary">{'Activate'}</Button>
                                    </React.Fragment>
                                }

                                {showRenameDevice && <Button variant="contained" onClick={nameDevice} color="secondary">Rename SIM</Button>}
                            </div>
                        </React.Fragment>
                    }

                    {showCreateDevice && <SubscriptionSocketSimCreate 
                        show={showCreateDevice} 
                        endpoint={props.endpoint} 
                        sim_no={simData.iccid} 
                        sim_id={simData.id} 
                        handleClose={() => setShowCreateDevice(false)} 
                        handleSuccess={handleCreateSuccess}
                    />}
                </React.Fragment>
            }

        </React.Fragment>
    )
}
export default SubscriptionSocketSim;