import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { Typography, ListItem, Avatar, Grid } from '@material-ui/core';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import Helpers from '../global/helpers';
import clsx from 'clsx';

import { amber, yellow, red, orange } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    ticketItem: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    ticketActive: {
        backgroundColor: theme.palette.action.selected
    },
    ticketFresh: {
        animation: `$flashFresh ease-in-out .5s 1`,
    },
    "@keyframes flashFresh": {
        "0%, 49%": {
            backgroundColor: amber[700]
        },
        "50%, 100%": {
            backgroundColor: 'auto'
        }
    },  
    ticketAvatar: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    ticketNumber: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: '8%'
    },
    ticketDescription: {
        flex: 1,
        minWidth: 160, 
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    ticketDescriptionOpen: {
        minWidth: 0, 
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    ticketType: {
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap',
    },
    ticketTypeSecondary: {
        color: theme.palette.text.secondary,
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap',
    },
    ticketMeta: {
        display: 'flex',
        flexDirection: 'row', 
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        justifyContent: 'flex-end',
        minWidth: '35%',
        flexWrap: 'wrap'
    },
    ticketMetaItem: {
        marginRight: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row'
    },
    ticketMetaItemTitle: {
        marginRight: theme.spacing(1)
    },
    ticketMetaItemValue: {
        fontWeight: 600
    },
    prioritynull: {
        width: 30,
        height: 30
    },
    priority1: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        width: 30,
        height: 30
    },
    priority2: {
        color: theme.palette.getContrastText(orange[500]),
        backgroundColor: orange[500],
        width: 30,
        height: 30
    },
    priority3: {
        color: theme.palette.getContrastText(yellow[600]),
        backgroundColor: yellow[600],
        width: 30,
        height: 30
    },
}));


const TicketSummary = props => {

    const isActive = props.isActive || false;
    const isFresh = props.isFresh || false;
    const theme = useTheme();
    const styles = useStyles(theme);
    const t = props.ticket;
    const openMode = props.openMode;

    const openTicket = () => {
        props.handleOpenTicket(t);
    }
    
    const priorityChar = () => {
        const x = Helpers.isNull(t.ticket_priority_name, '/')
        return x.charAt(0).toUpperCase();
    }

    const priorityColour = () => {

        if(t.id_ticket_status > 3)
            return styles.prioritynull;

        // Check for visit bookings first
        const nextVisit = t.visits.find(v => v.ticket_visit_reported === null);
        if(typeof nextVisit != 'undefined'){
            const bookedDay = Helpers.SQLtoUTCDateObj(nextVisit.date_booked);
            const endOfBookedDay = new Date().setTime(bookedDay.getTime() + (24*60*60*1000));
            if(new Date() > endOfBookedDay)
                return styles.priority1;
            if(new Date() > bookedDay)
                return styles.priority2;
            
            return styles.priority3;
        }

        if(t.priority_score > t.kpi_mins)
            return styles.priority1;
        if(t.priority_score > Math.floor(t.kpi_mins*.66))
            return styles.priority2;
        
        return styles.priority3;
    }

    const itemClass = isFresh ? clsx(styles.ticketItem, styles.ticketFresh) : isActive ? clsx(styles.ticketItem, styles.ticketActive) : styles.ticketItem;

    return (
        <ListItem key={t.id_ticket} alignItems="flex-start" className={itemClass} button onClick={openTicket}>
            <Grid container spacing={2} alignItems="center">
                <Grid item style={{width: 175}} zeroMinWidth>
                    <Grid item container direction="row" alignItems="center">
                        <Avatar className={priorityColour()} style={{fontSize: 12}}>P{t.id_ticket_priority}</Avatar>
                        <div className={styles.ticketNumber}>
                            <Typography variant="body1" className={styles.ticketType} noWrap>{`#${t.ticket_ref}`}</Typography>
                            <Typography variant="body2" className={styles.ticketTypeSecondary} noWrap>{t.ticket_status_name}</Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid item style={{flex: 1, minWidth: 140}}>
                    <Typography variant="body1" className={styles.ticketType} noWrap>{t.triggers.length === 0 && <FiberNewIcon />}{t.ticket_subject}</Typography>
                    <Typography variant="body2" className={styles.ticketTypeSecondary} noWrap>{t.ticket_description}</Typography>
                </Grid>
                {!props.openMode && 
                <Grid item style={{width: props.openMode ? 'auto' : 350, justifySelf: 'flex-end'}}>
                    <Grid item container direction="row" alignItems="center">
                        <div className={styles.ticketMetaItem}>
                            <Typography variant="caption" className={styles.ticketMetaItemValue}>{t.ticket_type_name}</Typography>
                        </div>
                        <div className={styles.ticketMetaItem}>
                            <Typography variant="caption" className={styles.ticketMetaItemValue}>{t.network_name}</Typography>
                        </div>
                        <div className={styles.ticketMetaItem}>
                            <Typography variant="caption" className={styles.ticketMetaItemTitle}>Last Updated: </Typography>
                            <Typography variant="caption" className={styles.ticketMetaItemValue}>{Helpers.SQLTimeSince(t.last_updated)}</Typography>
                        </div>
                    </Grid>
                </Grid>
                }
            </Grid>
        </ListItem>
    )

}

export default TicketSummary