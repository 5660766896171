import { Button, makeStyles, TextField, useTheme, Paper, FormHelperText, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import globalContext from "../../../context/global-context";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: 20,
        maxWidth: 400
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '15px',
    }
}))

const CommissioningAddPoints = props => {

    const context = useContext(globalContext);
    const theme = useTheme();
    const classes = useStyles(theme);

    const [addPoint, setAddPoint] = useState('UKEV');
    const [invalid, setInvalid] = useState(false);

    const handleInputChange = e => {
        setAddPoint(e.target.value.toUpperCase());
    }

    const handleAdd = () => {
        if(addPoint.length > 4 && invalid === false && typeof props.handleAdd === 'function'){
            props.handleAdd(addPoint);
            setAddPoint('UKEV');
        }
    }

    const handleKeyDown = e => {
        if(e.keyCode === 13)
            handleAdd();
    }

    useEffect(() => {
        if(addPoint === 'UKEV')
            setInvalid(false);
        else
            if(addPoint.length > 4 && addPoint.slice(0, 4) === 'UKEV')
                setInvalid(false);
            else
                setInvalid(true);
    }, [addPoint])


    return (
        <Paper className={classes.paper}>
            <div className={classes.row}>
                <div style={{flex: 1}}>
                    <TextField name="addPoint" value={addPoint} onChange={handleInputChange} label="Enter UKEV number" variant="outlined" error={invalid} fullWidth onKeyDown={handleKeyDown} disabled={props.loading}/>
                    {invalid && <FormHelperText>A VendElectric charge point must begin with UKEV</FormHelperText>}
                </div>
                <div>
                    <Button variant="contained" color="primary" onClick={handleAdd}>{props.loading ? <CircularProgress color="inherit" size={24} /> :' Add'}</Button>
                </div>
            </div>
        </Paper>
    )
}

export default CommissioningAddPoints;