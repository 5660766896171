import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, Paper, Grid, CircularProgress, TextField, Button, Modal, Fab, createGenerateClassName } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddOrganisation from '../organisations/add_organisation';
import SageLatestOrders from './sage_latest_orders';


const AddOrder = props => {

    // Create a local state
    const [order, setOrder] = useState({
        id_organisation: null,
        order_name: ''
    });

    const [selectedOrganisation, setOrganisation] = useState(null);
    const [select_id_organisation, setSelectIdOrganisation] = useState(typeof props.id_organisation!='undefined'?props.id_organisation:0);
    const [organisations, setOrganisations] = useState([]);
    const [loading, setLoading] = useState({organisations: true, creating: false});
    const [showAdd, setShowAdd] = useState(false);


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    useEffect(() => {

        console.log('calling get')
        getOrganisations();

    }, [select_id_organisation])

    const getOrganisations = () => {
        setLoading({...loading, organisations: true})

        context.fetchOrganisations().then(
            res => {
                setOrganisations(res.result);
                setLoading({...loading, organisations: false})

                // pre select the organisation if we need to
                if(select_id_organisation > 0){
                    const organisation = res.result.find(o => o.id_organisation == select_id_organisation);
                    selectOrganisation(null, organisation);
                }
            }, 
            err => {
                setLoading({...loading, organisations: false})
                context.showAlert('error', 'error fetching organisations: ' + JSON.stringify(err.msg));
            }
        )
    }


    // Styles

    const styles  = {
        organisations: {
            display: 'flex',
            flexDirection: 'column'
        },
        selectOrgansisation: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        taskbar: {
            paddingTop: 20,
            paddingBottom: 20,
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    }



    // Form functions
    const selectOrganisation = (e, newValue) => {
        setOrganisation(newValue);

        setOrder({
            ...order,
            id_organisation: newValue != null?newValue.id_organisation:null
        });
    }

    const handleNewOrganisation = (id) => {
        console.log(id);
        setShowAdd(false);
        setSelectIdOrganisation(id);
    }

    const handleNameChange = (e) => {
        setOrder({
            ...order,
            order_name: e.target.value
        });
    }

    const invalid = () => {
        if(isInvalid('id_organisation') || isInvalid('order_name'))
            return true;

        return false;
    }

    const isInvalid = prop => {
        switch(prop){
            case 'id_organisation':
                if(order.id_organisation == null || order.id_organisation < 1)
                    return true;
                break;
            case 'order_name':
                if(order.order_name == null || order.order_name.length == 0)
                    return true;
                break;
            default:
                return true;
        }

        return false;
    }

    const createOrder = () => {
        setLoading({
            ...loading,
            creating: true
        });

        if(!invalid()){
            context.saveOrder(order, 'A').then(
                res => {
                    setLoading({
                        ...loading,
                        creating: false
                    });

                    context.showAlert('success', `Order VE${res.result} created`);

                    if(typeof props.history != 'undefined')
                        props.history.push('/order/'+res.result);

                    props.onCreate();
                },
                err => {
                    setLoading({
                        ...loading,
                        creating: false
                    });
                    context.showAlert('error', 'Error creating order ' + JSON.stringify(err));
                }
            );
        } else {
            setLoading({
                ...loading,
                creating: false
            });
            context.showAlert('error', 'Order invalid');
        }
    }

    const getName = org => {
        if(org.organisation_name!=null)
            return org.organisation_name
        
        if(org.main_contact_name!=null)
            return org.main_contact_name
        
        if(org.main_contact_email!=null)
            return org.main_contact_email
        
        if(org.main_contact_phone!=null)
            return org.main_contact_phone
        
        return '';
    }
        
    return (

        <div style={styles.container}>
            <div style={styles.taskbar}>

                <Typography variant="h4">New Order</Typography>
            
            </div>

            <Paper style={{padding: 30, display: 'flex', flexDirection: 'column'}}>


                <Grid container spacing={3}>

                    <Grid container item spacing={3}>

                        <Grid item xs={12}>

                        <div style={styles.organisation}>
                            <div style={styles.selectOrgansisation} className="mb">
                                <Autocomplete
                                    options={organisations}
                                    getOptionLabel={option => option.organisation_name}
                                    style={{ width: 400 }}
                                    value={selectedOrganisation}
                                    onChange={selectOrganisation}
                                    id="id_organisation"
                                    className="mr"
                                    autoHighlight
                                    renderOption={option => (
                                    <React.Fragment>
                                        <span style={{marginRight: 10}}>({option.id_organisation})</span>
                                        {getName(option)}
                                    </React.Fragment>
                                    )}
                                    renderInput={params => <TextField 
                                        {...params} 
                                        error={isInvalid('id_organisation')}
                                        label="Select Organisation" 
                                        variant="filled"
                                    />}
                                />

                                <Fab onClick={()=>{setShowAdd(true)}} color="secondary" size="medium" aria-label="add">
                                    <AddIcon />
                                </Fab>

                                {loading.organisations && <CircularProgress size={20} className="ml" />}
                            </div>
                        </div>

                        </Grid>

                        
                        <Grid item xs={12}>

                        <div className="mb">
                            <TextField
                                error={isInvalid('order_name')}
                                style={{ width: 400 }}
                                id="order_name"
                                label="Order Name"
                                helperText="e.g. 'vendelectric Newark Back Car Park'"
                                variant="filled"
                                value={order.order_name}
                                onChange={handleNameChange}
                            />
                        </div>

                        </Grid>

                        <Grid item xs={12}>

                        <Button disabled={invalid()} variant="contained" color="secondary" onClick={createOrder}>Create{loading.creating && <CircularProgress size={20} />}</Button>
                
                        </Grid>
                </Grid>

            </Grid>

            </Paper>

            
            <SageLatestOrders />

            
            
            <Modal open={showAdd} onClose={() => {setShowAdd(false)}}>
                <Paper style={{position: 'absolute', width: '90%', maxWidth: 500, left: '50%', top: '50%', transform: `translate(-50%, -50%)`}}>
                    <AddOrganisation onAdd={handleNewOrganisation} />
                </Paper>
            </Modal>


        </div>
    );
}

export default AddOrder