import React, { useContext, useState, useEffect } from 'react';
import { useTheme, makeStyles, Dialog, DialogContent, Box, Typography, IconButton, List, ListItem } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Helpers from '../../../global/helpers';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import AutoTable from '../../../../components/autoTable';
import globalContext from '../../../../context/global-context';
const useStyles = makeStyles(theme => ({
    container: {
        display: 'block',
        width: '100%',
        maxHeight: 300,
        overflowY: 'auto'
    },
    inner: {},
}));

const ChargepointRecentConfigurations = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const { id_chargepoint } = props;

    const headCells = ["timestamp", "configured_by"]
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [configurationLog, setConfigurationLog] = useState(null);


    const loadData = async () => {
        setLoading(true);
        setError(null);
        setData([]);

        try {

            const res = await context.apiRequest(`/service_consumer/ev_connect/chargepoint/recent_configurations?id=${id_chargepoint}`, 'GET');
            if (res.success)
                setData(res.result);
            else
                throw res.msg;

        } catch (err) {

            setError(typeof err === 'string' ? err : typeof err?.msg === 'string' ? err.msg : JSON.stringify(err));
            setData([]);

        } finally {
            setLoading(false);
        }

    }

    console.log(configurationLog)

    useEffect(() => {
        loadData();
    }, [])

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>

                    {error && <Alert severity="error">{error}</Alert>}

                    <AutoTable
                        rows={data}
                        idCol="id"
                        headCells={headCells}
                        loading={loading}
                        handleRowClick={setConfigurationLog}
                        cellReplacementProps={{
                            'timestamp': (val, id) => Helpers.toLocaleDateTime(val)
                        }}
                    />

                </div>
            </div>

            {configurationLog &&
                <Dialog open={true} fullWidth maxWidth="sm" onClose={() => setConfigurationLog(false)}>
                    <DialogContent>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h5" style={{ display: 'flex', alignItems: 'center', gap: 15 }}><InfoIcon /> Configuration Log</Typography>
                            <IconButton onClick={() => setConfigurationLog(false)}><CloseIcon /></IconButton>
                        </Box>
                        <Typography variant="body2">{configurationLog.id_user === null ? 'Owner' : 'Installer'}</Typography>
                        <Typography variant="body2" color="textSecondary">{Helpers.toLocaleDateTime(configurationLog.timestamp)}</Typography>

                        <Box style={{ marginTop: 30, paddingBottom: 20 }}>
                            <Typography variant="h6">Configuration</Typography>
                            <List dense style={{ width: '100%' }}>
                                {configurationLog &&
                                    JSON.parse(configurationLog.configuration).map((config, idx) => (
                                        <ListItem key={idx} style={{ gap: '15px' }}>
                                            <Typography variant="body2">{config.key}: </Typography>
                                            <Typography variant="body2">
                                                {typeof config.value === 'boolean' ? config.value.toString() : config.value}
                                            </Typography>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </React.Fragment>
    )
}
export default ChargepointRecentConfigurations;