import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';

import { 
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    InputLabel,
    Typography,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    Grid,
    IconButton,
    Collapse,
    Box
} from '@material-ui/core';

import SyncIcon from '@material-ui/icons/Sync';

var serialTimer;
var serialAbortController;
const AddSubscriptionSocket = props => {

    const {id_subscription} = props;

    // Create a local state
    const [serialMode, setSerialMode] = useState(false);
    const [serialNo, setSerialNo] = useState('');
    const [serialMatched, setSerialMatched] = useState(false);
    const [serialNotMatched, setSerialNotMatched] = useState(false);
    const [waitingLookup, setWaitingLookup] = useState(false);

    const [socketId, setSocketId] = useState('UKEV');
    const [controllerType, setControllerType] = useState(5);
    const [controllerTypes, setControllerTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    // Use the global context
    const context = useContext(GlobalContext);

    useEffect(() => {
        context.apiRequest('controller_types').then(
            res => {
                setControllerTypes(res.result);
            },
            err => {
                context.showAlert('error', `Error loading controller types`);
            }
        )
    }, [])

    const handleChange = e => {
        setSocketId(e.target.value);
    }

    const handleChangeSerial = e => {
        setSerialNo(e.target.value);
    }

    const handleChangeType = e => {
        setControllerType(e.target.value);
    }

    const handleSearchKeyPress = e => {
        if(e.key == 'Enter')
            handleAddSocket();
    }

    const handleAddSocket = () => {

        if(isValid()){
            setLoading(true);

            const socket = {
                id_subscription: id_subscription,
                socket_id: socketId,
                id_controller_type: controllerType
            }
            console.log(socket);
            context.addSubscriptionSocket(socket).then(
                res => {
                    setLoading(false);
                    context.showAlert('success', 'Socket added');

                    if(typeof props.success == 'function')
                        props.success()
                },
                err => {
                    setLoading(false);
                    context.showAlert('error', 'Error adding socket ' + JSON.stringify(err))
                }
            )
                
        }
    }

    const isValid = () => {
        return (socketId !== null && socketId !== '' && controllerType !== null && controllerType > 0)
    }

    const handleActionCancel = () => {
        setSocketId('UKEV')

        if(typeof props.close == 'function')
            props.close()
    }

    const isSocket = () => {
        if(parseInt(controllerType) !== 1 && socketId !== null && socketId !== '' && socketId.slice(-2, -1) === '-')
            return true;
        else
            return false;
    }

    useEffect(() => {

        if(controllerType !== 5){
            if(serialMode === true)
                setSerialMode(false);   
            
            setSerialNo('');
            setSerialMatched(false);
            setSerialNotMatched(false);
        }

    }, [controllerType])


    

    const handleSerialKeyPress = e => {

        if(typeof serialTimer !== 'undefined')
            clearTimeout(serialTimer);

        serialTimer = setTimeout(() => {
            handleLookupSerialNo(serialNo);
        }, 700)

    }

    const handleLookupSerialNo = async (serial) => {

        try {
            
            if(typeof serialAbortController !== 'undefined')
                serialAbortController.abort();

            if(serial !== null && serial.length > 0){
                setWaitingLookup(true);
                setSerialMatched(false);
                setSerialNotMatched(false);

                serialAbortController = new AbortController();
                const result = await context.apiRequest(`/subscription/sockets/lookup/${serial}`, 'GET', null, serialAbortController.signal);

                if(result.success && result.result !== null){
                    setSocketId(result.result);
                    setSerialMatched(true);
                    setSerialMode(false);
                } else {
                    setSocketId('');
                    setSerialNotMatched(true);
                }

                setWaitingLookup(false);
                
            }


        } catch (error) {

            console.log(error);
            
        }

    }

        
    return (

        <Dialog open={props.show} onClose={props.close} aria-labelledby="add-socket-dialog-title">
            <DialogTitle>Add a unit</DialogTitle>
            <DialogContent>

                {isSocket() && <Typography variant="body2" color="error">Do you need the '-'? OCPP units will have the socket numbers created automatically from the config e.g. UKEV10001 becomes UKEV10001-1, UKEV10001-2 etc.</Typography>}

                <Grid container spacing={3}>

                    <Grid item xs={12}>

                        <Box style={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center'}}>
                            
                            <Box>
                                <Collapse in={!serialMode}>
                                    <TextField
                                        margin="dense"
                                        name="socketId"
                                        value={socketId}
                                        onChange={handleChange}
                                        label="Unit ID (UKEV*****)"
                                        type="text"
                                        InputLabelProps={{shrink: true, error: !isValid()}}
                                        autoFocus
                                        onKeyUp={handleSearchKeyPress}
                                        helperText={serialMatched ? serialNo : ''}
                                    />
                                </Collapse>

                                <Collapse in={serialMode}>
                                    <TextField
                                        margin="dense"
                                        name="serialNo"
                                        value={serialNo}
                                        onChange={handleChangeSerial}
                                        label="Serial No (DJ*******)"
                                        type="text"
                                        InputLabelProps={{shrink: true, error: !isValid()}}
                                        onKeyUp={handleSerialKeyPress}
                                        helperText={serialNotMatched ? 'No match found' : ''}
                                    />
                                </Collapse>
                            </Box>

                            {(controllerType === 5 && !waitingLookup) && <IconButton color="secondary" onClick={() => setSerialMode(!serialMode)}><SyncIcon /></IconButton>}
                            {waitingLookup && <CircularProgress color="secondary" sx={32} />}

                        </Box>

                    </Grid>

                    <Grid item xs={12}>

                        <FormControl fullWidth error={!isValid()}>
                            <InputLabel id='id_controller_type_label' shrink={true}>Controller</InputLabel>
                            <Select
                                name="id_controller_type"
                                value={controllerType}
                                onChange={handleChangeType}
                                labelId='id_controller_type_label'
                                label="Controller"                       
                            >
                                {controllerTypes.map((item, idx) => (
                                    <MenuItem key={idx} value={item.id_controller_type}>{item.controller_type_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>
                
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleActionCancel} color="default">
                    Cancel
                </Button>
                <Button onClick={handleAddSocket} color="secondary" disabled={loading}>
                    {!loading ? 'Add' : <CircularProgress color="inherit" size={16} />}
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default AddSubscriptionSocket