import { Typography, Dialog, DialogContent, DialogActions, Button, TextField, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useState, useEffect } from 'react';
import globalContext from '../../context/global-context';
import {useHistory} from 'react-router-dom';

const OrganisationMergeDialog = props => {

  const {show, handleCancel, organisation} = props;

  const context = useContext(globalContext);
  const history = useHistory();

  const [organisations, setOrganisations] = useState([]);
  const [loadingOrganisations, setLoadingOrganisations] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {

      getOrganisations();

  }, [])

  const getOrganisations = () => {
    setLoadingOrganisations(true);

    context.fetchOrganisations().then(
        res => {
            setOrganisations(res.result);
            setLoadingOrganisations(false);
        }, 
        err => {
            setLoadingOrganisations(false);
            context.showAlert('error', 'error fetching organisations: ' + JSON.stringify(err.msg));
        }
    )
  }

  
  const selectOrganisation = (e, newValue) => {
    setSelectedOrganisation(newValue);
  }

  const getName = org => {
    if(org.organisation_name!=null)
        return org.organisation_name
    
    if(org.main_contact_name!=null)
        return org.main_contact_name
    
    if(org.main_contact_email!=null)
        return org.main_contact_email
    
    if(org.main_contact_phone!=null)
        return org.main_contact_phone
    
    return '';
  }

  const handleConfirm = () => {
    setError(null)
    
    if(selectedOrganisation !== null){
      setLoading(true);

      context.apiRequest('/organisation/merge', 'POST', {
        from: props.organisation.id_organisation,
        to: selectedOrganisation.id_organisation
      }).then(
        res => {
          setLoading(false);
          history.replace(`/organisation/${selectedOrganisation.id_organisation}`);
          context.showAlert('success', `Organisation #${props.organisation.id_organisation} merged into #${selectedOrganisation.id_organisation}`);
        },
        err => {
          setLoading(false);
          setError(typeof err === 'string' ? err : err.JSON.stringify(err))
        }
      )
      
    } else {
      setError('Please select an organisation to merge into')
    }

  }
  
  return (
      <Dialog open={show} onClose={handleCancel} aria-labelledby="delete-socket-dialog-title">
          <DialogContent>
              <Typography variant="h6" style={{marginBottom: 10}}>Merge organisation</Typography>
              <Typography variant="subheading">Merge organisation #{props.organisation.id_organisation} {props.organisation.organisation_name} into:</Typography>

              {error && <Alert severity='error' style={{marginTop: 10}}>{error}</Alert>}
              

              {loadingOrganisations && <CircularProgress size={20} className="ml" />}
              
              {!loadingOrganisations && 
              <div>
                  <div className="mb mt">
                      <Autocomplete
                          options={organisations}
                          getOptionLabel={option => option.organisation_name}
                          style={{ width: 400 }}
                          value={selectedOrganisation}
                          onChange={selectOrganisation}
                          id="id_organisation"
                          className="mr"
                          autoHighlight
                          renderOption={option => (
                          <React.Fragment>
                              <span style={{marginRight: 10}}>({option.id_organisation})</span>
                              {getName(option)}
                          </React.Fragment>
                          )}
                          renderInput={params => <TextField 
                              {...params} 
                              label="Select Organisation" 
                              variant="filled"
                          />}
                      />
                  </div>
              </div>
              }

          </DialogContent>
          <DialogActions>
              <Button onClick={handleCancel} color="default">Cancel</Button>
              {!loading && <Button onClick={handleConfirm} color="secondary" variant="contained">Confirm</Button>}
              {loading && <CircularProgress size={20} className="ml" />}
          </DialogActions>
      </Dialog>
  )

}

export default OrganisationMergeDialog;