import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';

import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    TextField,
    FormHelperText,
    Box
} from '@material-ui/core';


const SuspendSubscription = props => {

    
    const {show, success, close} = props;

    // Create a local state
    const [suspend, setSuspend] = React.useState(false);
    const [email, setEmail] = React.useState(props.email);
    const [emailType, setEmailType] = React.useState(1);

    // Use the global context
    const context = useContext(GlobalContext);

    const handleChange = e => {
        setSuspend(e.target.value);
    }

    const handleChangeEmailType = e => {
        setEmailType(e.target.value);
    }

    const handleSuspendSubscription = () => {
        const params = {
            selected: props.selected,
            suspend: suspend,
            email: email,
            emailType: emailType
        }
        context.subscriptionSuspend(params).then(
            res => {
                context.showAlert('success', 'Subscription sockets updated');

                if(typeof success == 'function')
                    success()
            },
            err => {
                context.showAlert('error', 'Error updating socket suspension ' + JSON.stringify(err))
            }
        )
    }

    const handleActionCancel = () => {
        setSuspend(false);
        if(typeof props.close == 'function')
            props.close()
    }

        
    return (

        <Dialog open={show} onClose={close} aria-labelledby="start-date-dialog-title" maxWidth={'sm'}>
            <DialogContent>
                <Typography variant="h6" style={{marginBottom: 15}}>Suspend / Unsuspend</Typography>
                <FormControl fullWidth style={{marginBottom: 20}}>
                    <InputLabel id="suspend-select-label">Action</InputLabel>
                    <Select
                    labelId="suspend-select-label"
                    id="suspend-select"
                    value={suspend}
                    onChange={handleChange}
                    >
                    <MenuItem value={true}>Suspend</MenuItem>
                    <MenuItem value={false}>Unsuspend</MenuItem>
                    </Select>
                </FormControl>
                {suspend && <FormHelperText error={true}>Warning - pressing 'Confirm' will suspend the back office services to the charge points listed witihn the subscriptions you have selected and send an email to the recipient below.</FormHelperText>}
            
            
                {suspend && 
                    <React.Fragment>
                        <TextField
                            fullWidth
                            margin="dense"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            label="Confirm email address"
                            type="text"
                            InputLabelProps={{shrink: true}} 
                            style={{marginTop: 20}}
                        />
                        
                        <Box style={{marginTop: 15}}>
                            <FormControl fullWidth style={{marginBottom: 20}}>
                                <InputLabel id="suspend-email-select-label">Suspension email</InputLabel>
                                <Select
                                labelId="suspend-email-select-label"
                                id="email-type-select"
                                value={emailType}
                                onChange={handleChangeEmailType}
                                >
                                <MenuItem value={1}>Monta migration</MenuItem>
                                <MenuItem value={2}>Subscription not paid</MenuItem>
                                </Select>
                                <FormHelperText error={true}>Important: This selection will determine the type of email sent to the customer.</FormHelperText>
                            </FormControl>
                        </Box>
                    </React.Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleActionCancel} color="default">
                    Cancel
                </Button>
                <Button onClick={handleSuspendSubscription} color="secondary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default SuspendSubscription