import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';
import { Button, CircularProgress, Typography } from '@material-ui/core';


const DeleteOrderContact = props => {
    const [loading, setLoading] = useState(false);

    // Create a local state
    const [id, setId] = useState(typeof props.id_order_contact!='undefined'?props.id_order_contact:null);



    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    const submitForm = () => {
        setLoading(true);
        context.deleteOrderContact(id).then(
            res => {
                setId(0);
                setLoading(false);
                context.showAlert('success', 'Contact deleted');

                if(typeof props.onDelete == 'function')
                    props.onDelete();
            },
            err => {
                setLoading(false);
                context.showAlert('error', 'Error removing contact ' + JSON.stringify(err));
            }
        )
    }

    
        
    return (

        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: 20}}>
            <Typography variant="h5">Remove contact?</Typography>
            <Typography variant="body2">Are you sure you wish to remove <b>{props.name}</b> from this order? This cannot be un-done.</Typography>

            <div style={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '15px 0', justifyContent: 'center', alignItems: 'flex-end', padding: 10}}>
                <div style={{padding: '0 10px'}}>
                    <Button color="default" variant="contained" onClick={props.onCancel}>Cancel</Button>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{padding: '0 10px'}}>
                    <Button color="secondary" variant="contained" onClick={submitForm}>Confirm {loading && <CircularProgress size={20} className="ml" color="inherit" />}</Button>
                </div>
            </div>
        </div>

    );
}

export default DeleteOrderContact