import React from 'react';
import GlobalContext from '../../context/global-context';


const locationMarkerSelectionPopUp = props => {

    const firstPoint = props.sites[0];

    const navigate = (context) => {
        window.open('https://www.google.com/maps/dir/?api=1&parameters&origin='+context.location.lat+','+context.location.lng+'&destination='+firstPoint.Geo.latitude+','+firstPoint.Geo.longitude)
     }

     

    const _isStrNull = (str, app = '') => {
        return typeof str != 'undefined' && str != null && str.length > 0 && str != 'null' ? false : true;
    }

    const address = (site) => {    
        return (
            <span style={styles.locationaddress}>
                {!_isStrNull(site.AddressLine1) && <span>{site.AddressLine1}<br /></span>}
                {!_isStrNull(site.AddressLine2) && <span>{site.AddressLine2}<br /></span>}
                {!_isStrNull(site.AddressLine3) && <span>{site.AddressLine3}<br /></span>}
                {!_isStrNull(site.Town) && <span>{site.Town}<br /></span>}
                {!_isStrNull(site.County) && <span>{site.County}<br /></span>}
                {!_isStrNull(site.Country) && <span>{site.Country}<br /></span>}
                {!_isStrNull(site.Postcode) && <span>{site.Postcode}<br /></span>}
            </span>
        )
      }


    return (
        <GlobalContext.Consumer>
        {context => (
            <div style={Object.assign({}, styles.container, {bottom: props.visible?0:0})} className="animate">
                <div style={styles.summaryContainer}>
                    <div style={styles.summary}>
                        <div style={styles.right}>
                            {props.sites.map((site) => (
                                <div key={site.id}  style={styles.location} onClick={() => {props.selectLocation(site)}}>
                                    <span style={styles.locationtitle}>{site.SiteName}</span>
                                    <span style={styles.locationaddress}>{address(site)}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )}
        </GlobalContext.Consumer>
    );
}

const styles = {
    container: {
        width: '100%',
        position: 'absolute',
        left: 0,
        height: 'auto',
        minHeight: 180,
        maxHeight: 275,
        zIndex: 102,
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        overflow: 'auto'
    },
    summaryContainer: {
        padding: 20,
    },
    summary: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'row'
    },
    left: {
        paddingLeft: 10,
        flex: 1,
        height: '100%',
        maxWidth: 100,
        maxHeight: 140,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    right: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    distance: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
    distanceValue: {
        color: '#00adef',
        fontSize: 30,
        fontWeight: 'bold'
    },
    distanceLabel: {
        color: '#a2a2a2',
        fontSize: 12
    },
    navigate: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer'
    },
    navigateLabel: {
        color: '#a2a2a2',
        fontSize: 12
    },
    location: {
        marginBottom: 15,
        paddingBottom: 15,
        paddingRight: 30,
        minHeight: 50,
        borderBottom: '1px solid #f2f2f2',
        position: 'relative'
    },
    locationtitle: {
        color: '#484848',
        fontSize: 20,
        fontWeight: 900,
        display: 'block'
    },
    locationaddress: {
        color: '#888888',
        fontSize: 14
    },
    locationSelect: {
        position: 'absolute',
        top: '50%',
        right: 10,
        transform: 'translateY(-50%)'
    },
};

export default locationMarkerSelectionPopUp;