import React from 'react';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { Typography, List, ListItemIcon, ListItem, ListItemText, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

class EvPoints extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            fields: {
                EVPointID: null,
            },
            errors: {
                EVPointID: this.errorField()
            },
            isValid: false,
            isLoading: false,
            isError: false
        };
    }


    //
    //
    //  FORM BINDING AND VALIDATION
    //
    //


    errorField = (group = null) => {
        return {
            dirty: false,
            invalid: false,
            group: group
        };
    }
  
  
    bindField = (e) => {
        let fields = {...this.state.fields};
        fields[e.target.name] = e.target.value;
        this.setState({fields: fields}, () => {this.validate()});
    }

    dirtyGroup = (group = null, callback = () => {}) => {
        let errors = {...this.state.errors};
        Object.values(errors).map(e => {
            if(e.group == group)
                e.dirty = true;
        })
        this.setState({errors: errors}, () => {callback()});
    }
  
    validate = (callback = () => {}) => {
        let errors = {...this.state.errors};
        const required = [
            'EVPointID'
        ]
  
        required.map(f => {
            if(this.state.fields[f].length > 0)
                errors[f].dirty = true;
  
            errors[f].invalid = false;
            if(errors[f].dirty && (this.state.fields[f] == '' || isNaN(this.state.fields[f]))){
                errors[f].invalid = true;
            }
        })
  
        const isValid = Object.values(errors).findIndex(e => e.invalid === true) === -1;
        this.setState({errors: errors, isValid: isValid}, () => {callback()});
    }


    //
    //
    //  EOF FORM BINDING AND VALIDATION
    //
    //



    onRemove = () => {
        this.props.removePoint();
    }

    onAdd = async () => {

        this.dirtyGroup(null, async () => {
            this.validate(async () => {
                if(this.state.isValid){

                    this.setState({isLoading: true, isError: false});
                    await this.props.addPoint(this.state.fields)
                    this.setState({isLoading: false, isError: false});

                } else {
                    console.log('you are not valid');
                }
            })
        })

    }

    onNext = () => {
        console.log('switching view');
        this.props.switchView('scheme');
    }

    onBack = () => {
        console.log('switching view');
        this.props.switchView('scheme');
    }
    
    componentDidMount(){
        this.props.fetchPoints()
    }

    render(){
        const errors = this.state.errors;
        return(
            <div style={styles.container}>
                <div style={styles.wrapper}>

                    <div style={styles.col}>
                    
                        <Typography variant="h5">Add Points</Typography>
                        <p style={{marginTop: 0}}><small>If the number you type in has not been created it will add it to the database</small></p>
                        <div style={styles.inputWrapper}>
                            <input style={styles.input} placeholder="Point ID (without UKEV)" type="text" id="EVPointID" label="Point ID" name="EVPointID" autoFocus onChange={this.bindField} maxLength={4} />
                            <button style={styles.buttonInput} type="submit" onClick={this.onAdd}>{this.state.isLoading?<div style={styles.buttonLoading}><ActivityIndicator /></div>:'ADD'}</button>
                        </div>
                        {errors.EVPointID.invalid && <span style={styles.inputErrorMsg}>Point ID is required as a number</span>}
                    
                    </div>

                    <div style={styles.col}>
                        <Typography variant="h5">Don't forget</Typography>

                        <List>
                            <ListItem><ListItemIcon><CheckIcon color='primary'/></ListItemIcon><ListItemText primary="Check they aren't already commissioned" /></ListItem>
                            <ListItem><ListItemIcon><CheckIcon color='primary'/></ListItemIcon><ListItemText primary="Check the power ratings" /></ListItem>
                            <ListItem><ListItemIcon><CheckIcon color='primary'/></ListItemIcon><ListItemText primary="Confirm test tool was used" /></ListItem>
                        </List>


                        <div style={styles.buttonGroup}>
                            <div style={{flex: 1}}></div>
                            <Button onClick={this.onNext} color="secondary" variant="contained">NEXT</Button>
                        </div>

                    </div>

                </div>
            </div>
        );
    }

}


const styles  = {
    container: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100%', 
        width: '100%',
        backgroundColor: '#e2e2e2'
    },
    header: {
        color: '#484848',
        fontWeight: 300
    },
    wrapper: {
        flex:1,
        padding: 30,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    col: {
        flex: 1,
        padding: 20
    },
    formError: {
      color: '#F26A6A',
      fontSize: 14,
      marginBottom: 15,
      textAlign: 'center',
      display: 'block'
    },
    inputWrapper: {
      height: 54,
      width: '100%',
      backgroundColor: '#ebebeb',
      borderRadius: 12,
      marginBottom: 10,
      justifyContent: 'center',
      display: 'flex'
    },
    input: {
        fontSize: 16, 
        flex: 1,
        paddingLeft: 20,
        border: 'none'
    },
    inputError: {
      boxShadow: 'inset 0 0 0 1px #F26A6A'
    },
    inputErrorMsg: {
      color: '#F26A6A',
      fontSize: 11,
      paddingLeft: 20,
      marginBottom: 8,
      display: 'block'
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 15,
        width: '100%'
    },
    button: {
        backgroundColor: '#00adef',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer',
        alignSelf: 'flex-end'
    },
    buttonDefault: {
        backgroundColor: '#666666',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer',
        alignSelf: 'flex-end'
    },
    buttonInput: {
        backgroundColor: '#F07E00',
        border: 'none',
        color: '#fff',
        height: '100%',
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer',
        alignSelf: 'flex-end',
        paddingLeft: 12,
        paddingRight: 12
    },
    buttonLoading: {
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loading: {
      backgroundColor: '#00adef',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
}

export default EvPoints