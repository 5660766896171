import React, {useContext, useState, useEffect} from 'react';
import { useTheme } from '@material-ui/styles';
import Helpers from '../../../global/helpers';

import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Grid,
    TextField,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Switch,
    FormControlLabel
} from '@material-ui/core';
import GlobalContext from '../../../../context/global-context';


const blank_validation = (name, required = true) => {
    return JSON.parse(JSON.stringify({
        name,
        required,
        valid: false,
        msg: '',
        dirty: false
    }))
}

const AddUser = props => {

    const theme = useTheme();
    const context = useContext(GlobalContext)

    const [ user, setUser ] = useState({
        username: '',
        first_name: '',
        last_name: ''
    });
    const [ validation, setValidation ] = useState({
        username: blank_validation('username', true),
        first_name: blank_validation('first_name', true),
        last_name: blank_validation('last_name', true),
    });
    const [ loading, setLoading ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(null);


    useEffect(() => {
        validate();
    }, [user])


    const isError = (obj) => {
        return (obj.dirty && obj.valid === false);
    }

    const setDirty = name => {
        let newValidation = {...validation};
        if(typeof newValidation[name] != 'undefined'){
            newValidation[name].dirty = true;
            setValidation(newValidation);
        }
    }

    const setDirtyAll = () => {
        let newValidation = {...validation};
        const keys = Object.keys(newValidation);
        keys.map(k => newValidation[k].dirty = true);
        setValidation(newValidation);
    }

    const handleValueChange = e => {
        setDirty(e.target.name);
        const newUser = {...user};
        newUser[e.target.name] = e.target.value;
        setUser(newUser);
    }


    const validate = () => {
        let newValidation = {...validation};

        const keys = Object.keys(newValidation);
        keys.map(k => {
            newValidation[k].valid = true;
            newValidation[k].msg = '';

            if(newValidation[k].required === true && (user[k] === null || user[k].length === 0 || user[k] == '')){
                newValidation[k].valid = false;
                newValidation[k].msg = `${newValidation[k].name} is required to add a new user`;
            }
        })

        setValidation(newValidation);
    }

    const isValid = () => {
        const keys = Object.keys(validation);
        let result = true;
        keys.map(k => {
            if(validation[k].valid === false)
            result = false;
        })
        return result;
    }

    const handleAddUser = () => {
        if(isValid()){
            setLoading(true);
            context.apiRequest('/system/user', 'POST', user)
            .then(
                res => {
                    setLoading(false);
                    setShowPassword(res.password);
                }, err => {
                    setLoading(false);
                    context.showAlert('error', 'There was an error creating the user')
                }
            );
        }
    }

    const handleHidePassword = () => {
        setShowPassword(null);
        props.handleClose();
    }


    const ShowPasswordDialog = props => {

        return (
            <Dialog open={true} maxWidth='xs' fullWidth>
                <DialogTitle id="form-dialog-title">Temporary Password</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle2">Password</Typography>
                    <Typography variant="caption">Note this password and forward to the new user who will be asked to change their password on first login</Typography>
                    <Typography variant="h6" style={{marginTop: 10}}>{props.password}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={props.handleClose}>Got it</Button>
                </DialogActions>
            </Dialog>
        )
    }
    

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title" maxWidth='xs' fullWidth>
            <DialogTitle id="form-dialog-title">New User</DialogTitle>
            <DialogContent>


                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="caption" style={{marginBottom: 20}}>Enter the new user's details below and a temporary password will be displayed on screen for use the first time they login.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="username"
                            label="Username"
                            variant="outlined" 
                            value={user.username} 
                            onChange={handleValueChange} 
                            error={isError(validation.username)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                        {isError(validation.username) && <Typography variant="caption" color="error">{validation.username.msg}</Typography>}
                        {!isError(validation.username) && <Typography variant="caption">*Required</Typography>}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="first_name"
                            label="First Name"
                            variant="outlined" 
                            value={user.first_name} 
                            onChange={handleValueChange} 
                            error={isError(validation.first_name)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                        {isError(validation.first_name) && <Typography variant="caption" color="error">{validation.first_name.msg}</Typography>}
                        {!isError(validation.first_name) && <Typography variant="caption">*Required</Typography>}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="last_name"
                            label="Last Name"
                            variant="outlined" 
                            value={user.last_name} 
                            onChange={handleValueChange} 
                            error={isError(validation.last_name)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                        {isError(validation.last_name) && <Typography variant="caption" color="error">{validation.last_name.msg}</Typography>}
                        {!isError(validation.last_name) && <Typography variant="caption">*Required</Typography>}
                    </Grid>
                    
                </Grid>

                {showPassword !== null && <ShowPasswordDialog handleClose={handleHidePassword} password={showPassword} />}

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Close</Button>
                <div style={{flex: 1}}></div>
                <Button variant="contained" color="secondary" disabled={!isValid() || loading} onClick={handleAddUser}>Add User {loading && <CircularProgress size={24} />}</Button>
            </DialogActions>
        </Dialog>
    )

}

export default AddUser