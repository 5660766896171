import React from 'react';

import { useTheme } from '@material-ui/styles';
import { useStyles } from './styles';

import { Grid, Typography, List, ListItem, Paper } from '@material-ui/core';


// Statements class
const StatementsIndex = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    const routeTo = route => {
        props.history.push(`/statements/${route}`)
    }

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                

                <div className={styles.taskbar}>
                    <Typography variant="h4">Statements</Typography>
                </div>
                <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography variant="h6">VendElectric</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem id= "remittance" button onClick={() => routeTo('ve/hosts')}>Subscriber Remittance</ListItem>
                                <ListItem button onClick={() => routeTo('ve/ocpi')}>OCPI Dashboard</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12} sm={4} lg={3}>
                        <Typography variant="h6">BerthVend</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('bv/hosts')}>Subscriber Remittance</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    
                </Grid>
            </div>
        </div>
    )

}

export default StatementsIndex
