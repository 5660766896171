import React from 'react';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { withTheme } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { CircularProgress, Typography } from '@material-ui/core';

class SignIn extends React.Component {

    static contextType = GlobalContext

    constructor(props) {
        super(props);


        this.state = {
            fields: {
                username: '',
                password: '',
            },
            errors: {
                username: this.errorField(),
                password: this.errorField()
            },
            isValid: false,
            isLoading: false,
            loginError: false
        };
    }


    errorField = (group = null) => {
        return {
            dirty: false,
            invalid: false,
            group: group
        };
    }


    bindField = (e) => {
        let fields = { ...this.state.fields };
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields }, () => { this.validate() });
    }

    dirtyGroup = (group = null, callback = () => { }) => {
        let errors = { ...this.state.errors };
        Object.values(errors).map(e => {
            if (e.group == group)
                e.dirty = true;
        })
        this.setState({ errors: errors }, () => { callback() });
    }

    validate = (callback = () => { }) => {
        let errors = { ...this.state.errors };
        const required = [
            'username',
            'password'
        ]

        required.map(f => {
            if (this.state.fields[f].length > 0)
                errors[f].dirty = true;

            errors[f].invalid = false;
            if (errors[f].dirty && this.state.fields[f] == '') {
                errors[f].invalid = true;
            }
        })

        const isValid = Object.values(errors).findIndex(e => e.invalid === true) === -1;
        this.setState({ errors: errors, isValid: isValid }, () => { callback() });
    }

    onLogin = () => {

        this.dirtyGroup(null, () => {
            this.validate(() => {
                if (this.state.isValid) {

                    this.setState({ isLoading: true, loginError: false });

                    this.context.userLogin(this.state.fields)
                        .then(result => {
                            this.setState({ isLoading: false, loginError: false });
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ isLoading: false, loginError: true });
                        })
                } else {
                    console.log('you are not valid');
                }
            })
        })
    }


    componentDidMount() {

    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onLogin();
        }
    }

    render() {
        const errors = this.state.errors;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Typography variant="h4" style={styles.header}>Sign in</Typography>
                <div style={styles.signinWrapper} id="error_wrapper">
                    {this.state.loginError && <span style={styles.loginError}>Incorrect username or password</span>}
                    <div style={styles.inputWrapper}>
                        <input style={styles.input} placeholder="Username" type="text" id="username" label="Username" name="username" autoFocus onChange={this.bindField} onKeyDown={this._handleKeyDown} />
                    </div>
                    {errors.username.invalid && <span style={styles.inputErrorMsg}>Username is required</span>}
                    <div style={styles.inputWrapper}>
                        <input style={styles.input} placeholder="Password" type="password" id="password" label="Password" name="password" autoFocus onChange={this.bindField} onKeyDown={this._handleKeyDown} />
                    </div>
                    {errors.password.invalid && <span style={styles.inputErrorMsg}>Password is required</span>}
                    <Button disabled={this.state.isLoading} variant="contained" color="secondary" onClick={this.onLogin}>SIGN IN{this.state.isLoading && <CircularProgress color="inherit" size={20} style={{ marginLeft: 10 }} />}</Button>
                    <Typography variant="body2" color="textSecondary" style={{ marginTop: 10 }}>v{this.context.version}</Typography>
                </div>
            </div>
        );
    }

}

const styles = {
    header: {
        fontWeight: 300,
        marginBottom: 20
    },
    signinWrapper: {
        width: '80%',
        maxWidth: 300,
        display: 'flex',
        flexDirection: 'column'
    },
    loginError: {
        color: '#F26A6A',
        fontSize: 14,
        marginBottom: 15,
        textAlign: 'center',
        display: 'block'
    },
    inputWrapper: {
        height: 54,
        width: '100%',
        backgroundColor: '#ebebeb',
        borderRadius: 12,
        marginBottom: 10,
        justifyContent: 'center',
        display: 'flex'
    },
    input: {
        fontSize: 16,
        flex: 1,
        paddingLeft: 20,
        border: 'none'
    },
    inputError: {
        boxShadow: 'inset 0 0 0 1px #F26A6A'
    },
    inputErrorMsg: {
        color: '#F26A6A',
        fontSize: 11,
        paddingLeft: 20,
        marginBottom: 8
    },
    loading: {
        backgroundColor: '#f44336',
        opacity: .7,
        borderRadius: 25,
        height: 30,
        width: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

export default withTheme(SignIn)