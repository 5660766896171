import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from '../../context/global-context';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/styles';
import Helpers from '../global/helpers';

import { 
    Typography, 
    Button, 
    Paper, 
    Grid, 
    Divider, 
    Avatar, 
    List,
    ListItem,
    ListItemText,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import Map from './map';
import TicketComments from './ticket_comments';
import TicketContacts from './ticket_contacts';
import TicketConfirmDialog from './ticket_confirm_dialog';
import TicketAction from './ticket_action';

import TicketChargePointSubscription from './charge_point_subscription';

import TicketEdit from './ticket_edit';
import { ticketMeta } from '../../actions/ticketsActions';
import TicketAttachments from './ticket_attachments';
import TicketVisits from './ticket_visits';

const Ticket = props => {

    const theme = useTheme();
    const styles = useStyles(theme);

    const context = useContext(GlobalContext);

    // Because of the depth of the object this is the safest bet to avoid reference to other tickets
    const [ticket, setTicket] = useState(JSON.parse(JSON.stringify(props.ticket)));
    const [cpids, setCpids] = useState([]);

    // Action controllers
    const [editMode, setEditMode] = useState(props.editMode || false);
    const [showConfirmTakeDialog, setShowConfirmTakeDialog] = useState(false);
    const [showConfirmRouteDialog, setShowConfirmRouteDialog] = useState(false);

    const [ loading, setLoading ] = useState(false);
    

    /*
    *   Subscribe to changes to the ticket
    */
    useEffect(() => {
        // Re-subscribe if the charge points change
        if(cpids.length === 0 || (JSON.stringify(props.ticket.charge_points) != JSON.stringify(ticket.charge_points))){
            let newCpids = [];
            props.ticket.charge_points.map(cp => {newCpids.push(cp.id_charge_point)})
            setCpids(newCpids);
        }
        
        setTicket(JSON.parse(JSON.stringify(props.ticket)))

    }, [props.ticket]);



    const takeTicket = () => {
        setLoading(true);
        context.takeTicket(ticket.id_ticket).then(
            res => {
                setTicket(res.result);
                props.handleUpdateTicket(res.result);
                setLoading(false);
                setShowConfirmTakeDialog(false);
            }, err => {
                setLoading(false);
                setShowConfirmTakeDialog(false);
                context.showAlert('error', 'There was an error taking the ticket')
            }
        );
    }

    const closeTicket = () => {
        props.handleCloseTicket();
    }

    const getGroupSchemeName = id_group_scheme => {
        const gs = props.ticketMeta.group_schemes.find(gs => gs.GroupSchemeID === id_group_scheme);
        if(typeof gs != 'undefined')
            return gs.GroupSchemeName;

        return '';
    }

    const getGroupSchemeDetails = id_group_scheme => {
        return props.ticketMeta.group_schemes.find(gs => gs.GroupSchemeID === id_group_scheme) 
    }
    
    const getSiteName = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return s.SiteName;

        return '';
    }
    
    const getGroupSchemeNameBySite = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return getGroupSchemeName(s.GroupSchemeID);

        return '';
    }

    const getSiteDetails = id_site => {
        return props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site)
    }


    const priorityColour = () => {

        if(ticket.id_ticket_status > 3)
            return styles.prioritynull;

        
        // Check for visit bookings first
        const nextVisit = ticket.visits.find(v => v.ticket_visit_reported === null);
        if(typeof nextVisit != 'undefined'){
            const bookedDay = Helpers.SQLtoUTCDateObj(nextVisit.date_booked);
            const endOfBookedDay = new Date().setTime(bookedDay.getTime() + (24*60*60*1000));
            if(new Date() > endOfBookedDay)
                return styles.priority1;
            if(new Date() > bookedDay)
                return styles.priority2;
            
            return styles.priority3;
        }


        if(ticket.priority_score > ticket.kpi_mins)
            return styles.priority1;
        if(ticket.priority_score > Math.floor(ticket.kpi_mins*.66))
            return styles.priority2;
        
        return styles.priority3;
    }

    const switchCpids = SiteId => {
        // Switching is only allowed if you have selected a group scheme ticket
        if(ticket.id_ticket_effect === 3){
            let newCpids = [];
            if(SiteId != null){
                const newCps = props.ticketMeta.charge_points.filter(cp => cp.ChargeSiteID == SiteId);
                newCps.map(cp => newCpids.push(cp.CPID));
            }
            if(JSON.stringify(newCpids) != JSON.stringify(cpids)){
                setCpids(newCpids);
            }
        }
    }


    return (
        <div className={styles.ticket}>

            {/* MAIN CENTER BODY */}

            <div className={styles.ticketContent}>

                {/* TICKET NUMBER AND AVATAR */}

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: theme.spacing(3), width: '100%'}}>
                    <div>
                        <IconButton onClick={closeTicket} color="default" aria-label="Back to tickets"><ChevronLeftIcon /></IconButton>
                    </div>
                    <Avatar className={priorityColour()} style={{fontSize: 12}}>P{ticket.id_ticket_priority}</Avatar>
                    <div>
                        <Typography variant="h6"> <span style={{fontWeight: 300}}>Ticket:</span> #{ticket.ticket_ref}</Typography>
                        <Typography variant="subtitle1"> <span style={{fontWeight: 300}}>Routed to:</span> {ticket.ticket_unit_name}</Typography>
                        <Typography variant="subtitle1"> <span style={{fontWeight: 300}}>Created on:</span> {Helpers.SQLtoUTCDate(ticket.created)}</Typography>
                    </div>
                    <div style={{flex: 1}}></div>
                    <div style={{textAlign: 'right'}}>
                        <Typography variant="h6"> <span style={{fontWeight: 300}}>Status:</span> {ticket.ticket_status_name}</Typography>
                        <Typography variant="subtitle1">
                            <span style={{fontWeight: 300}}>Owned by:</span> {ticket.assigned_to} 
                            {ticket.id_user_assigned != props.user.id_user && <Button color="secondary" onClick={() => setShowConfirmTakeDialog(true)}>{ticket.id_user_assigned != null ? 'Take' : 'Own this ticket'}</Button>}
                        </Typography>
                        <Typography variant="subtitle1"> <span style={{fontWeight: 300}}>Created by:</span> {ticket.created_by}</Typography>
                        
                    </div>
                </div>

                
                {/* <Paper> */}
                    <div className={styles.ticketContentInner}>
                        

                        <Grid container spacing={3}>

                            {/* ENQUIRY TYPE AND DESCRIPTION */}

                            <Grid item md={12} lg={8}>

                                <Paper className={styles.paper}>

                                    <Typography color="textSecondary" className={styles.title}>Summary</Typography>
                                    <Typography className={styles.pos} variant="h6">{ticket.ticket_subject}</Typography>

                                    <Typography color="textSecondary" className={styles.title}>Description</Typography>
                                    <Typography className={styles.posPre}>{ticket.ticket_description}</Typography>

                                    <Typography color="textSecondary" className={styles.title}>Enquiry Type</Typography>
                                    <Typography className={styles.pos}>{ticket.ticket_type_name}</Typography>

                                </Paper>

                                {/* VISITS */}

                                <TicketVisits ticket={ticket} ticketMeta={props.ticketMeta} setTicket={setTicket} handleUpdateTicket={props.handleUpdateTicket} />


                            </Grid>

                            
                            

                            {/* REPORTED BY */}

                            <Grid item md={12} lg={4}>

                                <Paper className={styles.paper}>
                                
                                
                                    <Typography color="textSecondary" className={styles.title}>Reported By</Typography>
                                    <Typography className={styles.pos}>{ticket.raised_by_name}</Typography>
                                    
                                    
                                    <Typography color="textSecondary" className={styles.title}>Email</Typography>
                                    <Typography className={styles.pos}>{ticket.raised_by_email}</Typography>
                                    
                                    
                                    <Typography color="textSecondary" className={styles.title}>Phone</Typography>
                                    <Typography className={styles.pos}>{ticket.raised_by_phone}</Typography>

                                    <Divider className={styles.divider} />

                                    {/* ATTACHMENTS */}

                                    <TicketAttachments ticket={ticket} />

                                </Paper>

                                <Paper className={styles.paper}>

                                    {/* EXTERNAL NOTIFICATIONS */}

                                    <TicketContacts ticket={ticket} contactTypes={props.ticketMeta.contact_types} />

                                </Paper>

                            </Grid>

                                
                            {/* EDIT AND ACTION */}

                            <Grid item sm={12}>

                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                    <Button variant="contained" onClick={() => setEditMode(true)} className={styles.button}>Edit</Button>
                                    <Button variant="contained" color="secondary" onClick={() => setShowConfirmRouteDialog(true)} className={styles.button}>Action</Button>
                                </div>

                            </Grid>

                        </Grid>

                    </div>
                {/* </Paper> */}

                <Typography variant="subtitle2" style={{marginTop: 20}}>History</Typography>

                {ticket.mode != 'new' && <TicketComments ticket={ticket} />}
            </div>


            

            {/* META DATA TO THE RIGHT */}


            <div className={styles.ticketMeta}>

                
                <Typography color="textSecondary" className={styles.title}>Priority</Typography>
                <Typography className={styles.pos}>{ticket.ticket_priority_name}</Typography>

                <Typography color="textSecondary" className={styles.title}>Network</Typography>
                <Typography className={styles.pos}>{ticket.network_name}</Typography>

                <Typography color="textSecondary" className={styles.title}>Level</Typography>
                <Typography className={styles.pos}>{ticket.ticket_effect_name}</Typography>

                <Divider className={styles.inputField} />
                
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <Typography variant="subtitle2">Group Scheme</Typography>
                </div>
                {ticket.group_schemes.length === 0 && <Typography>No group schemes added</Typography>}
                <List>
                    {ticket.group_schemes.map((s, idx) => (
                            <ListItem key={idx}>
                                <ListItemText primary={`#${s.id_group_scheme}`} secondary={getGroupSchemeName(s.id_group_scheme)} />
                            </ListItem>
                    ))}
                </List>

                <Divider className={styles.inputField} />



                {ticket.sites.length > 0&& <div>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                        <Typography variant="subtitle2">Sites</Typography>
                    </div>
                    {ticket.sites.length === 0 && <Typography>No sites added</Typography>}
                    <List>
                        {ticket.sites.map((s, idx) => (
                                <ListItem key={idx} button={ticket.id_ticket_effect === 3} onClick={() => switchCpids(s.id_site)}>
                                    <ListItemText primary={getSiteName(s.id_site)} secondary={getGroupSchemeNameBySite(s.id_site)} />
                                </ListItem>
                        ))}
                    </List>

                    <Divider className={styles.inputField} />

                </div>}




                {/*
                *
                *   CHARGE POINT STATUSES
                *
                */}


                                
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <Typography variant="subtitle2">Charge Points</Typography>
                </div>

                {(ticket.id_ticket_effect === 1 && ticket.charge_points.length === 0 && cpids.length === 0) && <Typography color="textSecondary">Select a site above to show charge points</Typography>}


                {(ticket.charge_points.length > 0 || cpids.length > 0) && <div>

                    <TicketChargePointSubscription cpids={cpids} />

                </div>}

                <Divider className={styles.inputField}/>





                {/*
                *
                *   EOF CHARGE POINT STATUSES
                *
                */}






                
                <div style={{width: '100%', height: 300, position: 'relative'}}>
                    <Map ticket={ticket} meta={props.ticketMeta} />
                </div>

                <Divider className={styles.inputField} />


                <Typography variant="body2" color="textSecondary">Created: {Helpers.SQLtoUTCDate(ticket.created)}</Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>By: {ticket.created_by}</Typography>
                <Typography variant="body2" color="textSecondary">Updated: {ticket.triggers.length > 0 ? Helpers.SQLtoUTCDate(ticket.triggers[0].trigger_date) : Helpers.SQLtoUTCDate(ticket.last_updated)}</Typography>
                {ticket.triggers.length > 0 && <Typography variant="body2" color="textSecondary" gutterBottom>By: {ticket.triggers[0].name}</Typography>}

                {ticket.id_ticket_status === 5 && <Typography variant="body2" color="textSecondary">Closed: {Helpers.SQLtoUTCDate(ticket.closed_on)}</Typography>}
                {ticket.id_ticket_status === 5 && <Typography variant="body2" color="textSecondary">By: {ticket.closed_by}</Typography>}


                <Divider className={styles.inputField} />


            </div>


            
            
            {/* DIALOGS */}

            <TicketConfirmDialog open={showConfirmTakeDialog} title="Take this ticket" body="You are assigning this ticket to yourself" handleClose={() => {setShowConfirmTakeDialog(false)}} handleAccept={takeTicket} loading={loading} />

            <TicketAction 
                open={showConfirmRouteDialog} 
                handleClose={() => setShowConfirmRouteDialog(false)} 
                setTicket={setTicket} 
                handleAddComment={props.handleAddComment} 
                handleUpdateTicket={props.handleUpdateTicket} 
                ticket={ticket} 
                ticketMeta={props.ticketMeta}
                user={props.user}
             />
            

            {editMode && <TicketEdit 
                open={editMode}
                ticket={ticket} 
                handleUpdateTicket={props.handleUpdateTicket} 
                handleDirty={props.handleDirty} 
                dirty={props.dirty}
                ticketMeta={props.ticketMeta} 
                handleClose={() => setEditMode(false)}/>}

        </div>
    )

}

export default Ticket