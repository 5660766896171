import React, { useState, useContext, useRef } from 'react';
import {withRouter} from 'react-router-dom';
import Helpers from '../global/helpers';
import { useStyles } from './styles';

import { useTheme } from '@material-ui/styles';
import { 
    Typography, 
    List, 
    TextField,
    Popover,
    InputAdornment,
    IconButton
     } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import TicketSummary from './ticket_summary';




// Tickets Search class
const TicketSearch = props => {

    const theme = useTheme();
    const styles = useStyles(theme);

    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const searchTextField = useRef(null);

    const handleSearchChange = e => {
        setSearch(e.target.value);
        searchTickets(e.target.value);
    }

    const searchTickets = (val) => {

        // Search can be called on load if there is a query string with the search string in

        let copyTickets = [...JSON.parse(JSON.stringify(props.tickets))];
        const searchStr = val.toUpperCase();

        if(searchStr.length > 0 && searchStr != ''){
            copyTickets = copyTickets.filter(t => (
                Helpers.isNull(t.ticket_ref).toUpperCase().indexOf(searchStr) > -1 ||
                Helpers.isNull(t.ticket_subject).toUpperCase().indexOf(searchStr) > -1 ||
                Helpers.isNull(t.ticket_description).toUpperCase().indexOf(searchStr) > -1
            ));

            setSearchResults(copyTickets);
        } else {
            setSearchResults([]);
        }
    }

    return (

        <div className={styles.taskbar}>

            <div style={{display:'flex', flexDirection: 'row', flex: 1}}>
                <TextField 
                    ref={searchTextField} 
                    name="search" 
                    label="Search Tickets" 
                    variant="outlined" 
                    value={search} 
                    onChange={handleSearchChange} 
                    className={styles.inputField} 
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            {search.length != '' && <IconButton onClick={() => setSearch('')}><CloseIcon color="secondary" /></IconButton>}
                            </InputAdornment>
                        ),
                    }} />

                <Popover
                    id='search_results_popover'
                    open={searchResults.length > 0}
                    anchorEl={searchTextField.current}
                    onClose={() => setSearchResults([])}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    disableAutoFocus
                    disableEnforceFocus
                    style={{maxWidth: props.openMode ? 360 : 'auto'}}
                >
                    <div style={{padding: 20, maxHeight: 250, overflowY: 'auto'}}>
                        {searchResults.length == 0 && <Typography disabled>No ticket found</Typography>}
                        <List>
                        {searchResults.map(ticket =>
                            <TicketSummary key={ticket.id_ticket} ticket={ticket} handleOpenTicket={() => props.history.push(`/tickets/monitor/${ticket.ticket_ref}`)} openMode={props.openMode} /> 
                        )}
                        </List>
                    </div>
                </Popover>
            </div>

        </div>
    )

}

export default withRouter(TicketSearch)
