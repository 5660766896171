import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/styles';
import Helpers from '../global/helpers';
import { useStyles } from './styles';

import { 
    Typography, 
    Button, 
    TextField, 
    Grid, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    Divider, 
    Chip, 
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

import TicketContacts from './ticket_contacts';
import TicketSelectGroupScheme from './ticket_select_group_scheme';
import TicketSelectSite from './ticket_select_site';
import TicketSelectChargePoint from './ticket_select_charge_point';


const blank_validation = (name, required = true) => {
    return JSON.parse(JSON.stringify({
        name,
        required,
        valid: false,
        msg: '',
        dirty: false
    }))
}




const DirtyWarning = props => {
    return (
        <Dialog onClose={props.handleIgnore} aria-labelledby="dirty-warning-dialog-title" open={props.open}>
            <DialogTitle id="dirty-warning-dialog-title">Save your changes?</DialogTitle>
            <DialogContent>You have made a change without saving, would you like to save your changes?</DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Discard Changes</Button>
                <Button onClick={props.handleSave} variant="secondary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}


const TicketEdit = props => {

    const { open } = props;

    const theme = useTheme();
    const styles = useStyles(theme);

    const context = useContext(GlobalContext);

    // Because of the depth of thew object this is the safest bet to avoid reference to other tickets
    const [ticket, setTicket] = useState(JSON.parse(JSON.stringify(props.ticket)));
    const [ validation, setValidation ] = useState({
        id_ticket_type: blank_validation('Enquiry Type', true),
        id_ticket_effect: blank_validation('Level', false),
        charge_points: blank_validation('Charge Point', false),
        sites: blank_validation('Site', false),
        group_schemes: blank_validation('Group Scheme', false),
        ticket_subject: blank_validation('Summary', true),
        ticket_description: blank_validation('Description', true),
        id_ticket_priority: blank_validation('Priority', true),
        id_network: blank_validation('Network', true),
        id_ticket_contact_type: blank_validation('Reported By Type', true),
        raised_by_name: blank_validation('Report By Name', true),
        id_ticket_unit: blank_validation('A team to route', true),
        id_ticket_status: blank_validation('Ticket status', true),
    });

    // Dialogs
    const [showDirtyWarning, setShowDirtyWarning] = useState(false);

    const [ loading, setLoading ] = useState(false);
    
    // Because the state isn't re-rendered on prop change we have to expect it.
    useEffect(() => {

            //  If a comment comes in it will wipe out any changes made if you are in edit mode
            //  So we need to first overwrite the comments then compare if the two objects
            //  Are different. If not then leave it at that but if so alert the user to the
            //  change
            const currentTicket = JSON.parse(JSON.stringify(ticket));
            currentTicket.logs = [...props.ticket.logs];

            if(JSON.stringify(currentTicket) != JSON.stringify(props.ticket)){
                context.showAlert('error', 'This ticket has been updated, saving the ticket may overwrite any of the previous changes.');
                setTicket(currentTicket);
            } else {
                setTicket(JSON.parse(JSON.stringify(props.ticket)));
            }

    }, [props.ticket]);

    useEffect(() => {
        validate();
    }, [ticket])



    /*
    *
    *   SAVING AND VALIDATING
    *
    */

    const saveTicket = () => {
        setDirtyAll();
        if(isValid()){
            setLoading(true);
            context.saveTicket(ticket).then(
                res => {
                    setTicket(res.result);
                    props.handleUpdateTicket(res.result);
                    props.handleDirty(false);
                    props.handleClose();
                    setLoading(false);
                }, err => {
                    setLoading(false);
                    context.showAlert('error', 'There was an error saving the ticket')
                }
            );
        }
    }


    const validate = () => {
        let newValidation = {...validation};

        const keys = Object.keys(newValidation);
        keys.map(k => {
            newValidation[k].valid = true;
            newValidation[k].msg = '';

            if(newValidation[k].required === true && (ticket[k] === null || ticket[k].length === 0 || ticket[k] == '')){
                newValidation[k].valid = false;
                newValidation[k].msg = `${newValidation[k].name} is required to create a new ticket`;
            }
        })

        setValidation(newValidation);
    }

    const isValid = () => {
        const keys = Object.keys(validation);
        let result = true;
        keys.map(k => {
            if(validation[k].valid === false)
            result = false;
        })
        return result;
    }


    const closeEdit = () => {
        if(props.dirty)
            setShowDirtyWarning(true);
        else
            props.handleClose();
    }


    const discardEdit = () => {
        props.handleDirty(false);
        props.handleClose();
    }






    /*
    *
    *   HANDLE CHANGES TO VALUES
    *
    */

    const setDirty = name => {
        let newValidation = {...validation};
        if(typeof newValidation[name] != 'undefined'){
            newValidation[name].dirty = true;
            setValidation(newValidation);
        }
        props.handleDirty(true);
    }

    const setDirtyAll = () => {
        let newValidation = {...validation};
        const keys = Object.keys(newValidation);
        keys.map(k => newValidation[k].dirty = true);
        setValidation(newValidation);
    }

    const handleValueChange = e => {
        setDirty(e.target.name);
        const newTicket = {...ticket};
        newTicket[e.target.name] = e.target.value;
        setTicket(newTicket);
        props.handleDirty(true);
    }

    // CONTACTS

    const handleAddContact = obj => {
        var newTicket = {...ticket};
        newTicket.contacts.push(obj);
        setTicket(newTicket);
    }

    const handleRemoveContact = obj => {
        var newTicket = {...ticket};
        let idx = newTicket.contacts.findIndex(c => JSON.stringify(c) === JSON.stringify(obj));
        newTicket.contacts.splice(idx, 1);
        setTicket(newTicket);
    }

    // GROUP SCHEMES

    const handleAddGroupScheme = obj => {
        setDirty('group_schemes');

        var newTicket = {...ticket};
        newTicket.group_schemes.push(obj);

        // Attach the sites in too
        const sites = props.ticketMeta.sites.filter(s => s.GroupSchemeID === obj.id_group_scheme);
        const gss = [];
        sites.map(cp => gss.push({
            id_ticket: obj.id_ticket,
            id_site: cp.ChargeSiteID
        }))

        newTicket.sites = gss;
        newTicket.charge_points = [];

        setTicket(newTicket);

    }

    const handleRemoveGroupScheme = obj => {
        setDirty('group_schemes');

        var newTicket = {...ticket};
        const idx = newTicket.group_schemes.findIndex(p => p.id_group_scheme === obj.id_group_scheme);
        newTicket.group_schemes.splice(idx, 1);
        setTicket(newTicket);
    }

    // SITES

    const handleAddSite = obj => {
        setDirty('sites');

        var newTicket = {...ticket};
        newTicket.sites.push(obj);

        const site = props.ticketMeta.sites.find(s => s.ChargeSiteID === obj.id_site);

        if(typeof site != 'undefined'){
            const gs = {
                id_ticket: obj.id_ticket,
                id_group_scheme: site.GroupSchemeID
            }

        
            const cps = [];
            const charge_points = props.ticketMeta.charge_points.filter(c => c.ChargeSiteID === obj.id_site);
            charge_points.map(cp => cps.push({
                id_ticket: obj.id_ticket,
                id_charge_point: cp.CPID
            }))
            
            newTicket.group_schemes = [gs];
            newTicket.charge_points = cps;
        }

        setTicket(newTicket);
    }

    const handleRemoveSite = obj => {
        setDirty('sites');

        var newTicket = {...ticket};
        const idx = newTicket.sites.findIndex(p => p.id_site === obj.id_site);
        newTicket.sites.splice(idx, 1);
        setTicket(newTicket);
    }

    // CHARGE POINTS

    const handleAddChargePoint = obj => {
        setDirty('charge_points');

        var newTicket = {...ticket};
        newTicket.charge_points.push(obj);

        const cp = props.ticketMeta.charge_points.find(c => c.CPID === obj.id_charge_point);

        if(typeof cp != 'undefined'){
            const s = {
                id_ticket: obj.id_ticket,
                id_site: cp.ChargeSiteID
            }

            const gs = {
                id_ticket: obj.id_ticket,
                id_group_scheme: cp.GroupSchemeID
            }
            
            newTicket.sites = [s];
            newTicket.group_schemes = [gs];
        }

        setTicket(newTicket);
    }

    const handleRemoveChargePoint = obj => {
        setDirty('charge_points');

        var newTicket = {...ticket};
        const idx = newTicket.charge_points.findIndex(p => p.id_charge_point === obj.id_charge_point);
        newTicket.charge_points.splice(idx, 1);
        setTicket(newTicket);
    }



    


    /*
    *
    *   HELPER FUNCTIONS
    *
    */

    const getGroupSchemeDetails = id_group_scheme => {
        return props.ticketMeta.group_schemes.find(gs => gs.GroupSchemeID === id_group_scheme)
    }

    const getSiteDetails = id_site => {
        return props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site)
    }

    const isError = (obj) => {
        return (obj.dirty && obj.valid === false);
    }


    return (
        <Dialog open={open} onClose={closeEdit} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'}>
            <DialogContent>

            {/* MAIN CENTER BODY */}

            <div className={styles.ticketContent}>

                {/* TICKET NUMBER AND AVATAR */}

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: theme.spacing(3), width: '100%'}}>
                    <div>
                        <Typography variant="h6"> <span style={{fontWeight: 300}}>Edit Ticket:</span> #{ticket.ticket_ref}</Typography>
                    </div>
                </div>
                        

                    <Grid container spacing={3}>

                        {/* ENQUIRY TYPE AND DESCRIPTION */}

                        <Grid item xs={12} className={styles.section}>

                            <FormControl className={styles.inputField} variant="outlined" size="small">
                                <InputLabel id="id_ticket_effect_label">Level</InputLabel>
                                <Select
                                    labelId="id_ticket_effect_label"
                                    id="id_ticket_effect"
                                    name="id_ticket_effect"
                                    value={ticket.id_ticket_effect}
                                    onChange={handleValueChange}
                                    label="Level"
                                    
                                >
                                    {props.ticketMeta.effects.map((type, idx) => (
                                        <MenuItem key={idx} value={type.id_ticket_effect}>{type.ticket_effect_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                                
                        </Grid>

                        
                        <Divider className={styles.divider} />


                        {ticket.id_ticket_effect === 1 && 
                        <Grid item xs={12} className={styles.section}>
                            
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                <Typography variant="subtitle2">Charge Point</Typography>
                            </div>

                            {ticket.charge_points.length === 0 && <TicketSelectChargePoint charge_points={props.ticketMeta.charge_points} sites={props.ticketMeta.sites} ticket={props.ticket} handleAdd={handleAddChargePoint} />}
                            
                            {ticket.charge_points.length === 0 && <Typography style={{padding: 20}}>No charge points added</Typography>}

                            {ticket.charge_points.map((p,idx) => (
                                <Chip
                                    key={idx}
                                    label={p.id_charge_point}
                                    clickable
                                    onDelete={() => handleRemoveChargePoint(p)}
                                    style={{margin: theme.spacing(1)}}
                                />
                            ))}

                        </Grid>
                        }
        
                        {ticket.id_ticket_effect === 2 && 
                        <Grid item xs={12} className={styles.section}>

                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                <Typography variant="subtitle2">Sites</Typography>
                            </div>

                            {ticket.sites.length === 0 && <TicketSelectSite sites={props.ticketMeta.sites} group_schemes={props.ticketMeta.group_schemes} ticket={props.ticket} handleAdd={handleAddSite} />}

                            <List>
                                {ticket.sites.map((s, idx) => (
                                        <ListItem key={idx}>
                                            <ListItemText primary={getSiteDetails(s.id_site).SiteName} secondary={getSiteDetails(s.id_site).GroupSchemeName} />
                                            <ListItemSecondaryAction><IconButton edge="end" aria-label="delete" color="secondary" onClick={() => handleRemoveSite(s)}><CloseIcon /></IconButton></ListItemSecondaryAction>
                                        </ListItem>
                                ))}
                            </List>


                        </Grid>
                        }

                        {ticket.id_ticket_effect === 3 && 
                        <Grid item xs={12} className={styles.section}>

                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                <Typography variant="subtitle2">Group Scheme</Typography>
                            </div>

                            {ticket.group_schemes.length === 0 && <TicketSelectGroupScheme group_schemes={props.ticketMeta.group_schemes} ticket={props.ticket} handleAdd={handleAddGroupScheme} />}
                            
                            <List>
                                {ticket.group_schemes.map((s, idx) => (
                                        <ListItem key={idx}>
                                            <ListItemText primary={`#${s.id_group_scheme}`} secondary={getGroupSchemeDetails(s.id_group_scheme).GroupSchemeName} />
                                            <ListItemSecondaryAction><IconButton edge="end" aria-label="delete" color="secondary" onClick={() => handleRemoveGroupScheme(s)}><CloseIcon /></IconButton></ListItemSecondaryAction>
                                        </ListItem>
                                ))}
                            </List>
                                
                        </Grid>
                        }


                        
                        <Divider className={styles.divider} />

                        

                        <Grid item xs={12}>

                            <TextField size="small" name="ticket_subject" label="Summary" variant="outlined" value={ticket.ticket_subject} onChange={handleValueChange} className={styles.inputField} fullWidth autoComplete="no" error={isError(validation.ticket_subject)} />
                            {isError(validation.ticket_subject) && <Typography variant="caption" color="error">{validation.ticket_subject.msg}</Typography>}
                            {!isError(validation.ticket_subject) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}

                            <TextField size="small" name="ticket_description" label="Description" variant="outlined" value={ticket.ticket_description} onChange={handleValueChange} className={styles.inputField} fullWidth multiline rows={10} error={isError(validation.ticket_description)} />
                            {isError(validation.ticket_description) && <Typography variant="caption" color="error">{validation.ticket_description.msg}</Typography>}
                            {!isError(validation.ticket_description) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}

                        </Grid>


                        <Grid item xs={12}>

                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>

                            <FormControl className={styles.inputField} variant="outlined" size="small" error={isError(validation.id_ticket_priority)} >
                                <InputLabel id="id_ticket_priority_label">Priority</InputLabel>
                                <Select
                                    labelId="id_ticket_priority_label"
                                    id="id_ticket_priority"
                                    name="id_ticket_priority"
                                    value={ticket.id_ticket_priority}
                                    onChange={handleValueChange}
                                    label="Priority"
                                    
                                >
                                    {props.ticketMeta.priorities.map((type, idx) => (
                                        <MenuItem key={idx} value={type.id_ticket_priority}>{type.ticket_priority_name}</MenuItem>
                                    ))}
                                </Select>
                                {isError(validation.id_ticket_priority) && <Typography variant="caption" color="error">{validation.id_ticket_priority.msg}</Typography>}
                                {!isError(validation.id_ticket_priority) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                            </FormControl>
                            

                            <FormControl className={styles.inputField} variant="outlined" size="small" error={isError(validation.id_ticket_type)}>
                                <InputLabel id="ticket_type_label">Enquiry Type</InputLabel>
                                <Select
                                    labelId="ticket_type_label"
                                    id="id_ticket_type"
                                    name="id_ticket_type"
                                    value={ticket.id_ticket_type}
                                    onChange={handleValueChange}
                                    label="Enquiry Type"
                                >
                                    {props.ticketMeta.types.map((type, idx) => (
                                        <MenuItem key={idx} value={type.id_ticket_type}>{type.ticket_type_name}</MenuItem>
                                    ))}
                                </Select>
                                {isError(validation.id_ticket_type) && <Typography variant="caption" color="error">{validation.id_ticket_type.msg}</Typography>}
                                {!isError(validation.id_ticket_type) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                            </FormControl>

                            <FormControl className={styles.inputField} variant="outlined" size="small" error={isError(validation.id_network)}>
                                <InputLabel id="id_network_label">Network</InputLabel>
                                <Select
                                    labelId="id_network_label"
                                    id="id_network"
                                    name="id_network"
                                    value={ticket.id_network}
                                    onChange={handleValueChange}
                                    label="Network"
                                    
                                >
                                    {props.ticketMeta.networks.map((type, idx) => (
                                        <MenuItem key={idx} value={type.id_network}>{type.network_name}</MenuItem>
                                    ))}
                                </Select>
                                {isError(validation.id_network) && <Typography variant="caption" color="error">{validation.id_network.msg}</Typography>}
                                {!isError(validation.id_network) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}
                            </FormControl>
                            
                            </div>

                        </Grid>

                        
                        <Divider className={styles.divider} />  
                            

                        {/* REPORTED BY */}

                        <Grid item xs={12}>

                            <Typography variant="subtitle2" style={{marginBottom: theme.spacing(2)}}>Reported By</Typography>

                            <FormControl className={styles.inputField} variant="outlined" fullWidth size="small" error={isError(validation.id_ticket_contact_type)}>
                                <InputLabel id="id_ticket_contact_type_label">Type</InputLabel>
                                <Select
                                    labelId="id_ticket_contact_type_label"
                                    id="id_ticket_contact_type"
                                    name="id_ticket_contact_type"
                                    value={ticket.id_ticket_contact_type}
                                    onChange={handleValueChange}
                                    label="Type"
                                    
                                >
                                    {props.ticketMeta.contact_types.map((type, idx) => (
                                        <MenuItem key={idx} value={type.id_ticket_contact_type}>{type.ticket_contact_type_name}</MenuItem>
                                    ))}
                                </Select>
                                {isError(validation.id_ticket_contact_type) && <Typography variant="caption" color="error">{validation.id_ticket_contact_type.msg}</Typography>}
                                {!isError(validation.id_ticket_contact_type) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}

                            </FormControl>
                            <TextField size="small" name="raised_by_name" label="Name" variant="outlined" value={ticket.raised_by_name} onChange={handleValueChange} className={styles.inputField} fullWidth error={isError(validation.raised_by_name)} />
                            {isError(validation.raised_by_name) && <Typography variant="caption" color="error">{validation.raised_by_name.msg}</Typography>}
                            {!isError(validation.raised_by_name) && <Typography variant="caption" className={styles.colorOrange}>*Required</Typography>}

                            <TextField size="small" name="raised_by_email" label="Email" variant="outlined" value={ticket.raised_by_email} onChange={handleValueChange} className={styles.inputField} fullWidth />

                            <TextField size="small" name="raised_by_phone" label="Tel" variant="outlined" value={ticket.raised_by_phone} onChange={handleValueChange} className={styles.inputField} fullWidth />

                        </Grid>

                        <Divider className={styles.divider} />

                        <Grid item sm={12}>

                            <TicketContacts  
                                ticket={ticket} 
                                handleAddContact={handleAddContact} 
                                handleRemoveContact={handleRemoveContact} 
                                contactTypes={props.ticketMeta.contact_types}
                                editMode={true} />

                        </Grid>

                    </Grid>

            </div>

            </DialogContent>

            <DialogActions>

                <Button variant="contained" onClick={closeEdit}>Close</Button>
                <Button variant="contained" color="secondary" color={isValid() ? 'secondary' : 'default'} onClick={saveTicket} disabled={!isValid() || loading}>Save{loading && <CircularProgress color="inherit" size={24} style={{marginLeft: 10}} />}</Button>

            </DialogActions>

            <DirtyWarning open={showDirtyWarning} handleClose={discardEdit} handleSave={saveTicket} handleIgnore={() => setShowDirtyWarning(false)} />
        </Dialog>
    )

}

export default TicketEdit