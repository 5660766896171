import React, {useContext, useState, useEffect} from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, CircularProgress, List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import ProgressButton from '../../../components/progressButton';
import { Alert } from '@material-ui/lab';
import globalContext from '../../../context/global-context';
import API from '../../../api';


const AwaitingPay = props => {

    const context = useContext(globalContext);

    const {row} = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const [paymentReference, setPaymentReference] = useState('');
    const [bankAcc, setBankAcc] = useState(null);

    const [loadingDetails, setLoadingDetails] = useState(false);

    const handlePay = async () => {

        if(paymentReference !== ''){
        setLoading(true);
        setError(null);

        try {
            
            const res = await context.apiRequest(`/bv/statements/pay`, 'POST', {...row, payment_reference: paymentReference});
            if(res.success){
                setSuccess(true);

                if(typeof props.onSuccess === 'function')
                    props.onSuccess();
            } else
                throw res.msg;
            
        } catch (err) {
            setError(typeof err === 'string' ? err : typeof err.msg !== 'undefined' ? err.msg : JSON.stringify(err));
        } finally {
            setLoading(false);
        }

    }
        
    }

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }


    const loadAccount = async () => {

        try {

            setLoadingDetails(true);
            const data = await API.req(`/bv/organisation/account/${row.id_organisation}`, 'GET', null);
            if(data.success === true)
                setBankAcc(data.result);
            else
                setBankAcc(null);
        

            setLoadingDetails(false);
            
        } catch (error) {

            setLoadingDetails(false);
            
        }

    }

    useEffect(() => {

        loadAccount();

    }, [])

    return (
        <React.Fragment>
            <Dialog open={true} onClose={handleClose}>
                <DialogTitle>Pay Statment for {row.organisation_name}</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" style={{marginBottom: 6}}>Confirm you wish to mark the statement for {row.organisation_name} as paid.</Typography>
                    
                    {loadingDetails && <CircularProgress size={24} color="secondary" />}
                    {bankAcc &&
                    <React.Fragment>
                        <Box style={{marginTop: 20}}>
                            <Typography variant="body2">Account Name: <b>{bankAcc.bank_account_name}</b></Typography>
                            <Typography variant="body2">Sort / SWIFT or BIC Code: <b>{bankAcc.bank_account_sort}</b></Typography>
                            <Typography variant="body2">Account / IBAN Number: <b>{bankAcc.bank_account_no}</b></Typography>
                        </Box>
                        <List style={{marginTop: 20}} dense subheader={<ListSubheader component="div" style={{paddingLeft: 0, lineHeight: '28px'}}>Accounts contacts</ListSubheader>}>
                            {bankAcc.contacts.map((c, idx) => <ListItem key={idx}><ListItemText primary={`${c.name}: ${c.email}`} secondary={`${c.position} ${c.phone}`} /></ListItem>)}
                        </List>
                    </React.Fragment>
                    }
                    <TextField name="paymentReference" shrink={true} label="Payment Reference" fullWidth onChange={e => setPaymentReference(e.target.value)} value={paymentReference} error={paymentReference === ''} style={{marginBottom: 20}}/>
                    
                    {error && <Alert severity='error'>{error}</Alert>}
                    {success && <Alert severity='success'>Statement paid</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{success ? 'Close' : 'Cancel'}</Button>
                    {!success && <ProgressButton waiting={loading} onClick={handlePay} variant="contained" color="secondary">Confirm</ProgressButton>}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default AwaitingPay;