import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from './styles';

import {withRouter} from 'react-router-dom';

import { 
    Grid, 
    Typography, 
    Paper, 
    CircularProgress, 
    TableContainer, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody, 
    Button, 
    Tooltip, 
    Dialog, 
    DialogContent, 
    DialogActions, 
    DialogTitle, 
    FormControlLabel,
    Checkbox,
    TextField,
    FormControl,
    IconButton, 
    FormHelperText,
    Select,
    MenuItem,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Divider
} from '@material-ui/core';
import GlobalContext from '../../context/global-context';
import Helpers from '../global/helpers';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';


const DiscardDialog = props => {
    const { handleConfirm, handleCancel } = props;

    return (
        <Dialog onClose={props.handleCancel} aria-labelledby="dirty-warning-dialog-title" open={true} maxWidth={'xs'} fullWidth>
            <DialogTitle>DISCARD CHANGES</DialogTitle>
            <DialogContent>Are you sure you want to DISCARD your changes?</DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>No</Button>
                <Button onClick={handleConfirm} color="secondary">Yes</Button>
            </DialogActions>
        </Dialog>
    )
}


const SaveDialog = props => {
    const { handleConfirm, handleCancel } = props;

    return (
        <Dialog onClose={props.handleCancel} aria-labelledby="dirty-warning-dialog-title" open={true} maxWidth={'xs'} fullWidth>
            <DialogTitle>CONFIRM SAVE</DialogTitle>
            <DialogContent>Have you double checked your changes? Pressing 'Yes' will make your changes live</DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>No</Button>
                <Button onClick={handleConfirm} color="secondary">Yes</Button>
            </DialogActions>
        </Dialog>
    )
}


const UpdateDialog = props => {
    const { handleCancel, displayColumns, idCol, dataSelectors, selectors } = props;
    const theme = useTheme();
    const styles = useStyles(theme);
    const columns = displayColumns.filter(c => c.editable === true);

    const [ data, setData ] = useState(props.obj);
    const [ tmpObjdata, setTmpObjdata ] = useState({});
    const [ invalid, setInvalid ] = useState([]);

    useEffect(() => {
        validate();
    }, [data])

    const handleUpdate = () => {
        if(invalid.length === 0)
            props.handleUpdate(data);
    }

    const validate = () => {
        const required = displayColumns.filter(c => c.required === true);
        let newInvalid = [];
        required.map(r => {
            if(data[r.name] === null || data[r.name].length === 0 || data[r.name] == '')
                newInvalid.push({name: r.name})
        })
        setInvalid(newInvalid);
    }

    const handleChange = e => {
        let newData = JSON.parse(JSON.stringify(data));
        newData[e.target.name] = e.target.value;
        setData(newData);
    }

    const handleCheckedChange = e => {
        let newData = JSON.parse(JSON.stringify(data));
        newData[e.target.name] = e.target.checked;
        setData(newData);
    }

    const handleObjChange = e => {
        let newData = JSON.parse(JSON.stringify(tmpObjdata));
        newData[e.target.name] = e.target.value;
        setTmpObjdata(newData);
    }

    const handleObjAdd = name => {
        let newData = JSON.parse(JSON.stringify(data));

        // If empty create a new object
        if(newData[name] === '' || newData[name] === null)
            newData[name] = {};
        else
            newData[name] = JSON.parse(newData[name]);

        // Add the new key value pair
        newData[name][tmpObjdata[`${name}_key`]] = tmpObjdata[`${name}_value`];

        // Stringify the object back down
        newData[name] = JSON.stringify(newData[name]);

        setData(newData);
    }

    const handleObjRemove = (name, key) => {
        console.log(name);
        console.log(key);

        let newData = JSON.parse(JSON.stringify(data));

        // If empty create a new object
        if(newData[name] === '' || newData[name] === null)
            newData[name] = {};
        else
            newData[name] = JSON.parse(newData[name]);

        // Rmeove the key value pair
        if(typeof newData[name][key] !== 'undefined')
            delete newData[name][key];

        // Stringify the object back down
        newData[name] = JSON.stringify(newData[name]);

        // Null the value if it is an empty object
        if(newData[name] === '{}')
            newData[name] = '';

        setData(newData);
    }

    const inputControl = col => {

        
        let selector = typeof selectors !== 'undefined' ? selectors.find(s => s.col_name == col.name) : null;
        if(typeof selector === 'undefined')
            selector = null;

        const type = (col) => {
            switch(col.type.toUpperCase()){
                case 'DATE':
                    return (
                        <TextField
                        type="date"
                        name={col.name}
                        label={col.display_name ? col.display_name : Helpers.cleanHeader(col.name)}
                        value={data[col.name]}
                        onChange={handleChange}
                        error={isInvalid(col.name)}
                        autoComplete="off"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        />);
                    break;
                case 'BOOLEAN':
                    return (
                    <FormControlLabel
                        control={
                          <Checkbox
                            checked={data[col.name]}
                            onChange={handleCheckedChange}
                            name={col.name}
                            color="primary"
                          />
                        }
                        error={isInvalid(col.name).toString()}
                        label={col.display_name ? col.display_name : Helpers.cleanHeader(col.name)}
                    />);
                    break;
                case 'INT':
                    return (
                        <TextField
                            name={col.name}
                            label={col.display_name ? col.display_name : Helpers.cleanHeader(col.name)}
                            value={data[col.name]}
                            type="number"
                            onChange={handleChange}
                            error={isInvalid(col.name)}
                            autoComplete="off"
                            />
                    )
                    break;
                case 'SELECT':
                    return (
                        <Select
                            name={col.name}
                            labelId={`id_${col.name}_label`}
                            label={col.display_name ? col.display_name : Helpers.cleanHeader(col.name)}
                            value={data[col.name]}
                            onChange={handleChange}                            
                        >
                            <MenuItem value={null}>-- Select {col.display_name ? col.display_name : Helpers.cleanHeader(col.name)}--</MenuItem>
                            {dataSelectors[col.name].map((item, idx) => (
                                <MenuItem key={idx} value={item[selector.selector_name]}>{item[selector.display_col]}</MenuItem>
                            ))}
                        </Select>
                    )
                    break;
                case 'OBJECT':
                    return (
                        <React.Fragment>

                            <Divider style={{marginTop: 20, marginBottom: 10}} />

                            <Typography variant="subtitle2">{col.display_name ? col.display_name : Helpers.cleanHeader(col.name)}</Typography>
                            
                            <div style={{display :'flex', flexDirection: 'row', width: '100%'}}>
                                {/* KEY SELECTION */}
                                
                                {selector !== null && 
                                <FormControl className={styles.input} style={{flex: 1}}>
                                    <InputLabel id={`id_${col.name}_key_label`}>Key</InputLabel>
                                    <Select
                                        name={`${col.name}_key`}
                                        labelId={`id_${col.name}_key_label`}
                                        label="Key"
                                        value={tmpObjdata[`${col.name}_key`]}
                                        onChange={handleObjChange}                            
                                    >
                                        <MenuItem value={null}>-- Select {col.display_name ? col.display_name : Helpers.cleanHeader(col.name)}--</MenuItem>
                                        {dataSelectors[col.name].map((item, idx) => (
                                            <MenuItem key={idx} value={item[selector.selector_name]}>{item[selector.display_col]}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>}

                                {selector === null && 
                                    <FormControl className={styles.input} style={{flex: 1}}>
                                        <TextField
                                            name={`${col.name}_key`}
                                            label="Key"
                                            value={tmpObjdata[`${col.name}_key`]}
                                            onChange={handleObjChange}
                                            autoComplete="off"
                                        />
                                    </FormControl>
                                }

                                {/* VALUE */}
                                <FormControl className={styles.input} style={{flex: 1}}>
                                    <TextField
                                        name={`${col.name}_value`}
                                        label="Value"
                                        value={tmpObjdata[`${col.name}_value`]}
                                        onChange={handleObjChange}
                                        autoComplete="off"
                                        />
                                </FormControl>
                            </div>

                            <div style={{display :'flex', flexDirection: 'row'}}>
                                <Button variant="contained" color="primary" onClick={() => handleObjAdd(col.name)}>Add</Button>
                            </div>
                            
                            <div className={styles.highlightedArea}>
                                {data[col.name].length === 0 && 
                                    <Typography variant="body2" color="textSecondary">No {col.display_name ? col.display_name : Helpers.cleanHeader(col.name)} added</Typography>
                                }
                                {data[col.name] !== '' && data[col.name] !== null && 
                                    <List dense style={{width: '100%'}}>
                                        {Object.keys(JSON.parse(data[col.name])).map((k, idx) => (
                                            <ListItem key={idx}>
                                                <ListItemText primary={`${k}: ${JSON.parse(data[col.name])[k]}`} />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" onClick={() => handleObjRemove(col.name, k)}><CloseIcon /></IconButton >
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                }
                            </div>
                            
                            {col.required && <FormHelperText>* required</FormHelperText>}


                        </React.Fragment>
                    )
                    break;
                default:
                    return (
                        <TextField
                            name={col.name}
                            label={col.display_name ? col.display_name : Helpers.cleanHeader(col.name)}
                            value={data[col.name]}
                            onChange={handleChange}
                            error={isInvalid(col.name)}
                            autoComplete="off"
                            />
                    )
            }
        }

        return (
            <React.Fragment key={col.name}>
                {col.type.toUpperCase() === 'OBJECT' && type(col)}

                {col.type.toUpperCase() !== 'OBJECT' && 
                    <FormControl className={styles.input} fullWidth>
                        {col.type.toUpperCase() === 'SELECT' && <InputLabel id={`id_${col.name}_label`}>{col.display_name ? col.display_name : Helpers.cleanHeader(col.name)}</InputLabel>}
                        {type(col)}
                        {col.required && <FormHelperText>* required</FormHelperText>}
                    </FormControl>
                }
            </React.Fragment>
        )
    }


    const isInvalid = name => {
        const idx = invalid.findIndex(i => i.name == name);
        return idx > -1;
    }

    return (
        <Dialog onClose={props.handleCancel} aria-labelledby="dirty-warning-dialog-title" open={true} maxWidth={'xs'} fullWidth>
            <DialogTitle>{typeof data[idCol] != 'undefined' ? 'Edit' : 'Add new'}</DialogTitle>
            <DialogContent>
                {columns.map(c => inputControl(c))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleUpdate} variant="contained" color="secondary" disabled={invalid.length > 0}>{typeof data[idCol] != 'undefined' ? 'Update' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
    )
}


const Crud = props => {

    const { uri, idCol, orderCol, displayColumns, selectors, readOnly, clickRow, canRemove } = props;

    const theme = useTheme();
    const styles = useStyles(theme);
    const context = useContext(GlobalContext);

    const [ data, setData ] = useState([]);
    const [ dataOrg, setDataOrg ] = useState([]);
    const [ dirtyData, setDirtyData ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ modifyObj, setModifyObj ] = useState(null);

    const [ dataSelectors, setDataSelectors ] = useState({});

    const [ showSave, setShowSave ] = useState(false);
    const [ showDiscard, setShowDiscard ] = useState(false);

    useEffect(() => {

        context.apiRequest(uri).then(
            res => {
                setData(res.result);
                setDataOrg(JSON.parse(JSON.stringify(res.result)));
                setLoading(false);
            },
            err => {
                setLoading(false);
                context.showAlert('error', err.msg);
            }
        )


        //
        //  Gather any selectors that are also appropriate
        //
        if(typeof selectors != 'undefined' && Array.isArray(selectors))
            fetchSelectors();

    }, [])


    const fetchSelectors = async () => {

        let newDataSelectors = {};

        for(let sIdx=0; sIdx< selectors.length; sIdx++){
            const s = selectors[sIdx];
            if(typeof s.data !== 'undefined')
                newDataSelectors[s.col_name] = s.data;

            if(typeof s.uri !== 'undefined')
                await context.apiRequest(s.uri).then(
                    res => {
                        newDataSelectors[s.col_name] = res.result;
                    },
                    err => {
                        context.showAlert('error', `Error loading ${s.name} selectors`);
                    }
                )
        }
        
        setDataSelectors(newDataSelectors);

    }


    

    //
    // Setup a blank object based on the display columns
    //
    const createBlank = () => {
        let blankData = {};
        displayColumns.filter(c => c.editable === true).map(col => {
            switch(col.type.toUpperCase()){
                case 'BOOLEAN':
                    blankData[col.name] = false
                    break;
                case 'INT':
                case 'SELECT':
                case 'DATE':
                    blankData[col.name] = null;
                    break;
                default:
                    blankData[col.name] = ''
            }
        })
        return JSON.parse(JSON.stringify(blankData));
    }




    const handleSave = () => {
        setLoading(true);
        context.apiRequest(uri, 'PUT', data).then(
            res => {
                setData(res.result);
                setDataOrg(JSON.parse(JSON.stringify(res.result)));
                setLoading(false);
                setShowSave(false);
                setDirtyData(false);
                context.showAlert('success', 'Settings saved!');
            },
            err => {
                setLoading(false);
                setShowSave(false);
                context.showAlert('error', err.msg);
            }
        )
    };

    const handleUpdate = (obj) => {
        // Get a copy of the data
        let newData = JSON.parse(JSON.stringify(data));

        const idx = newData.findIndex(d => d[idCol] == obj[idCol]);

        if(idx > -1 && obj[idCol] != null){

            // UPDATE OBJECT
            newData[idx] = obj;

        } else {

            // NEW OBJECT
            let newObj = {};
            // Get a copy of the last item in the data
            if(newData.length > 0){
                newObj = JSON.parse(JSON.stringify(newData[newData.length-1])); 

                // Get a list of the keys involved
                const keys = Object.keys(newObj);
                //  Loop through and either null the property or
                //  assign the property from the incomming obj
                keys.map(k => {
                    if(typeof obj[k] != 'undefined'){
                        console.log(obj[k]);
                        newObj[k] = obj[k];
                    }
                    else
                        newObj[k] = null;
                })
            } else {

                // If no records currently exist to copy from
                // send in the minimal as a first record to be added
                newObj = JSON.parse(JSON.stringify(obj))

            }
    
            // If there is an order by column then set it to the end of the list
            if(typeof orderCol != 'undefined')
                newObj[orderCol] = newData.length;
    
            // Add the new obj to the data array
            newData.push(newObj);

        }

        setData(newData);
        setModifyObj(null);
        setDirtyData(true);
    };


    const handleRemoveNew = idx => {
        // Get a copy of the data
        let newData = JSON.parse(JSON.stringify(data));
        newData.splice(idx, 1);
        setData(newData);
    }


    const handleRemoveExisting = idx => {
        // Get a copy of the data
        let newData = JSON.parse(JSON.stringify(data));
        newData.splice(idx, 1);
        setData(newData);
        setDirtyData(true);
    }

    const handleDiscard = () => {
        setData(JSON.parse(JSON.stringify(dataOrg)));
        setDirtyData(false);
        setShowDiscard(false);
    };

    const sortedData = () => {
        if(typeof orderCol != 'undefined')
            return Helpers.multiSort(data, [{orderBy: orderCol, order: 'asc'}, {orderBy: idCol, order: 'asc'}])
        else
            return data;
    }

    const onDragEnd = result => {
        const { destination, source, draggableId } = result;

        if(!destination)
            return;

        if(destination.droppableId === source.droppableId && destination.index === source.index)
            return;

        const up = destination.index < source.index ? true : false;

        let newData = JSON.parse(JSON.stringify(sortedData()));
        newData.map((d, idx) => {
            switch(true){
                case idx === source.index:
                    d[orderCol] = destination.index;
                    break;
                case idx === destination.index:
                    d[orderCol] = up ? idx+1 : idx-1;
                    break;
                case up && idx > destination.index:
                    d[orderCol] = idx+1;
                    break;
                case !up && idx < destination.index:
                    d[orderCol] = idx != 0 ? idx-1 : idx;
                    break;
                default:
                    d[orderCol] = idx;
            }
        })
        setData(newData);
        setDirtyData(true);
    }


    const formattedValue = (value, type, name) => {
        if(value === null)
            return value;

        switch(type.toUpperCase()){
            case 'BOOLEAN':
                return typeof value === 'string' ? Boolean(Number(value)).toString() : Boolean(value).toString();
                break;
            case 'SELECT':
                if(typeof dataSelectors[name] != 'undefined'){
                    const selector = selectors.find(s => s.col_name == name);
                    const selected = dataSelectors[name].find(s => s[selector.selector_name] == value);
                    return selected[selector.display_col];
                }
                else
                    return value;
                break;
            case 'DATE':
                return Helpers.SQLtoUTCDate(value, false);
                break;
            case 'DATETIME':
                return Helpers.SQLtoUTCDate(value, true);
                break;
            default:
                return value;
        }
    }


    const handleRowClick = row => {
        if(typeof clickRow != 'undefined'){
            const uid_idx = clickRow.indexOf('!');
            var route = clickRow;
            if(uid_idx > -1){
                let uid = route.substr(route.length - (route.length - (uid_idx + 1)));
                route = route.replace(`!${uid}`, row[uid]);
            }
            props.history.push(route);
        }
    }

    const showEditControls = (typeof readOnly == 'undefined' || readOnly === false);

    const isClickableRow = (typeof clickRow != 'undefined');

    const columns = typeof displayColumns != 'undefined' ? displayColumns : Object.keys(data[0]);


    return (

        <Grid container>
            <Grid item xs={12} sm={8}>
                
                {loading && <CircularProgress />}

                {!loading && 
                <div>

                    {dirtyData && <Typography color="error" variant="body2" style={{marginBottom: 15}}>Save your changes to make them live!</Typography>}

                    <TableContainer component={Paper}>
                        <Table aria-label="data table">
                        <TableHead>
                            <TableRow>
                                {orderCol && <TableCell style={{width: 40}}></TableCell>}
                                {columns.map((c, idx) => (
                                    <TableCell key={idx}>{c.display_name ? c.display_name : Helpers.cleanHeader(c.name)}</TableCell>
                                ))}
                                {showEditControls && <TableCell align="right" >Action</TableCell>}
                            </TableRow>
                        </TableHead>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId={'col-sort'}>
                                {provided => (
                                    <TableBody
                                        innerRef={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{flex: 1}}>
                                            {sortedData().map((row, ridx) => (
                                                <Draggable key={ridx} draggableId={ridx.toString()} index={ridx}>
                                                {provided=>(
                                                    <TableRow
                                                        {...provided.draggableProps}
                                                        innerRef={provided.innerRef} 
                                                        role={undefined} 
                                                        hover={isClickableRow}
                                                        onClick={() => handleRowClick(row)}
                                                    >
                                                        {orderCol && <TableCell {...provided.dragHandleProps} style={{width: 40}} padding='none'><Tooltip title="Change order"><DragIndicatorIcon /></Tooltip></TableCell>}
                                                        {columns.map((c, idx) => (
                                                            <TableCell key={idx}>{formattedValue(row[c.name], c.type, c.name)}</TableCell>
                                                        ))}
                                                        {showEditControls && <TableCell align="right" padding='none'>
                                                            {row[idCol] && <IconButton color="primary" onClick={() => setModifyObj(row)}><Tooltip title="Edit"><EditIcon /></Tooltip></IconButton>}
                                                            {row[idCol] == null && <IconButton color="primary" onClick={() => handleRemoveNew(ridx)}><Tooltip title="Remove"><CloseIcon /></Tooltip></IconButton>}
                                                            {(row[idCol] != null && canRemove) && <IconButton color="primary" onClick={() => handleRemoveExisting(ridx)}><Tooltip title="Remove"><CloseIcon /></Tooltip></IconButton>}
                                                        </TableCell>}
                                                    </TableRow>
                                                )}
                                                </Draggable>
                                            ))}
                                    {provided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </DragDropContext>
                        </Table>
                    </TableContainer>

                    {showEditControls && <div className={styles.flexSpaced}>
                        <div>
                            <Button variant="contained" color="primary" onClick={() => setModifyObj(createBlank())}>Add new</Button>
                        </div>
                        <div className={styles.flexRight}>
                            {dirtyData && <Button color="default" onClick={() => setShowDiscard(true)}>Discard</Button>}
                            <Button variant="contained" color="secondary" onClick={() => setShowSave(true)} disabled={!dirtyData}>Save</Button>
                        </div>
                    </div>}

                </div>
                }

            </Grid>


            {showDiscard && <DiscardDialog handleCancel={() => setShowDiscard(false)} handleConfirm={handleDiscard} />}
            {modifyObj != null && <UpdateDialog handleCancel={() => setModifyObj(null)} handleUpdate={handleUpdate} displayColumns={displayColumns} obj={modifyObj} idCol={idCol} dataSelectors={dataSelectors} selectors={selectors} />}
            {showSave && <SaveDialog handleCancel={() => setShowSave(false)} handleConfirm={handleSave} />}
        </Grid>
    )

}

export default withRouter(Crud)
