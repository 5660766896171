import React from 'react';
import GlobalContext from '../../context/global-context';
import Helpers from '../global/helpers';
import querystring from 'query-string';
import {
    Table, 
    TableBody, 
    TableCell, 
    TablePagination, 
    TableRow, 
    TextField, 
    Typography, 
    Checkbox, 
    Button, 
    Paper, 
    TableContainer, 
    TableHead, 
    TableSortLabel, 
    InputAdornment,
    IconButton,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Tooltip,
    Divider,
    Chip,
    Link
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close'
import TocIcon from '@material-ui/icons/Toc'
import ArrowDownIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpIcon from '@material-ui/icons/ArrowUpward'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { withTheme } from '@material-ui/core/styles';

import Refresh from '../global/Refresh'

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import theme from '../../theme';

//
// The headers for the table with sorting
//

const headCells = ['id_charge_point', 'id_group_scheme', 'group_scheme_name', 'site_name', 'address_line_1', 'town', 'post_code', 'country']


const EnhancedTableHead = props => {
    const { order, orderBy, onRequestSort, headers, cleanHeader } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    const getOrderBy = property => {
        const idx = orderBy.findIndex(o => o.orderBy === property);
        if(idx > -1)
            return orderBy[idx].order;
        
        return false;
    }
  
    return (
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell
              key={header}
              align={'left'}
              sortDirection={getOrderBy(header)}
            >
              <TableSortLabel
                active={getOrderBy(header) !== false}
                direction={getOrderBy(header) || 'asc'}
                onClick={createSortHandler(header)}
              >
                {cleanHeader(header)}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  
  const OrderChips = props => {

    const { orderBy, handleDelete, handleDeleteAll, cleanHeader } = props;

    return (
      <div style={{paddingBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Typography variant="body2">Sort: </Typography>
        {orderBy.map(o => (
            <Chip
                key={o.orderBy}
                size="small"
                icon={o.order == 'desc' ? <ArrowDownIcon /> : <ArrowUpIcon />}
                label={cleanHeader(o.orderBy)}
                style={{marginLeft: 5, marginRight: 5}}
                onDelete={() => handleDelete(o.orderBy)}
            />
        ))}
        {orderBy.length > 1 && <Button color="secondary" onClick={handleDeleteAll}>Clear</Button>}
      </div>
    );
}
  
  
const FilterChip = props => {

  const { handleDelete, cleanHeader, filter } = props;

  return (
    <div style={{paddingBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Typography variant="body1">Filter: </Typography>
        <Chip
            size="medium"
            label={`${cleanHeader(filter.col)}: ${filter.val}`}
            style={{marginLeft: 5, marginRight: 5}}
            onDelete={() => handleDelete()}
            color="primary"
        />
    </div>
  );
}




const ColumnDialog = props => {
    const { columns, display, handleColumnClick, open, handleClose, cleanHeader, handleDisplayOrder, handleReset } = props;

    const onDragEnd = result => {
        const { destination, source, draggableId } = result;

        if(!destination)
            return;

        if(destination.droppableId === source.droppableId && destination.index === source.index)
            return;

        handleDisplayOrder(source.index, destination.index, draggableId)
    }
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="column-dialog" open={open}>
      
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <DialogTitle id="column-dialog-title">Show / Hide columns</DialogTitle>
            <Button color={"secondary"} onClick={handleReset}>Reset</Button>
        </div>
        
        <DragDropContext onDragEnd={onDragEnd}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'top'}}>
            
                <List 
                    style={{flex: 1}}
                    subheader={
                        <ListSubheader component="div" id="column-list-subheader">Columns</ListSubheader>
                    }
                >
                {columns.sort().map(value => (
                    <ListItem key={value} role={undefined} dense button onClick={() => handleColumnClick(value)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={display.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText id={value} primary={cleanHeader(value)} />
                    </ListItem>
                ))}
                </List>
                
                <Droppable droppableId={'col-sort'}>
                    {provided => (
                        <List 
                        innerRef={provided.innerRef}
                        {...provided.droppableProps}
                        style={{flex: 1}}
                        subheader={
                            <ListSubheader component="div" id="column-list-subheader">Order</ListSubheader>
                        }>
                            {display.map((value, idx) => (
                                <Draggable key={value} draggableId={value} index={idx}>
                                    {provided=>(
                                        <ListItem 
                                            {...provided.draggableProps}
                                            innerRef={provided.innerRef} 
                                            role={undefined} 
                                            dense >
                                            <ListItemIcon {...provided.dragHandleProps}><Tooltip title="Drag"><DragIndicatorIcon /></Tooltip></ListItemIcon>
                                            <ListItemText id={value} primary={cleanHeader(value)} />
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            
            </div>
        </DragDropContext>
      </Dialog>
    );
  }
  


// VeosNetwork class


class VeosNetwork extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);
        const qs = querystring.parse(this.props.location.search);

        this.state = {
            columns: [],
            data: [],
            display: this.getDisplayColumns(),
            filteredData: [],
            loading: false,
            table: {
                page: typeof qs.page != 'undefined'?parseInt(qs.page):0,
                rowsPerPage: this.getRowsPerPage(),
                order: typeof qs.order != 'undefined'?qs.order:'desc',
                orderBy: typeof qs.order_by != 'undefined'?Helpers.basetoObj(qs.order_by, []):[
                    {
                        order: 'desc',
                        orderBy: 'id_charge_point'
                    }
                ]
            },
            search: typeof qs.search != 'undefined'?qs.search:'',
            showColumnDialog: false,
            downloading: false,
            filter: typeof qs.filter != 'undefined'?Helpers.basetoObj(qs.filter,{}):null
        };
    }


    componentDidMount(){
        this.getNetwork();
    }


    getNetwork = () => {
        this.setState({
            loading: true
        }, () => {
            this.context.apiRequest('/points/network/veos') .then(
                res => {
                    const network = res.result;

                    this.setState({
                        columns: Object.keys(res.result[0]),
                        data: network,
                        filteredData: this.checkFilter(network),
                        loading: false
                    }, () => {
                        if(this.state.search.length > 0 && this.state.search != '')
                            this.search();
                    })
                },
                err => {
                    this.setState({
                        loading: false
                    })
                    this.context.showAlert('error', `Error loading network: ${err.msg}`);
                }
            )
        })
    }

    checkFilter = (data) => {
        let filteredData = JSON.parse(JSON.stringify(data));

        if(this.state.filter === null)
            return filteredData;

        return filteredData.filter(x => x[this.state.filter.col] === this.state.filter.val);
    }

    

    getDisplayColumns = () => {    
        var display = null;

        if(display != null){
            try {
                return JSON.parse(display);
            } catch (error) { 
                return [...headCells];
            }
        } else {
            display = localStorage.getItem('veos_net_col');

            if(display != null){
                try {
                    return JSON.parse(display);
                } catch (error) { 
                    return [...headCells];
                }
            } else {
                return [...headCells];
            }
        }
    }

    getRowsPerPage = () => {    
        const localRows = localStorage.getItem('rows');
        if(localRows !== null && !isNaN(JSON.parse(parseInt(localRows))))
            return JSON.parse(parseInt(localRows));
        else
            return 25
    }

    handleTabChange = (e, val) => {
        this.setState({
            tab: val,
            table: {
                ...this.state.table,
                page: 0
            }
        }, this.handleClearSearch)
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            table: {
                ...this.state.table,
                page: newPage
            }
        }, this.handleQueryChange);
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            table: {
                ...this.state.table,
                page: 0,
                rowsPerPage: parseInt(event.target.value, 10)
            }
        }, () => {
            localStorage.setItem('rows', parseInt(event.target.value, 10));
            this.handleQueryChange()
        });
    };

    handleRequestSort = (event, property) => {

        var newOrder = JSON.parse(JSON.stringify(this.state.table.orderBy));

        const idx = newOrder.findIndex(o => o.orderBy === property);
        if(idx === -1){
            newOrder.push({
                orderBy: property,
                order: 'desc'
            })
        } else {
            const isAsc = newOrder[idx].order === 'asc';
            if(isAsc){
                // Remove from order
                //newOrder.splice(idx, 1);
                newOrder[idx].order = 'desc'
            } else {
                newOrder[idx].order = 'asc'
            }
        }
        
        this.setState({
            table: {
                ...this.state.table,
                orderBy: newOrder
            }
        }, this.handleQueryChange);
    };

    handleDeleteSort = (property) => {

        var newOrder = JSON.parse(JSON.stringify(this.state.table.orderBy));

        const idx = newOrder.findIndex(o => o.orderBy === property);
        if(idx > -1){
            newOrder.splice(idx, 1);
        }
        
        this.setState({
            table: {
                ...this.state.table,
                orderBy: newOrder
            }
        });

    };

    handleDeleteAllSort = (property) => {

        this.setState({
            table: {
                ...this.state.table,
                orderBy: []
            }
        });

    };

    handleSearchChange = e => {
        this.setState({
            search: e.target.value
        })
    }

    handleSearchKeyPress = e => {
        if(e.key == 'Enter')
            this.search();
    }

    handleClearSearch = () => {
        this.setState({
            search: '',
            filteredData: this.checkFilter(this.state.data)
        }, this.handleQueryChange)
    }

    handleQueryChange = () => {

        let query = {};
        if(this.state.search != '')
            query.search = this.state.search;

        if(typeof query.search == 'undefined' && this.state.tab > 0)
            query.tab = this.state.tab;
        
        if(this.state.table.page != 0)
            query.page = this.state.table.page;
        
        if(this.state.table.order != 'desc')
            query.order = this.state.table.order;

        if(this.state.table.orderBy != 'id_order')
            query.order_by = btoa(JSON.stringify(this.state.table.orderBy));

        if(this.state.filter != null)
            query.filter = btoa(JSON.stringify(this.state.filter));
        
        this.props.history.replace('?'+Helpers.makeQueryString(query));

    }


        
    search = () => {
        this.handleQueryChange();

        // Search can be called on load if there is a query string with the search string in

        let data = this.checkFilter(this.state.data);
        const searchStr = this.state.search.toUpperCase();

        if(this.state.search.length > 0 && this.state.search != ''){
            data = this.checkFilter(this.state.data).filter(p => (
                this.isNull(p.id_charge_point).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.id_group_scheme).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.group_scheme_name).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.operational_status).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.site_name).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.address_line_1).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.address_line_2).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.address_line_3).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.town).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.county).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.post_code).toUpperCase().indexOf(searchStr) > -1 ||
                this.isNull(p.country).toUpperCase().indexOf(searchStr) > -1
            ));
        }

        // Reset the tab to 0 to ensure it is a global search and not per tab
        this.setState({
            filteredData: data,
            tab: 0
        }, () => {

            // If a page number exists in the query string and that page doesn't exist in the results return to page 0
            if(this.state.filteredData.length <= (this.state.table.rowsPerPage * this.state.table.page))
                this.setState({
                    table: {
                        ...this.state.table,
                        page: 0
                    }
                }, this.handleQueryChange)
        });
    }

    isNull = (str) => {
        if(typeof str == 'undefined' || str == null)
            return ''
        
        return str.toString();
    }
    

    render(){

        const rows = this.state.filteredData;
        const page = this.state.table.page;
        const rowsPerPage = this.state.table.rowsPerPage;
        const orderBy = this.state.table.orderBy;
        const order = this.state.table.order;    


        const handleColumnClick = val => {
            const idx = this.state.display.indexOf(val);
            let display = [...this.state.display];
            if(idx > -1)
                display.splice(idx, 1)
            else
                display.push(val)
            
            this.setState({display: display}, () => {
                saveDisplayColumns(display);
            })
        }

        const handleDisplayOrder = (src, dest, val) => {
            const display = Array.from(this.state.display);
            display.splice(src, 1);
            display.splice(dest, 0, val);
            this.setState({display: display}, () => {
                saveDisplayColumns(display);
            });
        }

        const resetDisplayColumns = () => {
            this.setState({display: [...headCells]}, () => {
                saveDisplayColumns(headCells);
            });
        }

        const saveDisplayColumns = (display) => {
            let name = 'veos_net_col';
            localStorage.setItem(name, JSON.stringify(display));
        }

        const handleShowColumnDialog = () => {
            this.setState({showColumnDialog: true});
        }

        const handleSetFilter = (col, val) => {
            this.setState(
            {
                filter: {
                    col: col,
                    val: val
                }
            }, () => {
                this.handleQueryChange();
                const filteredData = this.checkFilter(this.state.data);
                this.setState({filteredData: filteredData});
            })
        }

        const handleDeleteFilter = (col, val) => {
            this.setState({filter: null}, () => {
                this.handleQueryChange();
                const filteredData = this.checkFilter(this.state.data);
                if(this.state.search.length > 0)
                    this.search();
                else
                    this.setState({filteredData: filteredData});
            })
        }

        const sanitizeValue = (val, col) => {

            switch(col){
                case 'id_group_scheme':
                    return <Link href='#' style={{color: theme.palette.text.primary}} onClick={(e) => {e.preventDefault(); handleSetFilter(col, val)}}>{'#'+val}</Link>;
                    break;
                case 'group_scheme_name':
                case 'site_name':
                    return <Link href='#' style={{color: theme.palette.text.primary}} onClick={(e) => {e.preventDefault(); handleSetFilter(col, val)}}>{val}</Link>;
                    break;
                // case 'order_value':
                // case 'outstanding_balance':
                //     return val != null?'£'+val.toLocaleString('en-GB'):'';
                //     break;
                // case 'teckpro_order':
                //     return val === 1?'YES':'NO';
                //     break;
            }

            if(val != null && val.toString().match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/))
                return Helpers.SQLtoUTCDate(val, true);

            return Helpers.isNull(val, '');
        }

        

        // Makes the columns name more presentable
        const cleanHeader = header => {
            let newHeader = header;
            if(header.indexOf('id_') > -1)
                newHeader = newHeader.replace('id_', '') + ' ID';

            newHeader = newHeader.replace(/_/gi, ' ');
            return newHeader.charAt(0).toUpperCase() + newHeader.slice(1)
        }

        return(
            <div style={styles.container}>
                <div style={styles.inner}>

                    <div style={styles.taskbar}>

                        <Typography variant="h4">VendElectric Network</Typography>

                        <div style={{display:'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end'}}>
                            <Refresh handleClick={this.getOrders} loading={this.state.loading} />
                        </div>
                    </div>
                    
                    <div style={styles.taskbar}>
                        <div style={{display: 'flex', flex: 1}}>
                            <TextField 
                                name={'Search'} 
                                type="text" 
                                value={this.state.search} 
                                onChange={this.handleSearchChange} 
                                InputLabelProps={{shrink: true,}} 
                                label="Search Network" 
                                variant="outlined" 
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {this.state.search.length != '' && <IconButton onClick={this.handleClearSearch}><CloseIcon color="secondary" /></IconButton>}
                                      </InputAdornment>
                                    ),
                                }}
                                onKeyPress={this.handleSearchKeyPress} />
                        </div>
                        <div style={{width: 20}}></div>
                        <div>
                            <Button variant="contained" color={'secondary'} onClick={this.search} style={{marginRight: 10}}>Search</Button>
                            
                            <Tooltip title="Select Columns" aria-label="select-columns"><IconButton variant="contained" color="secondary" onClick={handleShowColumnDialog}><TocIcon /></IconButton></Tooltip>
                        </div>
                    </div>


                    <div className="flex-row align-center gap-15">
                    {this.state.filter !== null && <FilterChip handleDelete={handleDeleteFilter}  cleanHeader={cleanHeader} filter={this.state.filter} />}

                    {orderBy.length > 0 && <OrderChips orderBy={orderBy} handleDelete={this.handleDeleteSort} handleDeleteAll={this.handleDeleteAllSort} cleanHeader={cleanHeader} />}
                    </div>

                    <Paper style={styles.root}>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        <Divider />
                        <TableContainer>
                            <Table>
                                
                                <EnhancedTableHead
                                    headers={this.state.display}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={rows.length}
                                    cleanHeader={cleanHeader}
                                />
                                <TableBody>
                                {Helpers.multiSort(rows, orderBy).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                                    <TableRow key={row.id_order}>
                                        {this.state.display.map(x => (
                                            <TableCell>{sanitizeValue(row[x], x)}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>

                </div>

                <ColumnDialog 
                    columns={this.state.columns}
                    display={this.state.display}
                    open={this.state.showColumnDialog}
                    cleanHeader={cleanHeader}
                    handleColumnClick={handleColumnClick}
                    handleClose={() => this.setState({showColumnDialog: false})}
                    handleDisplayOrder={handleDisplayOrder}
                    handleReset={resetDisplayColumns}
                />
            </div>
        );
    }

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    },
    subheading: {
        display: 'block',
        fontSize: '.6em',
        fontWeight: 600,
        color: '#727272'
    }
}

export default withTheme(VeosNetwork)