import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';

import { 
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    DialogContentText
} from '@material-ui/core';


const StartSubscription = props => {

    // Create a local state
    const [start_date, setStartDate] = React.useState(new Date().toISOString());

    // Use the global context
    const context = useContext(GlobalContext);

    const handleChange = e => {
        setStartDate(e.target.value);
    }

    const handleUpdateStartDate = () => {
        const params = {
            selected: props.selected,
            start_date: start_date
        }
        context.updateSubscriptionsAs(params, 'subscribed').then(
            res => {
                context.showAlert('success', 'Subscriptions started');

                if(typeof props.success == 'function')
                    props.success()
            },
            err => {
                context.showAlert('error', 'Error starting subscriptions ' + JSON.stringify(err))
            }
        )
    }

    const handleActionCancel = () => {
        setStartDate(new Date().toISOString());
        if(typeof props.close == 'function')
            props.close()
    }

        
    return (

        <Dialog open={props.show} onClose={props.close} aria-labelledby="start-date-dialog-title">
            <DialogContent>
                <TextField
                    type="date"
                    autoFocus
                    margin="dense"
                    name="start_date"
                    value={start_date}
                    onChange={handleChange}
                    label="Subscription Starts"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleActionCancel} color="default">
                    Cancel
                </Button>
                <Button onClick={handleUpdateStartDate} color="secondary">
                    Start
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default StartSubscription