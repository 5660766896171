export function backgroundLoadMeta (payload) {

    return dispatch => {
        
        dispatch({
            type: 'BACKGROUND_META',
            payload: payload,
            status: 'ok'
        })

    }

}