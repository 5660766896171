import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import Helpers from '../global/helpers';
import { FixedSizeList } from 'react-window';
import { useStyles } from './styles';

import { 
    List,
    ListItem,
    ListItemText,
    TextField,
    Popover,
    Typography
} from '@material-ui/core';


var timeout = null;


const TicketSelectGroupScheme = props => {

    const theme = useTheme();
    const styles = useStyles(theme);

    const [filter, setFilter] = useState('');
    const [openPopover, setOpenPopover] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const searchGroupSchemes = useRef(null);

    const handleFilterChange = e => {
        const filterStr = e.target.value;
        setFilter(filterStr);

        if(timeout != null)
            clearTimeout(timeout);

        timeout = setTimeout(() => {
            filterData(filterStr);
        }, 750)
    }

    const handleAdd = gs => {
        props.handleAdd({
            id_ticket: props.ticket.id_ticket,
            id_group_scheme: gs.GroupSchemeID
        })
    }

    const filterData = (filterStr) => {

        setOpenPopover(true);

        let result = [];

        if(filterStr.length !== 0 && filterStr != ''){
            props.group_schemes.map(d => {
                const concatStr = `${d.GroupSchemeID.toString()}${d.GroupSchemeName.toUpperCase()}`;
                const idx = concatStr.indexOf(filterStr.toUpperCase());
                if(idx > -1)
                    result.push({...d, idx: idx});
            })            
        } else {
            setOpenPopover(false);
        }

        setFilteredData(Helpers.multiSort(result, [{orderBy: 'idx', order: 'asc'}, {orderBy: 'GroupSchemeName', order: 'asc'}]));
            
    }

    

    const DataRow = props => {
        const { index, style, data } = props;
        const gs = data[index];

        return (
            <ListItem key={index} button onClick={() => handleAdd(gs)} style={style}>
                <ListItemText primary={`#${gs.GroupSchemeID} ${gs.GroupSchemeName}`} />
            </ListItem>
        );
    }
    

    return (
        <div>

            <TextField 
                className={styles.inputField} 
                ref={searchGroupSchemes} 
                variant="outlined" 
                fullWidth 
                name="filter" 
                label="Search Group Schemes" 
                value={filter} 
                onChange={handleFilterChange} 
                autoComplete="off"
                size="small" />

            <Popover
                id='group_schemes_results_popover'
                open={openPopover}
                anchorEl={searchGroupSchemes.current}
                onClose={() => setOpenPopover(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableAutoFocus
                disableEnforceFocus
            >
                
                {filteredData.length == 0 && <div style={{padding: 20}}><Typography>No group scheme found</Typography></div>}
                <FixedSizeList height={180} width={400} itemSize={60} itemCount={filteredData.length} itemData={filteredData}>
                    {DataRow}
                </FixedSizeList>
            </Popover>

        </div>
    )

}

export default TicketSelectGroupScheme