import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { CircularProgress, Typography, Button, IconButton } from '@material-ui/core';
import Quickcrumbs from '../../global/Quickcrumbs';
import globalContext from '../../../context/global-context';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import Prompt from '../../../components/prompt';


const FirmwareInfo = props => {

    const id = props.match.params.id;

    const theme = useTheme();
    const styles = useStyles(theme);
    const context = useContext(globalContext);

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingAutoApply, setLoadingAutoApply] = useState(false);

    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {

        context.apiRequest(`firmware/${id}`).then(
            res => {
                setData(res.result);
                setLoading(false);
            },
            err => {
                setLoading(false);
                context.showAlert('error', err.msg);
            }
        )

    }, [])


    const handleDelete = async () => {

        setLoading(true);
        
        context.apiRequest(`firmware/${id}`, 'DELETE').then(
            res => {
                setLoading(false);
                context.showAlert('success', `Firmware version ${data.version} deleted.`);
                props.history.push('/settings/firmware');
            },
            err => {
                setLoading(false);
                context.showAlert('error', err.msg);
            }
        )

    }


    const handleToggleAutoApply = async () => {

        setLoadingAutoApply(true);
        const newVal = data.auto_apply ? 0 : 1;
        context.apiRequest(`firmware/autoapply/${id}/${newVal}`, 'PUT').then(
            res => {
                setLoadingAutoApply(false);
                context.showAlert('success', `Firmware version ${data.version} updated.`);
                const newData = JSON.parse(JSON.stringify(data));
                newData.auto_apply = Boolean(newVal);
                setData(newData);
            },
            err => {
                setLoadingAutoApply(false);
                context.showAlert('error', err.msg);
            }
        )

    }


    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(data.download_url);
        context.showAlert('success', 'Copied to clipboard');
    }

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Firmware Updates',
                            route: '/settings/firmware'
                        },
                        {
                            name: `Firmware`,
                            route: `/settings/firmware/${id}`
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">OCPP Firmware</Typography>

                </div>

                {loading && <CircularProgress />}

                {(!loading && data !== null) && 
                    <React.Fragment>
                        <div className={styles.wrapper}>

                            <Typography variant="body">Version:</Typography>
                            <Typography variant="h5" className="pb">{data.version}</Typography>

                            <Typography variant="body">Applies to:</Typography>
                            <Typography variant="h6" className="pb">{data.vendor} {data.model === null ? 'All Models' : data.model}</Typography>

                            <Typography variant="body">Min compatible version:</Typography>
                            <Typography variant="h6" className="pb">{data.from_version_min === '0' ? 'Any' : data.from_version_min}</Typography>
                            <Typography variant="body">Max compatible version:</Typography>
                            <Typography variant="h6" className="pb">{data.from_version_max === '0' ? 'Any' : data.from_version_max}</Typography>

                            <Typography variant="h6" style={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center'}}>Firmware location<IconButton size="small" color="secondary" onClick={handleCopyToClipboard}><FilterNoneIcon /></IconButton><a href={data.download_url} style={{color: theme.palette.secondary.main, lineHeight: '1rem'}}><CloudDownloadIcon /></a></Typography>
                            <Typography variant="body" className="pb">{data.download_url}</Typography>
                            

                            <Typography variant="body">Delivery Method:</Typography>
                            <Typography variant="h6" className="pb">{data.delivery_by.toUpperCase()}</Typography>

                            <Typography variant="h6">Notes:</Typography>
                            <Typography variant="body" className="pb">{data.notes}</Typography>


                            <Typography variant="body">Auto update:</Typography>
                            <Typography variant="h6" className="pb">{data.auto_apply} {Boolean(data.auto_apply)?'Yes':'No'}{!loadingAutoApply && <IconButton onClick={handleToggleAutoApply}>{Boolean(data.auto_apply)?<ToggleOnIcon color="secondary" />:<ToggleOffIcon color="textSecondary" />}</IconButton>}{loadingAutoApply && <CircularProgress size={18} style={{marginLeft: 10}} />}</Typography>

                            <div>
                                <Button variant="contained" color="primary" onClick={() => setConfirmDelete(true)}><DeleteIcon />Delete</Button>
                            </div>

                        </div>

                        {confirmDelete && <Prompt show={true} title={`Confirm delete version ${data.version} for ${data.vendor} ${data.model === null ? 'All Models' : data.model}?`} message={`Doing so will stop any charge points being updated that haven't already and remove the firmware version from the system.`} onClose={() => setConfirmDelete(false)} onConfirm={handleDelete} />}
                    </React.Fragment>
                }

                {(!loading && data === null) && 
                    <Typography variant="h6">Firmware not found</Typography>
                }

            </div>

        </div>
    )

}

export default FirmwareInfo
