import React, {useContext, useEffect, useState} from 'react';
import { useTheme, Typography, makeStyles, Tooltip, Popover, IconButton, Collapse } from '@material-ui/core';
import GlobalContext from '../../context/global-context';

import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EvStationIcon from '@material-ui/icons/EvStation';
import SubscriptionSocket from './subscription_socket';

const useStyles = makeStyles(theme => ({
    warningIcon: {
        backgroundColor: amber[700]
    },
    suspendedIcon: {
        backgroundColor: red[900]
    },
    socket: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        padding: 6,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.action.hover}`,
        borderRadius: 4,
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            border: `1px solid ${theme.palette.action.active}`,
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(3),
    },
}));

const SubscriptionSocketGroup = props => {

    const context = useContext(GlobalContext)
    const theme = useTheme();
    const classes = useStyles(theme);
    const [summary, setSummary] = useState(null);
    const {group} = props;

    const [showSockets, setShowSockets] = useState(false);

    const getIcon = () => {
        return <EvStationIcon style={{color: theme.palette.primary.main}} />;
    }

    useEffect(() => {

        let socket_ids = [];
        let kilowatts = [];
        let connectors = 0;
        let rfid = null;
        let connectivity_type = [];
        group.sockets.map(s => {
            socket_ids.push(s.socket_id);
            if(kilowatts.indexOf(s.kilowatts) === -1)
                kilowatts.push(s.kilowatts);
            connectors += +(s.connectors);
            if(s.rfid !== null && Boolean(s.rfid) === true)
                rfid = 'RFID';
            if(s.connectivity_type !== null){
                const con_type = s.connectivity_type.toUpperCase() === 'GPRS' ? 'GPRS' : 'Ethernet';
                if(connectivity_type.indexOf(con_type) === -1)
                    connectivity_type.push(con_type);
            }
        })

        setSummary({
            sockets: socket_ids.join(" / "),
            kilowatts: kilowatts.join(" / "),
            connectors: connectors,
            rfid: rfid,
            connectivity_type: connectivity_type.join(" / ")
        });

    }, [group])


    return (
        <React.Fragment>
            <div className="flex-col gap-10">
                <div className={classes.socket} onClick={() => setShowSockets(!showSockets)}>
                    {getIcon()}
                    {summary !== null && 
                        <React.Fragment>
                            <Typography variant="subtitle2">{summary.sockets}</Typography>
                            <Typography variant="body2">{summary.kilowatts && `${summary.kilowatts}kWh`}</Typography>
                            <Typography variant="body2">{summary.connectors && `x${summary.connectors}`}</Typography>
                            <Typography variant="body2">{summary.rfid !== null && summary.rfid}</Typography>
                            <Typography variant="body2">{summary.connectivity_type && summary.connectivity_type}</Typography>
                            <div className="flex"></div>
                            {showSockets ? <ExpandLessIcon style={{fontSize: 18}} /> : <ExpandIcon style={{fontSize: 18}} />}
                        </React.Fragment>
                    }
                </div>
                <Collapse in={showSockets}>
                    <div className="flex-col">
                        {group.sockets.map((s, idx) => (
                            <SubscriptionSocket key={idx} onClick={() => props.setUpdateSocket(s)} socket={s} isSuspended={props.isSuspended} isIncomplete={props.isIncomplete} handleDelete={() => props.handleDelete(group.id_unit_group)} id_subscription={props.id_subscription} />
                        ))}
                    </div>
                </Collapse>
            </div>
        </React.Fragment>
    )
}
export default SubscriptionSocketGroup;