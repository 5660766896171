import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { userNotified } from '../../actions/userActions';
import { withRouter } from 'react-router-dom';

import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core';
import Helpers from '../global/helpers'


import { Popover, Card, CardActionArea, CardActions, CardContent, Badge, Button, Typography } from '@material-ui/core';

const Notifications = props => {

    const { show, close, anchor } = props;

    const theme = useTheme()
    const context = useContext(GlobalContext);

    const userNotified = payload => {
        context.userNotificationRead(payload.id_user_notification).then(
            res => {
                props.userNotified(payload)
            },
            err => {
                console.log(err);
            }
        )
    }

    const notificationsHeight = () => {
        const max = 600;
        let height = 100;
        height = height + (props.notifications.length * 100);
        if(height < 600)
            return height;

        return max;
    }

    const unreadNotifications = Helpers.multiSort(props.notifications.filter(n => n.was_read == null), [{orderBy: 'created', order: 'desc'}])
    const readNotifications = Helpers.multiSort(props.notifications.filter(n => n.was_read != null), [{orderBy: 'created', order: 'asc'}])

    const route = notification => {

        if(notification.was_read === null)
            userNotified(notification);

        let url = null;

        switch(parseInt(notification.id_notification_type)){
            case 1:
                url = `/tickets/monitor/${notification.notification_route}`;
                break;
        }

        if(url === null)
            context.showAlert('error', 'This notification doesn\'t have a supported url');
        else
            props.history.push(url);
    } 


    const Notification = props => {
        const {n} = props

        return (<Card variant="outlined" style={{opacity: n.was_read == null ? 1 : .6, marginBottom: 20}}>
            <CardActionArea onClick={() => route(n)}>
                <CardContent>
                    <Typography style={{fontSize: 16}} color="textSecondary">{n.notification_title}</Typography>
                    <Typography style={{fontSize: 16}}>{n.notification_body}</Typography>
                    <Typography style={{fontSize: 12}} color="textSecondary">{Helpers.SQLTimeSince(n.created)}</Typography>
                </CardContent>
            </CardActionArea>
            {n.was_read == null && 
            <CardActions>
                <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Button size="small" color="secondary" onClick={() => userNotified(n)}>Mark as read</Button>
                </div>
            </CardActions>}
        </Card>)
    }

    return (
        <Popover
            id='notifcations_results_popover'
            open={show}
            anchorEl={anchor.current}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            disableAutoFocus
            disableEnforceFocus
        >
            <div style={{padding: 10, height: notificationsHeight(), width: 400, overflow: 'auto', backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText}}>
                <Typography color="inherit" gutterBottom>Notifications</Typography>

                {props.notifications.length == 0 && <div style={{padding: 20}}><Typography color="inherit">No notifications</Typography></div>}
                
                {unreadNotifications.map((n, idx) => (
                    <div key={idx}>
                        <Notification n={n} />
                    </div>
                ))}
                
                {readNotifications.map((n, idx) => (
                    <div key={idx}>
                        <Notification n={n} />
                    </div>
                ))}
            </div>
        </Popover>
    )
}

const mapStateToProps = state => {
  return {
    notifications: state.user.notifications
  }
}

const mapDispatchToProps = dispatch => {
  return {
        userNotified: payload => {
            dispatch(userNotified(payload));
        }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications))