import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';
import { Grid, TextField, Button, FormControl, FormHelperText, CircularProgress, Typography, InputLabel, Select, Collapse, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';


const SwitchOrderOrganisation = props => {
    const [loading, setLoading] = useState(false);
    const [id_order] = useState(typeof props.id_order != 'undefined'?props.id_order:null);
    const [id_organisation_old] = useState(typeof props.id_organisation != 'undefined'?props.id_organisation:null);
    const [id_organisation, setIdOrganisation] = useState(id_organisation_old);

    const [selectedOrganisation, setOrganisation] = useState(null);
    const [organisations, setOrganisations] = useState([]);

    useEffect(() => {

        setLoading(true)

        context.fetchOrganisations().then(
            res => {
                console.log(res);
                setOrganisations(res.result);

                // pre select the organisation if we need to
                if(id_organisation > 0){
                    const organisation = res.result.find(o => o.id_organisation == id_organisation);
                    handleChangeOrganisation(null, organisation);
                }

                setLoading(false)
            }, 
            err => {
                setLoading(false)
                context.showAlert('error', 'error fetching organisations: ' + JSON.stringify(err.msg));
            }
        )

        
    }, [])


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    const handleChangeOrganisation = (e, newValue) => {
        setOrganisation(newValue);
        setIdOrganisation(newValue!=null?newValue.id_organisation:null);
    }


    const invalid = () => {
        if(isInvalid('id_organisation'))
            return true;
        
        return false;
    }

    const isInvalid = prop => {
        switch(prop){
            case 'id_organisation':
                return id_organisation == id_organisation_old?true:false;
            default:
                return true;
        }
    }

    const submitForm = () => {
        console.log({
            id_order: id_order,
            id_organisation_old: id_organisation_old,
            id_organisation: id_organisation
        });

        if(!invalid())
            setLoading(true);
            context.switchOrderOrganisation({
                id_order: id_order,
                id_organisation_old: id_organisation_old,
                id_organisation: id_organisation
            }).then(
                res => {
                    setLoading(false);
                    context.showAlert('success', 'Order organisation switched');

                    if(typeof props.onSwitch == 'function')
                        props.onSwitch();
                },
                err => {
                    setLoading(false);
                    context.showAlert('error', 'Error switching organisation ' + JSON.stringify(err));
                }
            )
    }

    
    const getOrganisationName = org => {
        if(org.organisation_name!=null)
            return org.organisation_name
        
        if(org.main_contact_name!=null)
            return org.main_contact_name
        
        if(org.main_contact_email!=null)
            return org.main_contact_email
        
        if(org.main_contact_phone!=null)
            return org.main_contact_phone
        
        return '';
    }

    
        
    return (

        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: 20}}>
            <Typography variant="h5">Switch Organisation</Typography>
            <Typography variant="body" color="error">Switch Organisation</Typography>

            <div style={{flex: 1, margin: '10px 0', padding: 10}}>
                <Autocomplete
                    options={organisations}
                    getOptionLabel={option => option.organisation_name}
                    value={selectedOrganisation}
                    style={{width: '100%'}}
                    onChange={handleChangeOrganisation}
                    id="id_organisation"
                    autoHighlight
                    renderOption={option => (
                        <React.Fragment>
                            <span style={{marginRight: 10}}>({option.id_organisation})</span>
                            {getOrganisationName(option)}
                        </React.Fragment>
                    )}
                    renderInput={params => <TextField 
                        {...params}
                        label="Select Organisation" 
                    />}
                />
            </div>

            <div style={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 30, justifyContent: 'center', alignItems: 'flex-end', padding: 10}}>
                <div style={{flex: 1}}></div>
                <div style={{padding: '0 10px'}}>
                    <Button color="secondary" variant="contained" disabled={invalid()} onClick={submitForm}>Switch {loading && <CircularProgress size={20} className="ml" color="inherit" />}</Button>
                </div>
            </div>
        </div>

    );
}

export default SwitchOrderOrganisation