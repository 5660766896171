import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const ProductSettings = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Products',
                            route: '/settings/products'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Products</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>Products that are available to include in an order.</Typography>

                <Crud
                    heading='Products'
                    uri='products'
                    idCol='id_product'
                    displayColumns={[
                        { 
                            name: 'product_description', 
                            type: 'string',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'term', 
                            type: 'int',
                            editable: true,
                            required: false,
                            display_name: 'Term (if not subscription leave as 0)'
                        },
                        { 
                            name: 'price', 
                            type: 'string',
                            editable: true,
                            required: true,
                            display_name: 'Price (don\'t include £ symbol)'
                        },
                        { 
                            name: 'sage_item_code', 
                            type: 'select',
                            display_name: 'Sage Product Code',
                            editable: true,
                            required: false
                        },
                        { 
                            name: 'active_from', 
                            type: 'date',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'active_to', 
                            type: 'date',
                            editable: true,
                            required: false
                        },
                        { 
                            name: 'id_product_renewal', 
                            type: 'select',
                            display_name: 'Renewal Product Code',
                            editable: true,
                            required: false
                        }
                    ]}
                    selectors={[
                        {
                            uri: 'sage/item_codes',
                            name: 'Sage Product',
                            col_name: 'sage_item_code',
                            selector_name: 'Code',
                            display_col: 'Name'
                        },
                        {
                            uri: 'products/activecodes',
                            name: 'Renewal Product Codes',
                            col_name: 'id_product_renewal',
                            selector_name: 'id_product',
                            display_col: 'product_description'
                        },
                    ]}
                    canRemove={false}
                />

            </div>

        </div>
    )

}

export default ProductSettings
