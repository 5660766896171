import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { CircularProgress, DialogContent, DialogActions, Dialog, Typography, Button, Switch, FormControlLabel, FormHelperText } from '@material-ui/core';

const ClearDeactivationNoticeDialog = props => {

    const {show, success, close, id_order} = props;
    const context = useContext(GlobalContext);
    const [loading, setLoading] = React.useState(false);

    

    const handleClearDeactivation = () => {

        setLoading(true);
        
        context.orderClearDeactivationNotice(id_order).then(
            res => {

                context.showAlert('success', `Order deactivation schedule cleared`);
                setLoading(false);

                if(typeof props.success == 'function')
                    props.success();

            },
            err => {

                context.showAlert('error', 'Error clearing deactivation schedule ' + JSON.stringify(err));
                setLoading(false);
            }
        );

    }


    const handleClose = () => {
        if(typeof close == 'function')
            close()
    }

    
    return (
        <Dialog open={show} onClose={handleClose} aria-labelledby="delete-socket-dialog-title">
            <DialogContent style={{maxWidth: 500}}>
                <Typography variant="h6" style={{marginBottom: 10}}>Clear deactivation notice?</Typography>
                <Typography variant="subheading" style={{marginBottom: 20}}>This will clear the order from being deactivated.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">Cancel</Button>
                <Button onClick={handleClearDeactivation} color="secondary" disabled={loading}>Clear {loading && <CircularProgress color="inherit" size={18} />}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ClearDeactivationNoticeDialog;