import React from 'react';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { Typography, Button } from '@material-ui/core';

class Site extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            view: 'commission',
            isLoading: false,
            isError: false
        };
    }


    onCommission = () => {

        this.setState({isLoading: true, isError: false});
    
        this.props.commissionFinish()
            .then(res => {
                // Hide any loading
                this.setState({isLoading: false, isError: false}, () => {
                    this.setState({view: 'complete'});
                });
            })
            .catch(err => {
                console.log(err)
                this.setState({isLoading: false, isError: true});
            })

    }

    onBack = () => {
        this.props.switchView('site');
    }

    onReset = () => {
        this.props.commissionReset();
        this.props.switchView('points');
    }
    
    componentDidMount(){

    }

    render(){
        return(
            <div style={styles.container}>

                <div style={styles.wrapper}>
                    {this.state.view == 'commission' && 
                        <div>
                            <Typography variant="h5">Complete Commissioning</Typography>
                            <p style={{color: '#f21843'}}><small>Ensure you check through everything in the summary, any points you have added will be transferred to the selected site and group scheme even if they are currently allocated to another.</small></p>

                            <div style={styles.buttonGroup}>
                                <Button variant="contained" color="default" onClick={this.onBack}>Back</Button>
                                <Button variant="contained" color="secondary" onClick={this.onCommission}>{this.state.isLoading?<div style={styles.buttonLoading}><ActivityIndicator /></div>:'COMPLETE'}</Button>
                            </div>
                        </div>
                    }
                    {this.state.view == 'complete' && 
                        <div>
                            <Typography variant="h5">All done</Typography>
                            <Button variant="contained" color="primary" onClick={this.onReset}>Start again</Button>
                        </div>
                    }
                </div>
            </div>
        );
    }

}


const styles  = {
    container: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100%', 
        width: '100%', 
        overflow: 'auto',
        backgroundColor: '#e2e2e2'
    },
    wrapper: {
        flex:1,
        padding: 30,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    header: {
        color: '#484848',
        fontWeight: 300
    },
    signinWrapper: {
      width: '80%',
      maxWidth: 300,
      display: 'flex',
      flexDirection: 'column'
    },
    formError: {
      color: '#F26A6A',
      fontSize: 14,
      marginBottom: 15,
      textAlign: 'center',
      display: 'block'
    },
    inputWrapper: {
      height: 54,
      width: '100%',
      backgroundColor: '#ebebeb',
      borderRadius: 12,
      marginBottom: 10,
      justifyContent: 'center',
      display: 'flex'
    },
    input: {
        fontSize: 16, 
        flex: 1,
        paddingLeft: 20,
        border: 'none'
    },
    inputError: {
      boxShadow: 'inset 0 0 0 1px #F26A6A'
    },
    inputErrorMsg: {
      color: '#F26A6A',
      fontSize: 11,
      paddingLeft: 20,
      marginBottom: 8,
      display: 'block',
      width: '100%'
    },
    button: {
        backgroundColor: '#00adef',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer',
        alignSelf: 'flex-end'
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 30,
        width: '100%'
    },
    buttonDefault: {
        backgroundColor: '#666666',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer',
        alignSelf: 'flex-end'
    },
    buttonLoading: {
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loading: {
      backgroundColor: '#00adef',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    site: {
        cursor: 'pointer'
    },
    sites: {
        backgroundColor: '#fff',
        border: '1px solid #d2d2d2',
        padding: 12,
        marginTop: 20,
        maxHeight: 400,
        width: '100%',
        overflow: 'auto',
    }
}

export default Site