export function blankTicket () {
    return {
        id_ticket: null,
        ticket_ref: null,
        id_ticket_type: null,
        ticket_type_name: '',
        id_network: 1,
        network_name: '',
        ticket_subject: '',
        ticket_description: '',
        id_ticket_contact_type: null,
        raised_by_name: '',
        raised_by_email: '',
        raised_by_phone: '',
        id_ticket_effect: null,
        id_ticket_route: null,
        id_ticket_status: 2,
        ticket_status_name: '',
        id_ticket_priority: null,
        id_user_created: null,
        id_user_assigned: null,
        id_user_closed: null,
        created: null,
        last_updated: null,
        closed: null,
        group_schemes: [],
        sites: [],
        charge_points: [],
        logs: [],
        contacts: [],
        id_ticket_unit: null,
        mode: 'new'
    }
}

export function getRand(max) {
    return Math.floor(Math.random() * Math.floor(max))+1;
}

// export function createDummy () {
//     const issue = getRandomIssue();
//     const ticket_status = getRand(3);
//     const network = getRand(3);
//     const createdOn = new Date(new Date().setDate(new Date().getDate() - getRand(10)));
//     const dateDiff = (new Date().getTime() - createdOn.getTime())  / (1000 * 3600 * 24); 
//     const lastUpdated = new Date(new Date().setDate(new Date().getDate() - getRand(dateDiff)));
//     const ticketType = getRand(5);

//     const newTicket = {
//         ...JSON.parse(JSON.stringify(blankTicket())),
//         id_ticket: getRandomId(),
//         id_ticket_type: ticketType,
//         ticket_type_name: 1,
//         id_network: network,
//         network_name: networks.find(x => x.id_network === network).network_name, 
//         raised_by_type: getRand(4),
//         belongs_to_raised_by: Boolean(getRand(2)-1),
//         belongs_to_type: getRand(4),
//         id_ticket_status: ticket_status,
//         ticket_status_name: ticket_statuses.find(x => x.id_ticket_status === ticket_status).ticket_status_name, 
//         id_ticket_priority: getRand(3),
//         requires_external_notifications: getRand(4),
//         subject: issue.subject,
//         description: issue.description,
//         created_on: createdOn,
//         last_updated: lastUpdated,
//         charge_points: ['UKEV1234', 'UKEV4321'],
//         comments: [
//             {
//                 id_ticket_comment: new Date().getTime(),
//                 id_user: 1,
//                 name: 'Kieran Rushby',
//                 createdOn: new Date(),
//                 comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
//             },
//             {
//                 id_ticket_comment: new Date().getTime(),
//                 id_user: 1,
//                 name: 'Lorand Szotyori',
//                 createdOn: new Date(),
//                 comment: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
//             },
//             {
//                 id_ticket_comment: new Date().getTime(),
//                 id_user: 1,
//                 name: 'Frankie Mellon',
//                 createdOn: new Date(),
//                 comment: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
//             }
//         ],
//         mode: 'open'
//     }

//     return newTicket;
// }

function getRandomIssue() {
    const issues = [
        {
            subject: 'Cable stuck in UKEV1234',
            description: 'Customer has charged their car however on return the cable wont release from the vehicle. Telsa Model 3'
        },
        {
            subject: 'Charge not starting',
            description: 'A driver has reported they have tried to start a charge session at almost all these charge points and the app states connection however the charge point never goes green, just flashes blue'
        },
        {
            subject: 'App always asking for payment details even though money in wallet',
            description: 'When the customer starts a charge session it allways asks him to put in his card details even though he has £20 TopUp in his wallet.'
        }
    ]

    return issues[getRand(issues.length)-1];
}

function getRandomId() {
    const alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'H']
    return `TX${getRand(9)}${getRand(9)}${getRand(9)}${getRand(9)}${getRand(9)}${alpha[getRand(alpha.length)-1]}${alpha[getRand(alpha.length)-1]}`
}