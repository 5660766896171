import React from 'react';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { Typography, List, ListItemIcon, ListItem, ListItemText, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

class GroupScheme extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        console.log(props.groupSchemeName)

        this.state = {
            fields: {
                schemeName: typeof props.groupSchemeName == 'string' ? props.groupSchemeName : '',
            },
            errors: {
                schemeName: this.errorField()
            },
            isValid: false,
            isLoading: false,
            isAdding: false,
            isError: false,
            groupSchemes: [],
            schemeFilter: []
        };
    }


    //
    //
    //  FORM BINDING AND VALIDATION
    //
    //


    errorField = (group = null) => {
        return {
            dirty: false,
            invalid: false,
            group: group
        };
    }
  
  
    bindField = (e) => {
        let fields = {...this.state.fields};
        fields[e.target.name] = e.target.value;
        this.setState({fields: fields}, () => {
            this.filterSchemes();
            this.validate()
        });
    }

    dirtyGroup = (group = null, callback = () => {}) => {
        let errors = {...this.state.errors};
        Object.values(errors).map(e => {
            if(e.group == group)
                e.dirty = true;
        })
        this.setState({errors: errors}, () => {callback()});
    }
  
    validate = (callback = () => {}) => {
        let errors = {...this.state.errors};
        const required = [
            'schemeName'
        ]
  
        required.map(f => {
            if(this.state.fields[f].length > 0)
                errors[f].dirty = true;
  
            errors[f].invalid = false;
            if(errors[f].dirty && this.state.fields[f] == ''){
                errors[f].invalid = true;
            }
        })
  
        const isValid = Object.values(errors).findIndex(e => e.invalid === true) === -1;
        this.setState({errors: errors, isValid: isValid}, () => {callback()});
    }


    //
    //
    //  EOF FORM BINDING AND VALIDATION
    //
    //




    onSelect = (schemeID) => {
        this.props.selectScheme(schemeID);
        this.props.switchView('site');
    }

    onBack = () => {
        this.props.switchView('points');
    }

    onRemove = () => {
        this.props.removeScheme();
    }

    onAdd = () => {

        this.dirtyGroup(null, () => {
            this.validate(() => {
                if(this.state.isValid){

                this.setState({isAdding: true, isError: false});
            
                this.props.addGroupScheme(this.state.fields)
                    .then(res => {
                        console.log(res)
                        // Reload the schemes
                        this.loadGroupSchemes();

                        // Select the scheme just created
                        this.onSelect(res.result);

                        // Hide any loading
                        this.setState({isAdding: false, isError: false});

                        // Move to points
                        this.props.switchView('site');
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({isAdding: false, isError: true});
                    })
                } else {
                    console.log('you are not valid');
                }
            })
        })

    }
    
    loadGroupSchemes = async () => {
        this.setState({isLoading: true}, async () => {
            await this.props.fetchGroupSchemes();
            this.filterSchemes();
            this.setState({isLoading: false});
        })
    }

    getGroupScheme = () => {
        const scheme = this.props.groupSchemes.find(s=>s.groupSchemeID==this.props.commission.groupSchemeID);
        if(typeof scheme != 'undefined')
            return scheme.groupSchemeID + ': ' + scheme.groupSchemeName;

        return '';
    }

    filterSchemes = (value) => {
        if(value == ''){
            this.setState({schemeFilter: this.props.groupSchemes});
        } else {
            const filterd = this.props.groupSchemes.filter(s => s.groupSchemeName.toUpperCase().indexOf(this.state.fields.schemeName.toUpperCase()) > -1);
            this.setState({schemeFilter: filterd});
        }
    } 
    
    componentDidMount(){
        this.loadGroupSchemes()
    }

    render(){
        const errors = this.state.errors;
        return(
            <div style={styles.container}>
                <div style={styles.wrapper}>

                    <div style={styles.col}>
                        <Typography variant="h5" style={{marginBottom: 20}}>Create a group scheme</Typography>

                        <div style={styles.inputWrapper}>
                            <input style={styles.input} placeholder="Group scheme name" type="text" id="schemeName" label="Group Scheme" name="schemeName" value={this.state.fields.schemeName} autoFocus autoComplete="off" onChange={this.bindField} />
                        </div>
                    
                        {errors.schemeName.invalid && <span style={styles.inputErrorMsg}>Scheme name is required</span>}

                        
                        <button style={styles.button} type="submit" onClick={this.onAdd}>{this.state.isAdding?<div style={styles.buttonLoading}><ActivityIndicator /></div>:'CREATE NEW'}</button>
        
                        

                        <Typography variant="h6" style={{marginTop: 20}}>or select existing Group Scheme</Typography>
                        <p style={{marginTop: 0}}><small>Type in the box above to filter</small></p>
                        <div style={styles.schemes}>
                            {this.state.isLoading && <div style={styles.loading}><ActivityIndicator /></div>}
                            {(this.state.schemeFilter.length == 0 && !this.state.isLoading) && <span style={{color: '#a2a2a2'}}>Nothing found</span>}
                            {this.state.schemeFilter && this.state.schemeFilter.map(s => <p style={styles.scheme} key={s.groupSchemeID} onClick={() => this.onSelect(s.groupSchemeID)}>{s.groupSchemeID}: {s.groupSchemeName}</p>)}
                        </div>
                    </div>


                    <div style={styles.col}>
                        <Typography variant="h5">Don't forget</Typography>

                        <List>
                            <ListItem><ListItemIcon><CheckIcon color='primary'/></ListItemIcon><ListItemText primary="Check the group scheme doesn't exist" /></ListItem>
                            <ListItem><ListItemIcon><CheckIcon color='primary'/></ListItemIcon><ListItemText primary="That there isn't another variation of the group scheme you are creating" /></ListItem>
                        </List>

                    </div>

                </div>

                

                <div style={styles.buttonGroup}>
                    <Button color="default" variant="contained" onClick={this.onBack}>BACK</Button>
                </div>
            </div>
        );
    }

}


const styles  = {
    container: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100%', 
        width: '100%', 
        overflow: 'auto',
        backgroundColor: '#e2e2e2'
    },
    header: {
        color: '#484848',
        fontWeight: 300
    },
    wrapper: {
        flex:1,
        padding: 30,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    col: {
        flex: 1,
        padding: 20
    },
    signinWrapper: {
      width: '80%',
      maxWidth: 300,
      display: 'flex',
      flexDirection: 'column'
    },
    loginError: {
      color: '#F26A6A',
      fontSize: 14,
      marginBottom: 15,
      textAlign: 'center',
      display: 'block'
    },
    inputWrapper: {
      height: 54,
      width: '100%',
      backgroundColor: '#ebebeb',
      borderRadius: 12,
      marginBottom: 10,
      justifyContent: 'center',
      display: 'flex'
    },
    input: {
        fontSize: 16, 
        flex: 1,
        paddingLeft: 20,
        border: 'none'
    },
    inputError: {
      boxShadow: 'inset 0 0 0 1px #F26A6A'
    },
    inputErrorMsg: {
      color: '#F26A6A',
      fontSize: 11,
      paddingLeft: 20,
      marginBottom: 8,
      display: 'block',
      width: '100%'
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 15,
        padding: 30,
        flex: 1
    },
    buttonDefault: {
        backgroundColor: '#666666',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer',
        alignSelf: 'flex-end'
    },
    button: {
        backgroundColor: '#F07E00',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer'
    },
    buttonLoading: {
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loading: {
      backgroundColor: '#00adef',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    scheme: {
        cursor: 'pointer'
    },
    schemes: {
        backgroundColor: '#fff',
        border: '1px solid #d2d2d2',
        padding: 12,
        marginTop: 20,
        maxHeight: 400,
        overflow: 'auto',
    }
}

export default GroupScheme