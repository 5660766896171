import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const TicketUnits = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Ticket Units',
                            route: '/settings/ticket_units'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Ticket Units</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>A team, group or dept that would singularly be responsible for some tickets.</Typography>

                <Crud
                    uri='ticket_units'
                    idCol='id_ticket_unit'
                    orderCol='order'
                    displayColumns={[
                        { 
                            name: 'ticket_unit_name', 
                            type: 'string',
                            editable: true,
                            required: true
                        }, 
                        { 
                            name: 'ticket_unit_email', 
                            type: 'string',
                            editable: true
                        }, 
                        { 
                            name: 'id_ticket_unit_group', 
                            type: 'select',
                            display_name: 'Unit Group',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'routed_external_email_in', 
                            display_name: 'External email notification',
                            type: 'string',
                            editable: true
                        }
                    ]}
                    selectors={[
                        {
                            uri: 'ticket_unit_groups',
                            name: 'unit_group',
                            col_name: 'id_ticket_unit_group',
                            selector_name: 'id_ticket_unit_group',
                            display_col: 'ticket_unit_group_name'
                        }
                    ]}
                />

            </div>
        </div>
    )

}

export default TicketUnits
