import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Paper, Table, TableHead, TableCell, TableRow, TableBody, IconButton, Menu, MenuItem } from '@material-ui/core';
import SingleEditField from '../../components/singleEditField';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const OrderControlDocumentUnit = props => {

    const theme = useTheme();
    const classes = useStyles(theme);

    /**
     *  MENU
     */
    const [menuEl, setMenuEl] = useState(null);

    const handleClickMenu = (e) => {
        setMenuEl(e.currentTarget);
    };
  
    const handleCloseMenu = () => {
        setMenuEl(null);
    };


    /*
     *  UPDATING FIELDS 
     */
    const handleUpdateField = (field, value) => {

        if(typeof props.onUpdate === 'function')
            props.onUpdate(props.index, field, value);

    }

    const handleUpdateSocketField = (field, value, idx) => {

        if(typeof props.onUpdateSocket === 'function')
            props.onUpdateSocket(props.index, field, value, idx);

    }

    const handleUpdateAll = (field, value) => {

        if(typeof props.onUpdateAll === 'function')
            props.onUpdateAll(field, value);
            
    }

    const handleUpdateAllSockets = (field, value) => {

        if(typeof props.onUpdateAllSockets === 'function')
            props.onUpdateAllSockets(field, value);
            
    }


    /**
     * REMOVING A UNIT
     */

    const handleRemoveUnit = () => {
        if(typeof props.onRemove === 'function')
            props.onRemove(props.index);

        handleCloseMenu();
    }


    /**
     * SPLITTING A UNIT INTO TWO
     */

    const handleSplitUnit = () => {
        if(typeof props.onSplit === 'function')
            props.onSplit(props.index);
        
        handleCloseMenu();
    }


    /**
     * SELECTION ITEMS
     */

    const unitTypes = [
        {key: "auto", value: "AutoCharge"},
        {key: "basic", value: "BasicCharge"},
        {key: "qtm", value: "Quantum"},
        {key: "securi", value: "SecuriCharge"},
        {key: "wallpod", value: "WallPod"},
        {key: "wallbox", value: "25kW DC Wallbox"},
        {key: "ufc", value: "UFC"}
    ]

    const socketTypes = [
        {key: "Type 1", value: "Type 1"},
        {key: "Type 2", value: "Type 2"},
        {key: "CHAdeMO", value: "CHAdeMO"},
        {key: "CCS", value: "CCS"}
    ]


    return (
        <Paper variant="outlined" className="mt mb">
            <div className="flex-col pa">
                <div className="flex-row gap-20 mb align-center">
                    <Typography variant="subtitle2">Unit: {props.unit.unit_no}</Typography>
                    <Typography variant="subtitle2" style={{flex: 1}}>Unit Type: <SingleEditField field="unit_type" name="Unit type" value={props.unit.unit_type} type="select" items={unitTypes} onUpdate={handleUpdateField} onUpdateAll={handleUpdateAll}/></Typography>
                    <Typography variant="subtitle2" color={props.serialNumberExists(props.unit.unit_serial_no) ? 'error' : 'inherit'}>Serial No: {props.unit.unit_serial_no}</Typography>


                    <div>
                        <IconButton aria-controls="unit-menu" aria-haspopup="true" onClick={handleClickMenu} size="small"><MoreVertIcon /></IconButton>
                        <Menu
                            id="unit-menu"
                            anchorEl={menuEl}
                            keepMounted
                            open={Boolean(menuEl)}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={handleRemoveUnit}>Remove</MenuItem>
                            {props.type === 'mode3' && <MenuItem onClick={handleSplitUnit}>Split</MenuItem>}
                        </Menu>
                    </div>
                </div>
                
                <Table  size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Socket</TableCell>
                            <TableCell>Output</TableCell>
                            <TableCell>Socket ID</TableCell>
                            <TableCell>Sim No.</TableCell>
                            {props.type === 'mode3' && <TableCell>Tariff</TableCell>}
                            {props.type === 'delta' && <TableCell>Socket Type</TableCell>}
                            {props.type === 'greenflux' && <TableCell>RFID</TableCell>}
                            <TableCell>Connectivity </TableCell>
                            {props.type === 'greenflux' && <TableCell>ROL</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.unit.sockets.map((s, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{s.socket_no}</TableCell>
                                <TableCell>{s.socket_ouput}</TableCell>
                                <TableCell>
                                    <SingleEditField 
                                        field="socket_id"
                                        name="Socket ID"
                                        value={s.socket_id}
                                        index={idx}
                                        onUpdate={handleUpdateSocketField}
                                        onUpdateAll={handleUpdateAllSockets}
                                    />
                                </TableCell>
                                <TableCell>{s.socket_sim_no}</TableCell>
                                {props.type === 'mode3' && 
                                    <TableCell>
                                        <SingleEditField 
                                            field="tariff"
                                            name="Tariff"
                                            value={s.tariff}
                                            index={idx}
                                            onUpdate={handleUpdateSocketField}
                                            onUpdateAll={handleUpdateAllSockets}
                                        />
                                    </TableCell>
                                }
                                {props.type === 'delta' && 
                                    <TableCell>
                                        <SingleEditField 
                                            field="socket_type" 
                                            name="Socket type" 
                                            value={s.socket_type} 
                                            type="select" 
                                            items={socketTypes} 
                                            onUpdate={handleUpdateSocketField}
                                            onUpdateAll={handleUpdateAllSockets}
                                            index={idx}
                                        />
                                    </TableCell>
                                }
                                {props.type === 'greenflux' && <TableCell>{props.unit.unit_rfid}</TableCell>}
                                <TableCell>{s.connectivity}</TableCell>
                                {props.type === 'greenflux' && <TableCell>{s.unit_rol_no}</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <div className="flex-row gap-20 align-center mt">
                    <Typography variant="subtitle2" style={{flex: 1}}>Notes: <SingleEditField field="unit_notes" name="Unit notes" value={props.unit.unit_notes} onUpdate={handleUpdateField} onUpdateAll={handleUpdateAll}/></Typography>
                </div>
                
            </div>
        </Paper>
    )

}
export default OrderControlDocumentUnit;