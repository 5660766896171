import React, {useContext, useEffect, useState} from 'react';
import GlobalContext from '../../../context/global-context';
import ActivityIndicator from '../../global/ActivityIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Helpers from '../../global/helpers';
import { withTheme } from '@material-ui/styles';
import { Checkbox, IconButton, Typography } from '@material-ui/core';
import API from '../../../api';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const BerthVendPending = props => {

    const context = useContext(GlobalContext);

    const [end, setEnd] = useState(new Date().toISOString().split('T')[0]);
    const [dirty, setDirty] = useState(false);

    const [loading, setLoading] = useState(false);
    const [posting, setPosting] = useState(false);

    const [threshold, setThreshold] = useState('50');
    const [summaryLines, setSummaryLines] = useState([]);

    const [showDownloadWarning, setShowDownloadWarning] = useState(false);
    const [processError, setProcessError] = useState(null);
    const [inspectSummary, setInspectSummary] = useState(null);

    const [expandAll, setExpandAll] = useState(false);


    const loadStatements = async () => {

        try {

            setLoading(true);
            setDirty(false);
            let newSumLines = [];
            const data = await API.req(`/bv/statements?end=${end}`, 'GET', null);
            if(data.success === true){
                data.result.map(d => {

                    // const mgmnt_fee = d.net*.07;
                    // const mgmnt_vat = (d.country_code === 'GB' && d.tax > 0) ? d.net*.2 : 0;
                    // const amount_due = d.gross - (mgmnt_fee + mgmnt_vat);

                    const idx = newSumLines.findIndex(l => l.id_organisation === d.id_organisation);
                    if(idx > -1) {
                        newSumLines[idx].gross += d.gross;
                        newSumLines[idx].net += d.net;
                        newSumLines[idx].tax += d.tax;
                        newSumLines[idx].management_fee += d.management_fee;
                        newSumLines[idx].transaction_fee += d.transaction_fee;
                        newSumLines[idx].management_vat += d.management_vat;
                        newSumLines[idx].transaction_vat += d.transaction_vat;
                        newSumLines[idx].amount_due += d.amount_due;
                        newSumLines[idx].service_types.push({
                            ...d,
                            management_fee: d.management_fee,
                            management_vat: d.management_vat,
                            amount_due: d.amount_due,
                        });
                    } else {
                        newSumLines.push({
                            ...d,
                            service_types: [{
                                ...d,
                                management_fee: d.management_fee,
                                transaction_fee: d.transaction_fee,
                                management_vat: d.management_vat,
                                transaction_vat: d.transaction_vat,
                                amount_due: d.amount_due,
                            }],
                            management_fee: d.management_fee,
                            transaction_fee: d.transaction_fee,
                            management_vat: d.management_vat,
                            transaction_vat: d.transaction_vat,
                            amount_due: d.amount_due,
                            selected: false
                        })
                    }
                })
            }
            setSummaryLines(newSumLines);
            setLoading(false);
            
        } catch (error) {

            setLoading(false);
            
        }

    }

    const postStatements = async () => {

        try {

            setPosting(true);
            let organisations = [];
            summaryLines.filter(r => r.selected === true).map(r => organisations.push(r.id_organisation));

            const postData = await API.req(`/bv/statements`, 'POST', {
                end: end,
                organisations: organisations
            });

            if(postData.success === true)
                await loadStatements();
            
            setPosting(false);
            
        } catch (error) {

            setPosting(false);
            
        }

    }

    const toggleRow = (id_organisation, currency) => {

        let newSummaryLines = JSON.parse(JSON.stringify(summaryLines));
        const idx = newSummaryLines.findIndex(r => r.id_organisation===id_organisation && r.currency===currency);
        if(idx > -1)
            newSummaryLines[idx].selected = !newSummaryLines[idx].selected;
        setSummaryLines(newSummaryLines);

    }

    useEffect(() => {

        loadStatements();

    }, [])


    return (
        
        <div style={Object.assign({}, !props.show&&styles.hide)}>

        <h2 style={{color: props.theme.palette.primary.main, fontWeight: 300}}>Pending</h2>

        <div style={styles.taskbar}>
            <div>
                <TextField name="end" label="End" type="date" value={end} onChange={e => {setEnd(e.target.value); setDirty(true)}} style={styles.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                <Button variant="contained" color={dirty?'secondary':'default'} onClick={loadStatements}>Go{loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
            </div>
            <div>
                <FormControl style={styles.textField}>
                    <InputLabel shrink htmlFor="age-label-placeholder">
                        Threshold
                    </InputLabel>
                    <Select
                        value={threshold}
                        onChange={(e) => setThreshold(e.target.value)}
                        input={<Input name="threshold" id="source-label-placeholder" />}
                        displayEmpty
                        name="threshold"
                    >
                        <MenuItem value={'100'}>£100</MenuItem>
                        <MenuItem value={'50'}>£50</MenuItem>
                        <MenuItem value={'25'}>£25</MenuItem>
                        <MenuItem value={'0'}>None</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" color="secondary" onClick={postStatements}>Process{posting && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
            </div>
        </div>

        <Paper style={styles.root}>
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell><IconButton size="small" onClick={() => setExpandAll(!expandAll)}>{expandAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton></TableCell>
                        <TableCell>Marina</TableCell>
                        <TableCell align="right">Country Code</TableCell>
                        <TableCell align="right">Tax Number</TableCell>
                        <TableCell align="right">Currency</TableCell>
                        <TableCell align="right">Gross</TableCell>
                        <TableCell align="right">Net</TableCell>
                        <TableCell align="right">Tax</TableCell>
                        <TableCell align="right">Mgmnt Fee</TableCell>
                        <TableCell align="right">Trnactn Fee</TableCell>
                        <TableCell align="right">Mgmnt VAT</TableCell>
                        <TableCell align="right">Trnactn VAT</TableCell>
                        <TableCell align="right">Amount Due</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {summaryLines.filter(r => r.amount_due >= parseFloat(threshold)).map(row => (
                    <React.Fragment key={row.id_organisation}>
                        <TableRow selected={row.selected} onClick={() => setInspectSummary(row)}>
                            <TableCell><Checkbox checked={row.selected} onClick={e => {e.stopPropagation(); toggleRow(row.id_organisation, row.currency)}}/></TableCell>
                            <TableCell>{row.organisation_name}</TableCell>
                            <TableCell align="right">{row.country_code}</TableCell>
                            <TableCell align="right">{row.tax_number}</TableCell>
                            <TableCell align="right">{row.currency}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.gross, row.currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.net, row.currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.tax, row.currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.management_fee, row.currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.transaction_fee, row.currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.management_vat, row.currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.transaction_vat, row.currency)}</TableCell>
                            <TableCell align="right">{Helpers.localeCurrency(row.amount_due, row.currency)}</TableCell>
                        </TableRow>
                        {expandAll &&
                            <React.Fragment>
                                {row.service_types.map((st, idx) => (
                                    <TableRow key={idx} style={{backgroundColor: '#f8f8f8'}}>
                                        <TableCell></TableCell>
                                        <TableCell style={{paddingLeft: 40}}><b>{st.service_type}</b></TableCell>
                                        <TableCell align="right">{st.country_code}</TableCell>
                                        <TableCell align="right">{st.tax_number}</TableCell>
                                        <TableCell align="right">{st.currency}</TableCell>
                                        <TableCell align="right">{Helpers.localeCurrency(st.gross, st.currency)}</TableCell>
                                        <TableCell align="right">{Helpers.localeCurrency(st.net, st.currency)}</TableCell>
                                        <TableCell align="right">{Helpers.localeCurrency(st.tax, st.currency)}</TableCell>
                                        <TableCell align="right">{Helpers.localeCurrency(st.management_fee, st.currency)}</TableCell>
                                        <TableCell align="right">{Helpers.localeCurrency(st.transaction_fee, st.currency)}</TableCell>
                                        <TableCell align="right">{Helpers.localeCurrency(st.management_vat, st.currency)}</TableCell>
                                        <TableCell align="right">{Helpers.localeCurrency(st.transaction_vat, st.currency)}</TableCell>
                                        <TableCell align="right">{Helpers.localeCurrency(st.amount_due, st.currency)}</TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        }
                    </React.Fragment>
                ))}
                </TableBody>
            </Table>
        </Paper>

        {summaryLines.length == 0 && <div style={styles.nodata}>No Data</div>}

        <Dialog
            open={showDownloadWarning}
            onClose={() => setShowDownloadWarning(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Reload the data"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                You changed the values in the taskbar but didn't reload the data, reload before you download.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setShowDownloadWarning(false)} color="secondary">
                OK
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={processError !== null}
            onClose={() => setProcessError(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Error processing statments</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Typography color="error">{processError}</Typography>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => this.setProcessError(null)} color="secondary">
                Close
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={inspectSummary !== null}
            onClose={() => setInspectSummary(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            {inspectSummary !== null && 
                <React.Fragment>
                    <DialogTitle id="alert-dialog-title">{inspectSummary.organisation_name}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">Country Code: <b>{inspectSummary.country_code}</b></Typography>
                        <Typography variant="body2">Tax Number: <b>{inspectSummary.tax_number}</b></Typography>
                        <Typography variant="body2">Currency: <b>{inspectSummary.currency}</b></Typography>
                        <Table style={styles.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Service</TableCell>
                                    <TableCell align="right">Gross</TableCell>
                                    <TableCell align="right">Net</TableCell>
                                    <TableCell align="right">Tax</TableCell>
                                    <TableCell align="right">Tax Rate</TableCell>
                                    <TableCell align="right">Mgmnt Fee</TableCell>
                                    <TableCell align="right">Trnactn Fee</TableCell>
                                    <TableCell align="right">Mgmnt VAT</TableCell>
                                    <TableCell align="right">Trnactn VAT</TableCell>
                                    <TableCell align="right">Amount Due</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {inspectSummary.service_types.map(row => (
                                <TableRow key={row.id_organisation}>
                                    <TableCell>{row.service_type}</TableCell>
                                    <TableCell align="right">{Helpers.localeCurrency(row.gross, row.currency)}</TableCell>
                                    <TableCell align="right">{Helpers.localeCurrency(row.net, row.currency)}</TableCell>
                                    <TableCell align="right">{Helpers.localeCurrency(row.tax, row.currency)}</TableCell>
                                    <TableCell align="right">{row.tax_rate}</TableCell>
                                    <TableCell align="right">{Helpers.localeCurrency(row.management_fee, row.currency)}</TableCell>
                                    <TableCell align="right">{Helpers.localeCurrency(row.transaction_fee, row.currency)}</TableCell>
                                    <TableCell align="right">{Helpers.localeCurrency(row.management_vat, row.currency)}</TableCell>
                                    <TableCell align="right">{Helpers.localeCurrency(row.transaction_vat, row.currency)}</TableCell>
                                    <TableCell align="right">{Helpers.localeCurrency(row.amount_due, row.currency)}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions><Button onClick={() => this.setInspectSummary(null)} color="secondary">Close</Button></DialogActions>
                </React.Fragment>
            }
        </Dialog>

        </div>
    )
}


const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    hide: {
        display: 'none'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default withTheme(BerthVendPending)