import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Grid, Typography, Collapse, List, ListItem, Button, ButtonGroup } from '@material-ui/core';
import Quickcrumbs from '../../global/Quickcrumbs';
import globalContext from '../../../context/global-context';
import InputField from '../../../components/inputField';
import { isValidForm, makeValidation, validateForm } from '../../global/formHelper';
import FileDragDrop from '../../../components/fileDragDrop';
import ProgressButton from '../../../components/progressButton';


const useCustomStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    maxWidth: 600
  },
  upload: {
    marginBottom: 40
  }
}));


const FirmwareAdd = props => {

    const theme = useTheme();
    const styles = useStyles(theme);
    const customStyles = useCustomStyles(theme);
    const context = useContext(globalContext);

    const [mode, setMode] = useState('UPLOAD');

    const [vendors, setVendors] = useState([]);
    const [models, setModels] = useState([]);
    const [showModels, setShowModels] = useState(false);

    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [uploading, setUploading] = useState(false);

    const [fields, setFields] = useState({
      vendor: '',
      model: '',
      version: '',
      download_url: '',
      from_version_min: 0,
      from_version_max: 0,
      delivery_by: 'https',
      notes: '',
      auto_apply: true
    })

    const [validation, setValidation] = useState(makeValidation([
      {
        name: 'version',
        display_name: 'Version No',
        required: true
      },
      {
        name: 'vendor',
        display_name: 'Vendor',
        required: true
      },
      {
        name: 'model',
        display_name: 'Model',
        required: true
      },
      {
        name: 'download_url',
        display_name: 'Download URL',
        required: false
      },
      {
        name: 'from_version_min',
        display_name: 'From Version Min',
        required: true
      },
      {
        name: 'from_version_max',
        display_name: 'To Version Max',
        required: true
      },
      {
        name: 'delivery_by',
        display_name: 'Delivery Method',
        required: true
      },
      {
        name: 'notes',
        display_name: 'Notes',
        required: false
      },
      {
        name: 'auto_apply',
        display_name: 'Auto Apply',
        required: true
      }
    ]))


    // UPDATE THE FIELD VALUE WHEN INPUT CHANGES
    const handleInputChange = e => {
      let newFields = JSON.parse(JSON.stringify(fields));
      newFields[e.target.name] = e.target.value;
      setFields(newFields);
    }


    // SET THE MODEL VALUE ONLY
    const handleSetModel = model => {
      let newFields = JSON.parse(JSON.stringify(fields));
      newFields.model = model;
      setFields(newFields);
    }


    // ON LOAD FETCH THE EXISTING VENDORS AND MODELS
    useEffect(() => {

      context.apiRequest(`firmware/vendors`).then(
        res => {
            let newVendors = [];
            res.result.map(v => newVendors.push({
              key: v.vendor,
              value: v.vendor
            }));
            setVendors(newVendors);
        },
        err => {
            context.showAlert('error', err.msg);
        }
      )

      context.apiRequest(`firmware/models`).then(
        res => {
            setModels(res.result);
        },
        err => {
            context.showAlert('error', err.msg);
        }
      )

    }, [])


    // WHEN THE VENDOR CHANGES
    useEffect(() => {

      if(fields.vendor.length > 0 && models.filter(m => m.vendor === fields.vendor).length > 0){
        if(showModels === false)
          setShowModels(true);
      }
      else
        setShowModels(false);

    }, [fields.vendor])


    // WHEN A FIELD CHANGES
    useEffect(() => {
      const valid = isValidForm(fields, validation);
      setValidation(valid.validation);
      setIsValid(valid.isValid);
    }, [fields])


    // WHEN THE MODE CHANGES
    useEffect(() => {

      const newValidation = JSON.parse(JSON.stringify(validation));
      newValidation.download_url.required = (mode !== 'UPLOAD');
      const valid = isValidForm(fields, newValidation);
      setValidation(valid.validation);
      setIsValid(valid.isValid);

    }, [mode])



    // SUBMIT THE FORM
    const handleSubmit = () => {

      if(isValid){
        setLoading(true);
        if(mode === 'UPLOAD')
          setUploading(true);
        else
          handleAdd(fields);
      }

    }


    // WHEN A FILE HAS BEEN UPLOADED
    const handleUploaded = async (result) => {

      setUploading(false);

      try {

        if(result.success){

          let newFields = JSON.parse(JSON.stringify(fields));
          newFields.download_url = result.result;
          handleAdd(newFields)


        } else
          throw result;
        
      } catch (error) {
        context.showAlert('error', typeof error === 'string' ? error : JSON.stringify(error));
      } finally {
        setLoading(false);
      }

    }

    const handleUploadError = () => {
      setLoading(false);
      setUploading(false);
    }


    // ADD THE FIRMWARE RECORD
    const handleAdd = async (params) => {

      setLoading(true);

      try {

        const addFirmware = await context.apiRequest(`firmware`, 'POST', params);
        if(addFirmware.success)
          props.history.push('/settings/firmware');
        
      } catch (error) {
        context.showAlert('error', typeof error === 'string' ? error : JSON.stringify(error));
      } finally {
        setLoading(false);
      }

    }


    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Firmware Updates',
                            route: '/settings/firmware'
                        },
                        {
                            name: `Add Firmware`,
                            route: `/settings/firmware/add`
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Add OCPP Firmware</Typography>

                </div>

                <div className={styles.wrapper}>

                  <div className={customStyles.form}>

                    {/* MODE SELECTION */}
                    <div className={styles.flexRight}>
                      <ButtonGroup size="small" color="primary">
                        <Button onClick={() => setMode('UPLOAD')} variant={mode === 'UPLOAD' ? 'contained' : 'outlined'}>Upload</Button>
                        <Button onClick={() => setMode('INPUT')} variant={mode === 'INPUT' ? 'contained' : 'outlined'}>Input</Button>
                      </ButtonGroup>
                    </div>


                    {/* FILE SELECTION AND UPLOAD */}
                    {mode === 'UPLOAD' &&
                      <div className={customStyles.upload}>
                        <Typography variant="body1">Add firmware package</Typography>
                        <Typography variant="caption">This should be a packaged up file like a .zip or .tgz</Typography>
                        <div className="pt">
                          <FileDragDrop upload={uploading} onSuccess={handleUploaded} onError={handleUploadError} shouldConfirm={false} uploadUrl="firmware/upload" />
                        </div>
                      </div>
                    }


                    {/* FORM */}
                    <Typography variant="body1" className="pb">Properties</Typography>

                    <Grid container spacing={3}>
                      
                      {/* DOWNLOAD URL */}
                      {mode !== 'UPLOAD' &&
                        <Grid item xs={12}>
                          <div>
                            <InputField name="download_url" validation={validation.download_url} value={fields.download_url} handleChange={handleInputChange} variant="outlined" hoverLabel={true} placeholder={'https://ocppfiles.blob.core.windows.net/firmware/xxx.zip'} />
                          </div>
                        </Grid>
                      }

                      {/* VERSION */}
                      <Grid item xs={12} md={4}>
                        <div>
                          <InputField name="version" validation={validation.version} value={fields.version} handleChange={handleInputChange} variant="outlined" hoverLabel={true} placeholder={'e.g. 1.4.9'} />
                        </div>
                      </Grid>

                      {/* FROM VERSION MIN */}
                      <Grid item xs={12} md={4}>
                        <div>
                          <InputField name="from_version_min" validation={validation.from_version_min} value={fields.from_version_min} handleChange={handleInputChange} variant="outlined" hoverLabel={true} placeholder={'e.g. 1.2.5'} />
                        </div>
                      </Grid>

                      {/* FROM VERSION MAX */}
                      <Grid item xs={12} md={4}>
                        <div>
                          <InputField name="from_version_max" validation={validation.from_version_max} value={fields.from_version_max} handleChange={handleInputChange} variant="outlined" hoverLabel={true} placeholder={'e.g. 1.4.8'} />
                        </div>
                      </Grid>

                      {/* VENDOR */}
                      <Grid item xs={12} md={6}>
                        <div>
                          <InputField type="select" name="vendor" validation={validation.vendor} value={fields.vendor} handleChange={handleInputChange} variant="outlined" hoverLabel={true} items={vendors} />
                        </div>
                      </Grid>


                      {/* MODEL */}
                      <Grid item xs={12} md={6}>

                        {fields.vendor.length > 0 && 
                        <div>
                          <InputField 
                            name="model" 
                            validation={validation.model} 
                            value={fields.model} 
                            handleChange={handleInputChange} 
                            variant="outlined" 
                            hoverLabel={true} 
                          />
                          <Collapse in={showModels}>
                            {models.length > 0 && 
                              <List>
                                {models.filter(m => m.vendor === fields.vendor).map((m, idx) => (
                                  <ListItem key={idx} button onClick={() => handleSetModel(m.model)} selected={fields.model === m.model}>{m.model}</ListItem>
                                ))}
                              </List>
                            }
                          </Collapse>
                        </div>
                        }

                      </Grid>


                      {/* DELIVERY METHOD */}
                      <Grid item xs={6} md={3}>
                        <div>
                          <InputField type="select" name="delivery_by" validation={validation.delivery_by} value={fields.delivery_by} handleChange={handleInputChange} variant="outlined" hoverLabel={true} items={[{key: 'ftp', value: 'FTP'}, {key: 'http', value: 'HTTP'}, {key: 'https', value: 'HTTPS'}]} />
                        </div>
                      </Grid>

                      
                      {/* AUTO APPLY */}
                      <Grid item xs={6} md={3}>
                        <div>
                          <InputField type="select" name="auto_apply" validation={validation.auto_apply} value={fields.auto_apply} handleChange={handleInputChange} variant="outlined" hoverLabel={true} items={[{key: true, value: 'Yes'}, {key: false, value: 'No'}]} />
                        </div>
                      </Grid>

                      <Grid item xs={12} md={4}></Grid>


                      {/* NOTES */}
                      <Grid item xs={12}>
                        <div>
                          <InputField type="text" name="notes" validation={validation.notes} value={fields.notes} handleChange={handleInputChange} variant="outlined" hoverLabel={true} multiline rows={4}/>
                        </div>
                      </Grid>
                    </Grid>

                      
                    {/* BUTTONS */}
                    <div className={styles.flexSpaced}>
                      <Button onClick={() => props.history.push('/settings/firmware')}>Cancel</Button>
                      <ProgressButton variant="contained" onClick={handleSubmit} waiting={loading} color="secondary" disabled={!isValid}>Add</ProgressButton>
                    </div>

                  </div>
                
                </div>

            </div> 

        </div>
    )

}

export default FirmwareAdd
