import React from 'react';
import GlobalContext from '../context/global-context';
import ActivityIndicator from './global/ActivityIndicator';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withTheme,  } from '@material-ui/styles';


const blankVisit = {
    GroupSchemeId: null,
    ChargeSiteId: null,
    CompanyName: null,
    Address: null,
    Postcode: null,
    MainContact: null,
    ContactNumber: null,
    Description: null,
    VisitPlanned: null,
    VisitComplete: null,
    Engineer: null,
    EngineerNotes: null,
    Status: 0,
    RAMS: 0,
    OutOfHours: 0,
    meta: {
        state: 'A',
        loading: false,
        open: true,
        tab: 0 
    } 
}


class Visits extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            data: [],
            visits: [],
            loading: false,
            location: null,
            tab: 0,
            search: ''
        };
    }
    
    handleChange = (e, callback = () => {}) => {
        const bits = e.target.name.split('-');
        let visits = [...this.state.visits];
        let idx = visits.findIndex(v => v.VisitId == bits[1]);
        if(idx < 0)idx = 0;
        visits[idx][bits[0]] = e.target.value;
        this.setState({visits: visits}, () => {
            typeof callback === 'function' && callback();
        });
    }
    
    handleSearchChange = (e, callback = () => {}) => {
        this.setState({search: e.target.value}, () => {
            typeof callback === 'function' && callback();
        });
    }

    componentDidMount(){
        this.loadVisits();
    }

    loadVisits = () => {
        this.setState({loading: true, data: []}, () => {
            this.context.fetchVisits()
            .then(
                res => {
                    let pre = [...res.result]
                    pre.map(visit => {
                        visit.meta = {
                            open: false,
                            loading: false,
                            state: 'V',
                            tab: visit.VisitComplete!=null?2:visit.Status==1?1:0
                        }
                        visit.VisitPlanned = visit.VisitPlanned!=null?new Date(visit.VisitPlanned).toISOString().split('T')[0]:null;
                        visit.VisitComplete = visit.VisitComplete!=null?new Date(visit.VisitComplete).toISOString().split('T')[0]:null;
                    });
                    pre = pre.sort((a, b) => {
                        if(a.VisitComplete == null && a.VisitPlanned != null && b.VisitPlanned == null)
                            return -1;

                        return b.VisitId - a.VisitId;
                    });
                    const data = [...pre];
                    const visits = [...pre];
                    this.setState({data: data, visits: visits, loading: false})
                },
                err => {
                    this.setState({loading: false})
                    console.log(err)
                }
            )
        })
    }

    addVisit = () => {
        let visits = [...this.state.visits];
        visits.unshift(JSON.parse(JSON.stringify(blankVisit)));
        this.setState({visits: visits, tab: 0});
    }

    editVisit = (visit) => {
        let visits = [...this.state.visits];
        const idx = visits.findIndex(v => v.VisitId == visit.VisitId);
        visits[idx].meta.state = 'E';
        this.setState({visits: visits});
    }

    cancelEdit = (visit) => {
        const data = [...this.state.data];
        let visits = [...this.state.visits];

        if(visit.meta.state == 'A'){
            visits.shift();
        } else {  

            const dIdx = data.findIndex(v => v.VisitId == visit.VisitId);
            const vIdx = visits.findIndex(v => v.VisitId == visit.VisitId);

            visits[vIdx] = data[dIdx];
            visits[vIdx].meta.state = 'V';
        }

        this.setState({visits: visits});
    }

    saveVisit = (visit) => {
        let visits = [...this.state.visits];
        const idx = visits.findIndex(v => v.VisitId == visit.VisitId);
        visits[idx].meta.loading = true;

        // Escape the text areas
        this.setState({visits: visits}, () => {
            // PUSH THE VISIT TO THE DATABASE
            this.context.saveVisit(visit).then(
                res => {
                    console.log(res);
                    visits[idx].meta.loading = false;
                    if(res.success){
                        if(typeof res.result.VisitId != 'undefined')
                            visits[idx].VisitId = res.result.VisitId;

                        visits[idx].meta.state = 'V';
                        visits[idx].meta.error = '';
                        visits[idx].meta.tab = visit.VisitComplete!=null?2:visit.Status==1?1:0;
                    } else{
                        visits[idx].meta.error = res.msg;
                        console.log(res.msg)
                    }
                    this.setState({visits: visits})
                },
                err => {
                    console.log(err)
                    visits[idx].meta.loading = false;
                    visits[idx].meta.error = 'err.msg';
                    this.setState({visits: visits})
                }
            );
        });
    }

    

    handleVisitOpen = (visit) => {
        let visits = [...this.state.visits];
        visits.map(s => {
            if(s.VisitId == visit.VisitId)
                s.meta.open = !s.meta.open;
        })
        this.setState({visits: visits})
    }

    SQLtoUTCDate = (str, datetime = true) => {
        const d = str.split(/-|T|:|\.|Z/);
        const jD = new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]));
        if(datetime)
            return jD.toLocaleDateString('en-GB') + ' ' + jD.toLocaleTimeString('en-GB');
        
        return jD.toLocaleDateString('en-GB');
    }

    navigate = async (postcode) => {
        if(this.state.location == null)
           await this.getUserLocation();

        if(this.state.location != null)
            window.open('https://www.google.com/maps/dir/?api=1&parameters&origin='+this.state.location.lat+','+this.state.location.lng+'&destination='+postcode)
    }

    

    handleTabChange = (e, val) => {
        this.setState({tab: val});
    }

    

    getUserLocation = async () => {

        await navigator.geolocation.watchPosition(
          position => {
    
            let newLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
    
            this.setState({ location: newLocation }, () => {
                return true;
            });
    
          }, err => {
              console.log(err);
                return false;
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
        )

        return false;

    }

    render(){

        const visits = () => {
            let visits = this.state.visits.filter(v => v.meta.tab == this.state.tab);
        
            if(this.state.search.length > 0 && this.state.search != ''){
                visits = visits.filter(v => (_isNull(v.CompanyName).indexOf(this.state.search) > -1 ||
                _isNull(v.Address).indexOf(this.state.search) > -1 ||
                _isNull(v.Postcode).indexOf(this.state.search) > -1 ||
                _isNull(v.MainContact).indexOf(this.state.search) > -1 ||
                _isNull(v.Description).indexOf(this.state.search) > -1 ||
                _isNull(v.Engineer).indexOf(this.state.search) > -1 ||
                _isNull(v.EngineerNotes).indexOf(this.state.search) > -1))
            }

            return visits;
        }

        const _isNull = (str) => {
            if(typeof str == 'undefined' || str == null)
                return ''
            
            return str;
        }

        const toDate = (d) => {
            return new Date(d).toLocaleDateString('en-GB');
        }

        const tab = () => {
            switch(this.state.tab){
                case 0:
                    return 'Pending'
                    break;
                case 1:
                    return 'Active'
                    break;
                case 2:
                    return 'Complete'
                    break;
            }
        }

        return(
            <div style={styles.container}>
                <div style={styles.inner}>

                    <div style={styles.topBar}>
                        <h1 style={{flex: 1}}>{tab()} Visits</h1>
                            
                        <Tabs
                            value={this.state.tab}
                            onChange={this.handleTabChange}
                            indicatorColor="secondary"
                            textColor="secondary"
                        >
                            <Tab label="Pending" />
                            <Tab label="Active" />
                            <Tab label="Complete" />
                        </Tabs>
                    </div>
                    
                    <div style={styles.taskbar}>
                        <div style={{display: 'flex', flex: 1}}>
                            <TextField name={'Search'} type="text" value={this.state.search} onChange={this.handleSearchChange} InputLabelProps={{shrink: true,}} label="Search" variant="outlined" style={{flex: 1}} />
                        </div>
                        <div style={{width: 20}}></div>
                        <div>
                            <Button variant="contained" color={'secondary'} onClick={this.addVisit}>New Visit</Button>
                        </div>
                    </div>

                    {visits().map(visit => (
                        <ExpansionPanel key={visit.VisitId} expanded={visit.meta.open} onChange={() =>{this.handleVisitOpen(visit)}}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" >
                                <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
                                    <div style={{margin: 15}}>
                                        <div style={styles.heading}>#{visit.VisitId} - {visit.CompanyName}</div>
                                        <div style={styles.secondaryHeading}>Visit Date: {visit.VisitPlanned?<span style={styles.highlight}>{toDate(visit.VisitPlanned)}</span>:'No Date'}</div>
                                        <div style={styles.secondaryHeading}>Visit Complete: {visit.VisitComplete?<span style={styles.highlight}>{toDate(visit.VisitComplete)}</span>:'No Date'}</div>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>

                                <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                                
                                    {visit.meta.state != 'V' && <div style={{display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap'}}>
                                        <div style={{padding: 15, display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                            <div style={styles.pb}>
                                                <span style={styles.label}>Organisation: </span>
                                                {visit.meta.state != 'V' && <input name={'CompanyName-'+visit.VisitId} type="text" value={visit.CompanyName} onChange={this.handleChange} style={Object.assign({}, styles.textField, {fontSize: 18, fontWeight: 600})} maxLength="150"/>}
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                                <div style={styles.pb}><span style={styles.label}>Visit Date:&nbsp;</span>
                                                    {visit.meta.state != 'V' && <TextField name={'VisitPlanned-'+visit.VisitId} type="date" value={visit.VisitPlanned} onChange={this.handleChange} InputLabelProps={{shrink: true,}} />}
                                                </div>
                                                <div style={styles.pb}><span style={styles.label}>Visit Complete:&nbsp;</span> 
                                                    {visit.meta.state != 'V' && <TextField name={'VisitComplete-'+visit.VisitId} type="date" value={visit.VisitComplete} onChange={this.handleChange} InputLabelProps={{shrink: true,}} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                
                                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                        <div style={{minWidth: 400, width: '30%'}}>
                                            <div style={{padding: 15}}>
                                                {/* <div style={styles.pb}><span style={styles.label}>Group Scheme ID: </span> #{visit.GroupSchemeId}</div> */}
                                                <div style={styles.pb}><span style={styles.label}>Main Contact:&nbsp;</span>
                                                    {visit.meta.state == 'V' && visit.MainContact}
                                                    {visit.meta.state != 'V' && <input name={'MainContact-'+visit.VisitId} type="text" value={visit.MainContact} onChange={this.handleChange} style={styles.textField} maxLength="100" />}
                                                </div>
                                                <div style={styles.pb}><span style={styles.label}>Contact Number:&nbsp;</span> 
                                                    {visit.meta.state == 'V' && visit.ContactNumber}
                                                    {visit.meta.state != 'V' && <input name={'ContactNumber-'+visit.VisitId} type="text" value={visit.ContactNumber} onChange={this.handleChange} style={styles.textField} maxLength="50" />}
                                                </div>
                                                <div style={styles.pb}><span style={styles.label}>Address:&nbsp;</span> 
                                                    {visit.meta.state == 'V' && visit.Address}
                                                    {visit.meta.state != 'V' && <input name={'Address-'+visit.VisitId} type="text" value={visit.Address} onChange={this.handleChange} style={styles.textField} />}
                                                </div>
                                                <div style={styles.pb}><span style={styles.label}>Postcode:&nbsp;</span>
                                                    {visit.meta.state == 'V' && visit.Postcode}
                                                    {visit.meta.state != 'V' && <input name={'Postcode-'+visit.VisitId} type="text" value={visit.Postcode} onChange={this.handleChange} style={styles.textField} maxLength="10" />}
                                                </div>
                                                <div style={styles.pb}><span style={styles.label}>RAMS:&nbsp;</span>
                                                    {visit.meta.state == 'V' && (visit.RAMS?'Yes':'No')}
                                                    {visit.meta.state != 'V' && <Checkbox name={'RAMS-'+visit.VisitId} checked={visit.RAMS} onChange={this.handleChange} value="primary" inputProps={{ 'aria-label': 'primary checkbox' }} />}
                                                </div>
                                                <div style={styles.pb}><span style={styles.label}>Out Of Hours:&nbsp;</span>
                                                    {visit.meta.state == 'V' && (visit.OutOfHours?'Yes':'No')}
                                                    {visit.meta.state != 'V' && <Checkbox name={'OutOfHours-'+visit.VisitId} checked={visit.OutOfHours} onChange={this.handleChange} value="primary" inputProps={{ 'aria-label': 'primary checkbox' }} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{minWidth: 400, width: '50%'}}>
                                            <div style={{padding: 15}}>
                                                <Typography style={styles.label}>Description:</Typography>
                                                {visit.meta.state == 'V' && <Typography style={styles.notes}>{visit.Description}</Typography>}
                                                {visit.meta.state != 'V' && <textarea name={'Description-'+visit.VisitId} value={visit.Description} onChange={this.handleChange} style={styles.notes} />}

                                                <Typography style={styles.label}>Engineer Notes:</Typography>
                                                {visit.meta.state == 'V' && <Typography style={styles.notes}>{visit.EngineerNotes}</Typography>}
                                                {visit.meta.state != 'V' && <textarea name={'EngineerNotes-'+visit.VisitId} value={visit.EngineerNotes} onChange={this.handleChange} style={styles.notes} />}

                                                <div style={styles.pb}><span style={styles.label}>Engineer: </span>
                                                    {visit.meta.state == 'V' && visit.Engineer}
                                                    {visit.meta.state != 'V' && <input name={'Engineer-'+visit.VisitId} type="text" value={visit.Engineer} onChange={this.handleChange} style={styles.textField} maxLength="50" />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                                        {visit.meta.state == 'V' && <Button variant="contained" color={'secondary'} onClick={() => {this.navigate(visit.Postcode)}}>Navigate</Button>}
                                        {visit.meta.state == 'V' && <Button variant="contained" color={'secondary'} onClick={() => {this.editVisit(visit)}}>Edit</Button>}
                                        {(visit.meta.state == 'E' || visit.meta.state == 'A') && <Button variant="contained" onClick={() => {this.cancelEdit(visit)}}>Cancel</Button>}
                                        {(visit.meta.state == 'E' || visit.meta.state == 'A') && <Button variant="contained" color={'secondary'} onClick={() => {this.saveVisit(visit)}}>Save{visit.meta.loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>}
                                    </div>

                                </div>
                                
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}

                    {this.state.loading && <div style={Object.assign({}, styles.loading, {backgroundColor: this.props.theme.palette.primary.main})}><ActivityIndicator /></div>}
                    {!this.state.loading && this.state.visits.length == 0 && <div style={styles.nodata}>No Visits</div>}
                    

                </div>
            </div>
        );
    }

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        flex: 1,
        border: 'none',
        fontSize: 16,
        padding: 4,
        display: 'inline-block',
        marginLeft: 5,
        backgroundColor: '#f2f2f2'
    },
    topBar: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center',
        marginBottom: '10px'
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    },
    heading: {
        fontSize: 18,
        fontWeight: 600,
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: 15,
        color: '#a3a3a3',
    },
    label: {
        color: '#a3a3a3'
    },
    highlight: {
        color: '#000000',
    },
    pb: {
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center'
    },
    notes: {
        padding: 10,
        backgroundColor: '#f2f2f2',
        border: '1px solid #e2e2e2',
        marginBottom: 10,
        maxWidth: 900,
        minHeight: 60,
        width: '100%'
    },
}

export default withTheme(Visits)