import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import Helpers from '../global/helpers';
import { FixedSizeList } from 'react-window';
import { useStyles } from './styles';

import { 
    List,
    ListItem,
    ListItemText,
    TextField,
    Popover,
    Typography
} from '@material-ui/core';


var timeout = null;


const TicketSelectChargePoint = props => {

    const theme = useTheme();
    const styles = useStyles(theme);

    const [filter, setFilter] = useState('');
    const [openPopover, setOpenPopover] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const searchChargePoints = useRef(null);

    const handleFilterChange = e => {
        const filterStr = e.target.value;
        setFilter(filterStr);

        if(timeout != null)
            clearTimeout(timeout);

        timeout = setTimeout(() => {
            filterData(filterStr);
        }, 750)
    }

    const handleAdd = cp => {
        props.handleAdd({
            id_ticket: props.ticket.id_ticket,
            id_charge_point: cp.CPID
        })
    }

    const filterData = (filterStr) => {

        setOpenPopover(true);

        let result = [];

        if(filterStr.length !== 0 && filterStr != ''){
            if(!isNaN(filterStr.charAt(0)) || filterStr.length > 5)
                props.charge_points.map(d => {
                    const idx = d.CPID.toString().indexOf(filterStr);
                    if(idx > -1)
                        result.push({...d, idx: idx, SiteName: getSiteName(d.ChargeSiteID)});
                })            
        } else {
            setOpenPopover(false);
        }

        setFilteredData(Helpers.multiSort(result, [{orderBy: 'idx', order: 'asc'}, {orderBy: 'CPID', order: 'asc'}]));
            
    }

    const getSiteName = siteId => {
        return props.sites.find(s => s.ChargeSiteID === siteId).SiteName;
    }

    

    const DataRow = props => {
        const { index, style, data } = props;
        const cp = data[index];

        return (
            <ListItem key={index} button onClick={() => handleAdd(cp)} style={style}>
                <ListItemText primary={cp.CPID} secondary={cp.SiteName} />
            </ListItem>
        );
    }
    

    return (
        <div>

            <TextField 
                className={styles.inputField} 
                ref={searchChargePoints} 
                variant="outlined" 
                fullWidth 
                name="filter" 
                label="Search Charge Points" 
                value={filter} 
                onChange={handleFilterChange} 
                autoComplete="off"
                size="small" />

            <Popover
                id='charge_points_results_popover'
                open={openPopover}
                anchorEl={searchChargePoints.current}
                onClose={() => setOpenPopover(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableAutoFocus
                disableEnforceFocus
            >
                
                {filteredData.length == 0 && <div style={{padding: 20}}><Typography>No charge point found</Typography></div>}
                <FixedSizeList height={180} width={400} itemSize={60} itemCount={filteredData.length} itemData={filteredData}>
                    {DataRow}
                </FixedSizeList>
            </Popover>

        </div>
    )

}

export default TicketSelectChargePoint