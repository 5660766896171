import React from 'react';
import GlobalContext from '../../context/global-context';
import Helpers from '../global/helpers';
import { Typography, FormControl, InputLabel, Select, MenuItem, Input, IconButton, Chip } from '@material-ui/core/';
import AddIcon from '@material-ui/icons/Add';

import Refresh from '../global/Refresh'

const blank_filter = {
    columnObj: null,
    column: '',
    operator: '',
    value: ''
}

class Reports extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);
        
        this.state = {
            type: 'orders',
            columns: [],
            operators: ['IS', 'IS NOT', 'LESS THAN', 'GREATER THAN', 'LIKE'],
            filters: [],
            filter: {
                ...blank_filter
            },
            loading: false,
            table: {
                order: 'desc',
                orderBy: 'id_order'
            },
            data: []
        };
    }

    componentDidMount(){
        this.getColumns();
    }

    getColumns = () => {
        this.setState({
            loading: true
        }, () => {
            this.context.fetchReportColumns(this.state.type).then(
                res => {                    
                    let columns = [];
                    const keys = Object.keys(res.result);
                    keys.map(t => {
                        res.result[t].map(c => {
                            if(columns.findIndex(col => col.Field === c.Field) < 0)
                                columns.push({
                                    ...c,
                                    Table: t
                                })
                        })
                    })

                    this.setState({
                        columns: columns,
                        loading: false
                    })
                },
                err => {
                    this.setState({
                        loading: false
                    })
                    this.context.showAlert('error', 'Error loading columns');
                }
            )
        })
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.table.orderBy === property && this.state.table.order === 'asc';
        
        this.setState({
            table: {
                ...this.state.table,
                order: isAsc ? 'desc' : 'asc',
                orderBy: property
            }
        });
    };

    

    render(){
        const filter = this.state.filter;
        const handleColumnChange = (e) => {
            this.setState({
                filter: {
                    ...this.state.filter,
                    column: e.target.value,
                    columnObj: this.state.columns.find(c => c.Field == e.target.value)
                }
            })
        };
        const handleOperatorChange = (e) => {
            this.setState({
                filter: {
                    ...this.state.filter,
                    operator: e.target.value
                }
            })
        };

        const handleValueChange = (e) => {
            this.setState({
                filter: {
                    ...this.state.filter,
                    value: e.target.value
                }
            })
        };

        const handleAddFilter = () => {
            this.setState({
                filters: this.state.filters.push(this.state.filter)
            }, clearFilter);
        };

        const handleDelete = () => {}

        const clearFilter = () => {
            this.setState({filter: {
                ...blank_filter
            }})
        }

        // Makes the columns name more presentable
        const cleanField = field => {
            let newField = field;
            if(field.indexOf('id_') > -1)
                newField = newField.replace('id_', '') + ' ID';

            newField = newField.replace(/_/gi, ' ');
            return newField.charAt(0).toUpperCase() + newField.slice(1)
        }

        return(
            <div style={styles.container}>
                <div style={styles.inner}>

                    <div style={styles.taskbar}>

                        <Typography variant="h4"> Reports</Typography>

                    </div>

                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', width: '100%'}}>

                        <FormControl style={{minWidth: 200, margin: 10}}>
                            <InputLabel id="column-label">Column</InputLabel>
                            <Select
                            labelId="column-label"
                            id="column"
                            value={filter.column}
                            onChange={handleColumnChange}
                            >
                                {this.state.columns.map(
                                    (c, i) => <MenuItem key={i} value={c.Field}>{cleanField(c.Field)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        <FormControl style={{minWidth: 200, margin: 10}}>
                            <InputLabel id="operator-label">Operator</InputLabel>
                            <Select
                            labelId="operator-label"
                            id="operator"
                            value={filter.operator}
                            onChange={handleOperatorChange}
                            >
                                {this.state.operators.map(
                                    (o, i) => <MenuItem key={i} value={o}>{cleanField(o)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        <FormControl style={{minWidth: 200, margin: 10}}>
                            <InputLabel id="value-label">Value</InputLabel>
                            <Input
                                id="value"
                                value={filter.value}
                                onChange={handleValueChange}
                                inputProps={{
                                'aria-label': 'value',
                                }}
                            />
                        </FormControl>

                        <IconButton aria-label="add" color="secondary" size="medium" onClick={handleAddFilter}><AddIcon fontSize="inherit" /></IconButton>

                    </div>

                </div>
            </div>
        );
    }

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default Reports