import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'typeface-roboto';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';

import GlobalState from './context/GlobalState';

import { Provider } from 'react-redux';
import store from './store';

// import { makeStyles } from '@material-ui/core/styles';
// import theme from './theme';

// const useStyles = makeStyles(theme => ({
//     success: { backgroundColor: theme.palette.success.main},
//     error: { backgroundColor: theme.palette.error.main },
//     warning: { backgroundColor: theme.palette.warning.main },
//     info: { backgroundColor: theme.palette.primary.main },
// }));

const Root = props => {

    // const styles = useStyles(theme);

    return (
        <SnackbarProvider
        maxSnack={3} 
        anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}>
            <Provider store={store}>
                <GlobalState><App /></GlobalState>
            </Provider>
        </SnackbarProvider>
    )
}

ReactDOM.render(<Root />,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
