import React from 'react';

import { Typography, Breadcrumbs, Link } from '@material-ui/core';

const Quickcrumbs = props => {
    const { crumbs, history } = props

    const handleClick = (e, crumb) => {
        e.preventDefault();
        history.push(crumb.route);
    }

    return (
        <Breadcrumbs aria-label="breadcrumb" style={{paddingTop: 30}}>
            {crumbs.map((crumb, idx) => {
                if(idx < (crumbs.length - 1))
                    return (<Link key={idx} color="inherit" href={crumb.route} onClick={e => handleClick(e, crumb)}>{crumb.name}</Link>)
                else
                    return (<Typography key={idx} color="textPrimary">{crumb.name}</Typography>)
            })}
        </Breadcrumbs>
    )
}

export default Quickcrumbs