import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';
import { CircularProgress, DialogContent, DialogActions, Dialog, Typography, TextField, Button, List, ListSubheader, ListItem, ListItemText, Divider } from '@material-ui/core';

const IssuePaymentReminder = props => {

    const {show, success, close, proforma} = props;

    const context = useContext(GlobalContext);

    const [email, setEmail] = React.useState(props.proforma.recipients);
    const [loading, setLoading] = React.useState(false);

    const [contacts, setContacts] = React.useState([]);
    const [contactsLoading, setContactsLoading] = React.useState(false);

    useEffect(() => {
        setContactsLoading(true);
        context.fetchOrderContacts(proforma.id_order).then(res => {
            setContacts(res.result);
            setContactsLoading(false);
        }, err => {
            context.showAlert('Error', 'Could not load in the contacts for this order');
            setContacts([]);
            setContactsLoading(false);
        })
    }, [])

    const handleChange = e => {
        setEmail(e.target.value);
    }

    const handleSendReminder = () => {
        setLoading(true);
        const params = {
            email: email,
            id_order: proforma.id_order,
            invoice_no: proforma.invoice_no
        }
        context.issuePaymentReminder(params).then(
            res => {
                context.showAlert('success', 'Payment reminder issued');
                setLoading(false);

                if(typeof success == 'function')
                    success()
            },
            err => {
                setLoading(false);
                context.showAlert('error', 'Error sending payment reminder ' + JSON.stringify(err))
            }
        )
    }


    const handleClose = () => {
        if(typeof close == 'function')
            close()
    }

    
    return (
        <Dialog open={show} onClose={handleClose} aria-labelledby="delete-socket-dialog-title">
            <DialogContent style={{maxWidth: 500}}>
                <Typography variant="h6" style={{marginBottom: 10}}>Issue payment reminder?</Typography>
                <Typography variant="subheading" style={{marginBottom: 20}}>This will email the recipient below with an email notifying them payment is still due.</Typography>

                <Divider style={{marginTop: 20}} />
                <List aria-labelledby="contacts-list-subheader" subheader={<ListSubheader component="div" id="contacts-list-subheader">Select from order contacts</ListSubheader>}>
                    <Divider />
                    {contactsLoading && <CircularProgress color="secondary" size={18} />}
                    {!contactsLoading && contacts.map(c => (
                        <ListItem button onClick={() => {setEmail(c.contact_email)}}>
                            <ListItemText primary={c.contact_email} secondary={c.organisation_name}/>
                        </ListItem>
                    ))}
                </List>

                <TextField
                    fullWidth
                    margin="dense"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    label="Confirm email address"
                    type="text"
                    InputLabelProps={{shrink: true}} 
                    variant="filled"
                    style={{marginTop: 20}}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">Cancel</Button>
                <Button onClick={handleSendReminder} color="secondary" disabled={loading}>Confirm {loading && <CircularProgress color="inherit" size={18} />}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default IssuePaymentReminder;