import React, { useEffect, useState, useContext } from 'react';
import { useStyles } from '../styles';
import { useTheme, makeStyles } from '@material-ui/styles';
import { Typography, Divider, Grid } from '@material-ui/core';
import Helpers from '../../global/helpers';

import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import EvStationIcon from '@material-ui/icons/EvStation'
import PowerOffIcon from '@material-ui/icons/PowerOff'


export const EvStationConnection = props => {
    const { isOnline, size } = props;
    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div style={{position: 'relative', marginRight: theme.spacing(3), paddingLeft: size*.1}}>
            {isOnline && <CloudDoneIcon className={styles.colorGreen} color="success" style={{position: 'absolute', fontSize: size*.4, top: 0, left: 0}} />}
            {!isOnline && <CloudOffIcon className={styles.colorBlinkRed} style={{position: 'absolute', fontSize: size*.4, top: 0, left: 0}} />}
            <EvStationIcon style={{fontSize: size, color: theme.palette.text.secondary}}></EvStationIcon>
        </div>
    )
}


export const MainsReport = props => {
    const { feed, className } = props;
    const theme = useTheme();
    const styles = useStyles(theme);

    const [ mains, setMains ] = useState(true);

    useEffect(() => {

        let hasMains = true

        if(typeof feed.mode3 != 'undefined' && !feed.mode3.mains)
            hasMains = false;

        setMains(hasMains)

    }, [feed])

    return (
        <div className={className} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Typography>Mains:&nbsp;</Typography>
            <Typography  color={mains?'inherit':'error'}>{mains?'No errors reported':'Failed'}</Typography>
            {!mains && <PowerOffIcon className={styles.colorBlinkRed} />}
        </div>
    )
}

export const LastContact = props => {
    const { className } = props;
    const theme = useTheme();
    const styles = useStyles(theme);
    
    const [ timeSince, setTimeSince ] = useState(null);
    const [ timeSinceAuth, setTimeSinceAuth ] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if(props.lastContact != null)
                setTimeSince(Helpers.timeSince(new Date(props.lastContact)));

            if(props.lastAuthorised != null)
                setTimeSinceAuth(Helpers.timeSince(new Date(props.lastAuthorised)));
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [props.lastContact, props.lastAuthorised])

    return (
        <div>
            <div className={className}>
                <Typography>Last Contact: <b>{timeSince}</b></Typography>
                <Typography className={className} color="textSecondary" variant="caption">{Helpers.toLocaleDateTime(new Date(props.lastContact))}</Typography>
            </div>
            <div className={className}>
                <Typography>Connection Established: <b>{timeSinceAuth}</b></Typography>
                {props.lastAuthorised && <Typography className={className} color="textSecondary" variant="caption">{Helpers.toLocaleDateTime(new Date(props.lastAuthorised))}</Typography>}
            </div>
        </div>
    )
}

export const SessionEnding = props => {
    const { className } = props;
    const theme = useTheme();
    const styles = useStyles(theme);
    
    const [ endingIn, setEndingIn ] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if(props.secsRemaining != null)
                setEndingIn(Helpers.timeUntil(new Date(new Date().setTime(new Date().getTime() + (props.secsRemaining*1000)))));

        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [props.secsRemaining])

    return (
        <Typography className={className}>Time left: <b>{props.secsRemaining != null ? endingIn : 'No limit'}</b></Typography>
    )
}


export const Mode3Connector = props => {
    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <React.Fragment>
            <Divider className={styles.divider} />
                <Typography className={styles.pos} variant="subtitle1" color="textSecondary">Connector: <b>{props.connector.number}</b></Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Typography className={styles.pos}>Vehicle Connected: <b>{props.connector.vehicleIsConnected ? 'Yes' : 'No'}</b></Typography>
                        <Typography className={styles.pos}>Cable Connected: <b>{props.mode3.cableRating > 0 ? `Yes (${props.mode3.cableRating}A)` : 'No'}</b></Typography>
                        <Divider className={styles.divider} />
                        <Typography className={styles.pos}>Mode: <b>{props.connector.freemode ? <span className={styles.colorGreen}>PLUG &amp; GO</span> : 'PAYG'}</b></Typography>
                        <Typography className={styles.pos}>Max Capacity: <b>{props.connector.maxAmps + 'A'}</b></Typography>
                        <Typography className={styles.pos}>Current Limit: <b>{props.connector.loadLimit + 'A'}</b></Typography>
                    </Grid>
                    <Grid item item xs={12} sm={6}>
                        <Typography className={styles.pos}>Charge Session Active: <b>{props.connector.session != null ? 'Yes' : 'No'}</b></Typography>
                        {props.connector.session != null && 
                        <div style={{backgroundColor: theme.palette.success.main, padding: theme.spacing(3)}}>
                            <Typography className={styles.pos}>Status: <b>{props.connector.session.isCharging ? `Charging ${props.connector.freemode ? '(Plug & Go)' : ''}` : 'Not charging'}</b></Typography>
                            <Typography className={styles.pos}>Currently pulling: <b>{props.connector.session.loadKw} kW</b></Typography>
                            {props.connector.session.options.maxWh > -1 && <Typography className={styles.pos}>Max Charge: <b>{props.connector.session.options.maxWh/1000} kWh</b></Typography>}
                            {props.connector.session.whRemaining && <Typography className={styles.pos}>Charge Left: <b>{props.connector.session.whRemaining/1000} kWh</b></Typography>}
                            {props.connector.session.options.maxMins > -1 && <Typography className={styles.pos}>Max Time: <b>{props.connector.session.options.maxMins} mins</b></Typography>}
                            {props.connector.session.secsRemaining && <SessionEnding secsRemaining={props.connector.session.secsRemaining} className={styles.pos}  />}
                            <Typography className={styles.pos}>Meter reading: <b>{props.connector.session.wh/1000} kWh</b></Typography>
                        </div>}
                    </Grid>
                </Grid>
        </React.Fragment>
    )
}


export const OCPPConnector = props => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const connector_meta = props.socketInfo.ocpp16.connectors.find(c => c.number === props.connector.number);

    const getCurrentOffered = () => {
        if(typeof connector_meta != 'undefined' && connector_meta.meter !== null){
            const co = connector_meta.meter.sampledValue.find(sv => sv.measurand === 'Current.Offered');
            if(typeof co !== 'undefined')
                return `${co.value}${co.unit}`;
            else
                return '';
        }
        return '';
    }

    return (
        <React.Fragment>
            <Divider className={styles.divider} />
                <Typography className={styles.pos} variant="subtitle1" color="textSecondary">Connector: <b>{props.socketInfo.cpid}-{props.connector.number}</b></Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Typography className={styles.pos}>Connector status: <b>{connector_meta.status.status}</b></Typography>
                        <Typography className={styles.pos}>Error code: <b>{connector_meta.status.errorCode}</b></Typography>
                        <Typography className={styles.pos}>Info: <b>{connector_meta.status.info}</b></Typography>
                        <Typography className={styles.pos}>Current Offered: <b>{getCurrentOffered()}</b></Typography>
                    </Grid>
                    <Grid item item xs={12} sm={6}>
                        <Typography className={styles.pos}>Charge Session Active: <b>{props.connector.session != null ? 'Yes' : 'No'}</b></Typography>
                        {props.connector.session != null && 
                        <div style={{backgroundColor: theme.palette.success.main, padding: theme.spacing(3)}}>
                            <Typography className={styles.pos}>Status: <b>{props.connector.session.isCharging ? `Charging ${props.connector.freemode ? '(Plug & Go)' : ''}` : 'Not charging'}</b></Typography>
                            <Typography className={styles.pos}>Currently pulling: <b>{props.connector.session.loadKw} kW</b></Typography>
                            {props.connector.session.options.maxWh > -1 && <Typography className={styles.pos}>Max Charge: <b>{props.connector.session.options.maxWh/1000} kWh</b></Typography>}
                            {props.connector.session.whRemaining && <Typography className={styles.pos}>Charge Left: <b>{props.connector.session.whRemaining/1000} kWh</b></Typography>}
                            {props.connector.session.options.maxMins > -1 && <Typography className={styles.pos}>Max Time: <b>{props.connector.session.options.maxMins} mins</b></Typography>}
                            {props.connector.session.secsRemaining && <SessionEnding secsRemaining={props.connector.session.secsRemaining} className={styles.pos}  />}
                            <Typography className={styles.pos}>Meter reading: <b>{props.connector.session.wh/1000} kWh</b></Typography>
                        </div>}
                    </Grid>
                </Grid>
        </React.Fragment>
    )
}