const backgroundReducer = (state = {
    meta: {
        controller_types: []
    },
    status: {
        meta: 'LOADING'
    }
}, action) => {

    let meta = JSON.parse(JSON.stringify(state.meta));
    let status = JSON.parse(JSON.stringify(state.status));

    switch(action.type){
        case "BACKGROUND_META":
            state = {
                ...state,
                meta: action.payload || meta,
                status: {
                    ...status,
                    meta: status.meta != 'OK' ? action.status : status.meta
                }
            };
            break;
        default:
    }
    return state;
}

export default backgroundReducer;