import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { Typography, Grid, Card, CardContent, CardActionArea } from '@material-ui/core';
import Helpers from '../global/helpers';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { useStyles } from './styles';
import TicketVisit from './ticket_visit';

const customUseStyles = makeStyles(theme => ({
    card: {
        boxShadow: `inset 6px 0 0 ${theme.palette.primary.main}`
    }
}))



const TicketVisits = props => {

    const theme = useTheme();
    const styles = useStyles(theme);
    const customStyles = customUseStyles(theme);

    const [ openVisit, setOpenVisit ] = useState(null);


    //
    //
    //  If the ticket updates whilst a visit is open then update the openVisit with the latest version of itself
    //
    //
    useEffect(() => {

        if(openVisit !== null){
            const updatedVisit = props.ticket.visits.find(v => v.id_ticket_visit === openVisit.id_ticket_visit);
            if(typeof updatedVisit != 'undefined' && JSON.stringify(updatedVisit) != JSON.stringify(openVisit))
                setOpenVisit(updatedVisit);
        }

    }, [props.ticket])
    
    
    const getSiteName = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return s.SiteName;

        return '';
    }

    return (
        <div>
            <Typography variant="subtitle2" className={styles.pos}>Visits</Typography>
            {props.ticket.visits.length > 0 && <Grid container spacing={3}>
                {props.ticket.visits.map((v, idx) => (
                    <Grid item sm={6} key={idx}>
                        <Card variant="outlined" className={customStyles.card}>
                            <CardActionArea onClick={() => {setOpenVisit(v)}}>

                                <CardContent>
                                    <Typography variant="subtitle2" gutterBottom={true}>{v.ticket_visit_description}</Typography>
                                    <Typography variant="body2"><Typography color="textSecondary" variant="body2" component="span">When: </Typography>{Helpers.SQLtoUTCDate(v.date_booked, false)}</Typography>
                                    <Typography variant="body2"><Typography color="textSecondary" variant="body2" component="span">Where: </Typography>{getSiteName(v.id_site)}</Typography>
                                    <Typography variant="body2"><Typography color="textSecondary" variant="body2" component="span">Status: </Typography>{v.ticket_visit_reported === null ? 'Scheduled' : 'Complete'}</Typography>
                                </CardContent>
                            
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}

            </Grid>}
            {props.ticket.visits.length === 0 && <Typography variant="body2" color="textSecondary">-- No visits</Typography>}

            {openVisit !== null && <TicketVisit visit={openVisit} open={true} handleClose={() => setOpenVisit(null)} ticketMeta={props.ticketMeta} ticket={props.ticket} setTicket={props.setTicket} handleUpdateTicket={props.handleUpdateTicket} />}
            
        </div>
    )
}

export default TicketVisits
