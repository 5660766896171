import React from 'react';

import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Grid, Typography, Paper } from '@material-ui/core';
import OcpiParties from './parties';
import OcpiUninvoicedBatches from './uninvoiced_batches';
import OcpiActiveTariffs from './active_tariffs';
import OcpiInvoicedBatches from './invoiced_batches';


const OcpiStatementDashboard = props => {


  const theme = useTheme();
  const styles = useStyles(theme);



  return (
    <div className={styles.root}>
        <div className={styles.wrapper}>

            <div className={styles.taskbar}>
                <Typography variant="h4">OCPI Dashboard</Typography>
            </div>

            <Grid container spacing={2}>
                
                <Grid item xs={12} sm={4} lg={3}>
                    <Typography variant="h6">Parties</Typography>

                    <Paper className={styles.space}>

                        <OcpiParties />

                    </Paper>
                </Grid>
                
                <Grid item xs={12} sm={4} lg={3}>
                    <Typography variant="h6">Uninvoiced Batches</Typography>

                    <Paper className={styles.space}>

                        <OcpiUninvoicedBatches />

                    </Paper>

                    <Typography variant="h6">Invoiced Batches</Typography>

                    <Paper className={styles.space}>

                        <OcpiInvoicedBatches />

                    </Paper>
                </Grid>
                
                <Grid item xs={12} sm={4} lg={3}>
                    <Typography variant="h6">Active Tariffs</Typography>

                    <Paper className={styles.space}>

                        <OcpiActiveTariffs />

                    </Paper>
                </Grid>
                
            </Grid>

        </div>
    </div>
  )
}

export default OcpiStatementDashboard