import React, { useContext, useEffect } from 'react';
import GlobalContext from '../../context/global-context';
import { Typography, makeStyles, useTheme, Button, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: 20,
        padding: 20
    }
}))


const CommissioningGroupScheme  = props => {

    const context = useContext(GlobalContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    
    const handleChangeScheme = () => {
        if(typeof props.changeScheme === 'function')
            props.changeScheme();
    }

    useEffect(() => {
        console.log(props.groupScheme)
    }, [])

    return (
        <Paper className={classes.wrapper}>
            <Typography variant="h6" style={{marginBottom: 20}} gutterBottom={false}>#{props.groupScheme.groupSchemeID}: {props.groupScheme.groupSchemeName}</Typography>
            <Typography variant="subtitle2">Plan: {props.groupScheme.essentialPlan ? 'Essential' : 'Premium'}</Typography>
            <Typography variant="subtitle2">Currency: {props.groupScheme.Currency}</Typography>
            {props.groupScheme.essentialPlan && <Alert severity="warning" style={{marginTop: 10}}>Any charge point added to this scheme will be placed into Plug and Go mode</Alert>}
            <Button variant="contained" onClick={handleChangeScheme} color="primary" style={{marginTop: 20}}>Change</Button>
        </Paper>
    )
}

export default CommissioningGroupScheme;