import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Button, CircularProgress, Dialog, DialogTitle, DialogContent, Grid, FormControl, InputLabel, Select, MenuItem, DialogActions, TextField } from '@material-ui/core';
import globalContext from '../../context/global-context';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const SubscriptionSocketSimCreate = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const {endpoint, sim_no, sim_id} = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [form, setForm] = useState({
        auth_code: context.authCode(),
        service_profile: 418358,
        tariff_profile: 406408
    })

    const [serviceProfiles, setServiceProfiles] = useState('LOADING');
    const [tariffProfiles, setTariffProfiles] = useState('LOADING');

    var abortFetch = new AbortController();


    //
    //  Fetch the profile information from the Emnify portal
    //
    useEffect(() => {

        const fetchProfiles = () => {

            context.apiRequest(`/devices/service_profiles`, 'GET', null, abortFetch.signal).then(
                res => {
                    setServiceProfiles(res.result);
                }, 
                err => {
                    setServiceProfiles([]);
                    setError(`Error getting service profiles`);
                }
            )

            context.apiRequest(`/devices/tariff_profiles`, 'GET', null, abortFetch.signal).then(
                res => {
                    setTariffProfiles(res.result);
                }, 
                err => {
                    setTariffProfiles([]);
                    setError(`Error getting service profiles`);
                }
            )

        }
        fetchProfiles();

        return () => {
            abortFetch.abort();
        }

    }, [])



    //
    // Handle selection change
    //
    const handleSelectionChange = e => {
        let newForm = JSON.parse(JSON.stringify(form));
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }
    

    //
    // Create a device
    //
    const handleCreateDevice = async => {

        setLoading(true);
        setError(null);

        context.apiRequest(`/devices/create`, 'POST', {
            authCode: form.auth_code,
            endpoint: endpoint,
            sim_id: sim_id,
            service_profile: form.service_profile,
            tariff_profile: form.tariff_profile
        }).then(
            res => {
                console.log('I am success');
                context.setAuthCode(form.auth_code);
                setLoading(false);
                if(typeof props.handleSuccess === 'function')
                    props.handleSuccess();
            }, 
            err => {
                setLoading(false);
                setError(typeof err.msg !== 'undefined' ? err.msg : 'An error occured creating the device.');
            }
        ) 

    }

    const handleActionCancel = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }


    return (
        <React.Fragment>

            
            <Dialog open={props.show} onClose={handleActionCancel} aria-labelledby="update-socket-dialog-title" maxWidth={'xs'} fullWidth>
                <DialogTitle>Allocate Emnify SIM to Device</DialogTitle>
                <DialogContent>

                    {error && <Alert severity="error">{error}</Alert>}

                    <Typography variant="subtitle2">Device Name: {endpoint}</Typography>
                    <Typography variant="subtitle2">SIM no: {sim_no}</Typography>

                    
                    
                    <Grid item xs={8} style={{marginTop: 10}}>
                        <FormControl fullWidth error={form.service_profile === null}>
                            <InputLabel id='service_profile_label' shrink={true}>Service Profile</InputLabel>
                            <Select
                                name="service_profile"
                                value={form.service_profile}
                                onChange={handleSelectionChange}
                                labelId='service_profile_label'
                                label="Service Profile"                
                            >
                                <MenuItem value={null}>-- Select service profile--</MenuItem>
                                {serviceProfiles !== 'LOADING' && serviceProfiles.map((item, idx) => (
                                    <MenuItem key={idx} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                            {form.service_profile === null && <Typography variant="caption" color="error">Please select a service profile</Typography>}
                            {form.service_profile !== null && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={8} style={{marginTop: 10}}>
                        <FormControl fullWidth error={form.tariff_profile === null}>
                            <InputLabel id='tariff_profile_label' shrink={true}>Tariff Profile</InputLabel>
                            <Select
                                name="tariff_profile"
                                value={form.tariff_profile}
                                onChange={handleSelectionChange}
                                labelId='tariff_profile_label'
                                label="Tariff Profile"                
                            >
                                <MenuItem value={null}>-- Select tariff profile--</MenuItem>
                                {tariffProfiles !== 'LOADING' && tariffProfiles.map((item, idx) => (
                                    <MenuItem key={idx} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                            {form.tariff_profile === null && <Typography variant="caption" color="error">Please select a tariff profile</Typography>}
                            {form.tariff_profile !== null && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={8} style={{marginTop: 10}}>
                        <TextField
                            name="auth_code"
                            value={form.auth_code}
                            onChange={handleSelectionChange}
                            label="Authorisation code"
                            type="password"
                            InputLabelProps={{shrink: true}}
                            fullWidth
                        />
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleActionCancel} color="default">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateDevice} color="secondary" disabled={loading}>
                        {!loading ? 'Assign' : <CircularProgress color="inherit" size={16} />}
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}
export default SubscriptionSocketSimCreate;