import React, {useState, useEffect, useContext, useRef} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import Helpers from '../global/helpers';

import Grid from '@material-ui/core/Grid';

import {withRouter} from 'react-router-dom';
import OrdersSnippet from './orders_snippet';
import DashItem from './dash_item';
import { Typography, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, Collapse, IconButton, Accordion } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Refresh from '../global/Refresh';

const useStyles = makeStyles((theme) => ({
    listWrapper: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(2, 0, 2),
    },
    collapsedTitle: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0, 2),
    },
    listValue: {
        padding: '0 8px',
        display: 'inline'
    }
}));

const Dashboard = props => {

    
    const theme = useTheme();
    const classes = useStyles(theme);


    // Create a local state
    const [stats, setStats] = useState({
        subscriptionsPending: 0,
        subscriptionsUnpaid: 0,
        subscriptionsPaidMonth: 0,
        subscriptionsExpiring: 0,
        allTickets: 0,
        myTickets: 0,
        socketsIncomplete: 0,
        veosUnits: 0,
        veosConnectors: 0
    });

    const [orders, setOrders] = useState([]);
    const [map, setMap] = useState({
        idle: 0,
        charging: 0,
        fault: 0
    });

    const [networkStats, setNetworkStats] = useState({
        points: 0,
        statementsAwaiting: 0,
        statementsPending: 0,
        statementsPaidMonth: 0,
        sessionsToday: 0,
        sessionsMonth: 0,
        users: 0,
        usersMonth: 0,
    });

    const [fOrders, setFilteredOrders] = useState({
        agreements_toChase: [],
        agreements_toChaseDetails: [],
        agreements_toChaseUnpaid: [],
        outstanding_order_actions: 0,
        agreements_toChaseCommissioning: [],
        agreements_notShipped: [],
        agreements_toSend: [],
        agreements_outstanding: [],
        agreements_shippedNoInvoice: [],
        agreements_unpaid: [],
        agreements_unpaid_proforma: [],
        agreements_bookCommission: [],
        agreements_commissionReady: [],
        agreements_commissionOverdue: [],
        agreements_commissionUpcoming: [],
        agreements_requireOnboarding: [],
        agreements_other: [],
        ocpp_toConfig: [],
        incomplete_sockets: []
    });

    const [panel, setPanel] = useState(null);
    const [actionPanel, setActionPanel] = useState(null);

    const [showNetwork, setShowNetwork] = useState(false);

    const [loading, setLoading] = useState({
        orders: false,
        stats: false,
        map: false,
        network: false
    });

    // Counter is going to determine how often the useEffect gets called
    // Otherwise every time the data is loaded it will fire again.
    const [counter, setCounter] = useState(0);
    let timer;

    // Use the global context
    const context = useContext(GlobalContext);

    useEffect(() => {

       init();

    }, [counter])

    const init = () => {
        setLoading({
            ...loading,
            orders: true,
            stats: true
        });

        if(typeof timer != 'undefined')
            clearTimeout(timer);

        context.fetchOrdersBy('id_order_status', 'less', 4).then(
            res => {
                setOrders(res.result);

                const agreements_toChaseDetails = res.result.filter(o =>  isLiveOrder(o) && o.agreement_issued == null && o.agreement_counter_signed == null && daysBetween(o.created, new Date().toISOString()) >= 7 && (o.order_info_chased == null || daysBetween(o.order_info_chased, new Date().toISOString()) >= 7));
                const agreements_toChase = res.result.filter(o => o.agreement_issued != null && o.agreement_counter_signed == null && daysBetween(o.agreement_issued, new Date().toISOString()) >= 7 && (o.agreement_chased == null || daysBetween(o.agreement_chased, new Date().toISOString()) >= 7));
                const agreements_toChaseUnpaid = res.result.filter(o => o.outstanding_balance > 0 && o.invoiced == 1 && daysBetween(o.invoice_issued, new Date().toISOString()) >= 7 && (o.invoice_chased == null || daysBetween(o.invoice_chased, new Date().toISOString()) >= 7));
                const outstanding_order_actions = agreements_toChaseDetails.length + agreements_toChase.length + agreements_toChaseUnpaid.length;
                const agreements_toChaseCommissioning = res.result.filter(o => o.agreement_counter_signed != null && o.paid != null && o.commissioned == null && (o.commission_scheduled == null || daysBetween(o.commission_scheduled, new Date().toISOString()) >= 7) && (o.commission_schedule_chased == null || daysBetween(o.commission_schedule_chased, new Date().toISOString()) >= 7));
                
                const agreements_notShipped = res.result.filter(o => o.id_order_status == 1 && o.proforma_outstanding == 0 && o.invoiced == 0 && o.paid == 'No');

                const agreements_other = res.result.filter(o => o.id_order_status == 1 && (o.proforma_outstanding == 1 || o.invoiced == 1 || o.paid == 'Yes'));

                const agreements_toSend = res.result.filter(o => o.agreement_issued == null && o.agreement_counter_signed == null && isLiveOrder(o));
                const agreements_outstanding = res.result.filter(o => o.agreement_issued != null && o.agreement_counter_signed == null);
                
                const agreements_shippedNoInvoice = res.result.filter(o => isLiveOrder(o) && o.proforma_outstanding == 0 && o.invoiced == 0 && o.paid == 'No');

                const agreements_unpaid = res.result.filter(o => o.outstanding_balance > 0 && o.invoiced == 1);
                const agreements_unpaid_proforma = res.result.filter(o => o.outstanding_balance > 0 && o.proforma_outstanding == 1);

                const agreements_bookCommission = res.result.filter(o => o.agreement_counter_signed != null && o.paid == 'Yes' && o.commissioned == null && o.commission_scheduled == null);
                const agreements_commissionReady = res.result.filter(o => o.agreement_counter_signed != null && o.paid == 'Yes' && o.commissioned == null);
                const agreements_commissionOverdue = res.result.filter(o => o.agreement_counter_signed != null && o.paid == 'Yes' && o.commissioned == null && o.commission_scheduled != null && Helpers.SQLtoUTCDateObj(o.commission_scheduled) < new Date());
                const agreements_commissionUpcoming = res.result.filter(o => o.agreement_counter_signed != null && o.paid == 'Yes' && o.commissioned == null && o.commission_scheduled != null && Helpers.SQLtoUTCDateObj(o.commission_scheduled) >= new Date().setHours(0,0,0,0));
                const agreements_requireOnboarding = res.result.filter(o => o.agreement_counter_signed != null && o.paid == 'Yes' && o.commissioned != null && o.backoffice_onboarded == null);

                const ocpp_toConfig = res.result.filter(o => o.incomplete_charge_points_ocpp != null && parseInt(o.incomplete_charge_points_ocpp) > 0);
                const incomplete_sockets = res.result.filter(o => o.id_order_status > 1 && o.id_order_status < 5 && o.incomplete_charge_points != null && parseInt(o.incomplete_charge_points) > 0);

                setFilteredOrders({
                    agreements_toChaseDetails: agreements_toChaseDetails,
                    agreements_toChase: agreements_toChase,
                    agreements_toChaseUnpaid: agreements_toChaseUnpaid,
                    outstanding_order_actions: outstanding_order_actions,
                    agreements_toChaseCommissioning: agreements_toChaseCommissioning,
                    agreements_notShipped: agreements_notShipped,
                    agreements_toSend: agreements_toSend,
                    agreements_outstanding: agreements_outstanding,
                    agreements_shippedNoInvoice: agreements_shippedNoInvoice,
                    agreements_unpaid: agreements_unpaid,
                    agreements_unpaid_proforma: agreements_unpaid_proforma,
                    agreements_bookCommission: agreements_bookCommission,
                    agreements_commissionReady: agreements_commissionReady,
                    agreements_commissionOverdue: agreements_commissionOverdue,
                    agreements_commissionUpcoming: agreements_commissionUpcoming,
                    agreements_requireOnboarding: agreements_requireOnboarding,
                    agreements_other: agreements_other,
                    ocpp_toConfig: ocpp_toConfig,
                    incomplete_sockets: incomplete_sockets
                });

                setLoading({
                    ...loading,
                    orders: false
                });

            },
            err => {
                console.log(`error loading order data ${JSON.stringify(err)}`);
                setLoading({
                    ...loading,
                    orders: false
                });
                //setCounter();
            }
        )

        

        context.fetchStats().then(
            res => {
                setStats(res.data);
                setLoading({
                    ...loading,
                    stats: false
                });

            // Next data refresh
            // timer = setTimeout(()=>{
            //     setCounter(counter+1);
            // }, 60000);
            },
            err => {
                setLoading({
                    ...loading,
                    stats: false
                });
                context.showAlert('error', `error loading stats`);
            }
        )


    }

    const handleFetchNetwork = () => {

        setLoading({
            ...loading,
            map: true,
            network: true
        });
        
        
        context.fetchVeosPortal().then(
            res => {

                let mapData = {
                    idle: 0,
                    charging: 0,
                    fault: 0
                }


                res.data.points.map(p => {
                    if(p.status == 'CHARGING' && p.opStatus == 'INSTALLED')
                        mapData.charging++;

                    const mins = Math.floor((p.msSinceLastHeartbeat/1000)/60);

                    if(p.status == 'IDLE' && mins < 121)
                        mapData.idle++;

                    if(p.opStatus == 'INSTALLED' && mins > 120 && mins < 10080)
                        mapData.fault++;
                })

                setMap(mapData);
                
                console.log(res.data);
                setLoading({
                    ...loading,
                    map: false
                });
            },
            err => {
                setLoading({
                    ...loading,
                    map: false
                });
                context.showAlert('error', `error loading map`);
            }
        )
        
        
        context.apiRequest('/stats/network').then(
            res => {

                setNetworkStats(res.data);

                setLoading({
                    ...loading,
                    network: false
                });
            },
            err => {
                setLoading({
                    ...loading,
                    network: false
                });
                context.showAlert('error', `error loading network`);
            }
        )

    }

    useEffect(() => {

        if(showNetwork)
            handleFetchNetwork();

    }, [showNetwork])

    const isLiveOrder = (order) => {
        return (order.id_order_status > 1 && order.id_order_status < 5);
    }

    const handleQueryChange = () => {

        let query = {};
        
        if(panel != null)
            query.panel = panel;
        
        props.history.replace('?'+Helpers.makeQueryString(query));

    }

    const handlePanelChange = (newPanel, setPanelFunc = (p) => setPanel(p), panelVar = panel) => {
        if(newPanel == panelVar)
            setPanelFunc(null);
        else
            setPanelFunc(newPanel);
    };

    


    const handleChangeRoute = route => {
        props.history.push(route);
    }

    const styles  = {
        container: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            overflow: 'auto'
        },
        inner: {
            padding: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexFlow: 'row wrap',
            flexWrap: 'wrap'
        },
        card: {
            flex: '1 0 auto',
            maxWidth: 275,
            minWidth: 275,
            maxHeight: 160,
            cursor: 'pointer',
            margin: '0 10px'
        },
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 32
        },
        heading: {
            marginBottom: 0,
            fontWeight: 300
        },
        caption: {
            fontSize: 16,
            marginBottom: 0,
            fontWeight: 600
        },
        headingStat: {
            fontSize: 60,
            margin: 0,
            fontWeight: 300,
            color: theme.palette.primary,
        },
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        paperCenter: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
            textAlign: 'center'
        }
    }

    const daysBetween = (first, second) => {
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        first = Helpers.SQLtoUTCDateObj(first, false);
        second = Helpers.SQLtoUTCDateObj(second, false);

        return Math.round((second-first)/(1000*60*60*24));
    }
        

    const totalOrdersValue = (orders) => {
        let total = 0;
        orders.map(o => total += o.total);
        return '£'+total.toLocaleString('en-GB');
    }
        

    const totalOutstandingBalance = (orders) => {
        let total = 0;
        orders.map(o => total += o.outstanding_balance);
        return '£'+total.toLocaleString('en-GB');
    }

    const totalOrdersSockets = (orders) => {
        let total = 0;
        orders.map(o => total += o.sockets);
        return total;
    }

    const isLoading = (loading.orders || loading.stats)

    return (

        <div style={styles.container}>
            <div style={styles.inner}>
                
                <Grid container spacing={2} style={{marginTop: 10}}>
                    <Grid item xs={12}>

                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Typography variant="h4">Dashboard</Typography>

                            <Refresh handleClick={init} loading={isLoading} />
                        </div>
                    </Grid>
                </Grid>

                
                <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={4}>



                        <Typography variant="h6" className={classes.title}>
                            Order Processing
                        </Typography>
                        <div className={classes.listWrapper}>
                            <List>
                                <ListItem button onClick={() => handleChangeRoute('order/add')}>
                                    <ListItemIcon style={{minWidth: 36}}>
                                        <AddIcon color="secondary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Raise new order"/>
                                </ListItem>


                                <ListItem button onClick={()=>handleChangeRoute('orders/ocppconfig')}>
                                    <ListItemText primary="OCPP Configuration Required"/>
                                    <ListItemSecondaryAction><div className={classes.listValue}><b>{fOrders.ocpp_toConfig.length}</b></div></ListItemSecondaryAction>
                                </ListItem>


                                <ListItem button onClick={()=>handleChangeRoute('orders/notshipped')}>
                                    <ListItemText primary="Not yet shipped"/>
                                    <ListItemSecondaryAction><div className={classes.listValue}><b>{totalOrdersValue(fOrders.agreements_notShipped)}</b></div></ListItemSecondaryAction>
                                </ListItem>


                                <ListItem button onClick={()=>handleChangeRoute('orders/awaitingproforma')}>
                                    <ListItemText primary="Awaiting proforma / invoice"/>
                                    <ListItemSecondaryAction><div className={classes.listValue}><b>{totalOrdersValue(fOrders.agreements_shippedNoInvoice)}</b></div></ListItemSecondaryAction>
                                </ListItem>


                                <ListItem button onClick={()=>handleChangeRoute('orders/proformaunpaid')}>
                                    <ListItemText primary="Unpaid proformas"/>
                                    <ListItemSecondaryAction><div className={classes.listValue}><b>{totalOutstandingBalance(fOrders.agreements_unpaid_proforma)}</b></div></ListItemSecondaryAction>
                                </ListItem>


                                <ListItem button onClick={()=>handleChangeRoute('orders/invoiceunpaid')}>
                                    <ListItemText primary="Unpaid invoices"/>
                                    <ListItemSecondaryAction><div className={classes.listValue}><b>{totalOutstandingBalance(fOrders.agreements_unpaid)}</b></div></ListItemSecondaryAction>
                                </ListItem>


                                <ListItem button onClick={()=>handleChangeRoute('orders/other')}>
                                    <ListItemText primary="Other"/>
                                    <ListItemSecondaryAction><div className={classes.listValue}><b>{totalOrdersValue(fOrders.agreements_other)}</b></div></ListItemSecondaryAction>
                                </ListItem>


                                <DashItem panel={actionPanel} handleClick={(p) => handlePanelChange(p, setActionPanel, actionPanel)} handle={'order_actions'} heading="Outstanding Actions" value={fOrders.outstanding_order_actions} />
                                <Collapse in={actionPanel == 'order_actions'} style={{backgroundColor: '#f9e8d7'}}>
                                        

                                    <DashItem panel={panel} handleClick={handlePanelChange} handle="agreements_toChaseDetails" heading="Chase Stakeholder Info" value={fOrders.agreements_toChaseDetails.length} />
                                    <Collapse in={panel == 'agreements_toChaseDetails'}>
                                        <OrdersSnippet 
                                            header="Order" 
                                            comparator="order_info_chased" 
                                            comparatorHeader="Last Chased" 
                                            comparatorType='date' 
                                            orders={fOrders.agreements_toChaseDetails} 
                                            direction="desc" 
                                            history={props.history} />
                                    </Collapse>

                                    
                                    <DashItem panel={panel} handleClick={handlePanelChange} handle="agreements_toChase" heading="Chase Outstanding Agreements" value={fOrders.agreements_toChase.length} />
                                    <Collapse in={panel == 'agreements_toChase'}>
                                        <OrdersSnippet 
                                            header="Order" 
                                            comparator="agreement_chased" 
                                            comparatorHeader="Last Chased" 
                                            comparatorType='date' 
                                            orders={fOrders.agreements_toChase} 
                                            direction="desc" 
                                            history={props.history} />
                                    </Collapse>

                                    
                                    <DashItem panel={panel} handleClick={handlePanelChange} handle="agreements_toChaseUnpaid" heading="Chase Unpaid Invoices" value={fOrders.agreements_toChaseUnpaid.length} />
                                    <Collapse in={panel == 'agreements_toChaseUnpaid'}>
                                        <OrdersSnippet 
                                            header="Order" 
                                            comparator="invoice_chased" 
                                            comparatorHeader="Last Chased" 
                                            comparatorType='date' 
                                            orders={fOrders.agreements_toChaseUnpaid} 
                                            direction="desc" 
                                            history={props.history} />
                                    </Collapse>


                                </Collapse>
                            </List>
                        </div>

                    </Grid>
                    
                    <Grid item xs={12} sm={4}>

                        <Typography variant="h6" className={classes.title}>
                            Commission &amp; Onboarding
                        </Typography>
                        <div className={classes.listWrapper}>
                            <List>
                                
                                


                                <ListItem button onClick={()=>handleChangeRoute('orders/chargepointsincomplete?tab=0')}>
                                    <ListItemText primary="Missing charge point information"/>
                                    <ListItemSecondaryAction><div className={classes.listValue}><b>{stats.socketsIncomplete}</b></div></ListItemSecondaryAction>
                                </ListItem>
                                
                                <DashItem panel={panel} handleClick={handlePanelChange} handle="agreements_bookCommission" heading="Book commissioning" value={{
                                    Ords: fOrders.agreements_bookCommission.length,
                                    // Sckts: totalOrdersSockets(fOrders.agreements_bookCommission),
                                    // Val: totalOrdersValue(fOrders.agreements_bookCommission)
                                }} />
                                <Collapse in={panel == 'agreements_bookCommission'}>
                                    <OrdersSnippet 
                                    header="Order" 
                                    comparator="commission_scheduled" 
                                    comparatorHeader="Scheduled" 
                                    comparatorType='date' 
                                    orders={fOrders.agreements_bookCommission} 
                                    direction="asc" 
                                    history={props.history} />
                                </Collapse>
                                
                                <DashItem panel={panel} handleClick={handlePanelChange} handle="agreements_commissionUpcoming" heading="Upcoming commissioning" value={{
                                    Ords: fOrders.agreements_commissionUpcoming.length,
                                    // Sckts: totalOrdersSockets(fOrders.agreements_commissionUpcoming),
                                    // Val: totalOrdersValue(fOrders.agreements_commissionUpcoming)
                                }} />
                                <Collapse in={panel == 'agreements_commissionUpcoming'}>
                                    <OrdersSnippet 
                                    header="Order" 
                                    comparator="commission_scheduled" 
                                    comparatorHeader="Scheduled" 
                                    comparatorType='date' 
                                    orders={fOrders.agreements_commissionUpcoming} 
                                    direction="asc" 
                                    history={props.history} />
                                </Collapse>
                                
                                <DashItem panel={panel} handleClick={handlePanelChange} handle="agreements_commissionOverdue" heading="Commissioning overdue" value={{
                                    Ords: fOrders.agreements_commissionOverdue.length,
                                    // Sckts: totalOrdersSockets(fOrders.agreements_commissionOverdue),
                                    // Val: totalOrdersValue(fOrders.agreements_commissionOverdue)
                                }} />
                                <Collapse in={panel == 'agreements_commissionOverdue'}>
                                    <OrdersSnippet 
                                    header="Order" 
                                    comparator="commission_scheduled" 
                                    comparatorHeader="Scheduled" 
                                    comparatorType='date' 
                                    orders={fOrders.agreements_commissionOverdue} 
                                    direction="asc" 
                                    history={props.history} />
                                </Collapse>
                                
                                <DashItem panel={panel} handleClick={handlePanelChange} handle="agreements_requireOnboarding" heading="Require onboarding" value={fOrders.agreements_requireOnboarding.length} />
                                <Collapse in={panel == 'agreements_requireOnboarding'}>
                                    <OrdersSnippet 
                                    header="Order" 
                                    comparator="backoffice_invited" 
                                    comparatorHeader="Invite Sent" 
                                    comparatorType='date' 
                                    orders={fOrders.agreements_requireOnboarding} 
                                    direction="asc" 
                                    history={props.history} />
                                </Collapse>
                            </List>
                        </div>

                        <Typography variant="h6" className={showNetwork ? classes.title : classes.collapsedTitle}>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}>Charge Revenue</div>
                                {!showNetwork && <IconButton size="small" onClick={() => setShowNetwork(true)}><ExpandMoreIcon /></IconButton>}
                                {showNetwork && <Refresh size="small" handleClick={handleFetchNetwork} loading={loading.network} />}
                            </div>
                        </Typography>
                        
                        <Collapse in={showNetwork}>
                            <div className={classes.listWrapper}>
                                <List>
                                    <ListItem button onClick={()=>handleChangeRoute('statements')}>
                                        <ListItemText primary="Due for remittance"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>£{networkStats.statementsAwaiting.toLocaleString('en-GB')}</div></ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem button onClick={()=>handleChangeRoute('statements')}>
                                        <ListItemText primary="Statements awaiting payment"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>£{networkStats.statementsPending.toLocaleString('en-GB')}</div></ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem button onClick={()=>handleChangeRoute('statements')}>
                                        <ListItemText primary="Statements paid this month"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>£{networkStats.statementsPaidMonth.toLocaleString('en-GB')}</div></ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </div>
                        </Collapse>
                    </Grid>
                    
                    <Grid item xs={12} sm={4}>

                        <Typography variant="h6" className={showNetwork ? classes.title : classes.collapsedTitle}>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}>VendElectric Network</div>
                                {!showNetwork && <IconButton size="small" onClick={() => setShowNetwork(true)}><ExpandMoreIcon /></IconButton>}
                                {showNetwork && <Refresh size="small" handleClick={handleFetchNetwork} loading={loading.network} />}
                            </div>
                        </Typography>
                        <Collapse in={showNetwork}>
                            <div className={classes.listWrapper}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Units on IMS"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>{stats.veosUnits}</div></ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Sockets on IMS"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>{stats.veosConnectors}</div></ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Commissioned charge points"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>{networkStats.points}</div></ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Currently idle"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>{map.idle}</div></ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Currently charging"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>{map.charging}</div></ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Outages within 7 days"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>{map.fault}</div></ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Charge sessions this month"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>{networkStats.sessionsMonth}</div></ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Registered app users"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>{networkStats.users}</div></ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="New app registrations this month"/>
                                        <ListItemSecondaryAction><div className={classes.listValue}>{networkStats.usersMonth}</div></ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </div>
                        </Collapse>


                        {/* <Typography variant="h6" className={classes.title}>
                            Support
                        </Typography>
                        <div className={classes.listWrapper}>
                            <List>
                                <ListItem button onClick={()=>handleChangeRoute('tickets')}>
                                    <ListItemText primary="My open tickets"/>
                                    <ListItemSecondaryAction><div className={classes.listValue}>{stats.myTickets}</div></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem button onClick={()=>handleChangeRoute('tickets?status=1')}>
                                    <ListItemText primary="Open tickets"/>
                                    <ListItemSecondaryAction><div className={classes.listValue}>{stats.allTickets}</div></ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </div> */}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default withRouter(Dashboard)