import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';

import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({

}));


const TicketConfirmDialog = props => {

    const theme = useTheme();
    const styles = useStyles(theme);
    

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title" maxWidth='xs'>
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <Typography>{props.body}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button color="secondary" variant="contained" onClick={props.handleAccept} disabled={props.loading}>{props.acceptLabel || 'Confirm'}{props.loading && <CircularProgress color="inherit" size={24} style={{marginLeft: 10}} />}</Button>
            </DialogActions>
        </Dialog>
    )

}

export default TicketConfirmDialog