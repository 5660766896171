import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../../styles';

import { 
    Grid, 
    Typography, 
    Paper, 
    CircularProgress, 
    Button, 
    Dialog, 
    DialogContent, 
    DialogActions, 
    DialogTitle, 
    FormControlLabel,
    Checkbox,
    FormControl,
    Avatar,
    Divider
} from '@material-ui/core';
import GlobalContext from '../../../../context/global-context';
import Helpers from '../../../global/helpers';
import Quickcrumbs from '../../../global/Quickcrumbs';
import UserResetPassword from './resetPassword';





const SaveDialog = props => {
    const { handleConfirm, handleCancel } = props;

    return (
        <Dialog onClose={props.handleCancel} aria-labelledby="dirty-warning-dialog-title" open={true} maxWidth={'xs'} fullWidth>
            <DialogTitle>CONFIRM SAVE</DialogTitle>
            <DialogContent>Have you double checked your changes? Pressing 'Yes' will make your changes live</DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>No</Button>
                <Button onClick={handleConfirm} color="secondary">Yes</Button>
            </DialogActions>
        </Dialog>
    )
}


const User = props => {

    const theme = useTheme();
    const styles = useStyles(theme);
    const context = useContext(GlobalContext);

    const id_user = props.match.params.id;
    const [ data, setData ] = useState([]);
    const [ dataOrg, setDataOrg ] = useState([]);
    const [ dirtyData, setDirtyData ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    const [ showSave, setShowSave ] = useState(false);
    const [ showDiscard, setShowDiscard ] = useState(false);


    
    const [ sectors, setSectors ] = useState([]);
    const [ sectorsLoading, setSectorsLoading ] = useState(true);


    useEffect(() => {

        context.apiRequest(`system/user/${id_user}`).then(
            res => {
                setData(res.result);
                setDataOrg(JSON.parse(JSON.stringify(res.result)));
                setLoading(false);
            },
            err => {
                setLoading(false);
                context.showAlert('error', err.msg);
            }
        )

        context.apiRequest(`system/sectors/`).then(
            res => {
                setSectors(res.result);
                setSectorsLoading(false);
            },
            err => {
                setSectorsLoading(false);
                context.showAlert('error', err.msg);
            }
        )

    }, [])


    useEffect(() => {
        let dirty = false;
        if(JSON.stringify(data) != JSON.stringify(dataOrg))
            dirty = true;

        setDirtyData(dirty);
    }, [data])

    const handleSave = () => {
        setLoading(true);
        setShowSave(false);
        context.apiRequest(`system/user`, 'PUT', data).then(
            res => {
                setData(res.result);
                setDataOrg(JSON.parse(JSON.stringify(res.result)));
                setLoading(false);
                context.showAlert('success', 'User saved')
            },
            err => {
                setLoading(false);
                context.showAlert('error', err.msg);
            }
        )
    }


    //
    // PERMISSIONS
    //

    const hasPermission = sector => {
        const idx = data.system_sectors.findIndex(ss => ss.id_system_sector === sector.id_system_sector);
        return idx > -1
    }
    
    const hasAllPermissions = () => {
        var hasAll = true;
        sectors.map(s => {
            if(data.system_sectors.findIndex(ss => ss.id_system_sector === s.id_system_sector) < 0)
                hasAll = false;
        })
        return hasAll;
    }


    const handlePermissionToggle = sector => {
        const newData = JSON.parse(JSON.stringify(data));
        const idx = newData.system_sectors.findIndex(ss => ss.id_system_sector === sector.id_system_sector);
        if(idx > -1){
            newData.system_sectors.splice(idx, 1);
        } else {
            newData.system_sectors.push({
                id_system_sector: sector.id_system_sector,
                id_user: newData.id_user,
                system_sector_handle: sector.system_sector_handle
            });
        }
        setData(newData);
    }

    const handlePermissionSelectAll = () => {
        const newData = JSON.parse(JSON.stringify(data));
        newData.system_sectors = [];
        sectors.map(s => {
            newData.system_sectors.push({
                id_system_sector: s.id_system_sector,
                id_user: newData.id_user,
                system_sector_handle: s.system_sector_handle
            });
        })
        setData(newData);
    }

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Users',
                            route: '/settings/users'
                        },
                        {
                            name: props.match.params.id,
                            route: '/settings/users/'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">User</Typography>

                </div>

                {loading && <CircularProgress color="primary" />}

                {!loading &&
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Avatar style={{width: 80, height: 80, margin: theme.spacing(3)}}></Avatar>
                                <div style={{flex: 1}}>
                                    <div className={styles.label}>
                                        <Typography variant="subtitle1" color="textSecondary">Username:&nbsp;</Typography>
                                        <Typography variant="subtitle1">{data.username}</Typography>
                                    </div>
                                    <div className={styles.label}>
                                        <Typography variant="subtitle1" color="textSecondary">Name:&nbsp;</Typography>
                                        <Typography variant="subtitle1">{data.first_name} {data.last_name}</Typography>
                                    </div>
                                    <div className={styles.label}>
                                        <Typography variant="subtitle1" color="textSecondary">Last login:&nbsp;</Typography>
                                        <Typography variant="subtitle1">{Helpers.SQLtoUTCDate(data.last_login, true)}</Typography>
                                    </div>
                                    <div className={styles.label}>
                                        <Typography variant="subtitle1" color="textSecondary">System admin:&nbsp;</Typography>
                                        <Typography variant="subtitle1">{data.role === 1 ? 'Yes' : 'No'}</Typography>
                                    </div>
                                    <div className={styles.label}>
                                        <Typography variant="subtitle1" color="textSecondary">Active:&nbsp;</Typography>
                                        <Typography variant="subtitle1">{data.active === 1 ? 'Yes' : 'No'}</Typography>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        {data.active === 1 && 
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6" style={{marginBottom: theme.spacing(2)}}>Permissions</Typography>

                                <Paper style={{padding: theme.spacing(2)}}>
                                    <FormControl fullWidth>
                                        <FormControlLabel
                                            control={<Checkbox checked={hasAllPermissions()} onChange={handlePermissionSelectAll} name={'select_all'} />}
                                            label={'Select all'}
                                        />
                                    </FormControl>

                                    <Divider />

                                    {sectors.map(s => (
                                        <FormControl key={s.id_system_sector} fullWidth>
                                            <FormControlLabel
                                                control={<Checkbox checked={hasPermission(s)} onChange={() => handlePermissionToggle(s)} name={s.system_sector_handle} />}
                                                label={s.system_sector_name}
                                            />
                                        </FormControl>
                                    ))}
                                </Paper>

                                <div className={styles.flexSpaced}>
                                    <UserResetPassword id_user={data.id_user} />
                                    <Button variant="contained" color="secondary" onClick={() => setShowSave(true)} disabled={dirtyData === false}>Save</Button>
                                </div>
                            </Grid>
                        }
                    </Grid>
                }
                

            </div>

            {showSave && <SaveDialog handleConfirm={handleSave} handleCancel={() => setShowSave(false)} />}

        </div>
    )

}

export default User


