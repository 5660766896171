import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { Typography, Button, Grid, ListItem, List, ListItemText, Card, CardActionArea, CardContent, Avatar, Divider, Badge } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import EvCircularProgess from '../global/EvCircularProgress';
import Helpers from '../global/helpers';
import { red, orange } from '@material-ui/core/colors';

import TicketSummary from './ticket_summary';
import TicketAdd from './ticket_add';
import TicketSearch from './ticket_search';

import { 
    ticketMeta, 
    ticketsGet,
    ticketAdd
 } from '../../actions/ticketsActions';
import globalContext from '../../context/global-context';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden'
    },
    wrapper: {
        display: 'flex',
        padding: theme.spacing(2),
        flexDirection: 'column',
        flex: 1,
        height: 'auto',
        overflow: 'auto'
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    attentionLevel0: {
        color: 'inherit'
    },
    attentionLevel1: {
        backgroundColor: orange[500],
        color: theme.palette.getContrastText(orange[500])
    },
    attentionLevel2: {
        backgroundColor: red[500],
        color: theme.palette.getContrastText(red[500])
    },
    history: {
        maxHeight: 300,
        overflow: 'auto'
    }
}));




// Tickets Dashboard class
const TicketsDashboard = props => {

    const theme = useTheme();
    const styles = useStyles(theme);

    const tickets = props.tickets.tickets;
    const ticketMeta = props.tickets.meta;
    const status = props.tickets.status;

    const [ showNewTicket, setShowNewTicket ] = useState(false);

    const context = useContext(globalContext);

    useEffect(() => {
        props.ticketsGet();
        props.ticketMeta();
    }, [])

    
    const newTicket = () => {
        setShowNewTicket(true);
    }

    const filteredTickets = (status, ticketUnit) => {
        return tickets.filter(t => t.id_ticket_status === status && t.id_ticket_unit == ticketUnit).length;
    }

    const newTickets = (status, ticketUnit) => {
        return tickets.filter(t => t.id_ticket_status === status && t.id_ticket_unit == ticketUnit && t.triggers.length === 0).length;
    }

    const filteredTicketsAttentionLevel = (status, ticketUnit) => {
        
        const findStatus = ticketMeta.statuses.find(s => s.id_ticket_status === status);
        const filtered = tickets.filter(t => t.id_ticket_status === status && t.id_ticket_unit == ticketUnit);
        let attentionLevel = 0;

        if(typeof findStatus != 'undefined' && findStatus.management_status != 1)
            filtered.map(t => {

                // Check for visit bookings first to neutralise any priority KPIs
                const nextVisit = t.visits.find(v => v.ticket_visit_reported === null);
                if(typeof nextVisit != 'undefined'){
                    const bookedDay = Helpers.SQLtoUTCDateObj(nextVisit.date_booked);
                    const endOfBookedDay = new Date().setTime(bookedDay.getTime() + (24*60*60*1000));
                    if(new Date() > endOfBookedDay)
                        attentionLevel = 2;
                } else {

                    if(t.priority_score > t.kpi_mins)
                        attentionLevel = 2;
                    if(t.priority_score > Math.floor(t.kpi_mins * .66) && attentionLevel == 0)
                        attentionLevel = 1;

                }
            })

        return attentionLevel;

    }

    const filteredUnits = () => {
        if(props.user == null || props.tickets.meta === null || typeof props.tickets.meta === 'undefined')
            return []

        const filtered = props.tickets.meta.units.filter(u => {
            if(props.user.ticket_units.findIndex(uu => uu.id_ticket_unit == u.id_ticket_unit) > -1)
                return true;

            if(props.user.ticket_unit_groups.findIndex(uug => uug.id_ticket_unit_group == u.id_ticket_unit_group) > -1)
                return true;

            return false;
        });
        return filtered;
    }

    const filteredStatuses = (unit) => {
        if(props.user == null)
            return []

        const filtered = props.tickets.meta.statuses.filter(s => {

            if(props.user.ticket_unit_groups.findIndex(uug => uug.id_ticket_unit_group == unit.id_ticket_unit_group) > -1)
                return true;

            return s.management_status != 1
        });
        return filtered;
    }

    const onTimeResponses = unit_id => {
        const ontime = ticketMeta.ontime.find(ot => ot.id_ticket_unit === unit_id);
        if(typeof ontime === 'undefined')
            return `0%`;
        else
            return `${(ontime.perc_ontime*100).toFixed(0)}%`;
    }

    const myOnTimeResponses = () => {
        if(typeof props.user.ontime === 'undefined')
            return `0%`;
        else
            return `${(props.user.ontime.perc_ontime*100).toFixed(0)}%`;
    }

    const myTickets = tickets.filter(t => t.id_ticket_status < 4 && t.id_user_assigned === props.user.id_user)


    return (
        <div className={styles.root}>

            

            {/* LOADING STATUS */}
            {(status.tickets == 'loading' || status.meta == 'loading') && 
                <div className={styles.wrapper}>
                    <div style={{flex: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <EvCircularProgess size={60} color='primary' />
                        <Typography variant="h6" color="textSecondary" style={{marginLeft: 10}}>{status.tickets == 'loading' ? 'Loading Tickets' : status.meta == 'loading' ? 'Loading EV Network & System' : 'Nearly There'}</Typography>
                    </div>
                </div>
            }

            {/* LOADING STATUS */}
            {(status.tickets == 'error' || status.meta == 'error') && 
                <div className={styles.wrapper}>
                    <div style={{flex: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <WarningIcon color="error" />
                        <Typography variant="h6" color="error" style={{marginLeft: 10}}>Something went wrong loading either the tickets or system data. Try reloading.</Typography>
                    </div>
                </div>
            }



            {/* LOADING STATUS */}
            {(status.tickets == 'ok' && status.meta == 'ok') && 
            <div className={styles.wrapper}>


                {/* TICKETS SUMMARY TASKBAR */}

                <div className={styles.taskbar}>

                    <div style={{display:'flex', flexDirection: 'row'}}>
                        <Typography variant="h4" style={{marginBottom: theme.spacing(1)}}>Ticket Overview</Typography>
                    </div>

                    <div style={{flex: 1}}></div>

                    <div style={{display:'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end'}}>
                        <Button color="secondary" variant="contained" onClick={newTicket}>New</Button>
                    </div>

                    {/* <Button onClick={() => props.history.push('/tickets/monitor')}>Go to tickets</Button> */}

                </div>


                <TicketSearch tickets={tickets} openMode={false} />


                <Grid container spacing={3}>


                    <Grid item xs={12} sm={5} md={4} lg={3}>

                        <Card>
                            <CardContent style={{minHeight: 400}}>

                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <Avatar style={{width: 60, height: 60, marginRight: 30, backgroundColor: Helpers.getHexColor(Helpers.getInitials(props.user.name))}}>{Helpers.getInitials(props.user.name)}</Avatar>
                                    <div style={{flex: 1}}>
                                        <Typography gutterBottom variant="h6" component="h2" color="textSecondary">My Summary</Typography>
                                        <Typography gutterBottom variant="subtitle1" color="textSecondary">30 day on time score: {myOnTimeResponses()}</Typography>
                                    </div>
                                </div>

                                <Divider style={{marginTop: 10, marginBottom: 10}} />

                                <Typography gutterBottom variant="subtitle1" color="textSecondary">Owned Tickets</Typography>

                                <div>
                                    {myTickets.map(ticket =>
                                        <TicketSummary key={ticket.id_ticket} ticket={ticket} handleOpenTicket={() => props.history.push(`/tickets/monitor/${ticket.ticket_ref}?unit=0`)} openMode={true} /> 
                                    )}
                                    {myTickets.length == 0 && <Typography color="textSecondary" variant="caption">No owned tickets</Typography>}
                                </div>

                                <Typography gutterBottom variant="subtitle1" color="textSecondary">My History</Typography>
                                {props.user.ticket_history.length === 0 && <Typography color="textSecondary" variant="caption">No history</Typography>}
                                {props.user.ticket_history.length> 0 &&
                                    <div className={styles.history}>
                                        <List>
                                            {props.user.ticket_history.map((th, idx) => (
                                                <ListItem key={idx} button onClick={() => props.history.push(`/tickets/monitor/${th.ticket_ref}`)}>
                                                    <ListItemText secondary={`#${th.ticket_ref} - ${Helpers.SQLtoUTCDate(th.created, true)}`} primary={Helpers.truncate(th.log_entry, 100)} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                } 
                            </CardContent>
                        </Card>

                    </Grid>



                    <Grid item xs={12} sm={7} md={8} lg={9}>

                        <Grid container spacing={3}>


                            {filteredUnits().map((u, idx) => (
                                <Grid item xs={12} md={6} lg={4} key={idx}>

                                    <Card onClick={() => props.history.push('/tickets/monitor?unit='+u.id_ticket_unit)}>
                                        <CardActionArea>
                                            <CardContent style={{minHeight: 200}}>
                                                <Typography gutterBottom variant="h6" component="h2" color="textSecondary">{u.ticket_unit_name}</Typography>

                                                <List dense={true}>

                                                {filteredStatuses(u).map((status, sidx) => (

                                                    <ListItem key={sidx} className={styles[`attentionLevel${filteredTicketsAttentionLevel(status.id_ticket_status, u.id_ticket_unit)}`]}>
                                                        <ListItemText primary={status.ticket_status_name} />
                                                        <Typography color={filteredTickets(status.id_ticket_status, u.id_ticket_unit) == 0 ? 'textSecondary' : 'inherit'}>
                                                            {newTickets(status.id_ticket_status, u.id_ticket_unit) > 0 && 
                                                                <Badge 
                                                                    badgeContent={newTickets(status.id_ticket_status, u.id_ticket_unit)}
                                                                    color="primary"
                                                                    style={{marginRight: 15}}
                                                                    ><FiberNewIcon /></Badge>}
                                                            {filteredTickets(status.id_ticket_status, u.id_ticket_unit)}
                                                        </Typography>
                                                    </ListItem>

                                                ))}

                                                <ListItem>
                                                    <ListItemText primary={'30 Day on time responses'} />
                                                    <Typography color="textSecondary">{onTimeResponses(u.id_ticket_unit)}</Typography>
                                                </ListItem>
                                                    
                                                </List>

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>

                                </Grid>


                            ))}
                        </Grid>
                    </Grid>
                </Grid>


            </div>}

            
            {showNewTicket && <TicketAdd ticketMeta={ticketMeta} handleClose={() => setShowNewTicket(false)} handleAddTicket={props.ticketAdd} />}
        </div>
    )

}

const mapStateToProps = state => {
    return {
        tickets: state.tickets,
        user: state.user.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ticketAdd: ticket => {
            dispatch(ticketAdd(ticket));
        },
        ticketsGet: () => {
            dispatch(ticketsGet());
        },
        ticketMeta: metadata => {
            dispatch(ticketMeta(metadata))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TicketsDashboard)
