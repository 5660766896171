import React, {useContext, useState} from 'react';
import { useTheme, makeStyles, MenuItem } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Helpers from '../../global/helpers';

import Quickcrumbs from '../../global/Quickcrumbs';
import SearchBar from '../../../components/searchBar';
import AutoTable from '../../../components/autoTable';
import globalContext from '../../../context/global-context';
import ProductionChargepointConfiguration from './production/chargepoint_configuration_log';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}));

const ProductionChargepoints = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    
    const headCells = ["id_unit","identity","ukev_num","sim_no","model","model_group","date_configured"]
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [chargepoint, setChargepoint] = useState(null);

    const loadData = async search => {
        setLoading(true);
        setError(null);
        setData([]);

        try {
            
            const res = await context.apiRequest(`/service_consumer/production/chargepoints${typeof search !== 'undefined' ? `?search=${search}` : ``}`, 'GET');
            if(res.success){
                setData(res.result);

                if(res.result.length > 100)
                    setError('You search returned over 100 results, try refining your search criteria');
            }
            
        } catch (err) {

            setError(typeof err === 'string' ? err : typeof err?.msg === 'string' ? err.msg : JSON.stringify(err));
            setData([]);
            
        } finally {
            setLoading(false);
        }
        
    }


    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>

                    <Quickcrumbs 
                        crumbs={[
                            {
                                name: 'Service Users',
                                route: '/service_users'
                            },
                            {
                                name: 'Production Chargepoints',
                                route: '/service_users/production/chargepoints'
                            }
                        ]}
                        history={props.history}
                    />

                    <div className={classes.taskbar}>
                        <SearchBar 
                            label="Search by Serial No, UKEV No or SIM No" 
                            onSearch={loadData} 
                            onClear={loadData}
                            shrink={false}
                         />
                    </div>

                    {error && <Alert severity="error">{error}</Alert>}

                    <AutoTable 
                        rows={data} 
                        idCol="id_unit"
                        headCells={headCells}
                        loading={loading}
                        handleRowClick={setChargepoint}
                        cellReplacementProps={{
                            'date_configured': (val, id) => Helpers.toLocaleDateTime(val)
                        }}
                    />

                </div>
            </div>

            {chargepoint && <ProductionChargepointConfiguration log={chargepoint} onClose={() => setChargepoint(null)} />}
        </React.Fragment>
    )
}
export default ProductionChargepoints;