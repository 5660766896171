import React from 'react';
import GlobalContext from '../../context/global-context';
import LocationSummaryPopUp from './LocationSummaryPopUp';
import LocationMarkerSelectionPopUp from './LocationMarkerSelectionPopUp';
import { GoogleMap, Marker, MarkerClusterer, InfoBox } from '@react-google-maps/api';
import CustomStyle from './mapStyle';
import Pins from './pins';
import SiteFilter from './siteFilter';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ActivityIndicator from '../global/ActivityIndicator';
import { CircularProgress } from '@material-ui/core';

let timeout;
let changeFilterTimout;

const DEFAULT_CAMERA = {
  lat: 51.5007,
  lng: 0.1246,
}

class Map extends React.Component {

    static contextType = GlobalContext

    clusterStyle = [{
        url: Pins.blue,
        height: 20,
        width: 20,
        textColor: '#fff',
        textSize: 12,
        anchor: [10, 10], 
        anchorText: [0, 0],
        zIndex: 10
    }];

    constructor(props){
        super(props);

        this.state = {
            location: DEFAULT_CAMERA,
            camera: DEFAULT_CAMERA,
            activeMarker: null,
            selectedPlace: null,
            showInfoWindow: false,
            showSelectionWindow: false,
            pointsReport: [],
            sitesReport: [],
            sites: [],
            filter: {
              idle: true,
              fault: true,
              charging: true,
              withdrawn: false,
              versions: []
            },
            filteredSites: [],
            loadingMap: true,
            showFilter: false,
            versions: [],
            pinned: [],
            infoBox: null,
            downloading: false
        }
    }


    //
    //  Watch for the user location, if it hasn't already been set then also set the map camera to where you are.
    //

    getUserLocation = () => {

        navigator.geolocation.watchPosition(
          position => {
    
            let newLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
    
            this.setState({ location: newLocation, camera: this.state.camera == DEFAULT_CAMERA?newLocation:this.state.camera });
    
          }, err => {
              console.log(err);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
        )

    }


    //
    //  Get the site and charge point data to pump into the map
    //

    fetchPointReport = () => {

      console.log('fetching data');
      
      const localSites = JSON.parse(localStorage.getItem('sites'));

      if(localSites != null && (new Date(localSites.date) > new Date()))
        this.setState({sites: localSites.sites}, () => {
          this.setVersions();
        });


      this.context.fetchMapPoints()
      .then(
        res => {
          
          let pinned = localStorage.getItem('mp');
          if(pinned != null)
            pinned = JSON.parse(pinned);
          else
            pinned = [];

          this.setState({sites: res.data, pinned: pinned}, () => {

            this.setVersions();

            let expires = new Date();
            expires.setDate(expires.getDate() + 1);

            // Store the data, it's alot of data
            localStorage.setItem('sites', JSON.stringify({sites: this.state.sites, date: expires}));

            if(timeout != null && timeout > 0)
              clearTimeout(timeout);

            timeout = setInterval(this.fetchPointReport, 60000);

            this.getFilteredSites();

          });

        },
        err => {
          console.log(err);
        }
      )
    }


    //
    // Run through the data and create the versions that you can filter by
    //

    setVersions = () => {
      let versions = [];
      this.state.sites.map(s => {
        s.Points.map(p => {
          if(versions.indexOf(p.Version) == -1)
            versions.push(p.Version);
        })
      })

      this.setState({versions: versions.sort()});
    }
    

    
    //
    //  Get the map marker that will apply
    //
    getMarker = (site) => {
      return site.Marker;
    }

 
    renderMap = (filteredSites) => {

        const getGeo = (site) => {
          let geo = {
            latitude: 0,
            longitude: 0
          }

          if(typeof site.Geo != 'undefined' && site.Geo != null)
            geo = {
              latitude: site.Geo.latitude,
              longitude: site.Geo.longitude
            }
        
          return geo;
        }

        return (
          <GoogleMap
            id='map'
            mapContainerStyle={styles.map}
            zoom={7}
            center={this.state.camera}
            options={{
              styles: CustomStyle,
              disableDefaultUI: true,
              maxZoom: 18
            }}
            google={this.props.google}
            onClick={this.onMapPressHandler}
          >

            <Marker 
              position={this.state.location} 
              icon={{
                url: Pins.user, 
                anchor: new window.google.maps.Point(16,16), 
                scaledSize: new window.google.maps.Size(32,32)}}
              zIndex={999}
            />
            
            {/* <MarkerClusterer options={{imagePath:"../../assets/images/m/m", styles: this.clusterStyle}} onClick={this.clusterClickHandler} gridSize={1}> */}
            <MarkerClusterer options={{styles: this.clusterStyle}} onClick={this.clusterClickHandler} gridSize={1}>
            {
            (clusterer) => filteredSites.map((site) => (
              <Marker icon={{url: this.getMarker(site), anchor: new window.google.maps.Point(10,10), scaledSize: new window.google.maps.Size(20,20)}} key={site.ChargeSiteID} position={{lat: getGeo(site).latitude, lng: getGeo(site).longitude}} onClick={(props, marker, e) => {this.onMarkerPressHandler(props, marker, e, site)}} clusterer={clusterer} site={site} zIndex={10} onMouseOver={() => {this.setState({infoBox: site})}} />))
            }
            </MarkerClusterer>

            
            {this.state.infoBox && <InfoBox
              position={{lat: getGeo(this.state.infoBox).latitude, lng: getGeo(this.state.infoBox).longitude}}
              onCloseClick={() => {
                this.setState({infoBox: null})
              }}
            >
              <div style={{ backgroundColor: '#111111', opacity: 0.75, padding: 12, marginTop: 20, borderRadius: 6 }}>
                <div style={{ fontSize: 16, color: '#ffffff' }}>
                  {this.state.infoBox.GroupSchemeName}
                </div>
              </div>
            </InfoBox>}
            
          </GoogleMap>)
      }

      

    onMarkerPressHandler = (props, marker, e, site) => {

        this.setState({
            activeMarker: marker,
            selectedPlace: this.state.sites.find(s => s.ChargeSiteID === site.ChargeSiteID),
            showInfoWindow: true,
            showSelectionWindow: false
        });

    }

      

    onMapPressHandler = () => {

        this.setState({
            activeMarker: null,
            selectedPlace: null,
            showInfoWindow: false,
            showSelectionWindow: false,
            selectableSites: null,
            infoBox: null
        });

    }

    selectLocation = (site) => {
        this.setState({
          activeMarker: null,
          selectedPlace: this.state.sites.find(s => s.ChargeSiteID === site.ChargeSiteID),
          showInfoWindow: true,
          selectableSites: null,
          showSelectionWindow: false
        });

      }

    clusterClickHandler = (e) => {    
        const lat = e.center.lat();
        const lng = e.center.lng();
        const clusters = e.markerClusterer.clusters;
        if(clusters.length === 1 && clusters[0].markers.length > 1){
    
          let select = true;
          clusters[0].markers.map(m => {
            if(lat != m.position.lat())
              select = false;
            if(lng != m.position.lng())
              select = false;
          })
    
          if(select){
            this.setState({
              showInfoWindow: false,
              selectedPlace: null,
              selectableSites: this.state.sites.filter(p => {
                if(p.Geo == null)
                  return false;
                if(parseFloat(p.Geo.latitude).toFixed(4) == lat.toFixed(4) && parseFloat(p.Geo.longitude).toFixed(4) == lng.toFixed(4)){
                return true;
                }
                return false;
              }),
              showSelectionWindow: true
            });
          }

          
        }
      }

    handleTogglePin = (site) => {
      const pins = [...this.state.pinned];
      const idx = pins.indexOf(site.ChargeSiteID);
      if(idx > -1)
        pins.splice(idx, 1);
      else
        pins.push(site.ChargeSiteID);

      this.setState({pinned: pins}, () => {
        localStorage.setItem('mp', JSON.stringify(pins));
      });
    }

    clearPinned = () => {
      this.setState({pinned: []}, () => {
        localStorage.removeItem('mp');
      });
    }

    openFilter = () => {
      this.setState({showFilter: true})
    }

    closeFilter = () => {
      this.setState({showFilter: false})
    }


    changeFilter = (setting, value) => {

        let filter = {
          ...this.state.filter
        };
        filter[setting] = value;
        this.setState({filter: filter, loadingMap: true}, () => {

          if(typeof changeFilterTimout !== 'undefined')
            clearTimeout(changeFilterTimout);

          changeFilterTimout = setTimeout(this.getFilteredSites, 1000);

        });

    }

    toggleVersionFilter = (version) => {
      let versions = this.state.filter.versions;
      if(version != 'ALL'){
        const idx = versions.findIndex(v => v == version);
        if(idx > -1){
          versions.splice(idx, 1);
        } else {
          versions.push(version);
        }
      } else {
        versions = [];
      }
      const filter = {
        ...this.state.filter,
        versions: versions
      };
      this.setState({filter: filter, loadingMap: true}, () => {

        if(typeof changeFilterTimout !== 'undefined')
          clearTimeout(changeFilterTimout);

        changeFilterTimout = setTimeout(this.getFilteredSites, 1000);

      });
    }

    

    getFilteredSites = () => {

      let sites = [...this.state.sites];
      let filteredSites = [];

      sites.map(site => {
        if(site.ChargeSiteID != 10003){

          // The site wide state will be determined by this
          let idle = false, charging = false, version = false, fault = false, withdrawn = false;
  
          site.Points.map(p => {
  
            // Setup some variables for this point on it's own
            let c = false, v = false, f = false, w = false;
  
            if(p.DeploymentStatus == 'WITHDRAWN')
              w = true;
  
            if(p.Status == 'CHARGING')
              c = true;
            
            if(p.Status == 'FAULT_CONDITION' || p.LastHeartBeat == null){
              f = true;
            } else {
              const d = p.LastHeartBeat.split(/-|T|:|Z|\./);
              const heart = new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]));
              let mins = 0;
              var diff = (new Date() - heart);
              // The total mins since last heartbeat
              mins = Math.floor((Math.abs(diff)/1000)/60);
  
              if(mins > 120)
                f = true;
            }
  
            if(this.state.filter.versions.indexOf(p.Version) > -1)
              v = true;
  
            // If the point is withdrawn set the site marker to withdrawn
            if(w)
              withdrawn = w //this.state.filter.withdrawn;  
            
            // If the point is version set the site marker to version if that filter is on
            if(v && !w)
              version = this.state.filter.versions.length > 0;
  
            // If the point is charging set the site marker to charging
            if((c && !f) && !w)
              charging = c //this.state.filter.charging;
  
            // If the point is fault set the site marker to fault
            if(f && !w)
              fault = f //this.state.filter.fault;
            
            // If this point isn't charging, in fault or a lesser version then it is idle
            if(!c && !f && !w)
              idle = true;      
            
          })
  
          site.Marker = Pins.white;
  
          // Matches the withdrawn
          if(withdrawn && this.state.filter.withdrawn != null)
            site.Marker = Pins.black;
          
          // Is idle
          if(idle)
            site.Marker = Pins.blue;
  
          // Is charging
          if(charging && this.state.filter.charging)
            site.Marker = Pins.green;
  
          // Is faulty
          if(fault && this.state.filter.fault)
            site.Marker = Pins.red;
  
          // Is Faulty and either idle or charging
          if((charging || idle) && fault && this.state.filter.fault)
            site.Marker = Pins.orange;
  
          // Matches a selected version 
          if(version && this.state.filter.versions != null)
            site.Marker = Pins.yellow;

          // Is a pinned site
          if(this.state.pinned.indexOf(site.ChargeSiteID) > -1)
            site.Marker = Pins.purple

          
          

          // Decide if we want this site or not based on marker
          if(this._showSite(site))
            filteredSites.push(site);
          
        }

      })

      this.setState({filteredSites: filteredSites, loadingMap: false})
    }


    downloadCSV = (filteredSites) => {
      if(filteredSites.length > 0){
          this.setState({downloading: true});
          let csvStr = '';
  
          // Add the headers
          csvStr = csvStr + 'Group Scheme,';
          csvStr = csvStr + 'Address,';
          csvStr = csvStr + 'Charge Points,';
          csvStr = csvStr + 'Total Sockets,';
          csvStr = csvStr + 'Charge Points Offline,';
          csvStr = csvStr + 'Total Sockets Offline,';
          csvStr = csvStr + 'Technical Contacts,';


          csvStr = csvStr.substring(0,csvStr.length - 1);
          csvStr = csvStr + "\n";
  
          // Add the data
          filteredSites.map(row => {

              csvStr = csvStr + row.GroupSchemeName + ',';

              let address = '';
              address += this._isStrNull(row.AddressLine2)?'': '  ' + row.AddressLine2.replace(',', '');
              address += this._isStrNull(row.AddressLine1)?'': '  ' + row.AddressLine1.replace(',', '');
              address += this._isStrNull(row.AddressLine3)?'': '  ' + row.AddressLine3.replace(',', '');
              address += this._isStrNull(row.Town)?'': '  ' + row.Town.replace(',', '');
              address += this._isStrNull(row.County)?'': '  ' + row.County.replace(',', '');
              address += this._isStrNull(row.Country)?'': '  ' + row.Country.replace(',', '');
              address += this._isStrNull(row.PostCode)?'': '  ' + row.PostCode.replace(',', '');

              let points = '';
              let totalPoints = 0;
              let pointsOffline = '';
              let totalPointsOffline = 0;
              row.Points.map((point) => {
                let p = point.CPID + ' (' + point.Version + ' ) | ';
                points+= p;
                totalPoints++;
                if(this._inFault(point)){
                  pointsOffline+=p;
                  totalPointsOffline++;
                }

              })
              points = points.substring(0,points.length - 3);
              pointsOffline = pointsOffline.substring(0,pointsOffline.length - 3);
              
              let technicalContacts = '';
              if(typeof row.TechnicalContacts != 'undefined')
                technicalContacts+= this._isStrNull(row.TechnicalContacts.name)?'': row.TechnicalContacts.name
                technicalContacts+= this._isStrNull(row.TechnicalContacts.position)?'': ' | ' + row.TechnicalContacts.position
                technicalContacts+= this._isStrNull(row.TechnicalContacts.email)?'': ' | ' + row.TechnicalContacts.email
                technicalContacts+= this._isStrNull(row.TechnicalContacts.telephone)?'': ' | ' + row.TechnicalContacts.telephone;

              csvStr = csvStr + '"' + address + '",';
              csvStr = csvStr + '"' + points + '",';
              csvStr = csvStr + '"' + totalPoints + '",';
              csvStr = csvStr + '"' + pointsOffline + '",';
              csvStr = csvStr + '"' + totalPointsOffline + '",';
              csvStr = csvStr + '"' + technicalContacts + '",';

              csvStr = csvStr.substring(0,csvStr.length - 1);
              csvStr = csvStr + "\n";
          })
  
          csvStr = csvStr.substring(0, csvStr.length - 1);

          const filename = 'MapData';

          var blob = new Blob([csvStr], { type: 'text/csv' });
  
          if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          console.log('using window navigator');
          window.navigator.msSaveOrOpenBlob(blob, +filename+'.csv');
          this.setState({downloading: false});
          } else { // for Non-IE (chrome, firefox etc.)
          console.log('not using window navigator');
          let a = document.createElement('a');
          a.style.display = 'none';
          //a.href = 'data:application/octet-stream;base64,'+btoa(csvStr);
          a.href = 'data:text/csv;charset=utf-8,\ufeff'+csvStr;
          a.download = filename+'.csv';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.setState({downloading: false});
          }
      }
    }

    _isStrNull = (str) => {
      return typeof str != 'undefined' && str != null && str.length > 0 && str != 'null' ? false : true;
    }

    _inFault = (p) => {
      let result = false;
      
      if(p.Status == 'FAULT_CONDITION' || p.LastHeartBeat == null){
          result = true;
      } else {
          const d = p.LastHeartBeat.split(/-|T|:|Z|\./);
          const heart = new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]));
          let mins = 0;
          var diff = (new Date() - heart);
          // The total mins since last heartbeat
          mins = Math.floor((Math.abs(diff)/1000)/60);

          if(mins > 120)
              result = true;
      }

      return result;
    }


    _showSite = (site) => {
      switch(site.Marker){
        case Pins.black:
          return this.state.filter.withdrawn;
        case Pins.purple:
          return true;
        case Pins.yellow:
          return this.state.filter.versions.length > 0;
        case Pins.red:
        case Pins.orange:
          return this.state.filter.fault;
        case Pins.green:
          return this.state.filter.charging;
        case Pins.blue:
          return this.state.filter.idle;
        default:
          return false;
      }
    }

    componentDidMount(){
        this.getUserLocation();
        this.fetchPointReport();
    }

    

    componentWillUnmount (){
      if(timeout != null && timeout > 0)
        clearTimeout(timeout);
    }

    render(){

      const isPinned = site => {
        return this.state.pinned.indexOf(site.ChargeSiteID) > -1
      }

        return(
            <div style={styles.container}>
                <div style={styles.inner}>

                    <div style={styles.map}>

                        {this.state.loadingMap && <div style={{position: 'absolute', height: '100%', width: '100%', zIndex: 998, backgroundColor: 'rgba(255,255,255,.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress color="inherit" /></div>}

                        <SiteFilter filter={this.state.filter} changeFilter={this.changeFilter} show={this.state.showFilter} open={this.openFilter} close={this.closeFilter} versions={this.state.versions} toggleVersionFilter={this.toggleVersionFilter} clearPinned={this.clearPinned} />
                        <IconButton color="inherit" aria-label="open" onClick={() => this.downloadCSV(this.state.filteredSites)} style={styles.download}>{!this.state.download && <CloudDownloadIcon />}{this.state.downloading && <div style={styles.downloadLoading}><ActivityIndicator /></div>}</IconButton>

                        {(this.context.mapsLoaded && !this.state.loadingMap) && this.renderMap(this.state.filteredSites)}

                        {this.state.selectedPlace && <LocationSummaryPopUp site={this.state.selectedPlace} visible={this.state.showingInfoWindow} goToLocation={this.props.goToLocation} history={this.props.history} location={this.state.location} handleTogglePin={this.handleTogglePin} pinned={isPinned(this.state.selectedPlace)} />}

                        {this.state.selectableSites && <LocationMarkerSelectionPopUp sites={this.state.selectableSites} visible={this.state.showSelectionWindow} selectLocation={this.selectLocation} history={this.props.history}/>}


                    </div>

                </div>
            </div>
        );
    }

}


const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    topBar: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center',
        marginBottom: '10px'
    },
    map: {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    markerCallout: {
        borderWidth: 2,
        borderColor: '#acd448',
        borderRadius: 12,
        backgroundColor: '#000000'
    },
    download: {
      position: 'absolute',
      bottom: 20,
      right: 20,
      backgroundColor: '#fff',
      color: '#444',
      borderRadius: 6,
      boxShadow: '0 0 6px rgba(0,0,0,.3)',
      zIndex: 999,
    },
    downloadLoading: {
      height: 20,
      width: 20,
      borderRadius: 10,
      backgroundColor: '#888888'
    }
}

export default Map