import React from 'react';
import GlobalContext from '../context/global-context';
import ActivityIndicator from './global/ActivityIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class Transactions extends React.Component {

    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                source: 'veos',
                start: new Date().toISOString().split('T')[0],
                end: new Date().toISOString().split('T')[0],
                scheme: '',
                unpaid: true
            },
            loadedFields: {
                source: null,
                start: null,
                end: null,
                scheme: null,
                unpaid: null
            },
            schemes: [],
            data: [],
            loading: false,
            downloading: false,
            showDialog: false
        };
    }

    handleChange = (e, callback = () => { }) => {
        let fields = { ...this.state.fields };
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields }, () => {
            typeof callback === 'function' && callback();
        });
    }

    handleCheckboxChange = (e, callback = () => { }) => {
        let fields = { ...this.state.fields };
        fields[e.target.name] = e.target.checked;
        this.setState({ fields: fields }, () => {
            typeof callback === 'function' && callback();
        });
    }

    closeDialog = () => {
        this.setState({ showDialog: false });
    }

    componentDidMount() {
        this.loadSchemes();
    }

    loadSchemes = () => {
        let fields = {
            ...this.state.fields,
            scheme: ''
        };
        this.setState({ schemes: [], fields: fields }, () => {
            this.context.fetchGroupSchemes(this.state.fields.source)
                .then(
                    res => {
                        this.setState({ schemes: res.result })
                    },
                    err => {
                        console.log(err)
                    }
                )
        })
    }

    loadData = () => {
        this.setState({ loading: true, loadedFields: { ...this.state.fields }, data: [] });
        this.context.fetchChargeSessions(this.state.fields)
            .then(
                res => {
                    console.log(res);
                    const data = this.state.fields.unpaid ? res.result.filter(x => x.PaymentMade == null) : res.result;
                    this.setState({ data: data, loading: false })
                },
                err => {
                    this.setState({ data: [], loading: false })
                }
            );
    }

    dirty = () => {
        if (this.state.fields.source != this.state.loadedFields.source)
            return true;

        if (this.state.fields.start != this.state.loadedFields.start)
            return true;

        if (this.state.fields.end != this.state.loadedFields.end)
            return true;

        if (this.state.fields.scheme != this.state.loadedFields.scheme)
            return true;

        return false;
    }


    downloadCSV = () => {
        if (this.dirty()) {
            this.setState({ showDialog: true });
        } else {
            if (this.state.data.length > 0) {
                this.setState({ downloading: true });
                let csvStr = '';

                // Add the headers
                csvStr = csvStr + 'Session ID,';
                csvStr = csvStr + 'Point,';
                csvStr = csvStr + 'Start,';
                csvStr = csvStr + 'End,';
                csvStr = csvStr + 'Energy,';
                csvStr = csvStr + 'Total Gross,';
                csvStr = csvStr + 'Total Net,';
                csvStr = csvStr + 'Total VAT,';
                csvStr = csvStr + 'Net Service Charge,';
                csvStr = csvStr + 'Net Management Fee,';
                csvStr = csvStr + 'Management VAT,';
                csvStr = csvStr + 'Amount Due,';
                csvStr = csvStr + 'Currency,';
                csvStr = csvStr + 'Transaction ID,';
                csvStr = csvStr + 'Method,';
                csvStr = csvStr + 'Verified,';
                csvStr = csvStr + 'Paid,';


                csvStr = csvStr.substring(0, csvStr.length - 1);
                csvStr = csvStr + "\n";

                // Add the data
                this.state.data.map(row => {

                    csvStr = csvStr + row.ChargeSessionId + ',';
                    csvStr = csvStr + 'EV-' + row.EvPoint + ',';
                    csvStr = csvStr + this.SQLtoUTCDate(row.StartTime) + ',';
                    csvStr = csvStr + this.SQLtoUTCDate(row.EndTime) + ',';
                    csvStr = csvStr + row.Energy + ',';
                    csvStr = csvStr + '£' + Math.round(row.TotalGross * 100) / 100 + ',';
                    csvStr = csvStr + '£' + Math.round(row.TotalNet * 100) / 100 + ',';
                    csvStr = csvStr + '£' + Math.round(row.TotalVAT * 100) / 100 + ',';
                    csvStr = csvStr + '£' + Math.round(row.NetManagementServiceCharge * 100) / 100 + ',';
                    csvStr = csvStr + '£' + Math.round(row.NetManagementFee * 100) / 100 + ',';
                    csvStr = csvStr + '£' + Math.round(row.ManagementVAT * 100) / 100 + ',';
                    csvStr = csvStr + '£' + Math.round(row.SubscriberDue * 100) / 100 + ',';
                    csvStr = csvStr + row.Currency + ',';
                    csvStr = csvStr + row.TransactionID + ',';
                    csvStr = csvStr + row.Method + ',';
                    csvStr = csvStr + (row.PaymentVerified !== null ? this.SQLtoUTCDate(row.PaymentVerified) : false) + ',';
                    csvStr = csvStr + (row.PaymentMade != null ? this.SQLtoUTCDate(row.PaymentMade, false) : '') + ',';

                    csvStr = csvStr.substring(0, csvStr.length - 1);
                    csvStr = csvStr + "\n";

                })

                csvStr = csvStr.substring(0, csvStr.length - 1);

                const scheme = this.state.schemes.find(s => s.groupSchemeID == this.state.fields.scheme);
                const filename = (typeof scheme != 'undefined' ? scheme.groupSchemeName : 'ALL') + '_' + this.state.fields.start + '_' + this.state.fields.end;

                var blob = new Blob([csvStr], { type: 'text/csv' });

                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                    console.log('using window navigator');
                    window.navigator.msSaveOrOpenBlob(blob, 'transactions_' + filename + '.csv');
                    this.setState({ downloading: false });
                } else { // for Non-IE (chrome, firefox etc.)
                    console.log('not using window navigator');
                    let a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = 'data:application/octet-stream;base64,' + btoa(csvStr);
                    a.download = 'transactions_' + filename + '.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    this.setState({ downloading: false });
                }
            }
        }
    }

    SQLtoUTCDate = (str, datetime = true) => {
        const d = str.split(/-|T|:|\.|Z/);
        const jD = new Date(Date.UTC(d[0], (d[1] - 1), d[2], d[3], d[4], d[5], d[6]));
        if (datetime)
            return jD.toLocaleDateString('en-GB') + ' ' + jD.toLocaleTimeString('en-GB');

        return jD.toLocaleDateString('en-GB');
    }

    render() {
        const schemes = this.state.schemes.map(s => (<MenuItem value={s.groupSchemeID} key={s.groupSchemeID}>{s.groupSchemeName}</MenuItem>));
        const prefix = this.state.loadedFields.source == 'veos' ? 'UKEV' : 'EV-';

        return (
            <div style={styles.container}>
                <div style={styles.inner}>

                    <h2>Transactions</h2>

                    <div style={styles.taskbar}>

                        <div>

                            <FormControl style={styles.textField}>
                                <InputLabel shrink htmlFor="age-label-placeholder">
                                    Source
                                </InputLabel>
                                <Select
                                    value={this.state.fields.source}
                                    onChange={(e) => { this.handleChange(e, this.loadSchemes) }}
                                    input={<Input name="source" id="source-label-placeholder" />}
                                    displayEmpty
                                    name="source"
                                >
                                    <MenuItem value={'veos'}>VendElectric</MenuItem>
                                    <MenuItem value={'wavemasta'}>Wavemasta</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField name="start" label="Start" type="date" value={this.state.fields.start} onChange={this.handleChange} style={styles.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <TextField name="end" label="End" type="date" value={this.state.fields.end} onChange={this.handleChange} style={styles.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <FormControl style={styles.textField}>
                                <InputLabel shrink htmlFor="age-label-placeholder">
                                    Scheme
                                </InputLabel>
                                <Select
                                    value={this.state.fields.scheme}
                                    onChange={this.handleChange}
                                    input={<Input name="scheme" id="site-label-placeholder" />}
                                    displayEmpty
                                    name="scheme"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {schemes}
                                </Select>
                            </FormControl>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="unpaid"
                                        checked={this.state.fields.unpaid}
                                        onChange={this.handleCheckboxChange}
                                        value={this.state.fields.unpaid}
                                        color="secondary"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />}
                                label="Unpaid only" />

                            <Button variant="contained" color={this.dirty() ? 'secondary' : 'default'} onClick={this.loadData}>Go{this.state.loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>

                        </div>
                        <div>
                            <Button variant="contained" color="secondary" onClick={this.downloadCSV}>Download to .CSV{this.state.downloading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
                        </div>
                    </div>

                    <Paper style={styles.root}>
                        <Table style={styles.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Session ID</TableCell>
                                    <TableCell align="right">Point</TableCell>
                                    <TableCell align="right">Start</TableCell>
                                    <TableCell align="right">End</TableCell>
                                    <TableCell align="right">Energy</TableCell>
                                    <TableCell align="right">Total Gross</TableCell>
                                    <TableCell align="right">Total Net</TableCell>
                                    <TableCell align="right">Total VAT</TableCell>
                                    <TableCell align="right">Net Service Charge</TableCell>
                                    <TableCell align="right">Net Management Fee</TableCell>
                                    <TableCell align="right">Management VAT</TableCell>
                                    <TableCell align="right">Amount Due</TableCell>
                                    <TableCell align="right">Currency</TableCell>
                                    <TableCell align="right">Transaction ID</TableCell>
                                    <TableCell align="right">Method</TableCell>
                                    <TableCell align="right">Verified</TableCell>
                                    <TableCell align="right">Paid</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.data.map(row => (
                                    <TableRow key={row.ChargeSessionId}>
                                        <TableCell component="th" scope="row">{row.ChargeSessionId}</TableCell>
                                        <TableCell align="right">{prefix}{row.EvPoint}</TableCell>
                                        <TableCell align="right">{this.SQLtoUTCDate(row.StartTime)}</TableCell>
                                        <TableCell align="right">{this.SQLtoUTCDate(row.EndTime)}</TableCell>
                                        <TableCell align="right">{row.Energy}</TableCell>
                                        <TableCell align="right">&pound;{Math.round(row.TotalGross * 100) / 100}</TableCell>
                                        <TableCell align="right">&pound;{Math.round(row.TotalNet * 100) / 100}</TableCell>
                                        <TableCell align="right">&pound;{Math.round(row.TotalVAT * 100) / 100}</TableCell>
                                        <TableCell align="right">&pound;{Math.round(row.NetManagementServiceCharge * 100) / 100}</TableCell>
                                        <TableCell align="right">&pound;{Math.round(row.NetManagementFee * 100) / 100}</TableCell>
                                        <TableCell align="right">&pound;{Math.round(row.ManagementVAT * 100) / 100}</TableCell>
                                        <TableCell align="right">&pound;{Math.round(row.SubscriberDue * 100) / 100}</TableCell>
                                        <TableCell align="right">{row.Currency}</TableCell>
                                        <TableCell align="right">{row.TransactionID}</TableCell>
                                        <TableCell align="right">{row.Method}</TableCell>
                                        <TableCell align="right">{row.PaymentVerified !== null && this.SQLtoUTCDate(row.PaymentVerified)}</TableCell>
                                        <TableCell align="right">{row.PaymentMade != null && this.SQLtoUTCDate(row.PaymentMade, false)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>

                    {this.state.data.length == 0 && <div style={styles.nodata}>No Data</div>}

                    <Dialog
                        open={this.state.showDialog}
                        onClose={this.closeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Reload the data"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You changed the values in the taskbar but didn't reload the data, reload before you download.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeDialog} color="secondary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>


                </div>
            </div>
        );
    }

}

const styles = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
        borderRadius: 25,
        height: 24,
        width: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default Transactions