import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import GlobalContext from '../../context/global-context';
import { useStyles } from './styles';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    TextField,
    Avatar,
    Typography,
    ButtonGroup,
    CircularProgress,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

const useCustomStyles = makeStyles(theme => ({
    section: {
        marginBottom: 20
    },
    comment: {
        display: 'flex', 
        flexDirection: 'row',
        marginBottom: 20
    },
    check: {
        marginRight: 10
    }
}));


const blankVisit = () => {
    return {
        id_group_scheme: null,
        id_site: null,
        ticket_visit_description: '',
        date_booked: null
    }
}


const TicketVisitAdd = props => {

    const [visit, setVisit] = useState(blankVisit());

    const theme = useTheme();
    const styles = useStyles(theme);
    const customStyles = useCustomStyles(theme);

    const context = useContext(GlobalContext);

    useEffect(() => {
        if(props.ticket.sites.length === 1)
            setSite(props.ticket.sites[0].id_site);
    }, [])

    const handleAdd = () => {
        if(valid()){
            props.add(visit);
        }
    }

    const handleDiscard = () => {
        props.onClose();
    }

    const valid = () => {
        let valid = true;
        Object.keys(visit).map(v => {
            if(visit[v] === null || visit[v].length === 0 && visit[v] === '')
                valid = false;
        })
        return valid;
    }

    const handleChange = e => {
        let newVisit = {...visit};
        newVisit[e.target.name] = e.target.value;
        setVisit(newVisit);
    }

    const setSite = id_site => {
        let newVisit = {...visit};
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined'){
            newVisit.id_group_scheme = s.GroupSchemeID;
            newVisit.id_site = s.ChargeSiteID;
            setVisit(newVisit);
        }
    }
    
    const getSiteName = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return s.SiteName;

        return '';
    }
    
    const getSiteAddress = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return `${s.AddressLine1}, ${s.Town}. ${s.PostCode}`;

        return '';
    }

    return (
        <Dialog open={props.show} onClose={handleDiscard} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'xs'}>
            <DialogTitle id="form-dialog-title">Add a visit</DialogTitle>

            <DialogContent>

                <div className={customStyles.section}>

                    <TextField
                        name="ticket_visit_description"
                        label="Visit reason"
                        type="text"
                        className={styles.inputField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={visit.ticket_visit_description}
                        onChange={handleChange}
                    />

                </div>

                <div className={customStyles.section}>

                    <InputLabel shrink={true}>Which site</InputLabel>
                    <List dense>
                        {props.ticket.sites.map((s, idx) => (
                            <ListItem key={idx} button onClick={() => setSite(s.id_site)}>
                                {visit.id_site === s.id_site ? <CheckBoxIcon className={customStyles.check} /> : <CheckBoxOutlineBlankIcon className={customStyles.check} />}
                                <ListItemText primary={getSiteName(s.id_site)} secondary={getSiteAddress(s.id_site)} />
                            </ListItem>
                        ))}
                    </List>

                </div>

                <div className={customStyles.section}>

                    <TextField
                        name="date_booked"
                        label="When"
                        type="date"
                        className={styles.inputField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={visit.date_booked}
                        onChange={handleChange}
                    />

                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleDiscard}>Cancel</Button>
                <Button color="secondary" variant="contained" onClick={handleAdd} disabled={!valid()}>Add</Button>
            </DialogActions>
        </Dialog>
    )

}

export default TicketVisitAdd