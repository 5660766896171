import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const OcpiTariffSettings = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'OCPI Tariffs',
                            route: '/settings/ocpi_tariffs'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">OCPI Tariffs</Typography>

                </div>

                <Crud
                    heading='OCPI Tariffs'
                    uri='ocpi_tariffs'
                    idCol='id_tariff'
                    displayColumns={[
                        { 
                            name: 'id_party', 
                            type: 'select',
                            display_name: 'Party',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'tariff_description', 
                            type: 'string',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'price', 
                            type: 'string',
                            editable: true,
                            required: true,
                            display_name: 'Price (don\'t include £ symbol)'
                        },
                        { 
                            name: 'currency', 
                            type: 'select',
                            display_name: 'Currency',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'active_from', 
                            type: 'date',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'active_to', 
                            type: 'date',
                            editable: true,
                            required: false
                        },
                        { 
                            name: 'power_from', 
                            type: 'number',
                            editable: true,
                            display_name: 'Power from (kWh) e.g. 22kWh'
                        },
                        { 
                            name: 'power_to', 
                            type: 'number',
                            editable: true,
                            display_name: 'Power to (kWh) e.g. 50kWh'
                        }
                    ]}
                    selectors={[
                        {
                            uri: 'ocpi_parties',
                            name: 'Party',
                            col_name: 'id_party',
                            selector_name: 'id_party',
                            display_col: 'identity'
                        },
                        {
                            uri: 'currencies',
                            name: 'Currency',
                            col_name: 'currency',
                            selector_name: 'code',
                            display_col: 'code'
                        }
                    ]}
                    canRemove={false}
                />

            </div>

        </div>
    )

}

export default OcpiTariffSettings
