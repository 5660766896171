import React from 'react';
import {InputLabel, makeStyles, TextField, useTheme, Select, MenuItem, InputAdornment, CircularProgress, IconButton} from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  large: {
    padding: '30px 22px 14px'
  },
  largeLabel: {
    transform: 'translate(22px, 26px) scale(1)'
  },
  inputLabel: {
    fontSize: '.8rem',
    marginBottom: 5
  },
  inputNoLabel: {
    padding: '8px 10px 9px'
  },
  inputLargeNoLabel: {
    padding: '16px 20px 17px'
  },
  select: {
    width: '100%'
  }
}))

const InputField = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {validation, name, value, handleChange, variant, size} = props;
  const type = props.type || 'text';
  const hoverLabel = props.hoverLabel || false;
  const items = props.items || [];
  const placeholder = props.placeholder || null;
  const disabled = props.disabled === true || false;
  const multiline = props.multiline === true || false;
  const rows = typeof props.rows !== 'undefined' ? props.rows : 0;

  const isText = (type === 'textfield' || type === 'select') ? false: true;
  
  const inputProps = () => {
    let inputClasses = [];
    if(typeof size !== 'undefined' && size.toUpperCase() === 'LARGE')
      inputClasses.push(classes.large);

    if(typeof hoverLabel !== 'undefined' && hoverLabel === true)
      if(typeof size !== 'undefined' && size.toUpperCase() === 'LARGE')
        inputClasses.push(classes.inputLargeNoLabel);
      else
        inputClasses.push(classes.inputNoLabel);

    let inputPs = {classes: {}};
    if(!isText && type === 'select')
      inputPs.classes.select = clsx(inputClasses);
    else
      inputPs.classes.input = clsx(inputClasses);


    //
    //  Adornments
    //


    if(typeof props.handleClear === 'function' && value !== '')
      inputPs['endAdornment'] = <InputAdornment position="end"><IconButton onClick={props.handleClear} size="small"><CloseIcon style={{fontSize: 18}}/></IconButton></InputAdornment>;

    
    if(typeof props.loading !== 'undefined' && props.loading === true)
      inputPs['endAdornment'] = <InputAdornment position="end"><CircularProgress color='inherit' size={18} /></InputAdornment>;
      

    //
    // Read Only
    //
    if(typeof props.readOnly !== 'undefined')
      inputPs['readOnly'] = props.readOnly;


    //
    //  onBlur
    //
    if(typeof props.onBlur === 'function')
      inputPs['onBlur'] = props.onBlur;

    return inputPs;
  }
  
  const inputLabelProps = () => {
    let props = {};
    if(typeof size !== 'undefined' && size.toUpperCase() === 'LARGE')
      props = {
        classes: {
          root: classes.largeLabel
        }
      }

    return props;
  }

  const getLabel = () => {
    if(typeof validation === 'undefined' || typeof validation.display_name === 'undefined')
      return name;
    else
      return validation.display_name;
  }

  const handleKeyDown = e => {
    if(typeof props.handleKeyDown === 'function')
      props.handleKeyDown(e);
  }

  return (
        <div className="input-control">
          {hoverLabel && <InputLabel className={classes.inputLabel}>{getLabel()}</InputLabel>}
          {isText && 
            <TextField 
              type={type} 
              name={name} 
              label={hoverLabel ? false : getLabel()} 
              variant={typeof variant !== 'undefined' ? variant : 'filled'} 
              fullWidth 
              value={value} 
              onChange={handleChange} 
              onKeyDown={handleKeyDown} 
              error={validation.dirty && validation.error} 
              helperText={validation.dirty && validation.error ? validation.msg : ''}
              InputProps={inputProps()}
              InputLabelProps={inputLabelProps()}
              placeholder={placeholder}
              autoComplete="off"
              disabled={disabled}
              multiline={multiline}
              rows={rows}
            />
          }
          {type === 'select' && 
            <Select  
              name={name} 
              label={hoverLabel ? false : getLabel()} 
              variant={typeof variant !== 'undefined' ? variant : 'filled'} 
              value={value} 
              onChange={handleChange} 
              error={validation.dirty && validation.error} 
              className={classes.select}
              inputProps={inputProps()}
              disabled={disabled}
            >
              {
                items.map((item, idx) => {
                  if(typeof item === 'object')
                    return (<MenuItem value={item.key} key={idx}>{item.value}</MenuItem>)
                  else
                    return (<MenuItem value={item} key={idx}>{item}</MenuItem>)
                })
              }
            </Select>
          }
        </div>
  );
}

export default InputField;
