import React from 'react';
import GlobalContext from '../../context/global-context';
import EVPoint from './evPoint';
import { Typography, Button } from '@material-ui/core';

class Summary extends React.Component {

    static contextType = GlobalContext

    timer = null;

    constructor(props){
        super(props);

        this.state = {
            time: null
        }
    }

    getGroupScheme = () => {
        const scheme = this.props.groupSchemes.find(s=>s.groupSchemeID==this.props.commission.groupSchemeID);
        if(typeof scheme != 'undefined')
            return scheme.groupSchemeID + ': ' + scheme.groupSchemeName;

        return '';
    }

    evPoints = () => {
        if(typeof this.props.evPoints != 'undefined')
            return this.props.evPoints.map((p) => (
                <EVPoint 
                    key={p.EVPointID} 
                    point={p} 
                    changePointPower={this.props.changePointPower} 
                    addPointChargeTime={this.props.addPointChargeTime} 
                    stopPointChargeTime={this.props.stopPointChargeTime} 
                    removePoint={this.props.removePoint}
                    busyPoints={this.props.busyPoints} 
                    commission={this.props.commission} />
            ))
        return '';
    }

    
    getSite = () => {
        const site = this.props.sites.find(s=>s.ChargeSiteID==this.props.commission.siteID);
        if(typeof site != 'undefined')
            return site.ChargeSiteID + ': ' + site.SiteName;

        return '';
    }

    
    getSiteAddress = () => {
        const site = this.props.sites.find(s=>s.ChargeSiteID==this.props.commission.siteID);
        if(typeof site != 'undefined')
            return (
                <div style={{marginBottom: 15}}>
                    <div>{site.AddressLine1}
                    {(site.AddressLine2 != null && site.AddressLine2.length > 0) && ', '+site.AddressLine2}
                    {(site.AddressLine3 != null && site.AddressLine3.length > 0) && ', '+site.AddressLine3}
                    {(site.Town != null && site.Town.length > 0) && ', '+site.Town}
                    {(site.County != null && site.County.length > 0) && ', '+site.County}
                    {(site.PostCode != null && site.PostCode.length > 0) && ', '+site.PostCode}
                    {(site.Country != null && site.Country.length > 0) && ', '+site.Country}</div>
                </div>
            );

        return '';
    }
    
    
    componentDidMount(){
        this.timer = setInterval(() => {
            this.setState({time: new Date()}); 
        }, 1000);
    }

    componentWillUnmount() {
        if(typeof this.timer != 'undefined')
            clearInterval(this.timer)
    }

    render(){
        return(
            <div style={styles.container}>
                
                <Typography variant="h5">Commission Summary</Typography>

                {/* {(this.context.commission.groupSchemeID == null && this.context.commission.siteID == null && this.context.commission.EVPointIDs.length == 0) &&
                    <h2>Add in some info and I will fill up</h2>
                } */}
                

                {this.props.evPoints.length > 0 &&
                    <div style={styles.section}>
                        <Typography variant="h6">Points</Typography>
                        <div style={styles.pointsGrid}>
                            {this.evPoints()}
                        </div>
                    </div>
                }

                {this.props.commission.groupSchemeID &&
                    <div style={styles.section}>
                        <Typography variant="h6">Scheme: <span style={styles.bold}>{this.getGroupScheme()}</span></Typography>
                        <Button variant="contained" color="primary" onClick={this.props.removeScheme}>Change Scheme</Button>
                    </div>
                }

            
                {this.props.commission.siteID &&
                    <div style={styles.section}>
                        <Typography variant="h6">Site: <span style={styles.bold}>{this.getSite()}</span></Typography>
                        {this.getSiteAddress()}
                        <Button variant="contained" color="primary" onClick={this.props.removeSite}>Change Site</Button>
                    </div>
                }
            </div>
        );
    }

}


const styles  = {
    container: {
        display: 'flex',
        minHeight: '100%',
        flexDirection: 'column',
        width: '80%', 
        padding: '60px 0'
    },
    header: {
        color: '#484848',
        fontWeight: 300
    },
    signinWrapper: {
      width: '80%',
      maxWidth: 300,
      display: 'flex',
      flexDirection: 'column'
    },
    formError: {
      color: '#F26A6A',
      fontSize: 14,
      marginBottom: 15,
      textAlign: 'center',
      display: 'block'
    },
    inputWrapper: {
      height: 54,
      width: '100%',
      backgroundColor: '#ebebeb',
      borderRadius: 12,
      marginBottom: 10,
      justifyContent: 'center',
      display: 'flex'
    },
    input: {
        fontSize: 16, 
        flex: 1,
        paddingLeft: 20,
        border: 'none'
    },
    inputError: {
      boxShadow: 'inset 0 0 0 1px #F26A6A'
    },
    inputErrorMsg: {
      color: '#F26A6A',
      fontSize: 11,
      paddingLeft: 20,
      marginBottom: 8,
      display: 'block',
      width: '100%'
    },
    button: {
        backgroundColor: '#00adef',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer'
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 15,
        width: '100%'
    },
    buttonDefault: {
        backgroundColor: '#666666',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer'
    },
    loading: {
      backgroundColor: '#00adef',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    site: {
        cursor: 'pointer'
    },
    sites: {
        backgroundColor: '#fff',
        border: '1px solid #d2d2d2',
        padding: 12,
        marginTop: 20,
        maxHeight: 400,
        width: '100%',
        overflow: 'auto',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: 30
    },
    sectionHeader: {
        display: 'block',
        color: '#484848',
        width: '100%',
        fontWeight: 300
    },
    bold: {
        fontWeight: 900
    },
    pointsGrid: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        width: '100%',
    },
}

export default Summary