import React from 'react';
import GlobalContext from '../../context/global-context';
import CheckIcon from '@material-ui/icons/Check';
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Button, useTheme } from '@material-ui/core';

const SiteFilter = props => {

    const theme = useTheme();

    return (

        <GlobalContext.Consumer>
            {context => (
                <div style={styles.container}>
                    {!props.show && <IconButton aria-label="open" onClick={props.open}><TuneIcon style={{color: '#444'}} /></IconButton>}

                    {props.show && 
                    <div style={styles.wrapper}>
                        <span style={styles.header}>Status</span>
                        <IconButton aria-label="close" onClick={props.close} style={styles.close}><CloseIcon /></IconButton>
                        <div style={styles.options}>
                            <div style={styles.option}>
                                <span style={styles.label}>Idle</span>
                                <IconButton aria-label="idle" style={Object.assign({}, styles.button, props.filter.idle && styles.selected)} onClick={() => {props.changeFilter('idle', !props.filter.idle)}}>
                                    <CheckIcon />
                                </IconButton>
                            </div>
                            <div style={styles.option}>
                                <span style={styles.label}>Charging</span>
                                <IconButton aria-label="charging" style={Object.assign({}, styles.button, props.filter.charging && styles.selected)} onClick={() => {props.changeFilter('charging', !props.filter.charging)}}>
                                    <CheckIcon />
                                </IconButton>
                            </div>
                            <div style={styles.option}>
                                <span style={styles.label}>Fault</span>
                                <IconButton aria-label="fault" style={Object.assign({}, styles.button, props.filter.fault && styles.selected)} onClick={() => {props.changeFilter('fault', !props.filter.fault)}}>
                                    <CheckIcon />
                                </IconButton>
                            </div>
                            {/* <div style={styles.option}>
                                <span style={styles.label}>Withdrawn</span>
                                <IconButton aria-label="fault" style={Object.assign({}, styles.button, props.filter.withdrawn && styles.selected)} onClick={() => {props.changeFilter('withdrawn', !props.filter.withdrawn)}}>
                                    <CheckIcon />
                                </IconButton>
                            </div> */}
                        </div>


                        <span style={styles.seperator}> - OR - </span>

                            
                        <span style={styles.header}>Version</span>
                        
                        <div style={styles.options}>
                                <div style={styles.option}>
                                    <span style={styles.label}>All</span>
                                    <IconButton aria-label="idle" style={Object.assign({}, styles.button, (props.filter.versions.length == 0) && styles.selected)} onClick={() => {props.toggleVersionFilter('ALL')}}>
                                        <CheckIcon />
                                    </IconButton>
                                </div>
                                {props.versions.map(v => (
                                <div style={styles.option} key={v}>
                                    <span style={styles.label}>{v == null?'< V2.00':v}</span>
                                    <IconButton aria-label="idle" style={Object.assign({}, styles.button, (props.filter.versions.indexOf(v) > -1) && styles.selected)} onClick={() => {props.toggleVersionFilter(v)}}>
                                        <CheckIcon />
                                    </IconButton>
                                </div>
                                ))}
                        </div>


                        <Button style={{marginTop: 10}} onClick={props.clearPinned} style={{color: '#444'}}>Clear Pinned</Button>

                    </div>}
                </div>
            )}
        </GlobalContext.Consumer>
    );
}

const styles = {
    container: {
      position: 'absolute',
      top: 20,
      right: 20,
      backgroundColor: '#fff',
      borderRadius: 6,
      boxShadow: '0 0 6px rgba(0,0,0,.3)',
      zIndex: 999,
      maxHeight: '90%',
      overflow: 'auto',
      color: '#444'
    },
    wrapper: {
        display: 'flex',
        height: '100%',
        width: '100%',
        minWidth: 200,
        flexDirection: 'column',
        marginBottom: 20,
    },
    header: {
        marginTop: 20,
        marginBottom: 10,
        marginLeft: 20,
        fontSize: 18,
        fontWeight: 700
    },
    seperator: {
        marginTop: 20,
        marginBottom: 10,
        marginLeft: 20,
        fontSize: 18,
        textAlign: 'center',
        color: '#a2a2a2'
    },
    options: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
        marginLeft: '10%'
    },
    option: {
        width: '100%',
        display: 'flex',
        margin: '5px 0',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    label: {
        flex: 1
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #ddd',
        borderRadius: 4,
        padding: 4
    },
    selected: {
        backgroundColor: '#f44336',
        color: '#fff'
    },
    close: {
      position: 'absolute',
      top: 5,
      right: 0,
      color: '#444'
    }
  };

export default SiteFilter;

