import React, {useContext, useState, useEffect} from 'react';
import { useTheme, TextField, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Modal, Paper } from '@material-ui/core';
import {Alert, Autocomplete} from '@material-ui/lab'
import {makeValidation, isValidForm} from '../global/formHelper';
import Helpers from '../global/helpers';
import InputField from '../../components/inputField';
import globalContext from '../../context/global-context';
import AddOrganisation from '../organisations/add_organisation';
import SageLinkOrganisation from './sage_link_organisation';
import ProgressButton from '../../components/progressButton';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    container: {},
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
}));

const SageOrderImport = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const [organisations, setOrganisations] = useState([]);
    const [selectedOrganisation, setSelectedOrganisation] = useState(null);
    const [matchingOrganisation, setMatchingOrganisation] = useState(null);
    const [showAddOrganisation, setShowAddOrganisation] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [valid, setValid] = useState(false);

    const [form, setForm] = useState({
        id_organisation: null,
        order_name: '',
        manufacturer_ref: '',
        manufacturer_promise_date: '',
        id_sage_order_return: ''
    })

    const [validation, setValidation] = useState(makeValidation([
        {
            name: 'id_organisation',
            display_name: 'Organisation',
            required: true
        },
        {
            name: 'order_name',
            display_name: 'Order Name',
            required: true
        },
        {
            name: 'manufacturer_ref',
            display_name: 'MI Number',
            required: true
        },
        {
            name: 'manufacturer_promise_date',
            display_name: 'Promised Date',
            required: false
        },
        {
            name: 'id_sage_order_return',
            display_name: 'Sage Order ID',
            required: true
        }
    ]))

    const handleInputChange = e => {
        let newForm = JSON.parse(JSON.stringify(form));
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    const handleAddOrganisation = id => {
        setShowAddOrganisation(false);
        getOrganisations();
    }

    const sanitizeDocNo = docNo => {

        let sDocNo = docNo;
        do {
            sDocNo = sDocNo.substring(1);
        } while (sDocNo.charAt(0) === '0');

        return sDocNo;

    }

    const getOrganisations = () => {

        context.fetchOrganisations().then(
            res => {
                setOrganisations(res.result);
            }, 
            err => {
                context.showAlert('error', 'error fetching organisations: ' + JSON.stringify(err.msg));
            }
        )

    }

    const checkForSageOrg = () => {
        if(organisations.length > 0 && props.sageOrder !== null){
            const organisation = organisations.find(o => o.id_sage_customer === +(props.sageOrder.CustomerID))
            if(typeof organisation !== 'undefined'){

                setMatchingOrganisation(organisation);
                if(form.id_organisation === null)
                    setSelectedOrganisation(organisation);

            }
        }
    }


    const handleSubmit = async () => {

        if(valid) {
            setLoading(true);
            setError(null);

            try {

                const res = await context.apiRequest(`/order/import`, 'POST', form);
                
                if(res.success){
                    context.showAlert('success', `Order created VE${res.result}`);

                    if(typeof props.history != 'undefined')
                        props.history.push('/order/'+res.result);
                } else 
                    setError(res.msg);


            } catch (error) {
                setError(typeof error === 'string' ? error : typeof error.msg !== 'undefined' ? error.msg : 'Something went wrong, please try again');
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    }


    useEffect(() => {

        console.log(props.sageOrder.PromisedDeliveryDate)

        if(props.sageOrder !== null){
            let newForm = JSON.parse(JSON.stringify(form));
            newForm.id_organisation = null;
            newForm.order_name = '';
            newForm.manufacturer_ref = sanitizeDocNo(props.sageOrder.DocumentNo);
            newForm.manufacturer_promise_date = props.sageOrder.PromisedDeliveryDate;
            newForm.id_sage_order_return = props.sageOrder.SOPOrderReturnID;
            setForm(newForm);
            checkForSageOrg();
        }

    }, [props.sageOrder])

    useEffect(() => {

        checkForSageOrg();

    }, [organisations])

    useEffect(() => {

        if(selectedOrganisation !== null && typeof selectedOrganisation.id_organisation !== 'undefined'){
            if(props.sageOrder !== null){
                let newForm = JSON.parse(JSON.stringify(form));
                newForm.id_organisation = selectedOrganisation.id_organisation;
                setForm(newForm);
            }
        }

    }, [selectedOrganisation])

    useEffect(() => {
        const isValid = isValidForm(form, validation);
        setValidation(isValid.validation);
        setValid(isValid.isValid);
    }, [form])

    useEffect(() => {
        getOrganisations()
    }, [])


    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Import Order from Sage</DialogTitle>
                <DialogContent>

                    <div className={classes.form}>

                        {error && <Alert severity='error'>{error}</Alert>}
                        
                        <InputField name="order_name" validation={validation.order_name} value={form.order_name} handleChange={handleInputChange} />

                        <Autocomplete
                            options={organisations}
                            getOptionLabel={option => option.organisation_name}
                            value={selectedOrganisation}
                            onChange={(e, newValue) => setSelectedOrganisation(newValue)}
                            id="id_organisation"
                            autoHighlight
                            renderOption={option => (
                                <React.Fragment>
                                    <span style={{marginRight: 10}}>({option.id_organisation})</span>
                                    {option.organisation_name}
                                </React.Fragment>
                            )}
                            renderInput={params => <TextField 
                                {...params} 
                                label="Select Organisation" 
                                variant="filled"
                            />}
                        />

                        {(matchingOrganisation === null && selectedOrganisation === null) && 
                            <div className="flex-row gap-10" style={{justifyContent: 'space-between'}}>
                                <div className='flex'>
                                    <Typography color="error" variant="caption">No organisation for <b>{props.sageOrder.CustomerAccountNumber} - {props.sageOrder.CustomerAccountName}</b> has found linked to an IMS organisation, either select from the above or create new.</Typography>
                                </div>
                                <div>
                                    <Button variant="contained" size="small" onClick={() => setShowAddOrganisation(true)} color="secondary">Create new</Button>
                                </div>
                            </div>
                        }
                        
                        {(matchingOrganisation === null && selectedOrganisation !== null && selectedOrganisation.id_sage_customer === null) && 
                            <div className="flex-row gap-10" style={{justifyContent: 'space-between'}}>
                                <div className='flex'>
                                    <Typography color="error" variant="caption"><b>{selectedOrganisation.organisation_name}</b> is not currently linked to Sage account <b>{props.sageOrder.CustomerAccountNumber} - {props.sageOrder.CustomerAccountName}</b></Typography>
                                </div>
                                <div>
                                    <SageLinkOrganisation 
                                        id_organisation={selectedOrganisation.id_organisation} 
                                        id_sage_customer={props.sageOrder.CustomerID} 
                                        sage_customer_account_number={props.sageOrder.CustomerAccountNumber}
                                        onSuccess={getOrganisations}
                                     />
                                </div>
                            </div>
                        }

                        {(
                            selectedOrganisation !== null && selectedOrganisation.id_sage_customer !== null && 
                            (
                                (matchingOrganisation !== null && selectedOrganisation.id_organisation !== matchingOrganisation.id_organisation) ||
                                (matchingOrganisation === null && selectedOrganisation.id_sage_customer !== props.sageOrder.CustomerID)
                            )
                        ) && 
                            <Typography color="error" variant="caption">You are creating an IMS order against <b>{selectedOrganisation.organisation_name}</b> when the Sage order is against <b>{props.sageOrder.CustomerAccountNumber} - {props.sageOrder.CustomerAccountName}</b></Typography>
                        }

                        <InputField name="manufacturer_ref" validation={validation.manufacturer_ref} value={form.manufacturer_ref} handleChange={handleInputChange} />
                        
                        <TextField
                            name="manufacturer_promise_date"
                            type="date"
                            value={Helpers.SQLtoUTCISODate(form.manufacturer_promise_date)}
                            onChange={handleInputChange}
                            InputLabelProps={{shrink: true,}}
                            label="Promised Date"
                            variant="filled"
                        />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">Cancel</Button>
                    <ProgressButton waiting={loading} onClick={handleSubmit} color="secondary" variant="contained" disabled={!valid}>Import</ProgressButton>
                </DialogActions>
            </Dialog>


            <Modal open={showAddOrganisation} onClose={() => {setShowAddOrganisation(false)}}>
                <Paper style={{position: 'absolute', width: '90%', maxWidth: 500, left: '50%', top: '50%', transform: `translate(-50%, -50%)`}}>
                    <AddOrganisation onAdd={handleAddOrganisation} id_sage_customer={props.sageOrder.CustomerID} sage_customer_account_number={props.sageOrder.CustomerAccountNumber} />
                </Paper>
            </Modal>

        </React.Fragment>
    )
}
export default withRouter(SageOrderImport);