import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import RepeatIcon from '@material-ui/icons/Repeat';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { 
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    InputLabel,
    Typography,
    CircularProgress,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Menu,
    IconButton
} from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import SubscriptionSocketSim from './subscription_socket_sim';
import SubscriptionSocketSimAutocomplete from './socket_subscription_sim_autocomplete';




const MatchErrorDialog = props => {

    return (
        <Dialog open={true} onClose={props.close} aria-labelledby="socket-mismatch-dialog-title" maxWidth={'xs'} fullWidth>
            <DialogTitle><div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}><WarningIcon />&nbsp;Live Unit Mismatch</div></DialogTitle>
            <DialogContent>
                <Typography variant="body1">The last know configuration for this unit doesn't match this configuration.</Typography>
                <Typography variant="body2" color="textSecondary">Mismatched configuration keys</Typography>
                <pre>{JSON.stringify(props.mismatch, undefined, 2)}</pre>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} color="default">Close</Button>
            </DialogActions>
        </Dialog>
    )

}




const SwitchOriginalId = props => {

    const context = useContext(GlobalContext);

    const [transferableIdentity, setTransferableIdentity] = useState(props.socket.transferable_identity);
    const [passcode, setPasscode] = useState(context.authCode());


    const handleSave = () => {
        if(typeof props.save === 'function')
            props.save(transferableIdentity, passcode);
    }

    return (
        <Dialog open={true} onClose={props.close} aria-labelledby="switch-original-dialog-title" maxWidth={'xs'} fullWidth>
            <DialogTitle><div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}><WarningIcon />&nbsp;Switch the Original ID</div></DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{marginBottom: 20}}>Be careful switching the original ID, this will have a live impact on the charge point server.</Typography>
                <TextField
                    name="transferable_identity"
                    value={transferableIdentity}
                    onChange={e => {setTransferableIdentity(e.target.value)}}
                    label="Original ID (e.g. ROL0123)"
                    type="text"
                    InputLabelProps={{shrink: true}}
                    fullWidth
                    style={{marginBottom: 20}}
                />
                <TextField
                    name="passcode"
                    value={passcode}
                    onChange={e => {setPasscode(e.target.value)}}
                    label="Authorisation code"
                    type="password"
                    InputLabelProps={{shrink: true}}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} color="default">Close</Button>
                <Button onClick={handleSave} color="secondary">Confirm Change</Button>
            </DialogActions>
        </Dialog>
    )

}




const UnlockDialog = props => {

    const context = useContext(GlobalContext);
    const [passcode, setPasscode] = useState(context.authCode());


    const handleSave = () => {
        if(typeof props.handleUnlock === 'function')
            props.handleUnlock(passcode);
    }

    useEffect(() => {
        if(passcode !== null && passcode !== '')
            handleSave();
    }, [])

    return (
        <Dialog open={true} onClose={props.close} aria-labelledby="switch-original-dialog-title" maxWidth={'xs'} fullWidth>
            <DialogTitle><div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}><WarningIcon />&nbsp;Unlock Configuration</div></DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{marginBottom: 20}}>Only unlock a charge point configuration when you are sure. This can have an impact on the live network.</Typography>
                <TextField
                    name="passcode"
                    value={passcode}
                    onChange={e => {setPasscode(e.target.value)}}
                    label="Authorisation code"
                    type="password"
                    InputLabelProps={{shrink: true}}
                    fullWidth
                />
                {props.error && <Typography variant="body2" color="error">{props.error}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} color="default">Close</Button>
                <Button onClick={handleSave} color="secondary">Unlock</Button>
            </DialogActions>
        </Dialog>
    )

}




const LastPasswordDialog = props => {

    return (
        <Dialog open={true} onClose={props.close} aria-labelledby="switch-original-dialog-title" maxWidth={'xs'} fullWidth>
            <DialogTitle><div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}><WarningIcon />&nbsp;Last Known Password</div></DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{marginBottom: 20}}>Only unlock a charge point configuration when you are sure. This can have an impact on the live network.</Typography>
                <Typography variant="h6">{props.lastKnownPassword}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} color="default">Close</Button>
            </DialogActions>
        </Dialog>
    )

}


const blank_validation = (name, required = true) => {
    return JSON.parse(JSON.stringify({
        name,
        required,
        valid: false,
        msg: '',
        dirty: false
    }))
}


const UpdateSubscriptionSocket = props => {

    const theme = useTheme();

    // Use the global context
    const context = useContext(GlobalContext);

    // Create a local state
    const [socket, setSocket] = React.useState(props.socket);
    const [ validation, setValidation ] = useState({
        socket_id: blank_validation('Unit ID', true),
        connectors: blank_validation('Sockets', true),
        id_controller_type: blank_validation('Controller', true),
        connectivity_type: blank_validation('Connectivity', true),
        rfid: blank_validation('RFID Reader', true),
        power: blank_validation('Socket Rating', true),
        kilowatts: blank_validation('kWh Power', true),
        phase: blank_validation('Phase', true),
        network: blank_validation('Network', false),
        sim_no: blank_validation('Sim Number', false),
        transferable_identity: blank_validation('Original ID', false),
        connector_type: blank_validation('Connector Type', true)
    });


    const [loading, setLoading] = React.useState(false);
    const [controllerTypes, setControllerTypes] = useState([]);
    const [gprsNetworks, setGprsNetworks] = useState([]);
    const [transferSetting, setTransferSetting] = useState(null);
    const [liveMatchLoading, setLiveMatchLoading] = useState(false);
    const [liveMatchError, setLiveMatchError] = useState(null);
    const [showSwitchOriginal, setShowSwitchOriginal] = useState(false);
    const [showUnlockDialog, setShowUnlockDialog] = useState(false);
    const [showLocalPasswordDialog, setShowLocalPasswordDialog] = useState(false);
    const [preset, setPreset] = useState(0);
    const [menuEl, setMenuEl] = useState(null);
    const [authCode, setAuthCode] = useState(null);
    const [unlocked, setUnlocked] = useState(false);
    const [unlockError, setUnlockError] = useState(null);
    const [lastKnownPassword, setLastKnownPassword] = useState(null);



    //
    //  On load grab tha controller types and check the config on the live server
    //
    useEffect(() => {

        context.apiRequest('controller_types').then(
            res => {
                setControllerTypes(res.result);
            },
            err => {
                context.showAlert('error', `Error loading controller types`);
            }
        )

        context.apiRequest('gprs_networks').then(
            res => {
                setGprsNetworks(res.result);
            },
            err => {
                context.showAlert('error', `Error loading gprs networks`);
            }
        )

        if(socket.has_booted!==null && socket.id_controller_type === 2){
            setLiveMatchLoading(true);
            context.apiRequest('subscription/socket/liveconfig/'+socket.socket_id).then(
                res => {
                    console.log(res);
                    setLiveMatchLoading(false);
                    setLiveMatchError(null);
                    if(typeof res.result.lastKnownPassword !== 'undefined')
                        setLastKnownPassword(res.result.lastKnownPassword)
                },
                err => {
                    
                    setLiveMatchLoading(false);

                    if(typeof err.result != 'undefined'){
                        if(typeof err.result.lastKnownPassword !== 'undefined')
                            setLastKnownPassword(err.result.lastKnownPassword)

                        setLiveMatchError(err.result.unmatched);
                    }
                    console.log(err);
                }
            )
        }

    }, [])




    //
    //  Validate the form
    //
    const validate = () => {
        let newValidation = {...validation};

        const keys = Object.keys(newValidation);
        keys.map(k => {
            newValidation[k].valid = true;
            newValidation[k].msg = '';
            if(typeof socket[k] != 'undefined' && newValidation[k].required === true && (socket[k] === null || socket[k].length === 0 || socket[k] === '')){
                newValidation[k].valid = false;
                newValidation[k].msg = `${newValidation[k].name} is required to update a charge point`;
            }
        })

        //
        //  Custom validations
        //
        // if(socket.id_controller_type === 2 && (socket.transferable_identity === null || socket.transferable_identity.length === 0)){
        //     newValidation.transferable_identity.dirty = true;
        //     newValidation.transferable_identity.valid = false;
        //     newValidation.transferable_identity.msg = `${newValidation.transferable_identity.name} is required to update a Greenflux charge point`;
        // }

        if(socket.id_controller_type === 2 && socket.transferable_identity !== null && !socket.transferable_identity.match(/^ROL\d{4}$/)){
            newValidation.transferable_identity.dirty = true;
            newValidation.transferable_identity.valid = false;
            newValidation.transferable_identity.msg = `${newValidation.transferable_identity.name} is required in a ROL**** format`;
        }

        if(socket.connectivity_type === 'gprs' && (socket.network === null || socket.network.length === 0)){
            newValidation.network.dirty = true;
            newValidation.network.valid = false;
            newValidation.network.msg = `${newValidation.network.name} is required for a GPRS charge point`;
        }

        if(socket.id_controller_type === 1 && parseInt(socket.connectors) > 1){
            newValidation.connectors.dirty = true;
            newValidation.connectors.valid = false;
            newValidation.connectors.msg = `${newValidation.connectors.name} can only be 1 for a Mode 3 charge point ID`;
        }

        setValidation(newValidation);
    }


    const isValid = () => {
        const keys = Object.keys(validation);
        let result = true;
        keys.map(k => {
            if(validation[k].valid === false)
            result = false;
        })
        return result;
    }






    /*
    *
    *   HANDLE CHANGES TO VALUES
    *
    */

    const setDirty = name => {
        let newValidation = {...validation};
        if(typeof newValidation[name] != 'undefined'){
            newValidation[name].dirty = true;
            setValidation(newValidation);
        }
    }

    const setDirtyAll = () => {
        let newValidation = {...validation};
        const keys = Object.keys(newValidation);
        keys.map(k => newValidation[k].dirty = true);
        setValidation(newValidation);
    }


    const handleChange = e => {
        setDirty(e.target.name);
        let newSocket = JSON.parse(JSON.stringify(socket));
        newSocket[e.target.name] = e.target.value;

        //
        // Naughty bit of sanitization here
        //
        if(newSocket.connectivity_type === 'ethernet')
            newSocket.network = '';

        setSocket(newSocket);
    }


    const handleSelectSIM = sim_no => {
        setDirty('sim_no');
        let newSocket = JSON.parse(JSON.stringify(socket));
        newSocket.sim_no = sim_no;
        setSocket(newSocket);
    }


    const handlePresetChange = e => {
        setDirtyAll();
        const val = parseInt(e.target.value);
        setPreset(e.target.value);

        if(val !== null){
            let newSocket = JSON.parse(JSON.stringify(socket));

            // 1 = GPRS - Twin Socket 7.2kWh
            // 2 = GPRS - Single Socket 7.2kWh
            // 3 = GPRS - Twin Socket 3.6kWh
            // 4 = GPRS - Single Socket 3.6kWh
            // 5 = GPRS - Twin Socket 22kWh
            // 6 = GPRS - Single Socket 22kWh
            // 7 = GPRS - Twin Socket 11kWh
            // 8 = GPRS - Single Socket 11kWh
            // 9 = Ethernet - Twin Socket 7.2kWh
            // 10 = Ethernet - Single Socket 7.2kWh
            // 11 = Ethernet - Twin Socket 3.6kWh
            // 12 = Ethernet - Single Socket 3.6kWh
            // 13 = Ethernet - Twin Socket 22kWh
            // 14 = Ethernet - Single Socket 22kWh
            // 15 = Ethernet - Twin Socket 11kWh
            // 16 = Ethernet - Single Socket 11kWh

            const gprs = [1,2,3,4,5,6,7,8,17];
            const ethernet = [9,10,11,12,13,14,15,16,18];
            const twin = [1,3,5,7,9,11,13,15];
            const single = [2,4,6,8,10,12,14,16,17,18];
            const singlePhase = [1,2,3,4,9,10,11,12];
            const threePhase = [5,6,7,8,13,14,15,16,17,18];
            const thirytwo = [1,2,5,6,9,10,13,14];
            const sixteen = [3,4,7,8,11,12,15,16];
            const dcwall = [17,18];

            if(gprs.indexOf(val) > -1){
                newSocket.connectivity_type = 'gprs';
                newSocket.network = 'emnify';
            }
            
            if(ethernet.indexOf(val) > -1){
                newSocket.connectivity_type = 'ethernet';
                newSocket.network = '';
            }
            
            if(twin.indexOf(val) > -1)
                newSocket.connectors = 2;

            if(single.indexOf(val) > -1)
                newSocket.connectors = 1;

            if(singlePhase.indexOf(val) > -1)
                newSocket.phase = 'single';

            if(threePhase.indexOf(val) > -1)
                newSocket.phase = 'three';

            if(thirytwo.indexOf(val) > -1){
                newSocket.power = 32;
                if(singlePhase.indexOf(val) > -1)
                    newSocket.kilowatts = 7.2;
                else
                    newSocket.kilowatts = 22;
            }

            if(sixteen.indexOf(val) > -1){
                newSocket.power = 16;
                if(singlePhase.indexOf(val) > -1)
                    newSocket.kilowatts = 3.6;
                else
                    newSocket.kilowatts = 11;
            }

            if(dcwall.indexOf(val) > -1){
                newSocket.power = 60;
                newSocket.kilowatts = 25;
            }

            if(newSocket.id_controller_type === 1)
                newSocket.rfid = 0;
            else
                newSocket.rfid = 1;

            setSocket(newSocket);
                
        }
    }

    const handleUpdateSocket = () => {
        setDirtyAll();
        if(isValid()){
            setLoading(true);

            let form = {...socket};
            if(authCode !== null)
                form.authCode = authCode;
                
            context.saveSubscriptionSocket(form).then(
                res => {
                    setLoading(false);
                    context.showAlert('success', 'Socket updated');
                    
                    //setTransferSetting(res.result);

                    if(typeof props.success == 'function')
                        props.success();


                    if(typeof props.close == 'function')
                        props.close();

                },
                err => {
                    setLoading(false);
                    context.showAlert('error', 'Error updating socket ' + JSON.stringify(err))
                }
            )
        }
    }


    const handleUpdateTransferableIdentity = (transferableIdentity, passcode) => {
        setShowSwitchOriginal(false);
        setLoading(true);

        const params = {
            ...socket,
            transferable_identity: transferableIdentity,
            passcode: passcode
        }
        
        context.switchSubscriptionSocketBoard(params).then(
            res => {
                setLoading(false);
                let newSocket = JSON.parse(JSON.stringify(socket));
                newSocket.transferable_identity = transferableIdentity;
                setSocket(newSocket);
                context.setAuthCode(passcode);
                context.showAlert('success', 'Original ID changed');

                if(typeof props.success == 'function')
                    props.success();
            },
            err => {
                setLoading(false);
                context.showAlert('error', 'Error updating Original ID ' + JSON.stringify(err))
            }
        )
    }

    const handleUnlockSocket = authCode => {

        setUnlockError(null);
        context.apiRequest('/subscription/socket/validateauthcode', 'POST', {authCode: authCode}).then(
            res => {

                setAuthCode(authCode);
                context.setAuthCode(authCode);
                setShowUnlockDialog(false);
                setUnlocked(true);

            },
            err => {

                setUnlockError('Incorrect authorisation code.');

            }
        )
    }



    const handleActionCancel = () => {
        if(typeof props.close == 'function')
            props.close()
    }

    const handleOpenMenu = (event) => {
        setMenuEl(event.currentTarget);
    };
    
    const handleCloseMenu = () => {
        setMenuEl(null);
    };

    const isError = (obj) => {
        return (obj.dirty && obj.valid === false);
    }

    useEffect(validate, [socket]);
    
    const readOnly = (socket.has_booted!==null && !unlocked);

        
    return (

        <Dialog open={props.show} onClose={props.close} aria-labelledby="update-socket-dialog-title" maxWidth={'sm'} fullWidth>
            <DialogTitle>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div>Configure unit{readOnly && <Typography variant="body2" color="error">Configuration locked</Typography>}</div>
                    {liveMatchLoading && <CircularProgress size={24} />}
                    {readOnly && 
                        <div>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleOpenMenu}
                                >
                            <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="more-menu"
                                anchorEl={menuEl}
                                keepMounted
                                open={Boolean(menuEl)}
                                onClose={handleCloseMenu}
                                >
                                <MenuItem onClick={() => {handleCloseMenu(); setShowSwitchOriginal(true)}}><RepeatIcon style={{marginRight: 10}} /> Change Original ID</MenuItem>
                                <MenuItem onClick={() => {handleCloseMenu(); setShowUnlockDialog(true)}}><LockOpenIcon style={{marginRight: 10}} /> Unlock configuration</MenuItem>
                                {socket.id_controller_type === 2 && <MenuItem onClick={() => {handleCloseMenu(); setShowLocalPasswordDialog(true)}}><VpnKeyIcon style={{marginRight: 10}} /> Local Management Password</MenuItem>}
                            </Menu>
                        </div>
                    }
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4}>

                    
                    
                    {!readOnly && 
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id='preset_label' shrink={true}>Preset</InputLabel>
                            <Select
                                name="preset"
                                onChange={handlePresetChange}
                                value={preset}
                                labelId='preset_label'
                                label="Preset"  
                                readOnly={readOnly}                     
                            >
                                <MenuItem value={0}>-- Select a preset --</MenuItem>
                                <MenuItem value={4}>GPRS - Single Socket 3.6kWh</MenuItem>
                                <MenuItem value={2}>GPRS - Single Socket 7.2kWh</MenuItem>
                                <MenuItem value={8}>GPRS - Single Socket 11kWh</MenuItem>
                                <MenuItem value={6}>GPRS - Single Socket 22kWh</MenuItem>
                                <MenuItem value={3}>GPRS - Twin Socket 3.6kWh</MenuItem>
                                <MenuItem value={1}>GPRS - Twin Socket 7.2kWh</MenuItem>
                                <MenuItem value={7}>GPRS - Twin Socket 11kWh</MenuItem>
                                <MenuItem value={5}>GPRS - Twin Socket 22kWh</MenuItem>
                                <MenuItem value={17}>GPRS - 25KW DC Wallbox</MenuItem>
                                <MenuItem value={12}>Ethernet - Single Socket 3.6kWh</MenuItem>
                                <MenuItem value={10}>Ethernet - Single Socket 7.2kWh</MenuItem>
                                <MenuItem value={16}>Ethernet - Single Socket 11kWh</MenuItem>
                                <MenuItem value={14}>Ethernet - Single Socket 22kWh</MenuItem>
                                <MenuItem value={11}>Ethernet - Twin Socket 3.6kWh</MenuItem>
                                <MenuItem value={9}>Ethernet - Twin Socket 7.2kWh</MenuItem>
                                <MenuItem value={15}>Ethernet - Twin Socket 11kWh</MenuItem>
                                <MenuItem value={13}>Ethernet - Twin Socket 22kWh</MenuItem>
                                <MenuItem value={18}>Ethernet - 25KW DC Wallbox</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    }
                    
                    <Grid item xs={4}>
                        <TextField
                            name="socket_id"
                            value={socket.socket_id}
                            onChange={handleChange}
                            label="Unit ID (UKEV*****)"
                            type="text"
                            InputLabelProps={{shrink: true}}
                            autoFocus
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            error={isError(validation.socket_id)}
                        />
                        {isError(validation.socket_id) && <Typography variant="caption" color="error">{validation.socket_id.msg}</Typography>}
                        {!isError(validation.socket_id) && <Typography variant="caption">*Required</Typography>}
                    </Grid>
                    
                    <Grid item xs={4}>
                        <FormControl fullWidth error={isError(validation.connector_type)}>
                            <InputLabel id='connector_type_label' shrink={true}>Connector Type</InputLabel>
                            <Select
                                name="connector_type"
                                value={socket.connector_type}
                                onChange={handleChange}
                                labelId='connector_type_label'
                                label="Connector Type"  
                                readOnly={readOnly}                      
                            >
                                <MenuItem value={'socket'}>Socket</MenuItem>
                                <MenuItem value={'tethered'}>Tethered</MenuItem>
                            </Select>
                            {isError(validation.connector_type) && <Typography variant="caption" color="error">{validation.connector_type.msg}</Typography>}
                            {!isError(validation.connector_type) && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={4}>
                        <FormControl fullWidth error={isError(validation.connectors)}>
                            <InputLabel id='connectors_label' shrink={true}>Sockets</InputLabel>
                            <Select
                                name="connectors"
                                value={socket.connectors}
                                onChange={handleChange}
                                labelId='connectors_label'
                                label="Connectors"  
                                readOnly={readOnly}                      
                            >
                            <MenuItem value={1}>One</MenuItem>
                            <MenuItem value={2}>Two</MenuItem>
                            <MenuItem value={3}>Three</MenuItem>
                            <MenuItem value={4}>Four</MenuItem>
                            <MenuItem value={5}>Five</MenuItem>
                            </Select>
                            {isError(validation.connectors) && <Typography variant="caption" color="error">{validation.connectors.msg}</Typography>}
                            {!isError(validation.connectors) && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={8}>
                        <FormControl fullWidth error={isError(validation.id_controller_type)}>
                            <InputLabel id='id_controller_type_label' shrink={true}>Controller</InputLabel>
                            <Select
                                name="id_controller_type"
                                value={socket.id_controller_type}
                                onChange={handleChange}
                                labelId='id_controller_type_label'
                                label="Controller"  
                                readOnly={readOnly}                       
                            >
                                <MenuItem value={null}>-- Select controller--</MenuItem>
                                {controllerTypes.map((item, idx) => (
                                    <MenuItem key={idx} value={item.id_controller_type}>{item.controller_type_name}</MenuItem>
                                ))}
                            </Select>
                            {isError(validation.id_controller_type) && <Typography variant="caption" color="error">{validation.id_controller_type.msg}</Typography>}
                            {!isError(validation.id_controller_type) && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={4}>
                        <FormControl fullWidth error={isError(validation.rfid)}>
                            <InputLabel id='rfid_label' shrink={true}>RFID Reader</InputLabel>
                            <Select
                                name="rfid"
                                value={socket.rfid}
                                onChange={handleChange}
                                labelId='rfid_label'
                                label="RFID"      
                                readOnly={readOnly}                  
                            >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                            </Select>
                            {isError(validation.rfid) && <Typography variant="caption" color="error">{validation.rfid.msg}</Typography>}
                            {!isError(validation.rfid) && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={4}>
                        <FormControl fullWidth error={isError(validation.phase)}>
                            <InputLabel id='phase_label' shrink={true}>Phase</InputLabel>
                            <Select
                                name="phase"
                                value={socket.phase}
                                onChange={handleChange}
                                labelId='phase_label'
                                label="Phase" 
                                readOnly={readOnly}                      
                            >
                            <MenuItem value={'single'}>Single Phase</MenuItem>
                            <MenuItem value={'three'}>Three Phase</MenuItem>
                            </Select>
                            {isError(validation.phase) && <Typography variant="caption" color="error">{validation.phase.msg}</Typography>}
                            {!isError(validation.phase) && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={4}>
                        <FormControl fullWidth error={isError(validation.power)}>
                            <InputLabel id='power_label' shrink={true}>Socket Rating</InputLabel>
                            <Select
                                name="power"
                                value={socket.power}
                                onChange={handleChange}
                                labelId='power_label'
                                label="Power"  
                                readOnly={readOnly}                      
                            >
                            <MenuItem value={16}>16 Amps</MenuItem>
                            <MenuItem value={32}>32 Amps</MenuItem>
                            <MenuItem value={60}>60 Amps</MenuItem>
                            <MenuItem value={63}>63 Amps</MenuItem>
                            <MenuItem value={65}>65 Amps</MenuItem>
                            <MenuItem value={125}>125 Amps</MenuItem>
                            <MenuItem value={200}>200 Amps</MenuItem>
                            <MenuItem value={250}>250 Amps</MenuItem>
                            <MenuItem value={300}>300 Amps</MenuItem>
                            <MenuItem value={400}>400 Amps</MenuItem>
                            <MenuItem value={410}>410 Amps</MenuItem>
                            <MenuItem value={500}>500 Amps</MenuItem>
                            </Select>
                            {isError(validation.power) && <Typography variant="caption" color="error">{validation.power.msg}</Typography>}
                            {!isError(validation.power) && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={4}>
                        <FormControl fullWidth error={isError(validation.kilowatts)}>
                            <InputLabel id='kilowatts_label' shrink={true}>kWh</InputLabel>
                            <Select
                                name="kilowatts"
                                value={socket.kilowatts}
                                onChange={handleChange}
                                labelId='kilowatts_label'
                                label="kWh"  
                                readOnly={readOnly}                      
                            >
                            <MenuItem value={3.6}>3.6 kWh</MenuItem>
                            <MenuItem value={7.2}>7.2 kWh</MenuItem>
                            <MenuItem value={11}>11 kWh</MenuItem>
                            <MenuItem value={22}>22 kWh</MenuItem>
                            <MenuItem value={25}>25 kWh</MenuItem>
                            <MenuItem value={43}>43 kWh</MenuItem>
                            <MenuItem value={50}>50 kWh</MenuItem>
                            <MenuItem value={100}>100 kWh</MenuItem>
                            <MenuItem value={120}>120 kWh</MenuItem>
                            <MenuItem value={150}>150 kWh</MenuItem>
                            <MenuItem value={250}>250 kWh</MenuItem>
                            <MenuItem value={350}>350 kWh</MenuItem>
                            </Select>
                            {isError(validation.kilowatts) && <Typography variant="caption" color="error">{validation.kilowatts.msg}</Typography>}
                            {!isError(validation.kilowatts) && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={6}>
                        <FormControl fullWidth error={isError(validation.connectivity_type)}>
                            <InputLabel id='connectivity_type_label' shrink={true}>Connectivity</InputLabel>
                            <Select
                                name="connectivity_type"
                                value={socket.connectivity_type}
                                onChange={handleChange}
                                labelId='connectivity_type_label'
                                label="Connectivity"   
                                readOnly={readOnly}                      
                            >
                            <MenuItem value={'gprs'}>GPRS</MenuItem>
                            <MenuItem value={'ethernet'}>Ethernet</MenuItem>
                            <MenuItem value={'wifi'}>WiFi</MenuItem>
                            </Select>
                            {isError(validation.connectivity_type) && <Typography variant="caption" color="error">{validation.connectivity_type.msg}</Typography>}
                            {!isError(validation.connectivity_type) && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    
                    {socket.connectivity_type === 'gprs' && 
                    <Grid item xs={6}>
                        <FormControl fullWidth error={isError(validation.network)}>
                            <InputLabel id='network_label' shrink={true}>Network</InputLabel>
                            <Select
                                name="network"
                                value={socket.network}
                                onChange={handleChange}
                                labelId='network_label'
                                label="Network"   
                                readOnly={readOnly}                      
                            >
                            <MenuItem value={''}>-- Select --</MenuItem>
                                {gprsNetworks.map((item, idx) => (
                                    <MenuItem key={idx} value={item.gprs_network} style={{textTransform: 'capitalize'}}>{item.gprs_network}</MenuItem>
                                ))}
                            </Select>
                            {isError(validation.network) && <Typography variant="caption" color="error">{validation.network.msg}</Typography>}
                            {!isError(validation.network) && <Typography variant="caption">*Required</Typography>}
                        </FormControl>
                    </Grid>
                    }
                    
                    <Grid item xs={12}>
                        <TextField
                            name="sim_no"
                            value={socket.sim_no}
                            onChange={handleChange}
                            label="SIM No"
                            type="text"
                            InputLabelProps={{shrink: true}}
                            fullWidth
                            error={isError(validation.sim_no)}
                            autoComplete="off"
                        />
                        {isError(validation.sim_no) && <Typography variant="caption" color="error">{validation.sim_no.msg}</Typography>}
                        {(socket.connectivity_type === 'gprs' && socket.network === 'emnify') && <SubscriptionSocketSimAutocomplete sim_no={socket.sim_no} onSelect={handleSelectSIM} />}
                        {(socket.connectivity_type === 'gprs' && socket.network === 'emnify') && <SubscriptionSocketSim endpoint={socket.socket_id} sim_no={socket.sim_no} socket={socket} />}
                    </Grid>
                    
                    <Grid item xs={8}>
                        <TextField
                            name="transferable_identity"
                            value={socket.transferable_identity}
                            onChange={handleChange}
                            label="Original ID (e.g. ROL0123)"
                            type="text"
                            InputLabelProps={{shrink: true}}
                            fullWidth
                            InputProps={{
                                //readOnly: socket.controller_settings_transferred !== null,
                                readOnly: readOnly
                            }}
                            error={isError(validation.transferable_identity)}
                        />
                        {isError(validation.transferable_identity) && <Typography variant="caption" color="error">{validation.transferable_identity.msg}</Typography>}
                        {(!isError(validation.transferable_identity) && socket.id_controller_type === 2) && <Typography variant="caption">*Required</Typography>}
                        {/* {(socket.controller_settings_transferred !== null && socket.id_controller_type === 2) && <Typography variant="caption" color="error"> - This field is locked</Typography>} */}
                        {(readOnly && socket.id_controller_type === 2) && <Typography variant="caption" color="error"> - This field is locked</Typography>}
                    </Grid>

                </Grid>

                {liveMatchError !== null && <MatchErrorDialog close={()=>setLiveMatchError(null)} mismatch={liveMatchError} />}
                {showSwitchOriginal && <SwitchOriginalId close={()=>setShowSwitchOriginal(false)} socket={socket} save={handleUpdateTransferableIdentity} />}
                {showUnlockDialog && <UnlockDialog close={()=>setShowUnlockDialog(false)} handleUnlock={handleUnlockSocket} error={unlockError} />}
                {showLocalPasswordDialog && <LastPasswordDialog close={()=>setShowLocalPasswordDialog(false)} lastKnownPassword={lastKnownPassword} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleActionCancel} color="default">
                    Cancel
                </Button>
                <Button onClick={handleUpdateSocket} color="secondary" disabled={loading}>
                    {!loading ? 'Update' : <CircularProgress color="inherit" size={16} />}
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default UpdateSubscriptionSocket