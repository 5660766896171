import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Paper, CircularProgress, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import globalContext from '../../context/global-context';
import Refresh from '../global/Refresh';
import Helpers from '../global/helpers';
import SageOrderSearch from './sage_order_search';
import SageOrderImport from './sage_order_import';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    noOrders: {
        padding : '15px 0'
    }
}));

const SageLatestOrders = props => {


    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const [importOrder, setImportOrder] = useState(null);

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleLoadOrders = async () => {
        setLoading(true);
        try {

            const res = await context.apiRequest(`/sage/latest_orders`, 'GET');
            
            if(res.success)
                setOrders(res.result);
            else 
                setOrders([]);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        handleLoadOrders();
    }, [])

    const handleSearchResults = (result) => {
        setOrders(result);
    }

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>

                    <div className={classes.taskbar}>

                        <Typography variant="h6">Latest Sage200 Orders</Typography>
                        <div style={{flex: 1}}></div>
                        <SageOrderSearch handleSearchResults={handleSearchResults} handleClearSearch={handleLoadOrders} />
                        <Refresh loading={loading} handleClick={handleLoadOrders} />

                    </div>

                    <Paper style={{padding: 30, display: 'flex', flexDirection: 'column'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sage Order Number</TableCell>
                                    <TableCell>Sage Account</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Created</TableCell>
                                    <TableCell>Order Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((o, idx) => (
                                    <TableRow onClick={() => setImportOrder(o)} key={idx}>
                                        <TableCell>{o.DocumentNo}</TableCell>
                                        <TableCell>{o.CustomerAccountName}</TableCell>
                                        <TableCell>{o.DocumentCreatedBy}</TableCell>
                                        <TableCell>{Helpers.SQLtoUTCDate(o.DocumentDate, false)}</TableCell>
                                        <TableCell>{Helpers.localeCurrency(o.TotalGrossValue, 'GBP')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {(!loading && orders.length === 0) && 
                            <div className={classes.noOrders}>
                                <Typography variant="body2" color="textSecondary">Unable to find any orders</Typography>
                            </div>
                        }

                        {loading && <CircularProgress color="inherit" size={24} />}
                    </Paper>

                </div>
            </div>

            {importOrder !== null && <SageOrderImport open={true} handleClose={() => setImportOrder(null)} sageOrder={importOrder}/>}
        </React.Fragment>
    )
}
export default SageLatestOrders;