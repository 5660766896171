import React, {useContext, useState, useEffect} from 'react';
import { useTheme, makeStyles, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider } from '@material-ui/core';
import Helpers from '../global/helpers';
import AppUserRecentSessions from './app_user/app_user_recent_sessions';
import AppUserRecentTransactions from './app_user/app_user_recent_transactions';
import AppUserRecentDevices from './app_user/app_user_recent_devices';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const AppUser = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const {user} = props;

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    return (
        <React.Fragment>
            <Dialog open={true} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>User</DialogTitle>
                <DialogContent>
                    <div className='flex-col gap-10'>
                        
                        <div className='flex-row align-center'>
                            <div className='flex-col gap-0'>
                                <Typography variant="h6">{user.first_name} {user.last_name}</Typography>
                                <Typography variant="h6">{user.email}</Typography>
                            </div>
                            <div className='flex'></div>
                            <div className='flex-col gap-10'>
                                <Typography variant="body1" align="right">Curency wallet balance: </Typography>
                                <Typography variant="h3" align="right">{user.wallet_balance !== null ? Helpers.localeCurrency(user.wallet_balance, user.currency) : parseFloat(0).toFixed(2)}</Typography>
                            </div>
                        </div>

                        <Divider style={{marginTop: 10, marginBottom: 10}} />

                        <div className='flex-row gap-10'>
                            <div className='flex'>
                                <div className='flex-col'>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">ID: </Typography>
                                        <Typography variant="subtitle1">{user.id_user}</Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Phone: </Typography>
                                        <Typography variant="subtitle1">{user.phone}</Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Address: </Typography>
                                        <Typography variant="subtitle1">{user.address_line_1} {user.postal_code}</Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Member since: </Typography>
                                        <Typography variant="subtitle1">{Helpers.toLocaleDateTime(user.registered_on)}</Typography>
                                    </div>

                                    <Divider style={{marginTop: 10, marginBottom: 10}} />

                                    <Typography variant="subtitle1" style={{fontWeight: 600}}>Recent Devices</Typography>
                                    <AppUserRecentDevices id_user={user.id_user} />
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='flex-col'>
                                    <Typography variant="subtitle1" style={{fontWeight: 600}}>Recent Wallet Transactions</Typography>
                                    <AppUserRecentTransactions id_user={user.id_user} />
                                </div>
                            </div>
                        </div>

                        <Divider style={{marginTop: 10, marginBottom: 10}} />

                        <div>
                            <AppUserRecentSessions id_user={user.id_user} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}
export default AppUser;