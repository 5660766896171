import React from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from './styles';
import { Grid, Typography, List, ListItem, Paper } from '@material-ui/core';


// ServiceUsers class
const ServiceUsers = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    const routeTo = route => {
        props.history.push(`/service_users/${route}`)
    }

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                

                <div className={styles.taskbar}>

                    <Typography variant="h4">Service User Dashboard</Typography>

                </div>
            
                <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={4} md={3}>
                        <Typography variant="h6">VendElectric</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('app_users')}>App Users</ListItem>
                                <ListItem button onClick={() => routeTo('wallets')}>Wallet Balances</ListItem>
                                <ListItem button onClick={() => routeTo('wallet/payments')}>Wallet Payments</ListItem>
                                <ListItem button onClick={() => routeTo('charge_sessions')}>Charge Sessions</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12} sm={4} md={3}>
                        <Typography variant="h6">EV Connect</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('ev_connect/app_users')}>App Users</ListItem>
                                <ListItem button onClick={() => routeTo('ev_connect/chargepoints')}>Chargepoints</ListItem>
                            </List>
                        </Paper>

                        <Typography variant="h6">Production</Typography>

                        <Paper className={styles.space}>
                            <List>
                                <ListItem button onClick={() => routeTo('production/chargepoints')}>Chargepoints</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    
                </Grid>
            </div>
        </div>
    )

}

export default ServiceUsers
