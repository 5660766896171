import React from 'react';
import GlobalContext from '../../context/global-context';
import Helpers from '../global/helpers';
import querystring from 'query-string';
import {
    Table, 
    TableBody, 
    TableCell, 
    TablePagination, 
    TableRow, 
    Typography, 
    Button, 
    Paper, 
    TableContainer, 
    TableHead, 
    TableSortLabel, 
    Tabs,
    Tab,
    Chip
} from '@material-ui/core/';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownIcon from '@material-ui/icons/ArrowDownward'
import CloseIcon from '@material-ui/icons/Close'
import Refresh from '../global/Refresh';
import IssuePaymentReminder from './issue_payment_reminder';

//
// The headers for the table with sorting
//

const headCells = [
    { id: 'id_order', alignRight: false, label: 'Order ID' },
    { id: 'order_name', alignRight: false, label: 'Order Name' },
    { id: 'organisation_name', alignRight: false, label: 'Organisation' },
    { id: 'invoice_no', alignRight: false, label: 'Invoice To' },
    { id: 'proforma_issued', alignRight: true, label: 'Proforma Issued' },
    { id: 'invoice_chased', alignRight: true, label: 'Reminder Issued' },
    { id: 'days_issued', alignRight: true, label: 'Days Issued' },
    { id: 'days_since_chase', alignRight: true, label: 'Days Chased' },
    { id: 'invoice_no', alignRight: true, label: 'Invoice No' },
    { id: 'total', alignRight: true, label: 'Amount' },
    { id: 'action', alignRight: true, label: 'Action' },
]


const EnhancedTableHead = props => {
    const { onSelectAllClick, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    const getOrderBy = property => {
        const idx = orderBy.findIndex(o => o.orderBy === property);
        if(idx > -1)
            return orderBy[idx].order;
        
        return false;
    }
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? 'right' : 'left'}
              sortDirection={getOrderBy(headCell.id)}
            >
                <TableSortLabel
                    active={getOrderBy(headCell.id) !== false}
                    direction={getOrderBy(headCell.id) || 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }




const OrderChips = props => {

    const { orderBy, handleDelete, handleDeleteAll } = props;

    const getHeadLabel = id => {
        const headCell = headCells.find(h => h.id == id);
        if(typeof headCell != 'undefined')
            return headCell.label;

        return id;
    }

    return (
      <div style={{paddingBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Typography variant="body2">Sort: </Typography>
        {orderBy.map(o => (
            <Chip
                key={o.orderBy}
                size="small"
                icon={o.order == 'desc' ? <ArrowDownIcon /> : <ArrowUpIcon />}
                label={getHeadLabel(o.orderBy)}
                style={{marginLeft: 5, marginRight: 5}}
                onDelete={() => handleDelete(o.orderBy)}
            />
        ))}
        {orderBy.length > 1 && <Button color="secondary" onClick={handleDeleteAll}>Clear</Button>}
      </div>
    );
}
  


// Tickets class


class Billing extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        const qs = querystring.parse(this.props.location.search);

        this.state = {
            proformas: [],
            loading: false,
            table: {
                page:  typeof qs.page != 'undefined'?qs.page:0,
                rowsPerPage: 25,
                orderBy: [
                    {
                        orderBy: 'days_issued',
                        order: 'desc'
                    },
                    {
                        orderBy: 'days_since_chase',
                        order: 'desc'
                    }
                ]
            },
            tab: 0,
            showPaymentReminder: false,
            workingProforma: null
        };
    }

    componentDidMount(){
        this.getOutstandingProformas();
    }

    getOutstandingProformas = () => {
        this.setState({
            loading: true,
            showPaymentReminder: false,
            workingProforma: null
        }, () => {
            this.context.fetchSubscriptionsPaymentOutstanding().then(
                res => {

                    let proformas = [];
                    res.result.map(p => {
                        p.days_issued = this.daysDue(p.proforma_issued);
                        p.days_since_chase = this.daysDue(p.invoice_chased != null? p.invoice_chased : null);
                        proformas.push(p);
                    })

                    this.setState({
                        proformas: proformas,
                        loading: false
                    })
                },
                err => {
                    this.setState({
                        loading: false
                    })
                    this.context.showAlert('error', 'Error loading outstanding proformas');
                }
            )
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            table: {
                ...this.state.table,
                page: newPage
            }
        }, this.handleQueryChange);
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            table: {
                ...this.state.table,
                page: 0,
                rowsPerPage: parseInt(event.target.value, 10)
            }
        }, this.handleQueryChange);
    };

    handleRequestSort = (event, property) => {

        var newOrder = JSON.parse(JSON.stringify(this.state.table.orderBy));

        const idx = newOrder.findIndex(o => o.orderBy === property);
        if(idx === -1){
            newOrder.push({
                orderBy: property,
                order: 'desc'
            })
        } else {
            const isAsc = newOrder[idx].order === 'asc';
            if(isAsc){
                // Remove from order
                //newOrder.splice(idx, 1);
                newOrder[idx].order = 'desc'
            } else {
                newOrder[idx].order = 'asc'
            }
        }
        
        this.setState({
            table: {
                ...this.state.table,
                orderBy: newOrder
            }
        });

    };

    handleDeleteSort = (property) => {

        var newOrder = JSON.parse(JSON.stringify(this.state.table.orderBy));

        const idx = newOrder.findIndex(o => o.orderBy === property);
        if(idx > -1){
            newOrder.splice(idx, 1);
        }
        
        this.setState({
            table: {
                ...this.state.table,
                orderBy: newOrder
            }
        });

    };

    

    handleDeleteAllSort = (property) => {

        this.setState({
            table: {
                ...this.state.table,
                orderBy: []
            }
        });

    };

    handleTabChange = (e, val) => {
    
        this.setState({
            tab: val
        })
    }

    handleQueryChange = () => {

        let query = {};
        if(this.state.search != '')
            query.search = this.state.search;
        
        if(this.state.status != '' && this.state.status != 0)
            query.status = this.state.status;
        
        if(this.state.table.page != 0)
            query.page = this.state.table.page;
        
        this.props.history.replace('?'+Helpers.makeQueryString(query));

    }

    handleSearchChange = e => {
        this.setState({
            search: e.target.value
        }, this.handleQueryChange)
    }

    handleClearSearch = () => {
        this.setState({
            search: ''
        }, this.handleQueryChange)
    }

    isNull = (str) => {
        if(typeof str == 'undefined' || str == null)
            return ''
        
        return str.toString();
    }

    daysDue = (d) => {
        if(d == null)
            return null;

        const days = (new Date().getTime() - Helpers.SQLtoUTCDateObj(d).getTime()) / 86400000;
        return Math.floor(days);
    }

    handleShowSendReminder = (proforma) => {
        console.log(proforma);
        this.setState({
            showPaymentReminder: true,
            workingProforma: proforma
        })
    } 

    handleCloseSendReminder = (proforma) => {
        this.setState({
            showPaymentReminder: false,
            workingProforma: null
        })
    } 
    

    render(){

        const filterdProformas = () => {
            let proformas = this.state.proformas;

            if(this.state.tab == 0)
                proformas = proformas.filter(p => (p.days_issued >= 14 && p.days_since_chase == null) || p.days_since_chase >= 14)

            return proformas;
        }

        const rows = filterdProformas();
        const page = this.state.table.page;
        const rowsPerPage = this.state.table.rowsPerPage;
        const orderBy = this.state.table.orderBy;


        const comparator = (a, b, orderBy, order = 'desc') => {
            var x, y;

            if(Array.isArray(orderBy)){

                orderBy.map(z => {
                    x = a[z]!=null?a[z]:'';
                    y = b[z]!=null?b[z]:'';

                    if(isNaN(x) === false)
                        x = Number(x);

                    if(isNaN(y) === false)
                        y = Number(x);

                    if(x !== y){
                        if (y < x) return order == 'desc' ? -1 : 1;
                        if (y > x) return order == 'desc' ? 1 : -1;
                    }
                })

            } else {

                x = a[orderBy]!=null?a[orderBy]:'';
                y = b[orderBy]!=null?b[orderBy]:'';
    
                if (y < x) return order == 'desc' ? -1 : 1;
                if (y > x) return order == 'desc' ? 1 : -1;

            }
            return 0;
        }

        const tableSort = array => {
            var revOrder = JSON.parse(JSON.stringify(orderBy)).reverse();
            var arr = [...array];
            revOrder.map(o => {
                arr = arr.sort((a, b) => {
                    return comparator(a, b, o.orderBy, o.order)
                })
            })
            return arr;
        }


        const shouldChase = (row) => {
            if((row.invoice_chased != null && (Helpers.SQLtoUTCDate(new Date().toISOString(), false) == Helpers.SQLtoUTCDate(row.invoice_chased, false))) || row.days_since < 14)
                return 'default';
            
            return 'secondary';
        }

        return(
            <div style={styles.container}>
                <div style={styles.inner}>

                    <div style={styles.taskbar}>

                        <Typography variant="h4">Billing</Typography>

                        <div style={{display:'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end'}}>

                            <Tabs
                                value={this.state.tab}
                                onChange={this.handleTabChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label="types"
                            >
                                <Tab value={0} label="Remind" />
                                <Tab value={1} label="All" />
                            </Tabs>

                            <Refresh handleClick={this.getOutstandingProformas} loading={this.state.loading} />
                        </div>
                    </div>

                    {orderBy.length > 0 && <OrderChips orderBy={orderBy} handleDelete={this.handleDeleteSort} handleDeleteAll={this.handleDeleteAllSort} />}
                    
                    {/* <div style={styles.taskbar}>
                        <div style={{display: 'flex', flex: 1}}>
                            <TextField 
                                name={'Search'} 
                                type="text" 
                                value={this.state.search} 
                                onChange={this.handleSearchChange} 
                                InputLabelProps={{shrink: true,}} 
                                label={`Search ${typeof status != 'undefined' ? status.ticket_status_name: this.state.status == 0 ? 'My' : ''} tickets`} 
                                variant="outlined" 
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {this.state.search.length != '' && <IconButton onClick={this.handleClearSearch}><CloseIcon color="secondary" /></IconButton>}
                                      </InputAdornment>
                                    ),
                                }} />
                        </div>
                    </div> */}

                    <Paper style={styles.root}>
                        <TableContainer>
                            <Table>
                                
                                <EnhancedTableHead
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                {tableSort(rows).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                                    <TableRow
                                    key={row.id_ticket}>
                                        <TableCell component="th" scope="row">VE{row.id_order}</TableCell>
                                        <TableCell>{row.order_name}</TableCell>
                                        <TableCell>{row.organisation_name}</TableCell>
                                        <TableCell>{row.invoice_to}</TableCell>
                                        <TableCell align="right">{Helpers.SQLtoUTCDate(row.proforma_issued, false)}</TableCell>
                                        <TableCell align="right">{Helpers.SQLtoUTCDate(row.invoice_chased, false)}</TableCell>
                                        <TableCell align="right">{row.days_issued}</TableCell>
                                        <TableCell align="right">{row.days_since_chase}</TableCell>
                                        <TableCell align="right">{row.invoice_no}</TableCell>
                                        <TableCell align="right">£{row.total.toFixed(2)}</TableCell>
                                        <TableCell align="right"><Button color={shouldChase(row)} variant="contained" onClick={() => this.handleShowSendReminder(row)}>Chase</Button></TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>

                </div>

                {this.state.workingProforma != null && <IssuePaymentReminder show={this.state.showPaymentReminder} success={this.getOutstandingProformas} close={this.handleCloseSendReminder} proforma={this.state.workingProforma} />}
            </div>
        );
    }

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default Billing