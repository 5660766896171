import React, {useContext, useState} from 'react';
import { useTheme, Typography, makeStyles, Tooltip, Popover, IconButton } from '@material-ui/core';
import GlobalContext from '../../context/global-context';

import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import EvStationIcon from '@material-ui/icons/EvStation';
import PopupAlert from '../../components/popupAlert';

import Helpers from '../global/helpers';

const useStyles = makeStyles(theme => ({
    warningIcon: {
        backgroundColor: amber[700]
    },
    suspendedIcon: {
        backgroundColor: red[900]
    },
    socket: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        padding: 6,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.action.hover}`,
        borderRadius: 4,
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            border: `1px solid ${theme.palette.action.active}`,
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(3),
    },
}));

const SubscriptionSocket = props => {

    const context = useContext(GlobalContext)
    const theme = useTheme();
    const classes = useStyles(theme);
    const {socket, isIncomplete, isSuspended, id_subscription} = props;


    const [anchorEl, setAnchorEl] = useState(null);
    const [noModify, setNoModify] = useState(false);

    const handlePopoverOpen = (event) => {

        if(socket.connectors !== null || socket.power !== null || socket.connectivity_type !== null)
            setAnchorEl(event.currentTarget);

    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    const getIcon = (socket_id) => {
        if(isSuspended(socket_id))
            return <Tooltip title="This charge point is suspended"><NotInterestedIcon style={{color: theme.palette.error.main}} /></Tooltip>

        if(isIncomplete(socket_id))
            return <Tooltip title="Missing configuration information, click to edit"><WarningIcon style={{color: theme.palette.warning.main}} /></Tooltip>

        return <EvStationIcon style={{color: theme.palette.primary.main}} />;
    }

    const handleClick = () => {
        if(typeof props.onClick === 'function')
            props.onClick();
    }

    const handleDelete = e => {
        e.preventDefault();
        e.stopPropagation();
        if(typeof props.handleDelete === 'function')
            props.handleDelete(socket.id_subscription_socket);
    }

    const getControllerType = id => {
        if(typeof context.background.meta.controller_types !== 'undefined'){
            const controller_type = context.background.meta.controller_types.find(ct => ct.id_controller_type === id);

            if(typeof controller_type !== 'undefined')
                return controller_type.controller_type_name
        }         
        return 'Unknown'
    }

    const canModify = (id_subscription === socket.id_subscription || id_subscription === socket.renewal_id_subscription);

    return (
        <React.Fragment>
            <div 
                className={classes.socket}
                onClick={canModify ? handleClick : () => setNoModify(true)}
                aria-owns={open ? `socket-popover=${socket.socket_id}` : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {getIcon(socket.socket_id)}
                <Typography variant="subtitle2">{socket.socket_id}</Typography>
                <Typography variant="body2">{socket.kilowatts && `${socket.kilowatts}kWh`}</Typography>
                <Typography variant="body2">{socket.connectors && `x${socket.connectors}`}</Typography>
                <Typography variant="body2">{(socket.rfid !== null && Boolean(socket.rfid)) && 'RFID'}</Typography>
                <Typography variant="body2">{Helpers.connectivityType(socket.connectivity_type)}</Typography>
                <div className="flex"></div>
                {canModify && <IconButton onClick={handleDelete} size="small"><CloseIcon style={{fontSize: 18}} /></IconButton>}
            </div>
            <Popover
                id={`socket-popover=${socket.socket_id}`}
                open={open}
                anchorEl={anchorEl}
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div className="flex-col gap-5">
                    <Typography variant="h6">{socket.socket_id}</Typography>
                    <Typography variant="body2">Controller: <b>{getControllerType(socket.id_controller_type)}</b></Typography>
                    <Typography variant="body2">Sockets: <b>{socket.connectors}</b></Typography>
                    <Typography variant="body2">Connector Type: <b>{socket.connector_type}</b></Typography>
                    <Typography variant="body2">RFID: <b>{socket.rfid !== null && (Boolean(socket.rfid) ? 'Yes' : 'No')}</b></Typography>
                    <Typography variant="body2">Phase: <b>{socket.phase}</b></Typography>
                    <Typography variant="body2">Socket Rating: <b>{socket.power && `${socket.power}Amps`}</b></Typography>
                    <Typography variant="body2">kWh: <b>{socket.kilowatts && `${socket.kilowatts}kWh`}</b></Typography>
                    <Typography variant="body2">Connectivity: <b>{Helpers.connectivityType(socket.connectivity_type)}</b></Typography>
                    <Typography variant="body2">Network: <b>{socket.network}</b></Typography>
                    <Typography variant="body2">SIM No: <b>{socket.sim_no}</b></Typography>
                    <Typography variant="body2">Original ID: <b>{socket.transferable_identity}</b></Typography>
                </div>
            </Popover>

            <PopupAlert show={noModify} title="Unable to modify" message="This unit cannot be modified on this order, find the active subscription and modify from here." confirmLabel="OK" handleConfirm={() => setNoModify(false)} handleCancel={() => setNoModify(false)} showCancel={false} />
        </React.Fragment>
    )
}
export default SubscriptionSocket;