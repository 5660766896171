import React, { useContext, useEffect, useState } from 'react';

import { useTheme } from '@material-ui/styles';
import globalContext from '../../../context/global-context';
import { Alert } from '@material-ui/lab';
import { CircularProgress, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { useHistory } from 'react-router-dom';
import Helpers from '../../global/helpers';

const OcpiUninvoicedBatches = props => {

  const theme = useTheme();
  const styles = useStyles(theme);
  const context = useContext(globalContext);
  const history = useHistory();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError ] = useState(null);

  useEffect(() => {

    context.apiRequest('/ocpi_statements/pending/summary').then(
        res => {
            setData(res.result);
            setLoading(false);
        },
        err => {
            setLoading(false);
            context.showAlert('error', typeof err.msg === 'string' ? err.msg : JSON.stringify(err.msg));
            setError('Error loading statment data');
        }
    )

  }, [])

  const routeTo = route => {
    history.push(`/statements/${route}`)
  }

  return (
    <div>
       {error && <Alert severity='error'>{error}</Alert>}
       {loading && 
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress size={24} />
        </div>
        }
        {(!loading && data !== null) && 
          <React.Fragment>

            <List>
              <ListItem className={styles.flexSpaced}>
                  <Typography variant="subtitle2" color="textSecondary">Name</Typography>
                  <Typography variant="subtitle2" color="textSecondary">Total</Typography>
              </ListItem>
              {data.map((d, idx) => (
                <ListItem key={idx} className={styles.flexSpaced} button onClick={() => routeTo(`ve/ocpi/batch/${d.id_statement}`)}>
                <Typography>{d.identity} ({d.no})</Typography>
                <Typography>({Helpers.localeCurrency(d.total, d.currency)})</Typography>
                </ListItem>
              ))}
              {(!loading && data.length === 0) && <ListItem className={styles.flexSpaced}><Typography variant="subtitle2" color="textSecondary">No batches awaiting invoice</Typography></ListItem>} 
            </List>
            
          </React.Fragment>
        }

    </div>
  )

}

export default OcpiUninvoicedBatches