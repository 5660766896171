import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Button, Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const FirmwareUpdates = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Firmware Updates',
                            route: '/settings/firmware'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">OCPP Firmware Updates</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>Firmware updates added here are delivered by the VendElectric OCPP central system to charge points connected only to the VendElectric network. <br />Removing them does not remove the firmware from any charge point but stops it being delivered to any others.</Typography>

                <Crud
                    uri='firmware'
                    idCol='id_firmware'
                    readOnly={true}
                    clickRow={`/settings/firmware/!id_firmware`}
                    displayColumns={[
                        { 
                            name: 'vendor', 
                            display_name: 'Vendor',
                            type: 'string',
                            editable: false,
                            required: true
                        },
                        { 
                            name: 'model', 
                            display_name: 'Model',
                            type: 'string',
                            editable: false,
                            required: true
                        },
                        { 
                            name: 'version', 
                            display_name: 'Version',
                            type: 'string',
                            editable: false,
                            required: true
                        },
                        { 
                            name: 'download_url', 
                            display_name: 'URL',
                            type: 'string',
                            editable: false,
                            required: true
                        },
                        { 
                            name: 'delivery_by', 
                            display_name: 'Delivery Method',
                            type: 'string',
                            editable: false,
                            required: true
                        }
                    ]}
                />

                <div className="pt">
                    <Button variant="contained" color="primary" onClick={() => props.history.push('/settings/firmware/add')}>Add new</Button>
                </div>

            </div>

        </div>
    )

}

export default FirmwareUpdates
