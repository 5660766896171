import { Button, CircularProgress, Typography, ButtonGroup, makeStyles, useTheme } from '@material-ui/core';
import React, { useState, useRef, useEffect, useContext } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import globalContext from '../context/global-context';


const useStyles = makeStyles(theme => ({
  row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
  },
  dropArea: {
      padding: 10, 
      backgroundColor: theme.palette.background.default, 
      border: `2px solid ${theme.palette.action.active}`,
      borderRadius: 6,
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center'
  },
  dropAreaInvalid: {
      padding: 10, 
      backgroundColor: theme.palette.background.default, 
      border: `2px solid ${theme.palette.error.main}`,
      borderRadius: 6,
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center'
  },
  dropAreaHighlight: {
      padding: 4, 
      backgroundColor: theme.palette.action.hover, 
      border: `3px solid ${theme.palette.background.default}`,
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center'
  }
}));


const FileDragDrop = props => {

  const theme = useTheme();
  const styles = useStyles(theme);

  const context = useContext(globalContext);

  const [ dropHighlight, setDropHighlight ] = useState(false)
  const [ fileUpload, setFileUpload ] = useState(null);
  const [ uploading, setUploading ] = useState(false);
  const [ invalid, setInvalid ] = useState(false);

  const shouldConfirm = typeof props.shouldConfirm !== 'undefined' ? props.shouldConfirm : true;

  const dropArea = useRef(null);

  useEffect(() => {

      const preventDefaults = (e) => {
          e.preventDefault()
          e.stopPropagation()
      }

      dropArea.current.addEventListener('dragenter', e => {
          preventDefaults(e);
          setDropHighlight(true);
      }, false)

      dropArea.current.addEventListener('dragover', e => {
          preventDefaults(e);
          setDropHighlight(true);
      }, false)

      dropArea.current.addEventListener('dragleave', e => {
          preventDefaults(e);
          setDropHighlight(false);
      }, false)

      dropArea.current.addEventListener('drop', e => {
          preventDefaults(e);
          setDropHighlight(false);
          setFileUpload(e.dataTransfer.files[0]);
      }, false)

  }, [])


  const handleFileChange = e => {
    setFileUpload(e.target.files[0]);
  }

  const handleUpload = () => {

    if(fileUpload !== null){

        setInvalid(false);
        setUploading(true);

        context.apiFileUpload(props.uploadUrl, {attachment: fileUpload}).then(
            res => {
                setUploading(false);
                setFileUpload(null);

                if(typeof props.onSuccess !== 'undefined')
                    props.onSuccess(res);

            },
            err => {
                setUploading(false);
                context.showAlert('error', `Error uploading attachment: ${err.msg}`)
                console.log(err);
                if(typeof props.onError !== 'undefined')
                    props.onError();
            }
        )

    } else {
        setInvalid(true);

        if(typeof props.onError !== 'undefined')
            props.onError();
    }

  }

  const harmfulUpload = () => {
      if(fileUpload !== null && fileUpload.name.substring(fileUpload.name.indexOf('.'), fileUpload.name.length) === '.msg')
          return true;
      
      return false;
  }

  useEffect(() => {

    if(props.upload === true && uploading === false)
      handleUpload();

  }, [props.upload])


  return (
    <div ref={dropArea} className={dropHighlight ? styles.dropAreaHighlight : invalid ? styles.dropAreaInvalid : styles.dropArea}>
      {fileUpload === null && 
          <React.Fragment>
              {!dropHighlight && <Button
                  component="label"
                  disabled={uploading}
                  >
                  <VerticalAlignBottomIcon size="small" /> Choose a file or drag it here
                  <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                  />
                  {uploading && <CircularProgress size={24} />}
              </Button>}
              {dropHighlight && 
                  <div style={{paddingTop: 6, paddingBottom: 6, paddingLeft: 8, paddingRight: 8}}>
                      <Typography variant="button" className={styles.row}><VerticalAlignBottomIcon size="small" /> DROP</Typography>
                  </div>
              }
          </React.Fragment>
      }
  
      {fileUpload !== null && 
        <div style={{width: '100%'}}>
            <Typography variant="subtitle2">Confirm Upload</Typography>
            <div className={styles.row} style={{width: '100%'}}>
                <Typography variant="caption" style={{flex: 1}}>{fileUpload.name}</Typography>
                {!uploading && 
                <ButtonGroup disableElevation size="small" style={{marginLeft: 10}}>
                    <Button onClick={() => setFileUpload(null)}><CloseIcon fontSize="small" /></Button>
                    {shouldConfirm && <Button color="secondary" variant="contained" onClick={handleUpload}><CheckIcon fontSize="small" /></Button>}
                </ButtonGroup>}
                {uploading && <CircularProgress size={24} />}
            </div>
            {harmfulUpload() && <Typography color="error" variant="caption">Be careful, emails can contain malicious attachments or links.</Typography>}
        </div>}
    </div>
  )
}

export default FileDragDrop;