import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import React, {useContext, useState} from "react"
import Alert from '@material-ui/lab/Alert'
import globalContext from "../../../context/global-context";

const BatchInvoice = props => {

  const {id_statement} = props;
  const context = useContext(globalContext);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);


  const handleChangeInvoiceNumber = e => {
    setInvoiceNo(e.target.value);
  }


  const handleSubmit = async () => {

    setLoading(true);
    setError(null);
    try {

      if(invoiceNo === '')
        throw 'Please enter an invoice number';
      
      context.apiRequest(`/ocpi_statements/invoiced`, 'PUT', {
        id_statement: id_statement,
        invoice_no: invoiceNo
      }).then(
        res => {
          setLoading(false);
          setShow(false);

          if(typeof props.onSuccess === 'function')
            props.onSuccess();
        },
        err => {
          setLoading(false);
          setError(typeof err.msg !== 'undefined' ? err.msg : typeof err === 'string' ? err : JSON.stringify(err))
        }
      );
      
    } catch (error) {
      setLoading(false);
      setError(typeof error === 'string' ? error : JSON.stringify(error))
    }

  }


  return (
    <React.Fragment>
      <Button variant="contained" color="secondary" onClick={() => setShow(true)}>Mark Invoiced</Button>

      <Dialog open={show} fullWidth maxWidth="xs" onClose={() => setShow(false)}>
        <DialogTitle>Mark Statement as Invoiced</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error" style={{marginBottom: 10}}>{error}</Alert>}
          <TextField name="invoice_no" placeholder="Invoice number" label="Invoice No" fullWidth value={invoiceNo} onChange={handleChangeInvoiceNumber} />

          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: '10px 0', alignItems: 'center'}}>
            {loading && <CircularProgress size={32} color="secondary" />}
            {!loading && <Button variant="contained" color="secondary" onClick={handleSubmit}>Submit</Button>}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )

}

export default BatchInvoice