const ticketsReducer = (state = {
    tickets: [],
    openTicket: null,
    dirtyTicket: false,
    meta: {
        types: [],
        statuses: [],
        priorities: [],
        networks: [],
        effects: [],
        contact_types: [],
        units: [],
        group_schemes: [],
        sites: [],
        charge_points: []
    },
    status: {
        tickets: null,
        meta: null
    }
}, action) => {

    
    let tickets = JSON.parse(JSON.stringify(state.tickets));
    let openTicket = JSON.parse(JSON.stringify(state.openTicket));
    let status = JSON.parse(JSON.stringify(state.status));
    let meta = JSON.parse(JSON.stringify(state.meta));

    switch(action.type){
        case "TICKETS_GET":
            state = {
                ...state,
                tickets: action.payload || tickets,
                status: {
                    ...status,
                    tickets: status.tickets != 'ok' ? action.status : status.tickets
                }
            };
            break;
        case "TICKET_META":
            state = {
                ...state,
                meta: action.payload || meta,
                status: {
                    ...status,
                    meta: status.meta != 'ok' ? action.status : status.meta
                }
            };
            break;
        case "TICKET_OPEN":
            state = {
                ...state,
                openTicket: JSON.parse(JSON.stringify(action.payload)),
                dirtyTicket: false
            };
            break;
        case "TICKET_ADD":
            state = {
                ...state,
                tickets: [...state.tickets, action.payload]
            };
            break;
        case "TICKET_UPDATE":
            let idx = tickets.findIndex(t => t.id_ticket === action.payload.id_ticket);
            
            if(idx > -1)
                tickets[idx] = action.payload;
            else
                tickets.push(action.payload);

            // Is the open ticket the same as the ticket
            if(openTicket != null && openTicket.id_ticket === action.payload.id_ticket)
                openTicket = action.payload;  

            state = {
                ...state,
                tickets,
                openTicket,
                dirtyTicket: false
            };
            break;
        case "TICKET_DISCARD":
            let disIdx = tickets.findIndex(t => t.id_ticket === action.payload.id_ticket);
            state = {
                ...state,
                dirtyTicket: false,
                openTicket: tickets[disIdx]
            };;
            break;
        case "TICKET_ADDCOMMENT":
            let comment_idx = tickets.findIndex(t => t.id_ticket === action.payload.id_ticket);
    
            if(comment_idx > -1){
                tickets[comment_idx].logs.unshift(action.payload);
                tickets[comment_idx].last_updated = action.payload.created;
            }

            // Is the open ticket the same as the ticket comment
            if(openTicket != null && openTicket.id_ticket === action.payload.id_ticket)
                 openTicket.logs.unshift(action.payload);                

            state = {
                ...state,
                tickets,
                openTicket
            };
            break;
        case "TICKET_ADDCONTACT":
            let openTicketAddContact = {...state.openTicket};
            openTicketAddContact.contacts.push(action.payload);
            state = {
                ...state,
                openTicket: openTicketAddContact
            }
            break;
        case "TICKET_DELETECONTACT":
            let openTicketDelContact = {...state.openTicket};
            let delTicConIdx = openTicketDelContact.contacts.findIndex(c => JSON.stringify(c) === JSON.stringify(action.payload));
            openTicketDelContact.contacts.splice(delTicConIdx, 1);
            state = {
                ...state,
                openTicket: openTicketDelContact
            }
            break;
        case "TICKET_DIRTY":
            state = {
                ...state,
                dirtyTicket: action.payload
            };
            break;
        default:
    }
    return state;
}

export default ticketsReducer;