import React, {useContext, useState, useEffect} from 'react';
import { useTheme, makeStyles, Dialog, DialogContent, DialogTitle, Grid, Button, DialogActions, Typography, Paper, Divider, Collapse, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeValidation, isValidForm} from'../global/formHelper';
import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';
import globalContext from '../../context/global-context';
import DescriptionIcon from '@material-ui/icons/Description';
import OrderControlDocumentUnits from './order_control_document_units';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const OrderControlDocument = props => {
    const context = useContext(globalContext);
    const theme = useTheme();
    const classes = useStyles(theme);

    const [existingDocs, setExistingDocs] = useState([]);
    const [existingDocsList, setExistingDocsList] = useState([]);
    const [loadingExisting, setLoadingExisting] = useState(true);
    const [existing, setExisting] = useState(null);
    const [existingValidation, setExistingValidation] = useState(makeValidation([
        {
            name: "existing_id",
            display_name: "Select Existing Doc",
            required: false
        }
    ]));

    useEffect(() => {

        const doc = existingDocs.find(d => d.document_reference === existing);

        if(typeof doc !== 'undefined'){

            setForm(JSON.parse(doc.document_snapshot));
            let units = JSON.parse(doc.document_snapshot).units;
            units.forEach(u => u.existing_serial = true);
            setUnits(units);

        }

    }, [existing])


    const [form, setForm] = useState({
        type: "",
        scheme: "veos",
        project_ref: "",
        customer_ref: "",
        mi_no: "",
        maintenance_contract: false,
        maintenance_contract_other: "",
        management_fees_paid: false,
        customer_address: "",
        installation_address: "",
        installer_name: "",
        maintenance_contact: "",
        host_contact: "",
        notes: ""
    });

    const [units, setUnits] = useState([]);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [validation, setValidation] = useState(makeValidation([
        {
            name: "type",
            display_name: "Unit Types",
            required: true
        },
        {
            name: "scheme",
            display_name: "Scheme",
            required: true
        },
        {
            name: "project_ref",
            display_name: "Project Reference",
            required: false
        },
        {
            name: "customer_ref",
            display_name: "Customer PO Reference",
            required: false
        },
        {
            name: "mi_no",
            display_name: "Order MI Number",
            required: true
        },
        {
            name: "maintenance_contract",
            display_name: "Maintenance Contract",
            required: true
        },
        {
            name: "maintenance_contract_other",
            display_name: "Maintenance Contract Other",
            required: false
        },

        {
            name: "management_fees_paid",
            display_name: "Management Fees Paid",
            required: true
        },
        {
            name: "customer_address",
            display_name: "Customer Address",
            required: false
        },
        {
            name: "installation_address",
            display_name: "Installation Address",
            required: false
        },
        {
            name: "installer_name",
            display_name: "Installer",
            required: false
        },
        {
            name: "maintenance_contact",
            display_name: "Maintenance Contact",
            required: false
        },
        {
            name: "host_contact",
            display_name: "Host Contact",
            required: false
        },
        {
            name: "notes",
            display_name: "Notes",
            required: false
        }
    ]));

    
    useEffect(() => {
        const valid = isValidForm(form, validation, false);
        setValidation(valid.validation);
        setIsValid(valid.isValid);
    }, [form])
    
    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }
    
    const handleSubmit =  async () => {
        const valid = isValidForm(form, validation);
        setValidation(valid.validation);
        if(valid.isValid){
            setError(null);
            setWaiting(true);
            setSuccess(false);
            try {
                const body = {
                    id_order: props.order.id_order,
                    data: {
                        ...form,
                        units: units
                    }
                }
                const filename = `${body.data.type.toUpperCase()} ${body.data.scheme.toUpperCase()} – Project Management Control Document – ${body.data.mi_no} – 1 – ${body.data.units.length}.pdf`
                await context.apiFileGenerate(`/order/control_doc/${form.type}`, filename, 'POST', body).then(
                    res => {
                        setSuccess(true);
                        if(typeof props.onClose === 'function')
                            props.onClose();
                    },
                    err => {
                        setSuccess(false);
                        console.log(err);
                        throw err;
                    }
                )
    
            } catch (error) {
                console.log('error');
                setError(typeof error === 'string' ? error : 'ERR');
            } finally { 
                setWaiting(false);
            }
        }
    }

    useEffect(() => {

        if(typeof props.order !== 'undefined'){

            setLoadingExisting(true);
            context.apiRequest(`/order/control_docs/${props.order.id_order}`, 'GET').then(
                res => {
                    setLoadingExisting(false);
                    setExistingDocs(res.result);
                    let list = [];
                    res.result.map(d => {
                        list.push({
                            key: d.document_reference,
                            value: d.document_reference
                        })
                    })
                    setExistingDocsList(list);
                    if(list.length === 0)
                        setExisting(false);
                },
                err => {
                    setLoadingExisting(false);
                    setError(err.msg);
                }
            )

            let newForm = {...form};
            newForm.project_ref = props.order.order_name !== null ? props.order.order_name : '';
            newForm.mi_no = props.order.manufacturer_ref !== null ? props.order.manufacturer_ref : '';
            newForm.host_contact = props.order.main_contact_name !== null ? props.order.main_contact_name : ''
            setForm(newForm);

        }

    }, [props.order])

    const types = [
        {
            key: 'greenflux',
            value: 'Greenflux'
        },
        {
            key: 'mode3',
            value: 'Mode 3'
        },
        {
            key: 'delta',
            value: 'Delta'
        }
    ]

    const schemes = [
        {
            key: 'veos',
            value: 'VendElectric'
        },
        {
            key: 'evco',
            value: 'EVChargeOnline'
        },
        {
            key: 'gm',
            value: 'GM'
        },
        {
            key: 'other',
            value: 'Other'
        }
    ]

    const booleans = [
        {
            key: true,
            value: 'Yes'
        },
        {
            key: false,
            value: 'No'
        }
    ]
    

    return (
        <React.Fragment>
            <Dialog open={props.show} onClose={props.onClose} fullWidth={true} maxWidth="md">
                <DialogTitle><div className="flex-row align-center gap-5 mb"><DescriptionIcon /><Typography variant="h6">Generate Control Document</Typography></div></DialogTitle>
                <DialogContent>
               
                    {error && <Alert severity='error'>{error}</Alert>}

                    <Grid container spacing={4}>

                        {existing !== null &&
                            <Grid container item md={6} spacing={2}>

                                <Grid item xs={6}>
                                    <InputField name="type" variant="standard" validation={validation.type} value={form.type} handleChange={handleInputChange} hoverLabel={true} type="select" items={types} readOnly={existing !== false}/>
                                </Grid>

                                <Grid item xs={6}>
                                    <InputField name="scheme" variant="standard" validation={validation.scheme} value={form.scheme} handleChange={handleInputChange} hoverLabel={true} type="select" items={schemes} />
                                </Grid>

                            </Grid>
                        }

                        <Grid container item md={6} spacing={2}>

                            {existing === null &&
                                <Grid item xs={6}>
                                    {loadingExisting && <CircularProgress size={28} />}
                                    {!loadingExisting && <InputField name="existing_id" variant="standard" validation={existingValidation.existing_id} value={existing} handleChange={(e) => setExisting(e.target.value)} hoverLabel={true} type="select" items={existingDocsList} />}
                                </Grid>
                            }

                            
                            <Grid item xs={6}>
                                {existing === null && <Button onClick={() => setExisting(false)} variant="contained" color="secondary">New</Button>}
                                {existing !== null && <InputField name="mi_no" variant="standard" validation={validation.mi_no} value={form.mi_no} handleChange={handleInputChange} hoverLabel={true} readOnly={existing !== false} />}
                            </Grid>

                        </Grid>

                    </Grid>

                    <Collapse in={existing !== null}>

                        <div style={{paddingTop: 50}}>

                            <Grid container spacing={4}>

                                <Grid container item md={6} spacing={2}>

                                    <Grid item xs={12}>
                                        <InputField name="project_ref" variant="standard" validation={validation.project_ref} value={form.project_ref} handleChange={handleInputChange} hoverLabel={true} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputField name="customer_ref" variant="standard" validation={validation.customer_ref} value={form.customer_ref} handleChange={handleInputChange} hoverLabel={true} />
                                    </Grid>

                                </Grid>

                                <Grid container item md={6} spacing={2}>

                                    <Grid item xs={5}>
                                        <InputField name="maintenance_contract" variant="standard" validation={validation.maintenance_contract} value={form.maintenance_contract} handleChange={handleInputChange} hoverLabel={true} type="select" items={booleans} />
                                    </Grid>

                                    <Grid item xs={5}>
                                        <InputField name="management_fees_paid" variant="standard" validation={validation.management_fees_paid} value={form.management_fees_paid} handleChange={handleInputChange} hoverLabel={true} type="select" items={booleans} />
                                    </Grid>

                                    {form.maintenance_contract && 
                                        <Grid item xs={12}>
                                            <InputField name="maintenance_contract_other" variant="standard" validation={validation.maintenance_contract_other} value={form.maintenance_contract_other} handleChange={handleInputChange} hoverLabel={true} />
                                        </Grid>
                                    }

                                </Grid>

                                
                                <Grid container item md={6} spacing={2}>

                                    <Grid item xs={12}>
                                        <InputField name="customer_address" variant="standard" validation={validation.customer_address} value={form.customer_address} handleChange={handleInputChange} hoverLabel={true} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputField name="installation_address" variant="standard" validation={validation.installation_address} value={form.installation_address} handleChange={handleInputChange} hoverLabel={true} />
                                    </Grid>

                                    <Grid item xs={8}>
                                        <InputField name="installer_name" variant="standard" validation={validation.installer_name} value={form.installer_name} handleChange={handleInputChange} hoverLabel={true} />
                                    </Grid>

                                </Grid>

                                
                                <Grid container item md={6} spacing={2}>

                                    <Grid item xs={12}>
                                        <InputField name="maintenance_contact" variant="standard" validation={validation.maintenance_contact} value={form.maintenance_contact} handleChange={handleInputChange} hoverLabel={true} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputField name="host_contact" variant="standard" validation={validation.host_contact} value={form.host_contact} handleChange={handleInputChange} hoverLabel={true} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputField name="notes" variant="standard" validation={validation.notes} value={form.notes} handleChange={handleInputChange} hoverLabel={true} />
                                    </Grid>  

                                </Grid>                      

                            </Grid>

                            <OrderControlDocumentUnits id_order={props.order.id_order} mi_no={form.mi_no} type={form.type} setDocUnits={setUnits} units={units} existing={existing} />

                        </div>

                    </Collapse>

                </DialogContent>

                <DialogActions>

                    <Button onClick={props.onClose}>Cancel</Button>
                    
                    <ProgressButton waiting={waiting} onClick={handleSubmit} variant='contained' disabled={!isValid} color="secondary">Create</ProgressButton>

                </DialogActions>

            </Dialog>
        </React.Fragment>
    )
}
export default OrderControlDocument;