import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import TicketChargeChip from './ticket_charge_point_chip';
import TicketChargePointDialog from './ticket_charge_point_dialog';
import GlobalContext from '../../../context/global-context';
import { useStyles } from '../styles';


//  Used to capture and build up the socket data
//  outside the state and be watched and emitted 
//  inside the state. Why?? because when socket io
//  receives a message, retrieving an exisiting state
//  object will give you back the origional state
//  object regardless of it being changed.
var nonStateSocketData = [];

//
//  API requests could be running on unmount
//  These will need aborting
//
var abortCtls = [];

//
//  The socket
//
var socket;
var socket_connecting = false; 

const TicketChargePointSubscription = props => {
    const context = useContext(GlobalContext);

    // List of charge point IDs we need to subscribe to
    const { cpids } = props;
    const [ cpidSubscribed, setCpidsSubscribed ] = useState([]);

    // The real time relay messages
    const [ realTimeRelay, setRealTimeRelay ] = useState([]);
    
    // Show a single socket's data in a dialoge window
    const [ showSocket, setShowSocket ] = useState(null);


    // Output that we have mounted
    useEffect(() => {

        console.log('event listeners');

        
        window.addEventListener('CPID', e => {
            setRealTimeRelay(nonStateSocketData);
        }, false)

        // On un-mount we disconnect the socket
        return () => {
            // console.log('clean up')

            // clean up
            if(socket){
                socket.close();
                socket = null;
            }

            window.removeEventListener('CPID', e => {
                setRealTimeRelay(nonStateSocketData);
            }, true)

            //
            // Abort any api requests still going
            //
            abortCtls.map(ctl => ctl.abort());

            nonStateSocketData = [];

            //
            //  If the socket is connecting set this to false;
            //
            socket_connecting = false;
        }

    }, [])


    //
    //  When the list of charge points change re-subscribe
    //  This also gets called on mount
    //
    useEffect(() => {

        console.log(cpids);
        if(typeof props.user.token != 'undefined' && typeof cpids != 'undefined' && cpids.length > 0){

            console.log('Subscribe');
            subscribeToRealtimeRelay(cpids);
        }

    }, [cpids])



    //
    // get a sanitized list of the CPIDs we need to subscrib to
    //
    const getCPIDs = () => {
        console.log(cpids);
        let cpids_santitized = [];
        cpids.map(cpid => {
            if(cpid.length > 9){
                if(cpids_santitized.indexOf(cpid.slice(cpid, -1)) === -1)
                    cpids_santitized.push(cpid.slice(cpid, -1));
            } else 
                cpids_santitized.push(cpid);

        })
        console.log(cpids_santitized);
        setCpidsSubscribed(cpids_santitized);
        return cpids_santitized;
    }


    
    //
    //  Subscribe to the socket feed.
    //
    const subscribeToRealtimeRelay = (cpids) => {

        console.log('realtime subscribe');
        console.log(socket);
        console.log(socket_connecting);

        if((!socket || socket === null) && !socket_connecting){
            console.log('creating the io endpoint');
            console.log(props.user.token);
            socket_connecting = true;
            socket = io('https://controller.vendelectric.com/realtime-relay', {query: {token: props.user.token}, forceNew: true});
        }

        if(socket && !socket.connected && !socket_connecting){
            console.log('connect the socket');
            socket.connect();
        }

        if(socket && socket.connected){
            console.log('subscribe');
            let sub_cpids = getCPIDs();
            socket.send('subscribe', sub_cpids);
        }

        if(socket){


            // On connect subscribe to the charge points
            socket.on('connect', () => {
                socket_connecting = false;

                let sub_cpids = getCPIDs();

                setTimeout(() => {
                    console.log('subscribe on connect');
                    socket.send('subscribe', sub_cpids);
                }, 2000)
            

                //
                //  Incoming message from the socket
                //
                if(typeof socket._callbacks['$message'] == 'undefined')
                    socket.on('message', (type, msg) => {
                        console.log('got a message');
                        console.log(msg);

                        const newMsg = {
                            ...msg,
                            lastContactDateTime: new Date(new Date().setTime(new Date().getTime() - msg.lastContactMs)),
                            lastAuthorised: new Date(new Date().setTime(new Date().getTime() - msg.uptimeMs)),
                            mode: 'live'
                        }
                        
                        //
                        // Obtain a copy of the non state data
                        //
                        let newRealTimeRelay = JSON.parse(JSON.stringify(nonStateSocketData));
                        
                        //
                        //  Look for the idx of the cpid from the incomming message
                        //
                        const idx = newRealTimeRelay.findIndex(nCP => nCP.cpid === newMsg.cpid);

                        // I exist, update the data
                        if(idx > -1){
                        
                            // Update the existing message with the new message 
                            if(newMsg.isOnline === true || newRealTimeRelay[idx].isOnline === true)
                                newRealTimeRelay[idx] = newMsg;
                        }
                        else{
                            
                            //
                            //  I don't exist and I am offline
                            //  I won't have any data so I need to get the last known
                            //  state from the db. Do this outside this function
                            //  to avoid a delayed overwrite of the data.
                            //
                            if(newMsg.isOnline === false){
                                // let newFetchLastKnown = JSON.parse(JSON.stringify(fetchLastKnown));
                                // newFetchLastKnown.push(newMsg.cpid);
                                // setFetchLastKnown(newFetchLastKnown);
                                loadLastKnown(newMsg.cpid)
                            }

                            // Add the new charge point message in
                            newRealTimeRelay.push(newMsg);
                        }

                        // setting the state works from inside a socket
                        // message but retrieving the current state
                        // object doesn't instead we use a variable
                        // outside the state and emit and event to 
                        // trigger the set state event
                        nonStateSocketData = newRealTimeRelay;
                        window.dispatchEvent(new Event('CPID'));

                        
                    });

            });


            
            // On connect subscribe to the charge points
            socket.on('disconnect', () => {
                console.log('disconnected')
            })

            
            if(typeof socket._callbacks['$error'] == 'undefined')
                socket.on('error', (error) => {
                    console.log(`Socket error`)
                    console.log(error)
                })

            
            if(typeof socket._callbacks['$connect_error'] == 'undefined')
                socket.on('connect_error', (error) => {
                    console.log(`Socket connect_error`)
                    console.log(error)
                })

            
            // if(typeof socket._callbacks['$connect_timeout'] == 'undefined')
            //     socket.on('connect_timeout', (error) => {
            //         console.log(`Socket connect_timeout`)
            //         console.log(error)
            //     })

            
            // if(typeof socket._callbacks['$reconnect_error'] == 'undefined')
            //     socket.on('reconnect_error', (error) => {
            //         console.log(`Socket reconnect_error`)
            //         console.log(error)
            //     })

            
            // if(typeof socket._callbacks['$reconnect_failed'] == 'undefined')
            //     socket.on('reconnect_failed', (error) => {
            //         console.log(`Socket reconnect_failed`)
            //         console.log(error)
            //     })

        }

    }


    const loadLastKnown = cpid => {

        //
        //  Add an abort controller to the ctls array
        //
        abortCtls.push(new AbortController());
        const abortIdx = (abortCtls.length-1);
        const signal = abortCtls[abortIdx].signal;

        context.fetchPointLastKnown(cpid, signal).then(
            res => {

                const newMsg = {
                    ...res.result,
                    lastContactDateTime: new Date(new Date().setTime(new Date().getTime() - res.result.lastContactMs)),
                    lastAuthorised: null,
                    mode: 'dead'
                }
                    
                //
                // Obtain a copy of the non state data
                //
                let newRealTimeRelay = JSON.parse(JSON.stringify(nonStateSocketData));
                const idx = newRealTimeRelay.findIndex(nCP => nCP.cpid === newMsg.cpid);

                // I exist, update the data
                if(idx > -1){                     
                    // Update the existing message with the new message 
                    newRealTimeRelay[idx] = newMsg;
                }
                else{
                    // Add the new charge point message in
                    newRealTimeRelay.push(newMsg);
                }

                nonStateSocketData = newRealTimeRelay;

                // setting the state works from inside a socket
                // message but retrieving the current state
                // object doesn't instead we use a variable
                // outside the state and emit and event to 
                // trigger the set state event
                window.dispatchEvent(new Event('CPID'));

            },
            err => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        //console.log(realTimeRelay)
    }, [realTimeRelay])


    const getSocketInfo = cpid => {
        return realTimeRelay.find(feed => feed.cpid == cpid);
    }


    return (
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxHeight: 300, overflow: 'auto'}}>
            {cpidSubscribed.map((cpid, idx) => (
                <TicketChargeChip key={idx} cpid={cpid} socketInfo={getSocketInfo(cpid)} handleShowSocket={setShowSocket} />
            ))}
            
            {showSocket != null && <TicketChargePointDialog handleClose={() => setShowSocket(null)} cpid={showSocket} socketInfo={getSocketInfo(showSocket)} />}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketChargePointSubscription)