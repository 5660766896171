import React from 'react';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';

const EvPoint = props => {
    const point = props.point;

    const lastHeartBeat = () => {

        if(point.LastHeartBeat == null)
            return '';

        const d = point.LastHeartBeat.split(/-|T|:|Z|\./);
        const heart = new Date(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]);

        let hrs = 0, mins = 0, secs= 0;
        var diff = (new Date() - heart);

        // First get the total seconds
        secs = Math.abs(diff)/1000;

        // then get the total hours in those seconds
        hrs = Math.floor(secs/3600);

        // then get the total mins left in the seconds
        mins = Math.floor(secs/60);

        return hrs>0?hrs.toFixed(0) + ' Hrs':mins>0?mins.toFixed(0) + ' Mins':secs.toFixed(0) + ' Secs';
    }

    const status = () => {
        switch(parseInt(point.Status)){
            case 0:
                return 'UNASSIGNED'
            case 1:
                return 'NETWORK_ERROR'
            case 2:
                return 'IDLE'
            case 3:
                return 'CHARGING'
            case 4:
                return 'CONNECTED'
            case 5:
                return 'RESERVED'
            case 6:
                return 'FAULT_CONDITION'
            default:
                return 'UNKNOWN'
        }
    }

    const connectorStatus = () => {
        switch(parseInt(point.ConnectorStatus)){
            case 0:
                return 'UNLOCKED'
            case 1:
                return 'LOCKED'
            case 2:
                return 'CONNECTED'
            case 3:
                return 'ERROR'
            case 4:
                return 'FAILED'
            default:
                return 'UNKNOWN'
        }
    }

    const operationalStatus = () => {
        switch(parseInt(point.OperationalStatus)){
            case 0:
                return 'PRE_COMMISSIONED'
            case 1:
                return 'COMMISSIONED'
            case 2:
                return 'IN_MAINTENANCE'
            case 3:
                return 'WITHDRAWN'
            default:
                return 'UNKNOWN'
        }
    }

    const donutStatus = () => {
        let secondsDif = 601;
        if(point.LastHeartBeat != null){
            const d = point.LastHeartBeat.split(/-|T|:|Z|\./);
            const newD = new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]));
            const dif = new Date().getTime() - newD.getTime();
            secondsDif = dif / 1000;
        }

        const status = parseInt(point.Status);
        const connectorStatus = parseInt(point.ConnectorStatus);
        const controlPilot = parseInt(point.ControlPilot);
        const cableRating = parseInt(point.CableRating);

        if(status == 1 || status == 6 || connectorStatus > 2 || secondsDif > 600)
            return styles.donutFault;

        if(status == 3 && (controlPilot == 1 || controlPilot == 2) && cableRating > 0)
            return styles.donutCharging;

        if(status == 3)
            return styles.donutChargePending;
            
        if(status == 2)
            return styles.donutIdle;

        return {};
    }

    const chargingStatus = () => {

        const status = parseInt(point.Status);
        const controlPilot = parseInt(point.ControlPilot);
        const cableRating = parseInt(point.CableRating);

        if(status == 3 && (controlPilot == 1 || controlPilot == 2) && cableRating > 0)
            return styles.charging;

        return {};
    }

    const chargePointID = EVPointID => {
        if(parseInt(EVPointID) < 100000)
            return `UKEV${EVPointID}`;
        else
            return `UKEV${EVPointID.toString().slice(0, -1)}-${EVPointID.toString().slice(-1)}`;
    }

    return (
        <GlobalContext.Consumer>
            {context => (
                <div style={styles.container}>
                    {props.busyPoints.indexOf(point.EVPointID) > -1 && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}
                    
                    <div style={styles.wrapper}>


                        <div style={styles.header}><div style={Object.assign({}, styles.donut, donutStatus())}><div style={styles.donutInner}></div></div><span style={{flex: 1}}>{chargePointID(point.EVPointID)}</span><a href="JavaScript:void(0);" onClick={() => {props.removePoint(point.EVPointID)}} style={styles.remove}>&times;</a></div>
                        <div style={Object.assign({}, styles.attribute, point.groupSchemeID != props.commission.groupSchemeID && styles.attributeAlert)}>Group Scheme: <b>{point.groupSchemeID}</b></div>
                        <div style={Object.assign({}, styles.attribute, point.ChargeSiteID > 0 && styles.attributeAlert)}>Site: <b>{point.ChargeSiteID}</b></div>
                        <div style={Object.assign({}, styles.attribute, point.OperationalStatus == 1 && styles.attributeAlert)}>Operational: <b>{operationalStatus()}</b></div>
                        <div style={Object.assign({}, styles.attribute, chargingStatus())}>Status: <b>{status()}</b></div>
                        <div style={Object.assign({}, styles.attribute, chargingStatus())}>Max Amps: <b>{point.MaxAmps}A</b> - Max Load: <b>{point.MaxLoad}A</b></div>
                        <div style={Object.assign({}, styles.attribute, chargingStatus())}>Cable Rating: <b>{point.CableRating}A</b></div>
                        <div style={Object.assign({}, styles.attribute, chargingStatus())}>
                            Power: <select value={point.Power} onChange={(e) => {props.changePointPower(e, point.EVPointID)}}>
                                <option value="3.6">3.6kWh</option>
                                <option value="7.2">7.2kWh</option>
                                <option value="11">11kWh</option>
                                <option value="22">22kWh</option>
                            </select>
                        </div>
                        <div style={styles.attribute}>Meter: <b>{point.MeterReading}</b></div>
                        <div style={styles.attribute}>Minutes: <b>{point.Minutes}</b> {point.AddTime>0&&`..add ${point.AddTime} mins`}</div>
                        <div style={styles.attribute}>Last Contact: <b>{lastHeartBeat()}</b></div>
                        <div style={styles.buttonWrapper}>
                            <button style={styles.button} onClick={() => {props.addPointChargeTime(point.EVPointID)}}>Add 5 mins charge</button>
                            <button style={styles.buttonDefault} onClick={() => {props.stopPointChargeTime(point.EVPointID)}}>Stop</button>
                        </div>
                    </div>

                </div>
            )}
        </GlobalContext.Consumer>
    )
}


const styles  = {
    container:{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 290,
        maxWidth: 290,
        border: '1px solid #AAA',
        backgroundColor: '#fff',
        marginBottom: 10,
        marginTop: 10,
        position: 'relative'
    },
    wrapper: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        padding: 12,
        paddingLeft: 40,
        backgroundColor: '#e2e2e2',
        fontWeight: 900,
        marginBottom: 10,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    attribute: {
        padding: '4px 8px',
        fontSize: 13
    },
    attributeAlert: {
        backgroundColor: '#F21843',
        color: '#fff'
    },
    remove: {
        position: 'absolute',
        top: 12,
        right: 12,
        textDecoration: 'none',
        color: '#aaa',
        fontSize: 18,
        fontWeight: 300
    },
    donut: {
        height: 20,
        width: 20,
        backgroundColor: '#CCCC00',
        border: '1px solid #aaa',
        borderRadius: '50%',
        position: 'absolute',
        top: 10,
        left: 10
    },
    donutInner: {
        height: 10,
        width: 10,
        backgroundColor: '#e2e2e2',
        border: '1px solid #aaa',
        borderRadius: '50%',
        position: 'absolute',
        top: 4,
        left: 4
    },
    donutFault: {
        backgroundColor: '#F21843',
        animation: 'blink 1s steps(2, start) infinite'
    },
    donutIdle: {
        backgroundColor: '#00adef',
        animation: 'blink 1s steps(2, start) infinite'
    },
    donutCharging: {
        backgroundColor: '#64AD00',
    },
    donutChargePending: {
        backgroundColor: '#64AD00',
        animation: 'blink 1s steps(2, start) infinite'
    },
    charging: {
        backgroundColor: '#64AD00',
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'space-around'
    },
    button: {
        backgroundColor: '#00adef',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer',
        marginTop: 10
    },
    buttonDefault: {
        backgroundColor: '#424242',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer',
        marginTop: 10
    },
    loadingWrapper: {
        display: 'flex',
        position: 'absolute',
        height: '100%',
        width: '100%',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,.2)',
        zIndex: 99
    },
    loading: {
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    link: {
        backgroundColor: '#00adef',
        border: 'none',
        color: '#fff',
        padding: '3px 5px',
        textDecoration: 'none'
    },
}

export default EvPoint