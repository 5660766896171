import React, {useState, useContext} from 'react';
import GlobalContext from '../../../context/global-context';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import Pending from './pending';
import Awaiting from './awaiting';
import Settled from './settled';
import Report from './report';


const BerthVendStatements = props => {

    const context = useContext(GlobalContext)
    const [tab, setTab] = useState(0)
    const [awaitingBadge, setAwaiting] = useState(0)

    const handleTabChange = (e, val) => {
        setTab(val);
    }

    return (
        <div style={styles.container}>
            <div style={styles.inner}>

                <div style={styles.topBar}>
                    <h1 style={{flex: 1}}>BerthVend Statements</h1>
                    
                    <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        aria-label="disabled tabs example"
                    >
                        <Tab label="Pending" />
                        <Tab label={<Badge style={{padding: '0 16px'}} color="error" badgeContent={awaitingBadge}>Awaiting Payment</Badge>} />
                        <Tab label="Settled" />
                        <Tab label="Report" />
                    </Tabs>
                </div>

                <Pending show={tab === 0} />
                <Awaiting show={tab === 1} setBadge={setAwaiting} />
                <Settled show={tab === 2} />
                <Report show={tab === 3} />
                

            </div>
        </div>
    );

}


const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    topBar: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center',
        marginBottom: '10px'
    }
}

export default BerthVendStatements