import React, { useContext, useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, MarkerClusterer, InfoBox } from '@react-google-maps/api';
import CustomStyle from './mapStyle';
import Pins from '../map/pins';
import { makeStyles, useTheme } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    map: {
      width: '100%',
      height: '100%',
      position: 'relative'
    }
}));

const DEFAULT_CAMERA = {
  lat: 51.5007,
  lng: 0.1246,
}

const Map = props => {

    const [ ticket, setTicket ] = useState(null);
    const [ infoBox, setInfoBox] = useState(null);
    const map = useRef(null);

    const theme = useTheme();
    const styles = useStyles(theme);

    

    const clusterStyle = [{
        url: Pins.blue,
        height: 20,
        width: 20,
        textColor: '#fff',
        textSize: 12,
        anchor: [10, 10], 
        anchorText: [0, 0],
        zIndex: 10
    }];

    useEffect(() => {

      setTicket(props.ticket);

    }, [props.ticket])



    const getFilteredSites = () => {

      const ticket_sites = [];
      ticket.sites.map(s => ticket_sites.push(s.id_site));

      let map_sites = props.meta.sites.filter(s => ticket_sites.indexOf(s.ChargeSiteID) > -1);
      let filteredSites = [];

      map_sites.map(site => {

        // The site wide state will be determined by this
        let idle = false, charging = false, version = false, fault = false, withdrawn = false;
        const points = props.meta.charge_points.filter(p => p.ChargeSiteID === site.ChargeSiteID);

        points.map(p => {

          // Setup some variables for this point on it's own
          let c = false, f = false, w = false;

          if(p.DeploymentStatus == 'WITHDRAWN')
            w = true;

          if(p.Status == 'CHARGING')
            c = true;
          
          if(p.Status == 'FAULT_CONDITION' || p.LastHeartBeat == null){
            f = true;
          } else {
            const d = p.LastHeartBeat.split(/-|T|:|Z|\./);
            const heart = new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]));
            let mins = 0;
            var diff = (new Date() - heart);
            // The total mins since last heartbeat
            mins = Math.floor((Math.abs(diff)/1000)/60);

            if(mins > 120)
              f = true;
          }

          // If the point is withdrawn set the site marker to withdrawn
          if(w)
            withdrawn = w //this.state.filter.withdrawn;  

          // If the point is charging set the site marker to charging
          if((c && !f) && !w)
            charging = c //this.state.filter.charging;

          // If the point is fault set the site marker to fault
          if(f && !w)
            fault = f //this.state.filter.fault;
          
          // If this point isn't charging, in fault or a lesser version then it is idle
          if(!c && !f && !w)
            idle = true;      
          
        })

        site.Marker = Pins.white;

        // Matches the withdrawn
        if(withdrawn)
          site.Marker = Pins.black;
        
        // Is idle
        if(idle)
          site.Marker = Pins.blue;

        // Is charging
        if(charging)
          site.Marker = Pins.green;

        // Is faulty
        if(fault)
          site.Marker = Pins.red;

        // Is Faulty and either idle or charging
        if((charging || idle) && fault)
          site.Marker = Pins.orange;

        
        filteredSites.push(site);

      })

      return filteredSites;
    }

    const onMarkerPressHandler = (props, marker, e, site) => {
      console.log('click');
    }

    const getGeo = (site) => {
      let geo = {
        latitude: 0,
        longitude: 0
      }

      if(typeof site.Geo != 'undefined' && site.Geo != null)
        geo = {
          latitude: site.Geo.latitude,
          longitude: site.Geo.longitude
        }
    
      return geo;
    }


    const getCameraLocation = () => {
      if(ticket != null){
        const ticket_sites = [];
        ticket.sites.map(site => ticket_sites.push(site.id_site));
        const map_sites = props.meta.sites.filter(site => ticket_sites.indexOf(site.ChargeSiteID) > -1);
        let lat = 0;
        let lon = 0;
        map_sites.map(site => {
          if(typeof site.Geo !== 'undefined' && site.Geo !== null){
            lat += site.Geo.latitude;
            lon += site.Geo.longitude;
          }
        })
        if(lat != 0 || lon != 0)
          return {
            lat: (lat/map_sites.length),
            lng: (lon/map_sites.length),
          }
      }

      return DEFAULT_CAMERA;
    }  

      

    const onMapPressHandler = () => {

        setInfoBox(null);

    }

    return (
      <GoogleMap
          id='map'
          ref={map}
          mapContainerStyle={{
              width: '100%',
              height: '100%',
              position: 'relative'
          }}
          zoom={5}
          center={getCameraLocation()}
          options={{
          styles: CustomStyle,
          disableDefaultUI: true,
          maxZoom: 18
          }}
          google={props.google}
          onClick={onMapPressHandler}
      >

          <MarkerClusterer options={{styles: clusterStyle}} gridSize={1}>
          {
          (clusterer) => getFilteredSites().map((site) => (
            <Marker icon={{url: site.Marker, anchor: new window.google.maps.Point(10,10), scaledSize: new window.google.maps.Size(20,20)}} key={site.ChargeSiteID} position={{lat: getGeo(site).latitude, lng: getGeo(site).longitude}} onClick={(props, marker, e) => {onMarkerPressHandler(props, marker, e, site)}} clusterer={clusterer} site={site} zIndex={10} onMouseOver={() => setInfoBox(site)} />))
          }
          </MarkerClusterer>

          {infoBox && 
          <InfoBox position={{lat: getGeo(infoBox).latitude, lng: getGeo(infoBox).longitude}} onCloseClick={() => setInfoBox(null)}>
            <div style={{ backgroundColor: '#111111', opacity: 0.75, padding: 6, borderRadius: 6 }}>
              <div style={{ fontSize: 12, color: '#ffffff' }}>
                {infoBox.SiteName}
              </div>
            </div>
          </InfoBox>}

      </GoogleMap>
    )

}

export default Map