import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const SystemSectors = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'System Sectors',
                            route: '/settings/system_sectors'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">System Sectors</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>These sectors manage the control to the system by users. The handle is the unique controlling tag assigned to a sector of the system. <br /><b>DO NOT</b> modify these settings unless you know what effects it will have. Seek guidance from your system admin if unsure.</Typography>

                <Crud
                    uri='system/sectors'
                    idCol='id_system_sector'
                    displayColumns={[
                        { 
                            name: 'system_sector_name', 
                            display_name: 'Sector Name',
                            type: 'string',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'system_sector_handle', 
                            display_name: 'Sector Handle',
                            type: 'string',
                            editable: true,
                            required: true
                        }
                    ]}
                />

            </div>

        </div>
    )

}

export default SystemSectors
