import { makeStyles, useTheme, Card, CardContent, Typography, FormControl, InputLabel, Select, MenuItem, Button, IconButton, CircularProgress, ListItem, List, ListItemText, Link } from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import globalContext from '../../../context/global-context';
import { Alert } from '@material-ui/lab';
import Helpers from '../../global/helpers';
import RefreshIcon from '@material-ui/icons/Refresh';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      width: '100%',
      maxWidth: 300,
      minWidth: 300,
      position: 'relative'
    },
    content: {
        flex: 1
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px'
    },
    formControl: {
        minWidth: 100,
        marginTop: 15,
        flex: 1
    },
    buttonGroup: {
        marginTop: 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    close: {
        color: theme.palette.grey[400],
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 199
    },
    alert: {
        marginTop: 15,
        marginBottom: 5
    },
    list: {
        height: 100, 
        overflowY: 'auto'
    }
}));

const CommissioningPoint = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const [overrides, setOverrides] = useState({
        RFIDEnabled: props.point.RFIDEnabled,
        AMPs: props.point.AMPs,
        Power: props.point.Power,
        ConnectorSocketType: props.point.ConnectorSocketType
    });

    const [changed, setChanged] = useState(false);
    const [loading, setLoading] = useState(false);

    const [meters, setMeters] = useState([]);
    const [loadingMeters, setLoadingMeters] = useState(false);

    const handleInputChange = e => {
        const newOverrides = JSON.parse(JSON.stringify(overrides));
        newOverrides[e.target.name] = e.target.value;
        setOverrides(newOverrides);
        setChanged(true);
    }

    const displaySocketId = (EVPointID) => {
        let socket_id = `UKEV${EVPointID < 1000 ? '0' : ''}${EVPointID}`;
        if(socket_id.length === 8)
            return socket_id;
        else
            return `${socket_id.slice(0, 9)}-${socket_id.slice(9, socket_id.length)}`;
    }

    const operationalStatus = (status) => {
        switch(parseInt(status)){
            case 0:
                return <Alert severity="success" className={classes.alert}>Pre-Commissioned</Alert>;
            case 1:
                return <Alert severity="warning" className={classes.alert}>Commissioned</Alert>
            case 2:
                return <Alert severity="error" className={classes.alert}>In Maintenance</Alert>
            case 3:
                return <Alert severity="error" className={classes.alert}>Withdrawn</Alert>
            default:
                return <Alert severity="error" className={classes.alert}>Unknown</Alert>
        }
    }

    const handleRemove = () => {
        if(typeof props.handleRemove === 'function')
            props.handleRemove(props.point.EVPointID);
    }

    const handleSaveChanges = async () => {

        setLoading(true);

        const fields = {
            EVPointID: props.point.EVPointID,
            ConnectorID: props.point.ConnectorID,
            RFIDEnabled: overrides.RFIDEnabled,
            AMPs: overrides.AMPs,
            Power: overrides.Power,
            ConnectorSocketType: overrides.ConnectorSocketType
        }

        await context.commissionChargePointPower(fields).then(async (result) => {

            await context.commissionChargePointConnector(fields).then((result) => {
                setLoading(false);
                setChanged(false);
            }).catch((err) => {
                setLoading(false);
                context.showAlert('error', 'Error saving changes to connector')
            });

        }).catch((err) => {
            setLoading(false);
            context.showAlert('error', 'Error saving changes')
        });

    }

    const handleFetchMeters = () => {
        setLoadingMeters(true);
        context.commissionGetPointMeters(props.point.EVPointID)
        .then(result => {
            setLoadingMeters(false);
            setMeters(result.result);
        })
        .catch(err => {
            context.showAlert('error', `Error fetching last known meters for ${displaySocketId(props.point.EVPointID)}`)
            setMeters([]);
            setLoadingMeters(false);
        })
    }

    const getDebuggerAddress = (EVPointID) => {
        let socket_id = `UKEV${EVPointID}`;
        if(socket_id.length === 8)
            return `https://veos-debugger.vendelectric.com/`;
        else
            return `https://debugger.ocpp.vendelectric.com/#!?id=${socket_id.slice(0, 9)}`;
    }

    useEffect(() => {
        handleFetchMeters()
    }, [props.point])

    return (
        <Card className={classes.root}>
            <IconButton onClick={handleRemove} className={classes.close}><CloseIcon /></IconButton>
            <CardContent className={classes.content}>
                <Typography component="h5" variant="h5">{props.handleIsOnline(props.point.EVPointID) ? <WifiIcon style={{color: theme.palette.success.main}} />: <WifiOffIcon color="error" />} {displaySocketId(props.point.EVPointID)}</Typography>
                <Typography variant="caption" component="div" style={{flex: 1}}>Status: {props.handleIsOnline(props.point.EVPointID) ? <Typography variant="caption"  style={{color: theme.palette.success.main}}><b>Online</b></Typography>: <Typography variant="caption" color="error"><b>Offline</b></Typography>}</Typography>
                <Typography variant="caption" component="div" style={{flex: 1}}>Last Heartbeat: {props.point.LastHeartBeat === null ? <b>Never</b> : Helpers.SQLTimeSince(props.point.LastHeartBeat)}</Typography>
                {operationalStatus(props.point.OperationalStatus)}
                <div className={classes.formControl}>
                    <FormControl fullWidth>
                        <InputLabel id="rfid-label">RFID</InputLabel>
                        <Select
                            labelId="rfid-label"
                            id="rfid"
                            name="RFIDEnabled"
                            value={overrides.RFIDEnabled}
                            onChange={handleInputChange}
                            >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className={classes.row}>
                    <div className={classes.formControl}>
                        <FormControl fullWidth>
                            <InputLabel id="amps-label">AMPs</InputLabel>
                            <Select
                                labelId="amps-label"
                                id="amps"
                                name="AMPs"
                                value={overrides.AMPs}
                                onChange={handleInputChange}
                                >
                                <MenuItem value={16}>16 Amps</MenuItem>
                                <MenuItem value={32}>32 Amps</MenuItem>
                                <MenuItem value={60}>60 Amps</MenuItem>
                                <MenuItem value={63}>63 Amps</MenuItem>
                                <MenuItem value={65}>65 Amps</MenuItem>
                                <MenuItem value={125}>125 Amps</MenuItem>
                                <MenuItem value={200}>200 Amps</MenuItem>
                                <MenuItem value={250}>250 Amps</MenuItem>
                                <MenuItem value={300}>300 Amps</MenuItem>
                                <MenuItem value={400}>400 Amps</MenuItem>
                                <MenuItem value={410}>410 Amps</MenuItem>
                                <MenuItem value={500}>500 Amps</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className={classes.formControl}>
                        <FormControl fullWidth>
                            <InputLabel id="power-label">Power</InputLabel>
                            <Select
                                labelId="power-label"
                                id="power"
                                name="Power"
                                value={overrides.Power}
                                onChange={handleInputChange}
                                >
                                <MenuItem value={3.6}>3.6 kWh</MenuItem>
                                <MenuItem value={7.2}>7.2 kWh</MenuItem>
                                <MenuItem value={11}>11 kWh</MenuItem>
                                <MenuItem value={22}>22 kWh</MenuItem>
                                <MenuItem value={25}>25 kWh</MenuItem>
                                <MenuItem value={43}>43 kWh</MenuItem>
                                <MenuItem value={50}>50 kWh</MenuItem>
                                <MenuItem value={100}>100 kWh</MenuItem>
                                <MenuItem value={120}>120 kWh</MenuItem>
                                <MenuItem value={150}>150 kWh</MenuItem>
                                <MenuItem value={250}>250 kWh</MenuItem>
                                <MenuItem value={350}>350 kWh</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className={classes.formControl}>
                    <FormControl fullWidth>
                        <InputLabel id="connector-label">Connector</InputLabel>
                        <Select
                            labelId="connector-label"
                            id="connector"
                            name="ConnectorSocketType"
                            value={overrides.ConnectorSocketType}
                            onChange={handleInputChange}
                            >
                            <MenuItem value={6}>3 Pin Plug</MenuItem>
                            <MenuItem value={2}>Type 2 Socket</MenuItem>
                            <MenuItem value={8}>Type 2 Tethered</MenuItem>
                            <MenuItem value={3}>Type 2 DC (Tesla)</MenuItem>
                            <MenuItem value={4}>CHAdeMO</MenuItem>
                            <MenuItem value={5}>CCS</MenuItem>
                            <MenuItem value={7}>CCS (Combo)</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div style={{padding: '20px 0'}}>
                    <div className={classes.row}>
                        <Typography variant="caption" style={{flex: 1}}>Last known readings</Typography>
                        <IconButton size="small" onClick={handleFetchMeters} color="primary">{loadingMeters ? <CircularProgress color="inherit" size={22} /> : <RefreshIcon />}</IconButton>
                    </div>
                    <div className={classes.list}>
                        {meters.length === 0 && <Typography variant="caption" color="textSecondary">- No readings</Typography>}
                        <List dense={true}>
                            {meters.map((m, idx) => (
                                <ListItem key={idx}>
                                    <ListItemText primary={`${parseFloat(parseInt(m.Usage)/1000).toFixed(4)}kWh`} secondary={Helpers.SQLtoUTCDate(m.EndTime, true)} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </div>

                <div className={classes.buttonGroup}>
                    <Link href={getDebuggerAddress(props.point.EVPointID)} target="_blank" rel="noreferrer" style={{fontSize: 12}}>Open Debugger</Link>
                    <Button onClick={handleSaveChanges} disabled={!changed} variant="contained" color="secondary">{loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}</Button>
                </div>
            </CardContent>
        </Card>
    )
}

export default CommissioningPoint