import { createMuiTheme } from '@material-ui/core/styles';

const mode = localStorage.getItem('color-scheme') == 'dark'?'dark':'light';

const theme = createMuiTheme({
    palette: {
      type: mode,
      primary: {
        light: '#95dbf6',
        main: '#00adef',
        dark: '#0e5571',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#F2617E',
        main: '#F01842',
        dark: '#700B1F',
        contrastText: '#ffffff',
      },
      background: {
        default: '#f2f2f2'
      },
      text: {
          default: '#303030'
      }
    },
});

const darkTheme = createMuiTheme({
    palette: {
      type: mode,
      primary: {
        light: '#90CAE0',
        main: '#5f8594',
        dark: '#364C54',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#F2617E',
        main: '#F01842',
        dark: '#700B1F',
        contrastText: '#ffffff',
      },
      text: {
          default: '#f8f8f8'
      },
      action: {
        selected: 'rgba(255, 255, 255, 0.3)'
      }
    },
});

export default mode === 'dark' ? darkTheme : theme