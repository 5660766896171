import React from 'react';
import { useTheme, makeStyles, Typography, Dialog, DialogTitle, DialogContent, Divider } from '@material-ui/core';
import Helpers from '../../global/helpers';
import AppUserRecentChargepoints from './app_user/app_user_recent_chargepoints';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const EVConnectAppUser = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const {user} = props;

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    return (
        <React.Fragment>
            <Dialog open={true} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>EV Connect User</DialogTitle>
                <DialogContent>
                    <div className='flex-col gap-10'>
                        
                        <div className='flex-row align-center'>
                            <div className='flex-col gap-0'>
                                <Typography variant="h6">{user.first_name} {user.last_name}</Typography>
                                <Typography variant="h6">{user.email}</Typography>
                            </div>
                        </div>

                        <Divider style={{marginTop: 10, marginBottom: 10}} />

                        <div className='flex-row gap-10'>
                            <div className='flex'>
                                <div className='flex-col'>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">ID: </Typography>
                                        <Typography variant="subtitle1">{user.id_user}</Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Phone: </Typography>
                                        <Typography variant="subtitle1">{user.phone}</Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Member since: </Typography>
                                        <Typography variant="subtitle1">{Helpers.toLocaleDateTime(user.registered_on)}</Typography>
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='flex-col'>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Position: </Typography>
                                        <Typography variant="subtitle1">{user.position}</Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Company Name: </Typography>
                                        <Typography variant="subtitle1">{user.company_name}</Typography>
                                    </div>
                                    <div className='flex-row gap-10'>
                                        <Typography variant="body1">Company Reg No: </Typography>
                                        <Typography variant="subtitle1">{user.company_reg_number}</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Divider style={{marginTop: 10, marginBottom: 10}} />

                        <div className='flex-col'>
                            <Typography variant="subtitle1" style={{fontWeight: 600}}>Recent Chargepoints</Typography>
                            <AppUserRecentChargepoints id_user={user.id_user} />
                        </div>

                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}
export default EVConnectAppUser;