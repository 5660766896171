import React from 'react';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import { Typography, Button, CircularProgress } from '@material-ui/core';

class Site extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);

        this.state = {
            fields: {
                SiteName: '',
                AddressLine1: '',
                AddressLine2: '',
                AddressLine3: '',
                Town: '',
                County: '',
                PostCode: '',
                Country: '',
            },
            errors: {
                SiteName: this.errorField(),
                AddressLine1: this.errorField(),
                AddressLine2: this.errorField(),
                AddressLine3: this.errorField(),
                Town: this.errorField(),
                County: this.errorField(),
                PostCode: this.errorField(),
                Country: this.errorField()
            },
            isValid: false,
            isLoading: false,
            isAdding: false,
            isError: false
        };
    }


    //
    //
    //  FORM BINDING AND VALIDATION
    //
    //


    errorField = (group = null) => {
        return {
            dirty: false,
            invalid: false,
            group: group
        };
    }
  
  
    bindField = (e) => {
        let fields = {...this.state.fields};
        fields[e.target.name] = e.target.value;
        this.setState({fields: fields}, () => {this.validate()});
    }

    dirtyGroup = (group = null, callback = () => {}) => {
        let errors = {...this.state.errors};
        Object.values(errors).map(e => {
            if(e.group == group)
                e.dirty = true;
        })
        this.setState({errors: errors}, () => {callback()});
    }
  
    validate = (callback = () => {}) => {
        let errors = {...this.state.errors};
        const required = [
            'SiteName',
            'AddressLine1',
            'Town',
            'PostCode'
        ]
  
        required.map(f => {
            if(this.state.fields[f].length > 0)
                errors[f].dirty = true;
  
            errors[f].invalid = false;
            if(errors[f].dirty && this.state.fields[f] == ''){
                errors[f].invalid = true;
            }
        })
  
        const isValid = Object.values(errors).findIndex(e => e.invalid === true) === -1;
        this.setState({errors: errors, isValid: isValid}, () => {callback()});
    }


    //
    //
    //  EOF FORM BINDING AND VALIDATION
    //
    //




    onSelect = (siteID) => {
        this.props.selectSite(siteID);
        this.props.switchView('commission');
    }

    onRemove = () => {
        this.props.removeSite();
    }


    onBack = () => {
        this.props.switchView('scheme');
    }

    onAdd = () => {

        this.dirtyGroup(null, () => {
            this.validate(() => {
                if(this.state.isValid){

                    this.setState({isAdding: true, isError: false});
            
                    this.props.addSite(this.state.fields).then(res => {
                        console.log(res)
                        // Reload the schemes
                        this.loadSites();

                        // Select the scheme just created
                        this.onSelect(res.result.ChargeSiteID);

                        // Hide any loading
                        this.setState({isAdding: false, isError: false});

                        // Move to points
                        this.props.switchView('commission');
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({isAdding: false, isError: true});
                    })
                    
                } else {
                    console.log('you are not valid');
                }
            })
        })

    }
    
    loadSites = async () => {
        this.setState({isLoading: true}, async () => {
            await this.props.fetchSites()
            this.setState({isLoading: false})
        })
    }

    getSite = () => {
        const site = this.props.sites.find(s=>s.ChargeSiteID==this.props.commission.siteID);
        if(typeof site != 'undefined')
            return site.ChargeSiteID + ': ' + site.SiteName;

        return '';
    }
    
    componentDidMount(){
        this.loadSites()
    }

    render(){
        const errors = this.state.errors;
        return(
            <div style={styles.container}>

                <div style={styles.wrapper}>

                    <div style={styles.col}>
                        <Typography variant="h5" style={{marginBottom: 20}}>Create a site</Typography>

                        <div style={styles.inputWrapper}>
                            <input style={styles.input} placeholder="Site Name" type="text" id="SiteName" label="Site Name" name="SiteName" autoFocus onChange={this.bindField} />
                        </div>
                        {errors.SiteName.invalid && <span style={styles.inputErrorMsg}>Site name is required</span>}

                        <div style={styles.inputWrapper}>
                            <input style={styles.input} placeholder="Address Line 1" type="text" id="AddressLine1" label="Address Line 1" name="AddressLine1" onChange={this.bindField} />
                        </div>
                        {errors.AddressLine1.invalid && <span style={styles.inputErrorMsg}>Address Line 1 is required</span>}

                        <div style={styles.inputWrapper}>
                            <input style={styles.input} placeholder="Address Line 2" type="text" id="AddressLine2" label="Address Line 2" name="AddressLine2" onChange={this.bindField} />
                        </div>

                        <div style={styles.inputWrapper}>
                            <input style={styles.input} placeholder="Address Line 3" type="text" id="AddressLine3" label="Address Line 3" name="AddressLine3" onChange={this.bindField} />
                        </div>

                        <div style={styles.inputWrapper}>
                            <input style={styles.input} placeholder="Town" type="text" id="Town" label="Town" name="Town" onChange={this.bindField} />
                        </div>
                        {errors.Town.invalid && <span style={styles.inputErrorMsg}>Town is required</span>}

                        <div style={styles.inputWrapper}>
                            <input style={styles.input} placeholder="County" type="text" id="County" label="County" name="County" onChange={this.bindField} />
                        </div>

                        <div style={styles.inputWrapper}>
                            <input style={styles.input} placeholder="Post Code" type="text" id="PostCode" label="Post Code" name="PostCode" onChange={this.bindField} />
                        </div>
                        {errors.PostCode.invalid && <span style={styles.inputErrorMsg}>Post code is required</span>}

                        <div style={styles.inputWrapper}>
                            <input style={styles.input} placeholder="Country" type="text" id="Country" label="Country" name="Country" onChange={this.bindField} />
                        </div>


                        <button style={styles.button} type="submit" onClick={this.onAdd}>{this.state.isAdding?<div style={styles.buttonLoading}><ActivityIndicator /></div>:'CREATE NEW'}</button>
                        

                    </div>

                    <div style={styles.col}>

                        <Typography variant="h6">Select existing site</Typography>
                        <div style={styles.sites}>
                            {this.state.isLoading && <div style={styles.loading}><ActivityIndicator /></div>}
                            {(this.props.sites.length == 0 && !this.state.isLoading)  && <span style={{color: '#a2a2a2'}}>Nothing found</span>}
                            {this.props.sites && this.props.sites.map(s => <p style={styles.site} key={s.ChargeSiteID} onClick={() => this.onSelect(s.ChargeSiteID)}>{s.ChargeSiteID}: {s.SiteName}</p>)}
                        </div>

                    </div>
                    
                </div>

                
                <div style={Object.assign({}, styles.buttonGroup, {padding: 30})}>
                    <Button color="default" variant="contained" onClick={this.onBack}>BACK</Button>
                </div>
            </div>
        );
    }

}




const styles  = {
    container: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: '100%', 
        width: '100%', 
        overflow: 'auto',
        backgroundColor: '#e2e2e2'
    },
    header: {
        color: '#484848',
        fontWeight: 300
    },
    wrapper: {
        flex:1,
        padding: 30,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    col: {
        flex: 1,
        padding: 20,
        minWidth: 300
    },
    signinWrapper: {
      width: '80%',
      maxWidth: 300,
      display: 'flex',
      flexDirection: 'column'
    },
    formError: {
      color: '#F26A6A',
      fontSize: 14,
      marginBottom: 15,
      textAlign: 'center',
      display: 'block'
    },
    inputWrapper: {
      height: 54,
      width: '100%',
      backgroundColor: '#ebebeb',
      borderRadius: 12,
      marginBottom: 10,
      justifyContent: 'center',
      display: 'flex'
    },
    input: {
        fontSize: 16, 
        flex: 1,
        paddingLeft: 20,
        border: 'none'
    },
    inputError: {
      boxShadow: 'inset 0 0 0 1px #F26A6A'
    },
    inputErrorMsg: {
      color: '#F26A6A',
      fontSize: 11,
      paddingLeft: 20,
      marginBottom: 8,
      display: 'block',
      width: '100%'
    },
    button: {
        backgroundColor: '#F07E00',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer',
        alignSelf: 'flex-end'
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 15,
        padding: 30,
        flex: 1
    },
    buttonDefault: {
        backgroundColor: '#666666',
        border: 'none',
        color: '#fff',
        padding: 12,
        fontSize: 16,
        fontWeight: 300,
        cursor: 'pointer',
        alignSelf: 'flex-end'
    },
    buttonLoading: {
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loading: {
      backgroundColor: '#00adef',
      opacity: .7,
      borderRadius: 25,
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    site: {
        cursor: 'pointer'
    },
    sites: {
        backgroundColor: '#fff',
        border: '1px solid #d2d2d2',
        padding: 12,
        marginTop: 20,
        maxHeight: 400,
        width: '100%',
        overflow: 'auto',
    }
}

export default Site