import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Helpers from '../../global/helpers';

import AutoTable from '../../../components/autoTable';
import globalContext from '../../../context/global-context';
const useStyles = makeStyles(theme => ({
    container: {
        display: 'block',
        width: '100%',
        maxHeight: 500,
        overflowY: 'auto'
    },
    inner: {},
}));

const AppUserRecentSessions = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const {id_user} = props;

    const headCells = ["id_charge_session", "id_charge_point", "start", "end", "energy", "cost", "tariff", "currency", "pay_method"]
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const makeCurrency = (val, id) => {
        const row = data.find(r => r.id_charge_session === id);
        if(typeof row !== 'undefined')
            return Helpers.localeCurrency(val, row.currency);
        else
            return '';
    }

    const loadData = async search => {
        setLoading(true);
        setError(null);
        setData([]);

        try {
            
            const res = await context.apiRequest(`/service_consumer/app_user/recent_sessions?id=${id_user}`, 'GET');
            if(res.success)
                setData(res.result);
            else
                throw res.msg;
            
        } catch (err) {

            setError(typeof err === 'string' ? err : typeof err.msg !== 'undefined' ? err.msg : JSON.stringify(err));
            setData([]);
            
        } finally {
            setLoading(false);
        }
        
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>

                    <Typography variant="subtitle1" style={{fontWeight: 600}}>Recent Charge Sessions</Typography>

                    {error && <Alert severity="error">{error}</Alert>}

                    <AutoTable 
                        rows={data} 
                        idCol="id_charge_session"
                        headCells={headCells}
                        loading={loading}
                        cellReplacementProps={{
                            'start': (val, id) => Helpers.toLocaleDateTime(val),
                            'end': (val, id) => Helpers.toLocaleDateTime(val),
                            'energy': (val, id) => `${+(val) > 0 ? parseFloat(+(val)/1000).toFixed(2) : val} kWh`,
                            'cost': makeCurrency,
                            'tariff': makeCurrency,
                        }}
                    />

                </div>
            </div>
        </React.Fragment>
    )
}
export default AppUserRecentSessions;