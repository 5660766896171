import React, {useEffect, useContext, useState} from 'react';
import GlobalContext from '../../context/global-context';
import AddSubscriptionSocket from './subscription_socket_add';
import UpdateSubscriptionSocket from './subscription_socket_update';
import CommissioningDialog from '../commission/commissioning_dialog';
import { withRouter } from 'react-router-dom';
import SubscriptionSocket from './subscription_socket';

import {Button, Typography, Dialog, DialogContent, DialogActions, TextField, makeStyles, useTheme, CircularProgress} from '@material-ui/core';
import SubscriptionSocketGroup from './subscription_socket_group';
import DragDropGroup from '../../components/dragDropGroup';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../../components/progressButton';



const useStyles = makeStyles(theme => ({
    socketWrapper: {
        marginTop: 6,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '6px'
    }
}));

const DeleteSocketDialog = props => {

    const {show, handleConfirm, handleClose, socket_id} = props;
    
    return (
        <Dialog open={show} onClose={handleClose} aria-labelledby="delete-socket-dialog-title">
            <DialogContent>
                <Typography variant="subheading">Are you sure you wish to remove socket <b>{socket_id}</b>?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">Cancel</Button>
                <Button onClick={handleConfirm} color="secondary">Confirm</Button>
            </DialogActions>
        </Dialog>
    )

}


const GroupSocketsDialog = props => {

    const context = useContext(GlobalContext);
    const [passcode, setPasscode] = useState(context.authCode());
    const {show, handleClose, sockets, error, waiting} = props;

    const handleConfirm = () => {
        if(typeof props.handleConfirm === 'function' && passcode !== '')
            props.handleConfirm(sockets, passcode);
    }

    const presentSocketIds = () => {
        let str = '';
        sockets.map(s => str += ` & ${s.socket_id}`);
        return str.slice(3);
    }
    
    return (
        <Dialog open={show} onClose={handleClose} aria-labelledby="groups-sockets-dialog-title" maxWidth={'xs'} fullWidth>
            <DialogContent>
                <Typography variant="h6" style={{marginBottom: 20}}>Group sockets <b>{presentSocketIds()}</b> into a single unit?</Typography>
                {error && <Alert severity='error' style={{marginBottom: 10}}>{error}</Alert>}
                <TextField
                    name="passcode"
                    value={passcode}
                    onChange={e => {setPasscode(e.target.value)}}
                    label="Authorisation code"
                    type="password"
                    InputLabelProps={{shrink: true}}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">Cancel</Button>
                <ProgressButton waiting={waiting} onClick={handleConfirm} color="secondary">Confirm</ProgressButton>
            </DialogActions>
        </Dialog>
    )
    
}


const RemoveSocketGroupDialog = props => {

    const context = useContext(GlobalContext);
    const [passcode, setPasscode] = useState(context.authCode());
    const {show, handleClose, error, waiting, id_unit_group} = props;

    const handleConfirm = () => {
        if(typeof props.handleConfirm === 'function' && passcode !== '')
            props.handleConfirm(id_unit_group, passcode);
    }
    
    return (
        <Dialog open={show} onClose={handleClose} aria-labelledby="groups-sockets-dialog-title" maxWidth={'xs'} fullWidth>
            <DialogContent>
                <Typography variant="h6" style={{marginBottom: 20}}>Split grouped sockets back into a individual units?</Typography>
                {error && <Alert severity='error' style={{marginBottom: 10}}>{error}</Alert>}
                <TextField
                    name="passcode"
                    value={passcode}
                    onChange={e => {setPasscode(e.target.value)}}
                    label="Authorisation code"
                    type="password"
                    InputLabelProps={{shrink: true}}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">Cancel</Button>
                <ProgressButton waiting={waiting} onClick={handleConfirm} color="secondary">Confirm</ProgressButton>
            </DialogActions>
        </Dialog>
    )
    
}


const SubscriptionSockets = props => {
    const { id_subscription, id_order } = props;

    const [sockets, setSockets] = useState([])
    const [socketGroups, setSocketGroups] = useState([])

    const [socketStatuses, setSocketStatuses] = useState([])
    const [showAdd, setShowAdd] = useState(false)
    const [deleteSocket, setDeleteSocket] = useState(null)

    const [groupSockets, setGroupSockets] = useState(null)
    const [removeGroupSockets, setRemoveGroupSockets] = useState(null)

    const [commissionSockets, setCommissionSockets] = useState(false)
    const [updateSocket, setUpdateSocket] = useState(null)
    const [loading, setLoading] = useState(true);

    const [errors, setErrors] = useState({
        group: null,
        removeGroup: null
    })
    const [waiting, setWaiting] = useState({
        group: false,
        removeGroup: false
    })

    const context = useContext(GlobalContext);
    const theme = useTheme();
    const styles = useStyles(theme);

    useEffect(() => {
        fetchSockets();
        fetchSocketStatus();
    }, [props.reloads])

    const fetchSockets = () => {
        context.fetchSubscriptionSockets(id_subscription).then(
            res => {

                // Set ungrouped sockets
                setSockets(res.result.filter(s => s.id_unit_group === null));

                // Set grouped units
                let groups = [];
                res.result.filter(s => s.id_unit_group !== null).map(s => {
                    const idx = groups.findIndex(g => g.id_unit_group === s.id_unit_group);
                    if(idx === -1)
                        groups.push({
                            id_unit_group: s.id_unit_group,
                            sockets: [s]
                        })
                    else
                        groups[idx].sockets.push(s);
                });
                setSocketGroups(groups);

                setLoading(false);
            }, 
            err => {
                context.showAlert('error', `Error fetching sockets: ${err.toString()}`);
            }
        )
    }

    const fetchSocketStatus = () => {
        context.subscriptionSuspendStatus(id_subscription).then(
            res => {
                setSocketStatuses(res.result);
            }, 
            err => {
                context.showAlert('error', `Error fetching socket statuses: ${err.toString()}`);
            }
        )
    }

    const handleSocketDelete = () => {
        const socket = sockets.find(s => s.id_subscription_socket === deleteSocket)
        console.log(socket);

        if(id_subscription === socket.id_subscription)
            context.deleteSubscriptionSocket(deleteSocket).then(
                res => {
                    fetchSockets();
                    setDeleteSocket(null);
                }, 
                err => {
                    context.showAlert('error', `Error deleting socket: ${err.toString()}`);
                }
            )

        if(id_subscription === socket.renewal_id_subscription)
            context.apiRequest(`/subscription/socket/${deleteSocket}/renewal`, 'DELETE').then(
                res => {
                    fetchSockets();
                    setDeleteSocket(null);
                }, 
                err => {
                    context.showAlert('error', `Error deleting socket: ${err.toString()}`);
                }
            )
    }

    const handleSocketDeleteClose = () => {
        setDeleteSocket(null);
    }

    const getSocketId = id => {
        if(id === null)
            return '';

        return sockets.find(s => s.id_subscription_socket == id).socket_id
    }

    const socketCount = () => {
        let count = 0;
        sockets.map(s => count += (s.connectors !== null ? +(s.connectors) : 0));
        if(socketGroups !== null)
            socketGroups.map(g => {
                g.sockets.map(s => count += (s.connectors !== null ? +(s.connectors) : 0))
            });
        return count;
    }

    const handleCommission = () => {
        if(typeof props.history !== 'undefined')
            props.history.push(`/commissioning?subscription=${id_subscription}&order=${id_order}`)
    }

    
    const isSuspended = socket_id => {
        const socket = socketStatuses.find(s => s.cpid == socket_id);
        if(typeof socket != 'undefined' && socket.suspended == true)
            return true;

        return false;
    }

    const isIncomplete = socket_id => {
        const socket = sockets.find(s => s.socket_id == socket_id);
        if(typeof socket != 'undefined' && socket.controller_settings === null && socket.controller_settings_transferred === null)
            return true;

        return false;
    }

    const handleConfirmGroupSockets = (idxOne, idxTwo) => {

        const socketOne = JSON.parse(JSON.stringify(sockets[idxOne]));
        const socketTwo = JSON.parse(JSON.stringify(sockets[idxTwo]));
        setGroupSockets([socketOne, socketTwo]);

    }

    const handleGroupSockets = (sockets, passcode) => {

        setErrors({
            ...errors,
            group: null
        })

        setWaiting({
            ...waiting,
            group:true
        })

        let id_subscription_sockets = [];
        sockets.map(s => id_subscription_sockets.push(s.id_subscription_socket));

        context.apiRequest('/subscription/sockets/group', 'POST', {
            id_subscription_sockets,
            passcode
        }).then(
            res => {

                context.setAuthCode(passcode);
                setWaiting({
                    ...waiting,
                    group:false
                })
                fetchSockets();
                setGroupSockets(null);
            },
            err => {

                setWaiting({
                    ...waiting,
                    group:false
                })

                setErrors({
                    ...errors,
                    group: err.msg
                })
            }
        )        

    } 

    const handleDeleteGroup = (id_unit_group, passcode) => {

        setErrors({
            ...errors,
            removeGroup: null
        })

        setWaiting({
            ...waiting,
            removeGroup:true
        })

        context.apiRequest('/subscription/sockets/group', 'DELETE', {
            id_unit_group,
            passcode
        }).then(
            res => {

                context.setAuthCode(passcode);
                setWaiting({
                    ...waiting,
                    removeGroup:false
                })
                fetchSockets();
                setRemoveGroupSockets(null);
            },
            err => {

                setWaiting({
                    ...waiting,
                    removeGroup:false
                })

                setErrors({
                    ...errors,
                    removeGroup: err.msg
                })
            }
        )  

    }

    return (
        <React.Fragment>

            {/* List the charge points */}
            <div className={styles.socketWrapper}>
                {loading && <div className="row align-center gap-5"><CircularProgress size={16} /><Typography color="primary" variant="caption">Loading sockets</Typography></div>}
                
                {socketGroups.map((g, idx) => <SubscriptionSocketGroup key={idx} group={g} setUpdateSocket={setUpdateSocket} isSuspended={isSuspended} isIncomplete={isIncomplete} handleDelete={setRemoveGroupSockets} id_subscription={id_subscription} />)}
                
                <DragDropGroup id="subscription-sockets-combine" onCombine={handleConfirmGroupSockets} direction="horizontal">
                    {sockets.map((s, idx) => <SubscriptionSocket key={idx} onClick={() => setUpdateSocket(s)} socket={s} id_subscription={id_subscription} isSuspended={isSuspended} isIncomplete={isIncomplete} handleDelete={setDeleteSocket} />)}
                </DragDropGroup>
            
            </div>

            {socketCount() > props.max && 
            <div><Typography variant="caption" color="error">This subscription is oversubscribed</Typography></div>}
            
            {/* Add a new charge point */}
            {socketCount() < props.max && <Button color="secondary" onClick={() => setShowAdd(true)}>Add unit</Button>}
            
            {/* Commission */}
            {(socketCount() > 0 && !props.commissioned) && <Button color="secondary" onClick={handleCommission}>Commission</Button>}
            
            {/* Add socket dialog */}
            <AddSubscriptionSocket id_subscription={id_subscription} success={fetchSockets} close={() => setShowAdd(false)} show={showAdd} />

            {/* Update socket dialog */}
            {updateSocket !== null && <UpdateSubscriptionSocket socket={updateSocket} success={fetchSockets} close={() => setUpdateSocket(null)} show={true} />}
            
            {/* Delete socket dialog */}
            <DeleteSocketDialog show={deleteSocket != null} handleClose={handleSocketDeleteClose} handleConfirm={handleSocketDelete} socket_id={getSocketId(deleteSocket)} />
            
            
            {/* Group sockets dialog */}
            {groupSockets != null && <GroupSocketsDialog show={true} handleClose={() => setGroupSockets(null)} handleConfirm={handleGroupSockets} sockets={groupSockets} error={errors.group} waiting={waiting.group} />}

            {/* Group sockets dialog */}
            {removeGroupSockets != null && <RemoveSocketGroupDialog show={true} handleClose={() => setRemoveGroupSockets(null)} handleConfirm={handleDeleteGroup} id_unit_group={removeGroupSockets} error={errors.removeGroup} waiting={waiting.removeGroup} />}

            {/* Commission sockets dialog */}
            {commissionSockets && <CommissioningDialog show={commissionSockets} close={()=>{setCommissionSockets(false)}} id_subscription={id_subscription} evPoints={sockets} success={props.getSubscriptions} groupSchemeName={props.groupSchemeName} />}
        
        </React.Fragment>
    )
}

export default withRouter(SubscriptionSockets);