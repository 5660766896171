import React, {useContext, useState} from 'react';
import Helpers from '../global/helpers';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import querystring from 'query-string';
import {
    TablePagination, 
    Divider,
    CircularProgress
} from '@material-ui/core/';
import SearchBar from '../../components/searchBar';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import Prompt from '../../components/prompt';
import globalContext from '../../context/global-context';

import Quickcrumbs from '../global/Quickcrumbs';


const ResendSessionReceipt = props => {
    
    const context = useContext(globalContext)
    
    const [confirm, setConfirm] = useState(false);
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);


    const resendReceipt = async => {

        try {

            setConfirm(false);
            setSending(true);
            setSent(false);

            context.apiRequest(`/service_consumer/sessions/receipt/${props.id_session}`, 'POST').then(
                res => {
                    setSent(true);
                    setSending(false);
                    context.showAlert('success', 'Receipt sent');
                },
                err => {
                    setSending(false);
                    context.showAlert('error', typeof err === 'string' ? err : JSON.stringify(err));
                }
            )
            
        } catch (error) {
            setSending(false);
            console.log(error);
        }

    }

    
    return (
        <React.Fragment>
            <Button variant="contained" color="secondary" onClick={() => setConfirm(true)}>
                {(!sent && !sending) && <React.Fragment><SendIcon style={{marginRight: 15}} />Receipt</React.Fragment>}
                {sending && <React.Fragment><CircularProgress size={20} color="inherit" />Receipt</React.Fragment>}
                {(sent && !sending) && <React.Fragment><CheckIcon style={{marginRight: 15}} />Receipt</React.Fragment>}
            </Button>

            <Prompt show={confirm} title="Resend Receipt" message="Confirm you wish to email this receipt to the user" onClose={() => setConfirm(false)} onConfirm={resendReceipt} /> 
        </React.Fragment>
    )
}


class ChargeSessions extends React.Component {

    static contextType = GlobalContext

    constructor(props){
        super(props);
        const qs = querystring.parse(this.props.location.search);

        this.state = {
            fields: {
                date: new Date().toISOString().split('T')[0],
            },
            loadedFields: {
                date: null
            },
            data: [],
            filteredData: [],
            loading: false,
            downloading: false,
            showDialog: false,
            search: typeof qs.search != 'undefined'?qs.search:'',
            table: {
                page: typeof qs.page != 'undefined'?parseInt(qs.page):0,
                rowsPerPage: 25
            },
        };
    }


    
    handleChange = (e, callback = () => {}) => {
        let fields = {...this.state.fields};
        fields[e.target.name] = e.target.value;
        this.setState({fields: fields}, () => {
            typeof callback === 'function' && callback();
        });
    }
    
    handleCheckboxChange = (e, callback = () => {}) => {
        let fields = {...this.state.fields};
        fields[e.target.name] = e.target.checked;
        this.setState({fields: fields}, () => {
            typeof callback === 'function' && callback();
        });
    }

    closeDialog = () => {
        this.setState({showDialog: false});
    }

    loadData = () => {
        this.setState({loading: true, loadedFields: {...this.state.fields}, data: []});
        this.context.apiRequest(`/service_consumer/sessions?${this.context.queryString(this.state.fields)}`, 'GET')
        .then(
            res => {
                console.log(res);
                const data = res.result;
                this.setState({data: data, filteredData: data, loading: false})
            },
            err => {
                console.log(err);
                this.setState({data: [], loading: false})
            }
        );
    }

    dirty = () => {        
        if(this.state.fields.date != this.state.loadedFields.date)
            return true;

        return false;
    }


    downloadCSV = () => {
        if(this.dirty()){
            this.setState({showDialog: true});
        } else {
            if(this.state.data.length > 0){
                this.setState({downloading: true});
                let csvStr = '';

        
                // Add the headers
                csvStr = csvStr + 'Charge Session ID,';
                csvStr = csvStr + 'Charge Point,';
                csvStr = csvStr + 'Start Time,';
                csvStr = csvStr + 'End Time,';
                csvStr = csvStr + 'Name,';
                csvStr = csvStr + 'Email,';
                csvStr = csvStr + 'VRM,';
                csvStr = csvStr + 'Energy,';
                csvStr = csvStr + 'Tariff,';
                csvStr = csvStr + 'Cost,';
                csvStr = csvStr + 'Currency,';
                csvStr = csvStr + 'Payment Method,';
    
    
                csvStr = csvStr.substring(0,csvStr.length - 1);
                csvStr = csvStr + "\n";
        
                // Add the data
                this.state.data.map(row => { 


                    csvStr = csvStr + row.ChargeSessionId + ',';
                    csvStr = csvStr + row.ChargePoint + ',';
                    csvStr = csvStr + this.SQLtoUTCDate(row.StartTime) + ',';
                    csvStr = csvStr + this.SQLtoUTCDate(row.EndTime) + ',';
                    csvStr = csvStr + `${row.FirstName} ${row.LastName}` + ',';
                    csvStr = csvStr + row.Email + ',';
                    csvStr = csvStr + row.VRM + ',';
                    csvStr = csvStr + row.Energy + ',';
                    csvStr = csvStr + row.Tariff + ',';
                    csvStr = csvStr + Helpers.localeCurrency(row.Cost, row.Currency) + ',';
                    csvStr = csvStr + row.Currency + ',';                               
                    csvStr = csvStr + row.PaymentMethod + ',';
    
                    csvStr = csvStr.substring(0,csvStr.length - 1);
                    csvStr = csvStr + "\n";
                    
                })
        
                csvStr = csvStr.substring(0, csvStr.length - 1);
    
                const filename = 'charge_sessions_' + this.state.fields.date;
    
                var blob = new Blob([csvStr], { type: 'text/csv' });
        
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                    console.log('using window navigator');
                    window.navigator.msSaveOrOpenBlob(blob, filename+'.csv');
                    this.setState({downloading: false});
                } else { // for Non-IE (chrome, firefox etc.)
                    console.log('not using window navigator');
                    let a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = 'data:text/csv;charset=utf-8,'+encodeURIComponent("\uFEFF"+csvStr);
                    a.download = filename+'.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    this.setState({downloading: false});
                }

            }
        }
    }

    SQLtoUTCDate = (str, datetime = true) => {
        const d = str.split(/-|T|:|\.|Z/);
        const jD = new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]));
        if(datetime)
            return jD.toLocaleDateString('en-GB') + ' ' + jD.toLocaleTimeString('en-GB');
        
        return jD.toLocaleDateString('en-GB');
    }

    


    handleQueryChange = () => {

        let query = {};
        if(this.state.search != '')
            query.search = this.state.search;
        
        if(this.state.table.page != 0)
            query.page = this.state.table.page;
        
        this.props.history.replace('?'+Helpers.makeQueryString(query));

    }

    clearSearch = () => {
        this.setState({
            search: '',
            filteredData: this.state.data
        }, this.handleQueryChange)
    }

    search = str => {

        this.setState({search: str}, () => {
        
            this.handleQueryChange();

            // Search can be called on load if there is a query string with the search string in
            let data = this.state.data;
            const searchStr = this.state.search.toUpperCase();

            if(this.state.search.length > 0 && this.state.search != '' && data.length > 0){

                const cols = Object.keys(data[0]);
                console.log(cols);
                data = data.filter(r => {

                    for(let i=0;i<cols.length;i++){
                        if(this.isNull(r[cols[i]]).toUpperCase().indexOf(searchStr) > -1)
                            return true
                    }

                    return false;
                });

            }

            // Reset the tab to 0 to ensure it is a global search and not per tab
            this.setState({
                filteredData: data,
                table: {
                    ...this.state.table,
                    page: 0
                },
            }, this.handleQueryChange);
        
        });

    }

    isNull = (str) => {
        if(typeof str == 'undefined' || str == null)
            return ''
        
        return str.toString();
    }

    

    handleChangePage = (event, newPage) => {
        this.setState({
            table: {
                ...this.state.table,
                page: newPage
            }
        }, this.handleQueryChange);
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            table: {
                ...this.state.table,
                page: 0,
                rowsPerPage: parseInt(event.target.value, 10)
            }
        }, () => {
            this.handleQueryChange()
        });
    };

    render(){

        const page = this.state.table.page;
        const rowsPerPage = this.state.table.rowsPerPage;

        return(
            <div style={styles.container}>
                <div style={styles.inner}>

                    <Quickcrumbs 
                        crumbs={[
                            {
                                name: 'Service Users',
                                route: '/service_users'
                            },
                            {
                                name: 'Charge Sessions',
                                route: '/service_users/charge_sessions'
                            }
                        ]}
                        history={this.props.history}
                    />

                    <h2>Charge Sessions</h2>

                    <div style={styles.taskbar}>

                        <div>

                            <TextField name="date" label="Select Date" type="date" value={this.state.fields.date} onChange={this.handleChange} style={styles.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <Button variant="contained" color={this.dirty()?'secondary':'default'} onClick={this.loadData}>Go{this.state.loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>

                        </div>
                        <div style={{display: 'flex', flex: 1}}>
                            <SearchBar onSearch={this.search} onClear={this.clearSearch} label="Filter Sessions" />
                        </div>
                        <div>
                            <Button variant="contained" color="secondary" onClick={this.downloadCSV}>Download to .CSV{this.state.downloading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
                        </div>
                    </div>

                    <Paper style={styles.root}>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={this.state.filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        <Divider />
                        <Table style={styles.table}>
                            <TableHead>
                            <TableRow>
                                <TableCell>Charge Session ID</TableCell>
                                <TableCell>Charge Point</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>VRM</TableCell>
                                <TableCell>Energy</TableCell>
                                <TableCell>Tariff</TableCell>
                                <TableCell>Cost</TableCell>
                                <TableCell>Currency</TableCell>
                                <TableCell>Payment Method</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{row.ChargeSessionId}</TableCell>
                                    <TableCell>{row.ChargePoint}</TableCell>
                                    <TableCell>{this.SQLtoUTCDate(row.StartTime)}</TableCell>
                                    <TableCell>{this.SQLtoUTCDate(row.EndTime)}</TableCell>
                                    <TableCell>{row.FirstName} {row.LastName}</TableCell>
                                    <TableCell>{row.Email}</TableCell>
                                    <TableCell>{row.VRM}</TableCell>
                                    <TableCell>{row.Energy}</TableCell>
                                    <TableCell>{row.Tariff}</TableCell>
                                    <TableCell>{Helpers.localeCurrency(row.Cost, row.Currency)}</TableCell>
                                    <TableCell>{row.Currency}</TableCell>                               
                                    <TableCell>{row.PaymentMethod}</TableCell>                               
                                    <TableCell align="right">{(+(row.Cost) > 0) && <ResendSessionReceipt id_session={row.ChargeSessionId} />}</TableCell>                               
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={this.state.filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>

                    {this.state.data.length == 0 && <div style={styles.nodata}>No Data</div>}

                    <Dialog
                        open={this.state.showDialog}
                        onClose={this.closeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Reload the data"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You changed the values in the taskbar but didn't reload the data, reload before you download.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.closeDialog} color="secondary">
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                    

                </div>
            </div>
        );
    }

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default ChargeSessions