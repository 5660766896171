import React, {useContext, useRef, useState, useEffect} from 'react';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/styles';
import Helpers from '../global/helpers';
import { GoogleMap, Marker, MarkerClusterer, InfoBox } from '@react-google-maps/api';
import CustomStyle from './mapStyle';
import Pins from '../map/pins';
import EventIcon from '@material-ui/icons/Event';

import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Grid,
    TextField,
    Divider,
    FormControlLabel,
    Switch,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';
import GlobalContext from '../../context/global-context';
import TicketVisitReport from './ticket_visit_report';

import CloseIcon from '@material-ui/icons/Close';



const DEFAULT_CAMERA = {
    lat: 51.5007,
    lng: 0.1246,
}

const TicketVisit = props => {

    const theme = useTheme();
    const styles = useStyles(theme);
    const context = useContext(GlobalContext)

    const map = useRef(null);

    const [ addReport, setAddReport ] = useState(false)
    
    
    const getSiteName = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return s.SiteName;

        return '';
    }

    const getSiteAddress = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        if(typeof s != 'undefined')
            return (
                <React.Fragment>
                    {s.AddressLine1.length > 0 && <Typography variant="body2">{s.AddressLine1}</Typography>}
                    {s.AddressLine2.length > 0 && <Typography variant="body2">{s.AddressLine2}</Typography>}
                    {s.AddressLine3.length > 0 && <Typography variant="body2">{s.AddressLine3}</Typography>}
                    {s.Town.length > 0 && <Typography variant="body2">{s.Town}</Typography>}
                    {s.County.length > 0 && <Typography variant="body2">{s.County}</Typography>}
                    {s.PostCode.length > 0 && <Typography variant="body2">{s.PostCode}</Typography>}
                </React.Fragment>);

        return '';
    }
    
    const getTechnicalContact = id_site => {
        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === id_site);
        // const sd = props.ticketMeta.map_data.find(sd => sd.GroupSchemeID === s.GroupSchemeID && sd.ChargeSiteID === s.ChargeSiteID);
        // if(typeof sd != 'undefined')
        //     return (
        //         <React.Fragment>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Name: </Typography>
        //             {sd.TechnicalContacts.name != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.name}</Typography>}
        //             </div>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Email: </Typography>
        //             {sd.TechnicalContacts.email != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.email}</Typography>}
        //             </div>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Phone: </Typography>
        //             {sd.TechnicalContacts.telephone != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.telephone}</Typography>}
        //             </div>
        //             <div>
        //             <Typography variant="body2" color="textSecondary" component="span">Position: </Typography>
        //             {sd.TechnicalContacts.position != 'undefined' && <Typography variant="body2" component="span">{sd.TechnicalContacts.position}</Typography>}
        //             </div>
        //         </React.Fragment>);

        return '';
    }

    const getGeo = () => {
        let geo = {
          latitude: 0,
          longitude: 0
        }

        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === props.visit.id_site);
        if(typeof s != 'undefined')
            if(typeof s.Geo != 'undefined' && s.Geo != null)
                geo = {
                    latitude: s.Geo.latitude,
                    longitude: s.Geo.longitude
                }
      
        return geo;
    }

    const getCamera = () => {

        let geo = {...DEFAULT_CAMERA};

        const s = props.ticketMeta.sites.find(s => s.ChargeSiteID === props.visit.id_site);
        if(typeof s != 'undefined')
            if(typeof s.Geo != 'undefined' && s.Geo != null)
                geo = {
                    lat: s.Geo.latitude,
                    lng: s.Geo.longitude
                }
      
        return geo;
    }
  
    

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth>
            <DialogTitle id="form-dialog-title">
                <div className={styles.row}>
                    <div style={{flex: 1}}>Visit Details</div>
                    <div className={styles.row}><EventIcon style={{marginRight: 10}} />
                        <Typography variant="h6">{Helpers.SQLtoUTCDate(props.visit.date_booked, false)}</Typography>
                    </div>
                    {/* <IconButton onClick={props.handleClose}><CloseIcon /></IconButton> */}
                </div>
            </DialogTitle>
            <DialogContent>

                <div style={{padding: theme.spacing(2)}}>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <Typography variant="body2" color="textSecondary">Description:</Typography>
                            <Typography variant="subtitle1" gutterBottom={false}>{props.visit.ticket_visit_description}</Typography>
                        </Grid>
                        <Grid item sm={6}>
                    
                            <div className={styles.pos}>
                                <Typography variant="body2" color="textSecondary">Where:</Typography>
                                <Typography variant="body2">{getSiteName(props.visit.id_site)}</Typography>
                                {getSiteAddress(props.visit.id_site)}
                            </div>

                            <div className={styles.pos}>
                                <Typography variant="body2" color="textSecondary">Site technical contact:</Typography>
                                {getTechnicalContact(props.visit.id_site)}
                            </div>
                        </Grid>
                        <Grid item sm={6}>
                            
                            {/* MARKER ON A MAP */}
                            <div style={{width: '100%', height: 300}}>
                                <GoogleMap
                                    id='visit-map'
                                    ref={map}
                                    mapContainerStyle={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'relative'
                                    }}
                                    zoom={5}
                                    center={getCamera()}
                                    options={{
                                        styles: CustomStyle,
                                        disableDefaultUI: true,
                                        maxZoom: 20
                                    }}
                                    google={props.google}
                                >
                                        <Marker 
                                            icon={{url: Pins.black, anchor: new window.google.maps.Point(10,10), scaledSize: new window.google.maps.Size(20,20)}} 
                                            position={{lat: getGeo().latitude, lng: getGeo().longitude}} 
                                            zIndex={10} 
                                        />
                                </GoogleMap>
                            </div>
                        </Grid>
                    </Grid>

                    {props.visit.date_attended_arrived !== null &&
                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                <Typography variant="subtitle1">Visit Report</Typography>
                            </Grid>

                            <Grid item sm={6}>
                                
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="span">Attended by: </Typography>
                                    <Typography variant="body2" component="span">{props.visit.attending_name}</Typography>
                                </div>
                                
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="span">Arrived: </Typography>
                                    <Typography variant="body2" component="span">{Helpers.SQLtoUTCDate(props.visit.date_attended_arrived)}</Typography>
                                </div>
                                
                                <div className={styles.pos}>
                                    <Typography variant="body2" color="textSecondary" component="span">Departed: </Typography>
                                    <Typography variant="body2" component="span">{Helpers.SQLtoUTCDate(props.visit.date_attended_departed)}</Typography>
                                </div>

                                <div>
                                    <Typography variant="body2" color="textSecondary">Parts used: </Typography>
                                    {props.visit.parts.length > 0 && 
                                        <List dense>
                                            {props.visit.parts.map((p, idx) => (
                                                <ListItem key={idx}>
                                                    <ListItemText primary={p.ticket_visit_part_description} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    }
                                </div>

                            </Grid>

                            <Grid item sm={6}>
                                
                                <div>
                                    <Typography variant="body2" color="textSecondary">Report: </Typography>
                                    <Typography variant="body2">{props.visit.ticket_visit_report}</Typography>
                                </div>

                            </Grid>
                        </Grid>
                    }


                    {addReport && <TicketVisitReport open={true} handleClose={() => setAddReport(false)} ticket={props.ticket} ticketMeta={props.ticketMeta} visit={props.visit} setTicket={props.setTicket} handleUpdateTicket={props.handleUpdateTicket} /> }


                </div>

            </DialogContent>
            <DialogActions>

                {props.visit.ticket_visit_reported === null &&
                    <Button onClick={() => setAddReport(true)} variant="contained" color="secondary">Add Report</Button>
                }

                <div style={{flex: 1}}></div>

                <Button onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )

}

export default TicketVisit