import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core/';
import Helpers from '../global/helpers';
import {TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody} from '@material-ui/core';

const OrdersSnippet = props => {

    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();
    
    const [order, setOrder] = React.useState(props.direction);
    const [orderBy, setOrderBy] = React.useState(props.comparator);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const descendingComparator = (a, b, orderBy) => {
        let x = a[orderBy]!=null?a[orderBy]:'';
        let y = b[orderBy]!=null?b[orderBy]:'';

        if (y < x) return -1;
        if (y > x) return 1;

        return 0;
    }


    const getComparator = (order, orderBy) => {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const sort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    const useStyles = makeStyles({
        listItemAttention: {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.error.dark,
            }
        },
        listItemInfo: {
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.warning.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.warning.dark,
            }
        }
    });
    const classes = useStyles();

    const styles  = {
        wrapper: {
            width: '96%',
            marginLeft: '2%',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 280
        },
        subHeader: {
            backgroundColor: '#fff',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
            top: 0,
            zIndex: 1,
            position: 'sticky',
            padding: '5px 16px'
        }
    }

    const getBg = (dateStr) => {
        const comp = Helpers.SQLtoUTCDateObj(dateStr);
        const now = new Date();
        const diff = Math.round((now-comp)/(1000*60*60*24));

        if(diff > 6)
            return classes.listItemAttention

        if(diff > 2)
            return classes.listItemInfo

        return '';
    }

    const comparitorVal = val => {
        if(typeof props.comparatorType == 'undefined')
            return val;
        else 
            switch(props.comparatorType.toUpperCase()){
                case 'DATE':
                    return Helpers.SQLtoUTCDate(val, false)
                case 'CUR':
                    return '£' + val.toLocaleString('en-gb');
                default:
                    return val;
            }
    }

        
    return (
        // <List style={styles.wrapper}
        // subheader={
        //   <div style={styles.subHeader}>
        //     <Typography variant="subtitle2">{props.header}</Typography>
        //     <Typography variant="subtitle2">{props.comparatorHeader}</Typography>
        //   </div>
        // }>
        //     {sort(props.orders, getComparator(props.direction, props.comparator)).map(p => (
        //         <ListItem key={p.id_order} className={props.highlight && getBg(p[props.comparator])} button divider onClick={() => {
        //             props.history.push(`/order/${p.id_order}`);
        //         }}>
        //             <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        //                 <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`VE${p.id_order} - ${p.order_name}`}</div>
        //                 <div>{Helpers.SQLtoUTCDate(p[props.comparator], false)}</div>
        //             </div>
        //         </ListItem>
        //     ))}

        //     {props.orders.length == 0 && <ListItem><Typography>None</Typography></ListItem>}
        // </List>

        <div style={styles.wrapper}>
        <TableContainer>
            <Table size={'small'}>
            <TableHead>
                <TableRow>
                        <TableCell sortDirection={orderBy === 'id_order' ? order : false}>
                            <TableSortLabel active={orderBy === 'id_order'} direction={orderBy === 'id_order' ? order : 'asc'} onClick={(e) => handleRequestSort(e, 'id_order')}>
                            {props.header}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell sortDirection={orderBy === props.comparator ? order : false} align="right">
                            <TableSortLabel active={orderBy === props.comparator} direction={orderBy === props.comparator ? order : 'asc'} onClick={(e) => handleRequestSort(e, props.comparator)}>
                            {props.comparatorHeader}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                            <TableSortLabel active={orderBy === 'sockets'} direction={orderBy === 'sockets' ? order : 'asc'} onClick={(e) => handleRequestSort(e, 'sockets')}>
                            Sckts
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                            <TableSortLabel active={orderBy === 'total'} direction={orderBy === 'total' ? order : 'asc'} onClick={(e) => handleRequestSort(e, 'total')}>
                            Val
                            </TableSortLabel>
                        </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {sort(props.orders, getComparator(order, orderBy)).map(row => {
                    return (
                        <TableRow
                            hover
                            onClick={(event) => props.history.push(`/order/${row.id_order}`)}
                            key={row.id_order}
                        >
                            <TableCell>{`VE${row.id_order}`}</TableCell>
                            <TableCell>{row.order_name}</TableCell>
                            <TableCell align="right">{comparitorVal(row[props.comparator])}</TableCell>
                            <TableCell align="right">{row.sockets}</TableCell>
                            <TableCell align="right">£{row.total.toLocaleString('en-GB')}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
            </Table>
        </TableContainer>
        </div>
    );
}

export default OrdersSnippet