import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';

import { 
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    DialogContentText,
    InputLabel,
    Typography
} from '@material-ui/core';


const AddInvoiceNo = props => {

    // Create a local state
    const [params, setParams] = React.useState({
        invoice_no: null
    });

    // Use the global context
    const context = useContext(GlobalContext);

    const handleChange = e => {
        let newParams = {...params};
        newParams[e.target.name] = e.target.value;
        setParams(newParams);
    }

    const handleUpdateInvoiceNo = () => {
        const newParams = {
            selected: props.selected,
            ...params
        }
        context.updateSubscriptionsAs(newParams, 'invoice_no').then(
            res => {
                context.showAlert('success', 'Invoice info updated');

                if(typeof props.success == 'function')
                    props.success()
            },
            err => {
                context.showAlert('error', 'Error updating subscriptions ' + JSON.stringify(err))
            }
        )
    }

    const handleActionCancel = () => {
        setParams({
            invoice_no: null
        });

        if(typeof props.close == 'function')
            props.close()
    }

        
    return (

        <Dialog open={props.show} onClose={props.close} aria-labelledby="invoice-no-dialog-title">
            <DialogContent>
                <TextField
                    margin="dense"
                    name="invoice_no"
                    value={params.invoice_no}
                    onChange={handleChange}
                    label="Invoice number"
                    type="text"
                    fullWidth
                    InputLabelProps={{shrink: true}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleActionCancel} color="default">
                    Cancel
                </Button>
                <Button onClick={handleUpdateInvoiceNo} color="secondary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export default AddInvoiceNo