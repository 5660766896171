import React, {useState, useContext, useRef, useEffect} from 'react';
import {Typography, List, ListItem, ListItemText, ListItemIcon, Button, CircularProgress, useTheme, makeStyles, Tooltip, IconButton, ButtonGroup, Link} from '@material-ui/core';
import GlobalContext from '../../context/global-context';
import Helpers from '../global/helpers';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import MovieIcon from '@material-ui/icons/Movie';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import TicketConfirmDialog from './ticket_confirm_dialog';



const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    initials: {
        marginRight: theme.spacing(1)
    },
    delete: {
        width: 16, 
        height: 16, 
        marginLeft: 15,
        color: theme.palette.text.disabled,
        "&:hover" : {
            color: theme.palette.text.default
        }
    },
    dropArea: {
        padding: 4, 
        backgroundColor: theme.palette.background.default, 
        border: `3px solid ${theme.palette.background.default}`,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    dropAreaHighlight: {
        padding: 4, 
        backgroundColor: theme.palette.action.hover, 
        border: `3px solid ${theme.palette.background.default}`,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    }
}));



const TicketAttachments = props => {

    const context = useContext(GlobalContext);
    const theme = useTheme();
    const styles = useStyles(theme);

    const [ dropHighlight, setDropHighlight ] = useState(false)
    const [ fileUpload, setFileUpload ] = useState(null);
    const [ uploading, setUploading ] = useState(false);
    
    const [ confirmDelete, setConfirmDelete ] = useState(null);
    const [ deleting, setDeleteing ] = useState(false);
    
    const [ downloading, setDownloading ] = useState(false);

    const dropArea = useRef(null);

    useEffect(() => {

        const preventDefaults = (e) => {
            e.preventDefault()
            e.stopPropagation()
        }

        dropArea.current.addEventListener('dragenter', e => {
            preventDefaults(e);
            setDropHighlight(true);
        }, false)

        dropArea.current.addEventListener('dragover', e => {
            preventDefaults(e);
            setDropHighlight(true);
        }, false)

        dropArea.current.addEventListener('dragleave', e => {
            preventDefaults(e);
            setDropHighlight(false);
        }, false)

        dropArea.current.addEventListener('drop', e => {
            preventDefaults(e);
            setDropHighlight(false);
            setFileUpload(e.dataTransfer.files[0]);
        }, false)

    }, [])


    const handleFileChange = e => {
        setFileUpload(e.target.files[0]);
    }

    const handleUpload = () => {
        setUploading(true);

        context.apiFileUpload(`ticket/attach/${props.ticket.id_ticket}`, {attachment: fileUpload}).then(
            res => {
                setUploading(false);
                setFileUpload(null);
                console.log(res);
            },
            err => {
                setUploading(false);
                context.showAlert('error', `Error uploading attachment: ${err.msg}`)
                console.log(err);
            }
        )
    }

    const harmfulUpload = () => {
        if(fileUpload !== null && fileUpload.name.substring(fileUpload.name.indexOf('.'), fileUpload.name.length) === '.msg')
            return true;
        
        return false;
    }

    const handleDelete = () => {
        setDeleteing(true);

        context.apiRequest(`ticket/attach/${props.ticket.id_ticket}/${confirmDelete.id_ticket_attachment}`, 'DELETE').then(
            res => {
                setDeleteing(false);
                setConfirmDelete(null);
                console.log(res);
            },
            err => {
                setDeleteing(false);
                setConfirmDelete(null);
                context.showAlert('error', `Error deleting attachment: ${err}`)
                console.log(err);
            }
        )
    }

    const handleDownload = (e, a) => {
        
        e.preventDefault()
        e.stopPropagation()

        setDownloading(a.id_ticket_attachment);

        context.apiFileDownload(`ticket/attach/${props.ticket.id_ticket}/${a.id_ticket_attachment}`, a.ticket_attachment_name).then(
            res => {
                setDownloading(null);
                console.log(res);
            },
            err => {
                setDownloading(null);
                context.showAlert('error', `Error downloading attachment: ${err}`)
                console.log(err);
            }
        )
    }


    const fileTypeIcon = mimeType => {
        switch(mimeType.toUpperCase()){
            case 'IMAGE/JPEG':
            case 'IMAGE/PNG':
            case 'IMAGE/GIF':
                return <InsertPhotoIcon />
            case 'AUDIO/MPEG':
                return <AudiotrackIcon />
            case 'VIDEO/MPEG':
                return <MovieIcon />
            default:
                return <InsertDriveFileIcon />
        }
    }



    return (
        <div>
            <Typography variant="subtitle2">Attachments</Typography>
            <List dense={true}>
            {props.ticket.attachments.map((a, idx) => (
                <ListItem key={idx}>
                    <ListItemIcon>
                        {downloading !== a.id_ticket_attachment && <IconButton onClick={(e) => handleDownload(e, a)}>{fileTypeIcon(a.ticket_attachment_filetype)}</IconButton>}
                        {downloading === a.id_ticket_attachment && <CircularProgress size={24} />}
                    </ListItemIcon>
                    <ListItemText
                        primary={<Link href="#" color="secondary" onClick={(e) => handleDownload(e, a)}>{a.ticket_attachment_name} ({a.ticket_attachment_size})</Link>}
                        secondary={
                        <React.Fragment>
                            <div className={styles.row}>
                                <Tooltip title={a.name} aria-label={a.name}>
                                    <Typography color="textSecondary" variant="caption" className={styles.initials}>{Helpers.getInitials(a.name)}</Typography>
                                </Tooltip>
                                <Typography color="textSecondary" variant="caption"> - {Helpers.SQLtoUTCDate(a.created, true)}</Typography>
                                <IconButton className={styles.delete} onClick={() => setConfirmDelete(a)}><CloseIcon fontSize="small" className={styles.deleteIcon} /></IconButton>
                            </div>
                        </React.Fragment>
                        }
                    />
                </ListItem>
            ))}
            </List>
            
            <div ref={dropArea} className={dropHighlight ? styles.dropAreaHighlight : styles.dropArea}>
                {fileUpload === null && 
                    <React.Fragment>
                        {!dropHighlight && <Button
                            component="label"
                            disabled={uploading}
                            >
                            <VerticalAlignBottomIcon size="small" /> Choose a file or drag it here
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                            {uploading && <CircularProgress size={24} />}
                        </Button>}
                        {dropHighlight && 
                            <div style={{paddingTop: 6, paddingBottom: 6, paddingLeft: 8, paddingRight: 8}}>
                                <Typography variant="button" className={styles.row}><VerticalAlignBottomIcon size="small" /> DROP</Typography>
                            </div>
                        }
                    </React.Fragment>
                }
            
                {fileUpload !== null && 
                <div style={{width: '100%'}}>
                    <Typography variant="subtitle2">Confirm Upload</Typography>
                    <div className={styles.row} style={{width: '100%'}}>
                        <Typography variant="caption" style={{flex: 1}}>{fileUpload.name}</Typography>
                        {!uploading && 
                        <ButtonGroup disableElevation size="small" style={{marginLeft: 10}}>
                            <Button onClick={() => setFileUpload(null)}><CloseIcon fontSize="small" /></Button>
                            <Button color="secondary" variant="contained" onClick={handleUpload}><CheckIcon fontSize="small" /></Button>
                        </ButtonGroup>}
                        {uploading && <CircularProgress size={24} />}
                    </div>
                    {harmfulUpload() && <Typography color="error" variant="caption">Be careful, emails can contain malicious attachments or links.</Typography>}
                </div>}
            </div>


            {confirmDelete !== null && <TicketConfirmDialog open={true} title="Delete attachment" body={`Are you sure you wish to delete ${confirmDelete.ticket_attachment_name} from this ticket?`} handleClose={() => setConfirmDelete(null)} handleAccept={handleDelete} loading={deleting} />}
        </div>

    )
}

export default TicketAttachments