import { makeStyles } from '@material-ui/core/styles';
import { yellow, red, orange, blue, green, purple } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden'
    },
    wrapper: {
        display: 'flex',
        padding: theme.spacing(2),
        flexDirection: 'column',
        flex: 1,
        height: 'auto',
        overflow: 'auto'
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 'auto',
        overflow: 'auto'
    },
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    tickets: {
        flex: 1,
        minWidth: 300,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
    },
    ticketsInner: {
        padding: theme.spacing(2)
    },
    inputField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 180
    },
    ticketPane: {
        flex: 4,
        position: 'relative',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    ticket: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'row'
    },
    ticketContent: {
        flex: 3,
        padding: theme.spacing(2),
    },
    ticketContentInner: {
        padding: theme.spacing(2)
    },
    ticketMeta: {
        padding: theme.spacing(2),
        flex: 1
    },
    inputField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 180
    },
    prioritynull: {
        marginRight: theme.spacing(2)
    },
    priority1: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        marginRight: theme.spacing(2)
    },
    priority2: {
        color: theme.palette.getContrastText(orange[500]),
        backgroundColor: orange[500],
        marginRight: theme.spacing(2)
    },
    priority3: {
        color: theme.palette.getContrastText(yellow[600]),
        backgroundColor: yellow[600],
        marginRight: theme.spacing(2)
    },
    LED: {
        border: `1px solid ${theme.palette.grey[500]}`
    },
    LEDBLUE: {
        backgroundColor: blue[400],
        border: `1px solid ${theme.palette.grey[500]}`
    },
    LEDGREEN: {
        backgroundColor: green[400],
        border: `1px solid ${theme.palette.grey[500]}`
    },
    LEDRED: {
        backgroundColor: red[400],
        border: `1px solid ${theme.palette.grey[500]}`
    },
    LEDOCPP: {
        backgroundColor: purple[300],
        border: `1px solid ${theme.palette.grey[500]}`
    },
    LEDBLUEb: {
        animation: `$blinkBLUE 1s infinite`,
        border: `1px solid ${theme.palette.grey[500]}`
    },
    LEDGREENb: {
        animation: `$blinkGREEN 1s infinite`,
        border: `1px solid ${theme.palette.grey[500]}`
    },
    LEDREDb: {
        animation: `$blinkRED 1s infinite`,
        border: `1px solid ${theme.palette.grey[500]}`
    },
    colorBlue: {
        color: blue[400]
    },
    colorGreen: {
        color: green[400]
    },
    colorOrange: {
        color: orange[400]
    },
    colorRed: {
        color: red[400]
    },
    colorBlinkBlue: {
        animation: `$blinkColorBLUE 1s infinite`,
    },
    colorBlinkGreen: {
        animation: `$blinkColorGREEN 1s infinite`,
    },
    colorBlinkOrange: {
        animation: `$blinkColorORANGE 1s infinite`,
    },
    colorBlinkRed: {
        animation: `$blinkColorRED 1s infinite`,
    },
    "@keyframes blinkBLUE": {
        "0%, 49%": {
            backgroundColor: blue[400]
        },
        "50%, 100%": {
            backgroundColor: 'transparent'
        }
    },
    "@keyframes blinkColorBLUE": {
        "0%, 49%": {
            color: blue[400]
        },
        "50%, 100%": {
            color: 'transparent'
        }
    },
    "@keyframes blinkGREEN": {
        "0%, 49%": {
            backgroundColor: green[400]
        },
        "50%, 100%": {
            backgroundColor: 'transparent'
        }
    },
    "@keyframes blinkColorGREEN": {
        "0%, 49%": {
            color: green[400]
        },
        "50%, 100%": {
            color: 'transparent'
        }
    },
    "@keyframes blinkColorORANGE": {
        "0%, 49%": {
            color: orange[400]
        },
        "50%, 100%": {
            color: 'transparent'
        }
    },
    "@keyframes blinkRED": {
        "0%, 49%": {
            backgroundColor: red[400]
        },
        "50%, 100%": {
            backgroundColor: 'transparent'
        }
    },
    "@keyframes blinkColorRED": {
        "0%, 49%": {
            color: red[400]
        },
        "50%, 100%": {
            color: 'transparent'
        }
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    title: {
        fontSize: 12,
        marginRight: 10
    },
    pos: {
        marginBottom: 12
    },
    posPre: {
        marginBottom: 12,
        whiteSpace: 'pre-line'
    },
    button:{
        margin: 5
    }

}));