import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const TicketStatuses = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Ticket Statuses',
                            route: '/settings/ticket_statuses'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Ticket Statuses</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>Used to categorise a ticket into it's current state</Typography>

                <Crud
                    uri='ticket_status'
                    idCol='id_ticket_status'
                    orderCol='order'
                    displayColumns={[
                        { 
                            name: 'ticket_status_name', 
                            type: 'string',
                            editable: true,
                            required: true
                        }, 
                        { 
                            name: 'management_status', 
                            type: 'boolean',
                            editable: true
                        }, 
                        { 
                            name: 'locked', 
                            type: 'boolean',
                            editable: true
                        }, 
                        { 
                            name: 'disabled', 
                            type: 'boolean',
                            editable: true
                        }
                    ]}
                />

            </div>

        </div>
    )

}

export default TicketStatuses
