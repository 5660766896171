import React, {useContext, useState} from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import ProgressButton from '../../../../components/progressButton';
import { Alert } from '@material-ui/lab';
import globalContext from '../../../../context/global-context';


const EVConnectAppUserPasswordReset = props => {

    const context = useContext(globalContext);

    const {user} = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const sendPasswordReset = async search => {
        setLoading(true);
        setError(null);

        try {
            
            const res = await context.apiRequest(`/service_consumer/ev_connect/app_user/password_reset?email=${user.email}`, 'GET');
            if(res.success)
                setSuccess(true);
            else
                throw res.msg;
            
        } catch (err) {
            setError(typeof err === 'string' ? err : typeof err.msg !== 'undefined' ? err.msg : JSON.stringify(err));
        } finally {
            setLoading(false);
        }
        
    }

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    return (
        <React.Fragment>
            <Dialog open={true} onClose={handleClose}>
                <DialogTitle>Send Password Reset</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">Send password reset to {user.email}</Typography>
                    {error && <Alert severity='error'>{error}</Alert>}
                    {success && <Alert severity='success'>Reset password sent</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <ProgressButton waiting={loading} onClick={sendPasswordReset} variant="contained" color="secondary">Send</ProgressButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default EVConnectAppUserPasswordReset;