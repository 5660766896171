import React from 'react';
import GlobalContext from '../../context/global-context';
import Pins from './pins';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SimCardIcon from '@material-ui/icons/SimCard';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { IconButton } from '@material-ui/core';

const LocationSummary = props => {

    const navigate = (context) => {
       window.open('https://www.google.com/maps/dir/?api=1&parameters&origin='+props.location.lat+','+props.location.lng+'&destination='+props.site.Geo.latitude+','+props.site.Geo.longitude)
    }

    const _isStrNull = (str, app = '') => {
        return typeof str != 'undefined' && str != null && str.length > 0 && str != 'null' ? false : true;
    }

    const address = () => {    
        return (
            <span style={styles.locationaddress}>
                {!_isStrNull(props.site.AddressLine1) && <span>{props.site.AddressLine1}<br /></span>}
                {!_isStrNull(props.site.AddressLine2) && <span>{props.site.AddressLine2}<br /></span>}
                {!_isStrNull(props.site.AddressLine3) && <span>{props.site.AddressLine3}<br /></span>}
                {!_isStrNull(props.site.Town) && <span>{props.site.Town}<br /></span>}
                {!_isStrNull(props.site.County) && <span>{props.site.County}<br /></span>}
                {!_isStrNull(props.site.Country) && <span>{props.site.Country}<br /></span>}
                {!_isStrNull(props.site.PostCode) && <span>{props.site.PostCode}<br /></span>}
            </span>
        )
      }


    const lastHeartBeat = (point) => {

        if(point.LastHeartBeat == null)
            return '';

        const d = point.LastHeartBeat.split(/-|T|:|Z|\./);
        const heart = new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]));

        let days = 0, hrs = 0, mins = 0, secs= 0;
        var diff = (new Date() - heart);

        // First get the total seconds
        secs = Math.abs(diff)/1000;

        // then get the total days in those seconds
        days = Math.floor(secs/86400);

        // then get the total hours in those seconds
        hrs = Math.floor(secs/3600);

        // then get the total mins left in the seconds
        mins = Math.floor(secs/60);

        if(days > 0) return days + ' day' + (days > 1 ? 's' : '')
        if(hrs > 0) return hrs.toFixed(0) + ' hr' + (hrs > 1 ? 's' : '')
        if(mins > 0) return mins.toFixed(0) + ' min' + (mins > 1 ? 's' : '')
        return secs.toFixed(0) + ' sec' + (secs > 1 ? 's' : '')
    }

    const getMarker = (p) => {
        let charging = false;
        let version = false;
        let fault = false;
        let withdrawn = false;

        if(p.DeploymentStatus == 'WITHDRAWN')
            withdrawn = true;

        if(p.Status == 'CHARGING')
            charging = true;
        
        if(p.Status == 'FAULT_CONDITION' || p.LastHeartBeat == null){
            fault = true;
        } else {
            const d = p.LastHeartBeat.split(/-|T|:|Z|\./);
            const heart = new Date(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]);
            let mins = 0;
            var diff = (new Date() - heart);
            // The total mins since last heartbeat
            mins = Math.floor((Math.abs(diff)/1000)/60);

            if(mins > 120)
                fault = true;
        }

        // if(p.Version == null || parseFloat(p.Version.slice(1)) < 2.05)
        //     version = true;

        if(withdrawn)
            return Pins.black;

        if(fault)
          return Pins.red;

        if(version)
          return Pins.black;

        if(charging)
          return Pins.green;

        return Pins.blue;
    }

    return (

        <GlobalContext.Consumer>
        {context => (
            <div style={styles.container}>
                <div style={styles.summary}>
                    <div style={styles.left}>
                        <div style={styles.location}>
                            <span style={styles.locationtitle}><IconButton  onClick={() => props.handleTogglePin(props.site)}><LocationOnIcon style={{color: props.pinned?'#b415d1':'inherit'}} /></IconButton> {props.site.GroupSchemeName}</span>
                            {address()}
                        </div>
                        <a href="JavaScript:void(0);" onClick={() => {navigate(context)}} style={styles.navigate}>
                            <span style={styles.navigateLabel}>Navigate</span>
                        </a>
                    </div>
                    <div style={styles.right}>
                        <div style={styles.socketinfo}>
                            <span style={styles.locationtitle}>{props.site.Points.length} Points</span>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {props.site.Points.map((point) => (
                                <div key={point.CPID} style={styles.info}>
                                    <span style={styles.infotitle}><img src={getMarker(point)} style={{marginRight: 5}} /><b>{point.CPID}</b></span>
                                    <span style={styles.infotitle}><SimCardIcon style={styles.icon} color="disabled" />{point.Version}</span>
                                    <span style={styles.infotitle}><FavoriteIcon style={styles.icon} color="disabled" />{lastHeartBeat(point)}</span>
                                </div>
                            ))}

                            {props.site.Points.length == 0 && <div style={styles.info}><span style={styles.infotitle}>No Points</span></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </GlobalContext.Consumer>
    );
}

const styles = {
    container: {
      padding: 20
    },
    summary: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row'
    },
    left: {
        flex: 1,
        maxWidth: 400,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    right: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    distance: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
    distanceValue: {
        color: '#00adef',
        fontSize: 30,
        fontWeight: 'bold'
    },
    distanceLabel: {
        color: '#a2a2a2',
        fontSize: 12
    },
    navigate: {
        cursor: 'pointer',
        textDecoration: 'none',
    },
    navigateLabel: {
        color: '#111111',
        textDecoration: 'none',
        fontSize: 14,
        fontWeight: 600
    },
    location: {
        marginBottom: 15,
        paddingRight: 15
    },
    locationtitle: {
        color: '#484848',
        fontSize: 20,
        fontWeight: 900,
        display: 'block'
    },
    locationaddress: {
        color: '#888888',
        fontSize: 14
    },
    attributes: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        display: 'flex',
        marginBottom: 15
    },
    attribute: {
        marginRight: 15,
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    attributetitle: {
        color: '#484848',
        fontSize: 16,
        fontWeight: 'bold'
    },
    socketinfo: {
        marginBottom: 15
    },
    info: {
        marginRight: 15,
        marginTop: 10,
        padding: 6,
        backgroundColor: '#e2e2e2',
        display: 'flex',
        flexDirection: 'column',
    },
    infotitle: {
        display: 'flex',
        alignItems: 'center',
        color: '#484848',
        fontSize: 16,
        margin: '4px 0'
    },
    actions: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    action: {
        display: 'flex',
        marginRight: 15,
        flexDirection: 'row',
        alignItems: 'center'
    },
    actiontitle: {
        color: '#a2a2a2',
        fontSize: 14
    },
    actionActiveTitle: {
        color: '#00adef',
        fontSize: 14
    },
    icon: {
        fontSize: 16,
        marginRight: 5
    }
  };

export default LocationSummary;

