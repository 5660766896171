import React, {useState, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import { Typography, Paper, Grid, CircularProgress, TextField, Button, Modal, Fab, createGenerateClassName, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';


const ChangePassword = props => {

    // Create a local state
    const [password, setPassword] = useState({
        password: '',
        confirmPassword: '',
        dirty: false
    });
    const [loading, setLoading] = useState(false);


    // Use the global context
    const context = useContext(GlobalContext);
    

    // Styles

    const styles  = {
        container: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            overflow: 'auto'
        },
        inner: {
            flex: 1,
            padding: 20,
            flexDirection: 'row'
        },
        taskbar: {
            paddingTop: 20,
            paddingBottom: 20,
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    }



    // Form functions
    const handleChange = (e) => {
        let newPassword = {...password, dirty: true}
        newPassword[e.target.name] = e.target.value
        setPassword(newPassword);
    }

    const invalid = () => {
        if(isInvalid('password') || isInvalid('confirm_password'))
            return true;

        return false;
    }

    const isInvalid = prop => {
        switch(prop){
            case 'password':
                if(password.password == null || password.password.length < 6)
                    return true;
                break;
            case 'confirm_password':
                if(password.dirty && password.confirm_password != password.password)
                    return true;
                break;
            default:
                return true;
        }

        return false;
    }

    const submitForm = () => {
        setLoading(true);

        if(!invalid()){
            context.userChangePassword(password).then(
                res => {
                    setLoading(false);
                    context.showAlert('success', `Password Changed`);
                    context.userValidate();
                    props.handleClose();
                },
                err => {
                    setLoading(false);
                    context.showAlert('error', 'Error changing password ' + JSON.stringify(err));
                }
            );
        } else {
            setLoading(false);
            context.showAlert('error', 'Password invalid');
        }
    }

    return (

        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>


                <Grid container spacing={3}>

                    {props.required && <Grid item>
                        <Typography style={{marginBottom: 20}} variant="subtitle2">You are required to change your password before moving on.</Typography>
                    </Grid>}

                    <Grid container item spacing={3}>

                        
                        <Grid item xs={12}>

                            <div className="mb">
                                <TextField
                                    type="password"
                                    error={isInvalid('password')}
                                    style={{ width: 400 }}
                                    name="password"
                                    label="New Password"
                                    helperText="e.g. 'min 6 characters'"
                                    variant="filled"
                                    value={password.password}
                                    onChange={handleChange}
                                />
                            </div>

                        </Grid>

                        <Grid item xs={12}>

                            <div className="mb">
                                <TextField
                                    type="password"
                                    error={isInvalid('confirm_password')}
                                    style={{ width: 400 }}
                                    name="confirm_password"
                                    label="Confirm Password"
                                    helperText={isInvalid('confirm_password') && 'Passwords do not match'}
                                    variant="filled"
                                    value={password.confirm_password}
                                    onChange={handleChange}
                                />
                            </div>

                        </Grid>
                    </Grid>

                </Grid>

            </DialogContent>

            <DialogActions>

                <Button disabled={invalid()} variant="contained" color="secondary" onClick={submitForm}>Change{loading && <CircularProgress color="inherit" size={20} style={{marginLeft: 10}} />}</Button>

            </DialogActions>

        </Dialog>
    );
}

export default ChangePassword