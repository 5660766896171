import React, {useContext, useEffect, useState} from 'react';
import GlobalContext from '../../../context/global-context';
import ActivityIndicator from '../../global/ActivityIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Helpers from '../../global/helpers';
import API from '../../../api';
import { withTheme } from '@material-ui/styles';


const BerthVendSettled = props => {

    const context = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [marinas, setMarinas] = useState([]);
    const [expanded, setExpanded] = useState([]);


    const loadStatements = async () => {

        try {

            setLoading(true);
            const data = await API.req(`/bv/statements/settled`, 'GET', null);
            if(data.success === true){
                let newMarinas = [];
                data.result.map(marina => {
                    const midx = newMarinas.findIndex(m => m.id_organisation === marina.id_organisation);
                    if(midx === -1){
                        newMarinas.push(
                            {
                                id_organisation: marina.id_organisation,
                                organisation_name: marina.organisation_name,
                                country_code: marina.country_code,
                                statements: [marina]
                            }
                        )
                    } else {
                        newMarinas[midx].statements.push(marina);
                    }
                })
                setMarinas(newMarinas);
            }

            setLoading(false);
            
        } catch (error) {

            setLoading(false);
            
        }

    }

    useEffect(() => {

        loadStatements();

    }, [])


    const handleExpand = marina => {

        const newExpanded = JSON.parse(JSON.stringify(expanded));
        if(newExpanded.indexOf(marina.id_organisation) > -1){
            newExpanded.splice(newExpanded.indexOf(marina.id_organisation), 1);
        } else {
            newExpanded.push(marina.id_organisation);
        }

        setExpanded(newExpanded);

    }


    return (
        
        <div style={Object.assign({}, !props.show&&styles.hide)}>

        <div style={styles.taskbar}>
            <h2 style={{color: props.theme.palette.primary.main, fontWeight: 300}}>Settled</h2>
            <Button variant="contained" color="secondary" onClick={loadStatements}>Reload{loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
        </div>


        {marinas.map(marina => (
            <ExpansionPanel key={marina.id_organisation} expanded={expanded.indexOf(marina.id_organisation) > -1} onChange={() =>{handleExpand(marina)}}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography style={styles.heading}>{marina.organisation_name} - #{marina.id_organisation}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Table style={styles.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Payment Reference</TableCell>
                                <TableCell align="right">Payment Made</TableCell>
                                <TableCell align="right">Amount Paid</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {marina.statements.map(row => (
                            <TableRow key={row.payment_reference}>
                                <TableCell>{row.payment_reference}</TableCell>
                                <TableCell align="right">{Helpers.SQLtoUTCDate(row.payment_made)}</TableCell>
                                <TableCell align="right">{Helpers.localeCurrency(row.amount_paid, row.currency)}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        ))}


        {marinas.length == 0 && <div style={styles.nodata}>No Data</div>}

        </div>
    )
}


const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    hide: {
        display: 'none'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default withTheme(BerthVendSettled)