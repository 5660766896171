/*
*
*
*   This helper class just contains various none 
*   data or state modifying functions or properties
*
*/

class Helpers {
  
    //
    //  Return a Locale Date Object from a UTC SQL datetime string
    //  
    static SQLtoUTCDateObj = (str, time = true) => {
        if(str === null || str === '' || typeof str === 'undefined')
            return null;

        const d = str.split(/-|T|:| |\.|Z/);
        for(var i=0;i<d.length;i++){
            d[i] = parseInt(d[i]);
        }
        if(typeof d[3] != 'undefined' && time === true){
            return new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5]));
        }else{
            return new Date(Date.UTC(d[0], (d[1]-1), d[2], 0, 0, 0, 0));
        }
    }
  
    //
    //  Return a Locale Date String from a UTC SQL datetime string
    //  
    static SQLtoUTCDate = (str, time = true) => {
        const jD = this.SQLtoUTCDateObj(str);
        if(jD == null)
            return null;

        return time?jD.toLocaleDateString('en-GB') + ' ' + jD.toLocaleTimeString('en-GB'):jD.toLocaleDateString('en-GB');
    }
  
    //
    //  Return a Locale ISO String from a UTC SQL datetime string
    //  
    static SQLtoUTCISODateTime = (str) => {
        const jD = this.SQLtoUTCDateObj(str);
        if(jD == null)
            return '';

        return jD.toISOString()
    }
  
    //
    //  Return a Locale ISO Date String from a UTC SQL datetime string
    //  
    static SQLtoUTCISODate = (str) => {
        const jD = this.SQLtoUTCDateObj(str);
        if(jD == null)
            return '';

        return jD.toISOString().split('T')[0]
    }

    static toLocaleDateTime = (d) => {
        const jD = new Date(d);

        return jD.toLocaleDateString('en-GB') + ' ' + jD.toLocaleTimeString('en-GB');
    }


    //
    // Array of month names in short form
    //
    static monthsShort = () => {
        return [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ]
    }

    static makeQueryString = (obj) => {
      return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
    }

    static isNull = (str, replace = '') => {
        return typeof str != 'undefined' && str != null?str:replace;
    }


    static basetoObj = (val, defaultVal = {}) => {
        const decoded = atob(val);
        const obj = JSON.parse(decoded);
        if(typeof obj == 'object')
            return obj
        
        return defaultVal;        
    }

    static SQLTimeSince = d => {
        return this.timeSince(Helpers.SQLtoUTCDateObj(d));
    } 

    static timeSince = d => {
        const seconds = (new Date().getTime() - d.getTime()) / 1000;

        // 60 secs in a min
        // 3600 secs in an hour
        // 86400 secs in a day
        // 172800 secs in two days

        if(seconds > 604800)
            return d.toLocaleDateString('en-GB');
        if(seconds > 172800)
            return Math.floor(seconds/86400) + ' days ago';
        if(seconds > 86400)
            return 'Yesterday';
        if(seconds > 3600)
            return Math.floor(seconds/3600) + 'h ago';
        if(seconds > 60)
            return Math.floor(seconds/60) + 'm ago';
        return Math.floor(seconds) + 's ago';
    }

    static SQLTimeSinceSecs = d => {
        return this.timeSinceSecs(Helpers.SQLtoUTCDateObj(d));
    } 

    static timeSinceSecs = d => {
        return Math.floor((new Date().getTime() - d.getTime()) / 1000);
    }

    static SQLTimeSinceHours = d => {
        return this.timeSinceHours(Helpers.SQLtoUTCDateObj(d));
    } 

    static timeSinceMinutes = d => {
        const secs = this.timeSinceSecs(d);
        const mins = secs / 60;
        return Math.floor(mins);
    }

    static timeSinceHours = d => {
        const secs = this.timeSinceSecs(d);
        const hours = secs / 3600;
        return Math.floor(hours);
    }

    static timeUntil = d => {
        const seconds = (d.getTime() - new Date().getTime()) / 1000;

        // 60 secs in a min
        // 3600 secs in an hour
        // 86400 secs in a day
        // 172800 secs in two days

        if(seconds > 604800)
            return d.toLocaleDateString('en-GB');
        if(seconds > 172800)
            return Math.floor(seconds/86400) + ' days';
        if(seconds > 3600)
            return Math.floor(seconds/3600) + ' hrs';
        if(seconds > 60)
            return Math.floor(seconds/60) + ' mins';
        return Math.floor(seconds) + ' secs';
    }


    

    static lastHeartBeat = (beat) => {

        if(beat == null)
            return '';

        const d = beat.split(/-|T|:|Z|\./);
        const heart = new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]));

        let days = 0, hrs = 0, mins = 0, secs= 0;
        var diff = (new Date() - heart);

        // First get the total seconds
        secs = Math.abs(diff)/1000;

        // then get the total days in those seconds
        days = Math.floor(secs/86400);

        // then get the total hours in those seconds
        hrs = Math.floor(secs/3600);

        // then get the total mins left in the seconds
        mins = Math.floor(secs/60);

        if(days > 0) return days + ' day' + (days > 1 ? 's' : '')
        if(hrs > 0) return hrs.toFixed(0) + ' hr' + (hrs > 1 ? 's' : '')
        if(mins > 0) return mins.toFixed(0) + ' min' + (mins > 1 ? 's' : '')
        return secs.toFixed(0) + ' sec' + (secs > 1 ? 's' : '')
    }



    static comparator = (a, b, orderBy, order = 'desc') => {
        var x, y;

        if(Array.isArray(orderBy)){

            orderBy.map(z => {
                x = a[z]!=null?a[z]:'';
                y = b[z]!=null?b[z]:'';

                if(isNaN(x) === false)
                    x = Number(x);

                if(isNaN(y) === false)
                    y = Number(x);

                if(x !== y){
                    if (y < x) return order == 'desc' ? -1 : 1;
                    if (y > x) return order == 'desc' ? 1 : -1;
                }
            })

        } else {

            x = a[orderBy]!=null?a[orderBy]:'';
            y = b[orderBy]!=null?b[orderBy]:'';

            if (y < x) return order == 'desc' ? -1 : 1;
            if (y > x) return order == 'desc' ? 1 : -1;

        }
        return 0;
    }


    static multiSort = (data = [], orderBy = []) => {
        //
        //  Order by needs to be an array of objects with orderBy and order properties
        //  e.g. [{orderBy: id, order'desc'}, {orderBy: name, order'asc'}]
        //
        //  Initially reverse the order becuase you want to sort 
        //  from the last first so that the first property will
        //  become the main sorting factor 
        var revOrder = JSON.parse(JSON.stringify(orderBy)).reverse();

        // Make a deep copy of the data
        var arr = JSON.parse(JSON.stringify(data));

        // Loop through the properties to order by
        revOrder.map(o => {

            // Sort the data by that property
            arr = arr.sort((a, b) => {
                return this.comparator(a, b, o.orderBy, o.order)
            })
        })

        return arr;
    }

    // Makes the columns name more presentable
    static cleanHeader = header => {
        let newHeader = header;
        if(header.indexOf('id_') === 0)
            newHeader = newHeader.replace('id_', '') + ' ID';

        newHeader = newHeader.replace(/_/gi, ' ');
        return newHeader.charAt(0).toUpperCase() + newHeader.slice(1)
    }

    

    static getHexColor = str => {
        const hexArr = ['4','5','6','7','8','9','A','B','C','D'];
        let num = str.charCodeAt(0) * str.charCodeAt(1);
        while(num < 100000){
            num = num * 7;
        }
        num = num.toString().slice(0, 6);
        let color = '#';
        for(let i = 0; i < num.length; i++){
            color += hexArr[parseInt(num.charAt(i))];
        }
        return color;
    }

    static getInitials = str => {

        try {
            const names = str.split(' ');
            return names[0].charAt(0).toUpperCase()+names[names.length-1].charAt(0).toUpperCase();
        } catch (error) {
            console.log(error);
            return "DEL";
        }
/*
        const names = str.split(' ');
        return names[0].charAt(0).toUpperCase()+names[names.length-1].charAt(0).toUpperCase();
*/
    }

    static truncate = (str, len) => {
        const end = str.length < len ? str.length : len;
        return str.substring(0, end) + (str.length > len ? '...' : '');
    }

    static localeCurrency = (value, currency = 'GBP') => {
        //return (parseInt(value * 100) / 100).toLocaleString(navigator.language, {style: 'currency', currency: currency})
        return (parseFloat(value).toLocaleString(navigator.language, {style: 'currency', currency: currency}))
    }

    static connectivityType = (type) => {
        switch(type){
            case 'gprs':
                return 'GPRS';
            case 'ethernet':
                return 'Ethernet';
            case 'wifi':
                return 'WiFi';
            default:
                return 'GPRS'
        }
    }



}
export default Helpers;