import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const TicketTypes = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Ticket Types',
                            route: '/settings/ticket_types'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Ticket Types</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>A categorisation of tickets based on what is reported.</Typography>

                <Crud
                    uri='ticket_types'
                    idCol='id_ticket_type'
                    orderCol='order'
                    displayColumns={[
                        { 
                            name: 'ticket_type_name', 
                            type: 'string',
                            editable: true,
                            required: true
                        }, 
                        {
                            name: 'fault', 
                            type: 'boolean',
                            editable: true
                        },
                        { 
                            name: 'disabled', 
                            type: 'boolean',
                            editable: true
                        }
                    ]}
                />

            </div>
        </div>
    )

}

export default TicketTypes
