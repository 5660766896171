import React from 'react';
import GlobalContext from '../../context/global-context';
import GroupScheme from './groupScheme';
import EvPoints from './evPoints';
import Site from './site';
import Commission from './commission';
import Summary from './summary';
import EVPoint from './evPoint';
import { Dialog, DialogContent, FormHelperText, CircularProgress, Typography } from '@material-ui/core';



const BLANK_COMMISSION = {
  groupSchemeID: null,
  siteID: null,
  EVPointIDs: [],
}

var POINTS_INT = null;

class CommissioningDialog extends React.Component {

  static contextType = GlobalContext

  constructor(props){
    super(props);

    this.state = {
      view: 'points',
      groupSchemes: [],
      evPoints: [],
      sites: [],
      busyPoints: [],
      commission: BLANK_COMMISSION,
      loading: false
    };

  }

  componentDidMount() {
    this.checkPointProps();
  }

  componentWillUnmount() {
    this.clearPointsInterval()
  }

  checkPointProps = async () => {
    if(typeof this.props.evPoints != 'undefined'){

      this.setState({loading: true}, async () => {
        this.props.evPoints.map(async p => {
          if(p.id_controller_type === 1)
            await this.addPoint({EVPointID: p.socket_id.replace('UKEV', '')})
          
          if(p.id_controller_type === 2 || p.id_controller_type === 3){
            for(let i=0;i<parseInt(p.connectors);i++){
              await this.addPoint({EVPointID: `${p.socket_id.replace('UKEV', '')}${i+1}`})
            }
          }
        });

        this.setState({loading: false});
      })
    }
  }

  checkGroupSchemeProps = async () => {
    if(typeof this.props.groupSchemeName != 'undefined')
      return this.props.groupSchemeName;
    
    return '';
  }

  fetchGroupSchemes = async () => {

    await this.context.commissionFetchGroupSchemes().then(
      res => {
        this.setState({groupSchemes: res.result.recordset});
      },
      err => {
        this.context.showAlert('error', 'Error loading group schemes')
      }
    )

  }

  selectScheme = (schemeID) => {
    let newCommission = {...this.state.commission};
    newCommission.groupSchemeID = schemeID;
    this.setState({commission: newCommission});
  }

  removeScheme = () => {
    let newCommission = {...this.state.commission};
    newCommission.groupSchemeID = null;
    this.setState({commission: newCommission});
  }

  addGroupScheme = fields => {
    
    return new Promise((resolve, reject) => {
      this.context.commissionAddGroupScheme(fields).then(
        res => {
          this.context.showAlert('success', 'Geoup scheme added')
          resolve(res);
        },
        err => {
          this.context.showAlert('error', 'Error adding group scheme')
          reject(err);
        }
      )
    })
  };

  fetchPoints = () => {

    this.context.commissionFetchPoints(this.state.commission).then(
      res => {  
        if(this.state.commission.EVPointIDs.length > 0){
          this.setState({evPoints: res.result}, () => {

            if(POINTS_INT === null)
              POINTS_INT = setInterval(this.fetchPoints, 5000)
          });
        }
      },
      err => {
        console.log(err);
        this.context.showAlert('error', 'Error fetching points')
      }
    )

  }


  clearPointsInterval = () => {
    if(POINTS_INT !== null)
      clearInterval(POINTS_INT)
    
    POINTS_INT = null
  }

  removePoint = EVPointID => {

    let newCommission = {...this.state.commission};
    let newPoints = [...this.state.evPoints];
    const idx = newCommission.EVPointIDs.indexOf(EVPointID);
    const pIdx = newPoints.findIndex(p => p.EVPointID == EVPointID);
    newCommission.EVPointIDs.splice(idx, 1);
    newPoints.splice(pIdx, 1);

    this.setState({commission: newCommission, evPoints: newPoints}, () => {
      this.clearPointsInterval();
      this.fetchPoints();
    });
  }

  addPoint = async fields => {

    await this.context.commissionAddPoint(fields).then((result) => {
          
        let newCommission = {...this.state.commission};
        let newPoints = [...this.state.evPoints];

        if(newCommission.EVPointIDs.indexOf(result.result.EVPointID) == -1){
          newCommission.EVPointIDs.push(result.result.EVPointID);
          newPoints.push(result.result);
        }
        
        this.setState({commission: newCommission, evPoints: newPoints}, () => {
          this.clearPointsInterval();
          this.fetchPoints();
        });

    }).catch((err) => {
      this.context.showAlert('error', 'Error adding a point ' + fields.EVPointID)
    });
    
  };

  addPointChargeTime = EVPointID => {
    
    this.addBusyPoint(EVPointID, () => {

        const fields = {
          EVPointID: EVPointID
        }

        this.context.commissionAddPointChargeTime(fields).then((result) => {
            this.clearPointsInterval();
            this.fetchPoints();
            this.removeBusyPoint(EVPointID);
        }).catch((err) => {
          this.removeBusyPoint(EVPointID);
          this.context.showAlert('error', 'Error adding time')
        });

    })
    
  };

  stopPointChargeTime = EVPointID => {
    
    this.addBusyPoint(EVPointID, () => {

        const fields = {
          EVPointID: EVPointID
        }

        this.context.commissionStopPointChargeTime(fields).then((result) => {
            this.clearPointsInterval();
            this.fetchPoints();
            this.removeBusyPoint(EVPointID);
        }).catch((err) => {
          this.removeBusyPoint(EVPointID);
          this.context.showAlert('error', 'Error stopping time')
        });

    })
    
  };

  changePointPower = (e, EVPointID) => {
    
    // Push the point into a log of stuff thats loading
    this.addBusyPoint(EVPointID)

      const fields = {
        EVPointID: EVPointID,
        Power: e.target.value
      }

      this.context.commissionChargePointPower(fields).then((result) => {
          this.clearPointsInterval();
          this.fetchPoints();
          this.removeBusyPoint(EVPointID);
      }).catch((err) => {
        this.removeBusyPoint(EVPointID);
        this.context.showAlert('error', 'Error setting power')
      });
    
  };


  addBusyPoint = (EVPointID, callback = () => {}) => {
    // Push the point into a log of stuff thats loading
    let newBusyPoints = [...this.state.busyPoints];
    newBusyPoints.push(EVPointID);
    this.setState({
      busyPoints: newBusyPoints
    }, () => {
      if(typeof callback == 'function')
        callback();
    });
  }


  removeBusyPoint = EVPointID => {
    // Remove the point from the loading log 
    let removeBusyPoints = [...this.state.busyPoints];
    const idx = removeBusyPoints.indexOf(EVPointID);
    removeBusyPoints.splice(idx);
    this.setState({
      busyPoints: removeBusyPoints
    })
  }



  /*
  *
  *   SITES
  * 
  */

 fetchSites = async () => {

   await this.context.commissionFetchSites(this.state.commission.groupSchemeID).then((result) => {
      this.setState({sites: result.result})
   }).catch((err) => {
      this.context.showAlert('error', 'Error loading sites')
   });

}

selectSite = (siteID) => {
  let newCommission = {...this.state.commission};
  newCommission.siteID = siteID;
  this.setState({commission: newCommission});
}

removeSite = () => {
  let newCommission = {...this.state.commission};
  newCommission.siteID = null;
  this.setState({commission: newCommission});
}

addSite = fields => {
  return new Promise((resolve, reject) => {
    

    const params = {
      ...fields,
      groupSchemeID: this.state.commission.groupSchemeID
    }
  
    this.context.commissionAddSite(params).then((result) => {
        let sites = [...this.state.sites];
        sites.push(result.result);
        this.setState({sites: sites});
        resolve(result);
    }).catch((err) => {
      this.context.showAlert('error', 'Error adding site');
      reject(err);
    });

  })
};



/*
*
*   COMMISSION
* 
*/

 commissionFinish = () => {

  return new Promise((resolve, reject) => {

    this.context.commissionComplete(this.state.commission).then((result) => {


      //
      //  UPDATE THE SUBSCRIPTION AS COMMISSIONED
      //
      if(typeof this.props.id_subscription != 'undefined'){
        const params = {
            selected: [this.props.id_subscription],
            start_date: new Date()
        }
        this.context.updateSubscriptionsAs(params, 'subscribed').then(
            res => {
                this.context.showAlert('success', 'Subscriptions started');
  
                if(typeof this.props.success == 'function')
                    this.props.success()
  
                if(typeof this.props.close == 'function')
                    this.props.close()
            },
            err => {
                this.context.showAlert('error', 'Commission complete but there was an error starting subscriptions ' + JSON.stringify(err))
  
                if(typeof this.props.close == 'function')
                    this.props.close()
            }
        )
      }


      if(result.result == 'COMPLETE'){
        this.commissionReset();
        resolve(result);
      } else {
        this.context.showAlert('error', 'Error completing commissioning')
        reject(result);
      }

    }).catch((err) => {
      this.context.showAlert('error', 'Error completing commissioning')
      reject(err);
    });
    
  });

}


commissionReset = () => {
  this.setState({
    commission: {...BLANK_COMMISSION},
    groupSchemes: [],
    evPoints: [],
    sites: []
  });
  this.clearPointsInterval();
}






  onChangeViewHandler = (view) => {
    this.setState({view: view}, () => {
      console.log(this.state.view)
    })
  }


  render() {

    return (
      
      <Dialog open={this.props.show} onClose={this.props.close} aria-labelledby="commissioning-dialog" fullWidth={true} maxWidth={'lg'}>
        <DialogContent>

          {this.state.loading && <CircularProgress color="inherit" size={28} />}

          {!this.state.loading && <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

              {/* CHARGE POINTS */}
              {this.state.view == 'points' && <EvPoints switchView={this.onChangeViewHandler} removePoint={this.removePoint} addPoint={this.addPoint} fetchPoints={this.fetchPoints}></EvPoints>}
              
              {this.state.view == 'points' && 
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                {this.state.evPoints.map((p) => (
                  <EVPoint 
                      key={p.EVPointID} 
                      point={p} 
                      changePointPower={this.changePointPower} 
                      addPointChargeTime={this.addPointChargeTime} 
                      stopPointChargeTime={this.stopPointChargeTime} 
                      removePoint={this.removePoint}
                      busyPoints={this.state.busyPoints} 
                      commission={this.state.commission} />
                  ))}
              </div>}

              {/* GROUP SCHEME */}
              {this.state.view == 'scheme' && <GroupScheme switchView={this.onChangeViewHandler} selectScheme={this.selectScheme} removeScheme={this.removeScheme} addGroupScheme={this.addGroupScheme} fetchGroupSchemes={this.fetchGroupSchemes} groupSchemes={this.state.groupSchemes} groupSchemeName={typeof this.props.groupSchemeName == 'string' ? this.props.groupSchemeName : ''} />}
              
              {/* SITE */}
              {this.state.view == 'site' && <Site switchView={this.onChangeViewHandler} selectSite={this.selectSite} removeSite={this.removeSite} fetchSites={this.fetchSites} addSite={this.addSite} sites={this.state.sites} commission={this.state.commission}></Site>}
              
              {/* SUMMARY & COMMISSION */}
              {this.state.view == 'commission' && 
                <Summary 
                commission={this.state.commission} 
                groupSchemes={this.state.groupSchemes} 
                sites={this.state.sites} 
                evPoints={this.state.evPoints} 
                busyPoints={this.state.busyPoints}
                changePointPower={this.changePointPower} 
                addPointChargeTime={this.addPointChargeTime} 
                stopPointChargeTime={this.stopPointChargeTime}
                removePoint={this.removePoint}
                removeSite={()=>this.onChangeViewHandler('site')}
                removeScheme={()=>this.onChangeViewHandler('scheme')} />
              }
              {this.state.view == 'commission' && <Commission switchView={this.onChangeViewHandler} commissionReset={this.commissionReset} commissionFinish={this.commissionFinish}></Commission>}

            </div>}

        </DialogContent>
      </Dialog>

    )
            
  }
}

export default CommissioningDialog;
