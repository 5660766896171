import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import tickets from "./reducers/ticketsReducer";
import user from "./reducers/userReducer";
import background from "./reducers/backgroundReducer";

export default createStore(
    combineReducers({
        tickets,
        user,
        background
    }),
    {},
    applyMiddleware(thunk)
)