import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../context/global-context';
import { Typography, Button, CircularProgress, makeStyles, useTheme, List, ListItemText, ListItem, TextField, Collapse, Paper, FormControl, FormHelperText, Select, InputLabel, MenuItem} from '@material-ui/core';
import countries from 'i18n-iso-countries';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: 20,
        padding: 20,
        maxWidth: 400
    },
    formControl: {
        marginTop: 15
    },
    buttonGroup: {
        display: 'flex', 
        lexDirection: 'row', 
        justifyContent: 'space-between',
        marginTop: 15
    }
}))


const errorField = () => {
    return {
        dirty: false,
        invalid: false
    };
}


const CommissioningSelectSite = props => {

    const context = useContext(GlobalContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    const isoCountries = countries.getAlpha2Codes();
    


    const [sites, setSites] = useState([]);
    const [fields, setFields] = useState({
        SiteName: '',
        AddressLine1: '',
        AddressLine2: '',
        AddressLine3: '',
        Town: '',
        County: '',
        PostCode: '',
        Country: 'GB',
    })
    const [errors, setErrors] = useState(
        {
            SiteName: errorField(),
            AddressLine1: errorField(),
            AddressLine2: errorField(),
            AddressLine3: errorField(),
            Town: errorField(),
            County: errorField(),
            PostCode: errorField(),
            Country: errorField()
        }
    )
    const [showAdd, setShowAdd] = useState(false);
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [adding, setAdding] = useState(false);
    const [error, setError] = useState(false);

  
    const handleInputChange = (e) => {
        let newFields = {...fields};
        newFields[e.target.name] = e.target.value;
        setFields(newFields);
    }

    const dirtyAll = () => {
        let newErrors = {...errors};
        Object.values(errors).map(e => e.dirty = true)
        setErrors(newErrors);
    }
  
    const validate = () => {
        let newErrors = {...errors};
        const required = [
            'SiteName',
            'AddressLine1',
            'Town',
            'PostCode'
        ]
  
        required.map(f => {
            if(fields[f].length > 0)
                newErrors[f].dirty = true;
  
            newErrors[f].invalid = false;
            if(newErrors[f].dirty && fields[f] == ''){
                newErrors[f].invalid = true;
            }
        })
  
        const isValid = Object.values(newErrors).findIndex(e => e.invalid === true) === -1;
        setErrors(newErrors);
        setValid(isValid);
    }

    const handleSelect = (site) => {
        if(typeof props.handleSelect === 'function')
            props.handleSelect(site);
    }

    const handleAdd = () => {

        dirtyAll();
        validate();
        if(valid){

            setAdding(true);
            setError(false);

            const params = {
            ...fields,
            groupSchemeID: props.groupScheme.groupSchemeID
            }
                
            context.commissionAddSite(params).then((result) => {
                handleSelect(result.result);
                fetchSites();
                setAdding(false);
                setError(false);
            }).catch((err) => {
                setAdding(false);
                setError(true);
                context.showAlert('error', 'Error adding site');
            });
            
        } else {
            console.log('you are not valid');
        }

    }
    
    const fetchSites = async () => {
        setLoading(true);
        await context.commissionFetchSites(props.groupScheme.groupSchemeID).then((result) => {
            setLoading(false);
            setSites(result.result);
        }).catch((err) => {
            setLoading(false);
            context.showAlert('error', 'Error loading sites')
        });
    }


    useEffect(() => {
        fetchSites();
    }, [props.groupScheme])

    useEffect(() => {
        validate();
    }, [fields])


    return(
        <Paper className={classes.paper}>

            {!showAdd &&
            <React.Fragment>
                <Typography variant="h6">Select existing site</Typography>
                {loading && <CircularProgress size={24} />}
                {(sites.length == 0 && !loading)  && <Typography variant="body1" color="textSecondary">Nothing found</Typography>}

                <List className={classes.list}>
                    {sites.map((s, idx) => 
                        <ListItem button key={idx} onClick={() => handleSelect(s)}>
                            <ListItemText primary={`${s.ChargeSiteID}: ${s.SiteName}`} />
                        </ListItem>
                    )}
                </List>

                <Button variant="contained" color="primary" onClick={() => setShowAdd(true)}>Add new</Button>
            </React.Fragment>}

            <Collapse in={showAdd}>

                <Typography variant="h5" className={{marginBottom: 20}}>Create a new site</Typography>

                <FormControl fullWidth className={classes.formControl}>
                    <TextField variant="outlined" label="Site Name" type="text" id="SiteName" name="SiteName" autoFocus onChange={handleInputChange} error={errors.SiteName.invalid} />
                    {errors.SiteName.invalid && <FormHelperText>Site name is required</FormHelperText>}
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                    <TextField variant="outlined" type="text" id="AddressLine1" label="Address Line 1" name="AddressLine1" onChange={handleInputChange} error={errors.AddressLine1.invalid} />
                    {errors.AddressLine1.invalid && <FormHelperText>Address Line 1 is required</FormHelperText>}
                </FormControl>
                
                <FormControl fullWidth className={classes.formControl}>
                    <TextField variant="outlined" type="text" id="AddressLine2" label="Address Line 2" name="AddressLine2" onChange={handleInputChange} />
                </FormControl>
                
                <FormControl fullWidth className={classes.formControl}>
                    <TextField variant="outlined" type="text" id="AddressLine3" label="Address Line 3" name="AddressLine3" onChange={handleInputChange} />
                </FormControl>
                
                <FormControl fullWidth className={classes.formControl}>
                    <TextField variant="outlined" type="text" id="Town" label="Town" name="Town" onChange={handleInputChange} error={errors.Town.invalid}  />
                    {errors.Town.invalid && <FormHelperText>Town is required</FormHelperText>}
                </FormControl>
                
                <FormControl fullWidth className={classes.formControl}>
                    <TextField variant="outlined" type="text" id="County" label="County" name="County" onChange={handleInputChange} />
                </FormControl>
                
                <FormControl fullWidth className={classes.formControl}>
                    <TextField variant="outlined" type="text" id="PostCode" label="Post Code" name="PostCode" onChange={handleInputChange} error={errors.PostCode.invalid}  />
                    {errors.PostCode.invalid && <FormHelperText>Post code is required</FormHelperText>}
                </FormControl>
                
                <FormControl fullWidth className={classes.formControl}>
                    <Select variant="outlined" type="text" id="Country" name="Country" onChange={handleInputChange} value={fields.Country}>
                        {Object.keys(isoCountries).map(c => <MenuItem key={c} value={c}>{countries.getName(c, "en")}</MenuItem>)}
                    </Select>
                </FormControl>

                <div className={classes.buttonGroup}>
                    <Button variant="contained" color="default" onClick={() => setShowAdd(false)}>Back</Button>
                    <Button variant="contained" color="secondary" onClick={handleAdd}>{adding?<CircularProgress size={16} color="inherit" />:'Create'}</Button>
                </div>


            </Collapse>

        </Paper>
    );

}

export default CommissioningSelectSite