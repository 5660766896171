import { makeStyles, useTheme, Paper, Typography, Divider, Button, CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../context/global-context';
import CommissioningGroupScheme from './groupScheme';
import CommissioningSelectGroupScheme from './groupSchemeSelect';
import CommissioningPoints from './points/index';
import CommissioningSite from './site';
import CommissioningSelectSite from './siteSelect';
import CommissioningOrder from './order';
import querystring from 'query-string';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    overflow: 'auto'
  },
  inner: {
      flex: 1,
      padding: 20,
      flexDirection: 'row'
  },
  paper: {
    padding: theme.spacing(2)
  },
  infoPane: {
    marginTop: 40,
    maxWidth: 700
  },
  commissionPane: {
    marginTop: 40
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center'
  }
}));


const Commissioning  = props => {

  const context = useContext(GlobalContext);
  const theme = useTheme();
  const classes = useStyles(theme);

  const [loading, setLoading] = useState(false);
  const [commissioning, setCommissioning] = useState(false);

  const [points, setPoints] = useState([]);
  const [groupScheme, setGroupScheme] = useState(null);
  const [site, setSite] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [order, setOrder] = useState(null);

  const [valid, setValid] = useState(false);


  const getCorrectSocketId = (socket_id, connector) => {
    const id = parseInt(socket_id.replace('UKEV', ''));
    if(isNaN(id))
      return false;
    
    if(id > 599 && id < 9999)
      return id;
    else{
      return parseInt(`${id}${connector}`);
    }
  }

  const handleAddPoint = async (pointId) => {

    setLoading(true);

    let newPoints = JSON.parse(JSON.stringify(points));

    let socket_id = pointId;
    const dashIdx = socket_id.indexOf('-');
    if(dashIdx > -1)
      socket_id = socket_id.slice(0, dashIdx);

    let setSubscriptionIdTo = null;

    await context.fetchSubscriptionSocket(socket_id).then(
      async result => {
        if(typeof result.result[0] !== 'undefined'){

          const subscription_socket = result.result[0];

          for(let i=1;i<=subscription_socket.connectors;i++){
            const fields = {
              EVPointID: getCorrectSocketId(socket_id, i),
              ConnectorSocketType: subscription_socket.connector_type === 'tethered' ? 8 : 2,
              AMPs: subscription_socket.power,
              Power: subscription_socket.kilowatts,
              RFIDEnabled: subscription_socket.rfid,
              FreeModeOptionAvailable: subscription_socket.id_controller_type <= 2 ? true : false
            }

            await context.commissionAddPoint(fields).then((result) => {

              newPoints.push(result.result);
      
            }).catch((err) => {
              context.showAlert('error', 'Error adding a point ' + fields.EVPointID)
            });

          }

          // If there are charge points added but no subscription
          if(subscriptionId === null && subscription_socket.id_subscription !== null)
            setSubscriptionIdTo = subscription_socket.id_subscription;
          

        } else {

          const fields = {
            EVPointID: getCorrectSocketId(socket_id, 1),
            ConnectorSocketType: 2,
            AMPs: 32,
            Power: 7.2,
            RFIDEnabled: false,
            FreeModeOptionAvailable: false
          }

          await context.commissionAddPoint(fields).then((result) => {

            newPoints.push(result.result);
    
          }).catch((err) => {
            context.showAlert('error', 'Error adding a point ' + fields.EVPointID)
          });

        }
      }).catch(err => {
        context.showAlert('error', `Error fetching socket ${socket_id} from subscription`)
      })

      setLoading(false);
      setPoints(newPoints)

      if(setSubscriptionIdTo !== null)
        setSubscriptionId(setSubscriptionIdTo);

      

      return true;

  }

  const handleRemovePoint = (EVPointID) => {
    let newPoints = JSON.parse(JSON.stringify(points));
    const idx = newPoints.findIndex(p => p.EVPointID === EVPointID);
    if(idx > -1)
      newPoints.splice(idx, 1);

    setPoints(newPoints);
  }
  
  const handleChangeScheme = () => {
    setGroupScheme(null);
    setSite(null);
  }



  const handleReset = () => {
    setLoading(false);
    setCommissioning(false);
    setPoints([]);
    setGroupScheme(null);
    setSite(null)
    setSubscriptionId(null)
    setOrderId(null)
    setOrder(null)
    setSubscription(null)
  }


  const handleCommission = () => {

    setCommissioning(true);

    if(valid) {

      let commission = {
        groupSchemeID: groupScheme.groupSchemeID,
        siteID: site.ChargeSiteID,
        EVPointIDs: [],
      }

      points.forEach(p => {
        commission.EVPointIDs.push(p.EVPointID)
      })
    
      context.commissionComplete(commission).then((result) => {


        setCommissioning(false);
        if(result.result == 'COMPLETE'){
          handleReset();

          if(subscriptionId !== null){
            //
            //  UPDATE THE SUBSCRIPTION AS COMMISSIONED
            //    
            const params = {
                selected: [subscriptionId],
                start_date: new Date()
            }
            context.updateSubscriptionsAs(params, 'subscribed').then(
                res => {
                    if(typeof props.history !== 'undefined' && orderId !== null)
                      props.history.push(`/order/${orderId}`);

                    context.showAlert('success', 'Subscriptions started');
                },
                err => {
                    if(typeof props.history !== 'undefined' && orderId !== null)
                      props.history.push(`/order/${orderId}`);

                    context.showAlert('error', 'Commission complete but there was an error starting the subscription, you may need to start manually. ' + JSON.stringify(err))
                }
            )
          } else 
            context.showAlert('success', 'Commissioning complete');

        } else {
          context.showAlert('error', 'Error completing commissioning')
        }
  
      }).catch((err) => {
        setCommissioning(false);
        context.showAlert('error', 'Error completing commissioning')
      });

    }
  
  }


  const handleLoadSubscription = async () => {

    if(subscriptionId !== null){

      setLoading(true);

      await context.fetchSubscription(subscriptionId)
      .then(async result => {

        setSubscription(result.result[0]);

        // If no points have been added then add all the points from this subscription
        if(points.length === 0){
          await context.fetchSubscriptionSockets(subscriptionId)
            .then(async result => {

              const sockets = result.result;
              const newPoints = JSON.parse(JSON.stringify(points));

              for(let i=0;i<sockets.length;i++){
                
                const subscription_socket = sockets[i];

                for(let i=1;i<=subscription_socket.connectors;i++){
                  
                  const fields = {
                    EVPointID: getCorrectSocketId(subscription_socket.socket_id, i),
                    ConnectorSocketType: 2,
                    AMPs: subscription_socket.power,
                    Power: subscription_socket.kilowatts,
                    RFIDEnabled: subscription_socket.rfid,
                    FreeModeOptionAvailable: subscription_socket.id_controller_type <= 2 ? true : false
                  }

                  await context.commissionAddPoint(fields).then((result) => {

                    newPoints.push(result.result);
            
                  }).catch((err) => {
                    setLoading(false);
                    context.showAlert('error', 'Error adding a point ' + fields.EVPointID)
                  });

                }

              }

              setLoading(false);
              setPoints(newPoints)

            })
            .catch(err => {
              setLoading(false);
              context.showAlert('error', 'Error loading sockets from subscription')
            })
        } else
          setLoading(false);

      })
      .catch(err => {
        setLoading(false);
        context.showAlert('error', 'Error loading subscription')
      })

      
    }

  }

  const handleLoadOrder = async () => {

    if(orderId !== null){
      context.fetchOrder(orderId)
      .then(result => {
        setOrder(result.result);
      })
      .catch(err => {
        context.showAlert('error', 'Error loading order details')
      })
    }

  }



  useEffect(() => {
    if(points.length > 0 && groupScheme !== null && site !== null)
      setValid(true);
    else
      setValid(false);

  }, [points, groupScheme, site])


  useEffect(() => {
    if(orderId === null && subscription !== null && subscription.id_order !== null)
      setOrderId(subscription.id_order);
  }, [subscription])


  useEffect(() => {
    handleLoadSubscription();
  }, [subscriptionId])

  useEffect(() => {
    handleLoadOrder();
  }, [orderId])



  //
  //  Check for the presence of a subscription id in the query string
  //
  useEffect(() => {
    const qs = querystring.parse(props.location.search);
    if(typeof qs.subscription !== 'undefined' && parseInt(qs.subscription) > 0)
      setSubscriptionId(parseInt(qs.subscription));

    if(typeof qs.order !== 'undefined' && parseInt(qs.order) > 0)
      setOrderId(parseInt(qs.order));

  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.inner}>

        <Typography variant="h4" style={{marginBottom: 20}}>Remote Commissioning</Typography>
        {!subscriptionId && <Alert severity="warning" style={{marginBottom: 20}}>You are manually commissioning, Once complete you need to manually set the commissioning date in the order for the charge points.</Alert>}
        {subscriptionId && <Alert severity="info" style={{marginBottom: 20}}>Any additional charge points you add will need a manual commissioning date adding to the order.</Alert>}

          {order &&
            <React.Fragment>
              <Typography variant="h5" style={{marginBottom: 20}}>Order</Typography>
              <CommissioningOrder order={order} subscription={subscription} />
            </React.Fragment>
          }

          <Typography variant="h5">Charge Points</Typography>
          <Typography variant="caption" color="primary">Step 1: Add your charge points, check their configuration is correct, are online, and have been tested with a positive meter reading<br /><b>Note: Double check the power ratings with the installer.</b></Typography>
          <CommissioningPoints handleAdd={handleAddPoint} handleRemove={handleRemovePoint} points={points} order={order} loading={loading} />

          <div className={classes.infoPane}>

            <Divider style={{margin: '20px 0'}} />

            {points.length === 0 &&
              <React.Fragment>
                <Typography variant="h6">Start by adding your charge points</Typography>
                <Typography variant="body1">Enter the charge point ID and the sockets will be looked up against any existing orders</Typography>
              </React.Fragment>
            }

            {points.length > 0 && 
              <React.Fragment>
                <Typography variant="h5" style={{marginTop: 20}}>Group scheme</Typography>
                <Typography variant="caption" color="primary">Step 2: Select or create a new back office group scheme, most of the time it's the company name.<br /><b>Note: Selecting essential plan will put all charge points onto plug and go.</b><br /><b>Tip: Typing in the 'Create Group Scheme' box filters the list.</b></Typography>
                {groupScheme !== null && <CommissioningGroupScheme groupScheme={groupScheme} changeScheme={handleChangeScheme} />}
                {groupScheme === null && <CommissioningSelectGroupScheme handleSelect={setGroupScheme} orderGroupScheme={(typeof order !== 'undefined' && order !== null) ? order.group_scheme : ''}/>}

                <Divider style={{margin: '20px 0'}} />

                <Typography variant="h5" style={{ marginTop: 20}}>Site</Typography>
                <Typography variant="caption" color="primary">Step 3: Select or create a new site for the charge points, this is where the charge point are installed.<br /><b>Note: Even on essential mode this is required so we know where the charge points are should we need to find them.</b></Typography>
                {groupScheme === null && <Typography variant="body1" color="textSecondary">Select a group scheme first</Typography>}
                {site !== null && <CommissioningSite site={site} changeSite={() => setSite(null)} />}
                {(groupScheme !== null && site === null) && <CommissioningSelectSite groupScheme={groupScheme} site={site} handleSelect={setSite} />}
              </React.Fragment>
            }

          </div>

          <div className={classes.commissionPane}>
            <Paper className={classes.paper}>
              <div className={classes.row}>
                <Button variant="contained" size="large" color="secondary" disabled={!valid} onClick={handleCommission}>{commissioning ? <CircularProgress size={24} color="inherit" /> : 'Commission'}</Button>
                <Typography variant="body1" gutterBottom={false} style={{marginBottom: 0}}>Ensure you have double checked before commissioning.</Typography>
              </div>
            </Paper>
          </div>

      </div>
    </div>
  )
}

export default Commissioning