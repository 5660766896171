import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../../context/global-context';
import ActivityIndicator from '../../global/ActivityIndicator';
import { Table, TablePagination, TableBody, TableCell, TableHead, TableRow, Paper, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import Quickcrumbs from '../../global/Quickcrumbs';


const BerthVendWalletBalances  = props => {

    const context = useContext(GlobalContext);

    const [fields, setFields] = useState({
        pit: new Date().toISOString().split('T')[0]
    });

    const [loadedFields, setLoadedFields] = useState({pit: null})
    const [data, setData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [page, setPage] = useState(0);

    
    const handleChange = (e) => {
        let newFields = {...fields};
        newFields[e.target.name] = e.target.value;
        setFields(newFields)
    }

    useEffect(() => {

    }, [fields])

    const closeDialog = () => {
        setShowDialog(false);
    }

    const loadData = () => {
        setLoading(true);
        setLoadedFields(fields);
        setData([]);
        setHeaders([]);

        context.apiRequest(`/reporting/pit/bv/wallet_balances?${context.queryString(fields)}`, 'GET')
        .then(
            res => {
                setData(res.result);

                if(typeof res.result[0] !== 'undefined')
                    setHeaders(Object.keys(res.result[0]));

                setLoading(false);
            },
            err => {
                console.log(err);
                setLoading(false);
            }
        );
    }

    const dirty = () => {                
        if(fields.pit != loadedFields.pit)
            return true;

        return false;
    }


    const downloadExcel = async () => {
        setDownloading(true);
  
        try {
  
            await context.apiFileDownload(`/reporting/pit/bv/wallet_balances/download?${context.queryString(fields)}`, `WalletBalances_${new Date(fields.pit).getTime()}.xlsx`);
  
        } catch (error) {
  
            console.log(error);

        } finally {
          setDownloading(false);
        }
  
    }


    const downloadCSV = () => {
        if(dirty()){
            setShowDialog(true);
        } else {
            if(data.length > 0){
                setDownloading(true);
                let csvStr = '';

        
                // Add the headers
                headers.map((h, idx) => {
                    csvStr = csvStr + `${h},`;
                })
    
    
                csvStr = csvStr.substring(0,csvStr.length - 1);
                csvStr = csvStr + "\n";
        
                // Add the data
                data.map(row => { 

                    headers.map((h, idx) => {
                        csvStr = csvStr + `${row[h]},`;
                    })
    
                    csvStr = csvStr.substring(0,csvStr.length - 1);
                    csvStr = csvStr + "\n";
                    
                })
        
                csvStr = csvStr.substring(0, csvStr.length - 1);
    
                const filename = `wallet_balances_${fields.end}`;
    
                var blob = new Blob([csvStr], { type: 'text/csv' });
        
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                    console.log('using window navigator');
                    window.navigator.msSaveOrOpenBlob(blob, filename+'.csv');
                    setDownloading(false);
                } else { // for Non-IE (chrome, firefox etc.)
                    console.log('not using window navigator');
                    let a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = 'data:text/csv;charset=utf-8,'+encodeURIComponent("\uFEFF"+csvStr);
                    a.download = filename+'.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    setDownloading(false);
                }

            }
        }
    }

    const SQLtoUTCDate = (str, datetime = true) => {
        const d = str.split(/-|T|:|\.|Z/);
        const jD = new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5], d[6]));
        if(datetime)
            return jD.toLocaleDateString('en-GB') + ' ' + jD.toLocaleTimeString('en-GB');
        
        return jD.toLocaleDateString('en-GB');
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    return(
        <div style={styles.container}>
            <div style={styles.inner}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Reporting',
                            route: '/reporting'
                        },
                        {
                            name: 'BerthVend Wallet Balances',
                            route: '/reporting/bv/wallet_balances'
                        }
                    ]}
                    history={props.history}
                />

                <h2>BerthVend Wallet Balances</h2>

                <div style={styles.taskbar}>

                    <div>

                        <TextField name="pit" label="Date" type="date" value={fields.pit} onChange={handleChange} style={styles.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <Button variant="contained" color={dirty()?'secondary':'default'} onClick={loadData}>Go{loading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>

                    </div>
                    <div>
                        <Button variant="contained" color="secondary" onClick={downloadExcel}>Download to .xlsx{downloading && <div style={styles.loading}><ActivityIndicator /></div>}</Button>
                    </div>
                </div>

                {(data.length > 0 && headers.length > 0) &&
                    <Paper style={styles.root}>
                        <Table style={styles.table}>
                            <TableHead>
                            <TableRow>
                                {headers.map((h, idx) => <TableCell key={idx}>{h}</TableCell>)}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
                                <TableRow key={idx}>
                                    {headers.map((h, hIdx) => <TableCell key={hIdx}>{row[h]}</TableCell>)}
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                }

                {data.length == 0 && <div style={styles.nodata}>No Data</div>}

                <Dialog
                    open={showDialog}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Reload the data"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You changed the values in the taskbar but didn't reload the data, reload before you download.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={closeDialog} color="secondary">
                        OK
                    </Button>
                    </DialogActions>
                </Dialog>
                

            </div>
        </div>
    );

}

const styles  = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    inner: {
        flex: 1,
        padding: 20,
        flexDirection: 'row'
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    textField: {
        width: 200,
        marginRight: 20
    },
    taskbar: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    loading: {
      borderRadius: 25,
      height: 24,
      width: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    nodata: {
        padding: 20,
        fontSize: 18,
        opacity: .3
    }
}

export default BerthVendWalletBalances