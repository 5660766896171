import React, { useState, useEffect } from "react";
import GlobalContext from "../../context/global-context";
import ActivityIndicator from "../global/ActivityIndicator";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Helpers from "../global/helpers";
import { withTheme } from "@material-ui/styles";
import { Switch, FormControlLabel, Typography, TablePagination } from "@material-ui/core";

function Pending(props) {
  const [fields, setFields] = useState({
    source: "veos",
    end: new Date().toISOString().split("T")[0],
    threshold: 0,
    showUnpaid: false
  });
  const [loadedFields, setLoadedFields] = useState({
    source: null,
    end: null,
    threshold: 0,
  });
  const [schemesInternal, setSchemesInternal] = useState([]);
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const [filteredSummary, setFilteredSummary] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [processError, setProcessError] = useState(null);
  const [total, setTotal] = useState(null);
  const [posting, setPosting] = useState(false);
  const [paymentFrequencyFilter, setPaymentFrequencyFilter] = useState("All"); // State to store selected payment frequency

  const context = React.useContext(GlobalContext);

  const handleChange = (e, callback = () => { }) => {
    const updatedFields = { ...fields };
    updatedFields[e.target.name] = e.target.value;
    setFields(updatedFields);
    setDirty(true);
    typeof callback === "function" && callback();
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const loadSchemesInternal = (callback = () => { }) => {
    setSchemesInternal([]);
    context.fetchInternalGroups(fields.source).then(
      (res) => {
        setSchemesInternal(res.result);
        typeof callback === "function" && callback();
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };



  const loadStatements = async () => {

    setLoading(true);
    setLoadedFields({ ...fields });
    setData([]);
    setSummary([]);
    setSelected([]);

    loadSchemesInternal();

    try {

      const res = await context.fetchPendingStatements(fields);
      setSummary(res.result);
      setLoading(false);
      setDirty(false);

    } catch (error) {
      console.log(error);
      setData([]);
      setSummary([]);
      setLoading(false);
    }

  };

  useEffect(() => {

    if (paymentFrequencyFilter === 'All')
      setFilteredSummary(summary.filter(s => s.SubscriberDue >= fields.threshold));
    else {
      setFilteredSummary(summary.filter(s => paymentFrequency(s.groupSchemeID) === paymentFrequencyFilter && s.SubscriberDue >= fields.threshold))
    }

  }, [summary, paymentFrequencyFilter, fields])

  useEffect(() => {

    let total = 0;
    filteredSummary.map((s) => {
      total += parseInt(s.SubscriberDue * 100);
    });
    setTotal(total / 100);


  }, [filteredSummary])

  const postStatements = () => {
    setPosting(true);

    // let sessions = [];
    // data.map((row) => {
    //   if (
    //     selected.findIndex(
    //       (s) =>
    //         s.groupSchemeID === row.groupSchemeID && s.Currency === row.Currency
    //     ) > -1
    //   )
    //     sessions.push(row.Id);
    // });

    context
      .postStatements({
        ...fields,
        selectedGroupSchemeIDs: selected,
      })
      .then(
        (res) => {
          setPosting(false);
          loadStatements();
        },
        (err) => {
          console.log(err);
          setPosting(false);
          if (typeof err.result !== "undefined") setProcessError(err.msg);
          else setProcessError(JSON.stringify(err));
        }
      );
  };

  const paymentFrequency = (groupSchemeID) => {
    const scheme = schemesInternal.find(
      (s) => s.id_group_scheme === groupSchemeID
    );
    return typeof scheme !== "undefined" &&
      scheme.bank != null &&
      scheme.bank.payment_frequency
      ? scheme.bank.payment_frequency
      : "Unknown";
  };

  const toggleRow = (groupSchemeID, currency) => {
    const newSelected = JSON.parse(JSON.stringify(selected));
    const idx = newSelected.findIndex(r => r.groupSchemeID === groupSchemeID && r.Currency === currency);
    if (idx > -1)
      newSelected.splice(idx, 1);
    else
      newSelected.push({
        groupSchemeID: groupSchemeID,
        Currency: currency,
      })
    setSelected(newSelected);
  };

  const clearSelectedRows = () => {
    setSelected([]);
  };

  const toggleUnpaid = () => {
    const updatedFields = { ...fields };
    updatedFields.showUnpaid = !fields.showUnpaid;
    setFields(updatedFields);
    setDirty(true);
  };


  //
  // Pagination
  //

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10))
  };

  return (
    <div style={{ ...(!props.show && styles.hide) }}>
      <h2
        style={{
          color: props.theme.palette.primary.main,
          fontWeight: 300,
        }}
      >
        Pending {total !== null && ` (${Helpers.localeCurrency(total, "GBP")})`}
      </h2>

      <div style={styles.taskbar}>
        <div>
          {/*
          <FormControl style={styles.textField}>
            <InputLabel shrink htmlFor="source-label-placeholder">
              Source
            </InputLabel>
            <Select
              value={fields.source}
              onChange={(e) => handleChange(e)}
              input={<Input name="source" id="source-label-placeholder" />}
              displayEmpty
              name="source"
            >
              <MenuItem value={"veos"}>VendElectric</MenuItem>
              <MenuItem value={"wavemasta"}>Wavemasta</MenuItem>
            </Select>
          </FormControl>
          */}

          <TextField
            id="date"
            name="end"
            label="End"
            type="date"
            value={fields.end}
            onChange={(e) => handleChange(e)}
            style={styles.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Button
            id="load"
            variant="contained"
            color={dirty ? "secondary" : "default"}
            onClick={loadStatements}
          >
            Go
            {loading && (
              <div style={styles.loading}>
                <ActivityIndicator />
              </div>
            )}
          </Button>

          <FormControl style={{ marginLeft: 20 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={fields.showUnpaid}
                  onChange={toggleUnpaid}
                  name="showUnpaid"
                  color="secondary"
                />
              }
              label="Include unpaid subscriptions"
            />
          </FormControl>
        </div>
        <div>
          <FormControl style={styles.textField}>
            <InputLabel shrink htmlFor="payment-frequency-label-placeholder">
              Payment Frequency
            </InputLabel>
            <Select
              value={paymentFrequencyFilter}
              onChange={(e) => setPaymentFrequencyFilter(e.target.value)}
              input={
                <Input
                  name="payment-frequency"
                  id="payment-frequency-label-placeholder"
                />
              }
              displayEmpty
              name="payment-frequency"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Monthly">Monthly</MenuItem>
              <MenuItem value="Quarterly">Quarterly</MenuItem>
              <MenuItem value="Unknown">Unknown</MenuItem>
            </Select>
          </FormControl>

          <FormControl style={styles.textField}>
            <InputLabel shrink htmlFor="threshold-label-placeholder">
              Threshold
            </InputLabel>
            <Select
              value={fields.threshold}
              onChange={(e) => handleChange(e, clearSelectedRows)}
              input={
                <Input name="threshold" id="threshold-label-placeholder" />
              }
              displayEmpty
              name="threshold"
            >
              <MenuItem value={"100"}>£100</MenuItem>
              <MenuItem value={"50"}>£50</MenuItem>
              <MenuItem value={"25"}>£25</MenuItem>
              <MenuItem value={"0"}>None</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="secondary"
            onClick={postStatements}
          >
            Process
            {posting && (
              <div style={styles.loading}>
                <ActivityIndicator />
              </div>
            )}
          </Button>
        </div>
      </div>

      <Paper style={styles.root}>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={filteredSummary.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Table style={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>Group Scheme</TableCell>
              <TableCell>Payment Frequency</TableCell>
              <TableCell align="right">Currency</TableCell>
              <TableCell align="right">Revenue Gross</TableCell>
              <TableCell align="right">Revenue Net</TableCell>
              <TableCell align="right">Revenue Tax</TableCell>
              <TableCell align="right">Net Service Charge</TableCell>
              <TableCell align="right">Net Management Fee</TableCell>
              <TableCell align="right">Management VAT</TableCell>
              <TableCell align="right">Adjustments</TableCell>
              <TableCell align="right">Amount Due</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSummary.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
              <TableRow
                key={idx}
                selected={selected.findIndex(s => s.groupSchemeID === row.groupSchemeID && s.Currency === row.Currency) > -1}
                onClick={() => toggleRow(row.groupSchemeID, row.Currency)}
              >
                <TableCell>{row.groupSchemeName}</TableCell>
                <TableCell>{paymentFrequency(row.groupSchemeID)}</TableCell>
                <TableCell align="right">{row.Currency}</TableCell>
                <TableCell align="right">
                  {Helpers.localeCurrency(row.TotalGross, row.Currency)}
                </TableCell>
                <TableCell align="right">
                  {Helpers.localeCurrency(row.TotalNet, row.Currency)}
                </TableCell>
                <TableCell align="right">
                  {Helpers.localeCurrency(row.TotalVAT, row.Currency)}
                </TableCell>
                <TableCell align="right">
                  {Helpers.localeCurrency(
                    row.NetManagementServiceCharge,
                    row.Currency
                  )}
                </TableCell>
                <TableCell align="right">
                  {Helpers.localeCurrency(row.NetManagementFee, row.Currency)}
                </TableCell>
                <TableCell align="right">
                  {Helpers.localeCurrency(row.ManagementVAT, row.Currency)}
                </TableCell>
                <TableCell align="right">
                  {Helpers.localeCurrency(row.Adjustments, row.Currency)}
                </TableCell>
                <TableCell align="right">
                  {Helpers.localeCurrency(row.SubscriberDue, row.Currency)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={filteredSummary.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      {filteredSummary.length === 0 && <div style={styles.nodata}>No Data</div>}

      <Dialog
        open={showDialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reload the data"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You changed the values in the taskbar but didn't reload the data,
            reload before you download.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={processError !== null}
        onClose={() => setProcessError(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Error processing statements
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography color="error">{processError}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProcessError(null)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    overflow: "auto",
  },
  inner: {
    flex: 1,
    padding: 20,
    flexDirection: "row",
  },
  hide: {
    display: "none",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  textField: {
    width: 200,
    marginRight: 20,
  },
  taskbar: {
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loading: {
    borderRadius: 25,
    height: 24,
    width: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nodata: {
    padding: 20,
    fontSize: 18,
    opacity: 0.3,
  },
};

export default withTheme(Pending);
