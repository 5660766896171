import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';
import { CircularProgress, DialogContent, DialogActions, Dialog, Typography, TextField, Button, Switch, FormControlLabel, FormHelperText } from '@material-ui/core';

const IssueDeactivationNoticeDialog = props => {

    const {show, success, close, id_order} = props;

    const context = useContext(GlobalContext);

    const [email, setEmail] = React.useState(props.email);
    const [days, setDays] = React.useState(14);
    const [resetDeactivation, setResetDeactivation] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleChange = e => {
        setEmail(e.target.value);
    }

    const handleSendDeactivation = () => {
        setLoading(true);
        const params = {
            email: email,
            resetDeactivation: resetDeactivation,
            days: days
        }
        context.orderIssueDeactivationNotice(id_order, params).then(
            res => {
                context.showAlert('success', 'Deactivation notice issued');
                setLoading(false);

                if(typeof success == 'function')
                    success()
            },
            err => {
                setLoading(false);
                context.showAlert('error', 'Error updating subscriptions ' + JSON.stringify(err))
            }
        )
    }


    const handleClose = () => {
        if(typeof close == 'function')
            close()
    }

    
    return (
        <Dialog open={show} onClose={handleClose} aria-labelledby="delete-socket-dialog-title">
            <DialogContent style={{maxWidth: 500}}>
                <Typography variant="h6" style={{marginBottom: 10}}>Issue deactivation notice?</Typography>
                <Typography variant="subheading" style={{marginBottom: 20}}>This will email the recipient below with an email notifying deactivation will occur unless payment is made.</Typography>

                <TextField
                    fullWidth
                    margin="dense"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    label="Confirm email address"
                    type="text"
                    InputLabelProps={{shrink: true}} 
                    style={{marginTop: 20}}
                />


                <FormControlLabel
                    control={
                    <Switch
                        checked={resetDeactivation}
                        onChange={(e) => setResetDeactivation(e.target.checked)}
                        name="resetDeactivation"
                        color="secondary"
                    />
                    }
                    label="Adjust Deactivation Date"
                    style={{marginTop: 15}}
                />

                {resetDeactivation && <FormHelperText error={true}>Deactivation date will default to 14 days until deactivation is scheduled, if you wish to allow for longer adjust the days below.</FormHelperText>}

                {resetDeactivation && <TextField
                    margin="dense"
                    name="days"
                    value={days}
                    onChange={e => {setDays(parseInt(e.target.value))}}
                    label="Days until deactivation"
                    type="number"
                    InputLabelProps={{shrink: true}} 
                    style={{marginTop: 20}}
                />}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">Cancel</Button>
                <Button onClick={handleSendDeactivation} color="secondary" disabled={loading}>Confirm {loading && <CircularProgress color="inherit" size={18} />}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default IssueDeactivationNoticeDialog;