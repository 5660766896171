import React from 'react';

export default React.createContext({
    version: '0',
    user: false,
    isUserValid: () => {},
    alerts: [],
    showAlert: (type, msg) => {},
    closeAlert: () => {},
    token: null,
    init: true,
    mapsloaded: false,
    colorScheme: 'light',
    windowMode: 'xs',
    breakpoint: windowMode => {},
    userLogin: () => {},
    userLogout: () => {},
    userValidate: () => {},
    userChangePassword: () => {},
    userNotificationRead: id => {},
    fetchGroupSchemes: () => {},
    fetchInternalGroups: () => {},
    fetchPoints: () => {},
    fetchPointLastKnown: (cpid, signal) => {},
    fetchChargeSessions: () => {},
    fetchPendingStatements: () => {},
    postStatements: () => {},
    fetchAwaitingStatements: () => {},
    postPaidStatements: () => {},
    fetchPaidStatements: () => {},
    fetchAllStatements: () => {},
    fetchMapPoints: () => {},
    fetchVeosPortal: () => {},
    fetchStats: () => {},
    fetchVisits: () => {},
    addVisit: () => {},
    saveVisit: () => {},
    fetchOrganisations: () => {},
    fetchOrganisation: () => {},
    fetchOrganisationOrders: () => {},
    saveOrganisation: () => {},
    fetchOrders: () => {},
    fetchOrdersBy: () => {},
    fetchOrder: () => {},
    saveOrder: () => {},
    fetchLogs: () => {},
    addLog: () => {},
    fetchLogActions: () => {},
    fetchOrderSubscriptions: () => {},
    fetchOrganisationSubscriptions: () => {},
    fetchSubscription: (id) => {},
    addSubscription: () => {},
    deleteSubscription: () => {},
    saveSubscription: () => {},
    updateSubscriptionsAs: () => {},
    fetchProducts: () => {},
    fetchOrderContacts: () => {},
    addOrderContact: () => {},
    deleteOrderContact: () => {},
    fetchOrganisationTypes: () => {},
    fetchOrderStatuses: () => {},
    switchOrderOrganisation: () => {},
    search: () => {},
    fetchTickets: () => {},
    fetchTicket: () => {},
    addTicket: () => {},
    updateTicket: () => {},
    saveTicket: (ticket) => {},
    takeTicket: (id_ticket) => {},
    routeTicket: (obj) => {},
    addVisitReport: (obj) => {},
    fetchTicketLogs: () => {},
    addTicketLog: (comment) => {},
    fetchTicketsMeta: () => {},
    fetchUsers: () => {},
    fetchReportColumns: (table) => {},
    fetchOrderCancellationReasons: () => {},
    toggleColorScheme: () => {},
    fetchSubscriptionSockets: (id) => {},
    fetchSubscriptionSocket: (socket_id) => {},
    fetchSubscriptionSocketsUnpaidList: () => {},
    addSubscriptionSocket: (socket) => {},
    saveSubscriptionSocket: (socket) => {},
    switchSubscriptionSocketBoard: (socket) => {},
    deleteSubscriptionSocket: (id) => {},
    orderIssueDeactivationNotice: (id, params) => {},
    orderClearDeactivationNotice: (id) => {},
    commissionFetchGroupSchemes: () => {},
    commissionAddGroupScheme: (params) => {},
    commissionFetchPoints: () => {},
    commissionAddPoint: (params) => {},
    commissionGetPointMeters: (pointId) => {},
    commissionAddPointChargeTime: (params) => {},
    commissionStopPointChargeTime: (params) => {},
    commissionChargePointPower: (params) => {},
    commissionChargePointConnector: (params) => {},
    commissionFetchSites: (id) => {},
    commissionAddSite: (params) => {},
    commissionComplete: (params) => {},
    subscriptionSuspend: (params) => {},
    subscriptionSuspendStatus: (id) => {},
    fetchSubscriptionsPaymentOutstanding: () => {},
    issuePaymentReminder: () => {},
    apiRequest: (uri, method = 'GET', params = null, signal = null) => {},
    apiFileUpload: (uri, body, signal = null) => {},
    apiFileDownload: (uri, filename = new Date().getTime(), signal = null) => {},
    apiFileGenerate: (uri, filename, method = 'GET', params = null, signal = null) => {},
    background: {},
    authCode: () => {},
    setAuthCode: code => {}
})