import React, {useContext, useState, useEffect} from 'react';
import { useTheme, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import InputField from './inputField';
import { makeValidation, isValidForm} from'../content/global/formHelper';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const SingleEditField = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [showEdit, setShowEdit] = useState(false);

    const [field, setField] = useState(props.value);
    const [validation, setValidation] = useState(makeValidation([
        {
            name: 'field',
            display_name: props.name,
            required: props.required || false
        },
    ]));
    
    useEffect(() => {
        const valid = isValidForm({field: field}, validation, false);
        setValidation(valid.validation);
    }, [field])
    
    const handleInputChange = e => {
        setField(e.target.value);
    }
    
    const handleSubmit = () => {
        if(validation.field.error === false){
            if(typeof props.onUpdate === 'function')
                props.onUpdate(props.field, field, typeof props.index !== 'undefined' ? props.index : null)

            setShowEdit(false);
        }
    }
    
    const handleSubmitAll = () => {
        if(validation.field.error === false){
            if(typeof props.onUpdateAll === 'function')
                props.onUpdateAll(props.field, field)

            setShowEdit(false);
        }
    }

    const sanitizeValue = () => {

        if(props.type === 'select' && typeof props.items !== 'undefined'){
            const item = props.items.find(i => i.key === props.value);
            return typeof item !== 'undefined' ? item.value : props.value;
        } else 
            return props.value;

    }
    


    return (
        <React.Fragment>
            {sanitizeValue()} <IconButton size="small" onClick={() => setShowEdit(true)} color="secondary"><EditIcon style={{fontSize: 16}}/></IconButton>

            <Dialog open={showEdit} onClose={() => setShowEdit(false)} fullWidth={true} maxWidth="xs">
                <DialogTitle><div  className="flex-row align-center gap-5"><EditIcon /><Typography variant="subtitle1">Edit {props.name}</Typography></div></DialogTitle>
                <DialogContent>
                    <InputField variant="standard" name='field' validation={validation.field} value={field} handleChange={handleInputChange} hoverLabel={true} type={props.type || 'text'} items={props.items || null} />
                </DialogContent>
                <DialogActions>
                    <div className="flex-row gap-10">
                        <Button onClick={() => setShowEdit(false)}>Cancel</Button>
                        <div style={{flex: 1}}></div>
                        {typeof props.onUpdateAll === 'function' && <Button onClick={handleSubmitAll} variant="contained" color="default">Set all</Button>}
                        <Button onClick={handleSubmit} variant="contained" color="secondary">Save</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default SingleEditField;