import React from 'react';
import { Typography, Button, Paper, useTheme, makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: 20,
        padding: 20
    }
}))


const CommissioningSite  = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    
    const handleChangeSite = () => {
        if(typeof props.changeSite === 'function')
            props.changeSite();
    }

    return (
        <Paper className={classes.wrapper}>
            <Typography variant="h6">#{props.site.ChargeSiteID}: {props.site.SiteName}</Typography>
            <Typography variant="body1" style={{marginBottom: 20}}>
                {props.site.AddressLine1.length > 0 && props.site.AddressLine1}
                {props.site.AddressLine2.length > 0 && `, ${props.site.AddressLine2}`}
                {props.site.AddressLine3.length > 0 && `, ${props.site.AddressLine3}`}
                {props.site.Town.length > 0 && `, ${props.site.Town}`}
                {props.site.County.length > 0 && `, ${props.site.County}`}
                {props.site.PostCode.length > 0 && `, ${props.site.PostCode}`}
                {props.site.Country.length > 0 && `, ${props.site.Country}`}
            </Typography>
            <Button variant="contained" onClick={handleChangeSite} color="primary">Change</Button>
        </Paper>
    )
}

export default CommissioningSite;