import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const TicketContactTypes = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    const handleClick = e => {
        e.preventDefault();
        console.log(e.target.href);
    }

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Ticket Contact Types',
                            route: '/settings/ticket_contact_types'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Ticket Contact Types</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>Contact types are a reportable selectors available in various parts of the ticketing system that categorises different types of individual.</Typography>


                <Crud
                    uri='ticket_contact_type'
                    idCol='id_ticket_contact_type'
                    displayColumns={[
                        { 
                            name: 'ticket_contact_type_name', 
                            type: 'string',
                            editable: true,
                            required: true
                        },
                        { 
                            name: 'disabled', 
                            type: 'boolean',
                            editable: true
                        }
                    ]}
                />

            </div>
        </div>
    )

}

export default TicketContactTypes
