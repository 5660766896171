import React, { useState, useEffect, useContext } from 'react';
import { useStyles } from '../styles';
import { useTheme } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { 
    Avatar, 
    Chip
} from '@material-ui/core';



const TicketChargeChip = props => {

        const theme = useTheme();
        const styles = useStyles(theme);

        const { cpid, socketInfo, handleShowSocket } = props;


        const evLEDClass = (feed) => {
            if(feed == null)
                return null;

            let style = null;
            if(typeof feed.mode3 != 'undefined')
                style = `LED${feed.mode3.ledColour}${feed.mode3.ledBlink ? 'b' : ''}`;

            if(typeof feed.ocpp16 != 'undefined')
                style = `LEDOCPP`;

            if(style != null)
                return styles[style];

            return null;
        }

        const getIcon = (feed) => {
            if(feed == null)
                return ' ';

            if(typeof feed.ocpp16 != 'undefined')
                return feed.isOnline?<CheckIcon />:<CloseIcon />;

            return ' ';
        }

        return (
            <div>
                <Chip
                    label={cpid}
                    clickable
                    avatar={socketInfo == null ? <CircularProgress color="primary" size={24} /> : <Avatar className={evLEDClass(socketInfo)}>{getIcon(socketInfo)}</Avatar>}
                    onClick={() => handleShowSocket(cpid)}
                    style={{margin: theme.spacing(1)}}
                />
            </div>
        )
    }

export default TicketChargeChip