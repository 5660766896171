import React from 'react';
import {Button, useTheme, CircularProgress, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gutterBottom: {
    marginBottom: 10
  },
  gutterNone: {

  }
}))

const ProgressButton = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {waiting, variant, color, fullWidth, onClick, size, gutterBottom} = props;

  const isDisabled = () => {
    if(typeof props.disabled !== 'undefined')
      return props.disabled;

    return waiting || false;
  }

  return (
    <Button 
      disabled={isDisabled()} 
      variant={variant || 'default'} 
      color={color || 'default'} 
      fullWidth={fullWidth || false} 
      onClick={onClick}
      size={size || 'medium'}
      classes={{
        root: gutterBottom === true ? classes.gutterBottom : classes.gutterNone
      }}
      >
        {!waiting && props.children}
        {waiting && <CircularProgress color='inherit' size={20} style={{margin: '0 10px'}}/>}
    </Button>
  );
}

export default ProgressButton;
