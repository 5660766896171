import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';
import { TextField, Button, FormControl, CircularProgress, InputLabel, Select, MenuItem } from '@material-ui/core';


const AddLog = props => {
    const [loading, setLoading] = useState(false);

    const baseLog = {
        id_organisation: typeof props.id_organisation!='undefined'?props.id_organisation:null,
        id_order: typeof props.id_order!='undefined'?props.id_order:null,
        id_action: null,
        log_entry: '',
        tags: '',
        log_by: ''
    }

    // Create a local state
    const [log, setLog] = useState({...baseLog});

    const [logActions, setLogActions] = useState([]);


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    useEffect(() => {
        context.fetchLogActions().then(
            res => {
                setLogActions(res.result);
            },
            err => {
                context.showAlert('error', 'Unable to fetch log actions')
            }
        )
    }, [])

    const handleChange = e => {
        let newLog = {...log};
        newLog[e.target.name] = e.target.value;
        setLog(newLog);
    }

    const invalid = () => {
        if(isInvalid('log_entry') || (isInvalid('id_organisation') && isInvalid('id_order')))
            return true;
        
        return false;
    }

    const isInvalid = prop => {
        switch(prop){
            case 'log_entry':
                return log.log_entry == ''?true:false;
            case 'log_by':
                return log.log_by == ''?true:false;
            case 'id_organisation':
                return log.id_organisation == null?true:false;
            case 'id_order':
                return log.id_order == null?true:false;
            default:
                return true;
        }
    }

    const createLog = () => {
        if(!invalid())
            setLoading(true);
            context.addLog(log).then(
                res => {
                    setLog({...baseLog});
                    setLoading(false);
                    context.showAlert('success', 'Log added');

                    if(typeof props.onAdd == 'function')
                        props.onAdd();
                },
                err => {
                    setLoading(false);
                    context.showAlert('error', 'Error adding log ' + JSON.stringify(err));
                }
            )
    }

    
        
    return (

        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap'}}>
            <div style={{flex: 1, marginBottom: 10, padding: 10}}>
                <FormControl fullWidth>
                    <TextField name="log_entry" value={log.log_entry} onChange={handleChange} label="Add new note" multiline rows={3} error={isInvalid('log_entry')} />
                </FormControl>
            </div>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 30, alignItems: 'flex-end'}}>
                {/* <div style={{flex: 1}}>
                    <div style={{padding: '0 10px'}}>
                        <FormControl fullWidth>
                            <TextField name="tags" label="Tags" value={log.tags} onChange={handleChange} />
                            <FormHelperText>Enter as hashtags e.g. #UKEV1234 #vendelectric</FormHelperText>
                        </FormControl>
                    </div>
                </div> */}
                {log.id_order && <div style={{minWidth: 200}}>
                    <div style={{padding: '0 10px'}}>
                        <FormControl fullWidth>
                            <InputLabel id="id_action-label">Action</InputLabel>
                            <Select
                                labelId="id_action-label"
                                id="id_action"
                                name="id_action"
                                value={log.id_action}
                                onChange={handleChange}
                            >
                                <MenuItem value={null}>-None-</MenuItem> 
                                {logActions.map(a => (
                                    <MenuItem key={a.id_action} value={a.id_action}>{a.action_description}</MenuItem> 
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>}
                <div style={{flex:1}}></div>
                <div style={{padding: '0 10px'}}>
                    <Button color="secondary" variant="contained" disabled={invalid()} onClick={createLog}>Add {loading && <CircularProgress size={20} className="ml" color="inherit" />}</Button>
                </div>
            </div>
        </div>

    );
}

export default AddLog