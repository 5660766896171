import React, { useState } from "react";
import { withTheme } from "@material-ui/styles";
import GlobalContext from "../../context/global-context";
import ActivityIndicator from "../global/ActivityIndicator";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import AwaitingRevoke from "./awaiting_revoke";
import Helpers from "../global/helpers";
import RowMenu from "./awaiting_rowmenu";

class Awaiting extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      veosSchemes: [],
      schemesInternal: [],
      veosData: [],
      veosAdjustmentData: [],
      veosOCPIData: [],
      summary: [],
      loading: false,
      showDialog: false,
      dialogLoading: false,
      dialogError: null,
      activePaymentRow: null,
      activePaymentReference: "",
      schemeRevoke: null,
      total: null,
      downloading: false,
      page: 0,
    };
  }

  componentDidMount() {
    this.loadStatements();
  }

  closePaymentDialog = () => {
    this.setState({
      showDialog: false,
      activePaymentRow: null,
      activePaymentReference: "",
    });
  };

  showPaymentDialog = (row) => {
    const newRow = {
      ...row,
      bank: this.paymentInformation(row.groupSchemeID),
    };
    this.setState({ showDialog: true, activePaymentRow: newRow });
  };

  handlePaymentRefChange = (e) => {
    this.setState({
      activePaymentReference: e.target.value,
      dialogError: false,
    });
  };

  postPayment = () => {
    this.setState({ dialogLoading: true, dialogError: null });

    if (
      this.state.activePaymentReference != null &&
      this.state.activePaymentReference.length > 0
    ) {

      //
      //  Setup a blank ids array and loop through and add ids in
      //
      let ids = [];
      let adjustmentids = [];
      let ocpiids = [];

      const sessions = this.state['veosData'].filter(
        (s) =>
          s.groupSchemeID == this.state.activePaymentRow.groupSchemeID &&
          s.Currency == this.state.activePaymentRow.Currency
      );

      sessions.map((s) => {
        ids.push(s.Id);
      });

      const adjustments = this.state.veosAdjustmentData.filter(
        (s) =>
          s.groupSchemeID == this.state.activePaymentRow.groupSchemeID &&
          s.Currency == this.state.activePaymentRow.Currency
      );

      adjustments.map((a) => {
        adjustmentids.push(a.StatementAdjustmentId);
      });

      const ocpicdrs = this.state.veosOCPIData.filter(
        (s) =>
          s.id_group_scheme == this.state.activePaymentRow.groupSchemeID &&
          s.currency == this.state.activePaymentRow.Currency
      );

      ocpicdrs.map((o) => {
        ocpiids.push(o.id_statement_line);
      });

      const fields = {
        source: this.state.activePaymentRow.Source,
        ids: ids,
        adjustmentids: adjustmentids,
        ocpiids: ocpiids,
        paymentReference: this.state.activePaymentReference,
        groupScheme: this.state.activePaymentRow.groupSchemeID,
        currency: this.state.activePaymentRow.Currency,
        batches: this.state.activePaymentRow.Batches,
      };

      this.context.postPaidStatements(fields).then(
        (res) => {
          let newSummary = [...this.state.summary];
          newSummary.map((s) => {
            if (
              s.groupSchemeID == this.state.activePaymentRow.groupSchemeID &&
              s.Currency == this.state.activePaymentRow.Currency
            ) {
              s.Paid = true;
              s.PaymentReference = this.state.activePaymentReference;
            }
          });

          this.setState({
            summary: newSummary,
            showPaymentDialog: false,
            activePaymentReference: null,
            activePaymentRow: null,
            dialogLoading: false,
          });
        },
        (err) => {
          console.log("error");
          console.log(err);

          this.setState({ dialogLoading: false, dialogError: err.msg });
        }
      );

    } else {
      this.setState({
        dialogLoading: false,
        dialogError: "Please enter a payment reference",
      });
    }
  };

  loadSchemes = (callback = () => {}) => {
    this.context.fetchGroupSchemes('veos').then(
      (res) => {
        this.setState({ veosSchemes: res.result }, () => {
          typeof callback === "function" && callback();
        });
      },
      (err) => {
        this.setState({ loading: false });
        console.log(err);
      }
    );
  };

  loadSchemesInternal = (callback = () => {}) => {
    this.setState({ schemesInternal: [] }, () => {
      this.context.fetchInternalGroups().then(
        (res) => {
          this.setState({ schemesInternal: res.result }, () => {
            typeof callback === "function" && callback();
          });
        },
        (err) => {
          this.setState({ loading: false });
          console.log(err);
        }
      );
    });
  };

  loadStatements = () => {
    // Reset the storage and set to loading
    this.setState({
      loading: true,
      veosData: [],
      summary: [],
    });

    // Load in the schemes first, this will marry to the data once it is loaded

    //
    //  VEOS Schemes
    //
    this.loadSchemes(() => {
      //
      //  Internal Schemes
      //
      this.loadSchemesInternal(() => {
        //
        // Fetch the VEOS charge sessions that are available to pay
        //
        this.context.fetchAwaitingStatements("veos").then(
          (res) => {
            //
            // Store the data and move on
            //
            this.setState({ veosData: res.result }, () => {
              // Fetch the Waveasta charge sessions that are available to pay
              this.context.fetchAwaitingAdjustmentStatements("veos").then(
                (res) => {
                  this.setState({ veosAdjustmentData: res.result }, () => {
                    // Fetch the OCPI data
                    this.context.fetchAwaitingOCPIStatements("veos").then(
                      (res) => {
                        this.setState({ veosOCPIData: res.result }, () => {this.createSummaryData()});
                      },
                      (err) => {
                        this.setState({
                          data: [],
                          summary: [],
                          loading: false,
                        });
                      }
                    );
                  });
                },
                (err) => {
                  this.setState({ data: [], summary: [], loading: false });
                }
              );
            });
          },
          (err) => {
            this.setState({ data: [], summary: [], loading: false });
          }
        );
      });

    });
  };

  createSummaryData = () => {
    this.setState({ summary: [], loading: true }, () => {
      //
      //  Loop thought the data and cluster the information into a summary array
      //

      // This is our end result
      let summary = [];

      // There is more than one data source that needs concatenating into the summary
      const datasources = ["veosData"];

      //
      //  Loop through each data source and summarise the data which will then be
      //  concatenated into the overall summary
      //
      datasources.map((d) => {
        let tmpSummary = [];
        this.state[d].map((row) => {
          const idx = tmpSummary.findIndex(
            (s) =>
              s.groupSchemeID == row.groupSchemeID && s.Currency == row.Currency
          );
          if (idx == -1) {
            const newLine = {
              Selected: false,
              Sessions: 1,
              groupSchemeID: row.groupSchemeID,
              SubscriberDue: row.SubscriberDue,
              Source: row.Source,
              Paid: false,
              PaymentReference: "",
              Currency: row.Currency,
              BankName: "",
              BankAcc: "",
              BankSort: "",
              Batches: [row.StatementBatchId],
            };
            tmpSummary.push(newLine);
          } else {
            tmpSummary[idx].Sessions++;
            tmpSummary[idx].SubscriberDue += row.SubscriberDue;
            if (tmpSummary[idx].Batches.indexOf(row.StatementBatchId) === -1)
              tmpSummary[idx].Batches.push(row.StatementBatchId);
          }
        });

        if (d == "veosData") {
          this.state.veosAdjustmentData.map((row) => {
            const idx = tmpSummary.findIndex(
              (s) =>
                s.groupSchemeID == row.groupSchemeID &&
                s.Currency == row.Currency
            );
            if (idx == -1) {
              const newLine = {
                Selected: false,
                Sessions: 0,
                groupSchemeID: row.groupSchemeID,
                SubscriberDue: row.SubscriberAdjustmentAmount,
                Source: row.Source,
                Paid: false,
                PaymentReference: "",
                Currency: row.Currency,
                BankName: "",
                BankAcc: "",
                BankSort: "",
                Batches: [row.StatementBatchId],
              };
              tmpSummary.push(newLine);
            } else {
              tmpSummary[idx].SubscriberDue += row.SubscriberAdjustmentAmount;
              if (tmpSummary[idx].Batches.indexOf(row.StatementBatchId) === -1)
                tmpSummary[idx].Batches.push(row.StatementBatchId);
            }
          });

          this.state.veosOCPIData.map((row) => {
            const idx = tmpSummary.findIndex(
              (s) =>
                s.groupSchemeID == row.id_group_scheme &&
                s.Currency == row.currency
            );
            if (idx == -1) {
              const newLine = {
                Selected: false,
                Sessions: 0,
                groupSchemeID: row.id_group_scheme,
                SubscriberDue: row.subscriber_due,
                Source: "veos",
                Paid: false,
                PaymentReference: "",
                Currency: row.currency,
                BankName: "",
                BankAcc: "",
                BankSort: "",
                Batches: [row.id_batch],
              };
              tmpSummary.push(newLine);
            } else {
              tmpSummary[idx].SubscriberDue += row.subscriber_due;
              if (tmpSummary[idx].Batches.indexOf(row.id_batch) === -1)
                tmpSummary[idx].Batches.push(row.id_batch);
            }
          });
        }

        //
        //  Find the bank info to go with the summary
        //
        tmpSummary.map((row) => {
          const bank = this.paymentInformation(row.groupSchemeID);
          if (bank !== null) {
            row.BankName = bank.account_name;
            row.BankAcc = bank.account_number;
            row.BankSort = bank.sort_code;
          }
        });

        //
        // Concat with the existing summary and move on to the next
        //
        summary = summary.concat(tmpSummary);
      });

      // Total up the awaiting payment
      let total = 0;
      summary.map((s) => {
        total += parseInt(s.SubscriberDue * 100);
      });

      this.setState(
        { summary: summary, loading: false, total: total / 100 },
        () => {
          this.props.setBadge(this.state.summary.length);
        }
      );
    });
  };

  groupSchemeName = (groupSchemeID) => {
    let scheme = this.state.veosSchemes.find(
      (s) => s.groupSchemeID === groupSchemeID
    );
    return typeof scheme != "undefined" ? scheme.groupSchemeName : "Unknown";
  };

  paymentInformation = (groupSchemeID) => {
    const scheme = this.state.schemesInternal.find(
      (s) => s.id_group_scheme === groupSchemeID
    );
    return typeof scheme != "undefined" ? scheme.bank : null;
  };

  setSchemeRevoke = (val) => {
    this.setState({ schemeRevoke: val });
  };

  // Handling potential btoa encoding issues
  toBinaryStr(str) {
    const encoder = new TextEncoder();
    const charCodes = encoder.encode(str);
    return String.fromCharCode(...charCodes);
  }

  //
  // Download the table results into a csv file
  //

  downloadCSV = () => {
    if (this.state.summary.length > 0) {
      this.setState({ downloading: true });

      let csvStr = "";

      // Add the headers
      csvStr = csvStr + "Group Scheme,";
      csvStr = csvStr + "Source,";
      csvStr = csvStr + "Amount Due,";
      csvStr = csvStr + "Currency,";
      csvStr = csvStr + "Bank Name,";
      csvStr = csvStr + "Acc No / IBAN,";
      csvStr = csvStr + "Sort / Swift,";
      csvStr = csvStr + "Payment Reference,";

      csvStr = csvStr.substring(0, csvStr.length - 1);
      csvStr = csvStr + "\n";

      // Add the data
      this.state.summary.map((row) => {
        csvStr =
          csvStr +
          '"' +
          this.groupSchemeName(row.groupSchemeID) +
          '",';
        csvStr = csvStr + '"' + row.Source + '",';
        csvStr = csvStr + '"' + row.SubscriberDue.toFixed(2) + '",';
        csvStr = csvStr + '"' + row.Currency + '",';
        csvStr = csvStr + '"' + row.BankName + '",';
        csvStr = csvStr + '"' + row.BankAcc + '",';
        csvStr = csvStr + '"' + row.BankSort + '",';
        csvStr = csvStr + '"' + row.PaymentReference + '",';

        csvStr = csvStr.substring(0, csvStr.length - 1);
        csvStr = csvStr + "\n";
      });

      csvStr = csvStr.substring(0, csvStr.length - 1);

      var blob = new Blob([csvStr], { type: "text/csv" });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // for IE
        console.log("using window navigator");
        window.navigator.msSaveOrOpenBlob(
          blob,
          "vend_statements_awaiting_payment.csv"
        );
        this.setState({ downloading: false });
      } else {
        // for Non-IE (chrome, firefox etc.)
        console.log("not using window navigator");
        let a = document.createElement("a");
        a.style.display = "none";
        a.href =
          "data:application/octet-stream;base64," +
          btoa(this.toBinaryStr(csvStr));
        a.download = "vend_statements_awaiting_payment.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.setState({ downloading: false });
      }
    }
  };

  downloadBatchCSV = async (scheme, currency, batches) => {
    batches.forEach((b) => {
      window.open(
        `${this.context.endpoint}statement/download?currency=${currency}&groupscheme=${scheme}&batch=${b}`
      );
    });
  };

  emailBatchCSV = async (scheme, currency, batches) => {
    let totalSent = 0;
    try {
      for (let i = 0; i < batches.length; i++) {
        await this.context.apiRequest(
          `statement/send?currency=${currency}&groupscheme=${scheme}&batch=${batches[i]}`
        );
        totalSent += 1;
      }

      if (totalSent === batches.length) return "SENT";
      else return "PARTIAL";
    } catch (error) {
      console.log(error);
      if (!!totalSent) return "ERROR";
      else return "PARTIAL";
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  render() {
    return (
      <div style={Object.assign({}, !this.props.show && styles.hide)}>
        <div style={styles.taskbar}>
          <h2
            style={{
              color: this.props.theme.palette.primary.main,
              fontWeight: 300,
            }}
          >
            Awaiting Payment{" "}
            {this.state.total !== null &&
              ` (${Helpers.localeCurrency(this.state.total, "GBP")})`}
          </h2>

          <div>
            <Button
              variant="contained"
              color={"secondary"}
              onClick={this.loadStatements}
            >
              Refresh
              {this.state.loading && (
                <div style={styles.loading}>
                  <ActivityIndicator />
                </div>
              )}
            </Button>
          </div>
        </div>

        {/* 
                Add the pagination here
                 */}
        <Paper style={styles.root}>
          <TablePagination
            rowsPerPageOptions={[50]}
            component="div"
            count={this.state.summary.length}
            rowsPerPage={50}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={() => {}}
          />
          <Table style={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell>Group Scheme</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Amount Due</TableCell>
                <TableCell>Statements</TableCell>
                <TableCell>Bank Name</TableCell>
                <TableCell>Acc No / IBAN</TableCell>
                <TableCell>Sort / Swift</TableCell>
                <TableCell>Payment Reference</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.summary
                .slice(this.state.page * 50, this.state.page * 50 + 50)
                .map((row) => (
                  <TableRow key={row.groupSchemeID}>
                    <TableCell>
                      {this.groupSchemeName(row.groupSchemeID)}
                    </TableCell>
                    <TableCell>{row.Source}</TableCell>
                    <TableCell>
                      {Helpers.localeCurrency(row.SubscriberDue, row.Currency)}
                    </TableCell>
                    <TableCell>{row.Batches.length}</TableCell>
                    <TableCell>{row.BankName}</TableCell>
                    <TableCell>{row.BankAcc}</TableCell>
                    <TableCell>{row.BankSort}</TableCell>
                    <TableCell>{row.PaymentReference}</TableCell>
                    <TableCell align="right">
                      {/* This is here creates a hude memory cache issue */}
                      <RowMenu
                        row={row}
                        setSchemeRevoke={this.setSchemeRevoke}
                        showPaymentDialog={this.showPaymentDialog}
                        downloadBatchCSV={this.downloadBatchCSV}
                        groupSchemeName={this.groupSchemeName}
                        emailBatchCSV={this.emailBatchCSV}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[50]}
            component="div"
            count={this.state.summary.length}
            rowsPerPage={50}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={() => {}}
          />
        </Paper>
        {/* 
                Add the pagination here
                 */}

        {this.state.summary.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "14px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={this.downloadCSV}
            >
              Download to .CSV
              {this.state.downloading && (
                <div style={styles.loading}>
                  <ActivityIndicator />
                </div>
              )}
            </Button>
          </div>
        )}

        {this.state.summary.length == 0 && (
          <div style={styles.nodata}>No Data</div>
        )}

        {this.state.activePaymentRow && (
          <Dialog
            open={this.state.showDialog}
            onClose={this.closePaymentDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">MARK AS PAID</DialogTitle>
            <DialogContent>
              {this.state.dialogError && (
                <p style={{ color: this.props.theme.palette.primary.main }}>
                  {this.state.dialogError}
                </p>
              )}
              <DialogContentText>
                Marking as paid will also send the statement to the host and BCC
                the designated internal mailbox.
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                Payee:{" "}
                <b>
                  {this.groupSchemeName(this.state.activePaymentRow.groupSchemeID)}
                </b>
                <br />
                Amount:{" "}
                <b>
                  &pound;{this.state.activePaymentRow.SubscriberDue.toFixed(2)}
                </b>
              </DialogContentText>
              {this.state.activePaymentRow.bank && (
                <DialogContentText id="alert-dialog-description">
                  <span style={{ color: "rgba(0,0,0,.87)" }}>
                    BANK &amp; PAYMENT DETAILS
                  </span>
                  <br />
                  Account Name:{" "}
                  <b>{this.state.activePaymentRow.bank.account_name}</b>
                  <br />
                  Account Number:{" "}
                  <b>{this.state.activePaymentRow.bank.account_number}</b>
                  <br />
                  Sort Code: <b>{this.state.activePaymentRow.bank.sort_code}</b>
                </DialogContentText>
              )}
              {!this.state.activePaymentRow.bank && (
                <DialogContentText id="alert-dialog-description">
                  <b>No Bank details</b>
                </DialogContentText>
              )}
              <FormControl style={styles.textField}>
                <InputLabel shrink htmlFor="age-label-placeholder">
                  Payment Reference
                </InputLabel>
                <Input
                  name="paymentreference"
                  label="PaymentReference"
                  value={this.state.activePaymentReference}
                  onChange={this.handlePaymentRefChange}
                  style={styles.textField}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              {!this.state.dialogLoading && (
                <Button onClick={this.closePaymentDialog}>Cancel</Button>
              )}
              {!this.state.dialogLoading && (
                <Button onClick={this.postPayment} color="primary">
                  Pay
                </Button>
              )}
              {this.state.dialogLoading && (
                <div
                  style={Object.assign({}, styles.loading, {
                    backgroundColor: this.props.theme.palette.primary.main,
                  })}
                >
                  <ActivityIndicator />
                </div>
              )}
            </DialogActions>
          </Dialog>
        )}

        {this.state.schemeRevoke !== null && (
          <AwaitingRevoke
            scheme={this.state.schemeRevoke}
            handleClose={() => this.setSchemeRevoke(null)}
            onSuccess={this.loadStatements}
          />
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    overflow: "auto",
  },
  inner: {
    flex: 1,
    padding: 20,
    flexDirection: "row",
  },
  hide: {
    display: "none",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  textField: {
    width: 200,
    marginRight: 20,
  },
  taskbar: {
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loading: {
    borderRadius: 25,
    height: 24,
    width: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nodata: {
    padding: 20,
    fontSize: 18,
    opacity: 0.3,
  },
};

export default withTheme(Awaiting);
