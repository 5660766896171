import React, {useState, useEffect, useContext} from 'react';
import GlobalContext from '../../context/global-context';
import { useTheme } from '@material-ui/core/styles';
import Helpers from '../global/helpers';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddLog from './add_log';
import { CircularProgress, Collapse, Button } from '@material-ui/core';


const Logs = props => {

    const reloadCount = typeof props.reloadCount != 'undefined'?[props.reloadCount]:[];

    // Create a local state
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [id_organisation, setOrganisation] = useState(typeof props.id_organisation!='undefined'?props.id_organisation:null);
    const [id_order, setOrder] = useState(typeof props.id_order!='undefined'?props.id_order:null);
    const [showAdd, setShowAdd] = useState(false);


    // Use the global context
    const context = useContext(GlobalContext);
    const theme = useTheme();

    useEffect(() => {
        getLogs();
    }, reloadCount)

    const getLogs = () => {
        setLoading(true);

        let log_params = {
            id_organisation: id_organisation,
            id_order: id_order,
        }

        context.fetchLogs(log_params).then(
            res => {
                setLogs(res.result);
                setShowAdd(false);
                setLoading(false);
            }, 
            err => {
                setLoading(false);
                context.showAlert('error', 'error fetching logs: ' + JSON.stringify(err.msg));
            }
        )
    }

    
    const initials = str => {
        const bits = str.split(' ');
        let ints = '';
        bits.map(b => ints += b.charAt(0));
        return ints;
    }

        
    return (

        <div style={{padding: '0 30px'}}>
            
            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
                <Typography variant="h6" style={{flex: 1}}>Notes</Typography>
                {!showAdd && <Fab onClick={() => {setShowAdd(true)}} color="secondary" size="medium" aria-label="add log">
                    <AddIcon />
                </Fab>}
            </div>
            
            <Collapse in={showAdd}>
                <AddLog id_order={id_order} id_organisation={id_organisation} onAdd={getLogs} />
            </Collapse>

            <Divider />
            
            {loading && <CircularProgress color="primary" size={30} />}

            <List style={{maxHeight: 600, overflow: 'auto'}}>

                {logs.length == 0 && <Typography style={{color:'#aaa', padding: 30}}>No logs</Typography>}

                {logs.map((log, i) => (
                    
                    <div key={log.id_log}>
                        <Paper style={{marginBottom: 15}}>
                            <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar>{initials(Helpers.isNull(log.user, log.log_by))}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                    primary={log.log_entry}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                            {Helpers.SQLtoUTCDate(log.created)}
                                            </Typography>

                                            {log.id_order != null && <span> | </span>}
                                            {log.id_order != null && <Typography
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                            >Order: VE{log.id_order}</Typography>}
                                            
                                            <span> | {Helpers.isNull(log.user, log.log_by)}</span>
                                        </React.Fragment>
                                    }
                                    />
                            </ListItem>
                        </Paper>
                        
                        {log.id_action &&
                        <div style={{display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: 30, backgroundColor: '#455677', color: '#fff', marginBottom: 15}}>
                                <div style={{fontSize: 14, fontWeight: 400}}>{log.action_description} - <span style={{fontSize: 12, fontWeight: 300}}>{Helpers.isNull(log.user, log.log_by)} | {Helpers.SQLtoUTCDate(log.created, false)}</span></div>
                        </div>
                        }
                    </div>
                ))}

            </List>

            <Divider />

        </div>
    );
}

export default Logs