import React, { useContext, useEffect, useState } from 'react';

import { useTheme } from '@material-ui/styles';
import globalContext from '../../../context/global-context';
import { Alert } from '@material-ui/lab';
import { CircularProgress, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import Helpers from '../../global/helpers';

const OcpiActiveTariffs = props => {

  const theme = useTheme();
  const styles = useStyles(theme);
  const context = useContext(globalContext);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError ] = useState(null);

  useEffect(() => {

    context.apiRequest('/ocpi_tariffs/active').then(
        res => {
            setData(res.result);
            setLoading(false);
        },
        err => {
            setLoading(false);
            context.showAlert('error', typeof err.msg === 'string' ? err.msg : JSON.stringify(err.msg));
            setError('Error loading parties');
        }
    )

  }, [])

  return (
    <div>
       {error && <Alert severity='error'>{error}</Alert>}
       {loading && 
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress size={24} />
        </div>
        }
        {(!loading && data !== null) && 
          <List>
            <ListItem className={styles.flexSpaced}>
                <Typography variant="subtitle2" color="textSecondary">Name</Typography>
                <Typography variant="subtitle2" color="textSecondary">Tariff</Typography>
            </ListItem>
            {data.map((d, idx) => (
              <ListItem key={idx} className={styles.flexSpaced}>
                  <Typography>{d.identity}</Typography>
                  <Typography>{Helpers.localeCurrency(d.price, d.currency)}</Typography>
              </ListItem>
            ))}
          </List>
        }
    </div>
  )

}

export default OcpiActiveTariffs