import React from 'react';
import LocationSummary from './LocationSummary';


const locationSummaryPopUp = props => {

    const { site, toggleFavorite, history, location, handleTogglePin, visible, pinned } = props;

    return (
        <div style={Object.assign({}, styles.container, {bottom: visible?0:0})} className="animate">
            <div>
                {site && <LocationSummary site={site} toggleFavorite={toggleFavorite} history={history} location={location} handleTogglePin={handleTogglePin} pinned={pinned} />}
            </div>
        </div>
    );
}

const styles = {
    container: {
        width: '100%',
        position: 'absolute',
        left: 0,
        height: 220,
        zIndex: 99,
        backgroundColor: '#ffffff',
        overflow: 'auto'
    }
};

export default locationSummaryPopUp;