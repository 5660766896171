import React, { useRef, useContext, useState } from 'react';
import { connect } from 'react-redux';

import GlobalContext from '../../context/global-context';
import { withRouter } from 'react-router-dom';
import { useTheme } from '@material-ui/core';

import { AppBar, Toolbar, Button, Menu, MenuItem, IconButton, Typography, Badge } from '@material-ui/core'

import NotificationsIcon from '@material-ui/icons/Notifications'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import KeyIcon from '@material-ui/icons/VpnKey';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';

import Notifications from './notifications';
import GlobalSearch from '../dashboard/global_search';

const TopToolbar = props => {

    const theme = useTheme()
    const context = useContext(GlobalContext);

    const menuEl = useRef(null);
    const toolbarEl = useRef(null);

    const [showNotifications, setShowNotifications] = useState(false)
    const [showMenu, setShowMenu] = useState(false)


    const onMenuItemClick = (route) => {
        setShowMenu(false);
        props.history.push(route);
    };

    const unreadNotifications = () => {
        return props.notifications.filter(n => n.was_read === null).length
    }

    return (
        <GlobalContext.Consumer>
            {context => (
                <AppBar position="static" color="primary">
                    <Toolbar ref={toolbarEl}>
                        <IconButton id="hamburger" edge="start" color="inherit" aria-label="menu" onClick={props.onToggleDrawer}>
                            <MenuIcon />
                        </IconButton>
                        <GlobalSearch history={props.history} />
                        <Typography variant="subtitle1" style={{ flexGrow: 1 }} >Internal Management System</Typography>

                        <IconButton onClick={() => setShowNotifications(true)}>
                            <Badge badgeContent={unreadNotifications()} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>

                        <Button ref={menuEl} color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={() => setShowMenu(true)} startIcon={<AccountCircleIcon />}>
                            {context.user ? context.user.first_name : 'Menu'}
                        </Button>
                        <Menu
                            id="user-menu"
                            anchorEl={menuEl.current}
                            open={showMenu} onClose={() => setShowMenu(false)}
                        >
                            <MenuItem onClick={props.showChangePassword}><KeyIcon fontSize="small" style={{ marginRight: 10 }} />Change password</MenuItem>
                            <MenuItem onClick={context.toggleColorScheme}>{context.colorScheme == 'dark' ? <ToggleOnIcon fontSize="small" style={{ marginRight: 10 }} /> : <ToggleOffIcon fontSize="small" style={{ marginRight: 10 }} />} Dark / Light mode</MenuItem>
                            <MenuItem onClick={context.userLogout}><PowerSettingsNewIcon fontSize="small" style={{ marginRight: 10 }} />Sign out</MenuItem>
                            <Typography variant="caption" align="right" style={{ padding: 6 }} component="div">v{context.version}</Typography>
                        </Menu>
                    </Toolbar>

                    <Notifications anchor={toolbarEl} show={showNotifications} close={() => setShowNotifications(false)} />
                </AppBar>
            )}
        </GlobalContext.Consumer>
    )
}

const mapStateToProps = state => {
    return {
        notifications: state.user.notifications
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopToolbar))