import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../styles';

import { Typography } from '@material-ui/core';

import Crud from '../crud';
import Quickcrumbs from '../../global/Quickcrumbs';


const TicketPriorities = props => {


    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>

                <Quickcrumbs 
                    crumbs={[
                        {
                            name: 'Settings',
                            route: '/settings'
                        },
                        {
                            name: 'Ticket Priorities',
                            route: '/settings/ticket_priorities'
                        }
                    ]}
                    history={props.history}
                />

                <div className={styles.taskbar}>

                    <Typography variant="h4">Ticket Priorities</Typography>

                </div>
                
                <Typography color="textSecondary" variant="caption" className={styles.space}>These are the seperate levels of urgency you can apply to a ticket.</Typography>

                <Crud
                    uri='ticket_priority'
                    idCol='id_ticket_priority'
                    orderCol='order'
                    displayColumns={[
                        { 
                            name: 'ticket_priority_name', 
                            type: 'string',
                            editable: true,
                            required: true
                        }, 
                        { 
                            name: 'kpi_mins',
                            display_name: 'KPI (mins)', 
                            type: 'int',
                            editable: true
                        }, 
                        { 
                            name: 'disabled', 
                            type: 'boolean',
                            editable: true
                        }
                    ]}
                />

            </div>
        </div>
    )

}

export default TicketPriorities
