import { makeStyles, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';


const useStyles = makeStyles(theme => ({
    horizontalWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '10px'
    },
    verticalWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        gap: '10px'
    },
    draggedOver: {
        border: `1px dashed ${theme.palette.action.active}`,
        padding: 4
    }
}));

const DragDropGroup = props => {

    const theme  = useTheme();
    const styles = useStyles(theme);

    const {id, onCombine} = props;
    const direction = props.direction || 'vertical';

    const [dragOverId, setDragOverId] = useState(null);

    const handleDragEnd = result => {

        const { combine, source } = result;

        if(!combine)
            return;

        console.log(result);
        console.log(`Combine idx:${combine.draggableId} Source idx:${source.index}`)

        const destIdx = +(combine.draggableId.slice(id.length+1));
        if(typeof onCombine === 'function')
            onCombine(destIdx, source.index);

        setDragOverId(null);
        return;

    }

    const handleDragUpdate = (result) => {
        const {combine} = result;
        setDragOverId(combine ? combine.draggableId : combine);
    }
    
    return (
        <DragDropContext 
            onDragEnd={handleDragEnd}
            onDragUpdate={handleDragUpdate}
        >

            <Droppable droppableId={id} isCombineEnabled direction={direction.toLowerCase()}>
                {provided => (
                    <div
                    className={direction.toLowerCase() === 'horizontal' ? styles.horizontalWrapper : styles.verticalWrapper}
                    ref={provided.innerRef}
                    {...provided.droppableProps}>
                        {props.children.map((child, idx) => (
                            <Draggable key={idx} draggableId={`${id}-${idx}`} index={idx}>
                                {provided=>(
                                    <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef} 
                                    className={dragOverId === `${id}-${idx}` ? styles.draggedOver : {}}
                                    >
                                        {child}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>

        </DragDropContext>
    )
}

export default DragDropGroup;